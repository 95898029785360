import React from "react";
import DefaultReferralSettings from "./DefaultReferralSettings/DefaultReferralSettings";
import VipReferralSettings from "./VipReferralSettings/VipReferralSettings";

const ReferralsSettings = () => {
	return (
		<>
			<DefaultReferralSettings />
			<VipReferralSettings />
		</>
	);
};

export default ReferralsSettings;
