import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useParams } from "react-router-dom";
import Table from "../../theme/components/Table/Table";
import CopyWithAlert from "../../utils/CopyWithAlert";
import { IconButton } from "@material-ui/core";
import {
	deleteCompanyBalanceSecuritySettings,
	getCompanyBalanceSecuritySettingsList,
} from "../../app/actions/companiesActions";
import DeleteConfirmDialog from "../../theme/components/dialogs/DeleteConfirmDialog";
import { Button } from "../../theme/components/Button";
import AddSecuritySettingsToBalanceDialog from "./AddSecuritySettingsToBalanceDialog";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "ttlInMinutes", label: "TTL in Minutes" },
	{ key: "approveCount", label: "Approve Count" },
	{ key: "companyBalanceId", label: "Company Balance Id" },
	{ key: "manage", label: "Manage" },
];

const CompanyBalanceSecuritySettings = () => {
	const classes = useStyles();
	const routeParams = useParams();
	const dispatch = useDispatch();

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showAddSecuritySettingsDialog, setShowAddSecuritySettingsDialog] = useState(false);
	const [selectedBalanceSettingsId, setSelectedBalanceSettingsId] = useState(null);
	const companyBalanceSecuritySettings = useSelector(({ companies }) => companies.companyBalanceSecuritySettings);
	const balanceId = routeParams?.balanceId;

	useEffect(() => {
		dispatch(getCompanyBalanceSecuritySettingsList(balanceId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCloseDeleteModal = ({ accept } = {}) => {
		if (accept && selectedBalanceSettingsId) {
			dispatch(
				deleteCompanyBalanceSecuritySettings({ settingsId: selectedBalanceSettingsId, balanceId })
			);
		}
		setSelectedBalanceSettingsId(null);
		setShowDeleteDialog(false);
		setShowAddSecuritySettingsDialog(false);
	};

	const handleCloseAddSettingsModal = () => {
		setSelectedBalanceSettingsId(null);
		setShowAddSecuritySettingsDialog(false);
	};

	const handleDeleteClick = (id) => () => {
		setSelectedBalanceSettingsId(id);
		setShowDeleteDialog(true);
	};

	const handleEditClick = (id) => () => {
		setSelectedBalanceSettingsId(id);
		setShowAddSecuritySettingsDialog(true)
	}

	const handleAddUser = () => {
		setShowAddSecuritySettingsDialog(true);
	};

	return (
		<>
			<DeleteConfirmDialog
				open={showDeleteDialog}
				title='Delete Security Settings'
				message='Are you sure you want to delete selected security settings from company balance?'
				handleClose={handleCloseDeleteModal}
			/>
			<AddSecuritySettingsToBalanceDialog
				open={showAddSecuritySettingsDialog}
				handleClose={handleCloseAddSettingsModal}
				balanceId={balanceId}
				data={selectedBalanceSettingsId && companyBalanceSecuritySettings}
			/>
			{
				!companyBalanceSecuritySettings?.id &&
				<div className={classes.btnWrap}>
					<Button variant='outlined' color='primary' onClick={handleAddUser}>
						<AddIcon />
						Add Settings
					</Button>
				</div>
			}
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }, index) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{companyBalanceSecuritySettings.id ? (
						(
							<tr>
								<CopyWithAlert text={companyBalanceSecuritySettings?.id + "" || ""} label={"Id"}>
									<td className={classes.hoverCopyTd}>{companyBalanceSecuritySettings?.id}</td>
								</CopyWithAlert>
								<td>{companyBalanceSecuritySettings?.ttlInMinutes}</td>
								<td>{companyBalanceSecuritySettings?.approveCount}</td>
								<td>{companyBalanceSecuritySettings?.companyBalanceId}</td>
								<td width={"10%"}>
									<IconButton onClick={handleDeleteClick(companyBalanceSecuritySettings?.id)}>
										<DeleteIcon style={{ color: "#ff0000" }} />
									</IconButton>
									<IconButton onClick={handleEditClick(companyBalanceSecuritySettings)}>
										<EditIcon />
									</IconButton>
								</td>
							</tr>
						)
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</>
	);
};

export default CompanyBalanceSecuritySettings;

const useStyles = makeStyles((theme) => ({
	btnWrap: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		marginTop: "-70px",
		marginBottom: "20px"
	},
	paper: {
		display: "flex",
		alignItems: "center",
		padding: 8,
		paddingRight: 12,
		borderRadius: 8,
		width: "max-content",
		marginBottom: 16,
		"&:hover": {
			cursor: "pointer",
		},
	},
	icon: {
		color: theme.palette.primary.light,
		padding: 0,
		marginRight: 4,
		"& svg": {
			fontSize: 19,
		},
	},
	link: {
		color: theme.palette.primary.light,
		fontWeight: 700,
		textDecoration: "none",
	},
	hoverCopyTd: {
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.light,
		},
	},
}));
