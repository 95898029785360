import React, { useState } from "react";
import { Tabs, Tab, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import PageTitle from "../../../theme/components/PageTitle.jsx";
import OffersHistory from "./OffersHistory/OffersList.jsx";
import { LIQUIDITY_PROVIDERS_FILTER_FIELDS } from "../../../utils/constants/banking.js";

const tabs = {
	offers: "offers list",
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}>
			{value === index && <Box style={{ paddingTop: 24 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const LiquidityProviders = () => {
	const [activeTab, setActiveTab] = useState(0);

	const handleChangeTabs = (_, newTab) => {
		setActiveTab(newTab);
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<PageTitle
				title={"Liquidity Providers"}
				style={{ height: 100 }}
				filterFields={activeTab === 0 ? LIQUIDITY_PROVIDERS_FILTER_FIELDS : null}
			/>
			<Tabs
				value={activeTab}
				onChange={handleChangeTabs}
				aria-label='simple tabs example'
				indicatorColor='primary'>
				<Tab label={tabs.offers} {...a11yProps(0)} />
			</Tabs>
			<TabPanel value={activeTab} index={0}>
				<OffersHistory />
			</TabPanel>
		</div>
	);
};

export default LiquidityProviders;
