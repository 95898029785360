import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { useParams } from "react-router-dom";
import Table from "../../theme/components/Table/Table";
import CopyWithAlert from "../../utils/CopyWithAlert";
import { IconButton } from "@material-ui/core";
import {
	createCompanyBalanceUserForSecuritySettings,
	deleteCompanyBalanceUserOfSecuritySettings,
	getCompanyBalanceUsersOfSecuritySettings,
} from "../../app/actions/companiesActions";
import DeleteConfirmDialog from "../../theme/components/dialogs/DeleteConfirmDialog";
import { Button } from "../../theme/components/Button";
import AddUserToBalanceDialog from "./AddUserToBalanceDialog";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "userId", label: "User Id" },
	{ key: "securitySettingId", label: "Security Setting Id" },
	{ key: "manage", label: "Manage" },
];

const CompanyBalanceSecuritySettingsUsers = () => {
	const classes = useStyles();
	const routeParams = useParams();
	const dispatch = useDispatch();

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showAddSecuritySettingsUserDialog, setShowAddSecuritySettingsUserDialog] = useState(false);
	const [selectedBalanceSettingsUserId, setSelectedBalanceSettingsUserId] = useState(null);
	const companyBalanceSecuritySettings = useSelector(({ companies }) => companies.companyBalanceSecuritySettings);
	const companyBalanceSecuritySettingsUsers = useSelector(({ companies }) => companies.companyBalanceSecuritySettingsUsers)
	const balanceId = routeParams?.balanceId;

	useEffect(() => {
		if (companyBalanceSecuritySettings?.id) {
			dispatch(getCompanyBalanceUsersOfSecuritySettings(companyBalanceSecuritySettings?.id));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCloseDeleteModal = ({ accept } = {}) => {
		if (accept && selectedBalanceSettingsUserId) {
			dispatch(
				deleteCompanyBalanceUserOfSecuritySettings({ settingsId: companyBalanceSecuritySettings?.id, settingsUserId: selectedBalanceSettingsUserId })
			);
		}
		setSelectedBalanceSettingsUserId(null);
		setShowDeleteDialog(false);
		setShowAddSecuritySettingsUserDialog(false);
	};

	const handleCloseAddSettingsModal = () => {
		setSelectedBalanceSettingsUserId(null);
		setShowAddSecuritySettingsUserDialog(false);
	};

	const handleDeleteClick = (id) => () => {
		setSelectedBalanceSettingsUserId(id);
		setShowDeleteDialog(true);
	};

	const handleAddUser = () => {
		setShowAddSecuritySettingsUserDialog(true);
	};

	const handleAddUserToSecuritySettings = useCallback((formValues) => {
		dispatch(createCompanyBalanceUserForSecuritySettings({ settingsId: companyBalanceSecuritySettings?.id, userId: formValues?.userId }))
	}, [companyBalanceSecuritySettings?.id, dispatch])

	return (
		<>
			<DeleteConfirmDialog
				open={showDeleteDialog}
				title='Delete Security Settings User'
				message='Are you sure you want to delete selected user from company balance security settings?'
				handleClose={handleCloseDeleteModal}
			/>
			<AddUserToBalanceDialog
				open={showAddSecuritySettingsUserDialog}
				handleClose={handleCloseAddSettingsModal}
				balanceId={balanceId}
				onSubmit={handleAddUserToSecuritySettings}
			/>
			<div className={classes.btnWrap}>
				<Button variant='outlined' color='primary' onClick={handleAddUser}>
					<AddIcon />
					Add User to Settings
				</Button>
			</div>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }, index) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{companyBalanceSecuritySettingsUsers.length > 0 ? (
						companyBalanceSecuritySettingsUsers.map((rowData, index) => (
							<tr key={index}>
								<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
									<td className={classes.hoverCopyTd}>{rowData?.id}</td>
								</CopyWithAlert>
								<td>{rowData?.userId}</td>
								<td>{rowData?.securitySettingId}</td>
								<td width={"10%"}>
									<IconButton onClick={handleDeleteClick(rowData?.id)}>
										<DeleteIcon style={{ color: "#ff0000" }} />
									</IconButton>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</>
	);
};

export default CompanyBalanceSecuritySettingsUsers;

const useStyles = makeStyles((theme) => ({
	btnWrap: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		marginTop: "-70px",
		marginBottom: "20px"
	},
	paper: {
		display: "flex",
		alignItems: "center",
		padding: 8,
		paddingRight: 12,
		borderRadius: 8,
		width: "max-content",
		marginBottom: 16,
		"&:hover": {
			cursor: "pointer",
		},
	},
	icon: {
		color: theme.palette.primary.light,
		padding: 0,
		marginRight: 4,
		"& svg": {
			fontSize: 19,
		},
	},
	link: {
		color: theme.palette.primary.light,
		fontWeight: 700,
		textDecoration: "none",
	},
	hoverCopyTd: {
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.light,
		},
	},
}));
