export const BANKING_STATUS_FILTER = [
	{ label: "COMPLETED", value: "COMPLETED" },
	{ label: "PENDING", value: "PENDING" },
	{ label: "REJECTED", value: "REJECTED" },
	{ label: "PARTIALLY_FILLED", value: "PARTIALLY_FILLED" },
];

export const BANKING_HISTORY_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "ID",
		placeholder: "ID",
	},
	{
		field: "txId",
		type: "text",
		title: "Transaction ID",
		placeholder: "Transaction ID",
	},
	{ field: "userId", type: "number", title: "User ID", placeholder: "User ID" },

	{
		field: "paymentId",
		type: "text",
		title: "Payment Id",
		placeholder: "Payment Id",
	},
	{
		field: "status",
		type: "select",
		title: "Status",
		placeholder: "Status",
		data: BANKING_STATUS_FILTER,
	},
	{
		field: "creationTimeFrom",
		type: "date",
		title: "Creation date from",
		placeholder: "Creation date from",
	},
	{
		field: "creationTimeTo",
		type: "date",
		title: "Creation date to",
		placeholder: "Creation date to",
	},

	{
		field: "completeDateFrom",
		type: "date",
		title: "Complete date from",
		placeholder: "Complete date from",
	},
	{
		field: "completeTimeTo",
		type: "date",
		title: "Complete date to",
		placeholder: "Complete date to",
	},
];

export const BANKING_HISTORY_COLUMNS = [
	{ key: "id", headerName: "ID" },
	{ key: "txId", headerName: "Transaction ID" },
	{ key: "userId", headerName: "User ID" },
	{ key: "paymentId", headerName: "Payment ID" },
	{ key: "requestedAmount", headerName: "Requested Amount" },
	{ key: "executedAmount", headerName: "Executed Amount" },
	{ key: "creationTime", headerName: "Creation Time" },
	{ key: "completeTime", headerName: "Complete Time" },
	{ key: "status", headerName: "Status" },
];

export const BANKING_STATUS = {
	PENDING: "PENDING",
	REJECT: "REJECT",
	COMPLETE: "COMPLETE",
	PARTIALLY_FILLED: "PARTIALLY_FILLED",
};

export const BALANCES_COLUMNS = [
	{ field: "id", headerName: "ID", type: "copy" },
	{ field: "asset", headerName: "Asset", type: "text" },
	{ field: "quotable", headerName: "Quotable", type: "text" },
	{
		field: "assetCurrentBalance",
		headerName: "Asset Current Balance",
		type: "text",
	},
	{
		field: "assetTotalBalance",
		headerName: "Asset Total Balance",
		type: "text",
	},
	{
		field: "quotableFrozenBalance",
		headerName: "Quotable Frozen Balance",
		type: "text",
	},
	{
		field: "quotableCurrentBalance",
		headerName: "Quotable Current Balance",
		type: "text",
	},
	{
		field: "quotableTotalBalance",
		headerName: "Quotable Total Balance",
		type: "text",
	},
	{
		field: "price",
		headerName: "Exchange rate",
		type: "number",
	},
	{ field: "edit", headerName: "Manage", type: "manage" },
];

export const LIQUIDITY_PROVIDER_OFFER_STATUS_FILTER = [
	{ label: "NEW", value: "NEW" },
	{ label: "COMPLETED", value: "COMPLETED" },
	{ label: "WAITING FOR LIQUIDITY PROVIDER", value: "WAITING_FOR_LIQUIDITY_PROVIDER" },
	{ label: "EXPIRED", value: "EXPIRED" },
];

export const LIQUIDITY_PROVIDERS_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "ID",
		placeholder: "ID",
	},
	{
		field: "userId",
		type: "text",
		title: "User ID",
		placeholder: "User ID",
	},
	{
		field: "userCard",
		type: "text",
		title: "User Card",
		placeholder: "User Card",
	},
	{
		field: "cardIssuer",
		type: "text",
		title: "User Issuer",
		placeholder: "User Issuer",
	},
	{
		field: "providerId",
		type: "text",
		title: "Provider ID",
		placeholder: "Provider ID",
	},
	{
		field: "requestedAsset",
		type: "text",
		title: "Request Asset",
		placeholder: "Request Asset",
	},
	{
		field: "status",
		type: "select",
		title: "Status",
		placeholder: "Status",
		data: LIQUIDITY_PROVIDER_OFFER_STATUS_FILTER,
	},
	{
		field: "terminationTimeFrom",
		type: "date",
		title: "Termination time from",
		placeholder: "Termination time from",
	},
	{
		field: "terminationTimeTo",
		type: "date",
		title: "Termination time to",
		placeholder: "Termination time to",
	},
	{
		field: "completeDateFrom",
		type: "date",
		title: "Complete date from",
		placeholder: "Complete date from",
	},
	{
		field: "completeTimeTo",
		type: "date",
		title: "Complete date to",
		placeholder: "Complete date to",
	},
	{
		field: "txId",
		type: "text",
		title: "Transaction ID",
		placeholder: "Transaction ID",
	},
];
