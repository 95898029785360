import { makeStyles, Typography } from "@material-ui/core";
import Search from "./Search";
import Filter from "./Filter/index";
import { Button } from "./Button";
import AddIcon from "@material-ui/icons/Add";
import { useEffect, useRef, useState } from "react";
import ActiveFiltersPanel from "./Filter/ActiveFiltersPanel";

const PageTitle = ({
	title,
	renderTitle,
	onSearch,
	onCreate,
	onCustomButtonPress,
	customButtonLabel,
	filterFields,
	valueSearch,
}) => {
	const classes = useStyles();
	const timeout = useRef();
	const [search, setSearch] = useState("");

	useEffect(() => {
		setSearch(valueSearch || "");
	}, [valueSearch]);

	return (
		<>
			<div className={classes.root}>
				{renderTitle ?
					renderTitle?.() :
					<Typography variant={"h4"} color={"textPrimary"}>
						{title}
					</Typography>
				}
				<div style={{ display: "flex" }}>
					{onSearch && (
						<Search
							value={search}
							onChange={({ target }) => {
								setSearch(target.value);
								clearTimeout(timeout.current);
								timeout.current = setTimeout(() => onSearch(target.value), 500);
							}}
						/>
					)}
					{onCreate && (
						<Button
							variant='outlined'
							color='primary'
							style={{ marginLeft: 16, padding: "4px 28px" }}
							onClick={onCreate}>
							<AddIcon />
							Create
						</Button>
					)}
					{customButtonLabel && (
						<Button
							variant='outlined'
							color='primary'
							style={{ marginLeft: 16, padding: "4px 28px" }}
							onClick={onCustomButtonPress}>
							{customButtonLabel}
						</Button>
					)}
					{filterFields && <Filter filterFields={filterFields}></Filter>}
				</div>
			</div>
			{filterFields && (
				<ActiveFiltersPanel filterFields={filterFields}></ActiveFiltersPanel>
			)}
		</>
	);
};

export default PageTitle;

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 16,
		width: "calc(100vw - 100px)",
		height: 48,
		"&>.MuiTextField-root": {
			maxWidth: 256,
		},
	},
}));
