import {
	GET_SESSION_LOG_LIST_FAILURE,
	GET_SESSION_LOG_LIST_STARTED,
	GET_SESSION_LOG_LIST_SUCCESS,
	CLEAR_SESSION_LOG,
} from "../types";
import api from "../api";

export const getSessionLogListStarted = () => ({
	type: GET_SESSION_LOG_LIST_STARTED,
});

export const getSessionLogListSuccess = payload => ({
	type: GET_SESSION_LOG_LIST_SUCCESS,
	payload,
});

export const getSessionLogListFailure = error => ({
	type: GET_SESSION_LOG_LIST_FAILURE,
	payload: {
		error,
	},
});

export const getSessionLogList = params => {
	return async dispatch => {
		dispatch(getSessionLogListStarted());
		api.sessionLog
			.getList(params)
			.then(res => dispatch(getSessionLogListSuccess(res.data)))
			.catch(error => dispatch(getSessionLogListFailure(error)));
	};
};

export const clearSessionLog = () => ({
	type: CLEAR_SESSION_LOG,
});
