import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import PageTitle from "../../../theme/components/PageTitle";
import Table from "../../../theme/components/Table/Table";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import { getOwnersHistory } from "../../../app/actions/dhsActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../app/actions/filterActions";
import getStatusColor from "../../../utils/functions/getStatusColor";

const filterFields = [
	{ field: "id", type: "text", title: "Id", placeholder: "Id" },
	{
		field: "previousOwner",
		type: "text",
		title: "Previous owner",
		placeholder: "Previous owner",
	},
	{
		field: "newOwner",
		type: "text",
		title: "New owner",
		placeholder: "New owner",
	},

	{
		field: "timeWhenDeviceChangeOwnerFrom",
		type: "date",
		title: "Device transfer time from",
		placeholder: "Device transfer time from",
	},
	{
		field: "timeWhenDeviceChangeOwnerTo",
		type: "date",
		title: "Device transfer time to",
		placeholder: "Device transfer time to",
	},
	{ field: "status", type: "text", title: "Status", placeholder: "Status" },
];

const columns = [
	{ key: "id", label: "Id" },
	{ key: "previousOwner", label: "Previous owner" },
	{ key: "deviceId", label: "Device Id" },
	{ key: "newOwner", label: "New owner" },
	{ key: "activationCode", label: "Activation code" },
	{ key: "timeWhenDeviceChangeOwner", label: "Device transfer time" },
	{ key: "status", label: "Status" },
];

const OwnersHistory = () => {
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(
		({ dhs }) => dhs?.ownersHistory
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getOwnersHistory(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<PageTitle title={"Owners history"} filterFields={filterFields} />
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find(x => x.field === "size").value}
				page={activeFilters?.find(x => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default OwnersHistory;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.previousOwner + "" || ""}
					label={"Previous owner"}>
					<td data='hover'>{rowData?.previousOwner}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.deviceId + "" || ""} label={"Device Id"}>
					<td data='hover'>{rowData?.deviceId}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.newOwner + "" || ""} label={"New owner"}>
					<td data='hover'>{rowData?.newOwner}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.activationCode + "" || ""}
					label={"Activation code"}>
					<td data='hover'>{rowData?.activationCode}</td>
				</CopyWithAlert>
				<td>
					{rowData?.timeWhenDeviceChangeOwner &&
						moment(rowData?.timeWhenDeviceChangeOwner).format(
							"DD MMM YYYY HH:mm"
						)}
				</td>
				<CopyWithAlert text={rowData?.status + "" || ""} label={"Status"}>
					<td
						style={{
							color: rowData?.status && getStatusColor(rowData?.status),
						}}>
						{rowData?.status || "-"}
					</td>
				</CopyWithAlert>
			</tr>
		</>
	);
}
