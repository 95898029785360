import {
	GET_CURRENCIES_FAILURE,
	GET_CURRENCIES_STARTED,
	GET_CURRENCIES_SUCCESS,
	GET_WALLETS_LIST_FAILURE,
	GET_WALLETS_LIST_STARTED,
	GET_WALLETS_LIST_SUCCESS,
	SET_WALLETS_PARAMS,
	CLEAR_WALLETS,
} from "../types";
import api from "../api";

export const getWalletsListStarted = () => ({
	type: GET_WALLETS_LIST_STARTED,
});

export const getWalletsListSuccess = data => ({
	type: GET_WALLETS_LIST_SUCCESS,
	payload: {
		data,
	},
});

export const getWalletsListFailure = error => ({
	type: GET_WALLETS_LIST_FAILURE,
	payload: {
		error,
	},
});

export const getWalletsList = params => {
	return async dispatch => {
		dispatch(getWalletsListStarted());
		api.wallets
			.getList(params)
			.then(res => dispatch(getWalletsListSuccess(res.data)))
			.catch(error => dispatch(getWalletsListFailure(error)));
	};
};

export const setWalletsParams = params => ({
	type: SET_WALLETS_PARAMS,
	payload: {
		params,
	},
});

export const getCurrenciesStarted = () => ({
	type: GET_CURRENCIES_STARTED,
});

export const getCurrenciesSuccess = data => ({
	type: GET_CURRENCIES_SUCCESS,
	payload: {
		data,
	},
});

export const getCurrenciesFailure = error => ({
	type: GET_CURRENCIES_FAILURE,
	payload: {
		error,
	},
});

export const getCurrencies = params => {
	return async dispatch => {
		dispatch(getCurrenciesStarted());
		api.wallets
			.getCurrencies(params)
			.then(res => dispatch(getCurrenciesSuccess(res.data)))
			.catch(error => dispatch(getCurrenciesFailure(error)));
	};
};

export const clearWallets = () => ({
	type: CLEAR_WALLETS,
});
