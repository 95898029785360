import {MARKET} from "../types";
import api from "../api";

export const getMarketListStarted = () => ({
  type: MARKET.GET_MARKET_LIST_STARTED
});

export const getMarketListSuccess = (total, marketList) => ({
  type: MARKET.GET_MARKET_LIST_SUCCESS,
  payload: {
    total,
    marketList
  }
});

export const getMarketListFailure = error => ({
  type: MARKET.GET_MARKET_LIST_FAILURE,
  payload: {
    error
  }
});

export const getMarketList = () => {
  return async (dispatch) => {
    dispatch(getMarketListStarted());
    api.market.getMarketList()
      .then(res => dispatch(getMarketListSuccess(res.data?.total || 0, res.data?.balances || [])))
      .catch(error => dispatch(getMarketListFailure(error)));
  }
};
