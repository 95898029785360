import DialogWrapper from "../../theme/components/dialogs/DialogWrapper";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import { Button } from "../../theme/components/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requiredValidator } from "../../utils/customValidators/validators";
import { SelectInput } from "../../theme/components/Select";
import { getCurrencies } from "../../app/actions/walletsActions";
import { createCompanyBalance } from "../../app/actions/companiesActions";

const initialForm = {
	currency: "",
};

const CreateCompanyBalanceDialog = ({ open, handleClose, companyId }) => {
	const dispatch = useDispatch();
	const [form, setForm] = useState(initialForm);
	const [errors, setErrors] = useState({});
	const { currencies } = useSelector(({ wallets }) => wallets);

	useEffect(() => {
		dispatch(getCurrencies());
	}, [dispatch]);

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach((key) => {
			errForm = { ...errForm, [key]: requiredValidator(undefined, form[key]) };
			errForm[key].invalid && (valid = false);
		});

		setErrors(errForm);
		return valid;
	};

	const handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
		handleInputValidation(event);
	};

	const handleSave = () => {
		if (!validateForm()) {
			return;
		}
		if (form.currency) {
			console.log('form', form)
			dispatch(createCompanyBalance({ companyId, currency: form.currency}))
		}
		handleCloseDialog();
	};

	const handleInputValidation = (event) => {
		const { name, value } = event.target;
		const errorField = requiredValidator(undefined, value);
		setErrors({
			...errors,
			[name]: errorField,
		});
	};

	const handleCloseDialog = () => {
		setErrors({});
		setForm(initialForm);
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={handleCloseDialog}
			maxWidth={720}
			minWidth={375}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				Add Balance
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<SelectInput
						error={errors?.currency?.invalid}
						label='Currency'
						onChange={handleChange}
						value={form?.currency || ""}
						name={"currency"}
						onBlur={handleInputValidation}
						helperText={errors?.currency?.errorMessage}>
						{!!currencies &&
							currencies?.map((i, index) => (
								<MenuItem key={index} value={i?.ticker}>
									<div style={{ display: "flex", alignItems: "center" }}>
										<img
											src={`https://cryptoneed.com/icons/${i?.ticker}.svg`}
											alt={i?.ticker}
											width={16}
											height={16}
											style={{ marginRight: 12 }}
											loading={"lazy"}
										/>
										<p style={{ margin: 0 }}>{i?.ticker?.toUpperCase()}</p>
									</div>
								</MenuItem>
							))}
					</SelectInput>
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)", marginTop: 12 }}
							onClick={handleSave}>
							Add
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default CreateCompanyBalanceDialog;
