export const COMPANY_BALANCE_TRANSFERS_HISTORY_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "Id",
		placeholder: "Id",
	},
	{
		field: "targetAddress",
		type: "text",
		title: "Target Address",
		placeholder: "Target Address",
	},
	{
		field: "targetCurrency",
		type: "text",
		title: "Target Currency",
		placeholder: "Target Currency",
	},
	{
		field: "transactionType",
		type: "text",
		title: "Transaction Type",
		placeholder: "Transaction Type",
	},

	{
		field: "companyBalanceId",
		type: "text",
		title: "Company Balance Id",
		placeholder: "Company Balance Id",
	},
	{
		field: "dateFrom",
		type: "date",
		title: "Date from",
		placeholder: "Date from",
	},
	{
		field: "dateTo",
		type: "date",
		title: "Date to",
		placeholder: "Date to",
	},
];

export const COMPANY_BALANCE_TRANSFERS_INVOICES_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "Id",
		placeholder: "Id",
	},
	{
		field: "companyId",
		type: "text",
		title: "Company Id",
		placeholder: "Company Id",
	},
	{
		field: "targetUserId",
		type: "text",
		title: "Target User Id",
		placeholder: "Target User Id",
	},
	{
		field: "targetUserLogin",
		type: "text",
		title: "Target User Login",
		placeholder: "Target User Login",
	},
	{
		field: "targetAddress",
		type: "text",
		title: "Target Address",
		placeholder: "Target Address",
	},
	{
		field: "currency",
		type: "text",
		title: "Currency",
		placeholder: "Currency",
	},
	{
		field: "description",
		type: "text",
		title: "Description",
		placeholder: "Description",
	},
	{
		field: "creationTimeFrom",
		type: "date",
		title: "Creation Time From",
		placeholder: "Creation Time From",
	},
	{
		field: "creationTimeTo",
		type: "date",
		title: "Creation Time To",
		placeholder: "Creation Time To",
	},
	{
		field: "finishTimeFrom",
		type: "date",
		title: "Finish Time From",
		placeholder: "Finish Time From",
	},
	{
		field: "finishTimeTo",
		type: "date",
		title: "Finish Time To",
		placeholder: "Finish Time To",
	},
];

export const TRANSACTION_TYPES = {
	Withdrawal: 'Withdrawal',
	Deposit: 'Deposit',
}

export const COMPANY_REQUEST_STATUS = {
	PENDING: 'PENDING',
	APPROVED: "APPROVED",
	DECLINED: "DECLINED",
}
