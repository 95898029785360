// LOADING

import { generateActionTypes } from "./utils";

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

// LOGIN

export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_TWO_FA_SUCCESS = "LOGIN_TWO_FA_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOG_OUT = "LOG_OUT";

export const GET_ACCOUNT_DATA_STARTED = "GET_ACCOUNT_DATA_STARTED";
export const GET_ACCOUNT_DATA_SUCCESS = "GET_ACCOUNT_DATA_SUCCESS";
export const GET_ACCOUNT_DATA_FAILURE = "GET_ACCOUNT_DATA_FAILURE";

export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";
export const CHANGE_PASSWORD_STARTED = "CHANGE_PASSWORD_STARTED";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";

export const ENABLE_TWO_FA_STARTED = "ENABLE_TWO_FA_STARTED";
export const ENABLE_TWO_FA_SUCCESS = "ENABLE_TWO_FA_SUCCESS";
export const ENABLE_TWO_FA_FAILURE = "ENABLE_TWO_FA_FAILURE";

export const DISABLE_TWO_FA_STARTED = "DISABLE_TWO_FA_STARTED";
export const DISABLE_TWO_FA_SUCCESS = "DISABLE_TWO_FA_SUCCESS";
export const DISABLE_TWO_FA_FAILURE = "DISABLE_TWO_FA_FAILURE";

export const CHECK_IS_TWO_FA_STARTED = "CHECK_IS_TWO_FA_STARTED";
export const CHECK_IS_TWO_FA_SUCCESS = "CHECK_IS_TWO_FA_SUCCESS";
export const CHECK_IS_TWO_FA_FAILURE = "CHECK_IS_TWO_FA_FAILURE";

export const CHECK_CODE_TWO_FA_STARTED = "CHECK_CODE_TWO_FA_STARTED";
export const CHECK_CODE_TWO_FA_SUCCESS = "CHECK_CODE_TWO_FA_SUCCESS";
export const CHECK_CODE_TWO_FA_FAILURE = "CHECK_CODE_TWO_FA_FAILURE";

export const CHECK_INSTALL_TWO_FA_STARTED = "CHECK_INSTALL_TWO_FA_STARTED";
export const CHECK_INSTALL_TWO_FA_SUCCESS = "CHECK_INSTALL_TWO_FA_SUCCESS";
export const CHECK_INSTALL_TWO_FA_FAILURE = "CHECK_INSTALL_TWO_FA_FAILURE";

export const CLEAR_TWO_FA_QR_CODE = "CLEAR_TWO_FA_QR_CODE";

export const GET_WHOAMI_STARTED = "GET_WHOAMI_STARTED";
export const GET_WHOAMI_SUCCESS = "GET_WHOAMI_SUCCESS";
export const GET_WHOAMI_FAILURE = "GET_WHOAMI_FAILURE";

export const SEND_REGISTRATION_MESSAGE_STARTED =
	"SEND_REGISTRATION_MESSAGE_STARTED";
export const SEND_REGISTRATION_MESSAGE_SUCCESS =
	"SEND_REGISTRATION_MESSAGE_SUCCESS";
export const SEND_REGISTRATION_MESSAGE_FAILURE =
	"SEND_REGISTRATION_MESSAGE_FAILURE";

// USERS

export const GET_USERS_STARTED = "GET_USERS_STARTED";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_PENDING_ACTIVATION_USERS_STARTED =
	"GET_UPENDING_ACTIVATION_SERS_STARTED";
export const GET_PENDING_ACTIVATION_USERS_SUCCESS =
	"GET_PENDING_ACTIVATION_USERS_SUCCESS";
export const GET_PENDING_ACTIVATION_USERS_FAILURE =
	"GET_PENDING_ACTIVATION_USERS_FAILURE";

export const SET_USERS_PARAMS = "SET_USERS_PARAMS";

export const GET_PROFILE_BY_ID_STARTED = "GET_PROFILE_BY_ID_STARTED";
export const GET_PROFILE_BY_ID_SUCCESS = "GET_PROFILE_BY_ID_SUCCESS";
export const GET_PROFILE_BY_ID_FAILURE = "GET_PROFILE_BY_ID_FAILURE";

export const GET_ROLES_STARTED = "GET_ROLES_STARTED";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

export const CLEAR_USERS = "CLEAR_USERS";

export const GET_USER = "GET_USER";

export const ADD_USER_VIP_STATUS = generateActionTypes("ADD_USER_VIP_STATUS");
export const DELETE_USER_VIP_STATUS = generateActionTypes("DELETE_USER_VIP_STATUS");
export const ROLLBACK_USER_BLOCK_HIMSELF = generateActionTypes("ROLLBACK_USER_BLOCK_HIMSELF");

// ADMINS

export const GET_ADMINS_STARTED = "GET_ADMINS_STARTED";
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS";
export const GET_ADMINS_FAILURE = "GET_ADMINS_FAILURE";

export const EDIT_ADMIN_STARTED = "EDIT_ADMIN_STARTED";
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS";
export const EDIT_ADMIN_FAILURE = "EDIT_ADMIN_FAILURE";

export const CREATE_ADMIN_STARTED = "CREATE_ADMIN_STARTED";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAILURE = "CREATE_ADMIN_FAILURE";

export const SET_ADMINS_PARAMS = "SET_ADMINS_PARAMS";

export const CLEAR_ADMINS = "CLEAR_ADMINS";

// STATISTIC

export const GET_STATISTIC = generateActionTypes("GET_STATISTIC");

export const GET_NET_PROFIT = generateActionTypes("GET_NET_PROFIT");

export const CLEAR_STATISTIC_STATUS = "CLEAR_STATISTIC_STATUS";
export const CLEAR_STATISTIC_ERROR = "CLEAR_STATISTIC_ERROR";

// TRANSACTIONS

export const GET_TRANSACTIONS_STARTED = "GET_TRANSACTIONS_STARTED";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILURE = "GET_TRANSACTIONS_FAILURE";
export const SET_TRANSACTIONS_PARAMS = "SET_TRANSACTIONS_PARAMS";
export const CLEAR_TRANSACTIONS = "CLEAR_TRANSACTIONS";

// INTERNAL_BALANCES

export const GET_INTERNAL_BALANCES_STARTED = "GET_INTERNAL_BALANCES_STARTED";
export const GET_INTERNAL_BALANCES_SUCCESS = "GET_INTERNAL_BALANCES_SUCCESS";
export const GET_INTERNAL_BALANCES_FAILURE = "GET_INTERNAL_BALANCES_FAILURE";
export const SET_INTERNAL_BALANCES_PARAMS = "SET_INTERNAL_BALANCES_PARAMS";
export const CLEAR_INTERNAL_BALANCES = "CLEAR_INTERNAL_BALANCES";

export const SORT_INTERNAL_BALANCES_LIST = "SORT_INTERNAL_BALANCES_LIST";

export const FILTER_INTERNAL_BALANCES_LIST = "FILTER_INTERNAL_BALANCES_LIST";

//EXCHANGE

export const GET_EXCHANGE_STARTED = "GET_EXCHANGE_STARTED";
export const GET_EXCHANGE_SUCCESS = "GET_EXCHANGE_SUCCESS";
export const GET_EXCHANGE_FAILURE = "GET_EXCHANGE_FAILURE";
export const GET_INTERNAL_EXCHANGE_STARTED = "GET_INTERNAL_EXCHANGE_STARTED";
export const GET_INTERNAL_EXCHANGE_SUCCESS = "GET_INTERNAL_EXCHANGE_SUCCESS";
export const GET_INTERNAL_EXCHANGE_FAILURE = "GET_INTERNAL_EXCHANGE_FAILURE";
export const GET_INTERNAL_EXCHANGE_ID_STARTED =
	"GET_INTERNAL_EXCHANGE_ID_STARTED";
export const GET_INTERNAL_EXCHANGE_ID_SUCCESS =
	"GET_INTERNAL_EXCHANGE_ID_SUCCESS";
export const GET_INTERNAL_EXCHANGE_ID_FAILURE =
	"GET_INTERNAL_EXCHANGE_ID_FAILURE";

export const SET_EXCHANGE_PARAMS = "SET_EXCHANGE_PARAMS";
export const CLEAR_EXCHANGE = "CLEAR_EXCHANGE";

// GET_TRANSFERS_TO_MASTER
export const GET_TRANSFERS_TO_MASTER_WALLET_STARTED =
	"GET_TRANSFERS_TO_MASTER_WALLET_STARTED";
export const GET_TRANSFERS_TO_MASTER_WALLET_SUCCESS =
	"GET_TRANSFERS_TO_MASTER_WALLET_SUCCESS";
export const GET_TRANSFERS_TO_MASTER_WALLET_FAILURE =
	"GET_TRANSFERS_TO_MASTER_WALLET_FAILURE";

// SETTINGS

export const GET_SETTINGS_EXCHANGES_STARTED = "GET_SETTINGS_EXCHANGES_STARTED";
export const GET_SETTINGS_EXCHANGES_SUCCESS = "GET_SETTINGS_EXCHANGES_SUCCESS";
export const GET_SETTINGS_EXCHANGES_FAILURE = "GET_SETTINGS_EXCHANGES_FAILURE";

export const DELETE_SETTING_EXCHANGE_STARTED =
	"DELETE_SETTING_EXCHANGE_STARTED";
export const DELETE_SETTING_EXCHANGE_SUCCESS =
	"DELETE_SETTING_EXCHANGE_SUCCESS";
export const DELETE_SETTING_EXCHANGE_FAILURE =
	"DELETE_SETTING_EXCHANGE_FAILURE";

export const CREATE_SETTING_EXCHANGE_STARTED =
	"CREATE_SETTING_EXCHANGE_STARTED";
export const CREATE_SETTING_EXCHANGE_SUCCESS =
	"CREATE_SETTING_EXCHANGE_SUCCESS";
export const CREATE_SETTING_EXCHANGE_FAILURE =
	"CREATE_SETTING_EXCHANGE_FAILURE";

export const UPDATE_SETTING_EXCHANGE_STARTED =
	"UPDATE_SETTING_EXCHANGE_STARTED";
export const UPDATE_SETTING_EXCHANGE_SUCCESS =
	"UPDATE_SETTING_EXCHANGE_SUCCESS";
export const UPDATE_SETTING_EXCHANGE_FAILURE =
	"UPDATE_SETTING_EXCHANGE_FAILURE";

export const GET_SETTINGS_WITHDRAWS_STARTED = "GET_SETTINGS_WITHDRAWS_STARTED";
export const GET_SETTINGS_WITHDRAWS_SUCCESS = "GET_SETTINGS_WITHDRAWS_SUCCESS";
export const GET_SETTINGS_WITHDRAWS_FAILURE = "GET_SETTINGS_WITHDRAWS_FAILURE";

export const DELETE_SETTING_WITHDRAW_STARTED =
	"DELETE_SETTING_WITHDRAW_STARTED";
export const DELETE_SETTING_WITHDRAW_SUCCESS =
	"DELETE_SETTING_WITHDRAW_SUCCESS";
export const DELETE_SETTING_WITHDRAW_FAILURE =
	"DELETE_SETTING_WITHDRAW_FAILURE";

export const CREATE_SETTING_WITHDRAW_STARTED =
	"CREATE_SETTING_WITHDRAW_STARTED";
export const CREATE_SETTING_WITHDRAW_SUCCESS =
	"CREATE_SETTING_WITHDRAW_SUCCESS";
export const CREATE_SETTING_WITHDRAW_FAILURE =
	"CREATE_SETTING_WITHDRAW_FAILURE";

export const UPDATE_SETTING_WITHDRAW_STARTED =
	"UPDATE_SETTING_WITHDRAW_STARTED";
export const UPDATE_SETTING_WITHDRAW_SUCCESS =
	"UPDATE_SETTING_WITHDRAW_SUCCESS";
export const UPDATE_SETTING_WITHDRAW_FAILURE =
	"UPDATE_SETTING_WITHDRAW_FAILURE";

export const GET_SETTINGS_DEPOSITS_STARTED = "GET_SETTINGS_DEPOSITS_STARTED";
export const GET_SETTINGS_DEPOSITS_SUCCESS = "GET_SETTINGS_DEPOSITS_SUCCESS";
export const GET_SETTINGS_DEPOSITS_FAILURE = "GET_SETTINGS_DEPOSITS_FAILURE";

export const DELETE_SETTING_DEPOSIT_STARTED = "DELETE_SETTING_DEPOSIT_STARTED";
export const DELETE_SETTING_DEPOSIT_SUCCESS = "DELETE_SETTING_DEPOSIT_SUCCESS";
export const DELETE_SETTING_DEPOSIT_FAILURE = "DELETE_SETTING_DEPOSIT_FAILURE";

export const CREATE_SETTING_DEPOSIT_STARTED = "CREATE_SETTING_DEPOSIT_STARTED";
export const CREATE_SETTING_DEPOSIT_SUCCESS = "CREATE_SETTING_DEPOSIT_SUCCESS";
export const CREATE_SETTING_DEPOSIT_FAILURE = "CREATE_SETTING_DEPOSIT_FAILURE";

export const UPDATE_SETTING_DEPOSIT_STARTED = "UPDATE_SETTING_DEPOSIT_STARTED";
export const UPDATE_SETTING_DEPOSIT_SUCCESS = "UPDATE_SETTING_DEPOSIT_SUCCESS";
export const UPDATE_SETTING_DEPOSIT_FAILURE = "UPDATE_SETTING_DEPOSIT_FAILURE";

export const CLEAR_TRANSACTIONS_SETTINGS = "CLEAR_TRANSACTIONS_SETTINGS";
export const CLEAR_STATUS_SETTINGS = "CLEAR_STATUS_SETTINGS";
export const CLEAR_ERROR_SETTINGS = "CLEAR_ERROR_SETTINGS";

export const GET_MAX_PRECISION_STARTED = "GET_MAX_PRECISION_STARTED";
export const GET_MAX_PRECISION_SUCCESS = "GET_MAX_PRECISION_SUCCESS";
export const GET_MAX_PRECISION_FAILURE = "GET_MAX_PRECISION_FAILURE";

// BLOCKING

export const GET_USERS_BLOCKERS_STARTED = "GET_USERS_BLOCKERS_STARTED";
export const GET_USERS_BLOCKERS_SUCCESS = "GET_USERS_BLOCKERS_SUCCESS";
export const GET_USERS_BLOCKERS_FAILURE = "GET_USERS_BLOCKERS_FAILURE";

export const SET_USER_BLOCKERS_STARTED = "SET_USER_BLOCKERS_STARTED";
export const SET_USER_BLOCKERS_SUCCESS = "SET_USER_BLOCKERS_SUCCESS";
export const SET_USER_BLOCKERS_FAILURE = "SET_USER_BLOCKERS_FAILURE";

export const GET_BLOCKERS_BY_ID_STARTED = "GET_BLOCKERS_BY_ID_STARTED";
export const GET_BLOCKERS_BY_ID_SUCCESS = "GET_BLOCKERS_BY_ID_SUCCESS";
export const GET_BLOCKERS_BY_ID_FAILURE = "GET_BLOCKERS_BY_ID_FAILURE";

// ADDRESSES

export const GET_ADDRESSES_STARTED = "GET_ADDRESSES_STARTED";
export const GET_ADDRESSES_SUCCESS = "GET_ADDRESSES_SUCCESS";
export const GET_ADDRESSES_FAILURE = "GET_ADDRESSES_FAILURE";

// COINS
export const GET_NODE_DETAILS_STARTED = "GET_NODE_DETAILS_STARTED";
export const GET_NODE_DETAILS_SUCCESS = "GET_NODE_DETAILS_SUCCESS";
export const GET_NODE_DETAILS_FAILURE = "GET_NODE_DETAILS_FAILURE";

export const CLEAR_NODE_DETAILS = "CLEAR_NODE_DETAILS";

export const GET_WALLETS_TOTAL_STARTED = "GET_WALLETS_TOTAL_STARTED";
export const GET_WALLETS_TOTAL_SUCCESS = "GET_WALLETS_TOTAL_SUCCESS";
export const GET_WALLETS_TOTAL_FAILURE = "GET_WALLETS_TOTAL_FAILURE";

export const SORT_NODE_PAYMENT_LIST = "SORT_NODE_PAYMENT_LIST";
export const FILTER_NODE_PAYMENT_LIST = "FILTER_NODE_PAYMENT_LIST";

export const GET_INFO_NODE_BLOCKS_TRON_STARTED =
	"GET_INFO_NODE_BLOCKS_TRON_STARTED";
export const GET_INFO_NODE_BLOCKS_TRON_SUCCESS =
	"GET_INFO_NODE_BLOCKS_TRON_SUCCESS";
export const GET_INFO_NODE_BLOCKS_TRON_FAILURE =
	"GET_INFO_NODE_BLOCKS_TRON_FAILURE";

export const GET_INFO_NODE_BLOCKS_ETHEREUM_STARTED =
	"GET_INFO_NODE_BLOCKS_ETHEREUM_STARTED";
export const GET_INFO_NODE_BLOCKS_ETHEREUM_SUCCESS =
	"GET_INFO_NODE_BLOCKS_ETHEREUM_SUCCESS";
export const GET_INFO_NODE_BLOCKS_ETHEREUM_FAILURE =
	"GET_INFO_NODE_BLOCKS_ETHEREUM_FAILURE";

// BALANCES

export const GET_BALANCES_STARTED = "GET_BALANCES_STARTED";
export const GET_BALANCES_SUCCESS = "GET_BALANCES_SUCCESS";
export const GET_BALANCES_FAILURE = "GET_BALANCES_FAILURE";
export const FILTER_BINANCE_BALANCES = "FILTER_BINANCE_BALANCES";
export const SORT_BINANCE_BALANCES = "SORT_BINANCE_BALANCES";

export const SET_KUNA_PARAMS = "SET_KUNA_PARAMS";

export const GET_KUNA_BALANCE = generateActionTypes("GET_KUNA_BALANCE");
export const SORT_KUNA_BALANCE = "SORT_KUNA_BALANCE";
export const FILTER_KUNA_BALANCE = "FILTER_KUNA_BALANCE";

export const GET_KUNA_DEPOSITS_STARTED = "GET_KUNA_DEPOSITS_STARTED";
export const GET_KUNA_DEPOSITS_SUCCESS = "GET_KUNA_DEPOSITS_SUCCESS";
export const GET_KUNA_DEPOSITS_FAILURE = "GET_KUNA_DEPOSITS_FAILURE";

export const GET_KUNA_WITHDRAWAL_STARTED = "GET_KUNA_WITHDRAWAL_STARTED";
export const GET_KUNA_WITHDRAWAL_SUCCESS = "GET_KUNA_WITHDRAWAL_SUCCESS";
export const GET_KUNA_WITHDRAWAL_FAILURE = "GET_KUNA_WITHDRAWAL_FAILURE";

export const CLEAR_KUNA_WITHDRAWAL = "CLEAR_KUNA_WITHDRAWAL";
export const CLEAR_KUNA_DEPOSITS = "CLEAR_KUNA_DEPOSITS";

// ACTION_LOG

export const GET_ACTION_LOG_LIST_STARTED = "GET_ACTION_LOG_LIST_STARTED";
export const GET_ACTION_LOG_LIST_SUCCESS = "GET_ACTION_LOG_LIST_SUCCESS";
export const GET_ACTION_LOG_LIST_FAILURE = "GET_ACTION_LOG_LIST_FAILURE";

export const GET_ACTION_LOG_BY_ID_STARTED = "GET_ACTION_LOG_BY_ID_STARTED";
export const GET_ACTION_LOG_BY_ID_SUCCESS = "GET_ACTION_LOG_BY_ID_SUCCESS";
export const GET_ACTION_LOG_BY_ID_FAILURE = "GET_ACTION_LOG_BY_ID_FAILURE";

// SESSION_LOG

export const GET_SESSION_LOG_LIST_STARTED = "GET_SESSION_LOG_LIST_STARTED";
export const GET_SESSION_LOG_LIST_SUCCESS = "GET_SESSION_LOG_LIST_SUCCESS";
export const GET_SESSION_LOG_LIST_FAILURE = "GET_SESSION_LOG_LIST_FAILURE";

export const CLEAR_SESSION_LOG = "CLEAR_SESSION_LOG";

// FILTER

export const SET_ACTIVE_FILTER = "SET_ACTIVE_FILTER";
export const SET_ACTIVE_FILTER_PAGE = "SET_ACTIVE_FILTER_PAGE";
export const SET_ACTIVE_FILTER_SIZE = "SET_ACTIVE_FILTER_SIZE";
export const DELETE_ACTIVE_FILTER = "DELETE_ACTIVE_FILTER";
export const CLEAR_ACTIVE_FILTERS = "CLEAR_ACTIVE_FILTERS";

// WALLETS

export const GET_WALLETS_LIST_STARTED = "GET_WALLETS_LIST_STARTED";
export const GET_WALLETS_LIST_SUCCESS = "GET_WALLETS_LIST_SUCCESS";
export const GET_WALLETS_LIST_FAILURE = "GET_WALLETS_LIST_FAILURE";

export const SORT_WALLETS_LIST = "SORT_WALLETS_LIST";

export const FILTER_WALLETS_LIST = "FILTER_WALLETS_LIST";

export const SET_WALLETS_PARAMS = "SET_WALLETS_PARAMS";

export const GET_CURRENCIES_STARTED = "GET_CURRENCIES_STARTED";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";
export const GET_CURRENCIES_FAILURE = "GET_CURRENCIES_FAILURE";

export const CREATE_NEW_WALLET_STARTED = "CREATE_NEW_WALLET_STARTED";
export const CREATE_NEW_WALLET_SUCCESS = "CREATE_NEW_WALLET_SUCCESS";
export const CREATE_NEW_WALLET_FAILURE = "CREATE_NEW_WALLET_FAILURE";

export const WITHDRAW_STARTED = "WITHDRAW_STARTED";
export const WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS";
export const WITHDRAW_FAILURE = "WITHDRAW_FAILURE";

export const GET_WALLET_FEE_STARTED = "GET_WALLET_FEE_STARTED";
export const GET_WALLET_FEE_SUCCESS = "GET_WALLET_FEE_SUCCESS";
export const GET_WALLET_FEE_FAILURE = "GET_WALLET_FEE_FAILURE";

export const CHECK_INTERNAL_ADDRESS_SUCCESS = "CHECK_INTERNAL_ADDRESS_SUCCESS";
export const CHECK_INTERNAL_ADDRESS_FAILURE = "CHECK_INTERNAL_ADDRESS_FAILURE";

export const CLEAR_WALLETS = "CLEAR_WALLETS";

// MARKET

export const MARKET = {
	GET_MARKET_LIST_STARTED: "GET_MARKET_LIST_STARTED",
	GET_MARKET_LIST_SUCCESS: "GET_MARKET_LIST_SUCCESS",
	GET_MARKET_LIST_FAILURE: "GET_MARKET_LIST_FAILURE",
};

// USERS BALANCES

export const GET_USERS_BALANCES_BY_COIN_STARTED =
	"GET_USERS_BALANCES_BY_COIN_STARTED";
export const GET_USERS_BALANCES_BY_COIN_SUCCESS =
	"GET_USERS_BALANCES_BY_COIN_SUCCESS";
export const GET_USERS_BALANCES_BY_COIN_FAILURE =
	"GET_USERS_BALANCES_BY_COIN_FAILURE";

// SYNCHRONIZATION
// statistics
export const GET_SYNC_STATISTICS = generateActionTypes("GET_SYNC_STATISTICS");

// withdrawal history
export const GET_WITHDRAWAL_HISTORY = generateActionTypes(
	"GET_WITHDRAWAL_HISTORY"
);

// sync history
export const GET_SYSTEM_SYNC_HISTORY = generateActionTypes(
	"GET_SYSTEM_SYNC_HISTORY"
);

export const GET_USER_SYNC_HISTORY = generateActionTypes(
	"GET_SYSTEM_SYNC_HISTORY"
);

// order history
export const GET_BINANCE_ORDER_HISTORY = generateActionTypes(
	"GET_BINANCE_ORDER_HISTORY"
);

export const GET_KUNA_ORDER_HISTORY = generateActionTypes(
	"GET_KUNA_ORDER_HISTORY"
);

// auto_sync_balance

export const GET_SYNC_LIST_SETTINGS = generateActionTypes(
	"GET_SYNC_LIST_SETTINGS"
);
export const GET_SYNC_ITEM_SETTINGS = generateActionTypes(
	"GET_SYNC_ITEM_SETTINGS"
);
export const CREATE_SYNC_ITEM_SETTINGS = generateActionTypes(
	"CREATE_SYNC_ITEM_SETTINGS"
);
export const EDIT_SYNC_ITEM_SETTINGS = generateActionTypes(
	"EDIT_SYNC_ITEM_SETTINGS"
);

export const CLEAR_SYNC_HISTORY = "CLEAR_SYNC_HISTORY";
export const CLEAR_SYNC_STATUS = "CLEAR_SYNC_STATUS";
export const CLEAR_SYNC_ERROR = "CLEAR_SYNC_ERROR";

// DHS

export const GET_PROGRAM_PENDING_CONFIRMATION = generateActionTypes(
	"GET_PROGRAM_PENDING_CONFIRMATION"
);
export const GET_TOTAL_SHARES = generateActionTypes("GET_TOTAL_SHARES");
export const GET_TOTAL_SHARES_USER = generateActionTypes(
	"GET_TOTAL_SHARES_USER"
);
export const GET_DEVICES = generateActionTypes("GET_DEVICES");
export const CREATE_DEVICE = generateActionTypes("CREATE_DEVICE");
export const EDIT_DEVICE = generateActionTypes("EDIT_DEVICE");
export const DELETE_DEVICE = generateActionTypes("DELETE_DEVICE");
export const GET_SERVICE_ACCOUNT_BALANCES = generateActionTypes(
	"GET_SERVICE_ACCOUNT_BALANCES"
);
export const GET_DISTRIBUTION_HISTORY = generateActionTypes(
	"GET_DISTRIBUTION_HISTORY"
);
export const GET_CURRENT_SHARES = generateActionTypes("GET_CURRENT_SHARES");
export const CALCULATE_CURRENT_SHARES = generateActionTypes(
	"CALCULATE_CURRENT_SHARES"
);
export const SET_SHARES_DISTRIBUTION_FORCEFULLY = generateActionTypes(
	"SET_SHARES_DISTRIBUTION_FORCEFULLY"
);
export const GET_HISTORY_USED_PROGRAMS = generateActionTypes(
	"GET_HISTORY_USED_PROGRAMS"
);
export const PROGRAM_START = generateActionTypes("PROGRAM_START");
export const GET_OWNERS_HISTORY = generateActionTypes("GET_OWNERS_HISTORY");

export const CLEAR_DHS_STATUS = "CLEAR_DHS_STATUS";
export const CLEAR_DHS_ERROR = "CLEAR_DHS_ERROR";
export const FILTER_TOTAL_SHARES = "FILTER_TOTAL_SHARES";

// KYC

export const GET_KYC_BY_ID = generateActionTypes("GET_KYC_BY_ID");

export const GET_USER_KYC_HISTORY = generateActionTypes("GET_USER_KYC_HISTORY");

export const GET_DOCUMENT_FILE = generateActionTypes("GET_DOCUMENT_FILE");

export const MAKE_DECISION_USER_KYC = generateActionTypes(
	"MAKE_DECISION_USER_KYC"
);
export const DELETE_USER_KYC_BLOCK = generateActionTypes(
	"DELETE_USER_KYC_BLOCK"
);
export const GET_KYC_HISTORY = generateActionTypes("GET_KYC_HISTORY");

export const CLEAR_KYC_HISTORY = "CLEAR_KYC_HISTORY";

export const CLEAR_KYC_USER_DETAILS = "CLEAR_KYC_USER_DETAILS";

export const CLEAR_KYC_STATUS = "CLEAR_KYC_STATUS";

export const CLEAR_KYC_ERROR = "CLEAR_KYC_ERROR";


// BANK CARDS LIST
export const GET_ALL_BANK_CARDS_LIST = generateActionTypes("GET_ALL_BANK_CARDS_LIST");
export const GET_BANK_CARD_DETAILS_BY_ID = generateActionTypes("GET_BANK_CARD_DETAILS_BY_ID");
export const GET_CARD_PHOTO_FILE = generateActionTypes("GET_CARD_PHOTO_FILE");
export const MAKE_DECISION_BY_CARD = generateActionTypes(
	"MAKE_DECISION_BY_CARD"
);
export const CLEAR_BANK_CARDS_LIST = "CLEAR_BANK_CARDS_LIST";
export const CLEAR_BANK_CARD_DETAILS = "CLEAR_BANK_CARD_DETAILS";
export const CLEAR_BANK_CARDS_ERROR = "CLEAR_BANK_CARDS_ERROR";
export const CLEAR_BANK_CARDS_STATUS = "CLEAR_BANK_CARDS_ERROR";

// BANKING
export const GET_BANKING_HISTORY = generateActionTypes("GET_BANKING_HISTORY");
export const GET_PROVIDERS = generateActionTypes("GET_PROVIDERS");
export const CREATE_NEW_PROVIDER = generateActionTypes("CREATE_NEW_PROVIDER");
export const UPDATE_PROVIDER = generateActionTypes("UPDATE_PROVIDER");
export const DELETE_PROVIDER = generateActionTypes("DELETE_PROVIDER");
export const TRANSFER_FROM_BANKING_BALANCE_TO_WALLET = generateActionTypes(
	"TRANSFER_FROM_BANKING_BALANCE_TO_WALLET"
);
export const GET_ALL_BANKING_BALANCES = generateActionTypes(
	"GET_ALL_BANKING_BALANCES"
);
export const UPDATE_ALL_BANKING_BALANCES = generateActionTypes(
	"UPDATE_ALL_BANKING_BALANCES"
);

export const UPDATE_BANKING_SETTINGS = generateActionTypes(
	"UPDATE_BANKING_SETTINGS"
);
export const GET_ALL_BANKING_SETTINGS = generateActionTypes(
	"GET_ALL_BANKING_SETTINGS"
);
export const MAKE_DECISION_BY_PENDING_TRANSACTION = generateActionTypes(
	"MAKE_DECISION_BY_PENDING_TRANSACTION"
);
export const GET_LIQUIDITY_PROVIDERS_SETTINGS = generateActionTypes("GET_LIQUIDITY_PROVIDERS_SETTINGS");
export const CREATE_LIQUIDITY_PROVIDER_SETTINGS = generateActionTypes("CREATE_LIQUIDITY_PROVIDER_SETTINGS");
export const UPDATE_LIQUIDITY_PROVIDER_SETTINGS = generateActionTypes("UPDATE_LIQUIDITY_PROVIDER_SETTINGS");
export const DELETE_LIQUIDITY_PROVIDER_SETTINGS = generateActionTypes("DELETE_LIQUIDITY_PROVIDER_SETTINGS");
export const GET_LIQUIDITY_PROVIDERS_OFFERS = generateActionTypes("GET_LIQUIDITY_PROVIDERS_OFFERS");

export const CLEAR_BANKING_STATUS = "CLEAR_BANKING_STATUS";

export const CLEAR_BANKING_ERROR = "CLEAR_BANKING_ERROR";

// CAPITAL

//  FUNDS
export const CREATE_STABLE_INVESTMENT_FUND = generateActionTypes(
	"CREATE_STABLE_INVESTMENT_FUND"
);
export const CREATE_FLOATING_INVESTMENT_FUND = generateActionTypes(
	"CREATE_FLOATING_INVESTMENT_FUND"
);
export const UPDATE_STABLE_INVESTMENT_FUND = generateActionTypes(
	"UPDATE_STABLE_INVESTMENT_FUND"
);
export const UPDATE_FLOATING_INVESTMENT_FUND = generateActionTypes(
	"UPDATE_FLOATING_INVESTMENT_FUND"
);
export const DELETE_INVESTMENT_FUND = generateActionTypes(
	"DELETE_INVESTMENT_FUND"
);
export const SEARCH_INVESTMENT_FUNDS = generateActionTypes(
	"SEARCH_INVESTMENT_FUNDS"
);
export const GET_TOKEN_PRICES = generateActionTypes("GET_TOKEN_PRICES");
export const SEARCH_SELL_INVOICES = generateActionTypes("SEARCH_SELL_INVOICES");
export const MAKE_DECISION_BY_INVOICE = generateActionTypes(
	"MAKE_DECISION_BY_INVOICE"
);
export const SEARCH_FUND_OPERATIONS_HISTORY = generateActionTypes(
	"SEARCH_FUND_OPERATIONS_HISTORY"
);
export const SEARCH_USER_FUND_BALANCES = generateActionTypes(
	"SEARCH_USER_FUND_BALANCES"
);
export const GET_SYSTEM_BALANCES_FUNDS = generateActionTypes(
	"GET_SYSTEM_BALANCES_FUNDS"
);

export const GET_ESTIMATE_DIVIDENDS_INFO = generateActionTypes(
	"GET_ESTIMATE_DIVIDENDS_INFO"
);
export const SEND_FROM_SYSTEM_BALANCE_TO_WALLET_BY_ADDRESS =
	generateActionTypes("SEND_FROM_SYSTEM_BALANCE_TO_WALLET_BY_ADDRESS");
export const SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_FUND_TO_WALLET =
	generateActionTypes(
		"SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_FUND_TO_WALLET"
	);

export const DISTRIBUTE_DIVIDENDS = generateActionTypes("DISTRIBUTE_DIVIDENDS");
export const SEARCH_DIVIDENDS_DISTRIBUTION_PROGRAMS = generateActionTypes(
	"SEARCH_DIVIDENDS_DISTRIBUTION_PROGRAMS"
);
export const SEARCH_DIVIDENDS = generateActionTypes("SEARCH_DIVIDENDS");

export const CLEAR_FUNDS_SYSTEM_BALANCES = "CLEAR_FUNDS_SYSTEM_BALANCES";

export const CLEAR_USER_FUND_BALANCES = "CLEAR_USER_FUND_BALANCES";

export const CLEAR_TRANSFERS_SYSTEM_BALANCE_FUND =
	"CLEAR_TRANSFERS_SYSTEM_BALANCE_FUND";

export const CLEAR_FUNDS_OPERATIONS_HISTORY = "CLEAR_FUNDS_OPERATIONS_HISTORY";

export const CLEAR_FUNDS_STATUS = "CLEAR_FUNDS_STATUS";

export const CLEAR_FUNDS_ERROR = "CLEAR_FUNDS_ERROR";

export const GET_AVAILABLE_VIP_FUNDS = generateActionTypes("GET_AVAILABLE_VIP_FUNDS");

export const GET_USER_ENABLED_PRIVATE_FUNDS = generateActionTypes("GET_USER_ENABLED_PRIVATE_FUNDS");

export const ENABLE_USER_PRIVATE_FUND = generateActionTypes("ENABLE_USER_PRIVATE_FUND");

export const DELETE_USER_PRIVATE_FUND = generateActionTypes("DELETE_USER_PRIVATE_FUND");

export const CLEAR_USER_ENABLED_PRIVATE_FUNDS = "CLEAR_USER_ENABLED_PRIVATE_FUNDS"
export const CLEAR_FUND_PAYOUT_BALANCE_HISTORY = "CLEAR_FUND_PAYOUT_BALANCE_HISTORY"

export const CREATE_FUND_PAYOUT_BALANCE = generateActionTypes(
	"CREATE_FUND_PAYOUT_BALANCE"
);

export const GET_FUND_PAYOUT_BALANCE_BY_ID = generateActionTypes(
	"GET_FUND_PAYOUT_BALANCE_BY_ID"
);

export const GET_ALL_FUND_PAYOUT_BALANCES = generateActionTypes(
	"GET_ALL_FUND_PAYOUT_BALANCES"
);

export const TRANSFER_FROM_FUND_PAYOUT_BALANCE = generateActionTypes(
	"TRANSFER_FROM_FUND_PAYOUT_BALANCE"
);

export const GET_FUND_PAYOUT_BALANCE_HISTORY = generateActionTypes(
	"GET_FUND_PAYOUT_BALANCE_HISTORY"
);

//  INDEXES

export const CREATE_INVESTMENT_INDEX = generateActionTypes(
	"CREATE_INVESTMENT_INDEX"
);
export const UPDATE_INVESTMENT_INDEX = generateActionTypes(
	"UPDATE_INVESTMENT_INDEX"
);
export const DELETE_INVESTMENT_INDEX = generateActionTypes(
	"DELETE_INVESTMENT_INDEX"
);
export const SEARCH_INVESTMENT_INDEXES = generateActionTypes(
	"SEARCH_INVESTMENT_INDEXES"
);
export const SEARCH_USER_INDEX_BALANCES = generateActionTypes(
	"SEARCH_USER_INDEX_BALANCES"
);
export const SEARCH_INDEX_OPERATIONS_HISTORY = generateActionTypes(
	"SEARCH_INDEX_OPERATIONS_HISTORY"
);
export const SEARCH_USERS_INDEX_INVOICES = generateActionTypes(
	"SEARCH_USERS_INDEX_INVOICES"
);
export const MAKE_DECISION_BY_INVOICE_INDEX = generateActionTypes(
	"MAKE_DECISION_BY_INVOICE_INDEX"
);
export const SEARCH_SEND_TO_BINANCE_PROGRAMS = generateActionTypes(
	"SEARCH_SEND_TO_BINANCE_PROGRAMS"
);
export const GET_INDEX_SYSTEM_BALANCES = generateActionTypes(
	"GET_INDEX_SYSTEM_BALANCES"
);

export const TRANSFER_FROM_SYSTEM_BALANCE_TO_WALLET = generateActionTypes(
	"TRANSFER_FROM_SYSTEM_BALANCE_TO_WALLET"
);

export const SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_INDEX_TO_WALLET =
	generateActionTypes(
		"SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_INDEX_TO_WALLET"
	);

export const CLEAR_INDEX_SYSTEM_BALANCES = "CLEAR_INDEX_SYSTEM_BALANCES";

export const CLEAR_USER_INDEX_BALANCES = "CLEAR_USER_INDEX_BALANCES";

export const CLEAR_TRANSFERS_SYSTEM_BALANCE_INDEX =
	"CLEAR_TRANSFERS_SYSTEM_BALANCE_INDEX";

export const CLEAR_INDEXES_OPERATIONS_HISTORY =
	"CLEAR_INDEXES_OPERATIONS_HISTORY";

export const CLEAR_INDEXES_STATUS = "CLEAR_INDEXES_STATUS";

export const CLEAR_INDEXES_ERROR = "CLEAR_INDEXES_ERROR";

// REFERRAL PROGRAM

export const SEARCH_REFERRAL_BALANCES = generateActionTypes(
	"SEARCH_REFERRAL_BALANCES"
);

export const SEARCH_CLAIM_HISTORY = generateActionTypes("SEARCH_CLAIM_HISTORY");

export const SEARCH_REFERRAL_REWARDS = generateActionTypes(
	"SEARCH_REFERRAL_REWARDS"
);

export const GET_DEFAULT_REFERRAL_SETTINGS = generateActionTypes(
	"GET_DEFAULT_REFERRAL_SETTINGS"
);

export const UPDATE_DEFAULT_REFERRAL_SETTINGS = generateActionTypes(
	"UPDATE_DEFAULT_REFERRAL_SETTINGS"
);

export const GET_VIP_REFERRAL_SETTINGS = generateActionTypes(
	"GET_VIP_REFERRAL_SETTINGS"
);

export const GET_VIP_REFERRAL_SETTINGS_BY_USER_ID = generateActionTypes(
	"GET_VIP_REFERRAL_SETTINGS_BY_USER_ID"
);

export const UPDATE_VIP_REFERRAL_SETTINGS = generateActionTypes(
	"UPDATE_VIP_REFERRAL_SETTINGS"
);

export const DELETE_VIP_REFERRAL_SETTINGS = generateActionTypes(
	"DELETE_VIP_REFERRAL_SETTINGS"
);

export const CREATE_VIP_REFERRAL_SETTINGS = generateActionTypes(
	"CREATE_VIP_REFERRAL_SETTINGS"
);

export const GET_REFERRALS = generateActionTypes("GET_REFERRALS");

export const CHANGE_PARENT = generateActionTypes("CHANGE_PARENT");

export const GET_SELECTED_VIP_USER_REFERRAL_SETTINGS = generateActionTypes("GET_SELECTED_VIP_USER_REFERRAL_SETTINGS");

export const DELETE_VIP_USER_REFERRAL_DEFAULT_SETTINGS = generateActionTypes("DELETE_VIP_USER_REFERRAL_DEFAULT_SETTINGS");

export const DELETE_VIP_USER_REFERRAL_EXCHANGE_SETTINGS = generateActionTypes("DELETE_VIP_USER_REFERRAL_EXCHANGE_SETTINGS");

export const DELETE_VIP_USER_REFERRAL_FUND_SETTINGS = generateActionTypes("DELETE_VIP_USER_REFERRAL_FUND_SETTINGS");

export const CREATE_VIP_USER_REFERRAL_DEFAULT_SETTINGS = generateActionTypes("CREATE_VIP_USER_REFERRAL_DEFAULT_SETTINGS");

export const CREATE_VIP_USER_REFERRAL_EXCHANGE_SETTINGS = generateActionTypes("CREATE_VIP_USER_REFERRAL_EXCHANGE_SETTINGS");

export const CREATE_VIP_USER_REFERRAL_FUND_SETTINGS = generateActionTypes("CREATE_VIP_USER_REFERRAL_FUND_SETTINGS");

export const UPDATE_VIP_USER_REFERRAL_DEFAULT_SETTINGS = generateActionTypes("UPDATE_VIP_USER_REFERRAL_DEFAULT_SETTINGS");

export const UPDATE_VIP_USER_REFERRAL_EXCHANGE_SETTINGS = generateActionTypes("UPDATE_VIP_USER_REFERRAL_EXCHANGE_SETTINGS");

export const UPDATE_VIP_USER_REFERRAL_FUND_SETTINGS = generateActionTypes("UPDATE_VIP_USER_REFERRAL_FUND_SETTINGS");

export const CLEAR_REFERRALS_STATUS = "CLEAR_REFERRALS_STATUS";

export const CLEAR_REFERRALS_ERROR = "CLEAR_REFERRALS_ERROR";

export const CLEAR_SELECTED_VIP_USER_REFERRAL_SETTINGS = "CLEAR_SELECTED_VIP_USER_REFERRAL_SETTINGS";

export const GET_COMPANIES_LIST = generateActionTypes("GET_COMPANIES_LIST");

export const SET_COMPANIES_LIST_PARAMS = "SET_COMPANIES_LIST_PARAMS";

export const CLEAR_COMPANIES_LIST = "CLEAR_COMPANIES_LIST";

export const CREATE_COMPANY = generateActionTypes("CREATE_COMPANY");

export const UPDATE_COMPANY = generateActionTypes("UPDATE_COMPANY");

export const DELETE_COMPANY = generateActionTypes("DELETE_COMPANY");

export const DEACTIVATE_COMPANY = generateActionTypes("DEACTIVATE_COMPANY");

export const ACTIVATE_COMPANY = generateActionTypes("ACTIVATE_COMPANY");

export const GET_COMPANY_BY_ID = generateActionTypes("GET_COMPANY_BY_ID");

export const GET_COMPANIES_BY_NAME = generateActionTypes("GET_COMPANIES_BY_NAME");

export const GET_COMPANY_BALANCES = generateActionTypes("GET_COMPANY_BALANCES");

export const CREATE_COMPANY_BALANCE = generateActionTypes("CREATE_COMPANY_BALANCE");

export const DELETE_COMPANY_BALANCE = generateActionTypes("DELETE_COMPANY_BALANCE");

export const CLEAR_COMPANY_BALANCES = "CLEAR_COMPANY_BALANCES";

export const GET_COMPANY_BALANCE_USERS = generateActionTypes("GET_COMPANY_BALANCE_USERS");

export const CREATE_COMPANY_BALANCE_USER = generateActionTypes("CREATE_COMPANY_BALANCE_USER");

export const DELETE_COMPANY_BALANCE_USER = generateActionTypes("DELETE_COMPANY_BALANCE_USER");

export const GET_COMPANY_BALANCE_TRANSFER_HISTORY = generateActionTypes("GET_COMPANY_BALANCE_TRANSFER_HISTORY"); 

export const GET_COMPANY_BALANCE_TRANSFER_INVOICES = generateActionTypes("GET_COMPANY_BALANCE_TRANSFER_INVOICES"); 

export const GET_NEW_COMPANIES_REQUEST_LIST = generateActionTypes("GET_NEW_COMPANIES_REQUEST_LIST"); 

export const CHANGE_NEW_COMPANY_REQUEST_STATUS = generateActionTypes("CHANGE_NEW_COMPANY_REQUEST_STATUS");

export const GET_COMPANY_BALANCE_SECURITY_SETTINGS_LIST = generateActionTypes("GET_COMPANY_BALANCE_SECURITY_SETTINGS_LIST"); 

export const CREATE_COMPANY_BALANCE_SECURITY_SETTINGS = generateActionTypes("CREATE_COMPANY_BALANCE_SECURITY_SETTINGS"); 

export const UPDATE_COMPANY_BALANCE_SECURITY_SETTINGS = generateActionTypes("UPDATE_COMPANY_BALANCE_SECURITY_SETTINGS"); 

export const DELETE_COMPANY_BALANCE_SECURITY_SETTINGS = generateActionTypes("DELETE_COMPANY_BALANCE_SECURITY_SETTINGS"); 

export const CREATE_COMPANY_BALANCE_USER_FOR_SECURITY_SETTINGS = generateActionTypes("CREATE_COMPANY_BALANCE_USER_FOR_SECURITY_SETTINGS"); 

export const DELETE_COMPANY_BALANCE_USER_FOR_SECURITY_SETTINGS = generateActionTypes("DELETE_COMPANY_BALANCE_USER_FOR_SECURITY_SETTINGS"); 

export const GET_COMPANY_BALANCE_USERS_OF_SECURITY_SETTINGS = generateActionTypes("GET_COMPANY_BALANCE_USERS_OF_SECURITY_SETTINGS"); 

export const GET_COMPANIES_BALANCES_TRANSFER_INVOICES_LIST = generateActionTypes("GET_COMPANIES_BALANCES_TRANSFER_INVOICES_LIST"); 

export const GET_INDEX_ALL_ASSETS_LIST = generateActionTypes("GET_INDEX_ALL_ASSETS_LIST")

export const CREATE_NEW_INDEX_ASSET = generateActionTypes("CREATE_NEW_INDEX_ASSET")

export const UPDATE_INDEX_ASSET_INFO = generateActionTypes("UPDATE_INDEX_ASSET_INFO")

export const GET_INDEX_ASSET_BY_ASSET_ID = generateActionTypes("GET_INDEX_ASSET_BY_ASSET_ID")

export const GET_INDEX_ASSETS_LIST_BY_INDEX_ID = generateActionTypes("GET_INDEX_ASSETS_LIST_BY_INDEX_ID")

export const DELETE_INDEX_ASSET = generateActionTypes("DELETE_INDEX_ASSET")

export const MANUALLY_REFRESH_INDEX_ASSETS_RATES = generateActionTypes("MANUALLY_REFRESH_INDEX_ASSETS_RATES")

export const CLEAR_COMPANY_BALANCE_USERS = "CLEAR_COMPANY_BALANCE_USERS";
export const CLEAR_COMPANY_BALANCE_SECURITY_SETTINS = "CLEAR_COMPANY_BALANCE_SECURITY_SETTINS";