import api from "./api";
import { logOut } from "./actions/accountActions";

export default function apiMiddleware({ dispatch, getState }) {
	return (next) => (action) => {
		if (action?.type?.endsWith("FAILURE")) {
			const error = action?.payload?.error?.response;
			if (error?.status === 401 && error?.data !== "Wrong twofa") {
				dispatch(logOut());
			}

			if (
				error?.status === 403 &&
				error?.data?.error_description ===
					"Could not verify the provided CSRF token because your session was not found."
			) {
				api.client[error?.config.method](error?.config.url, error?.config?.data)
					.then(({ data }) =>
						dispatch({
							type: action.type.replace("FAILURE", "SUCCESS"),
							payload: { data },
						})
					)
					.catch((error) =>
						dispatch({
							type: action.type,
							payload: { error },
						})
					);
			} else {
				next(action);
			}
		} else {
			next(action);
		}
	};
}
