import DialogWrapper from "../../../theme/components/dialogs/DialogWrapper";
import {
	Checkbox,
	FormControlLabel,
	Grid,
	Typography,
	MenuItem,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SelectWithLabel } from "../../../theme/components/SelectWithLabel";
import { Input } from "../../../theme/components/Input";
import { Button } from "../../../theme/components/Button";
import { useEffect, useState, useCallback } from "react";
import {
	createSettingExchange,
	updateSettingExchange,
} from "../../../app/actions/settingsActions";
import { exchangeSettingsValidator } from "../../../utils/customValidators/exchangeSettingsValidator";

const formFields = [
	{ field: "assetFrom", type: "select", label: "Asset From" },
	{ field: "assetTo", type: "select", label: "Asset To" },
	{
		field: "minAmount",
		type: "number",
		label: "Min amount",
	},
	{
		field: "maxAmount",
		type: "number",
		label: "Max amount",
	},
	{
		field: "systemFee",
		type: "number",
		label: "System fee, %",
	},
	{ field: "allowed", type: "checkbox", label: "Allowed" },
	{ field: "fiat", type: "checkbox", label: "Fiat" },
];

const EditExchangeDialog = ({
	open,
	handleClose,
	data,
	params,
	currencies,
}) => {
	const dispatch = useDispatch();
	const { status } = useSelector(({ settings }) => settings);
	const [errors, setErrors] = useState({});
	const [state, setState] = useState(null);
	const createState = useCallback(() => {
		let form = {};
		formFields.forEach(item => {
			form[item.field] =
				item.type === "select" ? "" : item.type === "number" ? 0 : false;
		});
		return form;
	}, []);

	useEffect(() => {
		data ? setState(data) : setState(createState());
	}, [data, createState]);

	const handleChange = ({ target: { name, value, type } }) => {
		handleInputValidation(name, value, type);
		setState({ ...state, [name]: value });
	};

	const handleUpdate = () => {
		let updateData = { ...state };
		if (state.assetFrom === "uah") {
			updateData = { ...state, deposit: true, fiat: true };
		} else if (state.assetTo === "uah") {
			updateData = { ...state, fiat: true, deposit: false };
		}
		if (!validateForm()) {
			return;
		}
		data
			? dispatch(updateSettingExchange(updateData, params)).then(res => {
					if (status) {
						setState(createState());
						handleClose();
					}
			  })
			: dispatch(createSettingExchange(updateData, params)).then(res => {
					if (status) {
						setState(createState());
						handleClose();
					}
			  });
	};

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(state).forEach(key => {
			errForm = {
				...errForm,
				[key]: exchangeSettingsValidator(
					key,
					state[key],
					findField(key),
					state
				),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const findField = key => {
		return formFields?.find(x => x.field === key);
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]: exchangeSettingsValidator(
				name,
				value,
				findField(name),
				state
			),
		});
	};

	const closeDialog = () => {
		setState(createState());
		setErrors({});
		handleClose();
	};
	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				{data
					? `Edit ${data?.assetFrom?.toUpperCase()} / ${data?.assetTo?.toUpperCase()}`
					: "Create"}
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<SelectWithLabel
						label='Asset From'
						value={state?.assetFrom || ""}
						onChange={handleChange}
						name={"assetFrom"}
						error={errors?.assetFrom?.invalid}
						helperText={errors?.assetFrom?.errorMessage}>
						{currencies.map((i, index) => (
							<MenuItem key={index} value={i?.ticker}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<img
										src={`https://cryptoneed.com/icons/${i?.ticker}.svg`}
										alt={i?.ticker}
										width={16}
										height={16}
										style={{ marginRight: 12 }}
										loading={"lazy"}
									/>
									<p style={{ margin: 0 }}>{i?.ticker?.toUpperCase()}</p>
								</div>
							</MenuItem>
						))}
					</SelectWithLabel>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectWithLabel
						label='Asset To'
						value={state?.assetTo || ""}
						onChange={handleChange}
						name={"assetTo"}
						error={errors?.assetTo?.invalid}
						helperText={errors?.assetTo?.errorMessage}>
						{currencies.map((i, index) => (
							<MenuItem key={index} value={i?.ticker}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<img
										src={`https://cryptoneed.com/icons/${i?.ticker}.svg`}
										alt={i?.ticker}
										width={16}
										height={16}
										style={{ marginRight: 12 }}
										loading={"lazy"}
									/>
									<p style={{ margin: 0 }}>{i?.ticker?.toUpperCase()}</p>
								</div>
							</MenuItem>
						))}
					</SelectWithLabel>
				</Grid>
				{state &&
					formFields?.map(
						({ field, label, type }) =>
							type === "number" && (
								<Grid item xs={12} sm={6} key={field}>
									<Input
										label={label}
										variant={"outlined"}
										type={type}
										name={field}
										value={state[field] || ""}
										error={errors[field]?.invalid}
										helperText={errors[field]?.errorMessage}
										onChange={handleChange}
									/>
								</Grid>
							)
					)}
				<Grid item xs={12} sm={6}>
					<FormControlLabel
						control={
							<Checkbox
								checked={Boolean(state?.allowed)}
								color={"primary"}
								onChange={(_, v) => setState({ ...state, allowed: v })}
							/>
						}
						label='Allowed'
					/>
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)" }}
							onClick={handleUpdate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default EditExchangeDialog;
