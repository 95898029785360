import {
	GET_STATISTIC,
	GET_NET_PROFIT,
	CLEAR_STATISTIC_STATUS,
	CLEAR_STATISTIC_ERROR,
} from "../types";

export default function statistic(
	state = {
		data: [],
		netProfit: [],
		status: false,
		error: null,
	},
	action
) {
	switch (action.type) {
		case GET_STATISTIC.STARTED:
		case GET_NET_PROFIT.STARTED:
			return { ...state, status: false, error: null };
		case GET_STATISTIC.FAILURE:
		case GET_NET_PROFIT.FAILURE:
			return { ...state, status: false, error: action.payload.error };
		case GET_STATISTIC.SUCCESS:
			return {
				...state,
				data: action.payload,
			};
		case GET_NET_PROFIT.SUCCESS:
			return { ...state, netProfit: action.payload };
		case CLEAR_STATISTIC_STATUS:
			return {
				...state,
				status: false,
			};
		case CLEAR_STATISTIC_ERROR:
			return {
				...state,
				error: null,
				status: false,
			};
		default:
			return state;
	}
}
