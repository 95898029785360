import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import PageTitle from "../../theme/components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { COMPANY_BALANCE_TRANSFERS_HISTORY_FILTER_FIELDS, COMPANY_BALANCE_TRANSFERS_INVOICES_FILTER_FIELDS } from "../../utils/constants/companies";
import {
	getCompaniesByName,
	getCompaniesList,
} from "../../app/actions/companiesActions";
import CreateCompanyDialog from "./CreateCompanyDialog";
import CompaniesTab from "./CompaniesTab";
import TransfersHistory from "./TransfersHistoryTab";
import NewCompaniesRequests from "./NewCompaniesRequestsTab";
import TransfersInvoices from "./TransfersInvoicesTab";

const tabs = {
	companies: {
		label: "Companies",
		index: 0,
	},
	transfersHistory: {
		label: "Transfers History",
		index: 1,
	},
	newCompaniesRequests: {
		label: "New Requests",
		index: 2,
	},
	transferInvoices: {
		label: "Transfer Invoices",
		index: 3,
	},
};

const DEFAULT_PARAMS = { page: 0, size: 10 };

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}>
			{value === index && <Box style={{ paddingTop: 24 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const Companies = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(tabs.companies.index);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [filterFields, setFilterFields] = useState(0);

	const { params } = useSelector(({ companies }) => companies?.companies);

	useEffect(() => {
		switch (activeTab) {
			case 1:
				setFilterFields(COMPANY_BALANCE_TRANSFERS_HISTORY_FILTER_FIELDS);
				return;
			case 3:
				setFilterFields(COMPANY_BALANCE_TRANSFERS_INVOICES_FILTER_FIELDS)
				return;
			default:
				setFilterFields(null);
				return;
		}
	}, [dispatch, activeTab]);

	const handleChangeTabs = (_, newTab) => {
		setActiveTab(newTab);
	};

	const handleSearch = (search) => {
		if (search) {
			dispatch(getCompaniesByName(search));
		} else {
			dispatch(getCompaniesList({ ...DEFAULT_PARAMS, ...params }));
		}
	};

	const handleCreate = () => {
		setShowCreateModal(true);
	};

	const handleCloseCreateModal = () => {
		setShowCreateModal(false);
	};

	return (
		<div className={classes.root} style={{ maxWidth: "100%" }}>
			<PageTitle
				title={"Companies"}
				onCreate={activeTab === 0 ? handleCreate : null}
				onSearch={activeTab === 0 ? handleSearch : null}
				valueSearch={params?.search}
				filterFields={filterFields}
			/>
			<CreateCompanyDialog
				open={Boolean(showCreateModal)}
				handleClose={handleCloseCreateModal}
				params={params}
			/>
			<Tabs
				value={activeTab}
				onChange={handleChangeTabs}
				aria-label='simple tabs example'
				indicatorColor='primary'>
				<Tab label={tabs.companies.label} {...a11yProps(0)} />
				<Tab label={tabs.transfersHistory.label} {...a11yProps(1)} />
				<Tab label={tabs.newCompaniesRequests.label} {...a11yProps(2)} />
				<Tab label={tabs.transferInvoices.label} {...a11yProps(3)} />
			</Tabs>
			<TabPanel value={activeTab} index={0}>
				<CompaniesTab />
			</TabPanel>
			<TabPanel value={activeTab} index={1}>
				<TransfersHistory />
			</TabPanel>
			<TabPanel value={activeTab} index={2}>
				<NewCompaniesRequests />
			</TabPanel>
			<TabPanel value={activeTab} index={3}>
				<TransfersInvoices />
			</TabPanel>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		background: theme.palette.background.default,
		boxShadow: "none",
	},
	btnWrap: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	paper: {
		display: "flex",
		alignItems: "center",
		padding: 8,
		paddingRight: 12,
		borderRadius: 8,
		width: "max-content",
		marginBottom: 16,
		"&:hover": {
			cursor: "pointer",
		},
	},
	icon: {
		color: theme.palette.primary.light,
		padding: 0,
		marginRight: 4,
		"& svg": {
			fontSize: 19,
		},
	},
	link: {
		color: theme.palette.primary.light,
		fontWeight: 700,
		textDecoration: "none",
	},
}));

export default Companies;
