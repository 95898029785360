import {
	GET_COMPANIES_LIST,
	SET_COMPANIES_LIST_PARAMS,
	CLEAR_COMPANIES_LIST,
	UPDATE_COMPANY,
	GET_COMPANIES_BY_NAME,
	CREATE_COMPANY,
	GET_COMPANY_BALANCES,
	CLEAR_COMPANY_BALANCES,
	GET_COMPANY_BALANCE_USERS,
	CLEAR_COMPANY_BALANCE_USERS,
	GET_COMPANY_BALANCE_TRANSFER_HISTORY,
	GET_NEW_COMPANIES_REQUEST_LIST,
	GET_COMPANY_BALANCE_SECURITY_SETTINGS_LIST,
	GET_COMPANY_BALANCE_USERS_OF_SECURITY_SETTINGS,
	CLEAR_COMPANY_BALANCE_SECURITY_SETTINS,
	GET_COMPANY_BALANCE_TRANSFER_INVOICES,
} from "../types";

const companiesInitialState = {
	content: [],
	totalElements: 0,
	params: null,
};

export default function companies(
	state = {
		companies: companiesInitialState,
		companyBalances: [],
		companyBalanceUsers: [],
		companyBalanceSecuritySettings: {},
		companyBalanceSecuritySettingsUsers: [],
		companyBalanceTransferHistory: [],
		companyBalanceTransferInvoices: [],
		newComapaniesReqeustsList: [],
		error: null,
		selectedCompany: null,
	},
	action
) {
	switch (action.type) {
		case GET_COMPANIES_LIST.STARTED:
		case GET_COMPANIES_BY_NAME.STARTED:
		case CREATE_COMPANY.STARTED:
		case GET_COMPANY_BALANCES.STARTED:
		case GET_COMPANY_BALANCE_USERS.STARTED:
		case GET_COMPANY_BALANCE_TRANSFER_HISTORY.STARTED:
		case GET_NEW_COMPANIES_REQUEST_LIST.STARTED:
		case GET_COMPANY_BALANCE_SECURITY_SETTINGS_LIST.STARTED:
		case GET_COMPANY_BALANCE_USERS_OF_SECURITY_SETTINGS.STARTED:
		case GET_COMPANY_BALANCE_TRANSFER_INVOICES.STARTED:
			return { ...state, error: null };
		case GET_COMPANIES_LIST.SUCCESS:
			return {
				...state,
				companies: {
					...state.companies,
					content: action.payload?.content || [],
					totalElements: action.payload?.totalElements || 0,
				},
			};
		case GET_COMPANIES_BY_NAME.SUCCESS:
			return {
				...state,
				companies: {
					...state.companies,
					content: action.payload || [],
					totalElements: action.payload?.length || 0,
				},
			};
		case GET_COMPANY_BALANCES.SUCCESS:
			return {
				...state,
				companyBalances: action.payload || [],
			};
		case GET_COMPANY_BALANCE_USERS.SUCCESS:
			return {
				...state,
				companyBalanceUsers: action.payload || [],
			};
		case GET_COMPANY_BALANCE_TRANSFER_HISTORY.SUCCESS:
			return {
				...state,
				companyBalanceTransferHistory: action.payload || [],
			};
		case GET_NEW_COMPANIES_REQUEST_LIST.SUCCESS:
			return {
				...state,
				newComapaniesReqeustsList: action.payload || [],
			};
		case GET_COMPANY_BALANCE_SECURITY_SETTINGS_LIST.SUCCESS:
			return {
				...state,
				companyBalanceSecuritySettings: action.payload || {},
			}
		case GET_COMPANY_BALANCE_USERS_OF_SECURITY_SETTINGS.SUCCESS:
			return {
				...state,
				companyBalanceSecuritySettingsUsers: action.payload || [],
			}
		case GET_COMPANY_BALANCE_TRANSFER_INVOICES.SUCCESS:
			return {
				...state,
				companyBalanceTransferInvoices: action.payload || [],
			}
		case CREATE_COMPANY.FAILURE:
		case GET_COMPANIES_LIST.FAILURE:
		case GET_COMPANIES_BY_NAME.FAILURE:
		case GET_COMPANY_BALANCES.FAILURE:
		case GET_COMPANY_BALANCE_USERS.FAILURE:
		case GET_COMPANY_BALANCE_TRANSFER_HISTORY.FAILURE:
		case GET_NEW_COMPANIES_REQUEST_LIST.FAILURE:
		case GET_COMPANY_BALANCE_SECURITY_SETTINGS_LIST.FAILURE:
		case GET_COMPANY_BALANCE_USERS_OF_SECURITY_SETTINGS.FAILURE:
		case GET_COMPANY_BALANCE_TRANSFER_INVOICES.FAILURE:
			return { ...state, error: action.payload.error };
		case UPDATE_COMPANY.STARTED:
			return { ...state, error: null };
		case UPDATE_COMPANY.SUCCESS:
			return { ...state, selectedCompany: action.payload.data };
		case UPDATE_COMPANY.FAILURE:
			return { ...state, error: action.payload.error };
		case SET_COMPANIES_LIST_PARAMS:
			return {
				...state,
				companies: { ...state.companies, params: action.payload },
			};
		case CLEAR_COMPANIES_LIST:
			return {
				...state,
				companies: companiesInitialState,
				error: null,
			};
		case CLEAR_COMPANY_BALANCES:
			return { ...state, companyBalances: [], error: null };
		case CLEAR_COMPANY_BALANCE_USERS:
			return { ...state, companyBalanceUsers: [], error: null };
		case CLEAR_COMPANY_BALANCE_SECURITY_SETTINS:
			return { ...state, companyBalanceSecuritySettings: {}, companyBalanceSecuritySettingsUsers: [], error: null };
		default:
			return state;
	}
}
