import moment from "moment";

export function setErrorMessage(field) {
	switch (field) {
		case "invalidDate":
			return "Invalid Date Format.";
		case "invalidMinDate":
			return "Document expired.";
		default:
			return "This is a required field!";
	}
}

export function kycValidateInput(name, value, form) {
	// required
	const fieldArray = ["expirationDate", "decision"];

	if (!value && fieldArray.includes(name)) {
		return {
			invalid: true,
			errorMessage: setErrorMessage(name),
		};
	}

	// date
	if (name === "expirationDate" && value && !moment(value).isValid()) {
		return {
			invalid: true,
			errorMessage: setErrorMessage("invalidDate"),
		};
	}

	// maxDate
	if (name === "expirationDate" && moment().isAfter(value)) {
		return {
			invalid: true,
			errorMessage: setErrorMessage("invalidMinDate"),
		};
	}

	// default
	return {
		invalid: false,
		errorMessage: "",
	};
}
