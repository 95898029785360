import React, { useEffect, useState } from "react";
import Tab from "@material-ui/core/Tab";
import { StyledTabs } from "../../../theme/components/StyledTabs";
import {
	getTransactions,
	setTransactionParams,
} from "../../../app/actions/transactionsActions";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../theme/components/PageTitle";
import DetailsDialog from "../../../theme/components/dialogs/DetailsDialog";
import {
	setActiveFilter,
	setActiveFiltersPage,
	setActiveFiltersSize,
} from "../../../app/actions/filterActions";
import CryptoOperationsTable from "./CryptoOperationsTable";

const tabs = {
	deposits: "Deposits",
	withdrawals: "Withdrawals",
};

const filterFields = [
	{ field: "uid", type: "number", title: "User ID", placeholder: "User ID" },
	{ field: "address", type: "text", title: "Address", placeholder: "Address" },
	{ field: "amount", type: "number", title: "Amount", placeholder: "Amount" },
	{
		field: "blockhash",
		type: "text",
		title: "Blockhash",
		placeholder: "Blockhash",
	},
	{
		field: "id",
		type: "number",
		title: "Operation ID",
		placeholder: "Operation ID",
	},
	{
		field: "txid",
		type: "text",
		title: "Txid",
		placeholder: "Txid",
	},

	{ field: "ticker", type: "text", title: "Ticker", placeholder: "Ticker" },
	{ field: "source", type: "text", title: "Source", placeholder: "Source" },
	{
		field: "transactionStatus",
		type: "text",
		title: "Transaction Status",
		placeholder: "Transaction Status",
	},
	{
		field: "dateFrom",
		type: "date",
		title: "Create date from",
		placeholder: "Create date from",
	},
	{
		field: "dateTo",
		type: "date",
		title: "Create date to",
		placeholder: "Create Date to",
	},
];

const Crypto = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(tabs.deposits);
	const [details, setDetails] = useState(null);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);
	const { data, params, total } = useSelector(
		({ transactions }) => transactions
	);

	useEffect(() => {
		let filteringParams = {};
		activeFilters.forEach(item => {
			let ignoredFields = ["page", "size", "dateFrom", "dateTo"];
			if (ignoredFields.includes(item.field)) {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			} else {
				filteringParams = {
					...filteringParams,
					[item.field + ".equals"]: item.value,
				};
			}
		});
		/*eslint no-useless-computed-key: "off"*/
		filteringParams = {
			...filteringParams,
			["transactionType.equals"]:
				activeTab === tabs.withdrawals ? "Withdrawal" : "Deposit",
		};
		dispatch(getTransactions(filteringParams));
	}, [activeFilters, activeTab, dispatch]);

	const handleChangeTab = newTab => {
		setActiveTab(newTab);
		dispatch(
			setActiveFilter([
				{ field: "size", value: 10 },
				{ field: "page", value: 0 },
			])
		);
		dispatch(setTransactionParams({ page: 0, size: 10 }));
	};

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
		dispatch(setTransactionParams({ ...params, page: newPage }));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
		dispatch(setTransactionParams({ ...params, size: target.value, page: 0 }));
	};

	const handleManage = data => {
		let detailsData = [];
		Object.keys(data).forEach(key => {
			if (!!data[key]) {
				detailsData = [...detailsData, { field: key, value: data[key] }];
			}
		});
		setDetails(detailsData);
	};

	return (
		<>
			<DetailsDialog
				open={Boolean(details)}
				handleClose={() => {
					setDetails(null);
				}}
				data={details}
				title={
					activeTab === tabs.deposits
						? "Deposit operation"
						: "Withdrawal operations"
				}
			/>
			<PageTitle title={"Crypto operations"} filterFields={filterFields} />
			<StyledTabs
				style={{ marginBottom: 12 }}
				indicatorColor='primary'
				value={activeTab}
				onChange={(_, newTab) => handleChangeTab(newTab)}>
				<Tab label={"Deposits operations"} value={tabs.deposits} />
				<Tab label={"Withdrawals operations"} value={tabs.withdrawals} />
			</StyledTabs>
			<CryptoOperationsTable
				onPageChange={(event, newPage) => handleChangePage(event, newPage)}
				onRowsPerPageChange={event => handleChangeSize(event)}
				data={data}
				params={activeFilters}
				total={total}
				onManage={handleManage}
			/>
		</>
	);
};

export default Crypto;
