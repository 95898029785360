import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../../../theme/components/Table/Table";
import CopyWithAlert from "../../../../utils/CopyWithAlert";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";
import getStatusColor from "../../../../utils/functions/getStatusColor";
import { searchSendToBinancePrograms } from "../../../../app/actions/indexesCapitalActions";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "investOperationId", label: "Invest Operation Id" },
	{ key: "indexId", label: "Index Id" },
	{ key: "withdrawalId", label: "Withdrawal Id" },
	{ key: "currency", label: "Currency" },
	{ key: "depositAddress", label: "Deposit Address" },
	{ key: "amount", label: "Amount" },
	{ key: "commission", label: "Commission" },
	{ key: "timeCreated", label: "Created" },
	{ key: "timeCompleted", label: "Completed" },
	{ key: "status", label: "Status" },
];

const BinanceTransfersHistory = () => {
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(
		({ indexes }) => indexes.binanceTransfersHistory
	);

	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(searchSendToBinancePrograms(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label, align }) => (
							<th key={key} style={{ textAlign: `${align || "left"}` }}>
								{label}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default BinanceTransfersHistory;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<td>{rowData?.investOperationId}</td>
				<td>{rowData?.indexId}</td>
				<td>{rowData?.withdrawalId}</td>
				<td>{rowData?.currency}</td>
				<CopyWithAlert
					text={rowData?.depositAddress + "" || ""}
					label={"Deposit address"}>
					<td data='hover'>{rowData?.depositAddress}</td>
				</CopyWithAlert>
				<td>{+rowData?.amount}</td>
				<td>{+rowData?.commission}</td>
				<td>
					{rowData?.timeCreated &&
						moment(rowData?.timeCreated).format("DD MMM YYYY HH:mm")}
				</td>
				<td>
					{rowData?.timeCompleted &&
						moment(rowData?.timeCompleted).format("DD MMM YYYY HH:mm")}
				</td>
				<CopyWithAlert text={rowData?.status + "" || ""} label={"Status"}>
					<td
						style={{
							color: rowData?.status && getStatusColor(rowData?.status),
						}}>
						{rowData?.status || "-"}
					</td>
				</CopyWithAlert>
			</tr>
		</>
	);
}
