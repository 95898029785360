import {
  SET_ACTIVE_FILTER,
  SET_ACTIVE_FILTER_PAGE,
  SET_ACTIVE_FILTER_SIZE,
  DELETE_ACTIVE_FILTER,
  CLEAR_ACTIVE_FILTERS,
} from "../types";

export default function filter(
  state = {
    activeFilters: [{field: 'size', value: 10}, {field: 'page', value: 0}],
  }, action
) {
  switch (action.type) {
    case SET_ACTIVE_FILTER:
      return { ...state, activeFilters: action.payload };
    case SET_ACTIVE_FILTER_PAGE:
      return { ...state, activeFilters: state.activeFilters.map((item) => {
        if(item.field === 'page') {
          return {...item, value: action.payload};
        }
        return item;
      })
    };
    case SET_ACTIVE_FILTER_SIZE:
      return { ...state, activeFilters: state.activeFilters.map((item) => {
        if(item.field === 'page') {
          return {...item, value: 0};
        }
        if(item.field === 'size') {
          return {...item, value: action.payload};
        }
        return item;
      })
    };
    case DELETE_ACTIVE_FILTER:
      return { ...state, activeFilters: [...action.payload, {field: 'size', value: 10}, {field: 'page', value: 0}] };
    case CLEAR_ACTIVE_FILTERS:
      return { ...state, activeFilters: [{field: 'size', value: 10}, {field: 'page', value: 0}] };
    default:
      return state;
  }
}
