import React, { useCallback, useEffect, useState } from "react";
import { Tabs, Tab, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PageTitle from "../../../theme/components/PageTitle";
import {
	INVOICES_FILTER_FIELDS,
	OPERATION_HISTORY_FILTER_FIELDS,
	TRANSFERS_FILTER_FIELDS,
} from "../../../utils/constants/funds";
import { BINANCE_TRANSFERS_HISTORY_FILTER_FIELDS } from "../../../utils/constants/indexes";

import Invoices from "./Invoices";
import OperationsHistory from "../Funds/Info/OperationsHistory";
import IndexesSettings from "./Settings/IndexesSettings";
import SystemBalances from "../Funds/SystemBalances";
import Transfers from "../Funds/Transfers";
import UserBalance from "../Funds/UserBalances";
import BinanceTransfersHistory from "./BinanceTransfersHistory";
import Assets from "./Assets";
import { manuallyRefreshIndexAssetsRates } from "../../../app/actions/indexesCapitalActions";
// import Dividends from "./Dividends";
// import DistributionHistory from "./DistributionHistory";

const tabs = {
	invoice: "Invoice",
	info: "Info",
	settings: "Settings",
	transfers: "Transfers",
	userBalance: "User balance",
	systemBalances: "System balances",
	binanceTransfers: "Binance transfers",
	assets: "Assets",
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}>
			{value === index && <Box style={{ paddingTop: 24 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const Indexes = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [activeTab, setActiveTab] = useState(0);
	const [filterFields, setFilterFields] = useState(0);
	const [createIndex, setCreateIndex] = useState(false);

	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		// redirect from manage index assets from settings tab
		if (location?.state?.from === "manageIndexAssets") {
			setActiveTab(2)
		}
	}, [location])

	useEffect(() => {
		switch (activeTab) {
			case 0:
				setFilterFields(INVOICES_FILTER_FIELDS);
				return;
			case 1:
				setFilterFields(OPERATION_HISTORY_FILTER_FIELDS);
				return;
			case 3:
				setFilterFields(TRANSFERS_FILTER_FIELDS);
				return;
			case 6:
				setFilterFields(BINANCE_TRANSFERS_HISTORY_FILTER_FIELDS);
				return;

			default:
				setFilterFields(null);
				return;
		}
	}, [dispatch, activeTab]);

	const handleChangeTabs = (_, newTab) => {
		setActiveTab(newTab);
	};

	const handleCreate = () => {
		setCreateIndex(true);
	};

	const handleRefreshAssetsRate = useCallback(() => {
		let filteringParams = {};
		activeFilters.forEach(item => {
			filteringParams = { ...filteringParams, [item.field]: item.value };
		});
		dispatch(manuallyRefreshIndexAssetsRates(filteringParams))
	}, [activeFilters, dispatch])

	return (
		<div style={{ maxWidth: "100%" }}>
				<PageTitle
					title={"Indexes"}
					style={{ height: 100 }}
					filterFields={filterFields}
					onCreate={activeTab === 2 ? handleCreate : null}
					onCustomButtonPress={handleRefreshAssetsRate}
					customButtonLabel={activeTab === 7 ? "Refresh Rate" : null}
				/>
			<Tabs
				value={activeTab}
				onChange={handleChangeTabs}
				aria-label='simple tabs example'
				indicatorColor='primary'>
				<Tab label={tabs.invoice} {...a11yProps(0)} />
				<Tab label={tabs.info} {...a11yProps(1)} />
				<Tab label={tabs.settings} {...a11yProps(2)} />
				<Tab label={tabs.transfers} {...a11yProps(3)} />
				<Tab label={tabs.userBalance} {...a11yProps(4)} />
				<Tab label={tabs.systemBalances} {...a11yProps(5)} />
				<Tab label={tabs.binanceTransfers} {...a11yProps(5)} />
				<Tab label={tabs.assets} {...a11yProps(7)} />
			</Tabs>
			<TabPanel value={activeTab} index={0}>
				<Invoices />
			</TabPanel>
			<TabPanel value={activeTab} index={1}>
				<OperationsHistory isIndexes={true} />
			</TabPanel>
			<TabPanel value={activeTab} index={2}>
				<IndexesSettings
					createIndex={createIndex}
					onCloseCreateIndex={() => setCreateIndex(false)}
				/>
			</TabPanel>
			<TabPanel value={activeTab} index={3}>
				<Transfers isIndexes={true} />
			</TabPanel>
			<TabPanel value={activeTab} index={4}>
				<UserBalance isIndexes={true} />
			</TabPanel>
			<TabPanel value={activeTab} index={5}>
				<SystemBalances isIndexes={true} />
			</TabPanel>
			<TabPanel value={activeTab} index={6}>
				<BinanceTransfersHistory />
			</TabPanel>
			<TabPanel value={activeTab} index={7}>
				<Assets />
			</TabPanel>
		</div>
	);
};

export default Indexes;
