import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import SendIcon from '@material-ui/icons/Send';
import TransferBalanceDialog from "./components/TransferBalanceDialog";
import Table from "../../../../theme/components/Table/Table";
import CopyWithAlert from "../../../../utils/CopyWithAlert";
import {
  getAllFundPayoutBalances,
  transferFromFundPayoutBalance,
} from "../../../../app/actions/fundsCapitalActions";
import CreateFundBalanceDialog from "./components/CreateFundBalanceDialog";
import { Visibility } from "@material-ui/icons";

const columns = [
  { key: "id", label: "ID" },
  { key: "asset", label: "Asset" },
  { key: "address", label: "Address" },
  { key: "balance", label: "Balance" },
  { key: "edit", label: "Manage", type: "manage" },
];

const PayoutBalances = ({ createPayoutBalance, onCloseCreatePayoutBalance }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { content } = useSelector(({ funds }) => funds?.fundsPayoutBalances);
  const [showTransferModal, setShowTransferModal] = useState(null);

  useEffect(() => {
    dispatch(getAllFundPayoutBalances());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTransferSubmit = useCallback((formData) => {
    dispatch(transferFromFundPayoutBalance(formData))
  }, [dispatch])

  const handleManageClick = useCallback((rowData) => () => {
    history.push(`/capital/funds/payout-balance-history/${rowData.id}`);
  }, [history])

  return (
    <>
      <CreateFundBalanceDialog
        open={createPayoutBalance}
        handleClose={() => {
          onCloseCreatePayoutBalance();
        }}
      />
      <TransferBalanceDialog
        title="Transfer Payout Balance"
        open={Boolean(showTransferModal)}
        handleClose={() => {
          setShowTransferModal(null);
        }}
        data={showTransferModal}
        handleTransfer={handleTransferSubmit}
      />
      <Table>
        <thead>
          <tr>
            {columns.map(({ key, label }) => (
              <th key={key}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {content?.length > 0 ? (
            content?.map((rowData, index) => (
              <TableRow
                key={rowData?.id || index}
                rowData={rowData}
                handleTransferClick={() => setShowTransferModal(rowData)}
                onManage={handleManageClick(rowData)}
              />
            ))
          ) : (
            <tr>
              <td colSpan={8} align={"center"} height={100}>
                The list is empty...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default PayoutBalances;

function TableRow({ rowData, handleTransferClick, onManage }) {
  return (
    <>
      <tr>
        <CopyWithAlert text={rowData?.id + "" || ""} label={"ID"}>
          <td data='hover'>{rowData?.id}</td>
        </CopyWithAlert>
        <td>{rowData?.asset}</td>
        <CopyWithAlert
          text={rowData?.address + "" || ""}
          label={"Address"}>
          <td data='hover' style={{ maxWidth: 50 }}>
            {rowData?.address}
          </td>
        </CopyWithAlert>
        <td>{Number(rowData?.balance)}</td>
        <td width={120}>
          <Tooltip title="Transfer" placement="top">
            <IconButton onClick={handleTransferClick}>
              <SendIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="View" placement="top">
            <IconButton onClick={onManage}>
              <Visibility />
            </IconButton>
          </Tooltip>
        </td>
      </tr>
    </>
  );
}
