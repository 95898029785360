import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination, IconButton } from "@material-ui/core";
import Table from "../../../../theme/components/Table/Table";
import CopyWithAlert from "../../../../utils/CopyWithAlert";
import ChangeParentDialog from "./ChangeParentDialog";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";
import { getReferrals } from "../../../../app/actions/referralsActions";
import { REFERRALS_COLUMNS } from "../../../../utils/constants/referrals";
import EditIcon from "@material-ui/icons/Edit";

const Referrals = () => {
	const dispatch = useDispatch();
	const [editReferral, setEditReferral] = useState(null);
	const { referrals } = useSelector(({ referrals }) => referrals);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getReferrals(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<ChangeParentDialog
				open={Boolean(editReferral)}
				handleClose={() => setEditReferral(null)}
				data={editReferral}
			/>
			<Table>
				<thead>
					<tr>
						{REFERRALS_COLUMNS?.map(({ key, headerName, colSpan, align }) => (
							<th key={key} colSpan={colSpan} align={align || "left"}>
								{headerName}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{referrals?.content?.length > 0 ? (
						referrals?.content?.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
								handleEdit={() => setEditReferral(rowData)}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={referrals?.totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default Referrals;

function TableRow({ rowData, handleDelete, handleEdit }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.userId + "" || ""} label={"User ID"}>
					<td data='hover'>{rowData?.userId}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.parentId + "" || ""} label={"Parent ID"}>
					<td data='hover'>{rowData?.parentId}</td>
				</CopyWithAlert>
				<td>
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
				</td>
			</tr>
		</>
	);
}
