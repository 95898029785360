export function autoSyncBalancesValidator(value, name) {
	// required
	if (!value) {
		return {
			invalid: true,
			errorMessage: "This is a required field!",
		};
	}

	// percent
	if (
		(name === "maxBalanceGrowthPercent" || name === "balanceLeftoverPercent") &&
		+value > 100
	) {
		return {
			invalid: true,
			errorMessage:
				"The field is defined as a percentage and cannot be greater than 100.",
		};
	}

	// default
	return {
		invalid: false,
		errorMessage: "",
	};
}
