import {
	GET_SETTINGS_EXCHANGES_STARTED,
	GET_SETTINGS_EXCHANGES_SUCCESS,
	GET_SETTINGS_EXCHANGES_FAILURE,
	DELETE_SETTING_EXCHANGE_STARTED,
	DELETE_SETTING_EXCHANGE_SUCCESS,
	DELETE_SETTING_EXCHANGE_FAILURE,
	CREATE_SETTING_EXCHANGE_STARTED,
	CREATE_SETTING_EXCHANGE_SUCCESS,
	CREATE_SETTING_EXCHANGE_FAILURE,
	UPDATE_SETTING_EXCHANGE_STARTED,
	UPDATE_SETTING_EXCHANGE_SUCCESS,
	UPDATE_SETTING_EXCHANGE_FAILURE,
	GET_SETTINGS_WITHDRAWS_SUCCESS,
	GET_SETTINGS_WITHDRAWS_FAILURE,
	DELETE_SETTING_WITHDRAW_STARTED,
	DELETE_SETTING_WITHDRAW_SUCCESS,
	DELETE_SETTING_WITHDRAW_FAILURE,
	CREATE_SETTING_WITHDRAW_STARTED,
	CREATE_SETTING_WITHDRAW_SUCCESS,
	CREATE_SETTING_WITHDRAW_FAILURE,
	UPDATE_SETTING_WITHDRAW_STARTED,
	UPDATE_SETTING_WITHDRAW_SUCCESS,
	UPDATE_SETTING_WITHDRAW_FAILURE,
	GET_SETTINGS_DEPOSITS_STARTED,
	GET_SETTINGS_DEPOSITS_SUCCESS,
	GET_SETTINGS_DEPOSITS_FAILURE,
	DELETE_SETTING_DEPOSIT_STARTED,
	DELETE_SETTING_DEPOSIT_SUCCESS,
	DELETE_SETTING_DEPOSIT_FAILURE,
	CREATE_SETTING_DEPOSIT_STARTED,
	CREATE_SETTING_DEPOSIT_SUCCESS,
	CREATE_SETTING_DEPOSIT_FAILURE,
	UPDATE_SETTING_DEPOSIT_STARTED,
	UPDATE_SETTING_DEPOSIT_SUCCESS,
	UPDATE_SETTING_DEPOSIT_FAILURE,
	CLEAR_TRANSACTIONS_SETTINGS,
	CLEAR_STATUS_SETTINGS,
	CLEAR_ERROR_SETTINGS,
	GET_MAX_PRECISION_STARTED,
	GET_MAX_PRECISION_SUCCESS,
	GET_MAX_PRECISION_FAILURE,
} from "../types";

export default function settings(
	state = {
		exchange: {
			data: [],
			totalItems: 0,
			first: false,
			last: false,
		},
		transactions: {
			data: [],
			totalItems: 0,
			first: false,
			last: false,
		},
		maxPrecisionExchange: 0,
		status: false,
		error: null,
	},
	action
) {
	switch (action.type) {
		case CREATE_SETTING_DEPOSIT_STARTED:
		case CREATE_SETTING_EXCHANGE_STARTED:
		case CREATE_SETTING_WITHDRAW_STARTED:
		case UPDATE_SETTING_DEPOSIT_STARTED:
		case UPDATE_SETTING_EXCHANGE_STARTED:
		case UPDATE_SETTING_WITHDRAW_STARTED:
		case GET_SETTINGS_EXCHANGES_STARTED:
		case DELETE_SETTING_EXCHANGE_STARTED:
		case DELETE_SETTING_WITHDRAW_STARTED:
		case GET_SETTINGS_DEPOSITS_STARTED:
		case DELETE_SETTING_DEPOSIT_STARTED:
		case GET_MAX_PRECISION_STARTED:
			return { ...state, status: false, error: null };
		case CREATE_SETTING_DEPOSIT_FAILURE:
		case CREATE_SETTING_EXCHANGE_FAILURE:
		case CREATE_SETTING_WITHDRAW_FAILURE:
		case UPDATE_SETTING_DEPOSIT_FAILURE:
		case UPDATE_SETTING_EXCHANGE_FAILURE:
		case UPDATE_SETTING_WITHDRAW_FAILURE:
		case DELETE_SETTING_EXCHANGE_FAILURE:
		case GET_SETTINGS_EXCHANGES_FAILURE:
		case GET_SETTINGS_WITHDRAWS_FAILURE:
		case DELETE_SETTING_WITHDRAW_FAILURE:
		case GET_SETTINGS_DEPOSITS_FAILURE:
		case DELETE_SETTING_DEPOSIT_FAILURE:
		case GET_MAX_PRECISION_FAILURE:
			return { ...state, status: false, error: action.payload.error };
		case GET_MAX_PRECISION_SUCCESS:
			return { ...state, maxPrecisionExchange: action.payload?.maxPrecision };

		case GET_SETTINGS_EXCHANGES_SUCCESS:
			return { ...state, exchange: action.payload, status: true };
		case GET_SETTINGS_WITHDRAWS_SUCCESS:
		case GET_SETTINGS_DEPOSITS_SUCCESS:
			return { ...state, transactions: action.payload };
		case DELETE_SETTING_EXCHANGE_SUCCESS:
			return {
				...state,
				exchange: {
					...state.exchange,
					data: state.exchange.data.filter(i => i.id !== action.payload),
				},
				status: true,
			};
		case DELETE_SETTING_WITHDRAW_SUCCESS:
		case DELETE_SETTING_DEPOSIT_SUCCESS:
			return {
				...state,
				transactions: {
					...state.transactions,
					data: state.transactions.data.filter(i => i.id !== action.payload),
				},
				status: true,
			};
		case UPDATE_SETTING_EXCHANGE_SUCCESS:
			return {
				...state,
				exchange: {
					...state.exchange,
					data: state.exchange.data.map(i =>
						i.id === action.payload.id ? action.payload : i
					),
				},
				status: true,
			};
		case UPDATE_SETTING_WITHDRAW_SUCCESS:
		case UPDATE_SETTING_DEPOSIT_SUCCESS:
			return {
				...state,
				transactions: {
					...state.transactions,
					data: state.transactions.data.map(i =>
						i.id === action.payload.id ? action.payload : i
					),
				},
				status: true,
			};
		case CREATE_SETTING_EXCHANGE_SUCCESS:
		case CREATE_SETTING_WITHDRAW_SUCCESS:
		case CREATE_SETTING_DEPOSIT_SUCCESS:
			return {
				...state,
				status: true,
			};

		case CLEAR_TRANSACTIONS_SETTINGS:
			return {
				...state,
				transactions: { data: [], totalItems: 0, first: false, last: false },
				status: null,
			};
		case CLEAR_STATUS_SETTINGS:
			return {
				...state,
				status: false,
			};
		case CLEAR_ERROR_SETTINGS:
			return {
				...state,
				error: null,
				status: false,
			};
		default:
			return state;
	}
}
