import {enqueueSnackbar} from "../app/actions/notistackActions";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {useDispatch} from "react-redux";
import PropTypes from 'prop-types';

const CopyWithAlert = ({text, label, children}) => {
	const dispatch = useDispatch();

	return (
		<CopyToClipboard text={text} onCopy={() => dispatch(
			enqueueSnackbar({
				message: `${label} successfully copied to clipboard.`,
				options: {
					key: new Date().getTime() + Math.random(),
					variant: 'success',
					autoHideDuration: 3000
				},
			})
		)}>
			{children}
		</CopyToClipboard>
	);
};

CopyWithAlert.propTypes = {
	text: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired
}

export default CopyWithAlert;
