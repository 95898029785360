import {
	CREATE_INVESTMENT_INDEX,
	UPDATE_INVESTMENT_INDEX,
	DELETE_INVESTMENT_INDEX,
	SEARCH_INVESTMENT_INDEXES,
	SEARCH_USER_INDEX_BALANCES,
	CLEAR_USER_INDEX_BALANCES,
	SEARCH_INDEX_OPERATIONS_HISTORY,
	SEARCH_USERS_INDEX_INVOICES,
	MAKE_DECISION_BY_INVOICE_INDEX,
	SEARCH_SEND_TO_BINANCE_PROGRAMS,
	GET_INDEX_SYSTEM_BALANCES,
	CLEAR_INDEX_SYSTEM_BALANCES,
	TRANSFER_FROM_SYSTEM_BALANCE_TO_WALLET,
	SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_INDEX_TO_WALLET,
	CLEAR_TRANSFERS_SYSTEM_BALANCE_INDEX,
	CLEAR_INDEXES_OPERATIONS_HISTORY,
	CLEAR_INDEXES_STATUS,
	CLEAR_INDEXES_ERROR,
	GET_INDEX_ALL_ASSETS_LIST,
	GET_INDEX_ASSETS_LIST_BY_INDEX_ID,
	MANUALLY_REFRESH_INDEX_ASSETS_RATES,
} from "../types";

export default function indexes(
	state = {
		indexes: {
			content: [],
			totalElements: 0,
		},
		indexesOperationsHistory: {
			content: [],
			totalElements: 0,
		},
		invoices: [],
		userIndexBalance: {
			content: [],
			totalElements: 0,
		},
		systemBalancesIndexes: [],
		transfersFromSystemBalances: {
			content: [],
			totalElements: 0,
		},
		binanceTransfersHistory: {
			content: [],
			totalElements: 0,
		},
		assetsList: {
			content: [],
			totalElements: 0,
		},
		indexAssetsList: [],
		status: false,
		error: null,
	},
	action
) {
	switch (action.type) {
		case CREATE_INVESTMENT_INDEX.STARTED:
		case UPDATE_INVESTMENT_INDEX.STARTED:
		case DELETE_INVESTMENT_INDEX.STARTED:
		case SEARCH_INVESTMENT_INDEXES.STARTED:
		case SEARCH_USER_INDEX_BALANCES.STARTED:
		case SEARCH_INDEX_OPERATIONS_HISTORY.STARTED:
		case SEARCH_USERS_INDEX_INVOICES.STARTED:
		case MAKE_DECISION_BY_INVOICE_INDEX.STARTED:
		case SEARCH_SEND_TO_BINANCE_PROGRAMS.STARTED:
		case GET_INDEX_SYSTEM_BALANCES.STARTED:
		case TRANSFER_FROM_SYSTEM_BALANCE_TO_WALLET.STARTED:
		case SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_INDEX_TO_WALLET.STARTED:
		case GET_INDEX_ALL_ASSETS_LIST.STARTED:
		case GET_INDEX_ASSETS_LIST_BY_INDEX_ID.STARTED:
		case MANUALLY_REFRESH_INDEX_ASSETS_RATES.STARTED:
			return { ...state, status: false, error: null };
		case CREATE_INVESTMENT_INDEX.FAILURE:
		case UPDATE_INVESTMENT_INDEX.FAILURE:
		case DELETE_INVESTMENT_INDEX.FAILURE:
		case SEARCH_INVESTMENT_INDEXES.FAILURE:
		case SEARCH_USER_INDEX_BALANCES.FAILURE:
		case SEARCH_INDEX_OPERATIONS_HISTORY.FAILURE:
		case SEARCH_USERS_INDEX_INVOICES.FAILURE:
		case MAKE_DECISION_BY_INVOICE_INDEX.FAILURE:
		case SEARCH_SEND_TO_BINANCE_PROGRAMS.FAILURE:
		case GET_INDEX_SYSTEM_BALANCES.FAILURE:
		case TRANSFER_FROM_SYSTEM_BALANCE_TO_WALLET.FAILURE:
		case SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_INDEX_TO_WALLET.FAILURE:
		case GET_INDEX_ALL_ASSETS_LIST.FAILURE:
		case GET_INDEX_ASSETS_LIST_BY_INDEX_ID.FAILURE:
		case MANUALLY_REFRESH_INDEX_ASSETS_RATES.FAILURE:
			return { ...state, status: false, error: action.payload.error };
		case CREATE_INVESTMENT_INDEX.SUCCESS:
		case UPDATE_INVESTMENT_INDEX.SUCCESS:
		case MAKE_DECISION_BY_INVOICE_INDEX.SUCCESS:
			case TRANSFER_FROM_SYSTEM_BALANCE_TO_WALLET.SUCCESS:
			// case SEND_FROM_SYSTEM_BALANCE_TO_WALLET_BY_ADDRESS.SUCCESS:
			return { ...state, status: true };
		case SEARCH_USERS_INDEX_INVOICES.SUCCESS:
			return {
				...state,
				invoices: action.payload,
			};
		case SEARCH_INDEX_OPERATIONS_HISTORY.SUCCESS:
			return {
				...state,
				indexesOperationsHistory: action.payload,
			};

		case SEARCH_INVESTMENT_INDEXES.SUCCESS:
			return {
				...state,
				indexes: action.payload,
			};
		case GET_INDEX_SYSTEM_BALANCES.SUCCESS:
			return {
				...state,
				systemBalancesIndexes: action.payload,
			};
		case SEARCH_USER_INDEX_BALANCES.SUCCESS:
			return {
				...state,
				userIndexBalance: action.payload
			}
		case SEARCH_SEND_TO_BINANCE_PROGRAMS.SUCCESS:
			return {
				...state,
				binanceTransfersHistory: action.payload,
			};
		case GET_INDEX_ALL_ASSETS_LIST.SUCCESS:
			return {
				...state,
				assetsList: action.payload
			}
		case GET_INDEX_ASSETS_LIST_BY_INDEX_ID.SUCCESS:
			return {
				...state,
				indexAssetsList: action.payload
			}
		case MANUALLY_REFRESH_INDEX_ASSETS_RATES.SUCCESS:
			return {
				...state,
				assetsList: action.payload
			}
		case CLEAR_INDEX_SYSTEM_BALANCES:
			return {
				...state,
				systemBalancesIndexes: [],
			};

		case CLEAR_USER_INDEX_BALANCES:
			return {
				...state,
				userIndexBalance: {
					content: [],
					totalElements: 0,
				},
			};
		case CLEAR_TRANSFERS_SYSTEM_BALANCE_INDEX:
			return {
				...state,
				transfersFromSystemBalances: {
					content: [],
					totalElements: 0,
				},
			};
		case CLEAR_INDEXES_OPERATIONS_HISTORY:
			return {
				...state,
				indexesOperationsHistory: {
					content: [],
					totalElements: 0,
				},
			};
		case CLEAR_INDEXES_STATUS:
			return {
				...state,
				status: false,
				error: null,
			};
		case CLEAR_INDEXES_ERROR:
			return {
				...state,
				error: null,
				status: false,
			};
		default:
			return state;
	}
}
