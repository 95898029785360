import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PieChart } from "react-minimal-pie-chart";
import moment from "moment";
import clsx from "clsx";
import { Grid, MenuItem, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Chart from "../../theme/components/Chart";
import { SelectWithLabel } from "../../theme/components/SelectWithLabel";
import SlicedAddress from "../../theme/components/SlicedAddress";
import { ReactComponent as SentIcon } from "../../assets/sent.svg";
import { ReactComponent as ReceiveIcon } from "../../assets/receive.svg";
import { getStatistic } from "../../app/actions/statisticActions";
import { getTransactions } from "../../app/actions/transactionsActions";
import { getCurrencies } from "../../app/actions/walletsActions";
import getStatusColor from "../../utils/functions/getStatusColor";

const transactionTypes = {
	receive: "Deposit",
	sent: "Withdrawal",
};

const Home = () => {
	const dispatch = useDispatch();
	const statistic = useSelector(({ statistic }) => statistic.data);
	const { currencies } = useSelector(({ wallets }) => wallets);
	const transactions = useSelector(({ transactions }) => transactions?.data);
	const [selected, setSelected] = useState("");

	useEffect(() => {
		dispatch(getCurrencies());
	}, [dispatch]);

	const data = useMemo(
		() => currencies,
		// () => currencies?.filter(item => !item.ticker.includes("usdt")),
		[currencies]
	);

	useEffect(() => {
		if (data?.length > 0) {
			setSelected(data[0]?.ticker);
		}
	}, [data]);

	useEffect(() => {
		if (selected) {
			/*eslint no-useless-computed-key: "off"*/
			dispatch(
				getTransactions({
					page: 0,
					size: 10,
					["ticker.equals"]: selected,
					["create.direction"]: "up",
				})
			);
			dispatch(getStatistic(selected));
		}
	}, [dispatch, selected]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<SelectWithLabel
					label={"Select coin"}
					value={selected}
					onChange={({ target }) => setSelected(target.value)}
					style={{ maxWidth: 360 }}>
					{!!data &&
						data?.map((i, index) => (
							<MenuItem key={index} value={i?.ticker}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<img
										src={`https://cryptoneed.com/icons/${i?.ticker}.svg`}
										alt={i?.ticker}
										width={28}
										height={28}
										style={{ marginRight: 12 }}
										loading={"lazy"}
									/>
									<p style={{ margin: 4 }}>{i?.ticker?.toUpperCase()}</p>
								</div>
							</MenuItem>
						))}
				</SelectWithLabel>
			</Grid>
			<Grid item xs={12} md={6}>
				<div style={{ height: 460 }}>
					<Chart ticker={selected} />
				</div>
			</Grid>
			<Grid item xs={12} md={6}>
				<Typography
					variant={"h3"}
					color={"textPrimary"}
					style={{ marginBottom: 16 }}>
					Last transactions
				</Typography>
				{transactions?.length === 0 && (
					<Typography
						variant={"h6"}
						color={"textSecondary"}
						style={{ marginTop: 48 }}
						align={"center"}>
						No data yet
					</Typography>
				)}
				{transactions?.length &&
					transactions
						?.slice(0, 6)
						.map((i, index) => (
							<Transaction
								transaction={i}
								setDetail={(s) => console.log(s)}
								key={index}
							/>
						))}
			</Grid>
			<Grid item xs={12} md={6}>
				<Typography
					variant={"h3"}
					align={"center"}
					color={"textPrimary"}
					style={{ marginBottom: 16 }}>
					Deposits / Withdrawals
				</Typography>
				<div style={{ height: 280 }}>
					{statistic?.depositCount > 0 || statistic?.withdrawalCount > 0 ? (
						<PieChart
							data={[
								{
									title: "Withdrawals",
									value: statistic?.withdrawalCount,
									color: "#0880AB",
								},
								{
									title: "Deposits",
									value: statistic?.depositCount,
									color: "rgba(50, 88, 69)",
								},
							]}
							label={({ dataEntry }) =>
								`${dataEntry.value} ${
									dataEntry.title
								} (${dataEntry.percentage.toFixed(1)}%)`
							}
							animate
							labelStyle={{
								fontSize: "5px",
								fill: "#fff",
							}}
						/>
					) : (
						<Typography
							variant={"h6"}
							color={"textSecondary"}
							style={{ marginTop: 48 }}
							align={"center"}>
							No data yet
						</Typography>
					)}
				</div>
			</Grid>
			<Grid item xs={12} md={6}>
				<Typography
					variant={"h3"}
					align={"center"}
					color={"textPrimary"}
					style={{ marginBottom: 16 }}>
					Completed transactions
				</Typography>
				<div style={{ height: 280 }}>
					{statistic?.completedCount > 0 || statistic?.rejectedCount > 0 ? (
						<PieChart
							data={[
								{
									title: "Completed",
									value: statistic?.completedCount,
									color: "rgba(50, 88, 69)",
								},
								{
									title: "Failed",
									value: statistic?.rejectedCount,
									color: "rgba(88, 40, 59)",
								},
							]}
							labelPosition={
								statistic?.completedCount === 0 ||
								statistic?.rejectedCount === 0
									? 0
									: 50
							}
							label={({ dataEntry }) =>
								dataEntry.value === 0
									? ""
									: `${dataEntry.value} ${
											dataEntry.title
									  } (${dataEntry.percentage.toFixed(1)}%)`
							}
							animate
							labelStyle={{
								fontSize: "5px",
								fill: "#fff",
							}}
						/>
					) : (
						<Typography
							variant={"h6"}
							color={"textSecondary"}
							style={{ marginTop: 48 }}
							align={"center"}>
							No data yet
						</Typography>
					)}
				</div>
			</Grid>
		</Grid>
	);
};

export default Home;

export const Transaction = ({ transaction }) => {
	const classes = useTransactionsStyles();

	return (
		<Paper className={classes.root}>
			<Grid container spacing={2} alignItems={"center"}>
				<Grid item xs={6} className={classes.type}>
					{transaction?.transactionType === transactionTypes.sent ? (
						<SentIcon />
					) : (
						<ReceiveIcon />
					)}
					<div>
						<Typography
							variant={"body1"}
							color={"textPrimary"}
							style={{ fontWeight: 700 }}
							noWrap>
							{transaction?.transactionType === transactionTypes.sent
								? "Sent to "
								: "Receive from "}
							<SlicedAddress address={transaction?.address} />
						</Typography>
						<Typography
							variant={"subtitle2"}
							style={{
								color: getStatusColor(transaction?.transactionStatus),
							}}>
							{transaction?.transactionStatus}
						</Typography>
					</div>
				</Grid>
				<Grid
					item
					xs={6}
					className={clsx(
						classes.amount,
						transaction?.transactionType !== transactionTypes.sent && "sent"
					)}>
					<Typography variant={"body1"} style={{ fontWeight: 700 }} noWrap>
						{transaction?.transactionType === transactionTypes.sent && "- "}
						{transaction?.amount} {transaction?.ticker.toUpperCase()}
					</Typography>
					<Typography variant={"subtitle2"} color={"textSecondary"} noWrap>
						{moment(transaction?.create).format("DD.MM.yyyy HH:mm")}
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};

const useTransactionsStyles = makeStyles((theme) => ({
	root: {
		marginBottom: 8,
		padding: "8px 12px",
		[theme.breakpoints.down("sm")]: {
			padding: 8,
		},
	},
	type: {
		display: "flex",
		alignItems: "center",
		textOverflow: "ellipsis",
		"&>svg": {
			display: "block",
			marginRight: 16,
			minWidth: 36,
			width: 36,
			minHeight: 36,
			height: 36,
			[theme.breakpoints.down("md")]: {
				minWidth: 24,
				width: 24,
				height: 24,
				minHeight: 24,
				marginRight: 8,
			},
		},
	},
	amount: {
		textAlign: "right",
		"&.sent>p": {
			color: theme.palette.success.main,
		},
	},
	details: {
		textAlign: "right",
		"& button": {
			[theme.breakpoints.down("xs")]: {
				width: "100%",
			},
		},
	},
}));
