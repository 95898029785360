import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Grid, Typography } from "@material-ui/core";
import Table from "../../../../../theme/components/Table/Table";
import EditDefaultSettingsDialog from "./EditDefaultSettingsDialog";
import { clearActiveFilters } from "../../../../../app/actions/filterActions";
import { getDefaultReferralSettings } from "../../../../../app/actions/referralsActions";
import { DEFAULT_REFERRAL_SETTINGS_COLUMNS } from "../../../../../utils/constants/referrals";
import EditIcon from "@material-ui/icons/Edit";

const DefaultReferralSettings = () => {
	const dispatch = useDispatch();
	const [editSetting, setEditSetting] = useState(null);
	const { defaultReferralSettings } = useSelector(({ referrals }) => referrals);

	useEffect(() => {
		dispatch(getDefaultReferralSettings());
	}, [dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	return (
		<>
			<EditDefaultSettingsDialog
				open={Boolean(editSetting)}
				handleClose={() => setEditSetting(null)}
				data={editSetting}
				default
			/>
			<Grid container justifyContent='space-between'>
				<Typography
					variant={"body1"}
					color={"textPrimary"}
					style={{ marginBottom: 0 }}>
					Default settings
				</Typography>
			</Grid>
			<Table>
				<thead>
					<tr>
						{DEFAULT_REFERRAL_SETTINGS_COLUMNS?.map(
							({ key, headerName, colSpan, align }) => (
								<th
									key={key}
									colSpan={colSpan}
									style={{ textAlign: align || "left" }}>
									{headerName}

									{defaultReferralSettings && key !== "manage" ? (
										<Typography
											variant={"body1"}
											color={"textPrimary"}
											style={{ marginBottom: 0 }}>
											{+defaultReferralSettings[key]}
										</Typography>
									) : (
										<IconButton
											onClick={() => setEditSetting(defaultReferralSettings)}>
											<EditIcon />
										</IconButton>
									)}
								</th>
							)
						)}
					</tr>
				</thead>
			</Table>
		</>
	);
};

export default DefaultReferralSettings;
