import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import { makeStyles, TablePagination, lighten } from "@material-ui/core";
import Table from "../../theme/components/Table/Table";
import { Button } from "../../theme/components/Button";
import CopyWithAlert from "../../utils/CopyWithAlert";
import { getPendingActivationUsers } from "../../app/actions/usersActions";
import { sendRegistrationMessage } from "../../app/actions/accountActions";

const PendingActivationUsers = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [params, setParams] = useState(null);

	const data = useSelector(({ users }) => users.pendingActivationUsers);

	const columns = [
		{ key: "token", headerName: "Token" },
		{ key: "login", headerName: "Login" },
		{ key: "email", headerName: "Email" },
		{ key: "referralOwnerId", headerName: "Referral Owner Id" },
		{ key: "manage", headerName: "Manage", align: "center" },
	];

	useEffect(() => {
		dispatch(getPendingActivationUsers());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getPendingActivationUsers());
	}, [dispatch]);

	useEffect(() => {
		return () => {
			dispatch(setParams(null));
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setParams({ ...params, page: newPage }));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setParams({ ...params, size: target.value, page: 0 }));
	};

	const handleManage = (user) => {
		dispatch(sendRegistrationMessage({ token: user?.token }));
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, headerName, sort, colSpan, align }) => (
							<th
								key={key}
								className={clsx(classes.th, classes.paper, sort && "hover")}
								colSpan={colSpan}
								style={{ textAlign: align || "left" }}>
								{headerName}
							</th>
						))}
					</tr>
				</thead>
				<tbody style={{ position: "relative" }}>
					{data?.length > 0 ? (
						data?.map((row, index) => (
							<TableRow
								key={row?.id || index}
								rowData={row}
								onManage={handleManage}
							/>
						))
					) : (
						<tr>
							<td colSpan={13} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 30, 50]}
				component='div'
				count={data?.length || 0}
				rowsPerPage={params?.size || 10}
				page={(data?.length > 0 && params?.page) || 0}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</div>
	);
};

export default PendingActivationUsers;

function TableRow({ rowData, onManage }) {
	const classes = useStyles();
	return (
		<>
			<tr className={classes.hover}>
				<CopyWithAlert text={rowData?.token + "" || ""} label={"Token"}>
					<td className={classes.hoverCopyTd}>{rowData?.token}</td>
				</CopyWithAlert>
				<td>{rowData?.login}</td>
				<CopyWithAlert text={rowData?.email + "" || ""} label={"email"}>
					<td className={classes.hoverCopyTd}>{rowData?.email}</td>
				</CopyWithAlert>
				<td>{moment(rowData?.creationTime).format("DD MMM yyyy HH:mm:ss")}</td>
				<td style={{ textAlign: "center" }}>
					<Button
						color={"primary"}
						className={classes.resend}
						onClick={() => onManage(rowData)}>
						Resend
					</Button>
				</td>
			</tr>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	hoverCopyTd: {
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.light,
		},
	},
	resend: {
		fontSize: 14,
		background: `${theme.palette.background.paper} !important`,
		padding: "4px 24px",
		maxHeight: "36px",
		minHeight: 24,
		"&:hover": {
			background: `${lighten(theme.palette.background.paper, 0.1)} !important`,
		},
	},
}));
