import { useEffect, useState } from "react";
import { InputAdornment, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Button } from "../../theme/components/Button";
import AuthWrapper from "../../theme/components/AuthWrapper";
import { Input } from "../../theme/components/Input";
import { getAccountData, login } from "../../app/actions/accountActions";
import { useDispatch, useSelector } from "react-redux";
import { OneTimePassword } from "./OneTimePassword";
import FormError from "../../theme/components/FormError";
import { validateInput } from "../../utils/customValidators/validators";

export const Login = () => {
	const dispatch = useDispatch();
	const { authorized, isTwoFA, error } = useSelector(({ account }) => account);
	const { state } = useLocation();
	const history = useHistory();
	const [form, setForm] = useState({});
	const [errors, setErrors] = useState({});
	const [hidePassword, setHidePassword] = useState(true);

	const changeIcon = () => {
		setHidePassword(!hidePassword);
	};

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach(key => {
			errForm = {
				...errForm,
				[key]: validateInput(key, form[key], form, false),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	useEffect(() => {
		if (localStorage.getItem("access_token") && !authorized) {
			dispatch(getAccountData());
		}
		if (authorized) {
			history.push(state?.from?.pathname || "/");
		}
	}, [dispatch, history, authorized, state]);

	const handleOnChange = ({ target: { name, value } }) => {
		setErrors({ ...errors, [name]: validateInput(name, value, form, false) });
		setForm({ ...form, [name]: value.trim() });
	};

	const handleLogin = () => {
		if (validateForm()) {
			dispatch(login({ ...form, rememberMe: false }));
		}
	};
	return (
		<AuthWrapper>
			{!isTwoFA && (
				<div>
					<Typography variant='h2'>Sign in</Typography>
					<FormError
						isHidden={!Boolean(error)}
						errorMessage={"Invalid login or password."}
					/>
					<form>
						<Input
							variant='outlined'
							name={"username"}
							placeholder={"Enter username"}
							autoComplete='on'
							onChange={handleOnChange}
							error={errors?.username?.invalid}
							helperText={errors?.username?.errorMessage || ""}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<AccountCircleOutlinedIcon />
									</InputAdornment>
								),
							}}
						/>
						<Input
							placeholder='Enter password'
							variant='outlined'
							name={"password"}
							type={hidePassword ? "password" : "text"}
							autoComplete='on'
							onChange={handleOnChange}
							error={errors?.password?.invalid}
							helperText={errors?.password?.errorMessage || ""}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<LockOutlinedIcon />
									</InputAdornment>
								),
								endAdornment: hidePassword ? (
									<VisibilityOffIcon
										style={{ cursor: "pointer", fontSize: 20 }}
										onClick={() => changeIcon()}
									/>
								) : (
									<VisibilityIcon
										style={{ cursor: "pointer", fontSize: 20 }}
										onClick={() => changeIcon()}
									/>
								),
							}}
						/>
					</form>
					<Button variant='contained' color='primary' onClick={handleLogin}>
						Sign In
					</Button>
				</div>
			)}
			{isTwoFA && <OneTimePassword form={form} />}
		</AuthWrapper>
	);
};
