import { PHOTO_TYPES } from "../../utils/constants/bankCards";
import {
	GET_BANK_CARD_DETAILS_BY_ID,
	GET_ALL_BANK_CARDS_LIST,
	MAKE_DECISION_BY_CARD,
	GET_CARD_PHOTO_FILE,
	CLEAR_BANK_CARDS_LIST,
	CLEAR_BANK_CARD_DETAILS,
	CLEAR_BANK_CARDS_STATUS,
	CLEAR_BANK_CARDS_ERROR,
} from "../types";

export default function bankCards(
	state = {
		data: null,
		bankCardDetails: null,
		bankCardDetailsFiles: {
			[PHOTO_TYPES.SELFIE]: null,
			[PHOTO_TYPES.CARD]: null,
		},
		status: false,
		error: null,
		total: 0,
	},
	action
) {
	switch (action.type) {
		case GET_BANK_CARD_DETAILS_BY_ID.STARTED:
		case GET_ALL_BANK_CARDS_LIST.STARTED:
		case GET_CARD_PHOTO_FILE.STARTED:
			return { ...state, error: null };
		case MAKE_DECISION_BY_CARD.STARTED:
			return { ...state, status: true, error: null };
		case GET_BANK_CARD_DETAILS_BY_ID.FAILURE:
		case GET_ALL_BANK_CARDS_LIST.FAILURE:
		case MAKE_DECISION_BY_CARD.FAILURE:
		case GET_CARD_PHOTO_FILE.FAILURE:
			return { ...state, status: false, error: action.payload.error };
		case GET_CARD_PHOTO_FILE.SUCCESS:
			return {
				...state,
				bankCardDetailsFiles: { ...state.bankCardDetailsFiles, ...action.payload },
			};
		case GET_BANK_CARD_DETAILS_BY_ID.SUCCESS:
			return {
				...state,
				bankCardDetails: {
					...action.payload
				},
			};
		case GET_ALL_BANK_CARDS_LIST.SUCCESS:
			return {
				...state,
				data: action.payload?.content.map((i) => {
					return {
						...i
					};
				}),
				total: action.payload?.totalElements,
			};
		case MAKE_DECISION_BY_CARD.SUCCESS:
			return {
				...state,
				status: true,
			};
		case CLEAR_BANK_CARDS_LIST.SUCCESS:
			return {
				...state,
				data: null,
			};
		case CLEAR_BANK_CARD_DETAILS:
			return {
				...state,
				bankCardDetails: null,
				bankCardDetailsFiles: {
					[PHOTO_TYPES.SELFIE]: null,
					[PHOTO_TYPES.DOCUMENT_AVERS]: null,
					[PHOTO_TYPES.DOCUMENT_REVERS]: null,
				},
			};
		case CLEAR_BANK_CARDS_STATUS:
			return {
				...state,
				status: false,
			};
		case CLEAR_BANK_CARDS_ERROR:
			return {
				...state,
				error: null,
				status: false,
			};
		default:
			return state;
	}
}
