import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { IconButton, lighten, makeStyles } from "@material-ui/core";
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import AnyIcon from "@material-ui/icons/UnfoldMoreRounded";
import DownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import TopIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import ReceiveDialog from "./ReceiveDialog";
import { getInternalBalances } from "../../app/actions/internalBalancesActions";
import { getWalletsTotal } from "../../app/actions/coinsActions";
import {
	FILTER_INTERNAL_BALANCES_LIST,
	SORT_INTERNAL_BALANCES_LIST,
} from "../../app/types";
import PageTitle from "../../theme/components/PageTitle";
import { SORT_TYPES, next } from "../../utils/constants/sortTypes.js";

const InternalBalances = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const internalBalances = useSelector(
		({ internalBalances }) => internalBalances.filteredData
	);
	const walletsTotal = useSelector(({ coins }) => coins.walletsTotal);
	const [receiveExchange, setReceiveExchange] = useState(null);

	const [search, setSearch] = useState("");

	const [columns, setColumns] = useState([
		{
			key: "ticker",
			headerName: "Currency",
			sort: SORT_TYPES.none,
			type: "string",
		},
		{
			key: "balance",
			headerName: "System amount",
			sort: SORT_TYPES.none,
			type: "number",
		},
		{ key: "balanceInUsd", headerName: "Equivalent USD" },

		{ key: "totalBalanceUsers", headerName: "Users balance" },
		{ key: "balanceCompany", headerName: "Profit" },

		{ key: "status", headerName: "Status" },
		{ key: "receive", headerName: "SW Address" },
	]);

	const sorting = key => {
		const nextState = next(columns.find(s => s.key === key)?.sort);
		dispatch({
			type: SORT_INTERNAL_BALANCES_LIST,
			payload: {
				direction: nextState,
				key,
				type: columns.find(s => s.key === key)?.type,
			},
		});
		setColumns(
			columns.map(s =>
				s.key === key
					? { ...s, sort: nextState }
					: {
							...s,
							sort: s.sort ? SORT_TYPES.none : undefined,
					  }
			)
		);
	};

	useEffect(() => {
		dispatch(getInternalBalances());
		dispatch(getWalletsTotal());
	}, [dispatch]);

	const data = useMemo(() => {
		if (!internalBalances || !walletsTotal) {
			return;
		}
		return internalBalances.map(item => {
			const totalBalanceUsers = Number(
				walletsTotal.find(wallet => wallet.ticker === item.ticker).total
			);
			return {
				...item,
				totalBalanceUsers,
				balanceCompany: +(
					Number(item?.balance) - Number(totalBalanceUsers)
				).toFixed(8),
			};
		});
	}, [internalBalances, walletsTotal]);

	useEffect(() => {
		dispatch({ type: FILTER_INTERNAL_BALANCES_LIST, payload: search });
	}, [dispatch, search]);

	const handleSearch = search => {
		setSearch(search);
	};

	return (
		<div>
			<ReceiveDialog
				open={Boolean(receiveExchange)}
				handleClose={() => {
					setReceiveExchange(null);
				}}
				data={receiveExchange}
			/>
			<PageTitle title={"Internal balances"} onSearch={handleSearch} />
			<div className={classes.root}>
				<table className={classes.table}>
					<thead>
						<tr>
							{columns.map(({ key, headerName, sort, colSpan, align }) => (
								<th
									key={key}
									className={clsx(classes.th, classes.paper, sort && "hover")}
									colSpan={colSpan}
									align={align || "left"}
									onClick={() => sort && sorting(key)}>
									{sort === SORT_TYPES.up && <TopIcon />}
									{sort === SORT_TYPES.none && <AnyIcon />}
									{sort === SORT_TYPES.down && <DownIcon />}
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{data?.length > 0 ? (
							data.map((row, index) => (
								<TableRow
									key={row?.id || index}
									rowData={row}
									handleReceive={() => setReceiveExchange(row)}
								/>
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default InternalBalances;

function TableRow({ rowData, handleReceive }) {
	const classes = useStyles();
	return (
		<>
			<tr className={classes.spacing}></tr>
			<tr className={classes.hover}>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.name}({rowData?.ticker?.toUpperCase()})
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.balance || 0}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.balanceInUsd || 0}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.totalBalanceUsers || 0}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.balanceCompany || 0}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.status ? (
						<CheckIcon style={{ color: "#00ff00" }} />
					) : (
						<CloseIcon style={{ color: "#ff0000" }} />
					)}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.masterWalletAddress && (
						<IconButton onClick={handleReceive}>
							<AssignmentReturnedIcon />
						</IconButton>
					)}
				</td>
			</tr>
		</>
	);
}

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: 24,
		width: "100%",
		overflowX: "auto",
		paddingBottom: 24,
	},
	table: {
		borderSpacing: 0,
		width: "100%",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		"&:first-child": {
			borderRadius: "10px 0 0 10px",
		},
		"&:last-child": {
			borderRadius: "0 10px 10px 0",
		},
	},
	th: {
		color: theme.palette.text.secondary,
		padding: "14px 8px ",
		fontSize: 14,
		lineHeight: 2,
		cursor: "pointer",
		"&.hover:hover": {
			backgroundColor: lighten(theme.palette.background.paper, 0.04),
		},
		"&>svg": {
			fontSize: 19,
			verticalAlign: "middle",
			marginRight: 4,
		},
	},
	td: {
		color: theme.palette.text.primary,
		padding: "8px 14px",
		fontSize: 13,
		"& svg": {
			fontSize: 16,
		},
	},
	spacing: {
		height: 12,
	},
	hover: {
		"&:hover>td": {
			backgroundColor: lighten(theme.palette.background.paper, 0.04),
		},
	},
}));
