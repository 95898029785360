import {
	SEARCH_REFERRAL_BALANCES,
	SEARCH_CLAIM_HISTORY,
	SEARCH_REFERRAL_REWARDS,
	GET_DEFAULT_REFERRAL_SETTINGS,
	UPDATE_DEFAULT_REFERRAL_SETTINGS,
	GET_VIP_REFERRAL_SETTINGS,
	GET_VIP_REFERRAL_SETTINGS_BY_USER_ID,
	UPDATE_VIP_REFERRAL_SETTINGS,
	DELETE_VIP_REFERRAL_SETTINGS,
	CREATE_VIP_REFERRAL_SETTINGS,
	GET_REFERRALS,
	CHANGE_PARENT,
	CLEAR_REFERRALS_STATUS,
	CLEAR_REFERRALS_ERROR,
	GET_SELECTED_VIP_USER_REFERRAL_SETTINGS,
	CREATE_VIP_USER_REFERRAL_DEFAULT_SETTINGS,
	UPDATE_VIP_USER_REFERRAL_DEFAULT_SETTINGS,
	DELETE_VIP_USER_REFERRAL_DEFAULT_SETTINGS,
	DELETE_VIP_USER_REFERRAL_EXCHANGE_SETTINGS,
	UPDATE_VIP_USER_REFERRAL_EXCHANGE_SETTINGS,
	CREATE_VIP_USER_REFERRAL_EXCHANGE_SETTINGS,
	CREATE_VIP_USER_REFERRAL_FUND_SETTINGS,
	UPDATE_VIP_USER_REFERRAL_FUND_SETTINGS,
	DELETE_VIP_USER_REFERRAL_FUND_SETTINGS,
} from "../types";
import api from "../api";
import { actionCreator } from "../utils";

// SEARCH_REFERRAL_BALANCES
export const searchReferralBalancesAction = actionCreator(
	SEARCH_REFERRAL_BALANCES
);

export const searchReferralBalances = (params) => {
	return async (dispatch) => {
		dispatch(searchReferralBalancesAction.started());
		api.referrals
			.searchReferralBalances(params)
			.then((res) => dispatch(searchReferralBalancesAction.done(res.data)))
			.catch((error) => dispatch(searchReferralBalancesAction.failed(error)));
	};
};

// SEARCH_CLAIM_HISTORY

export const searchClaimHistoryAction = actionCreator(SEARCH_CLAIM_HISTORY);

export const searchClaimHistory = (params) => {
	return async (dispatch) => {
		dispatch(searchClaimHistoryAction.started());
		api.referrals
			.searchClaimHistory(params)
			.then((res) => dispatch(searchClaimHistoryAction.done(res.data)))
			.catch((error) => dispatch(searchClaimHistoryAction.failed(error)));
	};
};

// SEARCH_REFERRAL_REWARDS

export const searchReferralRewardAction = actionCreator(
	SEARCH_REFERRAL_REWARDS
);

export const searchReferralReward = (params) => {
	return async (dispatch) => {
		dispatch(searchReferralRewardAction.started());
		api.referrals
			.searchReferralReward(params)
			.then((res) => dispatch(searchReferralRewardAction.done(res.data)))
			.catch((error) => dispatch(searchReferralRewardAction.failed(error)));
	};
};

// GET_DEFAULT_REFERRAL_SETTINGS

export const getDefaultReferralSettingsAction = actionCreator(
	GET_DEFAULT_REFERRAL_SETTINGS
);

export const getDefaultReferralSettings = () => {
	return async (dispatch) => {
		dispatch(getDefaultReferralSettingsAction.started());
		api.referrals
			.getDefaultReferralSettings()
			.then((res) => dispatch(getDefaultReferralSettingsAction.done(res.data)))
			.catch((error) =>
				dispatch(getDefaultReferralSettingsAction.failed(error))
			);
	};
};

// UPDATE_DEFAULT_REFERRAL_SETTINGS

export const updateDefaultReferralSettingsAction = actionCreator(
	UPDATE_DEFAULT_REFERRAL_SETTINGS
);

export const updateDefaultReferralSettings = (data) => {
	return async (dispatch) => {
		dispatch(updateDefaultReferralSettingsAction.started());
		api.referrals
			.updateDefaultReferralSettings(data)
			.then((res) =>
				dispatch(updateDefaultReferralSettingsAction.done(res.data))
			)
			.catch((error) =>
				dispatch(updateDefaultReferralSettingsAction.failed(error))
			);
	};
};

// GET_VIP_REFERRAL_SETTINGS

export const getVipReferralSettingsAction = actionCreator(
	GET_VIP_REFERRAL_SETTINGS
);

export const getVipReferralSettings = (params) => {
	return async (dispatch) => {
		dispatch(getVipReferralSettingsAction.started());
		api.referrals
			.getVipReferralSettings(params)
			.then((res) => dispatch(getVipReferralSettingsAction.done(res.data)))
			.catch((error) => dispatch(getVipReferralSettingsAction.failed(error)));
	};
};

// GET_VIP_REFERRAL_SETTINGS_BY_USER_ID

export const getVipReferralSettingsByUserIdAction = actionCreator(
	GET_VIP_REFERRAL_SETTINGS_BY_USER_ID
);

export const getVipReferralSettingsByUserId = () => {
	return async (dispatch) => {
		dispatch(getVipReferralSettingsByUserIdAction.started());
		api.referrals
			.getVipReferralSettingsByUserId()
			.then((res) =>
				dispatch(getVipReferralSettingsByUserIdAction.done(res.data))
			)
			.catch((error) =>
				dispatch(getVipReferralSettingsByUserIdAction.failed(error))
			);
	};
};

// UPDATE_VIP_REFERRAL_SETTINGS

export const updateVipReferralSettingsAction = actionCreator(
	UPDATE_VIP_REFERRAL_SETTINGS
);

export const updateVipReferralSettings = (data, params) => {
	return async (dispatch) => {
		dispatch(updateVipReferralSettingsAction.started());
		api.referrals
			.updateVipReferralSettings(data)
			.then((res) => {
				dispatch(getVipReferralSettings(params));
				dispatch(updateVipReferralSettingsAction.done(res.data));
			})
			.catch((error) =>
				dispatch(updateVipReferralSettingsAction.failed(error))
			);
	};
};

// DELETE_VIP_REFERRAL_SETTINGS

export const deleteVipReferralSettingsAction = actionCreator(
	DELETE_VIP_REFERRAL_SETTINGS
);

export const deleteVipReferralSettings = (data, params) => {
	return async (dispatch) => {
		dispatch(deleteVipReferralSettingsAction.started());
		api.referrals
			.deleteVipReferralSettings(data)
			.then((res) => {
				dispatch(getVipReferralSettings(params));
				dispatch(deleteVipReferralSettingsAction.done(res.data));
			})
			.catch((error) =>
				dispatch(deleteVipReferralSettingsAction.failed(error))
			);
	};
};

// CREATE_VIP_REFERRAL_SETTINGS

export const createVipReferralSettingsAction = actionCreator(
	CREATE_VIP_REFERRAL_SETTINGS
);

export const createVipReferralSettings = (data) => {
	return async (dispatch) => {
		dispatch(createVipReferralSettingsAction.started());
		api.referrals
			.createVipReferralSettings(data)
			.then((res) => {
				dispatch(getVipReferralSettings());
				dispatch(createVipReferralSettingsAction.done(res.data));
			})
			.catch((error) =>
				dispatch(createVipReferralSettingsAction.failed(error))
			);
	};
};

// GET_REFERRALS

export const getReferralsAction = actionCreator(GET_REFERRALS);

export const getReferrals = () => {
	return async (dispatch) => {
		dispatch(getReferralsAction.started());
		api.referrals
			.getReferrals()
			.then((res) => dispatch(getReferralsAction.done(res.data)))
			.catch((error) => dispatch(getReferralsAction.failed(error)));
	};
};

// CHANGE_PARENT

export const changeParentAction = actionCreator(CHANGE_PARENT);

export const changeParent = (data) => {
	return async (dispatch) => {
		dispatch(changeParentAction.started());
		api.referrals
			.changeParent(data)
			.then((res) => {
				dispatch(getReferrals());
				dispatch(changeParentAction.done(res.data));
			})
			.catch((error) => dispatch(changeParentAction.failed(error)));
	};
};

export const clearReferralsStatus = () => ({
	type: CLEAR_REFERRALS_STATUS,
});

export const clearReferralsError = () => ({
	type: CLEAR_REFERRALS_ERROR,
});


// GET VIP USER PROFILE BY ID REFERRALS SETTINGS
export const getSelectedVipUserReferralAllSettingsAction = actionCreator(GET_SELECTED_VIP_USER_REFERRAL_SETTINGS);

export const getSelectedVipUserReferralAllSettings = (userId) => {
	return async (dispatch) => {
		dispatch(getSelectedVipUserReferralAllSettingsAction.started());
		api.referrals
			.getVipUserProfileReferralAllSettings(userId)
			.then((res) => dispatch(getSelectedVipUserReferralAllSettingsAction.done(res.data)))
			.catch((error) => dispatch(getSelectedVipUserReferralAllSettingsAction.failed(error)));
	};
};

export const createVipUserReferralDefaultSettingsAction = actionCreator(
	CREATE_VIP_USER_REFERRAL_DEFAULT_SETTINGS
);

export const createVipUserReferralDefaultSettings = ({
	userId,
	parentRewardPercent,
	grandParentRewardPercent
}) => {
	return async (dispatch) => {
		dispatch(createVipUserReferralDefaultSettingsAction.started());
		api.referrals
			.createVipUserProfileReferralDefaultSettings({
				userId,
				parentRewardPercent,
				grandParentRewardPercent
			})
			.then((res) => {
				dispatch(getSelectedVipUserReferralAllSettings(userId));
				dispatch(createVipUserReferralDefaultSettingsAction.done(res.data));
			})
			.catch((error) =>
				dispatch(createVipUserReferralDefaultSettingsAction.failed(error))
			);
	};
};

export const updateVipUserReferralDefaultSettingsAction = actionCreator(
	UPDATE_VIP_USER_REFERRAL_DEFAULT_SETTINGS
);

export const updateVipUserReferralDefaultSettings = ({
	id,
	userId,
	parentRewardPercent,
	grandParentRewardPercent
}) => {
	return async (dispatch) => {
		dispatch(updateVipUserReferralDefaultSettingsAction.started());
		api.referrals
			.updateVipUserProfileReferralDefaultSettings({
				id,
				parentRewardPercent,
				grandParentRewardPercent
			})
			.then((res) => {
				dispatch(getSelectedVipUserReferralAllSettings(userId));
				// get settings 
				dispatch(updateVipUserReferralDefaultSettingsAction.done(res.data));
			})
			.catch((error) =>
				dispatch(updateVipUserReferralDefaultSettingsAction.failed(error))
			);
	};
};

export const deleteVipUserReferralDefaultSettingsAction = actionCreator(
	DELETE_VIP_USER_REFERRAL_DEFAULT_SETTINGS
);

export const deleteVipUserReferralDefaultSettings = ({userId, settingsId}) => {
	return async (dispatch) => {
		dispatch(deleteVipUserReferralDefaultSettingsAction.started());
		api.referrals
			.deleteVipUserProfileReferralDefaultSettings(settingsId)
			.then((res) => {
				dispatch(getSelectedVipUserReferralAllSettings(userId));
				dispatch(deleteVipUserReferralDefaultSettingsAction.done(res.data));
			})
			.catch((error) =>
				dispatch(deleteVipUserReferralDefaultSettingsAction.failed(error))
			);
	};
};

export const createVipUserReferralExchangeSettingsAction = actionCreator(
	CREATE_VIP_USER_REFERRAL_EXCHANGE_SETTINGS
);

export const createVipUserReferralExchangeSettings = ({
	userId,
	parentRewardPercent,
	grandParentRewardPercent
}) => {
	return async (dispatch) => {
		dispatch(createVipUserReferralExchangeSettingsAction.started());
		api.referrals
			.createVipUserProfileReferralExchangeSettings({
				userId,
				parentRewardPercent,
				grandParentRewardPercent
			})
			.then((res) => {
				dispatch(getSelectedVipUserReferralAllSettings(userId));
				dispatch(createVipUserReferralExchangeSettingsAction.done(res.data));
			})
			.catch((error) =>
				dispatch(createVipUserReferralExchangeSettingsAction.failed(error))
			);
	};
};

export const updateVipUserReferralExchangeSettingsAction = actionCreator(
	UPDATE_VIP_USER_REFERRAL_EXCHANGE_SETTINGS
);

export const updateVipUserReferralExchangeSettings = ({
	id,
	userId,
	parentRewardPercent,
	grandParentRewardPercent
}) => {
	return async (dispatch) => {
		dispatch(updateVipUserReferralExchangeSettingsAction.started());
		api.referrals
			.updateVipUserProfileReferralExchangeSettings({
				id,
				parentRewardPercent,
				grandParentRewardPercent
			})
			.then((res) => {
				dispatch(getSelectedVipUserReferralAllSettings(userId));
				// get settings 
				dispatch(updateVipUserReferralExchangeSettingsAction.done(res.data));
			})
			.catch((error) =>
				dispatch(updateVipUserReferralExchangeSettingsAction.failed(error))
			);
	};
};

export const deleteVipUserReferralExchangeSettingsAction = actionCreator(
	DELETE_VIP_USER_REFERRAL_EXCHANGE_SETTINGS
);

export const deleteVipUserReferralExchangeSettings = ({ userId, settingsId }) => {
	return async (dispatch) => {
		dispatch(deleteVipUserReferralExchangeSettingsAction.started());
		api.referrals
			.deleteVipUserProfileReferralExchangeSettings(settingsId)
			.then((res) => {
				dispatch(getSelectedVipUserReferralAllSettings(userId));
				dispatch(deleteVipUserReferralExchangeSettingsAction.done(res.data));
			})
			.catch((error) =>
				dispatch(deleteVipUserReferralExchangeSettingsAction.failed(error))
			);
	};
};


export const createVipUserReferralFundSettingsAction = actionCreator(
	CREATE_VIP_USER_REFERRAL_FUND_SETTINGS
);

export const createVipUserReferralFundSettings = ({
	userId,
	parentRewardPercent,
	grandParentRewardPercent
}) => {
	return async (dispatch) => {
		dispatch(createVipUserReferralFundSettingsAction.started());
		api.referrals
			.createVipUserProfileReferralFundSettings({
				userId,
				parentRewardPercent,
				grandParentRewardPercent
			})
			.then((res) => {
				dispatch(getSelectedVipUserReferralAllSettings(userId));
				dispatch(createVipUserReferralFundSettingsAction.done(res.data));
			})
			.catch((error) =>
				dispatch(createVipUserReferralFundSettingsAction.failed(error))
			);
	};
};

export const updateVipUserReferralFundSettingsAction = actionCreator(
	UPDATE_VIP_USER_REFERRAL_FUND_SETTINGS
);

export const updateVipUserReferralFundSettings = ({
	id,
	userId,
	parentRewardPercent,
	grandParentRewardPercent
}) => {
	return async (dispatch) => {
		dispatch(updateVipUserReferralFundSettingsAction.started());
		api.referrals
			.updateVipUserProfileReferralFundSettings({
				id,
				parentRewardPercent,
				grandParentRewardPercent
			})
			.then((res) => {
				dispatch(getSelectedVipUserReferralAllSettings(userId));
				// get settings 
				dispatch(updateVipUserReferralFundSettingsAction.done(res.data));
			})
			.catch((error) =>
				dispatch(updateVipUserReferralFundSettingsAction.failed(error))
			);
	};
};

export const deleteVipUserReferralFundSettingsAction = actionCreator(
	DELETE_VIP_USER_REFERRAL_FUND_SETTINGS
);

export const deleteVipUserReferralFundSettings = ({userId, settingsId}) => {
	return async (dispatch) => {
		dispatch(deleteVipUserReferralFundSettingsAction.started());
		api.referrals
			.deleteVipUserProfileReferralFundSettings(settingsId)
			.then((res) => {
				dispatch(getSelectedVipUserReferralAllSettings(userId));
				dispatch(deleteVipUserReferralFundSettingsAction.done(res.data));
			})
			.catch((error) =>
				dispatch(deleteVipUserReferralFundSettingsAction.failed(error))
			);
	};
};