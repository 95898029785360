import {
	GET_CARD_PHOTO_FILE,
	MAKE_DECISION_BY_CARD,
	GET_ALL_BANK_CARDS_LIST,
	CLEAR_BANK_CARDS_LIST,
	CLEAR_BANK_CARD_DETAILS,
	CLEAR_BANK_CARDS_ERROR,
	GET_BANK_CARD_DETAILS_BY_ID,
	CLEAR_BANK_CARDS_STATUS,
} from "../types";
import api from "../api";
import { actionCreator } from "../utils";
import { Buffer } from "buffer";

// GET_BANK_CARD_DETAILS_BY_ID
export const getBankCardByIdAction = actionCreator(GET_BANK_CARD_DETAILS_BY_ID);

export const getBankCardById = (cardId) => {
	return async (dispatch) => {
		dispatch(getBankCardByIdAction.started());
		api.bankCards
			.getBankCardDetailsById(cardId)
			.then((res) => dispatch(getBankCardByIdAction.done(res.data)))
			.catch((error) => dispatch(getBankCardByIdAction.failed(error)));
	};
};

// GET_CARD_PHOTO_FILE
export const getCardPhotoFileAction = actionCreator(GET_CARD_PHOTO_FILE);

export const getCardPhotoFile = (photoId, type) => {
	return async (dispatch) => {
		dispatch(getCardPhotoFileAction.started());
		api.bankCards
			.getPhotoFile(photoId)
			.then((res) => {
				dispatch(
					getCardPhotoFileAction.done({
						[type]: Buffer.from(res.data, "binary").toString("base64"),
					})
				);
			})
			.catch((error) => dispatch(getCardPhotoFileAction.failed(error)));
	};
};

// MAKE_DECISION_BY_CARD
export const makeDecisionByCardAction = actionCreator(MAKE_DECISION_BY_CARD);

export const makeDecisionByCard = (data) => {
	return async (dispatch) => {
		dispatch(makeDecisionByCardAction.started());
		api.bankCards
			.makeDecisionByCard(data)
			.then((res) => {
				dispatch(makeDecisionByCardAction.done(res.data));
				dispatch(getBankCardById(data?.cardId));
			})
			.catch((error) => dispatch(makeDecisionByCardAction.failed(error)));
	};
};

// GET_ALL_BANK_CARDS_LIST
export const getAllBankCardsListAction = actionCreator(GET_ALL_BANK_CARDS_LIST);

export const getAllBankCardsList = (params) => {
	return async (dispatch) => {
		dispatch(getAllBankCardsListAction.started());
		api.bankCards
			.getAllBankCardsList(params)
			.then((res) => dispatch(getAllBankCardsListAction.done(res.data)))
			.catch((error) => dispatch(getAllBankCardsListAction.failed(error)));
	};
};

export const clearBankCardsList = () => ({
	type: CLEAR_BANK_CARDS_LIST,
});

export const clearBankCardDetails = () => ({
	type: CLEAR_BANK_CARD_DETAILS,
});
export const clearBankCardsStatus = () => ({
	type: CLEAR_BANK_CARDS_STATUS,
});

export const clearBankCardsError = () => ({
	type: CLEAR_BANK_CARDS_ERROR,
});
