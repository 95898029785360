import {
	CREATE_STABLE_INVESTMENT_FUND,
	CREATE_FLOATING_INVESTMENT_FUND,
	UPDATE_STABLE_INVESTMENT_FUND,
	UPDATE_FLOATING_INVESTMENT_FUND,
	DELETE_INVESTMENT_FUND,
	SEARCH_INVESTMENT_FUNDS,
	GET_TOKEN_PRICES,
	SEARCH_SELL_INVOICES,
	MAKE_DECISION_BY_INVOICE,
	SEARCH_FUND_OPERATIONS_HISTORY,
	SEARCH_USER_FUND_BALANCES,
	CLEAR_USER_FUND_BALANCES,
	GET_SYSTEM_BALANCES_FUNDS,
	CLEAR_FUNDS_SYSTEM_BALANCES,
	SEND_FROM_SYSTEM_BALANCE_TO_WALLET_BY_ADDRESS,
	SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_FUND_TO_WALLET,
	CLEAR_TRANSFERS_SYSTEM_BALANCE_FUND,
	DISTRIBUTE_DIVIDENDS,
	SEARCH_DIVIDENDS_DISTRIBUTION_PROGRAMS,
	SEARCH_DIVIDENDS,
	CLEAR_FUNDS_OPERATIONS_HISTORY,
	CLEAR_FUNDS_STATUS,
	CLEAR_FUNDS_ERROR,
	GET_AVAILABLE_VIP_FUNDS,
	GET_USER_ENABLED_PRIVATE_FUNDS,
	CLEAR_USER_ENABLED_PRIVATE_FUNDS,
	GET_ESTIMATE_DIVIDENDS_INFO,
	GET_FUND_PAYOUT_BALANCE_HISTORY,
	CREATE_FUND_PAYOUT_BALANCE,
	GET_ALL_FUND_PAYOUT_BALANCES,
	CLEAR_FUND_PAYOUT_BALANCE_HISTORY
} from "../types";

export default function funds(
	state = {
		funds: {
			content: [],
			totalElements: 0,
		},
		fundsPayoutBalances: {
			content: [],
			totalElements: 0,
		},
		fundPayoutBalanceHistory: {
			content: [],
			totalElements: 0,
		},
		tokenPrice: [],
		sellInvoices: {
			content: [],
			totalElements: 0,
		},
		fundsOperationsHistory: {
			content: [],
			totalElements: 0,
		},
		userFundBalance: {
			content: [],
			totalElements: 0,
		},
		systemBalancesFunds: [],
		transfersFromSystemBalances: {
			content: [],
			totalElements: 0,
		},
		dividendsDistributionPrograms: {
			content: [],
			totalElements: 0,
		},
		dividends: {
			content: [],
			totalElements: 0,
		},
		availableVipFunds: [],
		userEnabledPrivateFunds: [],
		estimatedDividendsInfo: {},
		status: false,
		error: null,
	},
	action
) {
	switch (action.type) {
		case CREATE_STABLE_INVESTMENT_FUND.STARTED:
		case CREATE_FLOATING_INVESTMENT_FUND.STARTED:
		case UPDATE_STABLE_INVESTMENT_FUND.STARTED:
		case UPDATE_FLOATING_INVESTMENT_FUND.STARTED:
		case DELETE_INVESTMENT_FUND.STARTED:
		case SEARCH_INVESTMENT_FUNDS.STARTED:
		case GET_TOKEN_PRICES.STARTED:
		case SEARCH_SELL_INVOICES.STARTED:
		case MAKE_DECISION_BY_INVOICE.STARTED:
		case SEARCH_FUND_OPERATIONS_HISTORY.STARTED:
		case SEARCH_USER_FUND_BALANCES.STARTED:
		case GET_SYSTEM_BALANCES_FUNDS.STARTED:
		case SEND_FROM_SYSTEM_BALANCE_TO_WALLET_BY_ADDRESS.STARTED:
		case SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_FUND_TO_WALLET.STARTED:
		case DISTRIBUTE_DIVIDENDS.STARTED:
		case SEARCH_DIVIDENDS_DISTRIBUTION_PROGRAMS.STARTED:
		case SEARCH_DIVIDENDS.STARTED:
		case GET_ESTIMATE_DIVIDENDS_INFO.STARTED:
		case GET_FUND_PAYOUT_BALANCE_HISTORY.STARTED:
		case GET_ALL_FUND_PAYOUT_BALANCES.STARTED:
		case CREATE_FUND_PAYOUT_BALANCE.STARTED:
			return { ...state, status: false, error: null };
		case CREATE_STABLE_INVESTMENT_FUND.FAILURE:
		case CREATE_FLOATING_INVESTMENT_FUND.FAILURE:
		case UPDATE_STABLE_INVESTMENT_FUND.FAILURE:
		case UPDATE_FLOATING_INVESTMENT_FUND.FAILURE:
		case DELETE_INVESTMENT_FUND.FAILURE:
		case SEARCH_INVESTMENT_FUNDS.FAILURE:
		case GET_TOKEN_PRICES.FAILURE:
		case SEARCH_SELL_INVOICES.FAILURE:
		case MAKE_DECISION_BY_INVOICE.FAILURE:
		case SEARCH_FUND_OPERATIONS_HISTORY.FAILURE:
		case SEARCH_USER_FUND_BALANCES.FAILURE:
		case GET_SYSTEM_BALANCES_FUNDS.FAILURE:
		case SEND_FROM_SYSTEM_BALANCE_TO_WALLET_BY_ADDRESS.FAILURE:
		case SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_FUND_TO_WALLET.FAILURE:
		case DISTRIBUTE_DIVIDENDS.FAILURE:
		case SEARCH_DIVIDENDS_DISTRIBUTION_PROGRAMS.FAILURE:
		case GET_AVAILABLE_VIP_FUNDS.FAILURE:
		case SEARCH_DIVIDENDS.FAILURE:
		case GET_ESTIMATE_DIVIDENDS_INFO.FAILURE:
		case GET_FUND_PAYOUT_BALANCE_HISTORY.FAILURE:
		case GET_ALL_FUND_PAYOUT_BALANCES.FAILURE:
		case CREATE_FUND_PAYOUT_BALANCE.FAILURE:
			return { ...state, status: false, error: action.payload.error };
		case CREATE_STABLE_INVESTMENT_FUND.SUCCESS:
		case CREATE_FLOATING_INVESTMENT_FUND.SUCCESS:
		case UPDATE_STABLE_INVESTMENT_FUND.SUCCESS:
		case UPDATE_FLOATING_INVESTMENT_FUND.SUCCESS:
		case SEND_FROM_SYSTEM_BALANCE_TO_WALLET_BY_ADDRESS.SUCCESS:
			return { ...state, status: true };
		case SEARCH_SELL_INVOICES.SUCCESS:
			return {
				...state,
				sellInvoices: action.payload,
			};
		case SEARCH_FUND_OPERATIONS_HISTORY.SUCCESS:
			return {
				...state,
				fundsOperationsHistory: action.payload,
			};

		case SEARCH_INVESTMENT_FUNDS.SUCCESS:
			return {
				...state,
				funds: action.payload,
			};
		case GET_SYSTEM_BALANCES_FUNDS.SUCCESS:
			return {
				...state,
				systemBalancesFunds: action.payload,
			};
		case SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_FUND_TO_WALLET.SUCCESS:
			return {
				...state,
				transfersFromSystemBalances: action.payload,
			};
		case SEARCH_USER_FUND_BALANCES.SUCCESS:
			return {
				...state,
				userFundBalance: action.payload,
			};
		case SEARCH_DIVIDENDS_DISTRIBUTION_PROGRAMS.SUCCESS:
			return {
				...state,
				dividendsDistributionPrograms: action.payload,
			};
		case SEARCH_DIVIDENDS.SUCCESS:
			return {
				...state,
				dividends: action.payload
			};
		case GET_AVAILABLE_VIP_FUNDS.SUCCESS:
			return {
				...state,
				availableVipFunds: action.payload,
			}
		case GET_USER_ENABLED_PRIVATE_FUNDS.SUCCESS:
			return {
				...state,
				userEnabledPrivateFunds: action.payload,
			}
		case GET_ESTIMATE_DIVIDENDS_INFO.SUCCESS:
			return {
				...state,
				estimatedDividendsInfo: action.payload,
			}
		case GET_FUND_PAYOUT_BALANCE_HISTORY.SUCCESS:
			return {
				...state,
				fundPayoutBalanceHistory: action.payload,
			}
		case GET_ALL_FUND_PAYOUT_BALANCES.SUCCESS:
			return {
				...state,
				fundsPayoutBalances: action.payload,
			}

		case CLEAR_USER_ENABLED_PRIVATE_FUNDS:
			return {
				...state,
				userEnabledPrivateFunds: [],
			}

		case CLEAR_FUNDS_SYSTEM_BALANCES:
			return {
				...state,
				systemBalancesFunds: [],
			};

		case CLEAR_FUND_PAYOUT_BALANCE_HISTORY:
			return {
				...state,
				fundPayoutBalanceHistory: {
					content: [],
					totalElements: 0,
				},
			};

		case CLEAR_USER_FUND_BALANCES:
			return {
				...state,
				userFundBalance: {
					content: [],
					totalElements: 0,
				},
			};
		case CLEAR_TRANSFERS_SYSTEM_BALANCE_FUND:
			return {
				...state,
				transfersFromSystemBalances: {
					content: [],
					totalElements: 0,
				},
			};
		case CLEAR_FUNDS_OPERATIONS_HISTORY:
			return {
				...state,
				indexesOperationsHistory: {
					content: [],
					totalElements: 0,
				},
			};
		case CLEAR_FUNDS_STATUS:
			return {
				...state,
				status: false,
				error: null,
			};
		case CLEAR_FUNDS_ERROR:
			return {
				...state,
				error: null,
				status: false,
			};
		default:
			return state;
	}
}
