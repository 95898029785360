import {
	GET_PROVIDERS,
	CREATE_NEW_PROVIDER,
	UPDATE_PROVIDER,
	DELETE_PROVIDER,
	TRANSFER_FROM_BANKING_BALANCE_TO_WALLET,
	GET_ALL_BANKING_BALANCES,
	GET_ALL_BANKING_SETTINGS,
	UPDATE_BANKING_SETTINGS,
	MAKE_DECISION_BY_PENDING_TRANSACTION,
	CLEAR_BANKING_STATUS,
	CLEAR_BANKING_ERROR,
	UPDATE_ALL_BANKING_BALANCES,
	GET_BANKING_HISTORY,
	GET_LIQUIDITY_PROVIDERS_SETTINGS,
	CREATE_LIQUIDITY_PROVIDER_SETTINGS,
	UPDATE_LIQUIDITY_PROVIDER_SETTINGS,
	DELETE_LIQUIDITY_PROVIDER_SETTINGS,
	GET_LIQUIDITY_PROVIDERS_OFFERS,
} from "../types";
import api from "../api";
import { actionCreator } from "../utils";
import { getTransactions } from "./transactionsActions";

// GET_PROVIDERS
export const getBankingHistoryAction = actionCreator(GET_BANKING_HISTORY);

export const getBankingHistory = params => {
	return async dispatch => {
		dispatch(getBankingHistoryAction.started());
		api.banking
			.getBankingHistory(params)
			.then(res => dispatch(getBankingHistoryAction.done(res.data)))
			.catch(error => dispatch(getBankingHistoryAction.failed(error)));
	};
};

// GET_PROVIDERS
export const getProvidersAction = actionCreator(GET_PROVIDERS);

export const getProviders = () => {
	return async dispatch => {
		dispatch(getProvidersAction.started());
		api.banking
			.getProviders()
			.then(res => dispatch(getProvidersAction.done(res.data)))
			.catch(error => dispatch(getProvidersAction.failed(error)));
	};
};

// CREATE_NEW_PROVIDER
export const createNewProviderAction = actionCreator(CREATE_NEW_PROVIDER);

export const createNewProvider = data => {
	return async dispatch => {
		dispatch(createNewProviderAction.started());
		api.banking
			.createNewProvider(data)
			.then(res => {
				dispatch(getProviders());
				dispatch(createNewProviderAction.done(res.data));
			})
			.catch(error => dispatch(createNewProviderAction.failed(error)));
	};
};

// UPDATE_PROVIDER
export const updateProviderAction = actionCreator(UPDATE_PROVIDER);

export const updateProvider = data => {
	return async dispatch => {
		dispatch(updateProviderAction.started());
		api.banking
			.updateProvider(data)
			.then(res => {
				dispatch(getProviders());
				dispatch(updateProviderAction.done(res.data));
			})
			.catch(error => dispatch(updateProviderAction.failed(error)));
	};
};

// DELETE_PROVIDER
export const deleteProviderAction = actionCreator(DELETE_PROVIDER);

export const deleteProvider = provider => {
	return async dispatch => {
		dispatch(deleteProviderAction.started());
		api.banking
			.deleteProvider(provider)
			.then(res => {
				dispatch(getProviders());
				dispatch(deleteProviderAction.done(res.data));
			})
			.catch(error => dispatch(deleteProviderAction.failed(error)));
	};
};

// TRANSFER_FROM_BANKING_BALANCE_TO_WALLET
export const transferFromBankingBalanceToWalletAction = actionCreator(
	TRANSFER_FROM_BANKING_BALANCE_TO_WALLET
);

export const transferFromBankingBalanceToWallet = data => {
	return async dispatch => {
		dispatch(transferFromBankingBalanceToWalletAction.started());
		api.banking
			.transferFromBankingBalanceToWallet(data)
			.then(res => {
				dispatch(getAllBankingBalances());
				dispatch(transferFromBankingBalanceToWalletAction.done(res.data));
			})
			.catch(error =>
				dispatch(transferFromBankingBalanceToWalletAction.failed(error))
			);
	};
};

// GET_ALL_BANKING_BALANCES
export const getAllBankingBalancesAction = actionCreator(
	GET_ALL_BANKING_BALANCES
);

export const getAllBankingBalances = () => {
	return async dispatch => {
		dispatch(getAllBankingBalancesAction.started());
		api.banking
			.getAllBankingBalances()
			.then(res => {
				dispatch(getAllBankingBalancesAction.done(res.data));
			})
			.catch(error => dispatch(getAllBankingBalancesAction.failed(error)));
	};
};

// UPDATE_ALL_BANKING_BALANCES
export const updateAllBankingBalancesAction = actionCreator(
	UPDATE_ALL_BANKING_BALANCES
);

export const updateAllBankingBalances = (data) => {
	return async dispatch => {
		dispatch(updateAllBankingBalancesAction.started());
		api.banking
			.updateBankingBalances(data)
			.then(res => {
				dispatch(getAllBankingBalances());
				dispatch(updateAllBankingBalancesAction.done(res.data));
			})
			.catch(error => dispatch(updateAllBankingBalancesAction.failed(error)));
	};
};

// GET_ALL_BANKING_SETTINGS
export const getAllBankingSettingsAction = actionCreator(
	GET_ALL_BANKING_SETTINGS
);

export const getAllBankingSettings = () => {
	return async dispatch => {
		dispatch(getAllBankingSettingsAction.started());
		api.banking
			.getAllBankingSettings()
			.then(res => dispatch(getAllBankingSettingsAction.done(res.data)))
			.catch(error => dispatch(getAllBankingSettingsAction.failed(error)));
	};
};

// UPDATE_BANKING_SETTINGS
export const updateBankingSettingsAction = actionCreator(
	UPDATE_BANKING_SETTINGS
);

export const updateBankingSettings = data => {
	return async dispatch => {
		dispatch(updateBankingSettingsAction.started());
		api.banking
			.updateBankingSettings(data)
			.then(res => {
				dispatch(getAllBankingSettings());
				dispatch(updateBankingSettingsAction.done(res.data));
			})
			.catch(error => dispatch(updateBankingSettingsAction.failed(error)));
	};
};

// MAKE_DECISION_BY_PENDING_TRANSACTION
export const makeDecisionByPendingTransactionAction = actionCreator(
	MAKE_DECISION_BY_PENDING_TRANSACTION
);

export const makeDecisionByPendingTransaction = data => {
	return async dispatch => {
		dispatch(makeDecisionByPendingTransactionAction.started());
		api.banking
			.makeDecisionByPendingTransaction(data)
			.then(res => {
				getTransactions({
					"transactionStatus.equals": "Waiting_for_manually_confirmation",
				});
				dispatch(makeDecisionByPendingTransactionAction.done(res.data));
			})
			.catch(error =>
				dispatch(makeDecisionByPendingTransactionAction.failed(error))
			);
	};
};


// LIQUIDITY PROVIDERS
// GET_PROVIDERS
export const getAllLiquidityProvidersSettingsAction = actionCreator(GET_LIQUIDITY_PROVIDERS_SETTINGS);

export const getAllLiquidityProvidersSettings = (params) => {
	return async dispatch => {
		dispatch(getAllLiquidityProvidersSettingsAction.started());
		api.banking
			.liquidityProviders
			.getAllLiquidityProviderSettingsList(params)
			.then(res => dispatch(getAllLiquidityProvidersSettingsAction.done(res.data)))
			.catch(error => dispatch(getAllLiquidityProvidersSettingsAction.failed(error)));
	};
};

// CREATE LIQUIDITY PROVIDER
export const createLiquidityProviderSettingsAction = actionCreator(CREATE_LIQUIDITY_PROVIDER_SETTINGS);

export const createLiquidityProviderSettings = (data, params) => {
	return async dispatch => {
		dispatch(createLiquidityProviderSettingsAction.started());
		api.banking
			.liquidityProviders
			.createLiquidityProviderSettings(data)
			.then(res => {
				dispatch(createLiquidityProviderSettingsAction.done(res.data));
				dispatch(getAllLiquidityProvidersSettings(params));
			})
			.catch(error => dispatch(createLiquidityProviderSettingsAction.failed(error)));
	};
};

// UPDATE LIQUIDITY PROVIDER
export const updateLiquidityProviderSettingsAction = actionCreator(UPDATE_LIQUIDITY_PROVIDER_SETTINGS);

export const updateLiquidityProviderSettings = (data, params) => {
	return async dispatch => {
		dispatch(updateLiquidityProviderSettingsAction.started());
		api.banking
			.liquidityProviders
			.updateLiquidityProviderSettings(data)
			.then(res => {
				dispatch(updateLiquidityProviderSettingsAction.done(res.data));
				dispatch(getAllLiquidityProvidersSettings(params));
			})
			.catch(error => dispatch(updateLiquidityProviderSettingsAction.failed(error)));
	};
};

// DELETE LIQUIDITY PROVIDER
export const deleteLiquidityProviderSettingsAction = actionCreator(DELETE_LIQUIDITY_PROVIDER_SETTINGS);

export const deleteLiquidityProviderSettings = (data, params) => {
	return async dispatch => {
		dispatch(deleteLiquidityProviderSettingsAction.started());
		api.banking
			.liquidityProviders
			.deleteLiquidityProviderSettings(data)
			.then(res => {
				dispatch(deleteLiquidityProviderSettingsAction.done(res.data));
				dispatch(getAllLiquidityProvidersSettings(params));
			})
			.catch(error => dispatch(deleteLiquidityProviderSettingsAction.failed(error)));
	};
};

// LIQUIDITY PROVIDERS
// GET_PROVIDERS
export const getLiquidityProvidersOffersList = actionCreator(GET_LIQUIDITY_PROVIDERS_OFFERS);

export const getLiquidityProvidersOffers = (params) => {
	return async dispatch => {
		dispatch(getLiquidityProvidersOffersList.started());
		api.banking
			.liquidityProviders
			.getLiquidityProvidersOffersList(params)
			.then(res => dispatch(getLiquidityProvidersOffersList.done(res.data)))
			.catch(error => dispatch(getLiquidityProvidersOffersList.failed(error)));
	};
};

export const clearBankingStatus = () => ({
	type: CLEAR_BANKING_STATUS,
});

export const clearBankingError = () => ({
	type: CLEAR_BANKING_ERROR,
});
