import { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	ButtonBase,
	InputAdornment,
	TextareaAutosize,
} from "@material-ui/core";
import { Input } from "../../../../../theme/components/Input";
import { Button } from "../../../../../theme/components/Button";
import DialogWrapper from "../../../../../theme/components/dialogs/DialogWrapper";
import { requiredValidator, validateInput as validateInputMethod } from "../../../../../utils/customValidators/validators";
import { useValidateForm } from "../../../../../utils/hooks/useValidateForm";
import { withdrawValidator } from "../../../../../utils/customValidators/withdrawValidator";

const initialForm = {
	asset: "",
	addressTo: "",
	amount: "",
	description: "",
};

const TransferBalanceDialog = ({ open, handleClose, data, handleTransfer, title }) => {
	const [form, setForm] = useState(initialForm);
	const { validateForm, validateInput, errors, setErrors } = useValidateForm({
		form, customValidator: requiredValidator, customFieldValidator: {
			'description': validateInputMethod,
			'amount': withdrawValidator,
		}
	})

	useEffect(() => {
		if (!!data) {
			setForm({ ...form, asset: data?.asset });
		}
		// eslint-disable-next-line
	}, [data]);

	const closeDialog = () => {
		setForm(initialForm);
		setErrors({});
		handleClose();
	};

	const handleChange = ({ target: { name, value } }) => {
		validateInput({ target: { name, value } });
		setForm({ ...form, [name]: value });
	};

	const handleChangeAmount = value => {
		validateInput({ target: { name: 'amount', value } });
		setForm({ ...form, amount: value });
	};

	const handleSave = () => {
		if (!validateForm()) {
			return;
		}
		handleTransfer(form);
		setForm(initialForm)
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				{title.toUpperCase()}
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Input
						label={"Address"}
						variant={"outlined"}
						type={"text"}
						name={"addressTo"}
						value={form?.addressTo}
						error={errors?.addressTo?.invalid}
						helperText={errors?.addressTo?.errorMessage}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Input
						label={`Amount ${form?.asset}`}
						variant='outlined'
						name={"amount"}
						value={form?.amount || ""}
						onChange={({ target: { value } }) =>
							/^[0-9]*\.?[0-9]*$/.test(value) &&
							!(value.length > 1 && value[0] === "0" && value[1] !== ".") &&
							handleChangeAmount(value)
						}
						onFocus={() =>
							!form?.amount
								? setForm({
									...form,
									amount: "",
								})
								: setForm({ ...form })
						}
						onBlur={() => !form?.amount && setForm({ ...form, amount: 0 })}
						error={errors?.amount?.invalid}
						helperText={errors?.amount?.errorMessage}
						InputLabelProps={{}}
						InputProps={{
							autoComplete: "off",
							endAdornment: (
								<InputAdornment position='end'>
									<ButtonBase disabled>
										<img
											src={`https://cryptoneed.com/icons/${form?.asset}.svg`}
											alt={form?.asset}
											width={24}
											height={24}
											loading={"lazy"}
										/>
										{form?.asset?.toUpperCase()}
									</ButtonBase>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<Input
						fullWidth
						multiline
						label={'Description (optional)'}
						variant={"outlined"}
						InputProps={{
							component: TextareaAutosize,
							minRows: 3,
							maxRows: 3,
						}}
						name={'description'}
						value={form['description'] || ""}
						error={errors['description']?.invalid}
						helperText={errors['description']?.errorMessage}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)" }}
							onClick={handleSave}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default TransferBalanceDialog;
