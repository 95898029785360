import DialogWrapper from "../../theme/components/dialogs/DialogWrapper";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Input } from "../../theme/components/Input";
import { Button } from "../../theme/components/Button";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { validateInput } from "../../utils/customValidators/validators";
import { createCompany, updateCompany } from "../../app/actions/companiesActions";

const initialForm = {
	companyName: "",
	shortName: "",
};

const CreateCompanyDialog = ({ open, handleClose, data, params }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [form, setForm] = useState(initialForm);
	const [errors, setErrors] = useState({});

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach(key => {
			errForm = { ...errForm, [key]: validateInput(key, form[key]) };
			errForm[key].invalid && (valid = false);
		});

		setErrors(errForm);
		return valid;
	};

	const handleChange = event => {
		event.preventDefault();
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
		handleInputValidation(event);
	};

	useEffect(() => {
		if (data) {
			setForm({ companyName: data?.companyName, shortName: data?.shortName })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	const handleCreate = () => {
		if (!validateForm()) {
			return;
		}
		if (data) {
			dispatch(updateCompany({ id: data?.id, ...form, params }))
		} else {
			dispatch(createCompany(form));
		}
		handleCloseDialog();
	};

	const handleInputValidation = event => {
		const { name, value } = event.target;
		const errorField = validateInput(name, value);
		setErrors({
			...errors,
			[name]: errorField,
		});
	};

	const handleCloseDialog = () => {
		setErrors({});
		setForm(initialForm);
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={handleCloseDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				Create company
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} className={classes.formCroup}>
					<Input
						type='text'
						error={errors?.companyName?.invalid}
						label='Company name'
						variant={"outlined"}
						name={"companyName"}
						value={form?.companyName || ""}
						onChange={handleChange}
						onBlur={handleInputValidation}
						helperText={errors?.companyName?.errorMessage}
					/>
				</Grid>
				<Grid item xs={12} className={classes.formCroup}>
					<Input
						type='text'
						error={errors?.shortName?.invalid}
						label='Short name'
						variant={"outlined"}
						name={"shortName"}
						value={form?.shortName || ""}
						onChange={handleChange}
						onBlur={handleInputValidation}
						helperText={errors?.shortName?.errorMessage}
					/>
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)", marginTop: 12 }}
							onClick={handleCreate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default CreateCompanyDialog;

const useStyles = makeStyles(theme => ({
	formCroup: {
		display: "flex",
		flexDirection: "column",
	},
	errorField: {
		color: theme.palette.text.error,
		fontSize: 12,
		position: "absolute",
		marginTop: 56,
	},
	success: {
		color: theme.palette.text.success,
	},
	error: {
		color: theme.palette.text.error,
	},
}));
