import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { Input } from "../../../theme/components/Input";
import { Button } from "../../../theme/components/Button";
import DialogWrapper from "../../../theme/components/dialogs/DialogWrapper";
import {
	createDevice,
	editDevice,
	clearDhsStatus,
} from "../../../app/actions/dhsActions";
import { requiredValidator } from "../../../utils/customValidators/validators";

const createFormFields = [
	{ field: "deviceId", type: "text", label: "Device Id" },
];

const editFormFields = [
	{ field: "deviceId", type: "text", label: "Device Id" },
	{ field: "ownerId", type: "text", label: "Owner Id" },
	{ field: "totalShares", type: "text", label: "Total Shares" },
];

const EditDeviceDialog = ({ open, handleClose, data = null, params }) => {
	const dispatch = useDispatch();
	const { status } = useSelector(({ dhs }) => dhs);
	const [errors, setErrors] = useState({});
	const [state, setState] = useState(null);
	const [formFields, setFormFields] = useState(null);

	useEffect(() => {
		if (data) {
			setFormFields(editFormFields);
			createState(editFormFields);
		} else {
			setFormFields(createFormFields);
			createState(createFormFields);
		}
		// eslint-disable-next-line
	}, [open, data]);

	const createState = fields => {
		let form = {};
		fields?.forEach(item => {
			form[item.field] =
				item.type === "text" || item.type === "select"
					? data
						? data[item.field]
						: ""
					: item.type === "number"
					? data
						? data[item.field]
						: 0
					: false;
		});
		setState(form);
	};

	useEffect(() => {
		if (status) {
			closeDialog();
			dispatch(clearDhsStatus());
		}
		// eslint-disable-next-line
	}, [status]);

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(state).forEach(key => {
			errForm = {
				...errForm,
				[key]: requiredValidator(undefined, state[key]),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]: requiredValidator(undefined, value),
		});
	};

	const handleChange = ({ target: { name, value } }) => {
		handleInputValidation(name, value);
		setState({ ...state, [name]: value });
	};

	const handleUpdate = () => {
		if (!validateForm()) {
			return;
		}
		if (data) {
			let updateData = { ...state };
			dispatch(editDevice(updateData, params));
		} else {
			dispatch(createDevice(state, params));
		}
	};

	const closeDialog = () => {
		setState(createState());
		setErrors({});
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Grid container justifyContent='space-between'>
				<Typography
					variant={"h6"}
					color={"textPrimary"}
					style={{ marginBottom: 32 }}>
					{data ? `Edit device ${data?.deviceId}` : "Create device"}
				</Typography>
			</Grid>
			<Grid container spacing={3}>
				{state &&
					formFields &&
					formFields?.map(({ field, label, type }) => (
						<Grid item xs={12} md={formFields.length > 1 ? 6 : 12} key={field}>
							<Input
								label={label}
								variant={"outlined"}
								type={type}
								name={field}
								value={state[field] || ""}
								error={errors[field]?.invalid}
								helperText={errors[field]?.errorMessage}
								onChange={handleChange}
							/>
						</Grid>
					))}
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)" }}
							onClick={handleUpdate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default EditDeviceDialog;
