import React, { useEffect, useMemo, useState } from "react";
import { Button } from "../../theme/components/Button";
import { useDispatch, useSelector } from "react-redux";
import { setUserBlockers } from "../../app/actions/blockingActions";
import { addUserVipStatus, deleteUserVipStatus, rollbackUserBlockHimself } from "../../app/actions/usersActions";
import { Input } from "../../theme/components/Input";
import { Grid, MenuItem, makeStyles, Divider, FormControlLabel, Checkbox } from "@material-ui/core";
import { SelectInput } from "../../theme/components/Select";
import moment from "moment";
import PropTypes from "prop-types";
import { USER_BLOCKHIMSELF_STRING_PATTERN } from "../../utils/constants/user";

const status = {
	active: false,
	block: true,
};

const initialForm = {
	id: null,
	exchangeblk: false,
	accountblk: false,
	withdrawalblk: false,
	depositblk: false,
};

const UserProfile = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isChangeForm, setIsChangeForm] = useState(false);
	const [form, setForm] = useState(initialForm);
	const user = useSelector(({ users }) => users.profile);
	const isUserBlockedByHimself = useMemo(() => (
		user?.email?.includes(USER_BLOCKHIMSELF_STRING_PATTERN) &&
		user?.login?.includes(USER_BLOCKHIMSELF_STRING_PATTERN)
	), [user?.email, user?.login])

	useEffect(() => {
		setForm({
			id: user?.id,
			exchangeblk: user?.exchangeblk || false,
			accountblk: user?.accountblk || false,
			withdrawalblk: user?.withdrawalblk || false,
			depositblk: user?.depositblk || false,
		});
	}, [user]);

	if (!user) {
		return <div>USER NOT FOUND!!!!</div>;
	}

	const handleBlock = () => {
		dispatch(setUserBlockers(form));
		setIsChangeForm(false);
	};

	const handleChange = event => {
		event.preventDefault();
		const { name, value } = event.target;
		setIsChangeForm(true);
		setForm({ ...form, [name]: value });
	};

	const handleChangeVIPStatus = (e, v) => {
		if (!user?.id) {
			return;
		}
		if (!user?.vip) {
			dispatch(addUserVipStatus(user?.id))
		} else {
			dispatch(deleteUserVipStatus(user?.id))
		}
	}

	const handleUnblockUserBlockedByHimself = () => {
		dispatch(rollbackUserBlockHimself(user?.id))
	}

	return (
		<div style={{ width: "100%" }}>
			<Grid container spacing={3} style={{ paddingBottom: 36 }}>
				<Grid item xs={4}>
					<Input
						label='Id'
						variant={"outlined"}
						name={"id"}
						value={form?.id || ""}
						disabled
					/>
				</Grid>
				<Grid item xs={4} className={classes.formCroup}>
					<Input
						type='text'
						label='First name'
						variant={"outlined"}
						name={"firstName"}
						value={user?.firstName || ""}
						disabled
					/>
				</Grid>
				<Grid item xs={4} className={classes.formCroup}>
					<Input
						type='text'
						label='Last name'
						variant={"outlined"}
						name={"lastName"}
						value={user?.lastName || ""}
						disabled
					/>
				</Grid>
				<Grid item xs={4} className={classes.formCroup}>
					<Input
						type='text'
						label='Login'
						variant={"outlined"}
						name={"login"}
						value={user?.login || ""}
						disabled
					/>
				</Grid>
				<Grid item xs={4} className={classes.formCroup}>
					<Input
						label='Email'
						variant={"outlined"}
						type={"email"}
						name={"email"}
						value={user?.email || ""}
						disabled
					/>
				</Grid>
				<Grid item xs={4}>
					<Input
						label='Role'
						variant={"outlined"}
						name={"authority"}
						value={user?.authority || ""}
						disabled
					/>
				</Grid>
				<Grid item xs={4}>
					<Input
						label='Created date'
						variant={"outlined"}
						name={"createdDate"}
						value={
							user?.createdDate
								? moment(user?.createdDate).format("DD MMM YYYY HH:mm")
								: ""
						}
						disabled
					/>
				</Grid>
				<Grid item xs={4}>
					<FormControlLabel
						control={
							<Checkbox
								checked={Boolean(user?.vip)}
								color={"primary"}
								onChange={handleChangeVIPStatus}
							/>
						}
						label='VIP'
					/>
				</Grid>
				{
					isUserBlockedByHimself &&
					<Grid item xs={4}>
						<Button
							style={{
								alignSelf: 'center',
								color: "#E44A4A",
							}}
							onClick={handleUnblockUserBlockedByHimself}>
							Rollback the User Blocked by himself
						</Button>
					</Grid>
				}
			</Grid>
			<Divider className={classes.divider} />
			<div className={classes.divider}></div>
			<Grid container spacing={3} style={{ paddingTop: 36, paddingBottom: 36 }}>
				<Grid item xs={4}>
					<SelectInput
						label='Account activity'
						onChange={handleChange}
						value={form?.accountblk || false}
						name={"accountblk"}
						status={form?.accountblk}>
						<MenuItem value={status.block} className={classes.error}>
							Block
						</MenuItem>
						<MenuItem value={status.active} className={classes.success}>
							Unblock
						</MenuItem>
					</SelectInput>
				</Grid>
				<Grid item xs={4}>
					<SelectInput
						label='Exchange activity'
						onChange={handleChange}
						value={form?.exchangeblk || false}
						name={"exchangeblk"}
						status={form?.exchangeblk}>
						<MenuItem value={status.block} className={classes.error}>
							Block
						</MenuItem>
						<MenuItem value={status.active} className={classes.success}>
							Unblock
						</MenuItem>
					</SelectInput>
				</Grid>
				<Grid item xs={4}>
					<SelectInput
						label='Withdrawal activity'
						onChange={handleChange}
						value={form?.withdrawalblk || false}
						name={"withdrawalblk"}
						status={form?.withdrawalblk}>
						<MenuItem value={status.block} className={classes.error}>
							Block
						</MenuItem>
						<MenuItem value={status.active} className={classes.success}>
							Unblock
						</MenuItem>
					</SelectInput>
				</Grid>
			</Grid>
			<Grid container justifyContent='center'>
				{isChangeForm && (
					<Button
						color={"primary"}
						style={{ width: "max(33%, 50px)", marginTop: 12 }}
						onClick={handleBlock}>
						Save
					</Button>
				)}
			</Grid>
		</div>
	);
};

UserProfile.propTypes = {
	user: PropTypes.object,
};

export default UserProfile;

const useStyles = makeStyles(theme => ({
	formCroup: {
		display: "flex",
		flexDirection: "column",
	},
	errorField: {
		color: theme.palette.text.error,
		fontSize: 12,
		position: "absolute",
		marginTop: 56,
	},
	divider: {
		height: 3,
	},
	success: {
		color: "#00ff00",
	},
	error: {
		color: "#ff0000",
	},
}));
