import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import DefaultVipReferralsSettings from "./UserVipReferralsCategories/DefaultVipReferalSettings";
import { getSelectedVipUserReferralAllSettings } from "../../app/actions/referralsActions";
import ExchangeVipReferalSettings from "./UserVipReferralsCategories/ExchangeVipReferalSettings";
import FundsVipReferalSettings from "./UserVipReferralsCategories/FundsVipReferalSettings";

const UserVipReferrals = () => {
	const dispatch = useDispatch();
	const user = useSelector(({ users }) => users.profile);
	const userVipReferralsSettings = useSelector(
		({ referrals }) => referrals?.selectedVipUserReferralSettings
	);

	useEffect(() => {
		if (user?.id) {
			dispatch(getSelectedVipUserReferralAllSettings(user?.id));
		}
	}, [dispatch, user?.id]);

	if (!user) {
		return <div>USER NOT FOUND!!!!</div>;
	}

	return (
		<Grid container direction='column' style={{ paddingBottom: 36 }}>
			<DefaultVipReferralsSettings
				settings={userVipReferralsSettings?.commonVipSettings}
			/>
			<ExchangeVipReferalSettings
				settings={userVipReferralsSettings?.exchangeVipSettings}
			/>
			<FundsVipReferalSettings
				settings={userVipReferralsSettings?.fundVipSettings}
			/>
		</Grid>
	);
};

UserVipReferrals.propTypes = {
	user: PropTypes.object,
};

export default UserVipReferrals;
