import { hideLoader, showLoader } from "./actions/loadingActions";
import { enqueueSnackbar } from "./actions/notistackActions";

export default function loadingMiddleware({ dispatch, getState }) {
	return (next) => (action) => {
		if (action.type.endsWith("STARTED")) {
			dispatch(showLoader());
		}
		if (action.type.endsWith("SUCCESS") || action.type.endsWith("FAILURE")) {
			dispatch(hideLoader());
		}
		if (
			action.type.endsWith("FAILURE") &&
			!action.payload?.error?.response?.data?.error_description?.includes(
				"Invalid access token"
			)
		) {
			dispatch(
				enqueueSnackbar({
					message:
						typeof action.payload?.error?.response?.data === "string"
							? action.payload?.error?.response?.data
							: action.payload?.error?.response?.data.error === "invalid_token"
							? "Session timed out"
							: action.payload?.error?.response?.data?.title || "Some error",
					options: {
						key: new Date().getTime() + Math.random(),
						variant: "error",
						autoHideDuration: 3000,
					},
				})
			);
		}
		next(action);
	};
}
