import React, { useEffect, useState, useRef, useCallback } from "react";
import { Tabs, Tab, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import ApplicationsWithdrawalTable from "./components/ApplicationsWithdrawalTable.jsx";
import Monitoring from "./components/Monitoring/Monitoring.jsx";
import HistoryBanking from "./components/HistoryBanking.jsx";
import ProvidersList from "./components/Providers/ProvidersList";
import PageTitle from "../../theme/components/PageTitle";
import { getTransactions } from "../../app/actions/transactionsActions.js";
import { makeDecisionByPendingTransaction } from "../../app/actions/bankingActions.js";
import { BANKING_HISTORY_FILTER_FIELDS } from "../../utils/constants/banking";

const tabs = {
	currentWithdrawals: "Current withdrawals",
	monitoring: "monitoring",
	history: "history",
	providers: "providers",
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}>
			{value === index && <Box style={{ paddingTop: 24 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const Banking = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(0);
	const { data } = useSelector(({ transactions }) => transactions);
	const timeOut = useRef();
	const getBankingData = useCallback(() => {
		if (activeTab === 0) {
			dispatch(
				getTransactions({
					"transactionStatus.equals": "Waiting_for_manually_confirmation",
				})
			);
			timeOut.current = setTimeout(() => {
				getBankingData();
			}, 20000);
		} else {
			clearTimeout(timeOut.current);
		}
	}, [dispatch, activeTab]);

	useEffect(() => {
		getBankingData();
		return () => {
			clearTimeout(timeOut.current);
		};
	}, [getBankingData]);

	const handleChangeTabs = (_, newTab) => {
		setActiveTab(newTab);
	};

	const handleMakeDecision = (id, decision) => {
		dispatch(
			makeDecisionByPendingTransaction({
				transactionId: id,
				status: decision,
			})
		);
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<PageTitle
				title={"Banking"}
				style={{ height: 100 }}
				filterFields={activeTab === 2 ? BANKING_HISTORY_FILTER_FIELDS : null}
			/>
			<Tabs
				value={activeTab}
				onChange={handleChangeTabs}
				aria-label='simple tabs example'
				indicatorColor='primary'>
				<Tab label={tabs.currentWithdrawals} {...a11yProps(0)} />
				<Tab label={tabs.monitoring} {...a11yProps(1)} />
				<Tab label={tabs.history} {...a11yProps(2)} />
				<Tab label={tabs.providers} {...a11yProps(3)} />
			</Tabs>
			<TabPanel value={activeTab} index={0}>
				<ApplicationsWithdrawalTable
					data={data}
					onMakeDecision={handleMakeDecision}
				/>
			</TabPanel>
			<TabPanel value={activeTab} index={1}>
				<Monitoring />
			</TabPanel>
			<TabPanel value={activeTab} index={2}>
				<HistoryBanking />
			</TabPanel>
			<TabPanel value={activeTab} index={3}>
				<ProvidersList />
			</TabPanel>
		</div>
	);
};

export default Banking;
