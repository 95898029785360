import {
	CREATE_SETTING_EXCHANGE_FAILURE,
	CREATE_SETTING_EXCHANGE_SUCCESS,
	CREATE_SETTING_EXCHANGE_STARTED,
	CREATE_SETTING_WITHDRAW_FAILURE,
	CREATE_SETTING_WITHDRAW_SUCCESS,
	CREATE_SETTING_WITHDRAW_STARTED,
	DELETE_SETTING_EXCHANGE_FAILURE,
	DELETE_SETTING_EXCHANGE_STARTED,
	DELETE_SETTING_EXCHANGE_SUCCESS,
	DELETE_SETTING_WITHDRAW_FAILURE,
	DELETE_SETTING_WITHDRAW_STARTED,
	DELETE_SETTING_WITHDRAW_SUCCESS,
	GET_SETTINGS_EXCHANGES_FAILURE,
	GET_SETTINGS_EXCHANGES_STARTED,
	GET_SETTINGS_EXCHANGES_SUCCESS,
	GET_SETTINGS_WITHDRAWS_FAILURE,
	GET_SETTINGS_WITHDRAWS_STARTED,
	GET_SETTINGS_WITHDRAWS_SUCCESS,
	UPDATE_SETTING_EXCHANGE_FAILURE,
	UPDATE_SETTING_EXCHANGE_STARTED,
	UPDATE_SETTING_EXCHANGE_SUCCESS,
	UPDATE_SETTING_WITHDRAW_FAILURE,
	UPDATE_SETTING_WITHDRAW_STARTED,
	UPDATE_SETTING_WITHDRAW_SUCCESS,
	GET_SETTINGS_DEPOSITS_STARTED,
	GET_SETTINGS_DEPOSITS_SUCCESS,
	GET_SETTINGS_DEPOSITS_FAILURE,
	DELETE_SETTING_DEPOSIT_STARTED,
	DELETE_SETTING_DEPOSIT_SUCCESS,
	DELETE_SETTING_DEPOSIT_FAILURE,
	CREATE_SETTING_DEPOSIT_STARTED,
	CREATE_SETTING_DEPOSIT_SUCCESS,
	CREATE_SETTING_DEPOSIT_FAILURE,
	UPDATE_SETTING_DEPOSIT_STARTED,
	UPDATE_SETTING_DEPOSIT_SUCCESS,
	UPDATE_SETTING_DEPOSIT_FAILURE,
	CLEAR_TRANSACTIONS_SETTINGS,
	CLEAR_STATUS_SETTINGS,
	CLEAR_ERROR_SETTINGS,
	GET_MAX_PRECISION_STARTED,
	GET_MAX_PRECISION_SUCCESS,
	GET_MAX_PRECISION_FAILURE,
} from "../types";
import api from "../api";
import { enqueueSnackbar } from "./notistackActions";
import { Message } from "../../utils/enum";

// SETTINGS_EXCHANGE

export const createSettingExchangeStarted = () => ({
	type: CREATE_SETTING_EXCHANGE_STARTED,
});

export const createSettingExchangeSuccess = () => ({
	type: CREATE_SETTING_EXCHANGE_SUCCESS,
});

export const createSettingExchangeFailure = error => ({
	type: CREATE_SETTING_EXCHANGE_FAILURE,
	payload: {
		error,
	},
});

export const createSettingExchange = (data, params) => {
	return async dispatch => {
		dispatch(createSettingExchangeStarted());
		api.settings.exchange
			.create(data)
			.then(() => {
				dispatch(createSettingExchangeSuccess);
				dispatch(getSettingsExchange(params));
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(createSettingExchangeFailure(error)));
	};
};

export const getSettingsExchangeStarted = () => ({
	type: GET_SETTINGS_EXCHANGES_STARTED,
});

export const getSettingsExchangeSuccess = data => ({
	type: GET_SETTINGS_EXCHANGES_SUCCESS,
	payload: data,
});

export const getSettingsExchangeFailure = error => ({
	type: GET_SETTINGS_EXCHANGES_FAILURE,
	payload: {
		error,
	},
});

export const getSettingsExchange = params => {
	return async dispatch => {
		dispatch(getSettingsExchangeStarted());
		api.settings.exchange
			.getList(params)
			.then(res => dispatch(getSettingsExchangeSuccess(res.data)))
			.catch(error => dispatch(getSettingsExchangeFailure(error)));
	};
};

export const getMaxPrecisionExchangeStarted = () => ({
	type: GET_MAX_PRECISION_STARTED,
});

export const getMaxPrecisionExchangeSuccess = data => ({
	type: GET_MAX_PRECISION_SUCCESS,
	payload: data,
});

export const getMaxPrecisionExchangeFailure = error => ({
	type: GET_MAX_PRECISION_FAILURE,
	payload: {
		error,
	},
});

export const getMaxPrecisionExchange = pair => {
	return async dispatch => {
		dispatch(getMaxPrecisionExchangeStarted());
		api.settings.exchange
			.getMaxPrecision(pair)
			.then(res => dispatch(getMaxPrecisionExchangeSuccess(res.data)))
			.catch(error => dispatch(getMaxPrecisionExchangeFailure(error)));
	};
};

export const deleteSettingExchangeStarted = () => ({
	type: DELETE_SETTING_EXCHANGE_STARTED,
});

export const deleteSettingExchangeSuccess = payload => ({
	type: DELETE_SETTING_EXCHANGE_SUCCESS,
	payload,
});

export const deleteSettingExchangeFailure = error => ({
	type: DELETE_SETTING_EXCHANGE_FAILURE,
	payload: {
		error,
	},
});

export const deleteSettingExchange = (id, params) => {
	return async dispatch => {
		dispatch(deleteSettingExchangeStarted());
		api.settings.exchange
			.delete(id)
			.then(() => {
				dispatch(deleteSettingExchangeSuccess(id));
				getSettingsExchange(params);
				dispatch(
					enqueueSnackbar({
						message: Message.DataDeleted,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(deleteSettingExchangeFailure(error)));
	};
};

export const updateSettingExchangeStarted = () => ({
	type: UPDATE_SETTING_EXCHANGE_STARTED,
});

export const updateSettingExchangeSuccess = payload => ({
	type: UPDATE_SETTING_EXCHANGE_SUCCESS,
	payload,
});

export const updateSettingExchangeFailure = error => ({
	type: UPDATE_SETTING_EXCHANGE_FAILURE,
	payload: {
		error,
	},
});

export const updateSettingExchange = (data, params) => {
	return async dispatch => {
		dispatch(updateSettingExchangeStarted());
		api.settings.exchange
			.edit(data)
			.then(res => {
				dispatch(updateSettingExchangeSuccess(res.data));
				dispatch(getSettingsExchange(params));
				dispatch(
					enqueueSnackbar({
						message: Message.DataChanged,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(updateSettingExchangeFailure(error)));
	};
};

// SETTINGS_WITHDRAW

export const getSettingsWithdrawStarted = () => ({
	type: GET_SETTINGS_WITHDRAWS_STARTED,
});

export const getSettingsWithdrawSuccess = data => ({
	type: GET_SETTINGS_WITHDRAWS_SUCCESS,
	payload: data,
});

export const getSettingsWithdrawFailure = error => ({
	type: GET_SETTINGS_WITHDRAWS_FAILURE,
	payload: {
		error,
	},
});

export const getSettingsWithdraw = params => {
	return async dispatch => {
		dispatch(getSettingsWithdrawStarted());
		api.settings.withdraw
			.getList(params)
			.then(res => dispatch(getSettingsWithdrawSuccess(res.data)))
			.catch(error => dispatch(getSettingsWithdrawFailure(error)));
	};
};

export const deleteSettingWithdrawStarted = () => ({
	type: DELETE_SETTING_WITHDRAW_STARTED,
});

export const deleteSettingWithdrawSuccess = payload => ({
	type: DELETE_SETTING_WITHDRAW_SUCCESS,
	payload,
});

export const deleteSettingWithdrawFailure = error => ({
	type: DELETE_SETTING_WITHDRAW_FAILURE,
	payload: {
		error,
	},
});

export const deleteSettingWithdraw = (id, params) => {
	return async dispatch => {
		dispatch(deleteSettingWithdrawStarted());
		api.settings.withdraw
			.delete(id)
			.then(res => {
				dispatch(deleteSettingWithdrawSuccess(res.data));
				dispatch(getSettingsWithdraw(params));
				dispatch(
					enqueueSnackbar({
						message: Message.DataDeleted,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(deleteSettingWithdrawFailure(error)));
	};
};

export const updateSettingWithdrawStarted = () => ({
	type: UPDATE_SETTING_WITHDRAW_STARTED,
});

export const updateSettingWithdrawSuccess = payload => ({
	type: UPDATE_SETTING_WITHDRAW_SUCCESS,
	payload,
});

export const updateSettingWithdrawFailure = error => ({
	type: UPDATE_SETTING_WITHDRAW_FAILURE,
	payload: {
		error,
	},
});

export const updateSettingWithdraw = (data, params) => {
	return async dispatch => {
		dispatch(updateSettingWithdrawStarted());
		api.settings.withdraw
			.edit(data)
			.then(res => {
				dispatch(updateSettingWithdrawSuccess(res.data));
				dispatch(getSettingsWithdraw(params));
				dispatch(
					enqueueSnackbar({
						message: Message.DataChanged,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(updateSettingWithdrawFailure(error)));
	};
};

export const createSettingWithdrawStarted = () => ({
	type: CREATE_SETTING_WITHDRAW_STARTED,
});

export const createSettingsWithdrawSuccess = () => ({
	type: CREATE_SETTING_WITHDRAW_SUCCESS,
});

export const createSettingWithdrawFailure = error => ({
	type: CREATE_SETTING_WITHDRAW_FAILURE,
	payload: {
		error,
	},
});

export const createSettingWithdraw = (data, params) => {
	return async dispatch => {
		dispatch(createSettingWithdrawStarted());
		api.settings.withdraw
			.create(data)
			.then(() => {
				dispatch(createSettingsWithdrawSuccess());
				dispatch(getSettingsWithdraw(params));
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(createSettingWithdrawFailure(error)));
	};
};

// SETTINGS_DEPOSIT

export const getSettingsDepositsStarted = () => ({
	type: GET_SETTINGS_DEPOSITS_STARTED,
});

export const getSettingsDepositsSuccess = data => ({
	type: GET_SETTINGS_DEPOSITS_SUCCESS,
	payload: data,
});

export const getSettingsDepositsFailure = error => ({
	type: GET_SETTINGS_DEPOSITS_FAILURE,
	payload: {
		error,
	},
});

export const getSettingsDeposits = params => {
	return async dispatch => {
		dispatch(getSettingsDepositsStarted());
		api.settings.deposit
			.getList(params)
			.then(res => dispatch(getSettingsDepositsSuccess(res.data)))
			.catch(error => dispatch(getSettingsDepositsFailure(error)));
	};
};

export const deleteSettingDepositStarted = () => ({
	type: DELETE_SETTING_DEPOSIT_STARTED,
});

export const deleteSettingDepositSuccess = payload => ({
	type: DELETE_SETTING_DEPOSIT_SUCCESS,
	payload,
});

export const deleteSettingDepositFailure = error => ({
	type: DELETE_SETTING_DEPOSIT_FAILURE,
	payload: {
		error,
	},
});

export const deleteSettingDeposit = (id, params) => {
	return async dispatch => {
		dispatch(deleteSettingDepositStarted());
		api.settings.deposit
			.delete(id)
			.then(res => {
				dispatch(deleteSettingDepositSuccess(res.data));
				dispatch(getSettingsDeposits(params));
				dispatch(
					enqueueSnackbar({
						message: Message.DataDeleted,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(deleteSettingDepositFailure(error)));
	};
};

export const updateSettingDepositStarted = () => ({
	type: UPDATE_SETTING_DEPOSIT_STARTED,
});

export const updateSettingDepositSuccess = payload => ({
	type: UPDATE_SETTING_DEPOSIT_SUCCESS,
	payload,
});

export const updateSettingDepositFailure = error => {
	return {
		type: UPDATE_SETTING_DEPOSIT_FAILURE,
		payload: {
			error,
		},
	};
};

export const updateSettingDeposit = (data, params) => {
	return async dispatch => {
		dispatch(updateSettingDepositStarted());
		api.settings.deposit
			.edit(data)
			.then(res => {
				dispatch(updateSettingDepositSuccess(res.data));
				dispatch(getSettingsDeposits(params));
				dispatch(
					enqueueSnackbar({
						message: Message.DataChanged,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(updateSettingDepositFailure(error)));
	};
};

export const createSettingDepositStarted = () => ({
	type: CREATE_SETTING_DEPOSIT_STARTED,
});

export const createSettingDepositSuccess = () => ({
	type: CREATE_SETTING_DEPOSIT_SUCCESS,
});

export const createSettingDepositFailure = error => ({
	type: CREATE_SETTING_DEPOSIT_FAILURE,
	payload: {
		error,
	},
});

export const createSettingDeposit = (data, params) => {
	return async dispatch => {
		dispatch(createSettingDepositStarted());
		api.settings.deposit
			.create(data)
			.then(() => {
				dispatch(createSettingDepositSuccess());
				dispatch(getSettingsDeposits(params));
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(createSettingDepositFailure(error)));
	};
};

export const clearTransactionsSettings = () => ({
	type: CLEAR_TRANSACTIONS_SETTINGS,
});

export const clearStatusSettings = () => ({
	type: CLEAR_STATUS_SETTINGS,
});

export const clearErrorSettings = () => ({
	type: CLEAR_ERROR_SETTINGS,
});
