import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../../../theme/components/Table/Table";
import CopyWithAlert from "../../../../utils/CopyWithAlert";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";
import { searchReferralReward } from "../../../../app/actions/referralsActions";
import { REFERRAL_REWARDS_COLUMNS } from "../../../../utils/constants/referrals";
import getStatusColor from "../../../../utils/functions/getStatusColor";

const Rewards = () => {
	const dispatch = useDispatch();
	const { referralRewards } = useSelector(({ referrals }) => referrals);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(searchReferralReward(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{REFERRAL_REWARDS_COLUMNS?.map(
							({ key, headerName, colSpan, align }) => (
								<th key={key} colSpan={colSpan} align={align || "left"}>
									{headerName}
								</th>
							)
						)}
					</tr>
				</thead>
				<tbody>
					{referralRewards?.content?.length > 0 ? (
						referralRewards?.content?.map((rowData, index) => (
							<TableRow key={index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={referralRewards?.totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default Rewards;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.referralId + "" || ""}
					label={"Referral ID"}>
					<td data='hover'>{rowData?.referralId}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.referralOwnerId + "" || ""}
					label={"Referral Owner ID"}>
					<td data='hover'>{rowData?.referralOwnerId}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.asset + "" || ""} label={"Asset"}>
					<td data='hover'>{rowData?.asset}</td>
				</CopyWithAlert>
				<td>{+rowData?.reward}</td>
				<CopyWithAlert text={rowData?.source + "" || ""} label={"Source"}>
					<td data='hover'>{rowData?.source}</td>
				</CopyWithAlert>
				<td>
					{rowData?.time && moment(rowData?.time).format("DD MMM YYYY HH:mm")}
				</td>
				<CopyWithAlert text={rowData?.status + "" || ""} label={"Status"}>
					<td
						data='hover'
						style={{
							color: rowData?.status && getStatusColor(rowData?.status),
						}}>
						{rowData?.status || "-"}
					</td>
				</CopyWithAlert>
			</tr>
		</>
	);
}
