import React, { useState } from "react";
import {
	Tabs,
	Tab,
	Box,
	Paper,
	IconButton,
	Typography,
	makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useHistory, useParams } from "react-router-dom";
import BackIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import PropTypes from "prop-types";
import CompanyBalances from "./CompanyBalances";
import { Button } from "../../theme/components/Button";
import CreateCompanyBalanceDialog from "./CreateBalanceDialog";

const tabs = {
	balances: {
		label: "Balances",
		index: 0,
	},
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}>
			{value === index && <Box style={{ paddingTop: 24 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const CompanyDetails = () => {
	const classes = useStyles();
	const history = useHistory();
	const routeParams = useParams();
	const [activeTab, setActiveTab] = useState(tabs.balances.index);
	const [showCreateBalanceModal, setShowCreateBalanceModal] = useState(false);
	const companyId = routeParams?.companyId;

	const handleChangeTabs = (_, newTab) => {
		setActiveTab(newTab);
	};

	const handleCreate = () => {
		setShowCreateBalanceModal(true);
	};

	const goBack = () => {
		history.push(history?.location?.state?.from || `/companies`, {
			from: "companyDetails",
			params: history?.location?.state?.params,
		});
	};

	const handleCloseModal = () => {
		setShowCreateBalanceModal(false)
	}

	return (
		<div className={classes.root} style={{ maxWidth: "100%" }}>
			<CreateCompanyBalanceDialog
				open={showCreateBalanceModal}
				handleClose={handleCloseModal}
				companyId={companyId}
			/>
			<div className={classes.btnWrap}>
				<Paper elevation={0} className={classes.paper} onClick={goBack}>
					<IconButton className={classes.icon} size={"small"}>
						<BackIcon />
					</IconButton>
					<Typography variant={"subtitle2"} color={"textSecondary"}>
						Back to{" "}
						<span className={classes.link}>
							{history?.location?.state?.title || "Companies"}
						</span>
					</Typography>
				</Paper>
				{activeTab === 0 && (
					<Button variant='outlined' color='primary' onClick={handleCreate}>
						<AddIcon />
						Add Balance
					</Button>
				)}
			</div>
			<Tabs
				value={activeTab}
				onChange={handleChangeTabs}
				aria-label='simple tabs example'
				indicatorColor='primary'>
				<Tab label={tabs.balances.label} {...a11yProps(0)} />
			</Tabs>
			<TabPanel value={activeTab} index={0}>
				<CompanyBalances companyId={companyId} />
			</TabPanel>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		background: theme.palette.background.default,
		boxShadow: "none",
	},
	btnWrap: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	paper: {
		display: "flex",
		alignItems: "center",
		padding: 8,
		paddingRight: 12,
		borderRadius: 8,
		width: "max-content",
		marginBottom: 16,
		"&:hover": {
			cursor: "pointer",
		},
	},
	icon: {
		color: theme.palette.primary.light,
		padding: 0,
		marginRight: 4,
		"& svg": {
			fontSize: 19,
		},
	},
	link: {
		color: theme.palette.primary.light,
		fontWeight: 700,
		textDecoration: "none",
	},
}));

export default CompanyDetails;
