import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogWrapper from "../../../theme/components/dialogs/DialogWrapper";
import { deleteDevice, clearDhsStatus } from "../../../app/actions/dhsActions";

const DeleteDeviceDialog = ({ open, handleClose, deviceId, params }) => {
	const dispatch = useDispatch();
	const { status } = useSelector(({ dhs }) => dhs);

	useEffect(() => {
		if (status) {
			handleClose();
			dispatch(clearDhsStatus());
		}
	}, [dispatch, status, handleClose]);

	const handleDelete = () => {
		dispatch(deleteDevice(deviceId, params));
	};

	return (
		<DialogWrapper open={open} handleClose={handleClose}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				{`Delete device ${deviceId?.toUpperCase()}?`}
			</Typography>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<IconButton onClick={handleDelete}>
					<DeleteIcon style={{ color: "red" }} />
				</IconButton>
			</div>
		</DialogWrapper>
	);
};

export default DeleteDeviceDialog;
