import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../../../theme/components/Table/Table";
import { searchDividendsDistributionPrograms } from "../../../../app/actions/fundsCapitalActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";

const columns = [
	{ key: "id", label: "ID" },
	{ key: "token", label: "Token" },
	{ key: "asset", label: "Asset" },
	{ key: "totalUserTokens", label: "Total User Tokens" },
	{ key: "amountToDistribute", label: "Amount To Distribute" },
	{ key: "amountToSave", label: "Amount To Save" },
	{ key: "rate", label: "Rate" },
	{ key: "rewardPercent", label: "Reward Percent" },
	{ key: "timeWhenProgramWasCompleted", label: "Completed" },
];

const DistributionHistory = () => {
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(
		({ funds }) => funds?.dividendsDistributionPrograms
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(searchDividendsDistributionPrograms(filteringParams));
	}, [dispatch, activeFilters]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find(x => x.field === "size").value}
				page={activeFilters?.find(x => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default DistributionHistory;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<td>{rowData?.id}</td>
				<td>{rowData?.token}</td>
				<td>{rowData?.asset}</td>
				<td>{+rowData?.totalUserTokens}</td>
				<td>{+rowData?.amountToDistribute}</td>
				<td>{+rowData?.amountToSave}</td>
				<td>{+rowData?.rate}</td>
				<td>{+rowData?.rewardPercent}</td>
				<td>
					{rowData?.timeWhenProgramWasCompleted &&
						moment(rowData?.timeWhenProgramWasCompleted).format(
							"DD MMM YYYY HH:mm"
						)}
				</td>
			</tr>
		</>
	);
}
