import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import twoFa from "../../assets/2fa.svg";
import { Button } from "../../theme/components/Button";
import {
	getAccountData,
	disableTwoFA,
	enableTwoFA,
	clearTwoFaQrCode,
} from "../../app/actions/accountActions";
import TwoFaQrCodeDialog from "../Login/TwoFaQrCodeDialog";
import ChangePassword from "./ChangePassword";
import DeleteConfirmDialog from "../../theme/components/dialogs/DeleteConfirmDialog";

const profileFields = [
	{ label: "ID", field: "id" },
	{ label: " First name", field: "firstName" },
	{ label: "Last name", field: "lastName" },
	{ label: "E-mail", field: "email" },
	{ label: "Role", field: "authorities" },
];

const Profile = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const { data, qrCode, isTwoFA, passError } = useSelector(
		state => state.account
	);

	useEffect(() => {
		dispatch(getAccountData());
	}, [dispatch]);

	useEffect(() => {
		if (isTwoFA) {
			dispatch(clearTwoFaQrCode());
		}
	}, [dispatch, isTwoFA]);

	const handleTwoFA = () => {
		data?.twofa ? setShowDeleteDialog(true) : dispatch(enableTwoFA());
	};

	const handleCloseQrCodeDialog = () => {
		dispatch(clearTwoFaQrCode());
	};

	const handleConfirmDisableTwoFA = ({ accept }) => {
		if (accept) {
			dispatch(disableTwoFA());
		}
		setShowDeleteDialog(false);
	};

	return (
		<>
			<TwoFaQrCodeDialog
				open={Boolean(qrCode)}
				handleClose={handleCloseQrCodeDialog}
				data={qrCode}
			/>
			<DeleteConfirmDialog
				open={showDeleteDialog}
				title='Turn off 2FA Authentication?'
				message='Are you sure that you to turn off 2FA Authentication?'
				handleClose={handleConfirmDisableTwoFA}
				btnOkText='Disable'
			/>
			<Grid container spacing={4} className={classes.root}>
				<Grid item md={4} xs={12} container>
					<Paper className={classes.paper} elevation={4}>
						<Typography variant='h4'>My Profile Page</Typography>
						{!!data &&
							profileFields.map(({ field, label }) => (
								<Typography
									key={field}
									color='textSecondary'
									className={classes.data}>
									{label}:<span>{data[field]}</span>
								</Typography>
							))}
					</Paper>
				</Grid>
				<Grid item container md={4} xs={12}>
					<Paper className={classes.paper} elevation={4}>
						<Grid container spacing={2}>
							<Grid item container xs={12}>
								<Grid xs={2} item className={classes.rowItem}>
									<img src={twoFa} alt='twoFa' />
								</Grid>
								<Grid xs={10} item className={classes.rowItem}>
									<Typography variant='h4'>
										Google Authenticator (2FA)
									</Typography>
								</Grid>
							</Grid>
							<Grid item container xs={12}>
								<Grid xs={6} md={6} item className={classes.rowItem}>
									<Typography
										className={
											data?.twofa ? classes.activated : classes.deactivated
										}>
										{data?.twofa ? "Active" : "Deactivated"}
									</Typography>
								</Grid>
								<Grid xs={6} md={6} item className={classes.rowItem}>
									<Button
										className={classes.enableBtn}
										variant='contained'
										color='primary'
										onClick={handleTwoFA}>
										{!data?.twofa ? "Enable" : "Disable"}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item md={4} xs={12}>
					<ChangePassword passError={passError} />
				</Grid>
			</Grid>
		</>
	);
};

export default Profile;

const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: "1280px",
		margin: "0 auto",
	},
	paper: {
		width: "100%",
		height: "100%",
		padding: "20px",
	},
	activated: {
		color: "#00ff00",
	},
	deactivated: {
		color: "#ff0000",
	},
	data: {
		display: "flex",
		justifyContent: "space-between",
		marginTop: "10px",
		"& span": {
			display: "block",
			textAlign: "right",
		},
	},
	rowItem: {
		margin: "auto 0",
		width: "100%",
		textAlign: "center",
	},
	enableBtn: {
		width: "100%",
	},
}));
