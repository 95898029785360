import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Table from "../../../theme/components/Table/Table";
import moment from "moment";
import { IconButton, TablePagination } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import { clearActiveFilters } from "../../../app/actions/filterActions";
import { clearTransactions } from "../../../app/actions/transactionsActions";
import getStatusColor from "../../../utils/functions/getStatusColor";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "create", label: "Date & time" },
	{ key: "ticker", label: "Currency" },
	{ key: "amount", label: "Amount" },
	{ key: "address", label: "Address" },
	{ key: "blockhash", label: "Blockhash" },
	{ key: "source", label: "Source" },
	{ key: "txid", label: "Txid" },
	{ key: "transactionStatus", label: "Status" },
	{ key: "manage", label: "Info" },
];

const CryptoOperationsTable = ({
	data,
	params,
	total,
	onPageChange,
	onRowsPerPageChange,
	onManage,
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearTransactions());
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody style={{ position: "relative" }}>
					{data?.length > 0 ? (
						data.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
								onStatusColor={getStatusColor}
								onManage={onManage}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 30, 50]}
				component='div'
				count={total}
				rowsPerPage={params.find(x => x.field === "size").value}
				page={params.find(x => x.field === "page").value}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
			/>
		</>
	);
};

export default CryptoOperationsTable;

function TableRow({ rowData, onStatusColor, onManage }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<td>
					{rowData?.create &&
						moment(rowData?.create).format("DD MMM YYYY HH:mm")}
				</td>
				<td>{rowData?.ticker}</td>
				<td>{rowData?.amount}</td>
				<CopyWithAlert text={rowData?.address + "" || ""} label={"Address"}>
					<td data='hover'>{rowData?.address}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.blockhash + "" || ""} label={"blockhash"}>
					<td data='hover'>{rowData?.blockhash}</td>
				</CopyWithAlert>
				<td>{rowData?.source}</td>
				<CopyWithAlert text={rowData?.txid + "" || ""} label={"txid"}>
					<td data='hover'>{rowData?.txid}</td>
				</CopyWithAlert>
				<td
					style={{
						color: onStatusColor(rowData?.transactionStatus),
					}}>
					{rowData?.transactionStatus || "-"}
				</td>
				<td>
					<IconButton onClick={event => onManage(rowData)}>
						<Visibility />
					</IconButton>
				</td>
			</tr>
		</>
	);
}
