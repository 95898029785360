import DialogWrapper from "../../theme/components/dialogs/DialogWrapper";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import QRCode from "react-qr-code";
import Address from "../../theme/components/Address";

const ReceiveDialog = ({ handleClose, data }) => {
	const classes = useStyles();

	return (
		<DialogWrapper
			open={Boolean(data)}
			handleClose={handleClose}
			maxWidth={520}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				Receive {data?.ticker.toLowerCase()} ({data?.ticker?.toUpperCase()})
				wallet
			</Typography>
			<Grid container justifyContent={"center"}>
				<Grid item>
					<div className={classes.qr}>
						{Boolean(data?.masterWalletAddress) && (
							<QRCode
								value={data?.masterWalletAddress}
								size={140}
								fgColor={"#000"}
								bgColor={"#fff"}
							/>
						)}
					</div>
				</Grid>
				<Grid item xs={12}>
					<Address
						variant={"outlined"}
						address={data?.masterWalletAddress}
						short
					/>
				</Grid>
				{/* <Grid item xs={12} style={{ marginTop: 32 }}>
					<Button
						variant={"contained"}
						color='primary'
						style={{ width: "100%" }}>
						Share QR code
					</Button>
				</Grid>*/}
			</Grid>
		</DialogWrapper>
	);
};

export default ReceiveDialog;

const useStyles = makeStyles(theme => ({
	qr: {
		marginTop: 32,
		marginBottom: 32,
		width: 160,
		height: 160,
		padding: 10,
		backgroundColor: "#fff",
	},
	input: {
		width: "100%",
		minWidth: 200,
		marginTop: 32,
	},
}));
