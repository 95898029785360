import Logo from "../../assets/logo.webp";
import {Container, makeStyles, Paper, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";

const AuthWrapper = ({children, isLogin}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Container maxWidth={'sm'}>
				<Paper className={classes.paper}>
					<div className={classes.logo}>
						<img src={Logo} alt="Cryptoneed"/>
					</div>
					{children}
				</Paper>
				{
					isLogin &&
					<Typography variant={'body1'} color={'textSecondary'} className={classes.forgot}>
						Forgot your password? <Link to="/reset-password">Reset password</Link>
					</Typography>
				}
			</Container>
		</div>
	);
};

export default AuthWrapper;

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '100vh',
		display: "flex",
		alignItems: 'center',
		paddingTop: 32,
		paddingBottom: 32,
		[theme.breakpoints.down('xs')]: {
			paddingTop: 12,
			paddingBottom: 12,
		},
		'& h2': {
			marginBottom: 24
		},
		'& p': {
			color: '#8E8F92',
			marginBottom: 18,
			[theme.breakpoints.down('xs')]: {
				marginBottom: 12,
			},
			'&>a': {
				color: theme.palette.primary.light,
				fontWeight: 500,
				textDecoration: 'none'
			}
		},
		'& form>*': {
			marginTop: 24,
			[theme.breakpoints.down('xs')]: {
				marginTop: 12,
			},
		},
		'& button': {
			width: '100%',
			marginTop: 32,
			[theme.breakpoints.down('xs')]: {
				marginTop: 12
			}
		}
	},
	paper: {
		padding: 48,
		borderRadius: 8,
		[theme.breakpoints.down('xs')]: {
			padding: 18,
		}
	},
	logo: {
		textAlign: 'center',
		marginBottom: 32,
		marginLeft: -18,
		[theme.breakpoints.down('sm')]: {
			marginBottom: 24,
			marginLeft: -15,
		},
		'&>img': {
			maxWidth: 256,
			[theme.breakpoints.down('sm')]: {
				maxWidth: 160,
			},
		}
	},
	forgot: {
		textAlign: 'center',
		marginTop: 32,
		[theme.breakpoints.down('xs')]: {
			marginTop: 12
		}
	}
}));
