import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../../../theme/components/Table/Table";
import CopyWithAlert from "../../../../utils/CopyWithAlert";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";
import { searchClaimHistory } from "../../../../app/actions/referralsActions";
import { CLAIM_HISTORY_COLUMNS } from "../../../../utils/constants/referrals";

const ClaimHistory = () => {
	const dispatch = useDispatch();
	const { claimHistory } = useSelector(({ referrals }) => referrals);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(searchClaimHistory(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{CLAIM_HISTORY_COLUMNS?.map(
							({ key, headerName, colSpan, align }) => (
								<th key={key} colSpan={colSpan} align={align || "left"}>
									{headerName}
								</th>
							)
						)}
					</tr>
				</thead>
				<tbody>
					{claimHistory?.content?.length > 0 ? (
						claimHistory?.content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={claimHistory?.totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default ClaimHistory;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.txId + "" || ""} label={"Transaction ID"}>
					<td data='hover'>{rowData?.txId}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.userId + "" || ""} label={"User Id"}>
					<td data='hover'>{rowData?.userId}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.asset + "" || ""} label={"Asset"}>
					<td data='hover'>{rowData?.asset}</td>
				</CopyWithAlert>
				<td>{+rowData?.amount}</td>

				<td>
					{rowData?.time &&
						moment(rowData?.creationTime).format("DD MMM YYYY HH:mm")}
				</td>
			</tr>
		</>
	);
}
