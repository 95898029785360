import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination, IconButton } from "@material-ui/core";
import { useHistory } from 'react-router-dom'
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIndexDialog from "./components/EditIndexDialog";
import Table from "../../../../theme/components/Table/Table";
import DeleteConfirmDialog from "../../../../theme/components/dialogs/DeleteConfirmDialog";
import CopyWithAlert from "../../../../utils/CopyWithAlert";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";
import getStatusColor from "../../../../utils/functions/getStatusColor";
import {
	searchInvestmentIndexes,
	deleteInvestmentIndex,
} from "../../../../app/actions/indexesCapitalActions";
import { Button } from "../../../../theme/components/Button";
import { useStyles } from "../Assets";

const columns = [
	{ key: "name", label: "Index" },
	{ key: "token", label: "Token" },
	{ key: "riskLevel", label: "Risk Level" },
	{ key: "availableTokens", label: "Available tokens" },
	{ key: "reservedBySystem", label: "Reserved by system" },
	{ key: "totalTokens", label: " Total tokens" },
	{ key: "maxBuyAmount", label: "Max buy" },
	{ key: "minBuyAmount", label: "Min buy" },
	{ key: "maxSellAmount", label: "Max sell" },
	{ key: "minSellAmount", label: "Min sell" },
	{ key: "buyCommissionPercent", label: "Buy commission percent" },
	{ key: "sellCommissionPercent", label: "Sell commission percent" },
	{ key: "price", label: "Price" },
	{ key: "binanceApiKey", label: "Binance api key" },
	{ key: "binanceApiSecret", label: "Binance api secret" },
	{ key: "edit", label: "Manage", type: "manage", align: "center" },
];

const IndexesSettings = ({ createIndex, onCloseCreateIndex }) => {
	const dispatch = useDispatch();
	const [deleteIndex, setDeleteIndex] = useState(null);
	const [editIndex, setEditIndex] = useState(null);
	const { content, totalElements } = useSelector(
		({ indexes }) => indexes?.indexes
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(searchInvestmentIndexes(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	const handleDelete = ({ accept }) => {
		if (accept) {
			dispatch(deleteInvestmentIndex(deleteIndex?.id));
		}
		setDeleteIndex(null);
	};

	return (
		<>
			<DeleteConfirmDialog
				open={Boolean(deleteIndex)}
				title='Delete Index'
				message={`Are you sure you want to delete the index - token ${deleteIndex?.token}?`}
				handleClose={handleDelete}
				btnOkText='Delete'
			/>
			<EditIndexDialog
				open={Boolean(editIndex) || createIndex}
				handleClose={() => {
					setEditIndex(null);
					onCloseCreateIndex();
				}}
				data={editIndex}
				params={activeFilters}
			/>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label, align }) => (
							<th key={key} style={{ textAlign: align || "left" }}>
								{label}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
								handleDelete={() => setDeleteIndex(rowData)}
								handleEdit={() => setEditIndex(rowData)}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default IndexesSettings;

function TableRow({ rowData, handleEdit, handleDelete }) {
	const classes = useStyles()
	const history = useHistory();
	const handleManageAssets = useCallback(() => {
		if (rowData.id) {
			history.push(`indexes/${rowData.id}/assets`)
		}
	}, [history, rowData.id])
	return (
		<tr>
			<td>{rowData?.name}</td>
			<CopyWithAlert text={rowData?.token + "" || ""} label={"Token"}>
				<td data='hover'>{rowData?.token}</td>
			</CopyWithAlert>
			<td
				style={{
					color: rowData?.riskLevel && getStatusColor(rowData?.riskLevel),
				}}>
				{rowData?.riskLevel || "-"}
			</td>
			<td>{+rowData?.availableTokens}</td>
			<td>{+rowData?.reservedBySystem || "-"}</td>
			<td>{+rowData?.reservedBySystem + rowData?.totalByUsers}</td>

			<td>{+rowData?.maxBuyAmount}</td>
			<td>{+rowData?.minBuyAmount}</td>
			<td>{+rowData?.maxSellAmount}</td>
			<td>{+rowData?.minSellAmount}</td>
			<td>{+rowData?.buyCommissionPercent}</td>
			<td>{+rowData?.sellCommissionPercent}</td>
			<td>{+rowData?.realPrice}</td>
			<CopyWithAlert
				text={rowData?.binanceApiKey + "" || ""}
				label={"Binance api key"}>
				<td data='hover' style={{ maxWidth: 50 }}>
					{rowData?.binanceApiKey}
				</td>
			</CopyWithAlert>
			<CopyWithAlert
				text={rowData?.binanceApiSecret + "" || ""}
				label={"Binance api secret"}>
				<td data='hover' style={{ maxWidth: 50 }}>
					{rowData?.binanceApiSecret}
				</td>
			</CopyWithAlert>
			<td>
				<Button
					variant='outlined'
					color='primary'
					className={classes.btn}
					onClick={handleManageAssets}>
					Manage
				</Button>
				<IconButton onClick={handleEdit}>
					<EditIcon />
				</IconButton>
				<IconButton onClick={handleDelete}>
					<DeleteIcon style={{ color: "#ff0000" }} />
				</IconButton>
			</td>
		</tr>
	);
}
