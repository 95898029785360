import {
	GET_USERS_BLOCKERS_STARTED,
	GET_USERS_BLOCKERS_SUCCESS,
	GET_USERS_BLOCKERS_FAILURE,
	GET_BLOCKERS_BY_ID_STARTED,
	GET_BLOCKERS_BY_ID_SUCCESS,
	GET_BLOCKERS_BY_ID_FAILURE,
} from '../types';

export default function blocking(
	state = {
		data: [],
		error: null,
		userBlockers: null,
	},
	action
) {
	switch (action.type) {
		case GET_USERS_BLOCKERS_STARTED:
			return { ...state, error: null };
		case GET_USERS_BLOCKERS_SUCCESS:
			return { ...state, ...action.payload };
		case GET_USERS_BLOCKERS_FAILURE:
			return { ...state, error: action.payload.error };
		case GET_BLOCKERS_BY_ID_STARTED:
			return { ...state, error: null };
		case GET_BLOCKERS_BY_ID_SUCCESS:
			return { ...state, userBlockers: action.payload.data };
		case GET_BLOCKERS_BY_ID_FAILURE:
			return { ...state, error: action.payload.error };
		default:
			return state;
	}
}
