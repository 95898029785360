import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import PageTitle from "../../theme/components/PageTitle";
import Table from "../../theme/components/Table/Table";
import CopyWithAlert from "../../utils/CopyWithAlert";
import {
	clearSessionLog,
	getSessionLogList,
} from "../../app/actions/sessionLogActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../app/actions/filterActions";

const columns = [
	{ field: "id", headerName: "Id" },
	{ field: "firstName", headerName: "First name" },
	{ field: "lastName", headerName: "Last name" },
	{ field: "uid", headerName: "User id" },
	{ field: "device", headerName: "Device" },
	{ field: "date", headerName: "Date&Time" },
	{ field: "actions", headerName: "Actions" },
];
const filterFields = [
	{
		field: "dateFrom",
		type: "date",
		title: "Date from",
		placeholder: "Date from",
	},
	{
		field: "dateTo",
		type: "date",
		title: "Date to",
		placeholder: "Date to",
	},
];

const Sessions = ({ id }) => {
	const dispatch = useDispatch();
	const { data, total } = useSelector(({ sessionLogs }) => sessionLogs);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);
	const [sessionFilterFields, setSessionFilterFields] = useState(filterFields);

	useEffect(() => {
		setSessionFilterFields(
			!id
				? [
						...filterFields,
						{
							field: "uid",
							type: "number",
							title: "User ID",
							placeholder: "User ID",
						},
				  ]
				: filterFields
		);
	}, [id]);

	useEffect(() => {
		let filteringParams = { uid: id || "" };
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getSessionLogList(filteringParams));
	}, [activeFilters, id, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
			dispatch(clearSessionLog());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<PageTitle
				title={id ? "" : "Sessions"}
				filterFields={sessionFilterFields}
			/>
			<div>
				<Table>
					<thead>
						<tr>
							{columns.map(({ field, headerName }) => (
								<th key={field} align={"left"}>
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{data.length > 0 ? (
							data.map((row, index) => (
								<TableRow key={row?.id || index} rowData={row} />
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[10, 30, 50]}
					component='div'
					count={total}
					rowsPerPage={activeFilters?.find(x => x.field === "size").value}
					page={activeFilters?.find(x => x.field === "page").value}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeSize}
				/>
			</div>
		</>
	);
};

export default Sessions;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<td>{rowData?.id}</td>
				<td>{rowData?.first_name}</td>
				<td>{rowData?.last_name}</td>
				<CopyWithAlert text={rowData?.uid + "" || ""} label={"user id"}>
					<td data='hover'>{rowData?.uid}</td>
				</CopyWithAlert>
				<td>{rowData?.description}</td>
				<td>
					{rowData?.date && moment(rowData?.date).format("DD MMM YYYY HH:mm")}
				</td>
				<td>{rowData?.action}</td>
			</tr>
		</>
	);
}
