import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../theme/components/Table/Table";
import CopyWithAlert from "../../utils/CopyWithAlert";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../app/actions/filterActions";
import { getCompanyBalanceTransferHistory } from "../../app/actions/companiesActions";
import { TRANSACTION_TYPES } from "../../utils/constants/companies";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "targetUserId", label: "Target User Id" },
	{ key: "targetAddress", label: "Target Address" },
	{ key: "companyBalanceId", label: "Company Balance Id" },
	{ key: "transactionType", label: "Transaction Type" },
	{ key: "description", label: "Description" },
	{ key: "amount", label: "Amount" },
	{ key: "time", label: "Time" },
];

const TransfersHistory = () => {
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(
		({ companies }) => companies.companyBalanceTransferHistory
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getCompanyBalanceTransferHistory(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default TransfersHistory;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.targetUserId + "" || ""}
					label={"Target User Id"}>
					<td data='hover'>{rowData?.targetUserId}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.targetAddress + "" || ""}
					label={"Target Address"}>
					<td data='hover'>{rowData?.targetAddress}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.companyBalanceId + "" || ""}
					label={"Company Balance Id"}>
					<td data='hover'>{rowData?.companyBalanceId}</td>
				</CopyWithAlert>
				<td
					style={{
						color:
							rowData?.transactionType === TRANSACTION_TYPES.Deposit
								? "#60CF61"
								: "#FF0000",
					}}>
					{rowData?.transactionType}
				</td>
				<td>{rowData?.description}</td>
				<td>{+rowData?.amount}</td>
				<td>
					{rowData?.time && moment(rowData?.time).format("DD MMM YYYY HH:mm")}
				</td>
			</tr>
		</>
	);
}
