import { lighten, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useState } from "react";
import AnyIcon from "@material-ui/icons/UnfoldMoreRounded";
import DownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import TopIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import Search from "../../theme/components/Search";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
	FILTER_NODE_PAYMENT_LIST,
	SORT_NODE_PAYMENT_LIST,
} from "../../app/types";
import { SORT_TYPES, next } from "../../utils/constants/sortTypes.js";

const PaymentListNode = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const data = useSelector(({ nodes }) => nodes.filteredPaymentList);
	const [search, setSearch] = useState("");
	const [columns, setColumns] = useState([
		{
			key: "confirmations",
			headerName: "Confirmations",
			sort: SORT_TYPES.none,
		},
		{ key: "blockhash", headerName: "Blockhash", sort: SORT_TYPES.none },
		{ key: "blocktime", headerName: "Blocktime", sort: SORT_TYPES.none },
		{ key: "txid", headerName: "Txid", sort: SORT_TYPES.none },
		{ key: "time", headerName: "Time", sort: SORT_TYPES.none },
		{ key: "timereceived", headerName: "Timereceived", sort: SORT_TYPES.none },
		{ key: "address", headerName: "Address", sort: SORT_TYPES.none },
		{ key: "category", headerName: "Category", sort: SORT_TYPES.none },
		{ key: "amount", headerName: "Amount", sort: SORT_TYPES.none },
		{ key: "vout", headerName: "Vout", sort: SORT_TYPES.none },
		{ key: "walletconflicts", headerName: "Walletconflicts" },
	]);

	const sorting = key => {
		const nextState = next(columns.find(s => s.key === key)?.sort);
		dispatch({
			type: SORT_NODE_PAYMENT_LIST,
			payload: { direction: nextState, key },
		});
		setColumns(
			columns.map(s =>
				s.key === key
					? { ...s, sort: nextState }
					: {
							...s,
							sort: s.sort ? SORT_TYPES.none : undefined,
					  }
			)
		);
	};

	useEffect(() => {
		dispatch({ type: FILTER_NODE_PAYMENT_LIST, payload: search });
	}, [dispatch, search]);

	return (
		<div style={{ width: "100%" }}>
			<div className={classes.head}>
				<Search
					value={search}
					onChange={({ target }) => setSearch(target.value)}
				/>
			</div>
			<div className={classes.root}>
				<table className={classes.table}>
					<thead>
						<tr>
							{columns.map(({ key, headerName, sort, colSpan, align }) => (
								<th
									key={key}
									className={clsx(classes.th, classes.paper, sort && "hover")}
									colSpan={colSpan}
									align={align || "left"}
									onClick={() => sort && sorting(key)}>
									{sort === SORT_TYPES.up && <TopIcon />}
									{sort === SORT_TYPES.none && <AnyIcon />}
									{sort === SORT_TYPES.down && <DownIcon />}
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{data?.length > 0 ? (
							data.map((row, index) => (
								<TableRow key={row?.id || index} rowData={row} />
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default PaymentListNode;

function TableRow({ rowData }) {
	const classes = useStyles();
	return (
		<>
			<tr className={classes.spacing}></tr>
			<tr className={classes.hover}>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.confirmations}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.blockhash}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.blocktime &&
						moment(rowData?.blocktime * 1000).format("DD MMM YYYY HH:mm")}
				</td>
				<td className={clsx(classes.td, classes.paper)}>{rowData?.txid}</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.time &&
						moment(rowData?.time * 1000).format("DD MMM YYYY HH:mm")}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.timereceived &&
						moment(rowData?.timereceived * 1000).format("DD MMM YYYY HH:mm")}
				</td>
				<td className={clsx(classes.td, classes.paper)}>{rowData?.address}</td>
				<td className={clsx(classes.td, classes.paper)}>{rowData?.category}</td>
				<td className={clsx(classes.td, classes.paper)}>{rowData?.amount}</td>
				<td className={clsx(classes.td, classes.paper)}>{rowData?.vout}</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.walletconflicts.join(",")}
				</td>
			</tr>
		</>
	);
}

const useStyles = makeStyles(theme => ({
	head: {
		display: "flex",
		alignItems: "end",
		justifyContent: "flex-end",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
		"&>.MuiTextField-root": {
			maxWidth: 256,
			[theme.breakpoints.down("sm")]: {
				marginTop: 24,
			},
			"&>.MuiOutlinedInput-root>input": {
				padding: 10,
			},
		},
	},
	root: {
		marginTop: 24,
		width: "100%",
		overflowX: "auto",
		paddingBottom: 24,
	},
	table: {
		borderSpacing: 0,
		width: "100%",
	},
	paper: {
		backgroundColor: theme.palette.background.light,
		"&:first-child": {
			borderRadius: "10px 0 0 10px",
		},
		"&:last-child": {
			borderRadius: "0 10px 10px 0",
		},
	},
	th: {
		color: theme.palette.text.secondary,
		padding: "8px",
		fontSize: 14,
		lineHeight: 2,
		whiteSpace: "nowrap",
		backgroundColor: "#262830",
		"&.hover:hover": {
			backgroundColor: lighten(theme.palette.background.paper, 0.04),
			cursor: "pointer",
		},
		"&>svg": {
			fontSize: 19,
			verticalAlign: "middle",
			marginRight: 4,
		},
	},
	td: {
		color: theme.palette.text.primary,
		backgroundColor: "#262830",
		padding: "8px 14px",
		fontSize: 13,
		maxWidth: 120,
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	spacing: {
		height: 12,
	},
	hover: {
		"&:hover>td": {
			backgroundColor: lighten(theme.palette.background.paper, 0.04),
		},
	},
}));
