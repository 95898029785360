import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Grid,
	Typography,
	MenuItem,
	TextareaAutosize,
	makeStyles,
} from "@material-ui/core";
import { SelectWithLabel } from "../../../../../theme/components/SelectWithLabel";
import { Input } from "../../../../../theme/components/Input";
import { Button } from "../../../../../theme/components/Button";
import DialogWrapper from "../../../../../theme/components/dialogs/DialogWrapper";
import { getCurrencies } from "../../../../../app/actions/walletsActions";
import {
	CREATE_INDEX_FORM_FIELDS,
	EDIT_INDEX_FORM_FIELDS,
} from "../../../../../utils/constants/indexes";
import {
	clearIndexesStatus,
	createInvestmentIndex,
	updateInvestmentIndex,
} from "../../../../../app/actions/indexesCapitalActions";
import { capitalValidator } from "../../../../../utils/customValidators/capitalValidator";

const EditIndexDialog = ({ open, handleClose, data }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { status } = useSelector(({ indexes }) => indexes);
	const { currencies } = useSelector(({ wallets }) => wallets);
	const [errors, setErrors] = useState({});
	const [state, setState] = useState(null);
	const [formFields, setFormFields] = useState(null);
	const updateFormFields = useCallback(() => {
		if (!data) {
			return;
		}
		let updateFields = [];
		let updateForm = {};
		let fields = data ? EDIT_INDEX_FORM_FIELDS : CREATE_INDEX_FORM_FIELDS;

		fields.forEach((item) => {
			updateFields = [...updateFields, item];
			updateForm[item?.field] = state[item?.field];
		});

		setFormFields(updateFields);
		setState(updateForm);
		// eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		if (status) {
			closeDialog();
			dispatch(clearIndexesStatus());
		}
		// eslint-disable-next-line
	}, [status]);

	useEffect(() => {
		updateFormFields();
	}, [updateFormFields]);

	useEffect(() => {
		dispatch(getCurrencies());
		if (data) {
			createState(EDIT_INDEX_FORM_FIELDS);
		} else {
			setFormFields(CREATE_INDEX_FORM_FIELDS);
			createState(CREATE_INDEX_FORM_FIELDS);
		}
		// eslint-disable-next-line
	}, [open, data]);

	const createState = (fields) => {
		let form = {};
		fields?.forEach((item) => {
			form[item.field] =
				item.type === "text" ||
				item.type === "select" ||
				item.type === "textarea"
					? data
						? data[item.field]
						: ""
					: item.type === "number"
					? data
						? +data[item.field]
						: 0
					: false;
		});
		setState(form);
	};

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(state).forEach((key) => {
			errForm = {
				...errForm,
				[key]: capitalValidator(key, state[key]),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]: capitalValidator(name, value),
		});
	};

	const handleChange = ({ target: { name, value } }) => {
		handleInputValidation();
		updateFormFields(name, value);
		setState({ ...state, [name]: value });
	};

	const handleChangeTypeNumber = (name, value) => {
		handleInputValidation(name, value);
		setState({ ...state, [name]: value });
	};

	const handleSave = () => {
		if (!validateForm()) {
			return;
		}
		if (data) {
			let updateData = {
				...state,
				indexId: data.id,
			};
			dispatch(updateInvestmentIndex(updateData));
		} else {
			let updateData = { ...state };
			dispatch(createInvestmentIndex(updateData));
		}
	};

	const closeDialog = () => {
		setState(createState());
		setErrors({});
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Grid container justifyContent='space-between'>
				<Typography
					variant={"h6"}
					color={"textPrimary"}
					style={{ marginBottom: 32 }}>
					{data ? `Edit index - token ${data?.token}` : "Create index"}
				</Typography>
			</Grid>
			<Grid container spacing={3}>
				{state &&
					formFields &&
					formFields?.map(({ field, label, type, selectData }) => (
						<Grid
							item
							xs={12}
							md={type === "textarea" ? 12 : formFields.length > 1 ? 6 : 12}
							key={field}>
							{type === "text" ? (
								<Input
									label={label}
									variant={"outlined"}
									type={type}
									name={field}
									value={state[field] || ""}
									error={errors[field]?.invalid}
									helperText={errors[field]?.errorMessage}
									onChange={handleChange}
								/>
							) : type === "number" ? (
								<Input
									label={label}
									variant='outlined'
									name={field}
									value={state[field] || ""}
									onChange={({ target: { value } }) =>
										/^[0-9]*\.?[0-9]*$/.test(value) &&
										!(
											value.length > 1 &&
											value[0] === "0" &&
											value[1] !== "."
										) &&
										handleChangeTypeNumber(field, value)
									}
									onFocus={() =>
										!state[field]
											? setState({
													...state,
													[field]: "",
											  })
											: setState({ ...state })
									}
									onBlur={() =>
										!state[field] && setState({ ...state, [field]: 0 })
									}
									error={errors[field]?.invalid}
									helperText={errors[field]?.errorMessage}
									InputProps={{
										autoComplete: "off",
									}}
								/>
							) : type === "textarea" ? (
								<Input
									className={classes.rootInput}
									fullWidth
									multiline
									label={label}
									variant={"outlined"}
									InputProps={{
										component: TextareaAutosize,
										minRows: 3,
										maxRows: 3,
									}}
									name={field}
									value={state[field] || ""}
									error={errors[field]?.invalid}
									helperText={errors[field]?.errorMessage}
									onChange={handleChange}
								/>
							) : type === "select" && selectData === "currencies" ? (
								<SelectWithLabel
									label={label}
									value={state[field] || ""}
									name={field}
									error={errors[field]?.invalid}
									helperText={errors[field]?.errorMessage}
									onChange={({ target }) =>
										handleChangeTypeNumber(field, target.value)
									}>
									{!!currencies &&
										currencies?.map((i, index) => (
											<MenuItem key={index} value={i?.ticker}>
												<div style={{ display: "flex", alignItems: "center" }}>
													<img
														src={`https://cryptoneed.com/icons/${i?.ticker}.svg`}
														alt={i?.ticker}
														width={16}
														height={16}
														style={{ marginRight: 12 }}
														loading={"lazy"}
													/>
													<p style={{ margin: 0 }}>
														{i?.ticker?.toUpperCase()}
													</p>
												</div>
											</MenuItem>
										))}
								</SelectWithLabel>
							) : (
								<SelectWithLabel
									label={label}
									value={state[field] || ""}
									name={field}
									error={errors[field]?.invalid}
									helperText={errors[field]?.errorMessage}
									onChange={({ target }) =>
										handleChangeTypeNumber(field, target.value)
									}>
									{selectData?.map((i, index) => (
										<MenuItem key={i.label || index} value={i.value}>
											{i.label}
										</MenuItem>
									))}
								</SelectWithLabel>
							)}
						</Grid>
					))}
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)" }}
							onClick={handleSave}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default EditIndexDialog;

const useStyles = makeStyles((theme) => ({
	rootInput: {
		"& .MuiFormHelperText-root": {
			marginTop: 96,
		},
	},
}));
