import {
	CLEAR_TRANSACTIONS,
	GET_TRANSACTIONS_FAILURE,
	GET_TRANSACTIONS_STARTED,
	GET_TRANSACTIONS_SUCCESS,
	SET_TRANSACTIONS_PARAMS,
	GET_TRANSFERS_TO_MASTER_WALLET_FAILURE,
	GET_TRANSFERS_TO_MASTER_WALLET_STARTED,
	GET_TRANSFERS_TO_MASTER_WALLET_SUCCESS,
} from "../types";
import api from "../api";

export const getTransactionsStarted = () => ({
	type: GET_TRANSACTIONS_STARTED,
});

export const getTransactionsSuccess = data => ({
	type: GET_TRANSACTIONS_SUCCESS,
	payload: data,
});

export const getTransactionsFailure = error => ({
	type: GET_TRANSACTIONS_FAILURE,
	payload: {
		error,
	},
});

export const getTransactions = params => {
	return async dispatch => {
		dispatch(getTransactionsStarted());
		api.transactions
			.getList(params)
			.then(res => dispatch(getTransactionsSuccess(res.data)))
			.catch(error => dispatch(getTransactionsFailure(error)));
	};
};

export const setTransactionParams = payload => ({
	type: SET_TRANSACTIONS_PARAMS,
	payload,
});

export const clearTransactions = () => ({
	type: CLEAR_TRANSACTIONS,
});

export const getTransfersToMasterWalletStarted = () => ({
	type: GET_TRANSFERS_TO_MASTER_WALLET_STARTED,
});

export const getTransfersToMasterWalletSuccess = data => ({
	type: GET_TRANSFERS_TO_MASTER_WALLET_SUCCESS,
	payload: data,
});

export const getTransfersToMasterWalletFailure = error => ({
	type: GET_TRANSFERS_TO_MASTER_WALLET_FAILURE,
	payload: {
		error,
	},
});

export const getTransfersToMasterWallet = params => {
	return async dispatch => {
		dispatch(getTransfersToMasterWalletStarted());
		api.finances
			.getTransfersToMasterWallet(params)
			.then(res => dispatch(getTransfersToMasterWalletSuccess(res.data)))
			.catch(error => dispatch(getTransfersToMasterWalletFailure(error)));
	};
};
