import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
	AppBar,
	Tab,
	Paper,
	Typography,
	IconButton,
	lighten,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import UserProfile from "./UserProfile";
import UsersTransactions from "./UsersTransactions";
import UserExchange from "./UserExchange";
import { makeStyles } from "@material-ui/core/styles";
import BackIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import { StyledTabs } from "../../theme/components/StyledTabs";
import Wallets from "./Wallets";
import { Button } from "../../theme/components/Button";
import Filter from "../../theme/components/Filter/index";
import ActiveFiltersPanel from "../../theme/components/Filter/ActiveFiltersPanel";
import { getProfileById } from "../../app/actions/usersActions";
import { deleteUserKycBlock } from "../../app/actions/kycActions";
import { getInternalExchangeId } from "../../app/actions/exchangeActions";
import { getTransactions } from "../../app/actions/transactionsActions";
import { setActiveFilter } from "../../app/actions/filterActions";
import DeleteConfirmDialog from "../../theme/components/dialogs/DeleteConfirmDialog";
import UserVipFunds from "./UserVipFunds";
import UserVipReferrals from "./UserVipReferrals";
import AddFundDialog from "./AddFundDialog";

const tabs = {
	profile: "Profile",
	transactions: "Transactions",
	exchange: "Exchange",
	wallets: "Wallets",
	funds: "Funds",
	referral: "Referral",
};

const transactionsFilterFields = [
	{ field: "address", type: "text", title: "Address", placeholder: "Address" },
	{ field: "amount", type: "number", title: "Amount", placeholder: "Amount" },
	{
		field: "blockhash",
		type: "text",
		title: "Blockhash",
		placeholder: "Blockhash",
	},
	{
		field: "id",
		type: "number",
		title: "Operation ID",
		placeholder: "Operation ID",
	},
	{ field: "ticker", type: "text", title: "Ticker", placeholder: "Ticker" },
	{
		field: "transactionStatus",
		type: "text",
		title: "Transaction Status",
		placeholder: "Transaction Status",
	},
];

const exchangeFilterFields = [
	{
		field: "id",
		type: "number",
		title: "Operation ID",
		placeholder: "Operation ID",
	},
	{
		field: "assetFrom",
		type: "text",
		title: "Asset From",
		placeholder: "Asset From",
	},
	{
		field: "assetTo",
		type: "text",
		title: "Asset To",
		placeholder: "Asset To",
	},
	{
		field: "amountMore",
		type: "number",
		title: "Amount More",
		placeholder: "Amount More",
	},
	{
		field: "amountLess",
		type: "number",
		title: "Amount Less",
		placeholder: "Amount Less",
	},
	{
		field: "totalMore",
		type: "number",
		title: "Total More",
		placeholder: "Total More",
	},
	{
		field: "totalLess",
		type: "number",
		title: "Total Less",
		placeholder: "Total Less",
	},
	{
		field: "dateFrom",
		type: "date",
		title: "Create date from",
		placeholder: "Create date from",
	},
	{
		field: "dateTo",
		type: "date",
		title: "Create date to",
		placeholder: "Create Date to",
	},
	{ field: "status", type: "text", title: "Status", placeholder: "Status" },
];

const UserCard = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();
	const [activeTab, setActiveTab] = useState(tabs.profile);
	const [params, setParams] = useState();
	const [filterFields, setFilterFields] = useState(transactionsFilterFields);
	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [showAddFundDialog, setShowAddFundDialog] = useState(false);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);
	const user = useSelector(({ users }) => users.profile);

	useEffect(() => {
		setParams(history?.location?.state?.params);
		if (id) {
			dispatch(getProfileById(id));
		}
	}, [dispatch, id, history?.location?.state]);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				if (activeTab === tabs.transactions) {
					if (item.field === "page" || item.field === "size") {
						filteringParams = { ...filteringParams, [item.field]: item.value };
					} else {
						filteringParams = {
							...filteringParams,
							[item.field + ".equals"]: item.value,
						};
					}
				}
				if (activeTab === tabs.exchange) {
					filteringParams = { ...filteringParams, [item.field]: item.value };
				}
			});
		}
		if (activeTab === tabs.transactions) {
			/*eslint no-useless-computed-key: "off"*/
			dispatch(getTransactions({ ...filteringParams, ["uid.equals"]: id }));
			setFilterFields(transactionsFilterFields);
		}
		if (activeTab === tabs.exchange) {
			/*eslint no-useless-computed-key: "off"*/
			dispatch(getInternalExchangeId(id, filteringParams));
			setFilterFields(exchangeFilterFields);
		}
	}, [activeFilters, activeTab, id, dispatch]);

	const handleChangeTab = (newTab) => {
		setActiveTab(newTab);
		dispatch(
			setActiveFilter([
				{ field: "size", value: 10 },
				{ field: "page", value: 0 },
			])
		);
	};

	const goBack = () => {
		history.push(history?.location?.state?.from || `/users/list`, {
			from: "user detail",
			params: params,
		});
	};

	const handleDeleteKyc = ({ accept }) => {
		if (accept) {
			dispatch(deleteUserKycBlock(user?.id));
		}
		setShowDeleteDialog(false);
	};

	const handleCloseDialog = () => {
		setShowAddFundDialog(false)
	}

	const handleAddFund = () => {
		setShowAddFundDialog(true)
	}

	return (
		<div className={classes.root}>
			<DeleteConfirmDialog
				open={showDeleteDialog}
				title='Delete KYC?'
				message={`Are you sure you want to remove the KYC of user id - ${user?.id}?`}
				handleClose={handleDeleteKyc}
				btnOkText='Delete'
			/>
			<AddFundDialog
				open={showAddFundDialog}
				handleClose={handleCloseDialog}
				userId={user?.id}
			/>
			<AppBar position='static' className={classes.appBar}>
				<div className={classes.btnWrap}>
					<Paper elevation={0} className={classes.paper} onClick={goBack}>
						<IconButton className={classes.icon} size={"small"}>
							<BackIcon />
						</IconButton>
						<Typography variant={"subtitle2"} color={"textSecondary"}>
							Back to{" "}
							<span className={classes.link}>
								{history?.location?.state?.title || "Users"}
							</span>
						</Typography>
					</Paper>
					{(activeTab === tabs.transactions || activeTab === tabs.exchange) && (
						<Filter filterFields={filterFields} />
					)}
					{activeTab === tabs.profile && user?.kycPassed && (
						<Button
							className={classes.delete}
							onClick={() => setShowDeleteDialog(true)}>
							Delete KYC
						</Button>
					)}
					{activeTab === tabs.funds && (
						<Button variant='outlined' color='primary' onClick={handleAddFund}>
							<AddIcon />
							Add Fund
						</Button>
					)}
				</div>
				{(activeTab === tabs.transactions || activeTab === tabs.exchange) && (
					<ActiveFiltersPanel />
				)}
				<StyledTabs
					style={{ marginBottom: 12 }}
					indicatorColor='primary'
					value={activeTab}
					onChange={(_, newTab) => handleChangeTab(newTab)}>
					<Tab label='Profile' value={tabs.profile} />
					<Tab label='Transactions' value={tabs.transactions} />
					<Tab label='Exchange' value={tabs.exchange} />
					<Tab label='Wallets' value={tabs.wallets} />
					{user?.vip && [
						<Tab key={tabs.funds} label='Funds' value={tabs.funds} />,
						<Tab key={tabs.referral} label='Referral' value={tabs.referral} />,
					]}
					{user?.liquidityProvider && [
						<Tab key={tabs.funds} label='Funds' value={tabs.funds} />,
						<Tab key={tabs.referral} label='Referral' value={tabs.referral} />,
					]}
				</StyledTabs>
			</AppBar>
			{activeTab === tabs.profile ? (
				<UserProfile />
			) : activeTab === tabs.transactions ? (
				<UsersTransactions id={id} />
			) : activeTab === tabs.exchange ? (
				<UserExchange />
			) : activeTab === tabs.wallets ? (
				<Wallets />
			) : activeTab === tabs.funds ? (
				<UserVipFunds />
			) : (
				<UserVipReferrals />
			)}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		background: theme.palette.background.default,
		boxShadow: "none",
	},
	btnWrap: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	paper: {
		display: "flex",
		alignItems: "center",
		padding: 8,
		paddingRight: 12,
		borderRadius: 8,
		width: "max-content",
		marginBottom: 16,
		"&:hover": {
			cursor: "pointer",
		},
	},
	icon: {
		color: theme.palette.primary.light,
		padding: 0,
		marginRight: 4,
		"& svg": {
			fontSize: 19,
		},
	},
	link: {
		color: theme.palette.primary.light,
		fontWeight: 700,
		textDecoration: "none",
	},
	delete: {
		background: `${theme.palette.error.main} !important`,
		minWidth: "150px",
		maxHeight: 36,
		"&:hover": {
			background: `${lighten(theme.palette.error.main, 0.1)} !important`,
		},
	},
}));

export default UserCard;
