import moment from "moment";

export const BANK_CARDS_DETAILS = [
	{ key: "id", title: "KYC ID", type: "string" },
	{ key: "status", title: "Status", type: "string" },
	{
		key: "idOfPersonResponsibleForDecisionOnThisKyc",
		title: "Decision is made",
		type: "string",
	},
	{ key: "decision", title: "Decision", type: "string" },
	{ key: "userId", title: "User ID", type: "string" },
	{ key: "cardName", title: "Card Name", type: "string"  },
	{ key: "cardNumber", title: "Card Number", type: "string"  },
	{ key: "cardIssuer", title: "Card Issuer", type: "string"  },
	{ key: "ownerFirstName", title: "First Name", type: "string" },
	{ key: "ownerLastName", title: "Last Name", type: "string" },
	{ key: "ownerMiddleName", title: "Middle Name", type: "string" },
	{ key: "createdDate", title: "Created", type: "date" },
	{ key: "completeDate", title: "Completed", type: "date" },
	{ key: "expirationDate", title: "Expiration", type: "date" },
];

export const BANK_CARDS_ALL_COLUMNS = [
	{ key: "userId", headerName: "User ID" },
	{ key: "cardName", headerName: "Card Name" },
	{ key: "cardNumber", headerName: "Card Number" },
	{ key: "cardIssuer", headerName: "Card Issuer" },
	{ key: "ownerFirstName", headerName: "First Name" },
	{ key: "ownerLastName", headerName: "Last Name" },
	{ key: "ownerMiddleName", headerName: "Middle Name" },
	{ key: "phoneNumber", headerName: "Phone Number" },
	{ key: "status", headerName: "Status" },
	{ key: "creationDate", headerName: "Created" },
	{ key: "expirationDate", headerName: "Expiration" },
	{ key: "completeDate", headerName: "Completed", tab: "all" },
	{ key: "manage", headerName: "Manage" },
];

export const BANK_CARDS_COLUMNS = [
	{ key: "userId", headerName: "User ID" },
	{ key: "cardName", headerName: "Card Name" },
	{ key: "cardNumber", headerName: "Card Number" },
	{ key: "ownerFirstName", headerName: "First Name" },
	{ key: "ownerLastName", headerName: "Last Name" },
	{ key: "ownerMiddleName", headerName: "Middle Name" },
	{ key: "phoneNumber", headerName: "Phone Number" },
	{ key: "status", headerName: "Status" },
	{ key: "creationDate", headerName: "Created" },
	{ key: "expirationDate", headerName: "Expiration" },
	{ key: "manage", headerName: "Manage" },
];

export const STATUS_FILTER = [
	{ label: "APPROVED", value: "APPROVED" },
	{ label: "WAITING_FOR_REVIEW", value: "WAITING_FOR_REVIEW" },
	{ label: "DECLINED", value: "DECLINED" },
	{ label: "EXPIRED", value: "EXPIRED" },
];

export const FILE_TYPES = ["image/jpeg", "image/png"];

export const BANK_CARDS_SOLUTION = {
	DECLINED: "DECLINED",
	APPROVED: "APPROVED",
};

export const BANK_CARDS_STATUS = {
	DECLINED: "DECLINED",
	PENDING: "WAITING_FOR_REVIEW",
	APPROVED: "APPROVED",
	EXPIRED: "EXPIRED"
};

export const PHOTO_TYPES = {
	SELFIE: "SELFIE",
	CARD: "CARD",
};

export const YEARS = () => {
	const maxYear = moment().format("YYYY");
	const arr = [];
	for (let index = 1900; index < maxYear; index++) {
		arr.push(index + "");
	}
	return arr.reverse();
};

export const MONTHS = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export const DAYS = [
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"10",
	"11",
	"12",
	"13",
	"14",
	"15",
	"16",
	"17",
	"18",
	"19",
	"20",
	"21",
	"22",
	"23",
	"24",
	"25",
	"26",
	"27",
	"28",
	"29",
	"30",
	"31",
];

export const BANK_CARDS_ALL_FILTER_FIELDS = [
	{ field: "userId", type: "number", title: "User ID", placeholder: "User ID" },
	{
		field: "firstName",
		type: "text",
		title: "First Name",
		placeholder: "First Name",
	},
	{
		field: "lastName",
		type: "text",
		title: "Last Name",
		placeholder: "Last Name",
	},
	{
		field: "	middleName",
		type: "text",
		title: "Middle Name",
		placeholder: "Middle Name",
	},
	// {
	// 	field: "status",
	// 	type: "select",
	// 	title: "Status",
	// 	placeholder: "Status",
	// 	data: STATUS_FILTER,
	// },
	{ field: "city", type: "text", title: "City", placeholder: "City" },
	{ field: "address", type: "text", title: "Address", placeholder: "Address" },
	{
		field: "postIndex",
		type: "text",
		title: "Post index",
		placeholder: "Post index",
	},
	{
		field: "creationDateFrom",
		type: "date",
		title: "Creation date from",
		placeholder: "Creation date from",
	},
	{
		field: "creationDateTo",
		type: "date",
		title: "Creation date to",
		placeholder: "Creation date to",
	},

	{
		field: "completeDateFrom",
		type: "date",
		title: "Complete date from",
		placeholder: "Complete date from",
	},
	{
		field: "completeDateTo",
		type: "date",
		title: "Complete date to",
		placeholder: "Complete date to",
	},
	{
		field: "expirationDateFrom",
		type: "date",
		title: "Expiration date from",
		placeholder: "Expiration date from",
	},
	{
		field: "expirationDateTo",
		type: "date",
		title: "Expiration date to",
		placeholder: "Expiration date to",
	},
];

export const BANK_CARDS_FILTER_FIELDS = [
	{ field: "userId", type: "number", title: "User ID", placeholder: "User ID" },

	{
		field: "firstName",
		type: "text",
		title: "First Name",
		placeholder: "First Name",
	},
	{
		field: "lastName",
		type: "text",
		title: "Last Name",
		placeholder: "Last Name",
	},
	{
		field: "	middleName",
		type: "text",
		title: "Middle Name",
		placeholder: "Middle Name",
	},
	{ field: "city", type: "text", title: "City", placeholder: "City" },
	{ field: "address", type: "text", title: "Address", placeholder: "Address" },
	{
		field: "postIndex",
		type: "text",
		title: "Post index",
		placeholder: "Post index",
	},
	{
		field: "creationDateFrom",
		type: "date",
		title: "Creation date from",
		placeholder: "Creation date from",
	},
];
