import {
	CLEAR_NODE_DETAILS,
	// GET_NODE_DETAILS_STARTED,
	// GET_NODE_DETAILS_SUCCESS,
	// GET_NODE_DETAILS_FAILURE,
	// SORT_NODE_PAYMENT_LIST,
	// FILTER_NODE_PAYMENT_LIST,
	GET_WALLETS_TOTAL_STARTED,
	GET_WALLETS_TOTAL_SUCCESS,
	GET_WALLETS_TOTAL_FAILURE,
	GET_INFO_NODE_BLOCKS_TRON_STARTED,
	GET_INFO_NODE_BLOCKS_TRON_SUCCESS,
	GET_INFO_NODE_BLOCKS_TRON_FAILURE,
	GET_INFO_NODE_BLOCKS_ETHEREUM_STARTED,
	GET_INFO_NODE_BLOCKS_ETHEREUM_SUCCESS,
	GET_INFO_NODE_BLOCKS_ETHEREUM_FAILURE,
} from "../types";

// import { sorting, filtering } from "../../utils/functions/filteringTable";

export default function coins(
	state = {
		walletsTotal: null,
		infoNodeBlocksTron: null,
		infoNodeBlocksEthereum: null,
		// nodeDetails: null,
		error: null,
		// paymentList: [],
		// filteredPaymentList: [],
	},
	action
) {
	switch (action.type) {
		// case GET_NODE_DETAILS_STARTED:
		// 	return { ...state, error: null };
		// case GET_NODE_DETAILS_SUCCESS:
		// 	return {
		// 		...state,
		// 		nodeDetails: action.payload.data,
		// 		paymentList: action.payload.data?.paymentList,
		// 		filteredPaymentList: action.payload.data?.paymentList,
		// 	};
		// case GET_NODE_DETAILS_FAILURE:
		// 	return { ...state, error: action.payload.error };

		// case SORT_NODE_PAYMENT_LIST:
		// 	return {
		// 		...state,
		// 		filteredPaymentList: sorting(
		// 			[...state.filteredPaymentList],
		// 			action.payload.direction,
		// 			action.payload.key
		// 		),
		// 	};
		// case FILTER_NODE_PAYMENT_LIST:
		// 	return {
		// 		...state,
		// 		filteredPaymentList: filtering(state.paymentList, action.payload),
		// 	};

		case GET_WALLETS_TOTAL_STARTED:
			return { ...state, walletsTotal: null, error: null };
		case GET_INFO_NODE_BLOCKS_ETHEREUM_STARTED:
			return { ...state, infoNodeBlocksEthereum: null, error: null };
		case GET_INFO_NODE_BLOCKS_TRON_STARTED:
			return { ...state, infoNodeBlocksTron: null, error: null };
		case GET_WALLETS_TOTAL_SUCCESS:
			return {
				...state,
				walletsTotal: action.payload.data,
			};
		case GET_INFO_NODE_BLOCKS_ETHEREUM_SUCCESS:
			return {
				...state,
				infoNodeBlocksEthereum: action.payload.data,
			};
		case GET_INFO_NODE_BLOCKS_TRON_SUCCESS:
			return {
				...state,
				infoNodeBlocksTron: action.payload.data.map(item => {
					const blockDifference =
						!!item.currentNodeBlock &&
						item.currentNetworkBlock - item.currentNodeBlock;
					return {
						...item,

						blockDifference: !item.currentNodeBlock
							? "-"
							: blockDifference <= 0
							? 0
							: blockDifference,
					};
				}),
			};
		case CLEAR_NODE_DETAILS:
			return {
				...state,
				infoNodeBlocksTron: null,
				infoNodeBlocksEthereum: null,
			};
		case GET_INFO_NODE_BLOCKS_TRON_FAILURE:
		case GET_WALLETS_TOTAL_FAILURE:
		case GET_INFO_NODE_BLOCKS_ETHEREUM_FAILURE:
			return { ...state, error: action.payload.error };

		default:
			return state;
	}
}
