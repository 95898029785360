import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import {
	IconButton,
	makeStyles,
	TablePagination,
	Typography,
	Tabs,
	Tab,
	Box,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { Visibility } from "@material-ui/icons";
import Table from "../../theme/components/Table/Table";
import PageTitle from "../../theme/components/PageTitle";
import CopyWithAlert from "../../utils/CopyWithAlert";
import PendingActivationUsers from "./PendingActivationUsers";
import {
	getUsers,
	setUsersParams,
	clearUsers,
} from "../../app/actions/usersActions";

const tabs = {
	users: "Users",
	pendingActivation: "Pending activation",
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}>
			{value === index && <Box style={{ paddingTop: 24 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const Users = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const [activeTab, setActiveTab] = useState(0);
	const { data, params, total } = useSelector(({ users }) => users);

	const columns = [
		{ key: "id", headerName: "ID" },
		{ key: "login", headerName: "Login" },
		{ key: "email", headerName: "Email" },
		{ key: "creationTime", headerName: "Created" },
		{ key: "kycPassed", headerName: "KYC" },
		{ key: "vip", headerName: "VIP" },
		{ key: "manage", headerName: "Manage" },
	];

	useEffect(() => {
		!!params && dispatch(getUsers(params));
	}, [dispatch, params]);

	useEffect(() => {
		if (
			history?.location?.state?.from === "user detail" &&
			history?.location?.state?.params
		) {
			dispatch(setUsersParams(JSON.parse(history?.location?.state?.params)));
		} else {
			dispatch(setUsersParams({ page: 0, size: 10, search: "" }));
		}
	}, [dispatch, history.location?.state]);

	useEffect(() => {
		return () => {
			dispatch(setUsersParams(null));
		};
	}, [dispatch]);

	const handleChangeTabs = (_, newTab) => {
		setActiveTab(newTab);
		setUsersParams(null);
	};

	const handleChangePage = (_, newPage) => {
		dispatch(setUsersParams({ ...params, page: newPage }));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setUsersParams({ ...params, size: target.value, page: 0 }));
	};

	const handleManage = (data) => {
		dispatch(clearUsers());
		history.push(
			`/users/list/${data?.login}/${data?.id}/?params=${JSON.stringify(
				params
			)}`,
			{ from: "/users/list", params: JSON.stringify(params), title: "Users" }
		);
	};

	const handleSearch = (search) => {
		dispatch(setUsersParams({ ...params, search, page: 0 }));
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<PageTitle
				title={"Users"}
				onSearch={activeTab === 0 ? handleSearch : null}
				valueSearch={params?.search}
			/>
			<Tabs
				value={activeTab}
				onChange={handleChangeTabs}
				aria-label='simple tabs example'
				indicatorColor='primary'>
				<Tab label={tabs.users} {...a11yProps(0)} />
				<Tab label={tabs.pendingActivation} {...a11yProps(1)} />
			</Tabs>
			<TabPanel value={activeTab} index={0}>
				<Typography
					variant={"body1"}
					style={{ fontWeight: 700, marginBottom: 8 }}>
					Total Users - {total}
				</Typography>
				<Table>
					<thead>
						<tr>
							{columns.map(({ key, headerName, sort, colSpan, align }) => (
								<th
									key={key}
									className={clsx(classes.th, classes.paper, sort && "hover")}
									colSpan={colSpan}
									align={align || "left"}>
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{data?.length > 0 ? (
							data?.map((row, index) => (
								<TableRow
									key={row?.id || index}
									rowData={row}
									onManage={handleManage}
								/>
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[10, 30, 50]}
					component='div'
					count={total}
					rowsPerPage={params?.size || 10}
					page={(total && params?.page) || 0}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeSize}
				/>
			</TabPanel>
			<TabPanel value={activeTab} index={1}>
				<PendingActivationUsers />
			</TabPanel>
		</div>
	);
};

export default Users;

function TableRow({ rowData, onManage }) {
	const classes = useStyles();
	return (
		<>
			<tr className={classes.hover}>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"id"}>
					<td className={classes.hoverCopyTd}>{rowData?.id}</td>
				</CopyWithAlert>
				<td>{rowData?.login}</td>
				<CopyWithAlert text={rowData?.email + "" || ""} label={"email"}>
					<td className={classes.hoverCopyTd}>{rowData?.email}</td>
				</CopyWithAlert>
				<td>{moment(rowData?.creationTime).format("DD MMM yyyy HH:mm:ss")}</td>
				<td>
					{rowData?.kycPassed ? (
						<CheckIcon style={{ color: "#00ff00" }} />
					) : (
						<CloseIcon style={{ color: "#ff0000" }} />
					)}
				</td>
				<td>
					{rowData?.vip ? (
						<CheckIcon style={{ color: "#00ff00" }} />
					) : (
						<CloseIcon style={{ color: "#ff0000" }} />
					)}
				</td>
				<td>
					<IconButton onClick={() => onManage(rowData)}>
						<Visibility />
					</IconButton>
				</td>
			</tr>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	hoverCopyTd: {
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.light,
		},
	},
}));
