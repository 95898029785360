import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Table from "../../../theme/components/Table/Table";
import moment from "moment";
import { TablePagination } from "@material-ui/core";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import { clearActiveFilters } from "../../../app/actions/filterActions";
import getStatusColor from "../../../utils/functions/getStatusColor";
import { clearSyncHistory } from "../../../app/actions/syncActions";

const columns = [
	{ key: "id", label: "Id" },

	{ key: "programId", label: "Program Id" },
	{ key: "orderId", label: "Order Id" },
	{ key: "orderPrice", label: "Order Price" },
	{ key: "orderType", label: "Order Type" },
	{ key: "market", label: "Market" },
	{ key: "executedAmount", label: "Amount" },
	{ key: "commission", label: "Commission" },
	{ key: "avgPrice", label: "AvgPrice" },
	{ key: "side", label: "Side" },
	{ key: "createdAt", label: "Created" },
	{ key: "updatedAt", label: "Updated" },
	{ key: "status", label: "Status" },
];

const KunaOrderHistoryTable = ({
	data,
	params,
	total,
	onPageChange,
	onRowsPerPageChange,
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearSyncHistory());
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody style={{ position: "relative" }}>
					{data?.length > 0 ? (
						data.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
								onStatusColor={getStatusColor}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 30, 50]}
				component='div'
				count={total}
				rowsPerPage={params.find(x => x.field === "size").value}
				page={params.find(x => x.field === "page").value}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
			/>
		</>
	);
};

export default KunaOrderHistoryTable;

function TableRow({ rowData, onStatusColor }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.programId + "" || ""}
					label={"Program Id"}>
					<td data='hover'>{rowData?.programId}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.orderId + "" || ""} label={"Order Id"}>
					<td data='hover'>{rowData?.orderId}</td>
				</CopyWithAlert>
				<td>{rowData?.orderPrice}</td>
				<td>{rowData?.orderType}</td>
				<CopyWithAlert text={rowData?.market + "" || ""} label={"Market"}>
					<td data='hover'>{rowData?.market}</td>
				</CopyWithAlert>

				<td>{rowData?.executedAmount}</td>
				<td>{rowData?.commission}</td>
				<td>{rowData?.avgPrice}</td>

				<CopyWithAlert text={rowData?.side + "" || ""} label={"Side"}>
					<td data='hover'>{rowData?.side}</td>
				</CopyWithAlert>
				<td>
					{rowData?.createdAt &&
						moment(rowData?.createdAt).format("DD MMM YYYY HH:mm")}
				</td>
				<td>
					{rowData?.updatedAt &&
						moment(rowData?.updatedAt).format("DD MMM YYYY HH:mm")}
				</td>
				<td
					data='hover'
					style={{
						color: rowData?.status && onStatusColor(rowData?.status),
					}}>
					{rowData?.status || "-"}
				</td>
			</tr>
		</>
	);
}
