import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../theme/components/PageTitle";
import {
	clearSyncHistory,
	getSyncStatistics,
} from "../../../app/actions/syncActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../app/actions/filterActions";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../../theme/components/Table/Table";
import CopyWithAlert from "../../../utils/CopyWithAlert";

const syncStatisticsFilterFields = [
	{
		field: "id",
		type: "number",
		title: "ID",
		placeholder: "ID",
	},
	{ field: "userId", type: "number", title: "User ID", placeholder: "User ID" },
	{
		field: "userOrderId",
		type: "number",
		title: "UserOrderId",
		placeholder: "UserOrderId",
	},
	{
		field: "syncProgramId",
		type: "number",
		title: " SyncProgramId",
		placeholder: "SyncProgramId",
	},
	{
		field: "currency",
		type: "text",
		title: "Currency",
		placeholder: "Currency",
	},
	{
		field: "dateFrom",
		type: "date",
		title: "Create date from",
		placeholder: "Create date from",
	},
	{
		field: "dateTo",
		type: "date",
		title: "Create date to",
		placeholder: "Create date to",
	},
];

const columns = [
	{ key: "id", label: "Id" },
	{ key: "currency", label: "Currency" },
	{ key: "executed", label: "Executed" },
	{ key: "estimated", label: "Estimated" },
	{ key: "profit", label: "Profit" },
	{ key: "userId", label: "User ID" },
	{ key: "userOrderId", label: "UserOrderId" },
	{ key: "syncProgramId", label: "SyncProgramId" },
	{ key: "date", label: "Created" },
];

const Statistics = () => {
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(
		({ synchronization }) => synchronization.sync
	);
	const [filterFields, setFilterFields] = useState(syncStatisticsFilterFields);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getSyncStatistics(filteringParams));
		setFilterFields(syncStatisticsFilterFields);
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
			dispatch(clearSyncHistory());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<PageTitle
				title={"Synchronization statistics"}
				filterFields={filterFields}
			/>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find(x => x.field === "size").value}
				page={activeFilters?.find(x => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default Statistics;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.currency + "" || ""} label={"Currency"}>
					<td data='hover'>{rowData?.currency}</td>
				</CopyWithAlert>
				<td>{rowData?.executed}</td>
				<td>{rowData?.estimated}</td>
				<td>{rowData?.profit}</td>
				<CopyWithAlert text={rowData?.userId + "" || ""} label={"User ID"}>
					<td data='hover'>{rowData?.userId}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.userOrderId + "" || ""}
					label={"UserOrderId"}>
					<td data='hover'>{rowData?.userOrderId}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.syncProgramId + "" || ""}
					label={"SyncProgramId"}>
					<td data='hover'>{rowData?.syncProgramId}</td>
				</CopyWithAlert>
				<td>
					{rowData?.date && moment(rowData?.date).format("DD MMM YYYY HH:mm")}
				</td>
			</tr>
		</>
	);
}
