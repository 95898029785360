import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import { TablePagination } from "@material-ui/core";
import clsx from "clsx";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Table from "../../../../theme/components/Table/Table";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "../../../../theme/components/Button.jsx";
import EditProviderDialog from "./EditProviderDialog.jsx";
import { useSettingsStyles } from "../../../Settings/SettingsStyles.jsx";
import { deleteLiquidityProviderSettings, getAllLiquidityProvidersSettings } from "../../../../app/actions/bankingActions.js";
import CopyWithAlert from "../../../../utils/CopyWithAlert.jsx";
import DeleteConfirmDialog from "../../../../theme/components/dialogs/DeleteConfirmDialog.jsx";

const columns = [
	{ field: "userId", headerName: "User ID" },
	{ field: "telegramChatId", headerName: "Telegram Chat ID" },
	{ field: "telegramBotToken", headerName: "Telegram Bot Token" },
	{ field: "edit", headerName: "Manage" },
];

const LiquidityProvidersList = () => {
	const classes = useSettingsStyles();
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(({ banking }) => banking.liquidityProviders);
	const [deleteProvider, setDeleteProvider] = useState(null);
	const [editProvider, setEditProvider] = useState(null);
	const [create, setCreate] = useState(false);
	const [params, setParams] = useState({ page: 0, size: 10 });

	useEffect(() => {
		dispatch(getAllLiquidityProvidersSettings(params));
	}, [dispatch, params]);

	const handleChangePage = (_, newPage) => {
		setParams({ ...params, page: newPage });
	};

	const handleChangeSize = ({ target }) => {
		setParams({ ...params, size: target.value, page: 0 });
	};

	const handleDeleteModalClose = ({ accept }) => {
		if (accept && deleteProvider?.userId) {
			dispatch(deleteLiquidityProviderSettings(deleteProvider?.userId, params)).then(() => {
				setDeleteProvider(null)
			})
		} else {
			setDeleteProvider(null)
		}
	}

	return (
		<div style={{ maxWidth: "100%" }}>
			<DeleteConfirmDialog
				open={Boolean(deleteProvider)}
				title={`Delete selected provider?`}
				message={`Are you sure you want to liquidity provider setting for user with id: ${deleteProvider?.userId}`}
				handleClose={handleDeleteModalClose}
				btnOkText="Delete"
			/>
			<EditProviderDialog
				open={Boolean(editProvider) || create}
				handleClose={() => {
					setEditProvider(null);
					setCreate(false);
				}}
				data={editProvider}
				params={params}
			/>
			<div className={classes.head} style={{ marginTop: -60 }}>
				<div></div>
				<Button
					variant='outlined'
					color='primary'
					style={{ marginBottom: 16, padding: "2px 28px" }}
					onClick={() => setCreate(true)}>
					<AddIcon />
					Create
				</Button>
			</div>
			<Table>
				<thead>
					<tr>
						{columns.map(({ field, headerName }) => (
							<th
								key={field}
								className={clsx(classes.th, classes.paper)}
								align={"left"}>
								{headerName}
							</th>
						))}
					</tr>
				</thead>
				<tbody style={{ position: "relative" }}>
					{content.length > 0 ? (
						content.map((row, index) => (
							<TableRow
								key={row?.id || index}
								rowData={row}
								handleDelete={() => setDeleteProvider(row)}
								handleEdit={() => setEditProvider(row)}
							/>
						))
					) : (
						<tr>
							<td colSpan={13} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={params?.size}
				page={params?.page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</div>
	);
};

export default LiquidityProvidersList;

function TableRow({ rowData, handleDelete, handleEdit }) {
	const classes = useSettingsStyles();

	return (
		<>
			<tr className={classes.hover}>
				<CopyWithAlert
					text={`${rowData?.userId}`}
					label={"User ID"}>
					<td data='hover'>{rowData?.userId}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.telegramChatId + "" || ""}
					label={"Telegram Chat ID"}>
					<td data='hover'>{rowData?.telegramChatId}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.telegramBotToken + "" || ""}
					label={"Telegram Bot Token"}>
					<td data='hover'>{rowData?.telegramBotToken}</td>
				</CopyWithAlert>
				<td className={clsx(classes.td, classes.paper)}>
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={handleDelete}>
						<DeleteIcon style={{ color: "#ff0000" }} />
					</IconButton>
				</td>
			</tr>
		</>
	);
}
