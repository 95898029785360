import DialogWrapper from "../../theme/components/dialogs/DialogWrapper";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Input } from "../../theme/components/Input";
import { Button } from "../../theme/components/Button";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
	checkValueIsNumber,
	requiredValidator,
} from "../../utils/customValidators/validators";
import { createCompanyBalanceSecuritySettings, updateCompanyBalanceSecuritySettings } from "../../app/actions/companiesActions";

const initialForm = {
	ttlInMinutes: "",
	approveCount: ""
};

const AddSecuritySettingsToBalanceDialog = ({ open, handleClose, balanceId, data }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [form, setForm] = useState(initialForm);
	const [errors, setErrors] = useState({});

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach((key) => {
			errForm = { ...errForm, [key]: requiredValidator(undefined, form[key]) };
			errForm[key].invalid && (valid = false);
		});

		setErrors(errForm);
		return valid;
	};

	const handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
		handleInputValidation(event);
	};

	const handleCreate = () => {
		if (!validateForm()) {
			return;
		}
		if (data && data?.id) {
			dispatch(updateCompanyBalanceSecuritySettings({ balanceId, settingsId: data?.id, ...form }))
		} else {
			dispatch(createCompanyBalanceSecuritySettings({ balanceId, ...form }));
		}
		handleCloseDialog();
	};

	const handleInputValidation = (event) => {
		const { name, value } = event.target;
		const errorField = requiredValidator(undefined, value);
		setErrors({
			...errors,
			[name]: errorField,
		});
	};

	const handleCloseDialog = () => {
		setErrors({});
		setForm(initialForm);
		handleClose();
	};

	useEffect(() => {
		if (data?.id) {
			setForm({ ttlInMinutes: data?.ttlInMinutes || '', approveCount: data?.approveCount || '' })
		}
	}, [data])

	return (
		<DialogWrapper
			open={open}
			handleClose={handleCloseDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				Add Security Settings
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} className={classes.formCroup}>
					<Input
						type='text'
						error={errors?.ttlInMinutes?.invalid}
						label='TTL in Minutes'
						variant={"outlined"}
						name={"ttlInMinutes"}
						value={form?.ttlInMinutes || ""}
						onChange={(e) => {
							checkValueIsNumber(e.target.value) && handleChange(e);
						}}
						onBlur={handleInputValidation}
						helperText={errors?.ttlInMinutes?.errorMessage}
					/>
				</Grid>
				<Grid item xs={12} className={classes.formCroup}>
					<Input
						type='text'
						error={errors?.approveCount?.invalid}
						label='Approve Count'
						variant={"outlined"}
						name={"approveCount"}
						value={form?.approveCount || ""}
						onChange={(e) => {
							checkValueIsNumber(e.target.value) && handleChange(e);
						}}
						onBlur={handleInputValidation}
						helperText={errors?.approveCount?.errorMessage}
					/>
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)", marginTop: 12 }}
							onClick={handleCreate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default AddSecuritySettingsToBalanceDialog;

const useStyles = makeStyles((theme) => ({
	formCroup: {
		display: "flex",
		flexDirection: "column",
	},
	errorField: {
		color: theme.palette.text.error,
		fontSize: 12,
		position: "absolute",
		marginTop: 56,
	},
	success: {
		color: theme.palette.text.success,
	},
	error: {
		color: theme.palette.text.error,
	},
}));
