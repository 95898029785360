import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { Input } from "../../../../theme/components/Input";
import { Button } from "../../../../theme/components/Button";
import DialogWrapper from "../../../../theme/components/dialogs/DialogWrapper";
import {
	changeParent,
	clearReferralsStatus,
} from "../../../../app/actions/referralsActions";

const initialForm = {
	id: "",
	newOwner: "",
};

const ChangeParentDialog = ({ open, handleClose, data = null }) => {
	const dispatch = useDispatch();
	const [errors, setErrors] = useState({});
	const [form, setForm] = useState(initialForm);
	const { status } = useSelector(({ referrals }) => referrals);

	useEffect(() => {
		if (!data) {
			return;
		}
		setForm({ ...form, id: data.id });
		// eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		if (status) {
			closeDialog();
			dispatch(clearReferralsStatus());
		}
		// eslint-disable-next-line
	}, [dispatch, status]);

	const handleUpdate = () => {
		if (!Number.isInteger(+form?.newOwner)) {
			setErrors({ newOwner: "Enter only an integer" });
			return;
		}
		dispatch(changeParent(form));
	};

	const handleChange = (value, name) => {
		if (!Number.isInteger(+value)) {
			setErrors({ [name]: "Enter only an integer" });
			return;
		}
		setForm({ ...form, [name]: value });
	};

	const closeDialog = () => {
		setForm(initialForm);
		setErrors({});
		handleClose();
	};

	return (
		<DialogWrapper open={open} handleClose={closeDialog}>
			<Grid container justifyContent='space-between'>
				<Typography
					variant={"h6"}
					color={"textPrimary"}
					style={{ marginBottom: 32 }}>
					Change parent
				</Typography>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Input
						label={`Parent ID`}
						variant='outlined'
						name={"newOwner"}
						value={form?.newOwner || ""}
						onChange={({ target: { value } }) =>
							/^[0-9]*$/.test(value) &&
							!(value.length > 1 && value[0] === "0" && value[1] !== ".") &&
							handleChange(value, "newOwner")
						}
						onFocus={() =>
							!form?.newOwner
								? setForm({
										...form,
										newOwner: "",
								  })
								: setForm({ ...form })
						}
						onBlur={() => !form?.newOwner && setForm({ ...form, newOwner: "" })}
						error={Boolean(errors?.newOwner)}
						helperText={errors?.newOwner}
					/>
				</Grid>
				<Grid item container justifyContent='center'>
					<Button
						color={"primary"}
						style={{ width: "max(50%, 50px)" }}
						onClick={handleUpdate}>
						Save
					</Button>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default ChangeParentDialog;
