import {
  SET_ACTIVE_FILTER,
  SET_ACTIVE_FILTER_PAGE,
  SET_ACTIVE_FILTER_SIZE,
  DELETE_ACTIVE_FILTER,
  CLEAR_ACTIVE_FILTERS,
  } from "../types";

export const setActiveFilter = payload => ({
  type: SET_ACTIVE_FILTER,
  payload
});

export const deleteActiveFilter = payload => ({
  type: DELETE_ACTIVE_FILTER,
  payload
});

export const clearActiveFilters = () => ({
  type: CLEAR_ACTIVE_FILTERS
});


export const setActiveFiltersPage = payload => ({
  type: SET_ACTIVE_FILTER_PAGE,
  payload
});

export const setActiveFiltersSize = payload => ({
  type: SET_ACTIVE_FILTER_SIZE,
  payload
});