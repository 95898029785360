import {
	GET_BALANCES_FAILURE,
	GET_BALANCES_STARTED,
	GET_BALANCES_SUCCESS,
	GET_KUNA_DEPOSITS_FAILURE,
	GET_KUNA_DEPOSITS_STARTED,
	GET_KUNA_DEPOSITS_SUCCESS,
	GET_KUNA_WITHDRAWAL_FAILURE,
	GET_KUNA_WITHDRAWAL_STARTED,
	GET_KUNA_WITHDRAWAL_SUCCESS,
	SET_KUNA_PARAMS,
	CLEAR_KUNA_DEPOSITS,
	CLEAR_KUNA_WITHDRAWAL,
	GET_KUNA_BALANCE,
	GET_USERS_BALANCES_BY_COIN_FAILURE,
	GET_USERS_BALANCES_BY_COIN_STARTED,
	GET_USERS_BALANCES_BY_COIN_SUCCESS,
} from "../types";
import api from "../api";
import { actionCreator } from "../utils";

export const getBalancesStarted = () => ({
	type: GET_BALANCES_STARTED,
});

export const getBalancesSuccess = payload => ({
	type: GET_BALANCES_SUCCESS,
	payload,
});

export const getBalancesFailure = error => ({
	type: GET_BALANCES_FAILURE,
	payload: {
		error,
	},
});

export const getBalancesBinance = () => {
	return async dispatch => {
		dispatch(getBalancesStarted());
		api.finances
			.getBalanceBinance()
			.then(res => dispatch(getBalancesSuccess(res.data)))
			.catch(error => dispatch(getBalancesFailure(error)));
	};
};

export const getBalancesKunaAction = actionCreator(GET_KUNA_BALANCE);

export const getBalancesKuna = () => {
	return async dispatch => {
		dispatch(getBalancesKunaAction.started());
		api.finances.getBalanceKuna
			.list()
			.then(res => dispatch(getBalancesKunaAction.done(res.data)))
			.catch(error => dispatch(getBalancesKunaAction.failed(error)));
	};
};

export const getKunaDepositsStarted = () => ({
	type: GET_KUNA_DEPOSITS_STARTED,
});

export const getKunaDepositsSuccess = payload => ({
	type: GET_KUNA_DEPOSITS_SUCCESS,
	payload,
});

export const getKunaDepositsFailure = error => ({
	type: GET_KUNA_DEPOSITS_FAILURE,
	payload: {
		error,
	},
});

export const getKunaDeposits = params => {
	return async dispatch => {
		dispatch(getKunaDepositsStarted());
		api.finances.getBalanceKuna
			.deposit(params)
			.then(res => dispatch(getKunaDepositsSuccess(res.data)))
			.catch(error => dispatch(getKunaDepositsFailure(error)));
	};
};

export const getKunaWithdrawalStarted = () => ({
	type: GET_KUNA_WITHDRAWAL_STARTED,
});

export const getKunaWithdrawalSuccess = payload => ({
	type: GET_KUNA_WITHDRAWAL_SUCCESS,
	payload,
});

export const getKunaWithdrawalFailure = error => ({
	type: GET_KUNA_WITHDRAWAL_FAILURE,
	payload: {
		error,
	},
});

export const getKunaWithdrawal = params => {
	return async dispatch => {
		dispatch(getKunaWithdrawalStarted());
		api.finances.getBalanceKuna
			.withdrawal(params)
			.then(res => dispatch(getKunaWithdrawalSuccess(res.data)))
			.catch(error => dispatch(getKunaWithdrawalFailure(error)));
	};
};

export const setKunaParams = payload => ({
	type: SET_KUNA_PARAMS,
	payload,
});

export const clearKunaDeposits = () => ({
	type: CLEAR_KUNA_DEPOSITS,
});

export const clearKunaWithdrawal = () => ({
	type: CLEAR_KUNA_WITHDRAWAL,
});

export const getUsersBalancesByCoinStarted = () => ({
	type: GET_USERS_BALANCES_BY_COIN_STARTED,
});

export const getUsersBalancesByCoinSuccess = payload => ({
	type: GET_USERS_BALANCES_BY_COIN_SUCCESS,
	payload,
});

export const getUsersBalancesByCoinFailure = error => ({
	type: GET_USERS_BALANCES_BY_COIN_FAILURE,
	payload: {
		error,
	},
});

export const getUsersBalancesByCoin = ticker => {
	return async dispatch => {
		dispatch(getUsersBalancesByCoinStarted());
		api.finances.usersBalances
			.getUsersBalancesByCoin(ticker)
			.then(res => dispatch(getUsersBalancesByCoinSuccess(res.data)))
			.catch(error => dispatch(getUsersBalancesByCoinFailure(error)));
	};
};
