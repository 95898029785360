import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Grid,
	Typography,
	MenuItem,
	TextareaAutosize,
	makeStyles,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import { SelectWithLabel } from "../../../../../theme/components/SelectWithLabel";
import { Input } from "../../../../../theme/components/Input";
import { Button } from "../../../../../theme/components/Button";
import DialogWrapper from "../../../../../theme/components/dialogs/DialogWrapper";
import { getCurrencies } from "../../../../../app/actions/walletsActions";
import {
	TYPES_FUNDS,
	TYPES_FUNDS_SELECT,
	RISK_LEVEL_CAPITAL,
} from "../../../../../utils/constants/funds";
import {
	clearFundStatus,
	createFloatingInvestmentFund,
	createStableInvestmentFund,
	updateFloatingInvestmentFund,
	updateStableInvestmentFund,
} from "../../../../../app/actions/fundsCapitalActions";
import { capitalValidator } from "../../../../../utils/customValidators/capitalValidator";

const createFormFields = [
	{ field: "name", type: "text", label: "Fund Name", isStable: true },
	{ field: "tokenName", type: "text", label: "Token Name", isStable: true },
	{
		field: "fundType",
		type: "select",
		label: "Type Fund",
		selectData: TYPES_FUNDS_SELECT,
		isStable: true,
	},
	{
		field: "assetName",
		type: "select",
		label: "Asset Name",
		selectData: "currencies",
		isStable: true,
	},
	{
		field: "riskLevel",
		type: "select",
		label: "Risk Level",
		selectData: RISK_LEVEL_CAPITAL,
		isStable: true,
	},
	{
		field: "maxBuyAmount",
		type: "number",
		label: "Max Buy Amount",
		isStable: true,
	},
	{
		field: "minBuyAmount",
		type: "number",
		label: "Min Buy Amount",
		isStable: true,
	},
	{
		field: "maxSellAmount",
		type: "number",
		label: "Max Sell Amount",
		isStable: true,
	},
	{
		field: "minSellAmount",
		type: "number",
		label: "Min Sell Amount",
		isStable: true,
	},
	{
		field: "userRewardPercent",
		type: "number",
		label: "User Reward Percent",
		isStable: true,
	},
	{
		field: "reservedBySystem",
		type: "number",
		label: "Reserved By System",
		isStable: false,
	},
	{
		field: "fundValuation",
		type: "number",
		label: "Fund Valuation",
		isStable: false,
	},
	{
		field: "apy",
		type: "number",
		label: "APY",
		isStable: true,
	},
	{
		field: "privateAccess",
		type: "checkbox",
		label: "Private Access",
		isStable: true,
	},
	{
		field: "description",
		type: "textarea",
		label: "Description",
		isStable: true,
	},
];
const editFormFields = [
	{
		field: "maxBuyAmount",
		type: "number",
		label: "Max Buy Amount",
		isStable: true,
	},
	{
		field: "minBuyAmount",
		type: "number",
		label: "Min Buy Amount",
		isStable: true,
	},
	{
		field: "maxSellAmount",
		type: "number",
		label: "Max Sell Amount",
		isStable: true,
	},
	{
		field: "minSellAmount",
		type: "number",
		label: "Min Sell Amount",
		isStable: true,
	},
	{
		field: "userRewardPercent",
		type: "number",
		label: "User Reward Percent",
		isStable: true,
	},
	{
		field: "fundValuation",
		type: "number",
		label: "Fund Valuation",
		isStable: false,
	},
	{
		field: "apy",
		type: "number",
		label: "APY",
		isStable: true,
	},
	{
		field: "privateAccess",
		type: "checkbox",
		label: "Private Access",
		isStable: true,
	},
	{
		field: "description",
		type: "textarea",
		label: "Description",
		isStable: true,
	},
];

const EditFundDialog = ({ open, handleClose, data }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { status } = useSelector(({ funds }) => funds);
	const { currencies } = useSelector(({ wallets }) => wallets);
	const [errors, setErrors] = useState({});
	const [state, setState] = useState(null);

	const [formFields, setFormFields] = useState(null);
	const updateFormFields = useCallback(() => {
		if (!state?.fundType && !data?.fundType) {
			return;
		}
		let updateFields = [];
		let updateForm = {};
		let fields = data ? editFormFields : createFormFields;
		if (
			state?.fundType === TYPES_FUNDS.STABLE ||
			data?.fundType === TYPES_FUNDS.STABLE
		) {
			fields.forEach((item) => {
				if (item.isStable) {
					updateFields = [...updateFields, item];
					updateForm[item.field] = state[item.field];
				}
			});
		} else {
			fields.forEach((item) => {
				updateFields = [...updateFields, item];
				updateForm[item?.field] = state[item?.field];
			});
		}
		setFormFields(updateFields);
		setState(updateForm);
		// eslint-disable-next-line
	}, [state?.fundType, data]);

	useEffect(() => {
		if (status) {
			closeDialog();
			dispatch(clearFundStatus());
		}
		// eslint-disable-next-line
	}, [status]);

	useEffect(() => {
		updateFormFields();
	}, [updateFormFields]);

	useEffect(() => {
		dispatch(getCurrencies());
		if (data) {
			createState(editFormFields);
		} else {
			setFormFields(createFormFields);
			createState(createFormFields);
		}
		// eslint-disable-next-line
	}, [open, data]);

	const createState = (fields) => {
		let form = {};
		fields?.forEach((item) => {
			form[item.field] =
				item.type === "text" ||
				item.type === "select" ||
				item.type === "textarea" ||
				item.type === "checkbox"
					? data
						? data[item.field]
						: ""
					: item.type === "number"
					? data
						? +data[item.field]
						: 0
					: false;
		});
		setState(form);
	};

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(state).forEach((key) => {
			errForm = {
				...errForm,
				[key]: capitalValidator(key, state[key]),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]: capitalValidator(name, value),
		});
	};

	const handleChange = ({ target: { name, value } }) => {
		handleInputValidation(name, value);
		updateFormFields(name, value);
		setState({ ...state, [name]: value });
	};

	const handleChangeCheckbox = ({ target: { name } }, v) => {
		handleInputValidation(name, v);
		updateFormFields(name, v);
		setState({ ...state, [name]: v });
	};

	const handleChangeTypeNumber = (name, value) => {
		handleInputValidation(name, value);
		setState({ ...state, [name]: value });
	};

	const handleSave = () => {
		if (!validateForm()) {
			return;
		}
		if (data) {
			let updateData = { ...state, id: data.id, name: data.name };
			delete updateData.fundType;
			data?.fundType === TYPES_FUNDS.STABLE
				? dispatch(updateStableInvestmentFund(updateData))
				: dispatch(updateFloatingInvestmentFund(updateData));
		} else {
			let updateData = { ...state };
			delete updateData.fundType;
			state?.fundType === TYPES_FUNDS.STABLE
				? dispatch(createStableInvestmentFund(updateData))
				: dispatch(createFloatingInvestmentFund(updateData));
		}
	};

	const closeDialog = () => {
		setState(createState());
		setErrors({});
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Grid container justifyContent='space-between'>
				<Typography
					variant={"h6"}
					color={"textPrimary"}
					style={{ marginBottom: 32 }}>
					{data ? `Edit fund - token ${data?.token}` : "Create fund"}
				</Typography>
			</Grid>
			<Grid container spacing={3}>
				{state &&
					formFields &&
					formFields?.map(({ field, label, type, selectData }) => (
						<Grid
							item
							xs={12}
							md={type === "textarea" ? 12 : formFields.length > 1 ? 6 : 12}
							key={field}>
							{type === "text" ? (
								<Input
									label={label}
									variant={"outlined"}
									type={type}
									name={field}
									value={state[field] || ""}
									error={errors[field]?.invalid}
									helperText={errors[field]?.errorMessage}
									onChange={handleChange}
								/>
							) : type === "number" ? (
								<Input
									label={label}
									variant='outlined'
									name={field}
									value={state[field] || ""}
									onChange={({ target: { value } }) =>
										/^[0-9]*\.?[0-9]*$/.test(value) &&
										!(
											value.length > 1 &&
											value[0] === "0" &&
											value[1] !== "."
										) &&
										handleChangeTypeNumber(field, value)
									}
									onFocus={() =>
										!state[field]
											? setState({
													...state,
													[field]: "",
											  })
											: setState({ ...state })
									}
									onBlur={() =>
										!state[field] && setState({ ...state, [field]: 0 })
									}
									error={errors[field]?.invalid}
									helperText={errors[field]?.errorMessage}
									InputProps={{
										autoComplete: "off",
									}}
								/>
							) : type === "textarea" ? (
								<Input
									className={classes.rootInput}
									fullWidth
									multiline
									label={label}
									variant={"outlined"}
									InputProps={{
										component: TextareaAutosize,
										minRows: 3,
										maxRows: 3,
									}}
									name={field}
									value={state[field] || ""}
									error={errors[field]?.invalid}
									helperText={errors[field]?.errorMessage}
									onChange={handleChange}
								/>
							) : type === "checkbox" ? (
								<FormControlLabel
									control={
										<Checkbox
											checked={Boolean(state[field])}
											color={"primary"}
											onChange={handleChangeCheckbox}
											name={field}
										/>
									}
									label={label}
								/>
							) : type === "select" && selectData === "currencies" ? (
								<SelectWithLabel
									label={label}
									value={state[field] || ""}
									name={field}
									error={errors[field]?.invalid}
									helperText={errors[field]?.errorMessage}
									onChange={({ target }) =>
										handleChangeTypeNumber(field, target.value)
									}>
									{!!currencies &&
										currencies?.map((i, index) => (
											<MenuItem key={index} value={i?.ticker}>
												<div style={{ display: "flex", alignItems: "center" }}>
													<img
														src={`https://cryptoneed.com/icons/${i?.ticker}.svg`}
														alt={i?.ticker}
														width={16}
														height={16}
														style={{ marginRight: 12 }}
														loading={"lazy"}
													/>
													<p style={{ margin: 0 }}>
														{i?.ticker?.toUpperCase()}
													</p>
												</div>
											</MenuItem>
										))}
								</SelectWithLabel>
							) : (
								<SelectWithLabel
									label={label}
									value={state[field] || ""}
									name={field}
									error={errors[field]?.invalid}
									helperText={errors[field]?.errorMessage}
									onChange={({ target }) =>
										handleChangeTypeNumber(field, target.value)
									}>
									{selectData?.map((i, index) => (
										<MenuItem key={i.label || index} value={i.value}>
											{i.label}
										</MenuItem>
									))}
								</SelectWithLabel>
							)}
						</Grid>
					))}
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)" }}
							onClick={handleSave}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default EditFundDialog;

const useStyles = makeStyles((theme) => ({
	rootInput: {
		"& .MuiFormHelperText-root": {
			marginTop: 96,
		},
	},
}));
