import { TextField, withStyles } from "@material-ui/core";

export const Input = withStyles(theme => ({
	root: {
		width: "100%",
		"& label, & label>.Mui-focused:not(.Mui-error)": {
			color: "#fff",
		},
		"& .MuiFormLabel-root.Mui-focused:not(.Mui-error)": {
			color: "#18A0FB",
		},

		"&.MuiInputAdornment-root > svg": {
			color: theme.palette.primary.light,
			fontSize: 16,
		},
		"& .MuiFormHelperText-root": {
			fontSize: 10,
			position: "absolute",
			marginTop: 58,
			fontWeight: 300,
			lineHeight: "100%",
		},

		"& .MuiFilledInput-root:not(.Mui-error)": {
			borderRadius: "0px",
			"&>input": {
				color: "#fff",
				fontSize: 14,
				lineHeight: 16,
				"&::placeholder": {
					opacity: 1,
				},
			},
			"& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
				borderRadius: 0,
				borderWidth: 1,
			},
			"&:not(.Mui-error) fieldset, &:not(.Mui-error) :hover fieldset, &:not(.Mui-error).Mui-focused fieldset":
				{
					// borderColor: "#454B54",
				},

			"&.MuiFilledInput-underline.Mui-disabled:before": {
				borderBottomStyle: "solid",
			},
			"& img": {
				marginRight: 8,
			},
		},

		"& .MuiOutlinedInput-root:not(.Mui-error)": {
			"&>input": {
				color: "#fff",
				borderRadius: 8,
				fontSize: 14,
				lineHeight: 16,
				background: "inherit",
				"&::placeholder": {
					opacity: 1,
					color: theme.palette.text.secondary,
				},
			},
			"& img": {
				marginRight: 8,
			},
			"& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
				borderRadius: 8,
				borderWidth: 1,
			},
			"&:not(.Mui-error) fieldset, &:not(.Mui-error) :hover fieldset, &:not(.Mui-error).Mui-focused fieldset":
				{
					borderColor: "#454B54",
				},
		},
	},
}))(TextField);
