import DialogWrapper from "./DialogWrapper";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "../Button";
const DeleteConfirmDialog = ({
	open,
	title,
	message,
	handleClose,
	btnOkText = "Ok",
	btnCancelText = "Cancel",
}) => {
	return (
		<DialogWrapper
			open={open}
			handleClose={() => handleClose({ accept: false })}>
			<Typography
				variant={"h6"}
				align={"center"}
				color={"textSecondary"}
				style={{ marginBottom: 24 }}>
				{title}
			</Typography>
			<Typography
				variant={"body1"}
				align={"left"}
				color={"textSecondary"}
				style={{ marginBottom: 24 }}>
				{message}
			</Typography>
			<Grid container justifyContent='space-between'>
				<Button
					color={"primary"}
					style={{ width: "max(25%, 50px)", marginTop: 12 }}
					onClick={() => handleClose({ accept: false })}>
					{btnCancelText}
				</Button>
				<Button
					color={"primary"}
					style={{
						width: "max(25%, 50px)",
						marginTop: 12,
						color: btnOkText === "Ok" ? "#60CF61" : "#E44A4A",
					}}
					onClick={() => handleClose({ accept: true })}>
					{btnOkText}
				</Button>
			</Grid>
		</DialogWrapper>
	);
};

export default DeleteConfirmDialog;
