import { Button as MButton, withStyles } from "@material-ui/core";

export const Button = withStyles(theme => ({
	root: {
		borderRadius: 8,
		color: "#fff",
		fontWeight: 700,
		textTransform: "none",
		minHeight: 48,
		fontSize: 16,
		[theme.breakpoints.down("xs")]: {
			minHeight: 24,
			fontSize: 14,
			"& svg": {
				display: "none",
			},
		},
		"&.MuiButton-outlined": {
			color: theme.palette.primary.light,
			borderColor: theme.palette.primary.light,
			[theme.breakpoints.down("xs")]: {
				fontSize: 14,
				padding: "5px 13px",
			},
		},
		"&.MuiButton-sizeSmall": {
			minHeight: 32,
		},
		"&.MuiButton-textPrimary:not(.Mui-disabled)": {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.primary.light,
		},
		"&.MuiButton-textSecondary:not(.Mui-disabled)": {
			backgroundColor: "rgba(255, 255, 255, 0.05)",
			color: theme.palette.text.secondary,
		},
		"&.MuiButton-textPrimary.Mui-disabled": {
			backgroundColor: "rgba(255, 255, 255, 0.05)",
		},
	},
}))(MButton);
