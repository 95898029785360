import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import clsx from "clsx";
import Table from "../../../../theme/components/Table/Table.jsx";
import { useSettingsStyles } from "../../../Settings/SettingsStyles.jsx";
import { getLiquidityProvidersOffers } from "../../../../app/actions/bankingActions.js";
import CopyWithAlert from "../../../../utils/CopyWithAlert.jsx";
import getStatusColor from "../../../../utils/functions/getStatusColor.js";
import { formatTime } from "../../../../utils/functions/date.js";
import { clearActiveFilters, setActiveFiltersPage, setActiveFiltersSize } from "../../../../app/actions/filterActions.js";

const columns = [
  { field: "id", headerName: "ID" },
  { field: "userId", headerName: "User ID" },
  { field: "providerId", headerName: "Provider ID" },
  { field: "userCard", headerName: "User Card" },
  { field: "requestedAssetAmount", headerName: "Asset Amount" },
  { field: "liquidityProviderReward", headerName: "Reward Amount" },
  { field: "price", headerName: "Price" },
  { field: "status", headerName: "Status" },
  { field: "terminationTime", headerName: "Termination Time" },
  { field: "completeTime", headerName: "Complete Time" },
  { field: "transactionId", headerName: "Transaction ID" },
];

const OfferStatusLabels = {
  'NEW': 'NEW',
  'COMPLETED': 'COMPLETED',
  'WAITING_FOR_LIQUIDITY_PROVIDER': 'WAITING PROVIDER',
  'EXPIRED': 'EXPIRED',
}

const LiquidityProvidersOffersList = () => {
  const classes = useSettingsStyles();
  const dispatch = useDispatch();
  const { content, totalElements } = useSelector(({ banking }) => banking.liquidityProvidersOffers);
  const activeFilters = useSelector(({ filter }) => filter.activeFilters);

  useEffect(() => {
    let filteringParams = {};
    if (activeFilters.length !== 0) {
      activeFilters.forEach(item => {
        filteringParams = { ...filteringParams, [item.field]: item.value };
      });
    }
    dispatch(getLiquidityProvidersOffers(filteringParams));
  }, [activeFilters, dispatch]);

  const handleChangePage = (_, newPage) => {
    dispatch(setActiveFiltersPage(newPage));
  };

  const handleChangeSize = ({ target }) => {
    dispatch(setActiveFiltersSize(target.value));
  };

  useEffect(() => {
    return () => {
      dispatch(clearActiveFilters());
    };
  }, [dispatch]);

  return (
    <div style={{ maxWidth: "100%" }}>
      <Table>
        <thead>
          <tr>
            {columns.map(({ field, headerName }) => (
              <th
                key={field}
                className={clsx(classes.th, classes.paper)}
                align={"left"}>
                {headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ position: "relative" }}>
          {content.length > 0 ? (
            content.map((row, index) => (
              <TableRow
                key={row?.id || index}
                rowData={row}
              />
            ))
          ) : (
            <tr>
              <td colSpan={13} align={"center"} height={100}>
                The list is empty...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 50]}
        component='div'
        count={totalElements || 0}
        rowsPerPage={activeFilters?.find(x => x.field === "size").value}
        page={activeFilters?.find(x => x.field === "page").value}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeSize}
      />
    </div >
  );
};

export default LiquidityProvidersOffersList;

function TableRow({ rowData, handleDelete, handleEdit }) {
  const classes = useSettingsStyles();

  return (
    <>
      <tr className={classes.hover}>
        <CopyWithAlert
          text={`${rowData?.id}`}
          label={"ID"}>
          <td data='hover'>{rowData?.id}</td>
        </CopyWithAlert>
        <CopyWithAlert
          text={rowData?.userId + "" || ""}
          label={"User ID"}>
          <td data='hover'>{rowData?.userId}</td>
        </CopyWithAlert>
        <CopyWithAlert
          text={rowData?.providerId + "" || ""}
          label={"Provider ID"}>
          <td data='hover'>{rowData?.providerId || '--'}</td>
        </CopyWithAlert>
        <CopyWithAlert
          text={rowData?.userCard + "" || ""}
          label={"User Card"}>
          <td data='hover'>{rowData?.userCard}</td>
        </CopyWithAlert>
        <CopyWithAlert
          text={`${Number(rowData?.requestedAssetAmount)} ${rowData?.requestedAsset}`}
          label={"Asset Amount"}>
          <td data='hover'>{`${Number(rowData?.requestedAssetAmount)} ${rowData?.requestedAsset}`}</td>
        </CopyWithAlert>
        <CopyWithAlert
          text={`${Number(rowData?.liquidityProviderReward)} ${rowData?.liquidityProviderRewardCurrency?.toUpperCase?.()}`}
          label={"Reward Amount"}>
          <td data='hover'>{`${Number(rowData?.liquidityProviderReward)} ${rowData?.liquidityProviderRewardCurrency?.toUpperCase?.()}`}</td>
        </CopyWithAlert>
        <CopyWithAlert
          text={Number(rowData?.price)}
          label={"Price"}>
          <td data='hover'>{Number(rowData?.price)}</td>
        </CopyWithAlert>
        <CopyWithAlert
          text={rowData?.price}
          label={"Status"}>
          <td style={{
            color: rowData?.status && getStatusColor(rowData?.status),
          }} data='hover'>{OfferStatusLabels?.[rowData?.status] || rowData?.status}</td>
        </CopyWithAlert>
        <CopyWithAlert
          text={formatTime(rowData?.terminationTime)}
          label={"Termination Time"}>
          <td data='hover'>{formatTime(rowData?.terminationTime) || '--'}</td>
        </CopyWithAlert>
        <CopyWithAlert
          text={formatTime(rowData?.completeTime)}
          label={"Complete Time"}>
          <td data='hover'>{formatTime(rowData?.completeTime) || '--'}</td>
        </CopyWithAlert>
        <CopyWithAlert
          text={rowData?.transactionId}
          label={"Transaction ID"}>
          <td data='hover'>{rowData?.transactionId}</td>
        </CopyWithAlert>
      </tr>
    </>
  );
}
