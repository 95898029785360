import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, MenuItem, makeStyles } from "@material-ui/core";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import clsx from "clsx";
import { SelectWithLabel } from "../../../theme/components/SelectWithLabel";
import { Input } from "../../../theme/components/Input";
import { Button } from "../../../theme/components/Button";
import DialogWrapper from "../../../theme/components/dialogs/DialogWrapper";
import {
	createSettingWithdraw,
	updateSettingWithdraw,
	updateSettingDeposit,
	createSettingDeposit,
	clearStatusSettings,
} from "../../../app/actions/settingsActions";
import { getCurrencies } from "../../../app/actions/walletsActions";
import { numberDigitsAfterDotValidator } from "../../../utils/customValidators/validators";
import { exchangeSettingsValidator } from "../../../utils/customValidators/exchangeSettingsValidator";

const systemFeeWarning = ["uaht"];

const withdrawalFormFields = [
	{ field: "ticker", type: "select", label: "Ticker" },
	{ field: "cnSlow", type: "number", label: "Commission (Slow)" },
	{ field: "cnMedium", type: "number", label: "Commission (Medium)" },
	{
		field: "cnFast",
		type: "number",
		label: "Commission (Fast)'",
	},
	{ field: "system", type: "number", label: "System fee" },
	{
		field: "maxWithdraw",
		type: "number",
		label: "Max amount",
	},
	{ field: "minWithdraw", type: "number", label: "Min amount" },
];

const depositFormFields = [
	{ field: "ticker", type: "select", label: "Ticker" },
	{ field: "minDeposit", type: "number", label: "Min amount" },
];

const EditTransactionsSettingsDialog = ({
	open,
	handleClose,
	data,
	params,
	transactionsType,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const currencies = useSelector(({ wallets }) => wallets.currencies);
	const { status, error } = useSelector(({ settings }) => settings);
	const [errors, setErrors] = useState({});
	const [state, setState] = useState(null);
	const [formFields, setFormFields] = useState(null);
	const createState = useCallback(() => {
		let form = {};
		formFields?.forEach(item => {
			form[item.field] =
				item.type === "select" ? "" : item.type === "number" ? 0 : false;
		});
		return form;
	}, [formFields]);

	useEffect(() => {
		dispatch(getCurrencies({ page: 0, size: 100 }));
	}, [dispatch]);

	useEffect(() => {
		setFormFields(
			transactionsType === "withdrawal"
				? withdrawalFormFields
				: depositFormFields
		);
	}, [transactionsType]);

	useEffect(() => {
		data ? setState(data) : setState(createState());
	}, [data, createState]);

	useEffect(() => {
		if (status) {
			closeDialog();
			dispatch(clearStatusSettings());
		}
		// eslint-disable-next-line
	}, [status]);

	useEffect(() => {
		if (error) {
			let arr = error?.response.data?.title?.split(" ");
			if (!arr) {
				return;
			}
			let numberDigits = Number(arr[arr.length - 1]) || 8;
			validateForm(numberDigits);
		}
		// eslint-disable-next-line
	}, [error]);

	const validateForm = (numberDigits = null) => {
		let valid = true;
		let errForm = {};
		Object.keys(state).forEach(key => {
			errForm = {
				...errForm,
				[key]: !!numberDigits
					? numberDigitsAfterDotValidator(state[key], numberDigits)
					: exchangeSettingsValidator(key, state[key], findType(key), state),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const findType = key => {
		return formFields?.find(x => x.field === key)?.type || "";
	};

	const handleInputValidation = (name, value, type) => {
		setErrors({
			...errors,
			[name]: exchangeSettingsValidator(name, value, type, state),
		});
	};

	const handleChange = ({ target: { name, value, type } }) => {
		handleInputValidation(name, value, type);
		setState({ ...state, [name]: value });
	};

	const handleUpdate = () => {
		if (!validateForm()) {
			return;
		}
		if (data) {
			let updateData = { ...state };
			delete updateData?.ticker;
			delete updateData?.update;
			transactionsType === "withdrawal"
				? dispatch(updateSettingWithdraw(updateData, params))
				: dispatch(updateSettingDeposit(updateData, params));
		} else {
			transactionsType === "withdrawal"
				? dispatch(createSettingWithdraw(state, params))
				: dispatch(createSettingDeposit(state, params));
		}
	};

	const closeDialog = () => {
		setState(createState());
		setErrors({});
		handleClose();
	};
	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Grid container justifyContent='space-between'>
				<Typography
					variant={"h6"}
					color={"textPrimary"}
					style={{ marginBottom: 32 }}>
					{data
						? `Edit ${data?.ticker} ${
								transactionsType === "withdrawal" ? "withdraw" : "deposit"
						  }  settings`
						: "Create"}
				</Typography>
				{!!data?.ticker && systemFeeWarning.includes(data?.ticker) && (
					<Typography variant={"subtitle2"}>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								color: "#FF8C00",
							}}>
							<ReportProblemOutlinedIcon
								style={{ color: "#FF8C00", marginRight: 8, fontSize: 24 }}
							/>
							Necessary to fill this field
						</div>
					</Typography>
				)}
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<SelectWithLabel
						label={"Ticker"}
						value={state?.ticker || ""}
						onChange={handleChange}
						name={"ticker"}
						error={errors?.ticker?.invalid}
						helperText={errors?.ticker?.errorMessage}
						disabled={Boolean(data)}>
						{currencies.map((i, index) => (
							<MenuItem key={index} value={i?.ticker}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<img
										src={`https://cryptoneed.com/icons/${i?.ticker}.svg`}
										alt={i?.ticker}
										width={16}
										height={16}
										style={{ marginRight: 12 }}
										loading={"lazy"}
									/>
									<p style={{ margin: 0 }}>{i?.ticker?.toUpperCase()}</p>
								</div>
							</MenuItem>
						))}
					</SelectWithLabel>
				</Grid>
				{state &&
					formFields &&
					formFields?.map(
						({ field, label, type }) =>
							type === "number" && (
								<Grid item xs={12} md={6} key={field}>
									<Input
										label={
											transactionsType === "withdrawal" &&
											field === "system" &&
											state?.ticker !== "usdt-trc20"
												? `${label}, %`
												: label
										}
										variant={"outlined"}
										type={type}
										name={field}
										value={state[field] || ""}
										error={errors[field]?.invalid}
										helperText={errors[field]?.errorMessage}
										onChange={handleChange}
										className={clsx({
											[classes.inputWarning]:
												!!data?.ticker &&
												systemFeeWarning.includes(data?.ticker) &&
												field === "system",
										})}
									/>
								</Grid>
							)
					)}
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)" }}
							onClick={handleUpdate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default EditTransactionsSettingsDialog;

const useStyles = makeStyles(theme => ({
	inputWarning: {
		"& .MuiOutlinedInput-root:not(.Mui-error)": {
			"&:not(.Mui-error) fieldset, &:not(.Mui-error) :hover fieldset, &:not(.Mui-error).Mui-focused fieldset":
				{
					borderColor: "#FF8C00",
				},
		},
	},
	icon: {
		color: "#FF8C00",
		marginRight: 8,
		fontSize: 24,
	},
}));
