import {
	GET_SYNC_LIST_SETTINGS,
	CREATE_SYNC_ITEM_SETTINGS,
	EDIT_SYNC_ITEM_SETTINGS,
	GET_SYNC_ITEM_SETTINGS,
	GET_SYNC_STATISTICS,
	GET_SYSTEM_SYNC_HISTORY,
	GET_USER_SYNC_HISTORY,
	GET_WITHDRAWAL_HISTORY,
	GET_BINANCE_ORDER_HISTORY,
	GET_KUNA_ORDER_HISTORY,
	CLEAR_SYNC_ERROR,
	CLEAR_SYNC_HISTORY,
	CLEAR_SYNC_STATUS,
} from "../types";
import api from "../api";
import { enqueueSnackbar } from "./notistackActions";
import { Message } from "../../utils/enum";
import { actionCreator } from "../utils";

// GET_KUNA_ORDER_HISTORY
export const getKunaOrderHistoryAction = actionCreator(GET_KUNA_ORDER_HISTORY);

export const getKunaOrderHistory = params => {
	return async dispatch => {
		dispatch(getKunaOrderHistoryAction.started());
		api.synchronization.orderHistory
			.getKunaOrderHistory(params)
			.then(res => dispatch(getKunaOrderHistoryAction.done(res.data)))
			.catch(error => dispatch(getKunaOrderHistoryAction.failed(error)));
	};
};

// GET_BINANCE_ORDER_HISTORY
export const getBinanceOrderHistoryAction = actionCreator(
	GET_BINANCE_ORDER_HISTORY
);

export const getBinanceOrderHistory = params => {
	return async dispatch => {
		dispatch(getBinanceOrderHistoryAction.started());
		api.synchronization.orderHistory
			.getBinanceOrderHistory(params)
			.then(res => dispatch(getBinanceOrderHistoryAction.done(res.data)))
			.catch(error => dispatch(getBinanceOrderHistoryAction.failed(error)));
	};
};

// GET_WITHDRAWAL_HISTORY
export const getWithdrawalHistoryAction = actionCreator(GET_WITHDRAWAL_HISTORY);

export const getWithdrawalHistory = params => {
	return async dispatch => {
		dispatch(getWithdrawalHistoryAction.started());
		api.synchronization
			.getWithdrawalHistory(params)
			.then(res => dispatch(getWithdrawalHistoryAction.done(res.data)))
			.catch(error => dispatch(getWithdrawalHistoryAction.failed(error)));
	};
};

// GET_USER_SYNC_HISTORY
export const getUserSyncHistoryAction = actionCreator(GET_USER_SYNC_HISTORY);

export const getUserSyncHistory = params => {
	return async dispatch => {
		dispatch(getUserSyncHistoryAction.started());
		api.synchronization.syncHistory
			.getUserHistory(params)
			.then(res => dispatch(getUserSyncHistoryAction.done(res.data)))
			.catch(error => dispatch(getUserSyncHistoryAction.failed(error)));
	};
};

// GET_SYSTEM_SYNC_HISTORY
export const getSystemSyncHistoryAction = actionCreator(
	GET_SYSTEM_SYNC_HISTORY
);

export const getSystemSyncHistory = params => {
	return async dispatch => {
		dispatch(getSystemSyncHistoryAction.started());
		api.synchronization.syncHistory
			.getSystemHistory(params)
			.then(res => dispatch(getSystemSyncHistoryAction.done(res.data)))
			.catch(error => dispatch(getSystemSyncHistoryAction.failed(error)));
	};
};

// GET_SYNC_STATISTICS
export const getSyncStatisticsAction = actionCreator(GET_SYNC_STATISTICS);

export const getSyncStatistics = params => {
	return async dispatch => {
		dispatch(getSyncStatisticsAction.started());
		api.synchronization
			.getSyncStatistics(params)
			.then(res => dispatch(getSyncStatisticsAction.done(res.data)))
			.catch(error => dispatch(getSyncStatisticsAction.failed(error)));
	};
};

//  SYNCHRONIZATION SETTINGS

export const getSettingsSyncListAction = actionCreator(GET_SYNC_LIST_SETTINGS);

export const getSettingsSyncList = params => {
	return async dispatch => {
		dispatch(getSettingsSyncListAction.started());
		api.synchronization.syncSettings
			.getList(params)
			.then(res => dispatch(getSettingsSyncListAction.done(res.data)))
			.catch(error => dispatch(getSettingsSyncListAction.failed(error)));
	};
};

export const createSettingsSyncItemAction = actionCreator(
	CREATE_SYNC_ITEM_SETTINGS
);

export const createSettingsSyncItem = (data, params) => {
	return async dispatch => {
		dispatch(createSettingsSyncItemAction.started());
		api.synchronization.syncSettings
			.create(data)
			.then(() => {
				dispatch(createSettingsSyncItemAction.done());
				dispatch(getSettingsSyncList());
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(createSettingsSyncItemAction.failed(error)));
	};
};

export const updateSettingsSyncItemAction = actionCreator(
	EDIT_SYNC_ITEM_SETTINGS
);

export const updateSettingsSyncItem = (data, params) => {
	return async dispatch => {
		dispatch(updateSettingsSyncItemAction.started());
		api.synchronization.syncSettings
			.edit(data)
			.then(res => {
				dispatch(updateSettingsSyncItemAction.done(res.data));
				dispatch(
					enqueueSnackbar({
						message: Message.DataChanged,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(updateSettingsSyncItemAction.failed(error)));
	};
};

export const getSettingsSyncItemAction = actionCreator(GET_SYNC_ITEM_SETTINGS);

export const getSettingsSyncItem = params => {
	return async dispatch => {
		dispatch(getSettingsSyncItemAction.started());
		api.synchronization.syncSettings
			.getItemById(params)
			.then(res => dispatch(getSettingsSyncItemAction.done(res.data)))
			.catch(error => dispatch(getSettingsSyncItemAction.failed(error)));
	};
};

export const clearSyncHistory = () => ({
	type: CLEAR_SYNC_HISTORY,
});

export const clearSyncStatus = () => ({
	type: CLEAR_SYNC_STATUS,
});

export const clearSyncError = () => ({
	type: CLEAR_SYNC_ERROR,
});
