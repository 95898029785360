import { RISK_LEVEL_CAPITAL } from "./funds";

export const BINANCE_TRANSFERS_HISTORY_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "Id",
		placeholder: "Id",
	},
	{
		field: "investOperationId",
		type: "text",
		title: "InvestOperation Id",
		placeholder: "InvestOperation Id",
	},
	{
		field: "indexId",
		type: "text",
		title: "Index Id",
		placeholder: "Index Id",
	},
	{
		field: "withdrawalId",
		type: "text",
		title: "Withdrawal Id",
		placeholder: "Withdrawal Id",
	},
	{
		field: "currency",
		type: "text",
		title: "Currency",
		placeholder: "Currency",
	},
	{
		field: "depositAddress",
		type: "text",
		title: "Deposit address",
		placeholder: "Deposit address",
	},
	{ field: "status", type: "text", title: "Status", placeholder: "Status" },

	{
		field: "timeCreatedFrom",
		type: "date",
		title: "Created date from",
		placeholder: "Created date from",
	},
	{
		field: "timeCreatedTo",
		type: "date",
		title: "Created date to",
		placeholder: "Created date to",
	},
	{
		field: "timeCompletedFrom",
		type: "date",
		title: "Completed date from",
		placeholder: "Completed date from",
	},
	{
		field: "timeCompletedTo",
		type: "date",
		title: "Completed date to",
		placeholder: "Completed date to",
	},
];

export const CREATE_INDEX_FORM_FIELDS = [
	{
		field: "name",
		type: "text",
		label: "Index name",
	},
	{ field: "tokenName", type: "text", label: "Token Name" },
	{
		field: "availableTokens",
		type: "number",
		label: "Available tokens",
	},
	{
		field: "riskLevel",
		type: "select",
		label: "Risk Level",
		selectData: RISK_LEVEL_CAPITAL,
	},
	{
		field: "maxBuyAmount",
		type: "number",
		label: "Max Buy Amount",
	},
	{
		field: "minBuyAmount",
		type: "number",
		label: "Min Buy Amount",
	},
	{
		field: "maxSellAmount",
		type: "number",
		label: "Max Sell Amount",
	},
	{
		field: "minSellAmount",
		type: "number",
		label: "Min Sell Amount",
	},
	{
		field: "reservedBySystem",
		type: "number",
		label: "Reserved By System",
	},
	{
		field: "buyCommissionPercent",
		type: "number",
		label: "Buy commission percent",
	},
	{
		field: "sellCommissionPercent",
		type: "number",
		label: "Sell commission percent",
	},
	{
		field: "binanceApiKey",
		type: "text",
		label: "Binance Api Key",
	},
	{
		field: "binanceApiSecret",
		type: "text",
		label: "Binance Api Secret",
	},
	{
		field: "description",
		type: "textarea",
		label: "Description",
	},
];
export const EDIT_INDEX_FORM_FIELDS = [
	{
		field: "name",
		type: "text",
		label: "Index name",
	},
	{
		field: "riskLevel",
		type: "select",
		label: "Risk Level",
		selectData: RISK_LEVEL_CAPITAL,
	},
	{
		field: "availableTokens",
		type: "number",
		label: "Available tokens",
	},
	{
		field: "reservedBySystem",
		type: "number",
		label: "Reserved By System",
	},
	{
		field: "maxBuyAmount",
		type: "number",
		label: "Max Buy Amount",
	},
	{
		field: "minBuyAmount",
		type: "number",
		label: "Min Buy Amount",
	},
	{
		field: "maxSellAmount",
		type: "number",
		label: "Max Sell Amount",
	},
	{
		field: "minSellAmount",
		type: "number",
		label: "Min Sell Amount",
	},
	{
		field: "buyCommissionPercent",
		type: "number",
		label: "Buy commission percent",
	},
	{
		field: "sellCommissionPercent",
		type: "number",
		label: "Sell commission percent",
	},
	{
		field: "binanceApiKey",
		type: "text",
		label: "Binance Api Key",
	},
	{
		field: "binanceApiSecret",
		type: "text",
		label: "Binance Api Secret",
	},
	{
		field: "description",
		type: "textarea",
		label: "Description",
	},
];
