import {
	GET_SYNC_STATISTICS,
	GET_SYSTEM_SYNC_HISTORY,
	GET_USER_SYNC_HISTORY,
	GET_BINANCE_ORDER_HISTORY,
	GET_KUNA_ORDER_HISTORY,
	GET_WITHDRAWAL_HISTORY,
	CLEAR_SYNC_ERROR,
	CLEAR_SYNC_HISTORY,
	CLEAR_SYNC_STATUS,
	GET_SYNC_LIST_SETTINGS,
	GET_SYNC_ITEM_SETTINGS,
	CREATE_SYNC_ITEM_SETTINGS,
	EDIT_SYNC_ITEM_SETTINGS,
} from "../types";

export default function synchronization(
	state = {
		sync: {
			content: [],
			totalElements: 0,
			first: false,
			last: false,
		},
		autoSyncBalance: {
			data: [],
			totalItems: 0,
			first: false,
			last: false,
		},
		status: false,
		error: null,
	},
	action
) {
	switch (action.type) {
		case GET_KUNA_ORDER_HISTORY.STARTED:
		case GET_BINANCE_ORDER_HISTORY.STARTED:
		case GET_USER_SYNC_HISTORY.STARTED:
		case GET_SYSTEM_SYNC_HISTORY.STARTED:
		case GET_SYNC_STATISTICS.STARTED:
		case GET_WITHDRAWAL_HISTORY.STARTED:
			return { ...state, status: false, error: null };

		case GET_KUNA_ORDER_HISTORY.FAILURE:
		case GET_BINANCE_ORDER_HISTORY.FAILURE:
		case GET_USER_SYNC_HISTORY.FAILURE:
		case GET_SYSTEM_SYNC_HISTORY.FAILURE:
		case GET_SYNC_STATISTICS.FAILURE:
		case GET_SYNC_LIST_SETTINGS.FAILURE:
		case GET_SYNC_ITEM_SETTINGS.FAILURE:
		case EDIT_SYNC_ITEM_SETTINGS.FAILURE:
		case CREATE_SYNC_ITEM_SETTINGS.FAILURE:
		case GET_WITHDRAWAL_HISTORY.FAILURE:
			return { ...state, status: false, error: action.payload.error };
		case GET_WITHDRAWAL_HISTORY.SUCCESS:
		case GET_SYNC_STATISTICS.SUCCESS:
		case GET_USER_SYNC_HISTORY.SUCCESS:
		case GET_SYSTEM_SYNC_HISTORY.SUCCESS:
		case GET_KUNA_ORDER_HISTORY.SUCCESS:
		case GET_BINANCE_ORDER_HISTORY.SUCCESS:
			return {
				...state,
				sync: action.payload,
			};
		case GET_SYNC_LIST_SETTINGS.SUCCESS:
			return { ...state, autoSyncBalance: action.payload };

		case EDIT_SYNC_ITEM_SETTINGS.SUCCESS:
			return {
				...state,
				autoSyncBalance: {
					...state.autoSyncBalance,
					data: state.autoSyncBalance.data.map(i =>
						i.id === action.payload.id ? action.payload : i
					),
				},
				status: true,
			};

		case CREATE_SYNC_ITEM_SETTINGS.SUCCESS:
			return {
				...state,
				status: true,
			};
		case CLEAR_SYNC_HISTORY:
			return {
				...state,
				history: [],
				error: null,
				status: false,
			};

		case CLEAR_SYNC_STATUS:
			return {
				...state,
				status: false,
			};
		case CLEAR_SYNC_ERROR:
			return {
				...state,
				error: null,
				status: false,
			};
		default:
			return state;
	}
}
