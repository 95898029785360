import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { Input } from "../../../../../theme/components/Input";
import { Button } from "../../../../../theme/components/Button";
import DialogWrapper from "../../../../../theme/components/dialogs/DialogWrapper";
import {
	clearReferralsStatus,
	updateDefaultReferralSettings,
} from "../../../../../app/actions/referralsActions";
import { requiredValidator } from "../../../../../utils/customValidators/validators";

const initialForm = {
	id: "",
	parentRewardPercent: "",
	grandParentRewardPercent: "",
};

const EditDefaultSettingsDialog = ({ open, handleClose, data = null }) => {
	const dispatch = useDispatch();
	const [errors, setErrors] = useState({});
	const [form, setForm] = useState(initialForm);
	const { status } = useSelector(({ referrals }) => referrals);

	useEffect(() => {
		if (!data) {
			return;
		}
		setForm({
			id: data.id,
			parentRewardPercent: +data.parentRewardPercent,
			grandParentRewardPercent: +data.grandParentRewardPercent,
		});
	}, [data]);

	useEffect(() => {
		if (status) {
			closeDialog();
			dispatch(clearReferralsStatus());
		}
		// eslint-disable-next-line
	}, [dispatch, status]);

	const handleUpdate = () => {
		if (!validateForm()) {
			return;
		}
		dispatch(updateDefaultReferralSettings(form));
	};

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach((key) => {
			errForm = {
				...errForm,
				[key]: requiredValidator(undefined, form[key]),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]: requiredValidator(undefined, value),
		});
	};
	const handleChange = (value, name) => {
		handleInputValidation(name, value);
		setForm({ ...form, [name]: value });
	};

	const closeDialog = () => {
		setForm(initialForm);
		setErrors({});
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Grid container justifyContent='space-between'>
				<Typography
					variant={"h6"}
					color={"textPrimary"}
					style={{ marginBottom: 32 }}>
					Change default setting
				</Typography>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Input
						label={`Parent Reward Percent`}
						variant='outlined'
						name={"parentRewardPercent"}
						value={form?.parentRewardPercent || ""}
						onChange={({ target: { value } }) =>
							/^[0-9]*\.?[0-9]*$/.test(value) &&
							!(value.length > 1 && value[0] === "0" && value[1] !== ".") &&
							handleChange(value, "parentRewardPercent")
						}
						onFocus={() =>
							!form?.parentRewardPercent
								? setForm({
										...form,
										parentRewardPercent: "",
								  })
								: setForm({ ...form })
						}
						onBlur={() =>
							!form?.parentRewardPercent &&
							setForm({ ...form, parentRewardPercent: "" })
						}
						error={errors?.parentRewardPercent?.invalid}
						helperText={errors?.parentRewardPercent?.errorMessage}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Input
						label={`Grand Parent Reward Percent`}
						variant='outlined'
						name={"grandParentRewardPercent"}
						value={form?.grandParentRewardPercent || ""}
						onChange={({ target: { value } }) =>
							/^[0-9]*\.?[0-9]*$/.test(value) &&
							!(value.length > 1 && value[0] === "0" && value[1] !== ".") &&
							handleChange(value, "grandParentRewardPercent")
						}
						onFocus={() =>
							!form?.grandParentRewardPercent
								? setForm({
										...form,
										grandParentRewardPercent: "",
								  })
								: setForm({ ...form })
						}
						onBlur={() =>
							!form?.grandParentRewardPercent &&
							setForm({ ...form, grandParentRewardPercent: "" })
						}
						error={errors?.grandParentRewardPercent?.invalid}
						helperText={errors?.grandParentRewardPercent?.errorMessage}
					/>
				</Grid>

				<Grid item container justifyContent='center'>
					<Button
						color={"primary"}
						style={{ width: "max(50%, 50px)" }}
						onClick={handleUpdate}>
						Save
					</Button>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default EditDefaultSettingsDialog;
