import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import PageTitle from "../../theme/components/PageTitle";

import {
	CLAIM_HISTORY_FILTER_FIELDS,
	REFERRALS_FILTER_FIELDS,
	REFERRAL_REWARDS_FILTER_FIELDS,
} from "../../utils/constants/referrals.js";
import ClaimHistory from "./components/ClaimHistory/ClaimHistory";
import Rewards from "./components/Rewards/Rewards";
import Referrals from "./components/Referrals/Referrals";
import ReferralsSettings from "./components/ReferralsSettings/ReferralsSettings";

const tabs = {
	claimHistory: "Claim history",
	rewards: "Rewards",
	referrals: "Referrals",
	settings: "Settings",
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}>
			{value === index && <Box style={{ paddingTop: 24 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const ReferralProgram = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(0);
	const [filterFields, setFilterFields] = useState(null);

	useEffect(() => {
		switch (activeTab) {
			case 0:
				setFilterFields(CLAIM_HISTORY_FILTER_FIELDS);
				return;
			case 1:
				setFilterFields(REFERRAL_REWARDS_FILTER_FIELDS);
				return;
			case 2:
				setFilterFields(REFERRALS_FILTER_FIELDS);
				return;
			default:
				setFilterFields(null);
		}
	}, [dispatch, activeTab]);

	const handleChangeTabs = (_, newTab) => {
		setActiveTab(newTab);
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<PageTitle
				title={"Referral program"}
				style={{ height: 100 }}
				filterFields={filterFields}
			/>
			<Tabs
				value={activeTab}
				onChange={handleChangeTabs}
				aria-label='simple tabs example'
				indicatorColor='primary'>
				<Tab label={tabs.claimHistory} {...a11yProps(0)} />
				<Tab label={tabs.rewards} {...a11yProps(1)} />
				<Tab label={tabs.referrals} {...a11yProps(2)} />
				<Tab label={tabs.settings} {...a11yProps(2)} />
			</Tabs>
			<TabPanel value={activeTab} index={0}>
				<ClaimHistory />
			</TabPanel>
			<TabPanel value={activeTab} index={1}>
				<Rewards />
			</TabPanel>
			<TabPanel value={activeTab} index={2}>
				<Referrals />
			</TabPanel>
			<TabPanel value={activeTab} index={3}>
				<ReferralsSettings />
			</TabPanel>
		</div>
	);
};

export default ReferralProgram;
