import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../theme/components/PageTitle";
import { clearExchange } from "../../../app/actions/exchangeActions";
import { getInternalExchange } from "../../../app/actions/exchangeActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../app/actions/filterActions";

import { TablePagination } from "@material-ui/core";

import moment from "moment";
import Table from "../../../theme/components/Table/Table";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import getStatusColor from "../../../utils/functions/getStatusColor";

const internalExchangeFilterFields = [
	{
		field: "id",
		type: "number",
		title: "Operation ID",
		placeholder: "Operation ID",
	},
	{
		field: "assetFrom",
		type: "text",
		title: "Asset From",
		placeholder: "Asset From",
	},
	{
		field: "assetTo",
		type: "text",
		title: "Asset To",
		placeholder: "Asset To",
	},
	{
		field: "amountMore",
		type: "number",
		title: "Amount More",
		placeholder: "Amount More",
	},
	{
		field: "amountLess",
		type: "number",
		title: "Amount Less",
		placeholder: "Amount Less",
	},
	{
		field: "totalMore",
		type: "number",
		title: "Total More",
		placeholder: "Total More",
	},
	{
		field: "totalLess",
		type: "number",
		title: "Total Less",
		placeholder: "Total Less",
	},
	{
		field: "dateFrom",
		type: "date",
		title: "Create date from",
		placeholder: "Create date from",
	},
	{
		field: "dateTo",
		type: "date",
		title: "Create date to",
		placeholder: "Create date to",
	},
	{ field: "status", type: "text", title: "Status", placeholder: "Status" },
	{ field: "uid", type: "number", title: "User ID", placeholder: "User ID" },
];

const columns = [
	{ key: "id", label: "Id" },
	{ key: "createDate", label: "Created" },
	{ key: "uid", label: "User ID" },
	{ key: "assetFrom", label: "Asset From" },
	{ key: "assetTo", label: "Asset To" },
	{ key: "amount", label: "Amount" },
	{ key: "price", label: "Price" },
	{ key: "total", label: "Total" },
	{ key: "status", label: "Status" },
];

const InternalExchange = () => {
	const dispatch = useDispatch();
	const { data, total } = useSelector(({ exchange }) => exchange);
	const [filterFields, setFilterFields] = useState(
		internalExchangeFilterFields
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getInternalExchange(filteringParams));
		setFilterFields(internalExchangeFilterFields);
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
			dispatch(clearExchange());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<PageTitle title={"Internal Exchange"} filterFields={filterFields} />
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={total}
				rowsPerPage={activeFilters?.find(x => x.field === "size").value}
				page={activeFilters?.find(x => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default InternalExchange;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<td>
					{rowData?.createDate &&
						moment(rowData?.createDate).format("DD MMM YYYY HH:mm")}
				</td>
				<CopyWithAlert text={rowData?.uid + "" || ""} label={"User ID"}>
					<td data='hover'>{rowData?.uid}</td>
				</CopyWithAlert>
				<td>{rowData?.assetFrom}</td>
				<td>{rowData?.assetTo}</td>
				<td>{rowData?.amount}</td>
				<td>{rowData?.price}</td>
				<td>{rowData?.total}</td>
				<td
					style={{
						color: rowData?.status && getStatusColor(rowData?.status),
					}}>
					{rowData?.status || "-"}
				</td>
			</tr>
		</>
	);
}
