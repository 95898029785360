import { useState, useCallback } from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import QRCode from "react-qr-code";
import parseURI from "otpauth-uri-parser";
import { Button } from "../../theme/components/Button";
import { Input } from "../../theme/components/Input";
import DialogWrapper from "../../theme/components/dialogs/DialogWrapper";
import {
	getAccountData,
	checkInstallTwoFA,
} from "../../app/actions/accountActions";
import { setErrorMessage } from "../../utils/customValidators/validators";
import CopyWithAlert from "../../utils/CopyWithAlert";

const TwoFaQrCodeDialog = ({ open, handleClose, data }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [errors, setErrors] = useState("");
	const [totpPass, setTotpPass] = useState("");

	const parsedQRCodeData = parseURI(data.qr_code);

	const handleSubmit = () => {
		if (!totpPass) {
			setErrors(setErrorMessage("totpPass"));
			return;
		}
		dispatch(checkInstallTwoFA({ totpPass })).then(response => {
			if (response?.data) {
				dispatch(getAccountData());
				onClose();
			} else {
				setErrors(setErrorMessage("totpPass"));
			}
			setTotpPass("");
		});
	};

	const onClose = () => {
		setTotpPass("");
		setErrors("");
		handleClose();
	};

	const handleChangeCode = value => {
		if (value) setErrors("");
		setTotpPass(value);
	};

	const renderQRCodeParsedData = useCallback(() => {
		const accountName = parsedQRCodeData?.label?.account;
		const secret = parsedQRCodeData?.query?.secret;
		return (
			<Grid className={classes.qrCodeParsedBlock}>
				<CopyWithAlert
					text={(Boolean(accountName) && accountName) || ""}
					label={"Account name"}>
					<Grid item>
						<Typography variant={"body2"}>{`Account name:`}</Typography>
						<Typography
							variant={"body2"}
							color={"textPrimary"}
							className={classes.link}>
							{`${accountName}`}
						</Typography>
					</Grid>
				</CopyWithAlert>
				<CopyWithAlert
					text={(Boolean(secret) && secret) || ""}
					label={"Secret key"}>
					<Grid item>
						<Typography variant={"body2"}>{`Secret key:`}</Typography>
						<Typography
							variant={"body2"}
							color={"textPrimary"}
							className={classes.link}>
							{`${secret}`}
						</Typography>
					</Grid>
				</CopyWithAlert>
			</Grid>
		);
	}, [
		parsedQRCodeData?.label?.account,
		parsedQRCodeData?.query?.secret,
		classes,
	]);

	return (
		<DialogWrapper open={open} handleClose={onClose} maxWidth={520}>
			<Typography variant={"h6"} color={"textPrimary"}>
				1. Configure the App:
			</Typography>
			<Typography
				variant={"body2"}
				color={"textPrimary"}
				style={{ textAlign: "justify" }}>
				Open your two factor authentication app and add account by scanning the
				below QR code.
			</Typography>
			<Grid container justifyContent={"center"}>
				<Grid item>
					<div className={classes.qr}>
						{Boolean(data) && (
							<QRCode
								value={data?.qr_code}
								size={140}
								fgColor={"#000"}
								bgColor={"#fff"}
							/>
						)}
					</div>
				</Grid>
			</Grid>
			{renderQRCodeParsedData()}
			<Typography variant={"h6"} color={"textPrimary"}>
				2. Store Backup Codes:
			</Typography>
			<Typography
				variant={"body2"}
				color={"textPrimary"}
				style={{ textAlign: "justify" }}>
				Backup codes can be used to access your account in the event you loose
				access to your device and cannot receive two-factor authentication
				codes. Following backup codes are only for one time use, we recommend
				you to save them securely.
			</Typography>
			<Grid container className={classes.codes} justifyContent={"center"}>
				<CopyWithAlert
					text={(Boolean(data) && data?.emergency_codes?.join(", ") + "") || ""}
					label={"Emergency codes"}>
					<Grid item>{Boolean(data) && data?.emergency_codes.join(", ")}</Grid>
				</CopyWithAlert>
			</Grid>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 14 }}>
				3. Enter token code that the application generates:
			</Typography>
			<Grid container>
				<Grid item xs={12} style={{ marginBottom: 32 }}>
					<form>
						<Input
							placeholder='Enter code'
							variant='outlined'
							name={"totpPass"}
							type={"text"}
							autoComplete='off'
							error={Boolean(errors)}
							helperText={errors}
							onChange={({ target: { value } }) =>
								/^[0-9]*$/.test(value) &&
								value.length <= 6 &&
								handleChangeCode(value)
							}
							value={totpPass}
						/>
					</form>
				</Grid>
			</Grid>
			<Grid container justifyContent={"space-between"}>
				<Button variant='outlined' color='primary' onClick={handleClose}>
					Cancel
				</Button>
				<Button variant='contained' color='primary' onClick={handleSubmit}>
					Confirm
				</Button>
			</Grid>
		</DialogWrapper>
	);
};

export default TwoFaQrCodeDialog;

const useStyles = makeStyles(theme => ({
	qr: {
		marginTop: 32,
		marginBottom: 32,
		width: 160,
		height: 160,
		padding: 10,
		backgroundColor: "#fff",
	},
	codes: {
		marginTop: 16,
		marginBottom: 16,
		cursor: "pointer",
		color: theme.palette.primary.light,
	},
	input: {
		width: "100%",
		minWidth: 200,
		marginTop: 32,
	},
	link: {
		cursor: "pointer",
		color: theme.palette.primary.light,
	},
	qrCodeParsedBlock: {
		marginBottom: 16,
		textAlign: "center",
	},
}));
