import {
	CREATE_INVESTMENT_INDEX,
	UPDATE_INVESTMENT_INDEX,
	SEARCH_INVESTMENT_INDEXES,
	SEARCH_USER_INDEX_BALANCES,
	CLEAR_USER_INDEX_BALANCES,
	SEARCH_INDEX_OPERATIONS_HISTORY,
	SEARCH_USERS_INDEX_INVOICES,
	MAKE_DECISION_BY_INVOICE_INDEX,
	SEARCH_SEND_TO_BINANCE_PROGRAMS,
	GET_INDEX_SYSTEM_BALANCES,
	CLEAR_INDEX_SYSTEM_BALANCES,
	TRANSFER_FROM_SYSTEM_BALANCE_TO_WALLET,
	SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_INDEX_TO_WALLET,
	CLEAR_TRANSFERS_SYSTEM_BALANCE_INDEX,
	CLEAR_INDEXES_STATUS,
	CLEAR_INDEXES_ERROR,
	CLEAR_INDEXES_OPERATIONS_HISTORY,
	DELETE_INVESTMENT_INDEX,
	GET_INDEX_ALL_ASSETS_LIST,
	CREATE_NEW_INDEX_ASSET,
	UPDATE_INDEX_ASSET_INFO,
	GET_INDEX_ASSET_BY_ASSET_ID,
	DELETE_INDEX_ASSET,
	GET_INDEX_ASSETS_LIST_BY_INDEX_ID,
	MANUALLY_REFRESH_INDEX_ASSETS_RATES,
} from "../types";
import api from "../api";
import { enqueueSnackbar } from "./notistackActions";
import { Message } from "../../utils/enum";
import { actionCreator } from "../utils";

//CREATE_INVESTMENT_INDEX
export const createInvestmentIndexAction = actionCreator(
	CREATE_INVESTMENT_INDEX
);

export const createInvestmentIndex = (data) => {
	return async (dispatch) => {
		dispatch(createInvestmentIndexAction.started());
		api.capital.indexes
			.createInvestmentIndex(data)
			.then((res) => {
				dispatch(createInvestmentIndexAction.done(res.data));
				dispatch(searchInvestmentIndexes());
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => dispatch(createInvestmentIndexAction.failed(error)));
	};
};

//UPDATE_INVESTMENT_INDEX
export const updateInvestmentIndexAction = actionCreator(
	UPDATE_INVESTMENT_INDEX
);

export const updateInvestmentIndex = (data) => {
	return async (dispatch) => {
		dispatch(updateInvestmentIndexAction.started());
		api.capital.indexes
			.updateInvestmentIndex(data)
			.then((res) => {
				dispatch(updateInvestmentIndexAction.done(res));
				dispatch(searchInvestmentIndexes());
				dispatch(
					enqueueSnackbar({
						message: Message.DataChanged,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => dispatch(updateInvestmentIndexAction.failed(error)));
	};
};

// DELETE_INVESTMENT_FUND
export const deleteInvestmentIndexAction = actionCreator(
	DELETE_INVESTMENT_INDEX
);

export const deleteInvestmentIndex = (indexId) => {
	return async (dispatch) => {
		dispatch(deleteInvestmentIndexAction.started());
		api.capital.indexes
			.deleteInvestmentIndex(indexId)
			.then((res) => {
				dispatch(deleteInvestmentIndexAction.done(res.data));
				dispatch(searchInvestmentIndexes());
				dispatch(
					enqueueSnackbar({
						message: Message.DataDeleted,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => dispatch(deleteInvestmentIndexAction.failed(error)));
	};
};

// SEARCH_INVESTMENT_INDEXES
export const searchInvestmentIndexesAction = actionCreator(
	SEARCH_INVESTMENT_INDEXES
);

export const searchInvestmentIndexes = (params) => {
	return async (dispatch) => {
		dispatch(searchInvestmentIndexesAction.started());
		api.capital.indexes
			.searchInvestmentIndexes(params)
			.then((res) => dispatch(searchInvestmentIndexesAction.done(res.data)))
			.catch((error) => dispatch(searchInvestmentIndexesAction.failed(error)));
	};
};

// SEARCH_USER_INDEX_BALANCES
export const searchUserIndexBalancesAction = actionCreator(
	SEARCH_USER_INDEX_BALANCES
);

export const searchUserIndexBalances = (params) => {
	return async (dispatch) => {
		dispatch(searchUserIndexBalancesAction.started());
		api.capital.indexes
			.searchUserIndexBalances(params)
			.then((res) => dispatch(searchUserIndexBalancesAction.done(res.data)))
			.catch((error) => dispatch(searchUserIndexBalancesAction.failed(error)));
	};
};

// SEARCH_USERS_INDEX_INVOICES
export const searchUsersIndexInvoicesAction = actionCreator(
	SEARCH_USERS_INDEX_INVOICES
);

export const searchUsersIndexInvoices = (params) => {
	return async (dispatch) => {
		dispatch(searchIndexOperationsHistoryAction.started());
		api.capital.indexes
			.searchUsersIndexInvoices(params)
			.then((res) => dispatch(searchUsersIndexInvoicesAction.done(res.data)))
			.catch((error) => dispatch(searchUsersIndexInvoicesAction.failed(error)));
	};
};

// SEARCH_INDEX_OPERATIONS_HISTORY
export const searchIndexOperationsHistoryAction = actionCreator(
	SEARCH_INDEX_OPERATIONS_HISTORY
);

export const searchIndexOperationsHistory = (params) => {
	return async (dispatch) => {
		dispatch(searchIndexOperationsHistoryAction.started());
		api.capital.indexes
			.searchIndexOperationsHistory(params)
			.then((res) =>
				dispatch(searchIndexOperationsHistoryAction.done(res.data))
			)
			.catch((error) =>
				dispatch(searchIndexOperationsHistoryAction.failed(error))
			);
	};
};

// MAKE_DECISION_BY_INVOICE_INDEX
export const makeDecisionByInvoiceIndexAction = actionCreator(
	MAKE_DECISION_BY_INVOICE_INDEX
);

export const makeDecisionByInvoiceIndex = ({ data, params }) => {
	return async (dispatch) => {
		dispatch(makeDecisionByInvoiceIndexAction.started());
		api.capital.indexes
			.makeDecisionByInvoiceIndex(data)
			.then((res) => {
				dispatch(makeDecisionByInvoiceIndexAction.done(res.data));
				dispatch(searchUsersIndexInvoices(params));
			})
			.catch((error) =>
				dispatch(makeDecisionByInvoiceIndexAction.failed(error))
			);
	};
};

// SEARCH_SEND_TO_BINANCE_PROGRAMS
export const searchSendToBinanceProgramsAction = actionCreator(
	SEARCH_SEND_TO_BINANCE_PROGRAMS
);

export const searchSendToBinancePrograms = (params) => {
	return async (dispatch) => {
		dispatch(searchSendToBinanceProgramsAction.started());
		api.capital.indexes
			.searchSendToBinancePrograms(params)
			.then((res) => dispatch(searchSendToBinanceProgramsAction.done(res.data)))
			.catch((error) =>
				dispatch(searchSendToBinanceProgramsAction.failed(error))
			);
	};
};

// GET_INDEX_SYSTEM_BALANCES
export const getSystemBalancesIndexesAction = actionCreator(
	GET_INDEX_SYSTEM_BALANCES
);

export const getSystemBalancesIndexes = () => {
	return async (dispatch) => {
		dispatch(getSystemBalancesIndexesAction.started());
		api.capital.indexes
			.getSystemBalancesIndexes()
			.then((res) => dispatch(getSystemBalancesIndexesAction.done(res.data)))
			.catch((error) => dispatch(getSystemBalancesIndexesAction.failed(error)));
	};
};

// TRANSFER_FROM_SYSTEM_BALANCE_TO_WALLET
export const transferFromSystemBalanceToWalletAction = actionCreator(
	TRANSFER_FROM_SYSTEM_BALANCE_TO_WALLET
);

export const transferFromSystemBalanceToWallet = (data) => {
	return async (dispatch) => {
		dispatch(transferFromSystemBalanceToWalletAction.started());
		api.capital.indexes
			.transferFromSystemBalanceToWallet(data)
			.then((res) =>
				dispatch(transferFromSystemBalanceToWalletAction.done(res.data))
			)
			.catch((error) =>
				dispatch(transferFromSystemBalanceToWalletAction.failed(error))
			);
	};
};

// SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_INDEX_TO_WALLET
export const searchForTransfersFromSystemBalanceIndexToWalletAction =
	actionCreator(SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_INDEX_TO_WALLET);

export const searchForTransfersFromSystemBalanceIndexToWallet = (params) => {
	return async (dispatch) => {
		dispatch(searchForTransfersFromSystemBalanceIndexToWalletAction.started());
		api.capital.indexes
			.searchForTransfersFromSystemBalanceIndexToWallet(params)
			.then((res) =>
				dispatch(
					searchForTransfersFromSystemBalanceIndexToWalletAction.done(res.data)
				)
			)
			.catch((error) =>
				dispatch(
					searchForTransfersFromSystemBalanceIndexToWalletAction.failed(error)
				)
			);
	};
};

// GET_INDEX_ALL_ASSETS_LIST
export const getIndexAllAssetsListAction =
	actionCreator(GET_INDEX_ALL_ASSETS_LIST);

export const getIndexAllAssetsList = (params) => {
	return async (dispatch) => {
		dispatch(getIndexAllAssetsListAction.started());
		api.capital.indexes
			.getIndexAllAssetsList(params)
			.then((res) =>
				dispatch(
					getIndexAllAssetsListAction.done(res.data)
				)
			)
			.catch((error) =>
				dispatch(
					getIndexAllAssetsListAction.failed(error)
				)
			);
	};
};

// CREATE_NEW_INDEX_ASSET
export const createNewIndexAssetAction =
	actionCreator(CREATE_NEW_INDEX_ASSET);

export const createNewIndexAsset = (params) => {
	return async (dispatch) => {
		dispatch(createNewIndexAssetAction.started());
		api.capital.indexes
			.createNewIndexAsset(params)
			.then((res) => {
				dispatch(
					createNewIndexAssetAction.done(res.data)
				)
				if (params?.indexId) {
					dispatch(getIndexAssetsListByIndexId(params.indexId))
				}
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			}
			)
			.catch((error) =>
				dispatch(
					createNewIndexAssetAction.failed(error)
				)
			);
	};
};

// UPDATE_INDEX_ASSET_INFO
export const updateIndexAssetInfoAction =
	actionCreator(UPDATE_INDEX_ASSET_INFO);

export const updateIndexAssetInfo = ({ id, balance, indexId = null }) => {
	return async (dispatch) => {
		dispatch(updateIndexAssetInfoAction.started());
		api.capital.indexes
			.updateIndexAssetInfo({ id, balance })
			.then((res) => {
				dispatch(
					updateIndexAssetInfoAction.done(res.data)
				)
				if (indexId) {
					dispatch(getIndexAssetsListByIndexId(indexId))
				} else {
					dispatch(getIndexAllAssetsList());
				}
				dispatch(
					enqueueSnackbar({
						message: Message.DataChanged,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			}
			)
			.catch((error) =>
				dispatch(
					updateIndexAssetInfoAction.failed(error)
				)
			);
	};
};

// GET_INDEX_ASSET_BY_ASSET_ID
export const getIndexAssetByAssetIdAction =
	actionCreator(GET_INDEX_ASSET_BY_ASSET_ID);

export const getIndexAssetByAssetId = (params) => {
	return async (dispatch) => {
		dispatch(getIndexAssetByAssetIdAction.started());
		api.capital.indexes
			.getIndexAssetByAssetId(params)
			.then((res) =>
				dispatch(
					getIndexAssetByAssetIdAction.done(res.data)
				)
			)
			.catch((error) =>
				dispatch(
					getIndexAssetByAssetIdAction.failed(error)
				)
			);
	};
};

// GET_INDEX_ASSETS_LIST_BY_INDEX_ID
export const getIndexAssetsListByIndexIdAction =
	actionCreator(GET_INDEX_ASSETS_LIST_BY_INDEX_ID);

export const getIndexAssetsListByIndexId = (indexId) => {
	return async (dispatch) => {
		dispatch(getIndexAssetsListByIndexIdAction.started());
		api.capital.indexes
			.getIndexAssetsListByIndexId(indexId)
			.then((res) =>
				dispatch(
					getIndexAssetsListByIndexIdAction.done(res.data)
				)
			)
			.catch((error) =>
				dispatch(
					getIndexAssetsListByIndexIdAction.failed(error)
				)
			);
	};
};

// DELETE_INDEX_ASSET
export const deleteIndexAssetAction =
	actionCreator(DELETE_INDEX_ASSET);

export const deleteIndexAsset = ({ assetId, indexId }) => {
	return async (dispatch) => {
		dispatch(deleteIndexAssetAction.started());
		api.capital.indexes
			.deleteIndexAsset(assetId)
			.then((res) => {
				dispatch(
					deleteIndexAssetAction.done(res.data)
				)
				if (indexId) {
					dispatch(getIndexAssetsListByIndexId(indexId))
				} else {
					dispatch(getIndexAllAssetsList());
				}
				dispatch(
					enqueueSnackbar({
						message: Message.DataDeleted,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			}
			)
			.catch((error) =>
				dispatch(
					deleteIndexAssetAction.failed(error)
				)
			);
	};
};

// MANUALLY_REFRESH_INDEX_ASSETS_RATES
export const manuallyRefreshIndexAssetsRatesAction =
	actionCreator(MANUALLY_REFRESH_INDEX_ASSETS_RATES);

export const manuallyRefreshIndexAssetsRates = (params) => {
	return async (dispatch) => {
		dispatch(manuallyRefreshIndexAssetsRatesAction.started());
		api.capital.indexes
			.manuallyRefreshIndexAssetsRates(params)
			.then((res) =>
				dispatch(
					manuallyRefreshIndexAssetsRatesAction.done(res.data)
				)
			)
			.catch((error) =>
				dispatch(
					manuallyRefreshIndexAssetsRatesAction.failed(error)
				)
			);
	};
};

export const clearIndexSystemBalances = () => ({
	type: CLEAR_INDEX_SYSTEM_BALANCES,
});

export const clearUserIndexBalances = () => ({
	type: CLEAR_USER_INDEX_BALANCES,
});

export const clearTransfersSystemBalanceIndex = () => ({
	type: CLEAR_TRANSFERS_SYSTEM_BALANCE_INDEX,
});

export const clearIndexesOperationsHistory = () => ({
	type: CLEAR_INDEXES_OPERATIONS_HISTORY,
});

export const clearIndexesStatus = () => ({
	type: CLEAR_INDEXES_STATUS,
});

export const clearIndexesError = () => ({
	type: CLEAR_INDEXES_ERROR,
});
