import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IconButton, makeStyles, Paper, TablePagination, Typography } from "@material-ui/core";
import moment from "moment";
import BackIcon from "@material-ui/icons/KeyboardArrowLeftRounded";

import Table from "../../../../theme/components/Table/Table";
import {
  getFundPayoutBalanceHistory,
  clearFundPayoutBalanceHistory,
} from "../../../../app/actions/fundsCapitalActions";
import {
  setActiveFiltersPage,
  setActiveFiltersSize,
  clearActiveFilters,
} from "../../../../app/actions/filterActions";


const columns = [
  { key: "id", label: "ID" },
  { key: "targetUserId", label: "Target User ID" },
  { key: "targetAddress", label: "Target Address" },
  { key: "asset", label: "Asset" },
  { key: "transactionType", label: "Transaction Type" },
  { key: "amount", label: "amount" },
  { key: "time", label: "Created" },
  { key: "description", label: "Description" },
];

const PayoutBalanceHistory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { content, totalElements } = useSelector(
    ({ funds }) => funds?.fundPayoutBalanceHistory
  );
  const activeFilters = useSelector(({ filter }) => filter.activeFilters);

  const goBack = () => {
    history.push(history?.location?.state?.from || `/capital/funds`, {
      from: "payoutBalanceHistory"
    });
  };

  useEffect(() => {
    let filteringParams = {};
    if (activeFilters.length !== 0) {
      activeFilters.forEach((item) => {
        filteringParams = { ...filteringParams, [item.field]: item.value };
      });
    }
    dispatch(getFundPayoutBalanceHistory(filteringParams));
  }, [activeFilters, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearActiveFilters());
      dispatch(clearFundPayoutBalanceHistory());
    };
  }, [dispatch]);

  const handleChangePage = (_, newPage) => {
    dispatch(setActiveFiltersPage(newPage));
  };

  const handleChangeSize = ({ target }) => {
    dispatch(setActiveFiltersSize(target.value));
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Paper elevation={0} className={classes.paper} onClick={goBack}>
          <IconButton className={classes.icon} size={"small"}>
            <BackIcon />
          </IconButton>
          <Typography variant={"subtitle2"} color={"textSecondary"}>
            Back to{" "}
            <span className={classes.link}>
              {history?.location?.state?.title || "Funds"}
            </span>
          </Typography>
        </Paper>
      </div>
      <Typography variant={"h4"} color={"textPrimary"}>
        Payout Balance History
      </Typography>
      <Table>
        <thead>
          <tr>
            {columns.map(({ key, label }) => (
              <th key={key}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {content?.length > 0 ? (
            content?.map((rowData, index) => (
              <TableRow key={rowData?.id || index} rowData={rowData} />
            ))
          ) : (
            <tr>
              <td colSpan={8} align={"center"} height={100}>
                The list is empty...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 50]}
        component='div'
        count={totalElements || 0}
        rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
        page={activeFilters?.find((x) => x.field === "page").value}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeSize}
      />
    </div>
  );
};

export default PayoutBalanceHistory;

function TableRow({ rowData }) {
  return (
    <>
      <tr>
        <td>{rowData?.id}</td>
        <td>{rowData?.asset}</td>
        <td>{rowData?.targetUserId}</td>
        <td>{rowData?.targetAddress}</td>
        <td>{rowData?.transactionType}</td>
        <td>{+rowData?.amount}</td>
        <td>
          {rowData?.time && moment(rowData?.time).format("DD MMM YYYY HH:mm")}
        </td>
        <td>{rowData?.description}</td>
      </tr>
    </>
  );
}

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    background: theme.palette.background.default,
    boxShadow: "none",
  },
  paper: {
    display: "flex",
    alignItems: "center",
    paddingRight: 12,
    borderRadius: 8,
    width: "max-content",
    marginBottom: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  header: {
    display: "flex",
    gap: 20,
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  btn: {
    minWidth: 100,
    minHeight: 30,
    padding: "4px 12px",
  },
}));
