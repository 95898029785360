import React, { useEffect } from "react";
import { TablePagination, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
	setExchangeParams,
	clearExchange,
} from "../../app/actions/exchangeActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../app/actions/filterActions";
import Table from "../../theme/components/Table/Table";
import CopyWithAlert from "../../utils/CopyWithAlert";
import getStatusColor from "../../utils/functions/getStatusColor";
const columns = [
	{ key: "id", label: "Id" },
	{ key: "createDate", label: "Created" },
	{ key: "assetFrom", label: "Asset From" },
	{ key: "assetTo", label: "Asset To" },
	{ key: "amount", label: "Amount" },
	{ key: "price", label: "Price" },
	{ key: "total", label: "Total" },
	{ key: "status", label: "Status" },
];

const UserExchange = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { data, params, total } = useSelector(({ exchange }) => exchange);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		return () => {
			dispatch(clearExchange());
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
		dispatch(setExchangeParams({ ...params, page: newPage }));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
		dispatch(setExchangeParams({ ...params, size: target.value, page: 0 }));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((rowData, index) => (
							<tr key={index}>
								<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
									<td className={classes.hoverCopyTd}>{rowData?.id}</td>
								</CopyWithAlert>
								<td>
									{rowData?.createDate &&
										moment(rowData?.createDate).format("DD MMM YYYY HH:mm")}
								</td>
								<td>{rowData?.assetFrom}</td>
								<td>{rowData?.assetTo}</td>
								<td>{rowData?.amount}</td>
								<td>{rowData?.price}</td>
								<td>{rowData?.total}</td>
								<td
									style={{
										color: rowData?.status && getStatusColor(rowData?.status),
									}}>
									{rowData?.status || "-"}
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={total}
				rowsPerPage={activeFilters.find(x => x.field === "size").value}
				page={activeFilters.find(x => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default UserExchange;

const useStyles = makeStyles(theme => ({
	hoverCopyTd: {
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.light,
		},
	},
}));
