import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	TablePagination,
	lighten,
	makeStyles,
	Typography,
} from "@material-ui/core";
import AnyIcon from "@material-ui/icons/UnfoldMoreRounded";
import DownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import TopIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import clsx from "clsx";
import { getBalancesBinance } from "../../app/actions/financesActions";
import PageTitle from "../../theme/components/PageTitle";
import Table from "../../theme/components/Table/Table";
import {
	FILTER_BINANCE_BALANCES,
	SORT_BINANCE_BALANCES,
} from "../../app/types";
import { SORT_TYPES, next } from "../../utils/constants/sortTypes.js";

export default function BinanceBalances() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { filteredData, totalInUsdt } = useSelector(
		({ finances }) => finances?.balances
	);
	const [search, setSearch] = useState("");
	const [params, setParams] = useState({ page: 0, size: 10 });
	const [columns, setColumns] = useState([
		{ key: "currency", label: "Ticker" },
		{
			key: "spotBalance",
			label: "Spot Balance",
			sort: SORT_TYPES.none,
			type: "number",
		},
		{
			key: "balanceInUsdt",
			label: "Equivalent USD",
			sort: SORT_TYPES.none,
			type: "number",
		},
	]);

	useEffect(() => {
		dispatch(getBalancesBinance());
	}, [dispatch]);

	useEffect(() => {
		dispatch({ type: FILTER_BINANCE_BALANCES, payload: search });
	}, [dispatch, search]);

	const handleSearch = (search) => {
		setParams({ ...params, page: 0 });
		setSearch(search);
	};

	const sorting = (key) => {
		const nextState = next(columns.find((s) => s.key === key)?.sort);
		dispatch({
			type: SORT_BINANCE_BALANCES,
			payload: {
				filteredData,
				direction: nextState,
				key,
				type: columns.find((s) => s.key === key)?.type,
			},
		});
		setColumns(
			columns.map((s) =>
				s.key === key
					? { ...s, sort: nextState }
					: {
							...s,
							sort: s.sort ? SORT_TYPES.none : undefined,
					  }
			)
		);
	};

	const handleChangePage = (_, newPage) => {
		setParams({ ...params, page: newPage });
	};

	const handleChangeSize = ({ target }) => {
		setParams({ ...params, size: target.value, page: 0 });
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<PageTitle title={"Binance balances"} onSearch={handleSearch} />

			<Typography
				variant={"body1"}
				style={{ fontWeight: 700, marginBottom: 12 }}>
				{`Total balance: ${(+totalInUsdt)?.toFixed(2) || 0} USD`}
			</Typography>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label, sort, colSpan, align }) => (
							<th
								key={key}
								className={clsx(classes.th, classes.paper, sort && "hover")}
								colSpan={colSpan}
								align={align || "left"}
								onClick={() => sort && sorting(key)}>
								{sort === SORT_TYPES.up && <TopIcon />}
								{sort === SORT_TYPES.none && <AnyIcon />}
								{sort === SORT_TYPES.down && <DownIcon />}
								{label}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{filteredData?.length > 0 ? (
						filteredData
							.slice(
								Number(params.page) * params.size,
								Number(params.page) * params.size + params.size
							)
							.map((data, index) => (
								<tr key={index}>
									{columns.map(({ key }) => (
										<td key={`${index}-${key}`} style={{ width: "33%" }}>
											{data[key]}
										</td>
									))}
								</tr>
							))
					) : (
						<tr>
							<td colSpan={3} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 30, 50]}
				component='div'
				count={filteredData?.length || 0}
				rowsPerPage={params?.size}
				page={params?.page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	titleWrap: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 16,
		width: "calc(100vw - 100px)",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	root: {
		marginTop: 24,
		width: "100%",
		overflowX: "auto",
		paddingBottom: 24,
	},
	table: {
		borderSpacing: 0,
		width: "100%",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		"&:first-child": {
			borderRadius: "10px 0 0 10px",
		},
		"&:last-child": {
			borderRadius: "0 10px 10px 0",
		},
	},
	th: {
		color: theme.palette.text.secondary,
		padding: "14px 8px ",
		fontSize: 14,
		lineHeight: 2,
		cursor: "pointer",
		"&.hover:hover": {
			backgroundColor: lighten(theme.palette.background.paper, 0.04),
		},
		"&>svg": {
			fontSize: 19,
			verticalAlign: "middle",
			marginRight: 4,
		},
	},
	td: {
		color: theme.palette.text.primary,
		padding: "8px 14px",
		fontSize: 13,
		"& svg": {
			fontSize: 16,
		},
	},
	spacing: {
		height: 12,
	},
	hover: {
		"&:hover>td": {
			backgroundColor: lighten(theme.palette.background.paper, 0.04),
		},
	},
}));
