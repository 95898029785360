import React, { useEffect, useState } from "react";
import Tab from "@material-ui/core/Tab";
import { StyledTabs } from "../../../theme/components/StyledTabs";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../theme/components/PageTitle";
import {
	clearActiveFilters,
	setActiveFiltersPage,
	setActiveFiltersSize,
} from "../../../app/actions/filterActions";
import SystemSyncHistoryTable from "./SystemSyncHistoryTable";
import UserSyncHistoryTable from "./UserSyncHistoryTable";
import {
	getSystemSyncHistory,
	getUserSyncHistory,
} from "../../../app/actions/syncActions";

const tabs = {
	system: "System",
	users: "Users",
};

const systemSyncHistoryFilterFields = [
	{
		field: "id",
		type: "number",
		title: "ID",
		placeholder: "ID",
	},
	{ field: "status", type: "text", title: "Status", placeholder: "Status" },
	{ field: "source", type: "text", title: "Source", placeholder: "Source" },
	{ field: "target", type: "text", title: "Target", placeholder: "Target" },
	{
		field: "timeWhenJobWasCreatedFrom",
		type: "date",
		title: "Time when job was created from",
		placeholder: "Time when job was created from",
	},
	{
		field: "timeWhenJobWasCreatedTo",
		type: "date",
		title: "Time when job was created to",
		placeholder: "Time when job was created to",
	},
	{
		field: "timeWhenJobWasCompletedFrom",
		type: "date",
		title: "Time when job was completed from",
		placeholder: "Time when job was completed from",
	},
	{
		field: "timeWhenJobWasCompletedTo",
		type: "date",
		title: "Time when job was completed to",
		placeholder: "Time when job was completed to",
	},
	{
		field: "currency",
		type: "text",
		title: "Currency",
		placeholder: "Currency",
	},
	{ field: "address", type: "text", title: "Address", placeholder: "Address" },
];

const userSyncHistoryFilterFields = [
	{
		field: "id",
		type: "number",
		title: "ID",
		placeholder: "ID",
	},
	{ field: "userId", type: "number", title: "User ID", placeholder: "User ID" },
	{
		field: "orderId",
		type: "number",
		title: "Order Id",
		placeholder: "Order Id",
	},
	{ field: "status", type: "text", title: "Status", placeholder: "Status" },
	{
		field: "customOrderId",
		type: "number",
		title: "Custom Order Id",
		placeholder: "Custom Order Id",
	},
	{
		field: "timeWhenProgramWasCreatedFrom",
		type: "date",
		title: "Time when program was created from",
		placeholder: "Time when program was created from",
	},
	{
		field: "timeWhenProgramWasCreatedTo",
		type: "date",
		title: "Time when program was created to",
		placeholder: "Time when program was created to",
	},
	{
		field: "timeWhenProgramWasCompletedFrom",
		type: "date",
		title: "Time when program was completed from",
		placeholder: "Time when program was completed from",
	},
	{
		field: "timeWhenProgramWasCompletedTo",
		type: "date",
		title: "Time when program was completed to",
		placeholder: "Time when program was completed to",
	},
];

const SyncHistory = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(tabs.system);
	const [filterFields, setFilterFields] = useState(
		systemSyncHistoryFilterFields
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);
	const { content, totalElements } = useSelector(
		({ synchronization }) => synchronization.sync
	);
	useEffect(() => {
		let filteringParams = {};
		activeFilters.forEach(item => {
			filteringParams = { ...filteringParams, [item.field]: item.value };
		});
		/*eslint no-useless-computed-key: "off"*/
		if (activeTab === tabs.system) {
			dispatch(getSystemSyncHistory(filteringParams));
			setFilterFields(systemSyncHistoryFilterFields);
		} else {
			dispatch(getUserSyncHistory(filteringParams));
			setFilterFields(userSyncHistoryFilterFields);
		}
	}, [activeFilters, activeTab, dispatch]);

	const handleChangeTab = newTab => {
		setActiveTab(newTab);
		dispatch(clearActiveFilters());
	};

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<PageTitle
				title={"History of synchronization programs"}
				filterFields={filterFields}
			/>
			<StyledTabs
				style={{ marginBottom: 12 }}
				indicatorColor='primary'
				value={activeTab}
				onChange={(_, newTab) => handleChangeTab(newTab)}>
				<Tab label={"System synchronization"} value={tabs.system} />
				<Tab label={"User synchronization"} value={tabs.users} />
			</StyledTabs>
			{activeTab === tabs.system ? (
				<SystemSyncHistoryTable
					onPageChange={(event, newPage) => handleChangePage(event, newPage)}
					onRowsPerPageChange={event => handleChangeSize(event)}
					data={content}
					params={activeFilters}
					total={totalElements || 0}
				/>
			) : (
				<UserSyncHistoryTable
					onPageChange={(event, newPage) => handleChangePage(event, newPage)}
					onRowsPerPageChange={event => handleChangeSize(event)}
					data={content}
					params={activeFilters}
					total={totalElements || 0}
				/>
			)}
		</>
	);
};

export default SyncHistory;
