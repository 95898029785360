import {
	CLEAR_TRANSACTIONS,
	GET_TRANSACTIONS_FAILURE,
	GET_TRANSACTIONS_STARTED,
	GET_TRANSACTIONS_SUCCESS,
	SET_TRANSACTIONS_PARAMS,
	GET_TRANSFERS_TO_MASTER_WALLET_FAILURE,
	GET_TRANSFERS_TO_MASTER_WALLET_STARTED,
	GET_TRANSFERS_TO_MASTER_WALLET_SUCCESS,
} from "../types";

export default function transactions(
	state = {
		data: [],
		error: null,
		params: { page: 0, size: 10 },
		total: 0,
	},
	action
) {
	switch (action.type) {
		case GET_TRANSACTIONS_STARTED:
		case GET_TRANSFERS_TO_MASTER_WALLET_STARTED:
			return { ...state, error: null };
		case GET_TRANSACTIONS_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				total: action.payload.totalItems,
			};
		case GET_TRANSFERS_TO_MASTER_WALLET_SUCCESS:
			return {
				...state,
				data: action.payload.content,
				total: action.payload.totalElements,
			};

		case GET_TRANSACTIONS_FAILURE:
		case GET_TRANSFERS_TO_MASTER_WALLET_FAILURE:
			return { ...state, error: action.payload.error };
		case SET_TRANSACTIONS_PARAMS:
			return {
				...state,
				params: action.payload,
				filteredParams: action.payload,
			};
		case CLEAR_TRANSACTIONS:
			return {
				data: [],
				error: null,
				params: { page: 0, size: 10 },
				total: 0,
			};
		default:
			return state;
	}
}
