import DialogWrapper from "./DialogWrapper";
import {Grid, Typography} from "@material-ui/core";

const DetailsDialog = ({open, handleClose, data, title}) => {

  return (
    <DialogWrapper open={open} handleClose={handleClose}>
      <Typography variant={'h4'} align={'center'} color={'textSecondary'} style={{marginBottom: 32, textDecoration: 'underline'}}>
        {title}
      </Typography>
        {data ?
          <div>
            {data.map(({field, value}) => {
              return <div key={field}  align={'left'}>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item xs={6}>
                    <Typography variant={'h6'} color={'textPrimary'} style={{fontWeight: 500, marginBottom: 16 }} noWrap>
                      {field}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant={'h6'} color={'textPrimary'} style={{fontWeight: 500, marginBottom: 16 }} noWrap>
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              })
            }
          </div> :
          <div></div>
        }
    </DialogWrapper>
  );
};

export default DetailsDialog;
