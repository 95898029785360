import {
	GET_PROGRAM_PENDING_CONFIRMATION,
	GET_TOTAL_SHARES,
	GET_TOTAL_SHARES_USER,
	GET_DEVICES,
	CREATE_DEVICE,
	EDIT_DEVICE,
	DELETE_DEVICE,
	GET_SERVICE_ACCOUNT_BALANCES,
	GET_DISTRIBUTION_HISTORY,
	GET_CURRENT_SHARES,
	CALCULATE_CURRENT_SHARES,
	SET_SHARES_DISTRIBUTION_FORCEFULLY,
	GET_HISTORY_USED_PROGRAMS,
	PROGRAM_START,
	CLEAR_DHS_STATUS,
	CLEAR_DHS_ERROR,
	FILTER_TOTAL_SHARES,
	GET_OWNERS_HISTORY,
} from "../types";
import { filtering } from "../../utils/functions/filteringTable";

export default function dhs(
	state = {
		devices: {
			data: [],
			params: { page: 0, size: 10 },
			totalElements: 0,
		},
		history: {},
		ownersHistory: [],
		historyUsedProgram: {},
		totalSharesData: [],
		totalSharesFilteredData: [],
		currentShares: [],
		balances: null,
		statusProgram: null,
		status: false,
		error: null,
	},
	action
) {
	switch (action.type) {
		case GET_PROGRAM_PENDING_CONFIRMATION.STARTED:
		case GET_TOTAL_SHARES.STARTED:
		case GET_TOTAL_SHARES_USER.STARTED:
		case GET_DEVICES.STARTED:
		case CREATE_DEVICE.STARTED:
		case EDIT_DEVICE.STARTED:
		case DELETE_DEVICE.STARTED:
		case GET_SERVICE_ACCOUNT_BALANCES.STARTED:
		case GET_DISTRIBUTION_HISTORY.STARTED:
		case GET_CURRENT_SHARES.STARTED:
		case CALCULATE_CURRENT_SHARES.STARTED:
		case SET_SHARES_DISTRIBUTION_FORCEFULLY.STARTED:
		case GET_HISTORY_USED_PROGRAMS.STARTED:
		case PROGRAM_START.STARTED:
		case GET_OWNERS_HISTORY.STARTED:
			return { ...state, status: false, error: null };
		case GET_PROGRAM_PENDING_CONFIRMATION.FAILURE:
		case GET_TOTAL_SHARES.FAILURE:
		case GET_TOTAL_SHARES_USER.FAILURE:
		case GET_DEVICES.FAILURE:
		case CREATE_DEVICE.FAILURE:
		case EDIT_DEVICE.FAILURE:
		case DELETE_DEVICE.FAILURE:
		case GET_SERVICE_ACCOUNT_BALANCES.FAILURE:
		case GET_DISTRIBUTION_HISTORY.FAILURE:
		case GET_CURRENT_SHARES.FAILURE:
		case CALCULATE_CURRENT_SHARES.FAILURE:
		case SET_SHARES_DISTRIBUTION_FORCEFULLY.FAILURE:
		case GET_HISTORY_USED_PROGRAMS.FAILURE:
		case PROGRAM_START.FAILURE:
		case GET_OWNERS_HISTORY.FAILURE:
			return { ...state, status: false, error: action.payload.error };
		case GET_DEVICES.SUCCESS:
			return { ...state, devices: action.payload };
		case CREATE_DEVICE.SUCCESS:
		case EDIT_DEVICE.SUCCESS:
		case DELETE_DEVICE.SUCCESS:
			return { ...state, status: true };
		case GET_TOTAL_SHARES.SUCCESS:
		case GET_TOTAL_SHARES_USER.SUCCESS:
			return {
				...state,
				totalSharesData: action.payload,
				totalSharesFilteredData: action.payload,
			};
		case FILTER_TOTAL_SHARES:
			return {
				...state,
				totalSharesFilteredData: filtering(
					[...state.totalSharesData],
					action.payload
				),
			};
		case GET_SERVICE_ACCOUNT_BALANCES.SUCCESS:
			return { ...state, balances: action.payload };
		case GET_PROGRAM_PENDING_CONFIRMATION.SUCCESS:
			return { ...state, statusProgram: action.payload };
		case GET_DISTRIBUTION_HISTORY.SUCCESS:
			return { ...state, history: action.payload };
		case GET_CURRENT_SHARES.SUCCESS:
			return { ...state, currentShares: action.payload };
		case CALCULATE_CURRENT_SHARES.SUCCESS:
		case SET_SHARES_DISTRIBUTION_FORCEFULLY.SUCCESS:
		case GET_HISTORY_USED_PROGRAMS.SUCCESS:
			return { ...state, historyUsedProgram: action.payload };
		case GET_OWNERS_HISTORY.SUCCESS:
			return { ...state, ownersHistory: action.payload };
		case PROGRAM_START.SUCCESS:
			return {
				...state,
				// sync: action.payload,
			};
		case CLEAR_DHS_STATUS:
			return {
				...state,
				status: false,
			};
		case CLEAR_DHS_ERROR:
			return {
				...state,
				error: null,
				status: false,
			};
		default:
			return state;
	}
}
