import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CachedIcon from "@material-ui/icons/Cached";
import Collapse from "@material-ui/core/Collapse";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { NavLink } from "react-router-dom";
import {
	AttachMoney,
	AddToHomeScreen,
	Description,
	ExpandLess,
	ExpandMore,
	GroupRounded,
	HomeRounded,
	ListAlt,
	MonetizationOn,
	Settings,
	PersonPin,
	Computer,
	Dvr,
	PanoramaWideAngle,
	Assessment,
	Business,
} from "@material-ui/icons";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { ReactComponent as ReferralProgramIcon } from "../../../assets/referral_program.svg";
import Header from "./Header";

const drawerWidth = 300;

const pages = [
	{ text: "Home", to: "/", icon: <HomeRounded />, subMenu: null },
	{
		text: "Finances",
		to: undefined,
		icon: <MonetizationOn />,
		subMenu: {
			control: "finances",
			pages: [
				{
					text: "Balance (Kuna)",
					icon: <ListAlt />,
					to: "/finances/kuna-balances",
				},
				{
					text: "Balance (Binance)",
					icon: <ListAlt />,
					to: "/finances/binance-balances",
				},
				{
					text: "Internal Balance",
					icon: <ListAlt />,
					to: "/finances/internal-balance",
				},
				{
					text: "User balance",
					icon: <ListAlt />,
					to: "/finances/user-balance",
				},
			],
		},
	},

	{
		text: "Sync settings",
		to: undefined,
		icon: <CachedIcon />,
		subMenu: {
			control: "sync",
			pages: [
				{
					text: "Auto Sync Balance",
					icon: <ListAlt />,
					to: "/sync/auto-sync-balance",
				},
				{
					text: "Withdrawal history",
					icon: <ListAlt />,
					to: "/sync/withdrawal-history",
				},
				{
					text: "Sync history",
					icon: <ListAlt />,
					to: "/sync/synchronization-history",
				},
				{
					text: "Order History",
					icon: <ListAlt />,
					to: "/sync/order-history",
				},
				{
					text: "Statistics",
					icon: <ListAlt />,
					to: "/sync/statistics",
				},
			],
		},
	},

	{
		text: "Orders",
		to: undefined,
		icon: <ListAlt />,
		subMenu: {
			control: "orders",
			pages: [
				{
					text: "Crypto Operations",
					icon: <ListAlt />,
					to: "/orders/crypto-operations",
				},
				{
					text: "Fiat Operations (Kuna)",
					icon: <ListAlt />,
					to: "/orders/fiat-operations",
				},
				{
					text: "Internal Exchange",
					icon: <ListAlt />,
					to: "/orders/internal-exchange",
				},
				{
					text: "Styajka",
					icon: <ListAlt />,
					to: "/orders/styajka",
				},
			],
		},
	},
	{
		text: "Monitoring",
		to: undefined,
		icon: <Computer />,
		subMenu: {
			control: "monitoring",
			pages: [{ text: "Coins", icon: <Dvr />, to: "/monitoring/coins" }],
		},
	},
	{
		text: "Users",
		to: undefined,
		icon: <GroupRounded />,
		subMenu: {
			control: "users",
			pages: [
				{ text: "User list", icon: <ListAlt />, to: "/users/list" },
				{ text: "KYC", icon: <ListAlt />, to: "/users/kyc_history" },
				{ text: "Bank Cards", icon: <ListAlt />, to: "/users/bank_cards" },
			],
		},
	},
	{
		text: "Admins",
		to: undefined,
		icon: <PersonPin />,
		subMenu: {
			control: "admins",
			pages: [
				{ text: "Admin list", icon: <ListAlt />, to: "/admins/list" },
				{ text: "Sessions", icon: <AddToHomeScreen />, to: "/admins/sessions" },
				{ text: "Action log", icon: <Description />, to: "/admins/actionlog" },
			],
		},
	},
	{
		text: "Settings",
		to: undefined,
		icon: <Settings />,
		subMenu: {
			control: "settings",
			pages: [
				{
					text: "Exchange settings",
					icon: <Settings />,
					to: "/settings/exchange",
				},
				{
					text: "Withdrawal settings",
					icon: <Settings />,
					to: "/settings/transactions/withdrawal",
				},
				{
					text: "Deposit settings",
					icon: <Settings />,
					to: "/settings/transactions/deposit",
				},
			],
		},
	},
	{
		text: "DHS",
		to: undefined,
		icon: <PanoramaWideAngle />,
		subMenu: {
			control: "dhs",
			pages: [
				{
					text: "Devices",
					icon: <PanoramaWideAngle />,
					to: "/dhs/devices",
				},
				{
					text: "Owners history",
					icon: <ListAlt />,
					to: "/dhs/owners-history",
				},
				{
					text: "Allocation service",
					icon: <ListAlt />,
					to: "/dhs/allocation-service",
				},
				{
					text: "Statistic",
					icon: <ListAlt />,
					to: "/dhs/statistic",
				},
				{
					text: "Approve",
					icon: <ListAlt />,
					to: "/dhs/approve",
				},
				{
					text: "History program",
					icon: <ListAlt />,
					to: "/dhs/history-program",
				},
				{
					text: "Distribution history",
					icon: <ListAlt />,
					to: "/dhs/distribution-history",
				},
				{
					text: "Shares",
					icon: <ListAlt />,
					to: "/dhs/shares",
				},
			],
		},
	},
	{
		text: "Report",
		to: undefined,
		icon: <Assessment />,
		subMenu: {
			control: "report",
			pages: [
				{
					text: "Profit",
					icon: <Assessment />,
					to: "/report/profit",
				},
			],
		},
	},
	{
		text: "Banking",
		to: undefined,
		icon: <AccountBalanceIcon />,
		subMenu: {
			control: "banking",
			pages: [
				{
					text: "Monitoring",
					icon: <ListAlt />,
					to: "/banking/monitoring",
				},
				{
					text: "Settings",
					icon: <ListAlt />,
					to: "/banking/settings",
				},
				{
					text: "Liquidity Providers",
					icon: <ListAlt />,
					to: "/banking/liq-providers",
				},

			]
		},
	},
	{
		text: "Capital",
		to: undefined,
		icon: <AttachMoney />,
		subMenu: {
			control: "capital",
			pages: [
				{
					text: "Funds",
					icon: <ListAlt />,
					to: "/capital/funds",
				},
				{
					text: "Indexes",
					icon: <ListAlt />,
					to: "/capital/indexes",
				},
			],
		},
	},
	{
		text: "Companies",
		to: "/companies",
		icon: <Business />,
		subMenu: null,
	},
	{
		text: "Referral program",
		to: "/referral-program",
		icon: <ReferralProgramIcon />,
		subMenu: null,
	},
];

export default function PageWrapper({ children }) {
	const classes = useStyles();
	const history = useHistory();

	const [state, setState] = useState({
		finances: false,
		orders: false,
		monitoring: false,
		users: false,
		admins: false,
		settings: false,
	});

	const [openDrawer, setOpenDrawer] = useState(false);

	const handleDrawerOpening = () => {
		if (!openDrawer) {
			let patchArr = history?.location?.pathname?.split("/");
			patchArr?.length > 3
				? setState({
						...state,
						[patchArr[1]]: true,
						[patchArr[2]]: true,
				  })
				: setState({
						...state,
						[patchArr[1]]: true,
				  });
			setOpenDrawer(true);
		}
	};

	const handleDrawerClosing = () => {
		setOpenDrawer(false);
		if (openDrawer) {
			setState({
				finances: false,
				users: false,
				admins: false,
				orders: false,
				settings: false,
				monitoring: false,
				dhs: false,
				report: false,
				capital: false,
				fund: false,
				index: false,
			});
		}
	};

	const expand = (type) => {
		if (openDrawer) {
			setState({ ...state, [type]: !state[type] });
		}
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Header />
			<Drawer
				variant='permanent'
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: openDrawer,
					[classes.drawerClose]: !openDrawer,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: openDrawer,
						[classes.drawerClose]: !openDrawer,
					}),
				}}
				onMouseEnter={handleDrawerOpening}
				onMouseLeave={handleDrawerClosing}>
				<Toolbar />
				<div className={classes.drawerContainer}>
					<List>
						{pages.map(({ text, to, icon, subMenu }) => (
							<div key={text}>
								<ListItem
									component={to && NavLink}
									to={to}
									exact={to ? true : undefined}
									button
									className={classes.item}
									onClick={() => subMenu && expand(subMenu?.control)}>
									<ListItemIcon style={{ minWidth: 38 }}>{icon}</ListItemIcon>
									<ListItemText primary={text} />
									{subMenu &&
										(state[subMenu.control] ? <ExpandLess /> : <ExpandMore />)}
								</ListItem>
								{subMenu && (
									<Collapse
										in={state[subMenu.control]}
										timeout='auto'
										unmountOnExit>
										<List component='div' disablePadding>
											{subMenu.pages.map((p) =>
												subMenu.isPageMenu ? (
													<div key={p.to || p.text}>
														<ListItem
															button
															className={classes.nested}
															component={p.to && NavLink}
															to={p.to}
															exact={p.to ? true : undefined}
															onClick={() =>
																p.pageMenu && expand(p?.pageMenu?.control)
															}>
															<ListItemIcon style={{ minWidth: 38 }}>
																{p.icon}
															</ListItemIcon>
															<ListItemText primary={p.text} />
															{p.pageMenu &&
																(state[p?.pageMenu?.control] ? (
																	<ExpandLess />
																) : (
																	<ExpandMore />
																))}
														</ListItem>
														{p?.pageMenu?.secondPages && (
															<Collapse
																in={state[p?.pageMenu?.control]}
																timeout='auto'
																unmountOnExit>
																<List component='div' disablePadding>
																	{p?.pageMenu?.secondPages.map((s) => (
																		<ListItem
																			button
																			className={classes.secondNested}
																			component={s.to && NavLink}
																			to={s.to}
																			key={s.to || setState.text}
																			isActive={(match, location) =>
																				match ||
																				location?.pathname.includes(s.to)
																			}>
																			<ListItemIcon style={{ minWidth: 38 }}>
																				{s.icon}
																			</ListItemIcon>
																			<ListItemText primary={s.text} />
																		</ListItem>
																	))}
																</List>
															</Collapse>
														)}
													</div>
												) : (
													<ListItem
														button
														className={classes.nested}
														component={p.to && NavLink}
														to={p.to}
														key={p.to || p.text}
														isActive={(match, location) =>
															match || location?.pathname.includes(p.to)
														}>
														<ListItemIcon style={{ minWidth: 38 }}>
															{p.icon}
														</ListItemIcon>
														<ListItemText primary={p.text} />
													</ListItem>
												)
											)}
										</List>
									</Collapse>
								)}
							</div>
						))}
					</List>
				</div>
			</Drawer>
			<main className={classes.content}>
				<Toolbar />
				{children}
			</main>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(6) + 1,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflowX: "hidden",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		backgroundColor: theme.palette.background.default,
		minHeight: "100vh",
		position: "relative",
	},
	item: {
		"& div": {
			"& span": {
				fontWeight: 700,
				textTransform: "uppercase",
				fontSize: 14,
			},
		},
		"& svg": {
			color: theme.palette.common.white,
			fontSize: 20,
		},
		"&.active": {
			color: theme.palette.primary.light,
			"& svg": {
				color: theme.palette.primary.light,
			},
		},
	},
	nested: {
		paddingLeft: theme.spacing(4),
		"& div": {
			"& span": {
				fontWeight: 700,
				textTransform: "uppercase",
				fontSize: 14,
			},
		},
		"& svg": {
			color: theme.palette.common.white,
			fontSize: 20,
		},
		"&.active": {
			color: theme.palette.primary.light,
			"& svg": {
				color: theme.palette.primary.light,
			},
		},
	},
	secondNested: {
		paddingLeft: theme.spacing(8),
		"& div": {
			"& span": {
				fontWeight: 700,
				textTransform: "uppercase",
				fontSize: 14,
			},
		},
		"& svg": {
			color: theme.palette.common.white,
			fontSize: 20,
		},
		"&.active": {
			color: theme.palette.primary.light,
			"& svg": {
				color: theme.palette.primary.light,
			},
		},
	},
}));
