import React from 'react';

const Roles = () => {
    return (
        <>
            Roles
        </>
    );
};

export default Roles;
