import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import {
	makeStyles,
	Typography,
	Paper,
	IconButton,
	Grid,
	lighten,
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import { Button } from "../../theme/components/Button";
import getStatusColor from "../../utils/functions/getStatusColor";
import { clearBankCardDetails, getBankCardById, getCardPhotoFile, makeDecisionByCard } from "../../app/actions/bankCardsActions";
import { PHOTO_TYPES, BANK_CARDS_STATUS, BANK_CARDS_DETAILS } from "../../utils/constants/bankCards";
import BankCardDecisionDialog from "./BankCardDecisionDialog";

const BankCardDetails = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { id } = useParams();
	const history = useHistory();
	const { bankCardDetails, bankCardDetailsFiles } = useSelector(({ bankCards }) => bankCards);
	const [params, setParams] = useState(null);
	const [decision, setDecision] = useState(null);

	useEffect(() => {
		setParams({ ...history?.location?.state, from: "bank_card_details" });
		dispatch(getBankCardById(id));
	}, [dispatch, id, history?.location?.state]);

	const goToUserProfile = () => {
		history.push(`/users/list/${null}/${bankCardDetails?.userId}`);
	};

	const createState = (item) => {
		item.photo.forEach((i) => {
			switch (i.fileType) {
				case PHOTO_TYPES.SELFIE:
					dispatch(getCardPhotoFile(i?.id, PHOTO_TYPES.SELFIE));
					return;
				case PHOTO_TYPES.CARD:
					dispatch(getCardPhotoFile(i?.id, PHOTO_TYPES.CARD));
					return;
				default:
					return;
			}
		});
	};

	useEffect(() => {
		if (!bankCardDetails) {
			return;
		}
		createState(bankCardDetails);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, bankCardDetails]);

	useEffect(() => {
		return () => {
			dispatch(clearBankCardDetails());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleDecision = (form) => {
		let formData = { ...form, cardId: bankCardDetails?.id, kycStatus: decision };
		dispatch(makeDecisionByCard(formData));
	};

	const handleCloseDialog = () => {
		setDecision(null);
	};

	return (
		<Grid container className={classes.root}>
			<BankCardDecisionDialog
				open={Boolean(decision)}
				handleClose={handleCloseDialog}
				data={bankCardDetails}
				decision={decision}
				handleDecision={handleDecision}
			/>
			<Grid item className={classes.head}>
				<Paper
					elevation={0}
					className={classes.paper}
					onClick={() =>
						history.push("/users/bank_cards", {
							...params,
							from: "bank_card_details",
						})
					}>
					<IconButton className={classes.icon} size={"small"}>
						<BackIcon />
					</IconButton>
					<Typography variant={"subtitle2"} color={"textSecondary"}>
						Back to <span className={classes.link}>{params?.title}</span>
					</Typography>
				</Paper>
				<Typography variant={"h4"} color={"textPrimary"}>
					Bank Card Details
				</Typography>
			</Grid>
			<Grid item container spacing={5} justifyContent='center'>
				<Grid item xs={12} md={6} lg={4}>
					<Paper elevation={4} className={classes.box}>
						{bankCardDetails &&
							BANK_CARDS_DETAILS.map(
								(item, index) =>
								bankCardDetails[item?.key] && (
										<Grid
											container
											key={index}
											spacing={2}
											alignItems={"center"}
											onClick={item.key === "userId" ? goToUserProfile : null}
											className={clsx(
												item.key === "userId" && classes.redirect
											)}>
											<Grid item xs={6}>
												<Typography variant={"h6"}>
													{`${item.title}: `}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography
													variant={"h6"}
													align='right'
													className={classes.typography}
													style={{
														color:
															item?.key === "status" &&
															getStatusColor(bankCardDetails[item?.key]),
													}}>
													{item?.type === "date"
														? moment(bankCardDetails[item?.key]).format(
																"DD MMM YYYY HH:mm"
														  )
														: bankCardDetails[item?.key]}
												</Typography>
											</Grid>
										</Grid>
									)
							)}
					</Paper>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Paper elevation={4} className={classes.box}>
						<Typography
							variant={"h5"}
							color={"textPrimary"}
							align='center'
							style={{ marginBottom: 24 }}>
							Selfie
						</Typography>

						{bankCardDetailsFiles[PHOTO_TYPES.SELFIE] != null && (
							<img
								alt='selfie'
								style={{ height: "auto", width: "100%" }}
								src={`data:image;base64,${
									bankCardDetailsFiles[PHOTO_TYPES.SELFIE]
								}`}
							/>
						)}
					</Paper>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Paper elevation={4} className={classes.box}>
						<Typography
							variant={"h5"}
							color={"textPrimary"}
							align='center'
							style={{ marginBottom: 24 }}>
							Front side of the card
						</Typography>
						{bankCardDetailsFiles[PHOTO_TYPES.CARD] != null && (
							<img
								alt='card'
								style={{ height: "auto", width: "100%" }}
								src={`data:image;base64,${
									bankCardDetailsFiles[PHOTO_TYPES.CARD]
								}`}
							/>
						)}
					</Paper>
				</Grid>
			</Grid>
			{bankCardDetails?.status === BANK_CARDS_STATUS.PENDING && (
				<Grid item container className={classes.btnWrap}>
					<Button
						variant='contained'
						color='primary'
						className={classes.declineBtn}
						onClick={() => setDecision(BANK_CARDS_STATUS.DECLINED)}>
						Decline
					</Button>
					<Button
						className={classes.approveBtn}
						variant='contained'
						onClick={() => setDecision(BANK_CARDS_STATUS.APPROVED)}>
						Approve
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

export default BankCardDetails;

const useStyles = makeStyles((theme) => ({
	head: {
		marginBottom: 16,
		"&>.MuiTextField-root": {
			maxWidth: 256,
			marginTop: 24,
			[theme.breakpoints.down("sm")]: {
				marginTop: 24,
			},
			"&>.MuiOutlinedInput-root>input": {
				padding: 10,
			},
		},
	},
	paper: {
		display: "flex",
		alignItems: "center",
		padding: 8,
		paddingRight: 12,
		borderRadius: 8,
		width: "max-content",
		marginBottom: 16,
		"&:hover": {
			cursor: "pointer",
		},
	},
	box: {
		width: "100%",
		height: "100%",
		padding: 16,
		borderRadius: 8,
	},
	typography: {
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	redirect: {
		color: theme.palette.primary.light,
		"&:hover": {
			color: lighten(theme.palette.primary.light, 0.4),
			cursor: "pointer",
		},
	},
	btnWrap: {
		marginTop: 40,
		justifyContent: "space-around",
	},
	declineBtn: {
		background: theme.palette.error.main,
		minWidth: 140,
		"&:hover": {
			background: lighten(theme.palette.error.main, 0.4),
		},
	},
	approveBtn: {
		background: theme.palette.success.main,
		minWidth: 140,
		"&:hover": {
			background: lighten(theme.palette.success.main, 0.4),
		},
	},
	icon: {
		color: theme.palette.primary.light,
		padding: 0,
		marginRight: 4,
		"& svg": {
			fontSize: 19,
		},
	},
}));
