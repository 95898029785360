import { memo, useEffect } from "react";
import { Paper } from "@material-ui/core";

const Chart = ({ ticker }) => {
	useEffect(() => {
		new window.TradingView.MediumWidget({
			symbols: [
				[`${ticker.includes("usdt") ? "USDT" : ticker?.toUpperCase()}USD|1D`],
			],
			chartOnly: false,
			width: "100%",
			height: "100%",
			locale: "en",
			colorTheme: "dark",
			gridLineColor: "rgba(146, 147, 151, 0.5)",
			trendLineColor: "#18A0FB",
			fontColor: "#929397",
			underLineColor: "rgba(28, 64, 48, 0.8)",
			underLineBottomColor: "rgba(28, 64, 48, 0)",
			isTransparent: true,
			autosize: true,
			showFloatingTooltip: true,
			container_id: "tradingview_5a0d8",
		});
	}, [ticker]);

	return (
		<Paper
			variant={"outlined"}
			style={{
				backgroundColor: "transparent",
				paddingTop: 0,
				paddingLeft: 12,
				paddingRight: 12,
				paddingBottom: 20,
			}}>
			<div id={"tradingview_5a0d8"}></div>
		</Paper>
	);
};

export default memo(Chart);
