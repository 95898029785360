import {
	GET_EXCHANGE_STARTED,
	GET_EXCHANGE_SUCCESS,
	GET_EXCHANGE_FAILURE,
  GET_INTERNAL_EXCHANGE_FAILURE,
  GET_INTERNAL_EXCHANGE_STARTED,
  GET_INTERNAL_EXCHANGE_SUCCESS,
  GET_INTERNAL_EXCHANGE_ID_FAILURE,
  GET_INTERNAL_EXCHANGE_ID_STARTED,
  GET_INTERNAL_EXCHANGE_ID_SUCCESS,
  SET_EXCHANGE_PARAMS,
	CLEAR_EXCHANGE,
} from '../types';

export default function exchange(
	state = {
		data: [],
		error: null,
		params: { page: 0, size: 10 },
		total: 0,
	},
	action
) {
	switch (action.type) {
		case GET_EXCHANGE_STARTED:
		case GET_INTERNAL_EXCHANGE_STARTED:
		case GET_INTERNAL_EXCHANGE_ID_STARTED:
			return { ...state, error: null };
		case GET_EXCHANGE_SUCCESS:
		case GET_INTERNAL_EXCHANGE_SUCCESS:
		case GET_INTERNAL_EXCHANGE_ID_SUCCESS:
			return { ...state, data: action.payload.data, total: action.payload.totalItems };
		case GET_EXCHANGE_FAILURE:
		case GET_INTERNAL_EXCHANGE_FAILURE:
		case GET_INTERNAL_EXCHANGE_ID_FAILURE:
			return { ...state, error: action.payload.error };
		case SET_EXCHANGE_PARAMS:
			return {...state, params: action.payload}
		case CLEAR_EXCHANGE:
			return { data: [], error: null, params: { page: 0, size: 10 }, total: 0 };
		default:
			return state;
	}
}
