import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import DoneIcon from "@material-ui/icons/Done";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import clsx from "clsx";
import { Button } from "./theme/components/Button";
import Notifier from "./utils/Notifier";
import { useDispatch } from "react-redux";
import Home from "./pages/Home";
import Loading from "./theme/components/Loading";
import { closeSnackbar } from "./app/actions/notistackActions";
import { Login } from "./pages/Login";
import { PrivateRoute } from "./utils/PrivateRoute";
import Users from "./pages/Users";
import Admins from "./pages/Admins";
import Roles from "./pages/Roles";
import Sessions from "./pages/Sessions";
import ActionLog from "./pages/ActionLog";
import PageWrapper from "./theme/components/global/PageWrapper";
import UserCard from "./pages/Users/UserCard";
import Profile from "./pages/Profile";
import Crypto from "./pages/Orders/CryptoOperations/Crypto";
import Coins from "./pages/Coins";
import NodeDetails from "./pages/Coins/NodeDetails";
import FiatOperations from "./pages/Orders/FiatOperations/FiatOperations";
import InternalExchange from "./pages/Orders/InternalExchange/InternalExchange";
import Styajka from "./pages/Orders/Styajka/Styajka";
import KunaBalances from "./pages/Transactions/KunaBalances";
import BinanceBalances from "./pages/Transactions/BinanceBalances";
import InternalBalances from "./pages/Transactions/InternalBalances";
import ExchangeSettings from "./pages/Settings/ExchangeSettings/ExchangeSettings";
import TransactionsSettings from "./pages/Settings/TransactionsSettings/TransactionsSettings";
import AdminCard from "./pages/Admins/AdminCard";
import AutoSyncBalances from "./pages/Transactions/AutoSyncBalance";
import UserBalance from "./pages/Transactions/UserBalance";
import Statistics from "./pages/SyncSettings/Statistics";
import WithdrawalHistory from "./pages/SyncSettings/WithdrawalHistory";
import SyncHistory from "./pages/SyncSettings/SyncHistory";
import OrderHistory from "./pages/SyncSettings/OrderHistory";
import Approve from "./pages/DHS/Approve";
import Devices from "./pages/DHS/Devices";
import HistoryProgram from "./pages/DHS/HistoryProgram";
import OwnersHistory from "./pages/DHS/OwnersHistory";
import DistributionHistory from "./pages/DHS/DistributionHistory";
import Shares from "./pages/DHS/Shares";
import Profit from "./pages/Report/Profit";
import KycHistory from "./pages/KYC/index";
import UserKycHistory from "./pages/KYC/UserKycHistory";
import Banking from "./pages/Banking/index";
import Funds from "./pages/Capital/Funds/index";
import Indexes from "./pages/Capital/Indexes/index";
import ReferralProgram from "./pages/ReferralProgram";
import Companies from "./pages/Companies";
import CompanyDetails from "./pages/Companies/CompanyDetails";
import ManageIndexAssets from "./pages/Capital/Indexes/ManageIndexAssets";
import CompanyBalanceDetails from "./pages/Companies/CompanyBalanceDetails";
import BankCardsList from "./pages/BankCards";
import BankCardDetails from "./pages/BankCards/BankCardDetails";
import BankingSettings from "./pages/Banking/BankingSettings";
import LiquidityProviders from "./pages/Banking/LiquidityProviders";
import PayoutBalanceHistory from "./pages/Capital/Funds/PayoutBalanceHistory";

const App = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	return (
		<SnackbarProvider
			action={(key) => (
				<Button
					onClick={() => dispatch(closeSnackbar(key))}
					style={{
						height: "100%",
						left: 0,
						position: "absolute",
						top: 0,
						width: "100%",
					}}
				/>
			)}
			iconVariant={{
				success: <DoneIcon />,
				error: <NotInterestedIcon />,
				warning: <ErrorOutlineOutlinedIcon />,
				info: <InfoOutlinedIcon />,
			}}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			classes={{
				variantSuccess: clsx(classes.root, classes.success),
				variantError: clsx(classes.root, classes.error),
				variantWarning: clsx(classes.root, classes.warning),
				variantInfo: clsx(classes.root, classes.info),
			}}>
			<Notifier />
			<BrowserRouter>
				<Loading />
				<Switch>
					<Route exact path='/login'>
						<Login />
					</Route>
					<PageWrapper>
						<PrivateRoute exact path='/'>
							<Home />
						</PrivateRoute>
						<PrivateRoute path='/users/list' exact>
							<Users />
						</PrivateRoute>
						<PrivateRoute path='/users/kyc_history' exact>
							<KycHistory />
						</PrivateRoute>
						<PrivateRoute path='/users/kyc_history/:id' exact>
							<UserKycHistory />
						</PrivateRoute>
						<PrivateRoute path='/users/bank_cards' exact>
							<BankCardsList />
						</PrivateRoute>
						<PrivateRoute path='/users/bank_cards/:id' exact>
							<BankCardDetails />
						</PrivateRoute>
						<PrivateRoute path='/admins/list' exact>
							<Admins />
						</PrivateRoute>
						<PrivateRoute path='/admins/list/:login/:id' exact>
							<AdminCard />
						</PrivateRoute>
						<PrivateRoute path='/admins/roles' exact>
							<Roles />
						</PrivateRoute>
						<PrivateRoute path='/admins/sessions' exact>
							<Sessions />
						</PrivateRoute>
						<PrivateRoute path='/admins/actionlog' exact>
							<ActionLog />
						</PrivateRoute>
						<PrivateRoute path='/users/list/:login/:id' exact>
							<UserCard />
						</PrivateRoute>
						<PrivateRoute path='/admins/roles' exact>
							<Roles />
						</PrivateRoute>
						<PrivateRoute path='/profile' exact>
							<Profile />
						</PrivateRoute>
						<PrivateRoute path='/orders/crypto-operations' exact>
							<Crypto />
						</PrivateRoute>
						<PrivateRoute path='/orders/fiat-operations' exact>
							<FiatOperations />
						</PrivateRoute>
						<PrivateRoute path='/orders/internal-exchange' exact>
							<InternalExchange />
						</PrivateRoute>
						<PrivateRoute path='/orders/styajka' exact>
							<Styajka />
						</PrivateRoute>
						<PrivateRoute path='/finances/kuna-balances' exact>
							<KunaBalances />
						</PrivateRoute>
						<PrivateRoute path='/finances/binance-balances' exact>
							<BinanceBalances />
						</PrivateRoute>
						<PrivateRoute path='/finances/internal-balance' exact>
							<InternalBalances />
						</PrivateRoute>
						<PrivateRoute path='/finances/user-balance' exact>
							<UserBalance />
						</PrivateRoute>
						<PrivateRoute path='/sync/auto-sync-balance' exact>
							<AutoSyncBalances />
						</PrivateRoute>
						<PrivateRoute path='/sync/withdrawal-history' exact>
							<WithdrawalHistory />
						</PrivateRoute>
						<PrivateRoute path='/sync/synchronization-history' exact>
							<SyncHistory />
						</PrivateRoute>
						<PrivateRoute path='/sync/order-history' exact>
							<OrderHistory />
						</PrivateRoute>
						<PrivateRoute path='/sync/statistics' exact>
							<Statistics />
						</PrivateRoute>
						<PrivateRoute path='/settings/exchange' exact>
							<ExchangeSettings />
						</PrivateRoute>
						<PrivateRoute path='/settings/transactions/:type' exact>
							<TransactionsSettings />
						</PrivateRoute>
						<PrivateRoute path='/monitoring/coins' exact>
							<Coins />
						</PrivateRoute>
						<PrivateRoute path='/monitoring/coins/:ticker' exact>
							<NodeDetails />
						</PrivateRoute>

						<PrivateRoute path='/dhs/devices' exact>
							<Devices />
						</PrivateRoute>
						<PrivateRoute path='/dhs/allocation-service' exact>
							{/* <AllocationService /> */}
						</PrivateRoute>
						<PrivateRoute path='/dhs/statistic' exact>
							{/* <Statistic /> */}
						</PrivateRoute>
						<PrivateRoute path='/dhs/approve' exact>
							<Approve />
						</PrivateRoute>
						<PrivateRoute path='/dhs/history-program' exact>
							<HistoryProgram />
						</PrivateRoute>
						<PrivateRoute path='/dhs/owners-history' exact>
							<OwnersHistory />
						</PrivateRoute>
						<PrivateRoute path='/dhs/distribution-history' exact>
							<DistributionHistory />
						</PrivateRoute>
						<PrivateRoute path='/dhs/shares' exact>
							<Shares />
						</PrivateRoute>
						<PrivateRoute path='/report/profit' exact>
							<Profit />
						</PrivateRoute>
						<PrivateRoute path='/banking/monitoring' exact>
							<Banking />
						</PrivateRoute>
						<PrivateRoute path='/banking/settings' exact>
							<BankingSettings />
						</PrivateRoute>
						<PrivateRoute path='/banking/liq-providers' exact>
							<LiquidityProviders />
						</PrivateRoute>
						<PrivateRoute path='/capital/funds' exact>
							<Funds />
						</PrivateRoute>
						<PrivateRoute path='/capital/funds/payout-balance-history/:balanceId' exact>
							<PayoutBalanceHistory />
						</PrivateRoute>
						<PrivateRoute path='/capital/indexes' exact>
							<Indexes />
						</PrivateRoute>
						<PrivateRoute path='/capital/indexes/:indexId/assets' exact>
							<ManageIndexAssets />
						</PrivateRoute>
						<PrivateRoute path='/referral-program' exact>
							<ReferralProgram />
						</PrivateRoute>
						<PrivateRoute path='/companies' exact>
							<Companies />
						</PrivateRoute>
						<PrivateRoute path='/companies/:companyId' exact>
							<CompanyDetails />
						</PrivateRoute>
						<PrivateRoute path='/companies/:companyId/balance/:balanceId' exact>
							<CompanyBalanceDetails />
						</PrivateRoute>
					</PageWrapper>
					<Redirect to={"/"} />
				</Switch>
			</BrowserRouter>
		</SnackbarProvider>
	);
};

export default App;

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 8,
		padding: "8px 16px",
		"&>*": {
			fontSize: 12,
			lineHeight: "16px",
			maxWidth: 220,
			fontWeight: 500,
		},
		"& svg": {
			fontSize: 24,
			marginRight: 11,
		},
	},
	success: {
		backgroundColor: "#36523E",
		"& svg": {
			color: "#5BB55D",
		},
	},
	error: {
		backgroundColor: "#5F3238",
		"& svg": {
			color: "#E44A4A",
		},
	},
	warning: {
		backgroundColor: "#655129",
		"& svg": {
			color: "#F8B017",
		},
	},
	info: {
		backgroundColor: "#21354C",
		"& svg": {
			color: "#18A0FB",
		},
	},
}));
