import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import {
	clearTransactions,
	setTransactionParams,
} from "../../app/actions/transactionsActions";
import Table from "../../theme/components/Table/Table";
import CopyWithAlert from "../../utils/CopyWithAlert";
import getStatusColor from "../../utils/functions/getStatusColor";
import {
	clearActiveFilters,
	setActiveFiltersPage,
	setActiveFiltersSize,
} from "../../app/actions/filterActions";

const columns = [
	{ key: "id", label: "ID operat." },
	{ key: "create", label: "Date & time" },
	{ key: "ticker", label: "Currency" },
	{ key: "amount", label: "Amount" },
	{ key: "address", label: "Address" },
	{ key: "blockhash", label: "blockhash" },
	{ key: "transactionStatus", label: "Status" },
];

const UsersTransactions = () => {
	const classes = useStyles();
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);
	const dispatch = useDispatch();
	const { data, params, total } = useSelector(
		({ transactions }) => transactions
	);

	useEffect(() => {
		return () => {
			dispatch(clearTransactions());
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
		dispatch(setTransactionParams({ ...params, page: newPage }));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
		dispatch(setTransactionParams({ ...params, size: target.value, page: 0 }));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((rowData, index) => (
							<tr key={index}>
								<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
									<td className={classes.hoverCopyTd}>{rowData?.id}</td>
								</CopyWithAlert>
								<td>
									{rowData?.create &&
										moment(rowData?.create).format("DD MMM YYYY HH:mm")}
								</td>
								<td>{rowData?.ticker}</td>
								<td>{rowData?.amount}</td>
								<CopyWithAlert
									text={rowData?.address + "" || ""}
									label={"Address"}>
									<td className={classes.hoverCopyTd}>{rowData?.address}</td>
								</CopyWithAlert>
								<CopyWithAlert
									text={rowData?.blockhash + "" || ""}
									label={"blockhash"}>
									<td className={classes.hoverCopyTd}>{rowData?.blockhash}</td>
								</CopyWithAlert>
								<td
									style={{
										color:
											rowData?.transactionStatus &&
											getStatusColor(rowData?.transactionStatus),
									}}>
									{rowData?.transactionStatus || "-"}
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 30, 50]}
				component='div'
				count={total}
				rowsPerPage={activeFilters.find(x => x.field === "size").value}
				page={activeFilters.find(x => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default UsersTransactions;

const useStyles = makeStyles(theme => ({
	head: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
		"&>.MuiTextField-root": {
			maxWidth: 256,
			[theme.breakpoints.down("sm")]: {
				marginTop: 24,
			},
		},
	},
	root: {
		marginTop: 24,
		width: "100%",
		overflowX: "auto",
		paddingBottom: 24,
	},
	table: {
		borderSpacing: 0,
		width: "100%",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		"&:first-child": {
			borderRadius: "10px 0 0 10px",
		},
		"&:last-child": {
			borderRadius: "0 10px 10px 0",
		},
	},
	hoverCopyTd: {
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.light,
		},
	},
}));
