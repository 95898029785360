import {
	GET_KYC_BY_ID,
	GET_USER_KYC_HISTORY,
	GET_DOCUMENT_FILE,
	MAKE_DECISION_USER_KYC,
	DELETE_USER_KYC_BLOCK,
	GET_KYC_HISTORY,
	CLEAR_KYC_HISTORY,
	CLEAR_KYC_USER_DETAILS,
	CLEAR_KYC_STATUS,
	CLEAR_KYC_ERROR,
} from "../types";
import api from "../api";
import { enqueueSnackbar } from "./notistackActions";
import { Message } from "../../utils/enum";
import { actionCreator } from "../utils";
import { getProfileById } from "./usersActions";
import { Buffer } from "buffer";

// GET_KYC_BY_ID
export const getKycByIdAction = actionCreator(GET_KYC_BY_ID);

export const getKycById = (kycId) => {
	return async (dispatch) => {
		dispatch(getKycByIdAction.started());
		api.kyc
			.getKycById(kycId)
			.then((res) => dispatch(getKycByIdAction.done(res.data)))
			.catch((error) => dispatch(getKycByIdAction.failed(error)));
	};
};

// GET_USER_KYC_HISTORY
export const getUserKycIdAction = actionCreator(GET_USER_KYC_HISTORY);

export const getUserKycId = (params) => {
	return async (dispatch) => {
		dispatch(getUserKycIdAction.started());
		api.kyc
			.getUserKycId(params)
			.then((res) => dispatch(getUserKycIdAction.done(res.data)))
			.catch((error) => dispatch(getUserKycIdAction.failed(error)));
	};
};

// GET_DOCUMENT_FILE
export const getDocumentFileAction = actionCreator(GET_DOCUMENT_FILE);

export const getDocumentFile = (id, type) => {
	return async (dispatch) => {
		dispatch(getUserKycIdAction.started());
		api.kyc
			.getDocumentFile(id)
			.then((res) => {
				dispatch(
					getDocumentFileAction.done({
						[type]: Buffer.from(res.data, "binary").toString("base64"),
					})
				);
			})
			.catch((error) => dispatch(getDocumentFileAction.failed(error)));
	};
};

// MAKE_DECISION_USER_KYC
export const makeDecisionUserKycAction = actionCreator(MAKE_DECISION_USER_KYC);

export const makeDecisionUserKyc = (data) => {
	return async (dispatch) => {
		dispatch(makeDecisionUserKycAction.started());
		api.kyc
			.makeDecisionUserKyc(data)
			.then((res) => {
				dispatch(makeDecisionUserKycAction.done(res.data));
				dispatch(getKycById(data.kycId));
			})
			.catch((error) => dispatch(makeDecisionUserKycAction.failed(error)));
	};
};

// GET_KYC_HISTORY
export const getKycHistoryAction = actionCreator(GET_KYC_HISTORY);

export const getKycHistory = (params) => {
	return async (dispatch) => {
		dispatch(getKycHistoryAction.started());
		api.kyc
			.getKycHistory(params)
			.then((res) => dispatch(getKycHistoryAction.done(res.data)))
			.catch((error) => dispatch(getKycHistoryAction.failed(error)));
	};
};

// DELETE_USER_KYC_BLOCK
export const deleteUserKycBlockAction = actionCreator(DELETE_USER_KYC_BLOCK);

export const deleteUserKycBlock = (userId) => {
	return async (dispatch) => {
		dispatch(deleteUserKycBlockAction.started());
		api.kyc
			.deleteUserKyc(userId)
			.then((res) => {
				dispatch(
					enqueueSnackbar({
						message: Message.DeleteData,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
				dispatch(getProfileById(userId));
				dispatch(deleteUserKycBlockAction.done(res.data));
			})
			.catch((error) => dispatch(deleteUserKycBlockAction.failed(error)));
	};
};

export const clearKycHistory = () => ({
	type: CLEAR_KYC_HISTORY,
});

export const clearKycUserDetails = () => ({
	type: CLEAR_KYC_USER_DETAILS,
});
export const clearKycStatus = () => ({
	type: CLEAR_KYC_STATUS,
});

export const clearKycError = () => ({
	type: CLEAR_KYC_ERROR,
});
