import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	TablePagination,
	Grid,
	makeStyles,
	lighten,
	Typography,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import {
	getProgramPendingConfirmation,
	getTotalShares,
	getServiceAccountBalances,
	calculateCurrentShares,
	programStart,
} from "../../../app/actions/dhsActions";
import { FILTER_TOTAL_SHARES } from "../../../app/types";
import PageTitle from "../../../theme/components/PageTitle";
import DeleteConfirmDialog from "../../../theme/components/dialogs/DeleteConfirmDialog";
import Table from "../../../theme/components/Table/Table";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import { Button } from "../../../theme/components/Button";
import { STATUS_PROGRAM } from "../../../utils/constants/statusProgramDHS";

const totalSharesColumns = [
	{ key: "ownerId", label: "Owner Id" },
	{ key: "shares", label: "Shares" },
];

const balancesColumns = [
	{ key: "ticker", label: "Ticker" },
	{ key: "availableBalance", label: "Available Balance" },
	{ key: "fullBalance", label: "Full Balance" },
];

const Approve = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const { totalSharesFilteredData, balances, statusProgram } = useSelector(
		({ dhs }) => dhs
	);
	const [params, setParams] = useState({ page: 0, size: 10 });
	const [search, setSearch] = useState("");

	useEffect(() => {
		dispatch(getProgramPendingConfirmation());
		dispatch(getTotalShares());
		dispatch(getServiceAccountBalances());
	}, [dispatch]);

	useEffect(() => {
		dispatch({ type: FILTER_TOTAL_SHARES, payload: search });
	}, [dispatch, search]);

	const handleApprove = ({ accept }) => {
		if (accept && statusProgram?.status === STATUS_PROGRAM.WAITING) {
			dispatch(programStart({ programId: statusProgram?.id }));
		}
		setShowConfirmDialog(false);
	};

	const handleCalculate = () => {
		dispatch(calculateCurrentShares());
	};
	const handleSearch = (search) => {
		setSearch(search);
	};
	const handleChangePage = (event, newPage) => {
		event.stopPropagation();
		setParams({ ...params, page: newPage });
	};

	const handleChangeSize = ({ target }) => {
		setParams({ ...params, size: target.value, page: 0 });
	};

	return (
		<>
			<DeleteConfirmDialog
				open={Boolean(showConfirmDialog)}
				title='Confirm decision'
				message={`Are you sure you want to start the distribution program?`}
				handleClose={handleApprove}
			/>

			<PageTitle
				title={"Approve"}
				onSearch={handleSearch}
				valueSearch={params?.search}
			/>
			{statusProgram?.status === STATUS_PROGRAM.PENDING ? (
				<Grid container spacing={3} justifyContent='flex-end'>
					<Grid item className={classes.infoBox}>
						<InfoIcon style={{ color: "rgb(0, 191, 255)", marginRight: 12 }} />
						<Typography
							variant={"body1"}
							align={"center"}
							style={{ color: "rgb(0, 191, 255)" }}>
							Distribution program is running now.
						</Typography>
					</Grid>
				</Grid>
			) : (
				<Grid container spacing={3} justifyContent='flex-end'>
					<Grid item>
						<Button
							className={classes.approveBtn}
							variant='contained'
							onClick={() => setShowConfirmDialog(true)}
							disabled={statusProgram?.status !== STATUS_PROGRAM.WAITING}>
							Approve
						</Button>
					</Grid>
					<Grid item>
						<Button
							variant='contained'
							color='primary'
							onClick={handleCalculate}>
							Calculate
						</Button>
					</Grid>
				</Grid>
			)}
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Table>
						<thead>
							<tr>
								{totalSharesColumns.map(({ key, label }) => (
									<th key={key} style={{ textAlign: "center" }}>
										{label}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{totalSharesFilteredData?.length > 0 ? (
								totalSharesFilteredData?.map((rowData, index) => (
									<tr key={index}>
										<CopyWithAlert
											text={rowData?.ownerId + "" || ""}
											label={"Owner Id"}>
											<td data='hover' style={{ textAlign: "center" }}>
												{rowData?.ownerId}
											</td>
										</CopyWithAlert>
										<td style={{ textAlign: "center" }}>{rowData?.shares}</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={8} align={"center"} height={100}>
										The list is empty...
									</td>
								</tr>
							)}
						</tbody>
					</Table>
					{totalSharesFilteredData?.length > 0 && (
						<TablePagination
							rowsPerPageOptions={[10, 20, 30, 50]}
							component='div'
							count={totalSharesFilteredData?.length || 0}
							rowsPerPage={params?.size || 10}
							page={params?.page || 0}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeSize}
							classes={{ root: classes.root, toolbar: classes.toolbar }}
						/>
					)}
				</Grid>
				<Grid item xs={12} sm={6}>
					<Table>
						<thead>
							<tr>
								{balancesColumns.map(({ key, label }) => (
									<th key={key} style={{ textAlign: "center" }}>
										{label}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{balances?.length > 0 ? (
								balances?.map((rowData, index) => (
									<tr key={index}>
										<CopyWithAlert
											text={rowData?.ticker + "" || ""}
											label={"Ticker"}>
											<td data='hover' style={{ textAlign: "center" }}>
												{rowData?.ticker}
											</td>
										</CopyWithAlert>
										<td style={{ textAlign: "center" }}>
											{rowData?.availableBalance}
										</td>
										<td style={{ textAlign: "center" }}>
											{rowData?.fullBalance}
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={8} align={"center"} height={100}>
										The list is empty...
									</td>
								</tr>
							)}
						</tbody>
					</Table>
				</Grid>
			</Grid>
		</>
	);
};

export default Approve;

const useStyles = makeStyles((theme) => ({
	infoBox: {
		display: "flex",
	},
	approveBtn: {
		backgroundColor: theme.palette.text.success,
		"&:hover": {
			backgroundColor: lighten(theme.palette.text.success, 0.4),
		},
	},
	root: {
		display: "flex",
		justifyContent: "center",
	},
	toolbar: {
		flex: "none",
	},
}));
