import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../theme/components/Table/Table";
import CopyWithAlert from "../../utils/CopyWithAlert";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../app/actions/filterActions";
import { getCompanyBalanceTransferInvoices } from "../../app/actions/companiesActions";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "companyId", label: "Company Id" },
	{ key: "targetAddress", label: "Target Address" },
	{ key: "companyBalanceId", label: "Company Balance Id" },
	{ key: "amount", label: "Amount" },
	{ key: "description", label: "Description" },
	{ key: "creationTime", label: "Creation Time" },
	{ key: "finishTime", label: "Finish Time" },
	{ key: "status", label: "Status" },
	{ key: "minConfirmationsToAccept", label: "Min. Confirm." },
	{ key: "confirmations", label: "Confirmations" },
];

const TransfersInvoices = () => {
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(
		({ companies }) => companies.companyBalanceTransferInvoices
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getCompanyBalanceTransferInvoices(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={12} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default TransfersInvoices;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.companyId + "" || ""} label={"Company Id"}>
					<td data='hover'>{rowData?.companyId}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.targetAddress + "" || ""}
					label={"Target Address"}>
					<td data='hover'>{rowData?.targetAddress}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.companyBalanceId + "" || ""}
					label={"Company Balance Id"}>
					<td data='hover'>{rowData?.companyBalanceId}</td>
				</CopyWithAlert>
				<td>{+rowData?.amount}</td>
				<td>{rowData?.description}</td>
				<td>
					{rowData?.creationTime && moment(rowData?.creationTime).format("DD MMM YYYY HH:mm")}
				</td>
				<td>
					{rowData?.finishTime && moment(rowData?.finishTime).format("DD MMM YYYY HH:mm")}
				</td>
				<td>
					{rowData?.status}
				</td>
				<td>
					{rowData?.minConfirmationsToAccept}
				</td>
				<td>
					{rowData?.confirmations}
				</td>
			</tr>
		</>
	);
}
