import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import DialogWrapper from "../../theme/components/dialogs/DialogWrapper";
import { Grid, Typography, makeStyles, MenuItem } from "@material-ui/core";
import { Input } from "../../theme/components/Input";
import { Button } from "../../theme/components/Button";
import { autoSyncBalancesValidator } from "../../utils/customValidators/autoSyncBalancesValidator";
import {
	updateSettingsSyncItem,
	createSettingsSyncItem,
} from "../../app/actions/syncActions";
import { SelectInput } from "../../theme/components/Select";

const initialForm = {
	currency: "",
	maxBalance: "",
	balanceLeftoverPercent: "",
	maxBalanceGrowthPercent: "",
	exchange: "",
};

const selectExchanges = [
	{
		label: "KUNA",
		value: "KUNA",
	},
	{
		label: "BINANCE",
		value: "BINANCE",
	},
];

const CreateSyncBalanceDialog = ({ open, handleClose, defaultData }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [form, setForm] = useState(initialForm);
	const [errors, setErrors] = useState({});
	const isEditMode = Boolean(defaultData);

	useEffect(() => {
		setForm({
			currency: defaultData?.currency || "",
			maxBalance: defaultData?.maxBalance || "",
			balanceLeftoverPercent: defaultData?.balanceLeftoverPercent || "",
			exchange: defaultData?.exchange || "",
			maxBalanceGrowthPercent: defaultData?.maxBalanceGrowthPercent,
		});
	}, [defaultData]);

	const validateForm = useCallback(() => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach(key => {
			errForm = {
				...errForm,
				[key]: autoSyncBalancesValidator(form[key], key),
			};
			errForm[key].invalid && (valid = false);
		});

		setErrors(errForm);
		return valid;
	}, [form]);

	const handleCloseDialog = useCallback(() => {
		setErrors({});
		setForm(initialForm);
		handleClose();
	}, [handleClose]);

	const handleChange = event => {
		event.preventDefault();
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
		handleInputValidation(event);
	};

	const handleCreate = useCallback(() => {
		if (!validateForm()) {
			return;
		}
		if (isEditMode) {
			const updateData = {
				id: defaultData.id,
				maxBalance: form.maxBalance,
				balanceLeftoverPercent: form.balanceLeftoverPercent,
				exchange: form.exchange,
				maxBalanceGrowthPercent: form.maxBalanceGrowthPercent,
			};
			// edit
			dispatch(updateSettingsSyncItem(updateData));
		} else {
			// create
			dispatch(createSettingsSyncItem(form));
		}

		handleCloseDialog();
	}, [
		validateForm,
		dispatch,
		handleCloseDialog,
		defaultData,
		isEditMode,
		form,
	]);

	const handleInputValidation = event => {
		const { value, name } = event.target;
		const errorField = autoSyncBalancesValidator(value, name);
		setErrors({
			...errors,
			[name]: errorField,
		});
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={handleCloseDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				{`${isEditMode ? "Edit" : "Create"} sync balance`}
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={6} className={classes.formCroup}>
					<Input
						type='text'
						error={errors?.currency?.invalid}
						label='Ticker'
						variant={"outlined"}
						name={"currency"}
						value={form?.currency || ""}
						onChange={handleChange}
						onBlur={handleInputValidation}
						helperText={errors?.currency?.errorMessage}
						disabled={isEditMode}
					/>
				</Grid>
				<Grid item xs={6} className={classes.formCroup}>
					<Input
						error={errors?.maxBalance?.invalid}
						label='Max Balance'
						variant={"outlined"}
						type={"number"}
						name={"maxBalance"}
						value={form?.maxBalance || ""}
						onChange={handleChange}
						onBlur={handleInputValidation}
						helperText={errors?.maxBalance?.errorMessage}
					/>
				</Grid>
				<Grid item xs={6} className={classes.formCroup}>
					<Input
						error={errors?.balanceLeftoverPercent?.invalid}
						label='Balance Leftover Percent, %'
						variant={"outlined"}
						type={"number"}
						name={"balanceLeftoverPercent"}
						value={form?.balanceLeftoverPercent || ""}
						onChange={handleChange}
						onBlur={handleInputValidation}
						helperText={errors?.balanceLeftoverPercent?.errorMessage}
					/>
				</Grid>
				<Grid item xs={6} className={classes.formCroup}>
					<Input
						error={errors?.maxBalanceGrowthPercent?.invalid}
						label='Max Balance Growth Percent, %'
						variant={"outlined"}
						type={"number"}
						name={"maxBalanceGrowthPercent"}
						value={form?.maxBalanceGrowthPercent || ""}
						onChange={handleChange}
						onBlur={handleInputValidation}
						helperText={errors?.maxBalanceGrowthPercent?.errorMessage}
					/>
				</Grid>

				<Grid item xs={6} className={classes.formCroup}>
					<SelectInput
						error={errors?.exchange?.invalid}
						label='Exchange'
						onChange={handleChange}
						value={form?.exchange || ""}
						name={"exchange"}
						onBlur={handleInputValidation}
						helperText={errors?.authorities?.errorMessage}>
						{selectExchanges.map((item, index) => (
							<MenuItem key={item.label} value={item.value}>
								{item.label}
							</MenuItem>
						))}
					</SelectInput>
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)", marginTop: 12 }}
							onClick={handleCreate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default CreateSyncBalanceDialog;

const useStyles = makeStyles(theme => ({
	formCroup: {
		display: "flex",
		flexDirection: "column",
	},
	errorField: {
		color: theme.palette.text.error,
		fontSize: 12,
		position: "absolute",
		marginTop: 56,
	},
	success: {
		color: theme.palette.text.success,
	},
	error: {
		color: theme.palette.text.error,
	},
}));
