import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import PageTitle from "../../../theme/components/PageTitle";
import Table from "../../../theme/components/Table/Table";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import { getHistoryUsedPrograms } from "../../../app/actions/dhsActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../app/actions/filterActions";
import getStatusColor from "../../../utils/functions/getStatusColor";
import ProgramDetailsDialog from "./ProgramDetailsDialog";

const filterFields = [
	{
		field: "creationTimeFrom",
		type: "date",
		title: "Create date from",
		placeholder: "Create date from",
	},
	{
		field: "creationTimeTo",
		type: "date",
		title: "Create date to",
		placeholder: "Create date to",
	},
	{
		field: "timeWhenProgramWasCompletedFrom",
		type: "date",
		title: "Completed date from",
		placeholder: "Completed date from",
	},
	{
		field: "timeWhenProgramWasCompletedTo",
		type: "date",
		title: "Completed date to",
		placeholder: "Completed date to",
	},
	{ field: "status", type: "text", title: "Status", placeholder: "Status" },
];

const columns = [
	{ key: "id", label: "Id" },
	{ key: "code", label: "Code" },
	{ key: "creationTime", label: "Created" },
	{ key: "shareRate", label: "Share rate" },
	{ key: "totalShares", label: "Total shares" },
	{ key: "totalUah", label: "Total uah" },
	{ key: "timeWhenProgramWasCompleted", label: "Distribution date" },
	{ key: "resultMessage", label: "Result message" },
	{ key: "status", label: "Status" },
];

const HistoryProgram = () => {
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(
		({ dhs }) => dhs?.historyUsedProgram
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	const [details, setDetails] = useState(null);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getHistoryUsedPrograms(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};
	const handleManage = data => {
		setDetails(data);
	};

	return (
		<>
			<ProgramDetailsDialog
				open={Boolean(details)}
				handleClose={() => {
					setDetails(null);
				}}
				data={details}
			/>
			<PageTitle title={"History Program"} filterFields={filterFields} />
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
								onManage={handleManage}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find(x => x.field === "size").value}
				page={activeFilters?.find(x => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default HistoryProgram;

function TableRow({ rowData, onManage }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.code + "" || ""} label={"Code"}>
					<td data='hover'>{rowData?.code}</td>
				</CopyWithAlert>
				<td>
					{rowData?.creationTime &&
						moment(rowData?.creationTime).format("DD MMM YYYY HH:mm")}
				</td>
				<td>{rowData?.shareRate}</td>
				<td>{rowData?.totalShares}</td>
				<td>{rowData?.totalUah}</td>
				<td>
					{rowData?.timeWhenProgramWasCompleted
						? moment(rowData?.timeWhenProgramWasCompleted).format(
								"DD MMM YYYY HH:mm"
						  )
						: "-"}
				</td>
				<td data='hover' onClick={() => onManage(rowData)}>
					{rowData?.resultMessage}
				</td>
				<CopyWithAlert text={rowData?.status + "" || ""} label={"Status"}>
					<td
						style={{
							color: rowData?.status && getStatusColor(rowData?.status),
						}}>
						{rowData?.status || "-"}
					</td>
				</CopyWithAlert>
			</tr>
		</>
	);
}
