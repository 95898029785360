import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import {
	Grid,
	MenuItem,
	makeStyles,
	Typography,
	TablePagination,
} from "@material-ui/core";
import AnyIcon from "@material-ui/icons/UnfoldMoreRounded";
import DownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import TopIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import { getCurrencies } from "../../app/actions/walletsActions";
import {
	setActiveFilter,
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../app/actions/filterActions";
import { getUsersBalancesByCoin } from "../../app/actions/financesActions";
import { SORT_TYPES, next } from "../../utils/constants/sortTypes.js";
import CopyWithAlert from "../../utils/CopyWithAlert";
import { SelectWithLabel } from "../../theme/components/SelectWithLabel";
import Table from "../../theme/components/Table/Table";

const UserBalance = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const data = useSelector(({ finances }) => finances.usersBalancesByCoin);
	const currencies = useSelector(({ wallets }) => wallets.currencies);
	const [selectedCoin, setSelectedCoin] = useState("");
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);
	const [sorting, setSorting] = useState({ sort: "balance,desc" });
	const [columns, setColumns] = useState([
		{
			key: "id",
			headerName: "User id",
			type: "string",
		},
		{
			key: "login",
			headerName: "Login",
			type: "string",
		},
		{
			key: "email",
			headerName: "Email",
			type: "string",
		},
		{
			key: "walletId",
			headerName: "Wallet Id",
			type: "string",
		},
		{
			key: "walletAddress",
			headerName: "Wallet address",
			type: "string",
		},
		{
			key: "available",
			headerName: "Available",
			sort: SORT_TYPES.none,
			type: "string",
		},
		{
			key: "usdtAmount",
			headerName: "USDT amount",
			sort: SORT_TYPES.none,
			type: "string",
		},
	]);

	const handleSorting = key => {
		const nextState = next(columns.find(s => s.key === key)?.sort);
		setSorting({ sort: `balance,${nextState}` });
		setColumns(
			columns.map(s =>
				s.key === key
					? { ...s, sort: nextState }
					: {
							...s,
							sort: s.sort ? SORT_TYPES.none : undefined,
					  }
			)
		);
	};

	useEffect(() => {
		dispatch(getCurrencies({ page: 0, size: 100 }));
		if (
			history?.location?.state?.from === "user detail" &&
			history?.location?.state?.params
		) {
			dispatch(setActiveFilter(JSON.parse(history?.location?.state?.params)));
		}
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch, history.location?.state]);

	useEffect(() => {
		if (currencies?.length > 0) {
			setSelectedCoin(currencies[0]?.ticker);
		}
	}, [currencies]);

	useEffect(() => {
		let filteringParams = { ...sorting };
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		!!selectedCoin &&
			dispatch(
				getUsersBalancesByCoin({ ...filteringParams, ticker: selectedCoin })
			);
	}, [dispatch, activeFilters, selectedCoin, sorting]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	const toUserCard = item => {
		history.push(`/users/list/${item?.login}/${item?.userId}`, {
			from: "/finances/user-balance",
			params: JSON.stringify(activeFilters),
			title: "User balance",
		});
	};
	return (
		<>
			<Grid container justifyContent='space-between'>
				<Grid item xs={2} sm={4}>
					<Typography variant={"h4"} color={"textPrimary"}>
						User balance
					</Typography>
				</Grid>
				<Grid item xs={2} sm={4}>
					<SelectWithLabel
						label={"Select coin"}
						value={selectedCoin}
						onChange={({ target }) => setSelectedCoin(target.value)}
						position={"right"}>
						{currencies.map((i, index) => (
							<MenuItem key={index} value={i?.ticker}>
								<div style={{ display: "flex", alignItems: "center" }}>
									<img
										src={`https://cryptoneed.com/icons/${i?.ticker}.svg`}
										alt={i?.ticker}
										width={28}
										height={28}
										style={{ marginRight: 12 }}
										loading={"lazy"}
									/>
									<p style={{ margin: 4 }}>{i?.ticker?.toUpperCase()}</p>
								</div>
							</MenuItem>
						))}
					</SelectWithLabel>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12} className={classes.tableWrap}>
					<Table>
						<thead>
							<tr>
								{columns.map(({ key, headerName, sort, colSpan, align }) => (
									<th
										key={key}
										className={clsx(classes.th, classes.paper, sort && "hover")}
										colSpan={colSpan}
										align={align || "left"}
										onClick={() => sort && handleSorting(key)}>
										{sort === SORT_TYPES.up && <TopIcon />}
										{sort === SORT_TYPES.none && <AnyIcon />}
										{sort === SORT_TYPES.down && <DownIcon />}
										{headerName}
									</th>
								))}
							</tr>
						</thead>
						<tbody style={{ position: "relative" }}>
							{data?.content?.length > 0 ? (
								data?.content?.map((row, index) => (
									<TableRow
										key={row?.id || index}
										rowData={row}
										toUserCard={toUserCard}
									/>
								))
							) : (
								<tr>
									<td colSpan={13} align={"center"} height={100}>
										The list is empty...
									</td>
								</tr>
							)}
						</tbody>
					</Table>
					<TablePagination
						rowsPerPageOptions={[10, 30, 50]}
						component='div'
						count={data?.totalElements || 0}
						rowsPerPage={activeFilters.find(x => x.field === "size").value}
						page={activeFilters.find(x => x.field === "page").value}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeSize}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default UserBalance;

function TableRow({ rowData, toUserCard }) {
	return (
		<>
			<tr></tr>
			<tr>
				<td data='hover' onClick={() => toUserCard(rowData)}>
					{rowData?.userId}
				</td>
				<td>{rowData?.login}</td>
				<CopyWithAlert text={rowData?.email + "" || ""} label={"Email"}>
					<td data='hover'>{rowData?.email}</td>
				</CopyWithAlert>
				<td>{rowData?.walletId}</td>
				<CopyWithAlert
					text={rowData?.walletAddress + "" || ""}
					label={"Wallet address"}>
					<td data='hover'>{rowData?.walletAddress}</td>
				</CopyWithAlert>
				<td>{+rowData?.available || 0}</td>
				<td>{+rowData?.usdtAmount || 0}</td>
			</tr>
		</>
	);
}

const useStyles = makeStyles(theme => ({
	titleWrap: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 16,
		width: "calc(100vw - 100px)",

		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	tableWrap: {
		marginTop: 24,
	},
}));
