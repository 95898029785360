import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory, useParams } from "react-router-dom";
import Table from "../../theme/components/Table/Table";
import CopyWithAlert from "../../utils/CopyWithAlert";
import { IconButton } from "@material-ui/core";
import {
	clearCompanyBalances,
	deleteCompanyBalance,
	getCompanyBalances,
} from "../../app/actions/companiesActions";
import DeleteConfirmDialog from "../../theme/components/dialogs/DeleteConfirmDialog";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "currency", label: "Currency" },
	{ key: "address", label: "Address" },
	{ key: "companyId", label: "Company Id" },
	{ key: "balance", label: "Balance" },
	{ key: "manage", label: "Manage" },
];

const CompanyBalances = () => {
	const classes = useStyles();
	const history = useHistory();
	const routeParams = useParams();
	const dispatch = useDispatch();

	const [showDeleteDialog, setShowDeleteDialog] = useState(false);
	const [selectedBalanceId, setSelectedBalanceId] = useState(null);
	const data = useSelector(({ companies }) => companies.companyBalances);
	const companyId = routeParams?.companyId;

	useEffect(() => {
		dispatch(getCompanyBalances(companyId));
		return () => {
			dispatch(clearCompanyBalances());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCloseDeleteModal = ({ accept }) => {
		if (accept && selectedBalanceId) {
			dispatch(
				deleteCompanyBalance({ balanceId: selectedBalanceId, companyId })
			);
		}
		setShowDeleteDialog(false);
		setSelectedBalanceId(null);
	};

	const handleViewBalance = (id) => () => {
		history.push(`/companies/${companyId}/balance/${id}`, {
			from: `/companies/${companyId}`,
		});
	};

	const handleDeleteClick = (id) => () => {
		setSelectedBalanceId(id);
		setShowDeleteDialog(true);
	};

	return (
		<>
			<DeleteConfirmDialog
				open={showDeleteDialog}
				title='Delete Company Balance'
				message='Are you sure you want to delete selected company balance?'
				handleClose={handleCloseDeleteModal}
			/>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }, index) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((rowData, index) => (
							<tr key={index}>
								<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
									<td className={classes.hoverCopyTd}>{rowData?.id}</td>
								</CopyWithAlert>
								<td>{rowData?.currency}</td>
								<td>{rowData?.address}</td>
								<td>{rowData?.companyId}</td>
								<td>{rowData?.balance}</td>
								<td width={"10%"}>
									<IconButton onClick={handleViewBalance(rowData?.id)}>
										<Visibility />
									</IconButton>
									<IconButton onClick={handleDeleteClick(rowData?.id)}>
										<DeleteIcon style={{ color: "#ff0000" }} />
									</IconButton>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</>
	);
};

export default CompanyBalances;

const useStyles = makeStyles((theme) => ({
	head: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
		"&>.MuiTextField-root": {
			maxWidth: 256,
			[theme.breakpoints.down("sm")]: {
				marginTop: 24,
			},
		},
	},
	root: {
		marginTop: 24,
		width: "100%",
		overflowX: "auto",
		paddingBottom: 24,
	},
	table: {
		borderSpacing: 0,
		width: "100%",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		"&:first-child": {
			borderRadius: "10px 0 0 10px",
		},
		"&:last-child": {
			borderRadius: "0 10px 10px 0",
		},
	},
	hoverCopyTd: {
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.light,
		},
	},
}));
