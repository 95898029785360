export function capitalValidator(name, value) {
	if (name === "privateAccess") {
		return {
			invalid: false,
			errorMessage: "",
		};
	}

	if (!value) {
		return {
			invalid: true,
			errorMessage: "This is a required field!",
		};
	}

	if (name === "name" && value.length > 20) {
		return {
			invalid: true,
			errorMessage: `Name cannot be more than 20 characters.`,
		};
	}

	if (name === "apy" && value < 1) {
		return {
			invalid: true,
			errorMessage: `Minimum APY amount is 1.`,
		};
	}
	if (name === "apy" && value.length > 3) {
		return {
			invalid: true,
			errorMessage: `Maximum APY amount is 999.`,
		};
	}

	if (name === "tokenName" && value.length > 3) {
		return {
			invalid: true,
			errorMessage: "The name is 3 characters long.",
		};
	}

	// default
	return {
		invalid: false,
		errorMessage: "",
	};
}
