import {
	FormControl,
	InputLabel,
	makeStyles,
	Select,
	FormHelperText,
} from "@material-ui/core";
import propTypes from "prop-types";

export const SelectWithLabel = ({
	children,
	label,
	error,
	helperText,
	position,
	...props
}) => {
	const cls = useStyles();

	return (
		<FormControl
			variant='outlined'
			className={cls.root}
			error={error}
			style={{ float: position || "left" }}
			id='select-required-label'>
			<InputLabel id='select-required-label'>{label}</InputLabel>
			<Select {...props} label={label} labelId='select-required-label'>
				{children}
			</Select>
			<FormHelperText>{helperText}</FormHelperText>
		</FormControl>
	);
};

SelectWithLabel.propTypes = {
	value: propTypes.any,
	onChange: propTypes.func.isRequired,
	label: propTypes.string.isRequired,
	style: propTypes.object,
};

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		maxWidth: "360px",
		[theme.breakpoints.down("md")]: {
			maxWidth: "100%",
		},
		"& label, & label.Mui-focused:not(.Mui-error)": {
			color: "#fff",
		},
		"& .MuiFormLabel-root.Mui-focused:not(.Mui-error)": {
			color: "#18A0FB",
		},
		"& .MuiInputLabel-root:not(.Mui-error)": {
			color: "#fff",
		},
		"& .MuiFormHelperText-root": {
			fontSize: 10,
			position: "absolute",
			marginTop: 56,
			fontWeight: 300,
			lineHeight: "100%",
		},
		"& .MuiOutlinedInput-root": {
			fontSize: 14,
			"&>input": {
				color: "#fff",
				borderRadius: 8,
				fontSize: 14,
				lineHeight: 16,
				[theme.breakpoints.down("xs")]: {
					lineHeight: 14,
					paddingTop: 12,
					paddingBottom: 12,
				},
				"&::placeholder": {
					opacity: 1,
					color: theme.palette.text.secondary,
				},
			},
			"& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
				borderRadius: 8,
				borderWidth: 1,
			},
			"&:not(.Mui-error) fieldset, &:not(.Mui-error) :hover fieldset, &:not(.Mui-error).Mui-focused fieldset":
				{
					borderColor: "#454B54",
				},
		},
	},
}));
