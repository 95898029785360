import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../../theme/components/Table/Table";
import getStatusColor from "../../../utils/functions/getStatusColor";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import { getBankingHistory } from "../../../app/actions/bankingActions";
import { BANKING_HISTORY_COLUMNS } from "../../../utils/constants/banking";

import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../app/actions/filterActions";

const HistoryBanking = () => {
	const dispatch = useDispatch();
	const { history } = useSelector(({ banking }) => banking);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getBankingHistory(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{BANKING_HISTORY_COLUMNS?.map(
							({ key, headerName, colSpan, align }) => (
								<th key={key} colSpan={colSpan} align={align || "left"}>
									{headerName}
								</th>
							)
						)}
					</tr>
				</thead>
				<tbody>
					{history?.content?.length > 0 ? (
						history?.content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={history?.totalElements || 0}
				rowsPerPage={activeFilters?.find(x => x.field === "size").value}
				page={activeFilters?.find(x => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default HistoryBanking;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.txId + "" || ""} label={"Transaction ID"}>
					<td data='hover'>{rowData?.txId}</td>
				</CopyWithAlert>

				<CopyWithAlert text={rowData?.userId + "" || ""} label={"User Id"}>
					<td data='hover'>{rowData?.userId}</td>
				</CopyWithAlert>

				<CopyWithAlert
					text={rowData?.paymentId + "" || ""}
					label={"Payment ID"}>
					<td data='hover'>{rowData?.paymentId}</td>
				</CopyWithAlert>
				<td>{+rowData?.requestedAmount}</td>
				<td>{+rowData?.executedAmount}</td>
				<td>
					{rowData?.creationTime &&
						moment(rowData?.creationTime).format("DD MMM YYYY HH:mm")}
				</td>
				<td>
					{rowData?.completeTime &&
						moment(rowData?.completeTime).format("DD MMM YYYY HH:mm")}
				</td>
				<td
					style={{
						color: rowData?.status && getStatusColor(rowData?.status),
					}}>
					{rowData?.status || "-"}
				</td>
			</tr>
		</>
	);
}
