import {
	CREATE_STABLE_INVESTMENT_FUND,
	CREATE_FLOATING_INVESTMENT_FUND,
	UPDATE_STABLE_INVESTMENT_FUND,
	UPDATE_FLOATING_INVESTMENT_FUND,
	DELETE_INVESTMENT_FUND,
	SEARCH_INVESTMENT_FUNDS,
	GET_TOKEN_PRICES,
	SEARCH_SELL_INVOICES,
	MAKE_DECISION_BY_INVOICE,
	SEARCH_FUND_OPERATIONS_HISTORY,
	SEARCH_USER_FUND_BALANCES,
	CLEAR_USER_FUND_BALANCES,
	GET_SYSTEM_BALANCES_FUNDS,
	CLEAR_FUNDS_SYSTEM_BALANCES,
	SEND_FROM_SYSTEM_BALANCE_TO_WALLET_BY_ADDRESS,
	SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_FUND_TO_WALLET,
	CLEAR_TRANSFERS_SYSTEM_BALANCE_FUND,
	DISTRIBUTE_DIVIDENDS,
	SEARCH_DIVIDENDS_DISTRIBUTION_PROGRAMS,
	SEARCH_DIVIDENDS,
	CLEAR_FUNDS_STATUS,
	CLEAR_FUNDS_ERROR,
	CLEAR_FUNDS_OPERATIONS_HISTORY,
	GET_AVAILABLE_VIP_FUNDS,
	GET_USER_ENABLED_PRIVATE_FUNDS,
	ENABLE_USER_PRIVATE_FUND,
	DELETE_USER_PRIVATE_FUND,
	CLEAR_USER_ENABLED_PRIVATE_FUNDS,
	GET_ESTIMATE_DIVIDENDS_INFO,
	CREATE_FUND_PAYOUT_BALANCE,
	GET_FUND_PAYOUT_BALANCE_BY_ID,
	GET_ALL_FUND_PAYOUT_BALANCES,
	TRANSFER_FROM_FUND_PAYOUT_BALANCE,
	GET_FUND_PAYOUT_BALANCE_HISTORY,
	CLEAR_FUND_PAYOUT_BALANCE_HISTORY,
} from "../types";
import api from "../api";
import { enqueueSnackbar } from "./notistackActions";
import { Message } from "../../utils/enum";
import { actionCreator } from "../utils";

//CREATE_STABLE_INVESTMENT_FUND
export const createStableInvestmentFundAction = actionCreator(
	CREATE_STABLE_INVESTMENT_FUND
);

export const createStableInvestmentFund = (data) => {
	return async (dispatch) => {
		dispatch(createStableInvestmentFundAction.started());
		api.capital.funds
			.createStableInvestmentFund(data)
			.then((res) => {
				dispatch(createStableInvestmentFundAction.done(res.data));
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) =>
				dispatch(createStableInvestmentFundAction.failed(error))
			);
	};
};

// CREATE_FLOATING_INVESTMENT_FUND
export const createFloatingInvestmentFundAction = actionCreator(
	CREATE_FLOATING_INVESTMENT_FUND
);

export const createFloatingInvestmentFund = (data) => {
	return async (dispatch) => {
		dispatch(createFloatingInvestmentFundAction.started());
		api.capital.funds
			.createFloatingInvestmentFund(data)
			.then((res) => {
				dispatch(createFloatingInvestmentFundAction.done(res.data));
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) =>
				dispatch(createFloatingInvestmentFundAction.failed(error))
			);
	};
};

//UPDATE_STABLE_INVESTMENT_FUND
export const updateStableInvestmentFundAction = actionCreator(
	UPDATE_STABLE_INVESTMENT_FUND
);

export const updateStableInvestmentFund = (data) => {
	return async (dispatch) => {
		dispatch(updateStableInvestmentFundAction.started());
		api.capital.funds
			.updateStableInvestmentFund(data)
			.then((res) => {
				dispatch(updateStableInvestmentFundAction.done(res));
				dispatch(searchInvestmentFunds());
				dispatch(
					enqueueSnackbar({
						message: Message.DataChanged,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) =>
				dispatch(updateStableInvestmentFundAction.failed(error))
			);
	};
};

// UPDATE_FLOATING_INVESTMENT_FUND
export const updateFloatingInvestmentFundAction = actionCreator(
	UPDATE_FLOATING_INVESTMENT_FUND
);

export const updateFloatingInvestmentFund = (data) => {
	return async (dispatch) => {
		dispatch(updateFloatingInvestmentFundAction.started());
		api.capital.funds
			.updateFloatingInvestmentFund(data)
			.then((res) => {
				dispatch(updateFloatingInvestmentFundAction.done(res.data));
				dispatch(searchInvestmentFunds());
				dispatch(
					enqueueSnackbar({
						message: Message.DataChanged,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) =>
				dispatch(updateFloatingInvestmentFundAction.failed(error))
			);
	};
};

// SEARCH_INVESTMENT_FUNDS
export const searchInvestmentFundsAction = actionCreator(
	SEARCH_INVESTMENT_FUNDS
);

export const searchInvestmentFunds = (params) => {
	return async (dispatch) => {
		dispatch(searchInvestmentFundsAction.started());
		api.capital.funds
			.searchInvestmentFunds(params)
			.then((res) => dispatch(searchInvestmentFundsAction.done(res.data)))
			.catch((error) => dispatch(searchInvestmentFundsAction.failed(error)));
	};
};

// DELETE_INVESTMENT_FUND
export const deleteInvestmentFundAction = actionCreator(DELETE_INVESTMENT_FUND);

export const deleteInvestmentFund = (fundId) => {
	return async (dispatch) => {
		dispatch(deleteInvestmentFundAction.started());
		api.capital.funds
			.deleteInvestmentFund(fundId)
			.then((res) => {
				dispatch(deleteInvestmentFundAction.done(res.data));
				dispatch(searchInvestmentFunds());
				dispatch(
					enqueueSnackbar({
						message: Message.DataDeleted,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => dispatch(deleteInvestmentFundAction.failed(error)));
	};
};

export const getAvailableVipFundsAction = actionCreator(
	GET_AVAILABLE_VIP_FUNDS
);

export const getAvailableVipFunds = () => {
	return async (dispatch) => {
		dispatch(getAvailableVipFundsAction.started());
		api.capital.funds
			.getAvailableVipFunds()
			.then((res) => dispatch(getAvailableVipFundsAction.done(res.data)))
			.catch((error) => dispatch(getAvailableVipFundsAction.failed(error)));
	};
};

export const getUserEnabledPrivateFundsAction = actionCreator(
	GET_USER_ENABLED_PRIVATE_FUNDS
);

export const getUserEnabledPrivateFunds = (userId) => {
	return async (dispatch) => {
		dispatch(getUserEnabledPrivateFundsAction.started());
		api.capital.privateUserFunds
			.getAllEnabledUserFunds(userId)
			.then((res) => {
				dispatch(getUserEnabledPrivateFundsAction.done(res.data))
			})
			.catch((error) =>
				dispatch(getUserEnabledPrivateFundsAction.failed(error))
			);
	};
};

export const enablePrivateFundForUserAction = actionCreator(
	ENABLE_USER_PRIVATE_FUND
);

export const enablePrivateFundForUser = ({ userId, fundId }) => {
	return async (dispatch) => {
		dispatch(enablePrivateFundForUserAction.started());
		api.capital.privateUserFunds
			.enablePrivateFundForUser({ userId, fundId })
			.then((res) => {
				dispatch(getUserEnabledPrivateFunds(userId))
				dispatch(enablePrivateFundForUserAction.done(res.data))
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => dispatch(enablePrivateFundForUserAction.failed(error)));
	};
};

export const deletePrivateFundForUserAction = actionCreator(
	DELETE_USER_PRIVATE_FUND
);

export const deletePrivateFundForUser = (userId) => {
	return async (dispatch) => {
		dispatch(deletePrivateFundForUserAction.started());
		api.capital.privateUserFunds
			.deletePrivateFundForUser(userId)
			.then((res) => {
				dispatch(getUserEnabledPrivateFunds(userId))
				dispatch(deletePrivateFundForUserAction.done(res.data))
				dispatch(
					enqueueSnackbar({
						message: Message.DeleteData,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => dispatch(deletePrivateFundForUserAction.failed(error)));
	};
};

// GET_TOKEN_PRICES
export const getTokenPricesAction = actionCreator(GET_TOKEN_PRICES);

export const getTokenPrices = (data) => {
	return async (dispatch) => {
		dispatch(getTokenPricesAction.started());
		api.capital.funds
			.getTokenPrices(data)
			.then((res) => dispatch(getTokenPricesAction.done(res.data)))
			.catch((error) => dispatch(getTokenPricesAction.failed(error)));
	};
};

// SEARCH_SELL_INVOICES
export const searchSellInvoicesAction = actionCreator(SEARCH_SELL_INVOICES);

export const searchSellInvoices = (data) => {
	return async (dispatch) => {
		dispatch(searchSellInvoicesAction.started());
		api.capital.funds
			.searchSellInvoices(data)
			.then((res) => dispatch(searchSellInvoicesAction.done(res.data)))
			.catch((error) => dispatch(searchSellInvoicesAction.failed(error)));
	};
};

// MAKE_DECISION_BY_INVOICE
export const makeDecisionByInvoiceAction = actionCreator(
	MAKE_DECISION_BY_INVOICE
);

export const makeDecisionByInvoice = ({ data, params }) => {
	return async (dispatch) => {
		dispatch(makeDecisionByInvoiceAction.started());
		api.capital.funds
			.makeDecisionByInvoice(data)
			.then((res) => {
				dispatch(makeDecisionByInvoiceAction.done(res.data));
				dispatch(searchSellInvoices(params));
			})
			.catch((error) => dispatch(makeDecisionByInvoiceAction.failed(error)));
	};
};

// SEARCH_USER_FUND_BALANCES
export const searchUserFundBalancesAction = actionCreator(
	SEARCH_USER_FUND_BALANCES
);

export const searchUserFundBalances = (params) => {
	return async (dispatch) => {
		dispatch(searchUserFundBalancesAction.started());
		api.capital.funds
			.searchUserFundBalances(params)
			.then((res) => dispatch(searchUserFundBalancesAction.done(res.data)))
			.catch((error) => dispatch(searchUserFundBalancesAction.failed(error)));
	};
};

// SEARCH_FUND_OPERATIONS_HISTORY
export const searchFundOperationsHistoryAction = actionCreator(
	SEARCH_FUND_OPERATIONS_HISTORY
);

export const searchFundOperationsHistory = (params) => {
	return async (dispatch) => {
		dispatch(searchFundOperationsHistoryAction.started());
		api.capital.funds
			.searchFundOperationsHistory(params)
			.then((res) => dispatch(searchFundOperationsHistoryAction.done(res.data)))
			.catch((error) =>
				dispatch(searchFundOperationsHistoryAction.failed(error))
			);
	};
};

// GET_SYSTEM_BALANCES_FUNDS
export const getSystemBalancesFundsAction = actionCreator(
	GET_SYSTEM_BALANCES_FUNDS
);

export const getSystemBalancesFunds = () => {
	return async (dispatch) => {
		dispatch(getSystemBalancesFundsAction.started());
		api.capital.funds
			.getSystemBalancesFunds()
			.then((res) => dispatch(getSystemBalancesFundsAction.done(res.data)))
			.catch((error) => dispatch(getSystemBalancesFundsAction.failed(error)));
	};
};

// SEND_FROM_SYSTEM_BALANCE_TO_WALLET_BY_ADDRESS
export const sendFromSystemBalanceToWalletByAddressAction = actionCreator(
	SEND_FROM_SYSTEM_BALANCE_TO_WALLET_BY_ADDRESS
);

export const sendFromSystemBalanceToWalletByAddress = (data) => {
	return async (dispatch) => {
		dispatch(sendFromSystemBalanceToWalletByAddressAction.started());
		api.capital.funds
			.sendFromSystemBalanceToWalletByAddress(data)
			.then((res) =>
				dispatch(sendFromSystemBalanceToWalletByAddressAction.done(res.data))
			)
			.catch((error) =>
				dispatch(sendFromSystemBalanceToWalletByAddressAction.failed(error))
			);
	};
};

// SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_FUND_TO_WALLET
export const searchForTransfersFromSystemBalanceToWalletAction = actionCreator(
	SEARCH_FOR_TRANSFERS_FROM_SYSTEM_BALANCE_FUND_TO_WALLET
);

export const searchForTransfersFromSystemBalanceToWallet = (params) => {
	return async (dispatch) => {
		dispatch(searchForTransfersFromSystemBalanceToWalletAction.started());
		api.capital.funds
			.searchForTransfersFromSystemBalanceToWallet(params)
			.then((res) =>
				dispatch(
					searchForTransfersFromSystemBalanceToWalletAction.done(res.data)
				)
			)
			.catch((error) =>
				dispatch(
					searchForTransfersFromSystemBalanceToWalletAction.failed(error)
				)
			);
	};
};

// DISTRIBUTE_DIVIDENDS
export const distributeDividendsAction = actionCreator(DISTRIBUTE_DIVIDENDS);

export const distributeDividends = (data) => {
	return async (dispatch) => {
		dispatch(distributeDividendsAction.started());
		api.capital.funds
			.distributeDividends(data)
			.then((res) => dispatch(distributeDividendsAction.done(res.data)))
			.catch((error) => dispatch(distributeDividendsAction.failed(error)));
	};
};

// SEARCH_DIVIDENDS_DISTRIBUTION_PROGRAMS
export const searchDividendsDistributionProgramsAction = actionCreator(
	SEARCH_DIVIDENDS_DISTRIBUTION_PROGRAMS
);

export const searchDividendsDistributionPrograms = (params) => {
	return async (dispatch) => {
		dispatch(searchDividendsDistributionProgramsAction.started());
		api.capital.funds
			.searchDividendsDistributionPrograms(params)
			.then((res) =>
				dispatch(searchDividendsDistributionProgramsAction.done(res.data))
			)
			.catch((error) =>
				dispatch(searchDividendsDistributionProgramsAction.failed(error))
			);
	};
};

// SEARCH_DIVIDENDS
export const searchDividendsAction = actionCreator(SEARCH_DIVIDENDS);

export const searchDividends = (params) => {
	return async (dispatch) => {
		dispatch(searchDividendsAction.started());
		api.capital.funds
			.searchDividends(params)
			.then((res) => dispatch(searchDividendsAction.done(res.data)))
			.catch((error) => dispatch(searchDividendsAction.failed(error)));
	};
};

// GET_SYSTEM_BALANCES_FUNDS
export const getEstimateDevidendsInfoAction = actionCreator(
	GET_ESTIMATE_DIVIDENDS_INFO
);

export const getEstimateDevidendsInfo = ({ fundId }) => {
	return async (dispatch) => {
		dispatch(getEstimateDevidendsInfoAction.started());
		api.capital.funds
			.getEstimateDividendsInfo(fundId)
			.then((res) => dispatch(getEstimateDevidendsInfoAction.done(res.data)))
			.catch((error) => dispatch(getEstimateDevidendsInfoAction.failed(error)));
	};
};

export const createFundPayoutBalanceAction = actionCreator(
	CREATE_FUND_PAYOUT_BALANCE
);

export const createFundPayoutBalance = (data) => {
	return async (dispatch) => {
		dispatch(createFundPayoutBalanceAction.started());
		api.capital.funds
			.createFundPayoutBalance(data)
			.then((res) => {
				dispatch(createFundPayoutBalanceAction.done(res.data));
				dispatch(getAllFundPayoutBalances());
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) =>
				dispatch(createFundPayoutBalanceAction.failed(error))
			);
	};
};

export const getFundPayoutBalanceByIdAction = actionCreator(
	GET_FUND_PAYOUT_BALANCE_BY_ID
);

export const getFundPayoutBalanceById = (balanceId) => {
	return async (dispatch) => {
		dispatch(getFundPayoutBalanceByIdAction.started());
		api.capital.funds
			.getFundPayoutBalanceById(balanceId)
			.then((res) => {
				dispatch(getFundPayoutBalanceByIdAction.done(res.data))
			})
			.catch((error) =>
				dispatch(getFundPayoutBalanceByIdAction.failed(error))
			);
	};
};

export const getAllFundPayoutBalancesAction = actionCreator(
	GET_ALL_FUND_PAYOUT_BALANCES
);

export const getAllFundPayoutBalances = () => {
	return async (dispatch) => {
		dispatch(getAllFundPayoutBalancesAction.started());
		api.capital.funds
			.getAllFundPayoutBalances()
			.then((res) => {
				dispatch(getAllFundPayoutBalancesAction.done(res.data))
			})
			.catch((error) =>
				dispatch(getAllFundPayoutBalancesAction.failed(error))
			);
	};
};

export const transferFromFundPayoutBalanceAction = actionCreator(
	TRANSFER_FROM_FUND_PAYOUT_BALANCE
);

export const transferFromFundPayoutBalance = (data) => {
	return async (dispatch) => {
		dispatch(transferFromFundPayoutBalanceAction.started());
		api.capital.funds
			.transferFromFundPayoutBalance(data)
			.then((res) => {
				dispatch(transferFromFundPayoutBalanceAction.done(res.data));
				dispatch(getAllFundPayoutBalances());
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) =>
				dispatch(transferFromFundPayoutBalanceAction.failed(error))
			);
	};
};

export const getFundPayoutBalanceHistoryAction = actionCreator(
	GET_FUND_PAYOUT_BALANCE_HISTORY
);

export const getFundPayoutBalanceHistory = (params) => {
	return async (dispatch) => {
		dispatch(getFundPayoutBalanceHistoryAction.started());
		api.capital.funds
			.getFundPayoutBalanceTransferHistory(params)
			.then((res) => {
				dispatch(getFundPayoutBalanceHistoryAction.done(res.data))
			})
			.catch((error) =>
				dispatch(getFundPayoutBalanceHistoryAction.failed(error))
			);
	};
};


export const clearFundsSystemBalances = () => ({
	type: CLEAR_FUNDS_SYSTEM_BALANCES,
});

export const clearUserFundBalances = () => ({
	type: CLEAR_USER_FUND_BALANCES,
});

export const clearTransfersSystemBalanceFund = () => ({
	type: CLEAR_TRANSFERS_SYSTEM_BALANCE_FUND,
});

export const clearFundOperationsHistory = () => ({
	type: CLEAR_FUNDS_OPERATIONS_HISTORY,
});

export const clearFundPayoutBalanceHistory = () => ({
	type: CLEAR_FUND_PAYOUT_BALANCE_HISTORY,
});

export const clearFundStatus = () => ({
	type: CLEAR_FUNDS_STATUS,
});

export const clearFundError = () => ({
	type: CLEAR_FUNDS_ERROR,
});

export const clearUserEnabledPrivateFunds = () => ({
	type: CLEAR_USER_ENABLED_PRIVATE_FUNDS,
});
