import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import Table from "../../../../theme/components/Table/Table";
import {
	// distributeDividends,
	searchDividendsDistributionPrograms,
} from "../../../../app/actions/fundsCapitalActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "token", label: "Token" },
	{ key: "asset", label: "Asset" },
	{ key: "walletAddress", label: "Wallet Address" },
	{ key: "balance", label: "Balance" },
];

const DistributionPrograms = () => {
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(
		({ funds }) => funds?.dividendsDistributionPrograms
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	// const content = [
	// 	{
	// 		id: 1,
	// 		walletAddress: "13e24r-4trg-gj4rtn-gnel-dn-nbprf",
	// 		token: "TEST",
	// 		asset: "usdt-trc20",
	// 		balance: 120000,
	// 	},
	// ];
	// const totalElements = 0;

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(searchDividendsDistributionPrograms(filteringParams));
	}, [dispatch, activeFilters]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default DistributionPrograms;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<td>{rowData?.id}</td>
				<td>{rowData?.token}</td>
				<td>{rowData?.asset}</td>
				<td>{rowData?.walletAddress}</td>
				<td>{+rowData?.balance}</td>
			</tr>
		</>
	);
}
