import {
	CLEAR_ADMINS,
	GET_ADMINS_FAILURE,
	GET_ADMINS_STARTED,
	GET_ADMINS_SUCCESS,
	SET_ADMINS_PARAMS,
	CREATE_ADMIN_FAILURE,
	CREATE_ADMIN_STARTED,
	CREATE_ADMIN_SUCCESS,
	EDIT_ADMIN_FAILURE,
	EDIT_ADMIN_STARTED,
	EDIT_ADMIN_SUCCESS,
} from "../types";
import api from "../api";
import { enqueueSnackbar } from "./notistackActions";
import { getBlockersById } from "./blockingActions";
import { getProfileById } from "./usersActions";

export const getAdminsStarted = () => ({
	type: GET_ADMINS_STARTED,
});

export const getAdminsSuccess = data => ({
	type: GET_ADMINS_SUCCESS,
	payload: data,
});

export const getAdminsFailure = error => ({
	type: GET_ADMINS_FAILURE,
	payload: {
		error,
	},
});

export const getAdmins = params => {
	return async dispatch => {
		dispatch(getAdminsStarted());
		api.users
			.getAdmins(params)
			.then(res => dispatch(getAdminsSuccess(res.data)))
			.catch(error => dispatch(getAdminsFailure(error)));
	};
};

export const setAdminsParams = payload => ({
	type: SET_ADMINS_PARAMS,
	payload,
});

export const clearAdmins = () => ({
	type: CLEAR_ADMINS,
});

export const deleteAdmin = (login, params) => {
	return async dispatch => {
		api.users.deleteUser(login).finally(() => {
			dispatch(getAdmins(params));
		});
	};
};

export const createAdminStarted = () => ({
	type: CREATE_ADMIN_STARTED,
});

export const createAdminSuccess = data => ({
	type: CREATE_ADMIN_SUCCESS,
	payload: {
		data,
	},
});
export const createAdminFailure = error => ({
	type: CREATE_ADMIN_FAILURE,
	payload: {
		error,
	},
});

export const createAdmin = data => {
	return async dispatch => {
		dispatch(createAdminStarted());
		api.users
			.createAdmin(data)
			.then(res => {
				dispatch(createAdminSuccess(res));
				dispatch(getAdmins({ page: 0, size: 10 }));
				dispatch(
					enqueueSnackbar({
						message: `${data.authorities[0].split("_")[1]} ${data.firstName} ${
							data.lastName
						}  successfully created`,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => {
				dispatch(createAdminFailure(error));
				dispatch(
					enqueueSnackbar({
						message: error.response.data.title,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "error",
							autoHideDuration: 3000,
						},
					})
				);
			});
	};
};

export const editAdminStarted = () => ({
	type: EDIT_ADMIN_STARTED,
});

export const editAdminSuccess = data => ({
	type: EDIT_ADMIN_SUCCESS,
	payload: {
		data,
	},
});

export const editAdminFailure = error => ({
	type: EDIT_ADMIN_FAILURE,
	payload: {
		error,
	},
});

export const editAdmin = data => {
	return async dispatch => {
		dispatch(editAdminStarted());
		api.users
			.editAdmin(data)
			.then(res => {
				dispatch(editAdminSuccess(res.data));
				dispatch(
					enqueueSnackbar({
						message: "Activity saved",
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
				if (data?.id) {
					dispatch(getBlockersById(data.id))
					dispatch(getProfileById(data.id))
				}
			})
			.catch(error => dispatch(editAdminFailure(error.response)));
	};
};
