import {
	GET_ACTION_LOG_LIST_FAILURE,
	GET_ACTION_LOG_LIST_STARTED,
	GET_ACTION_LOG_LIST_SUCCESS,
	GET_ACTION_LOG_BY_ID_FAILURE,
	GET_ACTION_LOG_BY_ID_STARTED,
	GET_ACTION_LOG_BY_ID_SUCCESS,
} from "../types";
import api from "../api";

export const getActionLogListStarted = () => ({
	type: GET_ACTION_LOG_LIST_STARTED,
});

export const getActionLogListSuccess = payload => ({
	type: GET_ACTION_LOG_LIST_SUCCESS,
	payload,
});

export const getActionLogListFailure = error => ({
	type: GET_ACTION_LOG_LIST_FAILURE,
	payload: {
		error,
	},
});

export const getActionLogList = params => {
	return async dispatch => {
		dispatch(getActionLogListStarted());
		api.actionLog
			.getList(params)
			.then(res => dispatch(getActionLogListSuccess(res.data)))
			.catch(error => dispatch(getActionLogListFailure(error)));
	};
};

export const getActionLogByIdStarted = () => ({
	type: GET_ACTION_LOG_BY_ID_STARTED,
});

export const getActionLogByIdSuccess = (data, total) => ({
	type: GET_ACTION_LOG_BY_ID_SUCCESS,
	payload: { data, total },
});

export const getActionLogByIdFailure = error => ({
	type: GET_ACTION_LOG_BY_ID_FAILURE,
	payload: {
		error,
	},
});

export const getActionLogById = params => {
	return async dispatch => {
		dispatch(getActionLogByIdStarted());
		api.actionLog
			.getById(params)
			.then(res =>
				dispatch(
					getActionLogByIdSuccess(
						res.data,
						Number(res.headers["x-total-count"])
					)
				)
			)
			.catch(error => dispatch(getActionLogByIdFailure(error)));
	};
};
