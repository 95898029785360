export function withdrawValidator(value, balance, minValue = 0) {
	if (!value) {
		return {
			invalid: true,
			errorMessage: "This is a required field!",
		};
	}

	if (Number(value) < Number(minValue)) {
		return {
			invalid: true,
			errorMessage: `The sum must be equal to or greater than ${minValue}`,
		};
	}

	if (Number(value) > Number(balance)) {
		return {
			invalid: true,
			errorMessage: "Not enough money.",
		};
	}

	if (Number(value) > Number(balance)) {
		return {
			invalid: true,
			errorMessage: `Sum must be equal to or less than ${balance}`,
		};
	}

	// default
	return {
		invalid: false,
		errorMessage: "",
	};
}
