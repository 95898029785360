import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import {
	makeStyles,
	Typography,
	Paper,
	IconButton,
	Grid,
	lighten,
} from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import {
	clearKycUserDetails,
	getDocumentFile,
	getKycById,
	makeDecisionUserKyc,
	deleteUserKycBlock,
} from "../../app/actions/kycActions";
import { Button } from "../../theme/components/Button";
import KycDecisionDialog from "./KycDecisionDialog";
import {
	KYC_DETAILS,
	PHOTO_TYPES,
	KYC_STATUS,
	COUNTRIES,
} from "../../utils/constants/kyc";
import getStatusColor from "../../utils/functions/getStatusColor";

const UserKycHistory = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { id } = useParams();
	const history = useHistory();
	const { userDetails, userDetailsFiles } = useSelector(({ kyc }) => kyc);
	const [params, setParams] = useState(null);
	const [decision, setDecision] = useState(null);
	const [state, setState] = useState({});

	useEffect(() => {
		setState(null);
		setParams({ ...history?.location?.state, from: "kyc_detail" });
		dispatch(getKycById(id));
	}, [dispatch, id, history?.location?.state]);

	const goToUserProfile = () => {
		history.push(`/users/list/${null}/${userDetails?.userId}`);
	};

	const createState = (item) => {
		let form = {};
		item.documents.forEach((i) => {
			switch (i.fileType) {
				case PHOTO_TYPES.SELFIE:
					setState({ ...state, [PHOTO_TYPES.SELFIE]: i });
					dispatch(getDocumentFile(i?.id, PHOTO_TYPES.SELFIE));
					return;
				case PHOTO_TYPES.DOCUMENT_AVERS:
					setState({ ...state, [PHOTO_TYPES.DOCUMENT_AVERS]: i });
					dispatch(getDocumentFile(i?.id, PHOTO_TYPES.DOCUMENT_AVERS));
					return;
				case PHOTO_TYPES.DOCUMENT_REVERS:
					setState({ ...state, [PHOTO_TYPES.DOCUMENT_REVERS]: i });
					dispatch(getDocumentFile(i?.id, PHOTO_TYPES.DOCUMENT_REVERS));
					return;
				default:
					return;
			}
		});
		setState(form);
	};

	useEffect(() => {
		if (!userDetails) {
			return;
		}
		createState(userDetails);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, userDetails]);

	useEffect(() => {
		return () => {
			dispatch(clearKycUserDetails());
			setState(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const handleDecision = (form) => {
		if (decision === "DELETED") {
			dispatch(deleteUserKycBlock(userDetails?.id));
			return;
		}
		let formData = { ...form, kycId: userDetails?.id, kycStatus: decision };
		dispatch(makeDecisionUserKyc(formData));
	};

	const handleCloseDialog = () => {
		setDecision(null);
	};

	return (
		<Grid container className={classes.root}>
			<KycDecisionDialog
				open={Boolean(decision)}
				handleClose={handleCloseDialog}
				data={userDetails}
				decision={decision}
				handleDecision={handleDecision}
			/>
			<Grid item className={classes.head}>
				<Paper
					elevation={0}
					className={classes.paper}
					onClick={() =>
						history.push("/users/kyc_history", {
							...params,
							from: "kyc_detail",
						})
					}>
					<IconButton className={classes.icon} size={"small"}>
						<BackIcon />
					</IconButton>
					<Typography variant={"subtitle2"} color={"textSecondary"}>
						Back to <span className={classes.link}>{params?.title}</span>
					</Typography>
				</Paper>
				<Typography variant={"h4"} color={"textPrimary"}>
					KYC history user
				</Typography>
			</Grid>
			<Grid item container spacing={5} justifyContent='center'>
				<Grid item xs={12} md={6} lg={4}>
					<Paper elevation={4} className={classes.box}>
						{userDetails &&
							KYC_DETAILS.map(
								(item, index) =>
									userDetails[item?.key] && (
										<Grid
											container
											key={index}
											spacing={2}
											alignItems={"center"}
											onClick={item.key === "userId" ? goToUserProfile : null}
											className={clsx(
												item.key === "userId" && classes.redirect
											)}>
											<Grid item xs={6}>
												<Typography variant={"h6"}>
													{`${item.title}: `}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography
													variant={"h6"}
													align='right'
													className={classes.typography}
													style={{
														color:
															item?.key === "status" &&
															getStatusColor(userDetails[item?.key]),
													}}>
													{item?.key === "countryCode"
														? COUNTRIES[userDetails.countryCode]
														: item.type === "date"
														? moment(userDetails[item?.key]).format(
																"DD MMM YYYY HH:mm"
														  )
														: userDetails[item?.key]}
												</Typography>
											</Grid>
										</Grid>
									)
							)}
					</Paper>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Paper elevation={4} className={classes.box}>
						<Typography
							variant={"h5"}
							color={"textPrimary"}
							align='center'
							style={{ marginBottom: 24 }}>
							Selfie
						</Typography>

						{userDetailsFiles[PHOTO_TYPES.SELFIE] != null && (
							<img
								alt='selfie'
								style={{ height: "auto", width: "100%" }}
								src={`data:image;base64,${
									userDetailsFiles[PHOTO_TYPES.SELFIE]
								}`}
							/>
						)}
					</Paper>
				</Grid>
				<Grid item xs={12} md={6} lg={4}>
					<Paper elevation={4} className={classes.box}>
						<Typography
							variant={"h5"}
							color={"textPrimary"}
							align='center'
							style={{ marginBottom: 24 }}>
							Front side of the document
						</Typography>
						{userDetailsFiles[PHOTO_TYPES.DOCUMENT_AVERS] != null && (
							<img
								alt='selfie'
								style={{ height: "auto", width: "100%" }}
								src={`data:image;base64,${
									userDetailsFiles[PHOTO_TYPES.DOCUMENT_AVERS]
								}`}
							/>
						)}
						{userDetailsFiles[PHOTO_TYPES.DOCUMENT_REVERS] && (
							<Typography
								variant={"h5"}
								color={"textPrimary"}
								align='center'
								style={{ marginBottom: 24, marginTop: 24 }}>
								Back side of document data page
							</Typography>
						)}
						{userDetailsFiles[PHOTO_TYPES.DOCUMENT_REVERS] && (
							<img
								alt='selfie'
								style={{ height: "auto", width: "100%" }}
								src={`data:image;base64,${
									userDetailsFiles[PHOTO_TYPES.DOCUMENT_REVERS]
								}`}
							/>
						)}
					</Paper>
				</Grid>
			</Grid>
			{userDetails?.status === KYC_STATUS.PENDING && (
				<Grid item container className={classes.btnWrap}>
					<Button
						variant='contained'
						color='primary'
						className={classes.declineBtn}
						onClick={() => setDecision(KYC_STATUS.DECLINED)}>
						Decline
					</Button>
					<Button
						className={classes.approveBtn}
						variant='contained'
						onClick={() => setDecision(KYC_STATUS.APPROVED)}>
						Approve
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

export default UserKycHistory;

const useStyles = makeStyles((theme) => ({
	head: {
		marginBottom: 16,
		"&>.MuiTextField-root": {
			maxWidth: 256,
			marginTop: 24,
			[theme.breakpoints.down("sm")]: {
				marginTop: 24,
			},
			"&>.MuiOutlinedInput-root>input": {
				padding: 10,
			},
		},
	},
	paper: {
		display: "flex",
		alignItems: "center",
		padding: 8,
		paddingRight: 12,
		borderRadius: 8,
		width: "max-content",
		marginBottom: 16,
		"&:hover": {
			cursor: "pointer",
		},
	},
	box: {
		width: "100%",
		height: "100%",
		padding: 16,
		borderRadius: 8,
	},
	typography: {
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	redirect: {
		color: theme.palette.primary.light,
		"&:hover": {
			color: lighten(theme.palette.primary.light, 0.4),
			cursor: "pointer",
		},
	},
	btnWrap: {
		marginTop: 40,
		justifyContent: "space-around",
	},
	declineBtn: {
		background: theme.palette.error.main,
		minWidth: 140,
		"&:hover": {
			background: lighten(theme.palette.error.main, 0.4),
		},
	},
	approveBtn: {
		background: theme.palette.success.main,
		minWidth: 140,
		"&:hover": {
			background: lighten(theme.palette.success.main, 0.4),
		},
	},
	icon: {
		color: theme.palette.primary.light,
		padding: 0,
		marginRight: 4,
		"& svg": {
			fontSize: 19,
		},
	},
}));
