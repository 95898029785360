import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TablePagination, makeStyles, IconButton } from "@material-ui/core";
import Table from "../../../../theme/components/Table/Table";
import DeleteConfirmDialog from "../../../../theme/components/dialogs/DeleteConfirmDialog";
import CopyWithAlert from "../../../../utils/CopyWithAlert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";
import {
	getIndexAllAssetsList,
	deleteIndexAsset
} from "../../../../app/actions/indexesCapitalActions";
import CreateAssetDialog from "./components/CreateAssetDialog";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "indexId", label: "Index Id" },
	{ key: "token", label: "token" },
	{ key: "balance", label: "Balance" },
	{ key: "usdRate", label: "USD Rate" },
	{ key: "usdBalance", label: "USD Balance" },
	{ key: "actions", label: "Actions", align: 'center' }
];

const Assets = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [showConfirmDialog, setShowConfirmDialog] = useState(null);
	const [showEditDialog, setShowEditDialog] = useState(null);
	const [params, setParams] = useState({ page: 0, size: 10 });

	const { content, totalElements } = useSelector(
		({ indexes }) => indexes.assetsList
	);

	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		console.log({filteringParams})
		setParams(filteringParams);
		dispatch(getIndexAllAssetsList(filteringParams));
	}, [activeFilters, dispatch]);

	const getInvoiceData = useCallback(() => {
		dispatch(getIndexAllAssetsList(params));
		// eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		getInvoiceData();
	}, [getInvoiceData]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	const handleDeleteAsset = ({ accept }) => {
		if (accept) {
			dispatch(deleteIndexAsset({ assetId: showConfirmDialog?.id }));
		}
		setShowConfirmDialog(null);
	};

	const goToUserProfile = (userId) => {
		history.push(`/users/list/${null}/${userId}`);
	};

	return (
		<>
			<DeleteConfirmDialog
				open={Boolean(showConfirmDialog)}
				title='Confirm decision'
				message={`Are you sure you want to delete selected additional asset - ${showConfirmDialog?.token}?`}
				handleClose={handleDeleteAsset}
			/>
			<CreateAssetDialog
				open={Boolean(showEditDialog)}
				handleClose={() => setShowEditDialog(null)}
				params={showEditDialog}
				data={showEditDialog}
			/>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label, align }) => (
							<th key={key} style={{ textAlign: `${align || "left"}` }}>
								{label}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
								onDelete={() => setShowConfirmDialog(rowData)}
								onEdit={() => setShowEditDialog(rowData)}
								goToUserProfile={goToUserProfile}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default Assets;

function TableRow({ rowData, goToUserProfile, onDelete, onEdit }) {
	const classes = useStyles();
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<td data='hover' onClick={() => goToUserProfile(rowData?.userId)}>
					{rowData?.indexId}
				</td>
				<CopyWithAlert text={rowData?.token + "" || ""} label={"Token"}>
					<td data='hover'>{rowData?.token}</td>
				</CopyWithAlert>
				<td>{+rowData?.balance}</td>
				<td>{+rowData?.usdRate}</td>
				<td>{+rowData?.usdBalance}</td>
				<td>
					<div className={classes.btnWrap}>
						<IconButton onClick={onEdit}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={onDelete}>
							<DeleteIcon style={{ color: "#ff0000" }} />
						</IconButton>
					</div>
				</td>
			</tr>
		</>
	);
}

export const useStyles = makeStyles((theme) => ({
	btnWrap: {
		display: "flex",
		justifyContent: "center",
		gap: 20
	},
	btn: {
		minWidth: 100,
		minHeight: 30,
		padding: "4px 12px",
	},
	tab: {
		paddingTop: theme.spacing(3),
	},
}));
