import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const KeyboardDate = ({ filter, onChange, error, helperText, form }) => {
	const classes = useStyles();
	const [selectedDate, setDate] = useState(null);
	const [inputValue, setInputValue] = useState(null);

	useEffect(() => {
		setInputValue(form[filter.field]);
	}, [form, filter]);

	const onDateChange = (date, value) => {
		setDate(date);
		setInputValue(value);
		onChange(filter.field, value);
	};

	return (
		<div className={classes.root}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<KeyboardDatePicker
					error={error}
					helperText={helperText}
					invalidDateMessage=''
					mask='____-__-__'
					format='YYYY-MM-DD'
					placeholder='YYYY-MM-DD'
					views={["year", "month", "date"]}
					label={filter?.title}
					name={filter?.field}
					autoOk={true}
					showTodayButton={true}
					value={selectedDate}
					inputValue={inputValue || null}
					onChange={onDateChange}
					inputVariant='outlined'
				/>
			</MuiPickersUtilsProvider>
		</div>
	);
};

export default KeyboardDate;

const useStyles = makeStyles(theme => ({
	root: {
		width: "100",
		"& .MuiFormControl-root": {
			width: "100%",
			"& label, & label>.Mui-focused:not(.Mui-error)": {
				color: "#fff",
			},
			"& .MuiFormLabel-root.Mui-focused:not(.Mui-error)": {
				color: "#18A0FB",
			},
			"& .Mui-error": {
				color: "#E44A4A",
			},
			"& .MuiFormHelperText-root": {
				fontSize: 10,
				position: "absolute",
				marginTop: 56,
				fontWeight: 300,
				lineHeight: "100%",
			},
			"& .MuiOutlinedInput-root:not(.Mui-error)": {
				"&>input": {
					color: "#fff",
					borderRadius: 8,
					fontSize: 14,
					lineHeight: 16,
					[theme.breakpoints.down("xs")]: {
						lineHeight: 14,
						paddingTop: 12,
						paddingBottom: 12,
					},
					"&::placeholder": {
						opacity: 1,
						color: theme.palette.text.secondary,
					},
				},
				"& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
					borderRadius: 8,
					borderWidth: 1,
				},
				"&:not(.Mui-error) fieldset, &:not(.Mui-error) :hover fieldset, &:not(.Mui-error).Mui-focused fieldset":
					{
						borderColor: "#454B54",
					},
			},
		},
	},
}));
