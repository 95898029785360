import {
	SEARCH_REFERRAL_BALANCES,
	SEARCH_CLAIM_HISTORY,
	SEARCH_REFERRAL_REWARDS,
	GET_DEFAULT_REFERRAL_SETTINGS,
	UPDATE_DEFAULT_REFERRAL_SETTINGS,
	GET_VIP_REFERRAL_SETTINGS,
	GET_VIP_REFERRAL_SETTINGS_BY_USER_ID,
	UPDATE_VIP_REFERRAL_SETTINGS,
	DELETE_VIP_REFERRAL_SETTINGS,
	CREATE_VIP_REFERRAL_SETTINGS,
	GET_REFERRALS,
	CHANGE_PARENT,
	CLEAR_REFERRALS_STATUS,
	CLEAR_REFERRALS_ERROR,
	GET_SELECTED_VIP_USER_REFERRAL_SETTINGS,
	CLEAR_SELECTED_VIP_USER_REFERRAL_SETTINGS,
} from "../types";

export default function referrals(
	state = {
		referralBalances: null,
		claimHistory: null,
		referralRewards: null,
		defaultReferralSettings: null,
		vipReferralSettings: null,
		selectedVipUserReferralSettings: {},
		referrals: null,
		status: false,
		error: null,
	},
	action
) {
	switch (action.type) {
		case SEARCH_REFERRAL_BALANCES.STARTED:
		case SEARCH_CLAIM_HISTORY.STARTED:
		case SEARCH_REFERRAL_REWARDS.STARTED:
		case GET_DEFAULT_REFERRAL_SETTINGS.STARTED:
		case UPDATE_DEFAULT_REFERRAL_SETTINGS.STARTED:
		case GET_VIP_REFERRAL_SETTINGS.STARTED:
		case GET_VIP_REFERRAL_SETTINGS_BY_USER_ID.STARTED:
		case UPDATE_VIP_REFERRAL_SETTINGS.STARTED:
		case DELETE_VIP_REFERRAL_SETTINGS.STARTED:
		case CREATE_VIP_REFERRAL_SETTINGS.STARTED:
		case GET_REFERRALS.STARTED:
		case CHANGE_PARENT.STARTED:
		case GET_SELECTED_VIP_USER_REFERRAL_SETTINGS.STARTED:
			return { ...state, status: false, error: null };
		case SEARCH_REFERRAL_BALANCES.FAILURE:
		case SEARCH_CLAIM_HISTORY.FAILURE:
		case SEARCH_REFERRAL_REWARDS.FAILURE:
		case GET_DEFAULT_REFERRAL_SETTINGS.FAILURE:
		case UPDATE_DEFAULT_REFERRAL_SETTINGS.FAILURE:
		case GET_VIP_REFERRAL_SETTINGS.FAILURE:
		case GET_VIP_REFERRAL_SETTINGS_BY_USER_ID.FAILURE:
		case UPDATE_VIP_REFERRAL_SETTINGS.FAILURE:
		case DELETE_VIP_REFERRAL_SETTINGS.FAILURE:
		case CREATE_VIP_REFERRAL_SETTINGS.FAILURE:
		case GET_REFERRALS.FAILURE:
		case CHANGE_PARENT.FAILURE:
		case GET_SELECTED_VIP_USER_REFERRAL_SETTINGS.FAILURE:
			return { ...state, status: false, error: action.payload.error };
		case UPDATE_VIP_REFERRAL_SETTINGS.SUCCESS:
		case DELETE_VIP_REFERRAL_SETTINGS.SUCCESS:
		case CREATE_VIP_REFERRAL_SETTINGS.SUCCESS:
		case CHANGE_PARENT.SUCCESS:
			return { ...state, status: true };
		case SEARCH_REFERRAL_BALANCES.SUCCESS:
			return {
				...state,
				referralBalances: action.payload,
			};
		case SEARCH_CLAIM_HISTORY.SUCCESS:
			return {
				...state,
				claimHistory: action.payload,
			};
		case SEARCH_REFERRAL_REWARDS.SUCCESS:
			return {
				...state,
				referralRewards: action.payload,
			};
		case UPDATE_DEFAULT_REFERRAL_SETTINGS.SUCCESS:
			return {
				...state,
				status: true,
				defaultReferralSettings: action.payload,
			};
		case GET_DEFAULT_REFERRAL_SETTINGS.SUCCESS:
			return {
				...state,
				defaultReferralSettings: action.payload,
			};
		case GET_VIP_REFERRAL_SETTINGS.SUCCESS:
			return {
				...state,
				vipReferralSettings: action.payload,
			};
		case GET_REFERRALS.SUCCESS:
			return {
				...state,
				referrals: action.payload,
			};
		case GET_SELECTED_VIP_USER_REFERRAL_SETTINGS.SUCCESS:
			return {
				...state,
				selectedVipUserReferralSettings: action.payload,
			}

		case CLEAR_REFERRALS_STATUS:
			return {
				...state,
				status: false,
				error: null,
			};
		case CLEAR_REFERRALS_ERROR:
			return {
				...state,
				error: null,
				status: false,
			};
		case CLEAR_SELECTED_VIP_USER_REFERRAL_SETTINGS:
			return {
				...state,
				selectedVipUserReferralSettings: {},
			};
		default:
			return state;
	}
}
