import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import PageTitle from "../../../theme/components/PageTitle";
import Table from "../../../theme/components/Table/Table";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import { getDistributionHistory } from "../../../app/actions/dhsActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../app/actions/filterActions";

const filterFields = [
	{
		field: "id",
		type: "number",
		title: "Distribution ID",
		placeholder: "Distribution ID",
	},
	{
		field: "userId",
		type: "text",
		title: "User Id",
		placeholder: "User Id",
	},
	{
		field: "distributionDateFrom",
		type: "date",
		title: "Distribution date from",
		placeholder: "Distribution date from",
	},
	{
		field: "distributionDateTo",
		type: "date",
		title: "Distribution date to",
		placeholder: "Distribution date to",
	},
];

const columns = [
	{ key: "id", label: "Id" },
	{ key: "distributionDate", label: "Distribution Date" },
	{ key: "userId", label: "User Id" },
	{ key: "userShares", label: "User shares" },
	{ key: "shareRate", label: "Share rate" },
	{ key: "totalShares", label: "Total shares" },
	{ key: "totalUah", label: "Total uah" },
	{ key: "uahReward", label: "Uah Reward" },
];

const DistributionHistory = () => {
	const dispatch = useDispatch();

	const { content, totalElements } = useSelector(({ dhs }) => dhs?.history);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);
	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getDistributionHistory(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<PageTitle title={"Distribution history"} filterFields={filterFields} />
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find(x => x.field === "size").value}
				page={activeFilters?.find(x => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default DistributionHistory;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<td>
					{rowData?.distributionDate &&
						moment(rowData?.distributionDate).format("DD MMM YYYY HH:mm")}
				</td>
				<CopyWithAlert text={rowData?.userId + "" || ""} label={"User Id"}>
					<td data='hover'>{rowData?.userId}</td>
				</CopyWithAlert>
				<td>{+rowData?.userShares}</td>
				<td>{+rowData?.shareRate}</td>
				<td>{+rowData?.totalShares}</td>
				<td>{+rowData?.totalUah}</td>
				<td>{+rowData?.uahReward}</td>
			</tr>
		</>
	);
}
