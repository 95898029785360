import {
	CLEAR_USERS,
	GET_USERS_FAILURE,
	GET_USERS_STARTED,
	GET_USERS_SUCCESS,
	GET_PENDING_ACTIVATION_USERS_FAILURE,
	GET_PENDING_ACTIVATION_USERS_STARTED,
	GET_PENDING_ACTIVATION_USERS_SUCCESS,
	GET_ROLES_SUCCESS,
	GET_PROFILE_BY_ID_SUCCESS,
	SET_USERS_PARAMS,
} from "../types";

export default function users(
	state = {
		data: [],
		pendingActivationUsers: [],
		roles: [],
		error: null,
		profile: null,
		params: null,
		total: 0,
	},
	action
) {
	switch (action.type) {
		case GET_USERS_STARTED:
		case GET_PENDING_ACTIVATION_USERS_STARTED:
			return { ...state, error: null };
		case GET_USERS_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				total: action.payload.totalItems,
			};
		case GET_PENDING_ACTIVATION_USERS_SUCCESS:
			return {
				...state,
				pendingActivationUsers: action.payload,
			};
		case GET_USERS_FAILURE:
		case GET_PENDING_ACTIVATION_USERS_FAILURE:
			return {
				...state,
				error: action.payload.error,
			};
		case GET_PROFILE_BY_ID_SUCCESS:
			return { ...state, profile: action.payload.data[0] };
		case GET_ROLES_SUCCESS:
			return { ...state, roles: action.payload.data[0] };
		case SET_USERS_PARAMS:
			return { ...state, params: action.payload };
		case CLEAR_USERS:
			return { data: [], error: null, params: null, total: 0 };
		default:
			return state;
	}
}
