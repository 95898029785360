import {
	GET_KYC_BY_ID,
	GET_USER_KYC_HISTORY,
	GET_DOCUMENT_FILE,
	MAKE_DECISION_USER_KYC,
	DELETE_USER_KYC_BLOCK,
	GET_KYC_HISTORY,
	CLEAR_KYC_HISTORY,
	CLEAR_KYC_USER_DETAILS,
	CLEAR_KYC_STATUS,
	CLEAR_KYC_ERROR,
} from "../types";
import { PHOTO_TYPES } from "../../utils/constants/kyc";

export default function kyc(
	state = {
		data: null,
		userDetails: null,
		userDetailsFiles: {
			[PHOTO_TYPES.SELFIE]: null,
			[PHOTO_TYPES.DOCUMENT_AVERS]: null,
			[PHOTO_TYPES.DOCUMENT_REVERS]: null,
		},
		userHistory: null,
		status: false,
		error: null,
		total: 0,
	},
	action
) {
	switch (action.type) {
		case GET_KYC_BY_ID.STARTED:
		case GET_USER_KYC_HISTORY.STARTED:
		case GET_KYC_HISTORY.STARTED:
		case GET_DOCUMENT_FILE.STARTED:
			return { ...state, error: null };
		case DELETE_USER_KYC_BLOCK.STARTED:
		case MAKE_DECISION_USER_KYC.STARTED:
			return { ...state, status: true, error: null };
		case GET_KYC_BY_ID.FAILURE:
		case GET_USER_KYC_HISTORY.FAILURE:
		case GET_KYC_HISTORY.FAILURE:
		case DELETE_USER_KYC_BLOCK.FAILURE:
		case MAKE_DECISION_USER_KYC.FAILURE:
		case GET_DOCUMENT_FILE.FAILURE:
			return { ...state, status: false, error: action.payload.error };
		case GET_DOCUMENT_FILE.SUCCESS:
			return {
				...state,
				userDetailsFiles: { ...state.userDetailsFiles, ...action.payload },
			};
		case GET_KYC_BY_ID.SUCCESS:
			return {
				...state,
				userDetails: {
					...action.payload,
					dob: `${
						String(action.payload?.dayOfBirth).length === 1
							? "0" + action.payload?.dayOfBirth
							: action.payload?.dayOfBirth
					}.${
						String(action.payload?.monthOfBirth).length === 1
							? "0" + action.payload?.monthOfBirth
							: action.payload?.monthOfBirth
					}.${action.payload?.yearOfBirth}`,
				},
			};
		case GET_KYC_HISTORY.SUCCESS:
			return {
				...state,
				data: action.payload?.content.map((i) => {
					return {
						...i,
						dob: `${
							String(i.dayOfBirth).length === 1
								? "0" + i.dayOfBirth
								: i.dayOfBirth
						}.${
							String(i.monthOfBirth).length === 1
								? "0" + i.monthOfBirth
								: i.monthOfBirth
						}.${i.yearOfBirth}`,
					};
				}),
				total: action.payload?.totalElements,
			};
		case GET_USER_KYC_HISTORY.SUCCESS:
			return {
				...state,
				userHistory: action.payload,
			};
		case MAKE_DECISION_USER_KYC.SUCCESS:
			return {
				...state,
				status: true,
			};
		case DELETE_USER_KYC_BLOCK.SUCCESS:
			return {
				...state,
				decision: action.payload,
				status: false,
			};
		case CLEAR_KYC_HISTORY.SUCCESS:
			return {
				...state,
				data: null,
			};
		case CLEAR_KYC_USER_DETAILS:
			return {
				...state,
				userDetails: null,
				userDetailsFiles: {
					[PHOTO_TYPES.SELFIE]: null,
					[PHOTO_TYPES.DOCUMENT_AVERS]: null,
					[PHOTO_TYPES.DOCUMENT_REVERS]: null,
				},
			};
		case CLEAR_KYC_STATUS:
			return {
				...state,
				status: false,
			};
		case CLEAR_KYC_ERROR:
			return {
				...state,
				error: null,
				status: false,
			};
		default:
			return state;
	}
}
