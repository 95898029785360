export const DEFAULT_REFERRAL_SETTINGS_COLUMNS = [
	{ key: "id", headerName: "ID", align: "center" },
	{
		key: "parentRewardPercent",
		headerName: "Parent Reward Percent",
		align: "center",
	},
	{
		key: "grandParentRewardPercent",
		headerName: "Grand Parent Reward Percent",
		align: "center",
	},
	{ key: "manage", headerName: "", align: "center" },
];

export const DEFAULT_VIP_USER_REFERRAL_SETTINGS_COLUMNS = [
	{ key: "id", headerName: "ID", align: "center" },
	{
		key: "parentRewardPercent",
		headerName: "Parent Reward Percent",
		align: "center",
	},
	{
		key: "grandParentRewardPercent",
		headerName: "Grand Parent Reward Percent",
		align: "center",
	},
	{ key: "manage", headerName: "Manage", align: "center" },
];

export const VIP_REFERRAL_SETTINGS_COLUMNS = [
	{ key: "id", headerName: "ID", align: "center" },
	{ key: "userId", headerName: "User ID", align: "center" },
	{
		key: "parentRewardPercent",
		headerName: "Parent Reward Percent",
		align: "center",
	},
	{
		key: "grandParentRewardPercent",
		headerName: "Grand Parent Reward Percent",
		align: "center",
	},
	{ key: "manage", headerName: "Manage" },
];

export const CLAIM_HISTORY_COLUMNS = [
	{ key: "id", headerName: "ID" },
	{ key: "txId", headerName: "Transaction ID" },
	{ key: "userId", headerName: "User ID" },
	{ key: "asset", headerName: "Asset" },
	{ key: "amount", headerName: "Amount" },
	{ key: "time", headerName: "Creation Time" },
];

export const REFERRAL_REWARDS_COLUMNS = [
	{ key: "id", headerName: "ID" },
	{ key: "referralId", headerName: "Referral ID" },
	{ key: "referralOwnerId", headerName: "Referral Owner ID" },
	{ key: "asset", headerName: "Asset" },
	{ key: "reward", headerName: "Reward" },
	{ key: "source", headerName: "Source" },
	{ key: "time", headerName: "Creation Time" },
	{ key: "status", headerName: "Status" },
];

export const REFERRALS_COLUMNS = [
	{ key: "id", headerName: "ID" },
	{ key: "userId", headerName: "User ID" },
	{ key: "parentId", headerName: "Parent ID" },
	{ key: "manage", headerName: "Manage" },
];

export const REFERRAL_BALANCES_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "Id",
		placeholder: "Id",
	},
	{
		field: "userId",
		type: "text",
		title: "User Id",
		placeholder: "User Id",
	},
	{
		field: "asset",
		type: "text",
		title: "Asset",
		placeholder: "Asset",
	},
];

export const CLAIM_HISTORY_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "Id",
		placeholder: "Id",
	},
	{
		field: "userId",
		type: "text",
		title: "User Id",
		placeholder: "User Id",
	},
	{
		field: "asset",
		type: "text",
		title: "Asset",
		placeholder: "Asset",
	},
	{
		field: "txId",
		type: "text",
		title: "Transaction ID",
		placeholder: "Transaction ID",
	},
	{
		field: "timeFrom",
		type: "date",
		title: "Date from",
		placeholder: "Date from",
	},
	{
		field: "timeTo",
		type: "date",
		title: "Date to",
		placeholder: "Date to",
	},
];

export const SOURCE_REFERRAL_REWARDS_FILTER = [
	{ label: "ALL", value: "" },
	{ label: "EXCHANGE", value: "EXCHANGE" },
	{ label: "FUND", value: "FUND" },
];

export const STATUS_REFERRAL_REWARDS_FILTER = [
	{ label: "ALL", value: "" },
	{ label: "PENDING", value: "PENDING" },
	{ label: "COMPLETED", value: "COMPLETED" },
];

export const REFERRAL_REWARDS_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "Id",
		placeholder: "Id",
	},
	{
		field: "referralId",
		type: "text",
		title: "Referral ID",
		placeholder: "Referral ID",
	},

	{
		field: "referralOwnerId",
		type: "text",
		title: "Referral Owner ID",
		placeholder: "Referral Owner ID",
	},
	{
		field: "asset",
		type: "text",
		title: "Asset",
		placeholder: "Asset",
	},
	{
		field: "source",
		type: "select",
		title: "Source",
		placeholder: "Source",
		data: SOURCE_REFERRAL_REWARDS_FILTER,
	},
	{
		field: "status",
		type: "select",
		title: "Status",
		placeholder: "Status",
		data: STATUS_REFERRAL_REWARDS_FILTER,
	},
	{
		field: "timeFrom",
		type: "date",
		title: "Date from",
		placeholder: "Date from",
	},
	{
		field: "timeTo",
		type: "date",
		title: "Date to",
		placeholder: "Date to",
	},
];

export const REFERRALS_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "Id",
		placeholder: "Id",
	},
	{
		field: "userId",
		type: "text",
		title: "User Id",
		placeholder: "User Id",
	},
	{
		field: "referralId",
		type: "text",
		title: "Referral ID",
		placeholder: "Referral ID",
	},
];
