import {
	GET_STATISTIC,
	GET_NET_PROFIT,
	CLEAR_STATISTIC_STATUS,
	CLEAR_STATISTIC_ERROR,
} from "../types";
import api from "../api";
import { actionCreator } from "../utils";

// GET_STATISTIC
export const getStatisticAction = actionCreator(GET_STATISTIC);

export const getStatistic = ticker => {
	return async dispatch => {
		dispatch(getStatisticAction.started());
		api.statistic
			.getStatistic(ticker)
			.then(res => dispatch(getStatisticAction.done(res.data)))
			.catch(error => dispatch(getStatisticAction.failed(error)));
	};
};

// GET_NET_PROFIT
export const getNetProfitAction = actionCreator(GET_NET_PROFIT);

export const getNetProfit = () => {
	return async dispatch => {
		dispatch(getNetProfitAction.started());
		api.statistic
			.getNetProfit()
			.then(res => dispatch(getNetProfitAction.done(res.data)))
			.catch(error => dispatch(getNetProfitAction.failed(error)));
	};
};

export const clearStatisticStatus = () => ({
	type: CLEAR_STATISTIC_STATUS,
});

export const clearStatisticError = () => ({
	type: CLEAR_STATISTIC_ERROR,
});
