import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

export const MuiTheme = createTheme({
	palette: {
		type: "dark",
		primary: {
			main: "#14528E",
			light: "#18A0FB",
			dark: "rgba(24, 160, 251, 0.1)",
		},
		secondary: {
			main: "#262830",
		},
		text: {
			primary: "#ffffff",
			secondary: "#929397",
			success: "#60CF61",
			warning: "#ff9800",
			error: "#E44A4A",
			info: "rgb(77, 232, 232)",
		},
		background: {
			paper: "#262830",
			default: "#181A1E",
		},
		success: {
			main: "#60CF61",
		},
		error: {
			main: "#E44A4A",
		},
		action: {
			active: "#18A0FB",
			disabled: "#929397",
		},
	},
	shape: {
		borderRadius: 8,
	},
	typography: {
		htmlFontSize: 14,
		fontFamily: "'Exo 2', sans-serif",
		h2: {
			fontSize: 32,
			fontWeight: "bold",
			[breakpoints.down("sm")]: {
				fontSize: 24,
			},
		},
		h3: {
			fontSize: 26,
			fontWeight: 700,
			[breakpoints.down("sm")]: {
				fontSize: 21,
			},
		},
		h4: {
			fontSize: 24,
			fontWeight: 700,
			lineHeight: "40px",
			[breakpoints.down("sm")]: {
				fontSize: 18,
			},
		},
		h5: {
			fontSize: 21,
			fontWeight: 500,
			[breakpoints.down("sm")]: {
				fontSize: 16,
			},
		},
		h6: {
			fontSize: 18,
			fontWeight: 700,
			[breakpoints.down("sm")]: {
				fontSize: 14,
			},
		},
		body1: {
			fontSize: 16,
			[breakpoints.down("xs")]: {
				fontSize: 14,
			},
		},
		body2: {
			fontSize: 14,
		},
		subtitle1: {
			fontWeight: 700,
			fontSize: 13,
			margin: 0,
		},
		subtitle2: {
			fontWeight: 500,
			fontSize: 12,
		},
		caption: {
			fontSize: 11,
			fontWeight: 300,
		},
	},
});
