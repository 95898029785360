import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { Input } from "../../../../../theme/components/Input";
import { Button } from "../../../../../theme/components/Button";
import DialogWrapper from "../../../../../theme/components/dialogs/DialogWrapper";
import {
	clearReferralsStatus,
	createVipReferralSettings,
	updateVipReferralSettings,
} from "../../../../../app/actions/referralsActions";
import { requiredValidator } from "../../../../../utils/customValidators/validators";

let formFields = [
	{
		field: "userId",
		type: "number",
		label: "User ID",
	},
	{
		field: "parentRewardPercent",
		type: "number",
		label: "Parent reward percent",
	},
	{
		field: "grandParentRewardPercent",
		type: "number",
		label: "Grand parent reward percent",
	},
];

const EditVipSettingsDialog = ({ open, handleClose, data, params }) => {
	const dispatch = useDispatch();
	const { status } = useSelector(({ referrals }) => referrals);
	const [errors, setErrors] = useState({});
	const [form, setForm] = useState(null);
	const createForm = useCallback(() => {
		let form = {};
		if (data) {
			formFields = formFields.filter((i) => i.field !== "userId");
		}
		formFields?.forEach((item) => {
			form[item.field] =
				item.type === "select" ? "" : item.type === "number" ? 0 : false;
		});
		return form;
	}, [data]);

	useEffect(() => {
		data
			? setForm({
					id: data.id,
					parentRewardPercent: +data.parentRewardPercent,
					grandParentRewardPercent: +data.grandParentRewardPercent,
			  })
			: setForm(createForm());
	}, [data, createForm]);

	useEffect(() => {
		if (status) {
			closeDialog();
			dispatch(clearReferralsStatus());
		}
		// eslint-disable-next-line
	}, [status]);

	const handleUpdate = () => {
		if (!validateForm()) {
			return;
		}
		if (data) {
			dispatch(updateVipReferralSettings({ ...form, id: data.id }, params));
		} else {
			dispatch(createVipReferralSettings({ referrals: [form] }, params));
		}
	};

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach((key) => {
			errForm = {
				...errForm,
				[key]: requiredValidator(undefined, form[key]),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]: requiredValidator(undefined, value),
		});
	};
	const handleChange = (value, name) => {
		handleInputValidation(name, value);
		setForm({ ...form, [name]: value });
	};

	const closeDialog = () => {
		setForm(createForm());
		setErrors({});
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Grid container justifyContent='space-between'>
				<Typography
					variant={"h6"}
					color={"textPrimary"}
					style={{ marginBottom: 32 }}>
					{data ? `Edit VIP settings ID = ${data?.id}` : "Create VIP settings"}
				</Typography>
			</Grid>
			<Grid container spacing={3}>
				{form &&
					formFields &&
					formFields?.map(
						({ field, label, type }) =>
							type === "number" && (
								<Grid item xs={12} sm={6} key={field}>
									<Input
										label={label}
										variant='outlined'
										name={field}
										value={form[field] || ""}
										onChange={({ target: { value } }) =>
											/^[0-9]*\.?[0-9]*$/.test(value) &&
											!(
												value.length > 1 &&
												value[0] === "0" &&
												value[1] !== "."
											) &&
											handleChange(value, field)
										}
										onFocus={() =>
											!form[field]
												? setForm({
														...form,
														[field]: "",
												  })
												: setForm({ ...form })
										}
										onBlur={() =>
											!form[field] && setForm({ ...form, [field]: "" })
										}
										error={errors[field]?.invalid}
										helperText={errors[field]?.errorMessage}
									/>
								</Grid>
							)
					)}
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)" }}
							onClick={handleUpdate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default EditVipSettingsDialog;
