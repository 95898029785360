import { useEffect, useState } from "react";
import {
	Grid,
	Typography,
	ButtonBase,
	InputAdornment,
} from "@material-ui/core";
import { Input } from "../Input";
import { Button } from "../Button";
import DialogWrapper from "./DialogWrapper";
import { requiredValidator } from "../../../utils/customValidators/validators";
import { withdrawValidator } from "../../../utils/customValidators/withdrawValidator";

const initialForm = {
	currency: "",
	addressTo: "",
	amount: "",
};

const TransferDialog = ({ open, handleClose, data, handleTransfer, title }) => {
	const [errors, setErrors] = useState({});
	const [form, setForm] = useState(initialForm);

	useEffect(() => {
		if (!!data) {
			setForm({ ...form, currency: data?.asset });
		}
		// eslint-disable-next-line
	}, [data]);

	const closeDialog = () => {
		setForm(initialForm);
		setErrors({});
		handleClose();
	};

	const handleChange = ({ target: { name, value } }) => {
		handleInputValidation(name, value);
		setForm({ ...form, [name]: value });
	};

	const handleChangeAmount = value => {
		handleInputValidation("amount", value);
		setForm({ ...form, amount: value });
	};

	const handleSave = () => {
		if (!validateForm()) {
			return;
		}
		handleTransfer(form);
	};

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach(key => {
			errForm = {
				...errForm,
				[key]:
					key !== "amount"
						? requiredValidator(undefined, form[key])
						: withdrawValidator(form[key], data?.balance),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]:
				name !== "amount"
					? requiredValidator(undefined, value)
					: withdrawValidator(value, data?.balance),
		});
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				{title.toUpperCase()}
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Input
						label={"Address"}
						variant={"outlined"}
						type={"text"}
						name={"addressTo"}
						value={form?.addressTo}
						error={errors?.addressTo?.invalid}
						helperText={errors?.addressTo?.errorMessage}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Input
						label={`Amount ${form?.currency}`}
						variant='outlined'
						name={"amount"}
						value={form?.amount || ""}
						onChange={({ target: { value } }) =>
							/^[0-9]*\.?[0-9]*$/.test(value) &&
							!(value.length > 1 && value[0] === "0" && value[1] !== ".") &&
							handleChangeAmount(value)
						}
						onFocus={() =>
							!form?.amount
								? setForm({
										...form,
										amount: "",
								  })
								: setForm({ ...form })
						}
						onBlur={() => !form?.amount && setForm({ ...form, amount: 0 })}
						error={errors?.amount?.invalid}
						helperText={errors?.amount?.errorMessage}
						InputLabelProps={{}}
						InputProps={{
							autoComplete: "off",
							endAdornment: (
								<InputAdornment position='end'>
									<ButtonBase disabled>
										<img
											src={`https://cryptoneed.com/icons/${form?.currency}.svg`}
											alt={form?.currency}
											width={24}
											height={24}
											loading={"lazy"}
										/>
										{form?.currency?.toUpperCase()}
									</ButtonBase>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)" }}
							onClick={handleSave}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default TransferDialog;
