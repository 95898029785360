import DialogWrapper from "../../../../theme/components/dialogs/DialogWrapper";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SelectInput } from "../../../../theme/components/Select";
import { Input } from "../../../../theme/components/Input";
import { Button } from "../../../../theme/components/Button";
import { useEffect, useState, useCallback } from "react";
import {
	clearBankingStatus,
	createNewProvider,
	updateProvider,
} from "../../../../app/actions/bankingActions";
import { requiredValidator } from "../../../../utils/customValidators/validators";

const providerStatus = {
	enable: true,
	disable: false,
};

const formFields = [
	{
		field: "provider",
		type: "text",
		label: "Provider",
	},
	{ field: "exchangeEnabled", type: "select", label: "Exchange Enabled" },
	{ field: "withdrawEnabled", type: "select", label: "Withdraw Enabled" },
];

const EditProviderDialog = ({ open, handleClose, data }) => {
	const dispatch = useDispatch();
	const { status } = useSelector(({ banking }) => banking);
	const [errors, setErrors] = useState({});
	const [state, setState] = useState(null);
	const createState = useCallback(() => {
		let form = {};
		formFields.forEach(item => {
			form[item.field] =
				item.type === "text" ? "" : item.type === "number" ? 0 : false;
		});
		return form;
	}, []);
	const closeDialog = useCallback(() => {
		if (status) {
			setState(createState());
			setErrors({});
			handleClose();
			dispatch(clearBankingStatus());
		}
	}, [dispatch, status, createState, handleClose]);

	useEffect(() => {
		data ? setState(data) : setState(createState());
		return () => {
			dispatch(clearBankingStatus());
		};
	}, [dispatch, data, createState]);

	useEffect(() => {
		closeDialog();
	});

	const handleChange = ({ target: { name, value, type } }) => {
		handleInputValidation(name, value);
		setState({ ...state, [name]: value });
	};

	const handleUpdate = () => {
		if (!validateForm()) {
			return;
		}
		data
			? dispatch(updateProvider(state)).then(_ => {
					if (status) {
						setState(createState());
						handleClose();
					}
			  })
			: dispatch(createNewProvider(state)).then(_ => {
					if (status) {
						setState(createState());
						handleClose();
					}
			  });
	};

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(state).forEach(key => {
			errForm = {
				...errForm,
				[key]: requiredValidator(undefined, state[key]),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]: requiredValidator(undefined, value),
		});
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={() => {
				dispatch(clearBankingStatus());
				handleClose();
			}}
			maxWidth={720}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				{data
					? `Edit provider ${data?.provider?.toUpperCase()}`
					: "Create provider"}
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<Input
						label={"Provider"}
						variant={"outlined"}
						type={"text"}
						name={"provider"}
						value={state?.provider || ""}
						error={errors.provider?.invalid}
						helperText={errors.provider?.errorMessage}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<SelectInput
						label='Withdraw'
						onChange={handleChange}
						value={state?.withdrawEnabled || false}
						name={"withdrawEnabled"}
						status={!state?.withdrawEnabled}>
						<MenuItem
							value={providerStatus.disable}
							style={{ color: "#ff0000" }}>
							Disable
						</MenuItem>
						<MenuItem
							value={providerStatus.enable}
							style={{ color: "#00ff00" }}>
							Enable
						</MenuItem>
					</SelectInput>
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)" }}
							onClick={handleUpdate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default EditProviderDialog;
