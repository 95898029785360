import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination, IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DistributeIcon from '@material-ui/icons/CallSplit';
import EditFundDialog from "./components/EditFundDialog";
import Table from "../../../../theme/components/Table/Table";
import DeleteConfirmDialog from "../../../../theme/components/dialogs/DeleteConfirmDialog";
import CopyWithAlert from "../../../../utils/CopyWithAlert";
import { useSettingsStyles } from "../../../Settings/SettingsStyles";
import {
	deleteInvestmentFund,
	distributeDividends,
	searchInvestmentFunds,
} from "../../../../app/actions/fundsCapitalActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";
import getStatusColor from "../../../../utils/functions/getStatusColor";
import EstimateDividendsInfoDialog from "../DistributionPrograms/EstimateDividendsInfoDialog";

const columns = [
	{ key: "token", label: "Token" },
	{ key: "asset", label: "Asset" },
	{ key: "fundType", label: "Type" },
	{ key: "riskLevel", label: "Risk Level" },
	{ key: "capitalization", label: "Capitalization" },
	{ key: "totalTokens", label: "Total Tokens" },
	{ key: "totalByUsers", label: "Total By Users" },
	{ key: "privateAccess", label: "Private Access" },
	{ key: "price", label: "Price" },
	{ key: "maxBuyAmount", label: "Max Buy" },
	{ key: "minBuyAmount", label: "Min Buy" },
	{ key: "maxSellAmount", label: "Max Sell" },
	{ key: "minSellAmount", label: "Min Sell" },
	{ key: "userRewardPercent", label: "User Reward Percent" },
	{ key: "reservedBySystem", label: "Reserved By System" },
	{ key: "walletAddress", label: "Wallet Address" },
	{ key: "apy", label: "APY" },
	{ key: "balance", label: "Balance" },
	{ key: "fundValuation", label: "Fund Valuation" },
	{ key: "edit", label: "Manage", type: "manage" },
];

const FundSettings = ({ createFund, onCloseCreateFund }) => {
	const dispatch = useDispatch();
	const [deleteFund, setDeleteFund] = useState(null);
	const [editFund, setEditFund] = useState(null);
	const { content, totalElements } = useSelector(({ funds }) => funds?.funds);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);
	const [showDistributionModal, setShowDistributionModal] = useState(null);


	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(searchInvestmentFunds(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	const handleDeleteFund = ({ accept }) => {
		if (accept) {
			dispatch(deleteInvestmentFund(deleteFund?.id));
		}
		setDeleteFund(null);
	};

	const createDistributionProgram = ({ accept }) => {
		if (accept) {
			// console.log(confirm);
			dispatch(distributeDividends({ fundId: showDistributionModal?.id }));
		}
		setShowDistributionModal(null);
	};

	return (
		<>
			<DeleteConfirmDialog
				open={Boolean(deleteFund)}
				title='Delete Fund'
				message={`Are you sure you want to delete the fund - token ${deleteFund?.token}?`}
				handleClose={handleDeleteFund}
				btnOkText='Delete'
			/>
			<EditFundDialog
				open={Boolean(editFund) || createFund}
				handleClose={() => {
					setEditFund(null);
					onCloseCreateFund();
				}}
				data={editFund}
				params={activeFilters}
			/>
			<EstimateDividendsInfoDialog
				title='Creation of a dividend distribution program'
				handleClose={createDistributionProgram}
				open={Boolean(showDistributionModal)}
				fundId={showDistributionModal?.id}
			/>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
								handleDelete={() => setDeleteFund(rowData)}
								handleEdit={() => setEditFund(rowData)}
								handleDistributeClick={() => setShowDistributionModal(rowData)}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default FundSettings;

function TableRow({ rowData, handleDelete, handleEdit, handleDistributeClick }) {
	const classes = useSettingsStyles();
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.token + "" || ""} label={"Token"}>
					<td data='hover'>{rowData?.token}</td>
				</CopyWithAlert>
				<td>{rowData?.asset}</td>
				<td>{rowData?.fundType}</td>
				<td
					style={{
						color: rowData?.riskLevel && getStatusColor(rowData?.riskLevel),
					}}>
					{rowData?.riskLevel || "-"}
				</td>
				<td>{+rowData?.capitalization}</td>
				<td>{+rowData?.totalTokens}</td>
				<td>{+rowData?.totalByUsers}</td>
				<td
					className={clsx(classes.td, classes.paper)}
					style={{ color: rowData?.privateAccess ? "#00ff00" : "#ff0000" }}>
					{+rowData?.privateAccess ? "Enabled" : "Disabled"}
				</td>
				<td>{+rowData?.price}</td>
				<td>{+rowData?.maxBuyAmount}</td>
				<td>{+rowData?.minBuyAmount}</td>
				<td>{+rowData?.maxSellAmount}</td>
				<td>{+rowData?.minSellAmount}</td>
				<td>{+rowData?.userRewardPercent}</td>
				<td>{+rowData?.reservedBySystem || "-"}</td>
				<CopyWithAlert
					text={rowData?.walletAddress + "" || ""}
					label={"Wallet Address"}>
					<td data='hover' style={{ maxWidth: 50 }}>
						{rowData?.walletAddress}
					</td>
				</CopyWithAlert>
				<td>{+rowData?.apy || ""}</td>
				<td>{+rowData?.balance || "-"}</td>
				<td>{+rowData?.fundValuation || "-"}</td>
				<td>
					<Tooltip title="Distribute" placement="top">
					<IconButton onClick={handleDistributeClick}>
						<DistributeIcon />
					</IconButton>
					</Tooltip>
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={handleDelete}>
						<DeleteIcon style={{ color: "#ff0000" }} />
					</IconButton>
				</td>
			</tr>
		</>
	);
}
