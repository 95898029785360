import {
	GET_EXCHANGE_STARTED,
	GET_EXCHANGE_SUCCESS,
	GET_EXCHANGE_FAILURE,
	GET_INTERNAL_EXCHANGE_FAILURE,
	GET_INTERNAL_EXCHANGE_STARTED,
	GET_INTERNAL_EXCHANGE_SUCCESS,
	GET_INTERNAL_EXCHANGE_ID_FAILURE,
	GET_INTERNAL_EXCHANGE_ID_STARTED,
	GET_INTERNAL_EXCHANGE_ID_SUCCESS,
	SET_EXCHANGE_PARAMS,
	CLEAR_EXCHANGE,
} from "../types";
import api from "../api";

export const getExchangeStarted = () => ({
	type: GET_EXCHANGE_STARTED,
});

export const getExchangeSuccess = data => ({
	type: GET_EXCHANGE_SUCCESS,
	payload: {
		data,
	},
});

export const getExchangeFailure = error => ({
	type: GET_EXCHANGE_FAILURE,
	payload: {
		error,
	},
});

export const getExchange = () => {
	return async dispatch => {
		api.exchange
			.getList()
			.then(res => dispatch(getExchangeSuccess(res.data)))
			.catch(error => dispatch(getExchangeFailure(error)));
	};
};

export const getInternalExchangeStarted = () => ({
	type: GET_INTERNAL_EXCHANGE_STARTED,
});

export const getInternalExchangeSuccess = payload => ({
	type: GET_INTERNAL_EXCHANGE_SUCCESS,
	payload,
});

export const getInternalExchangeFailure = error => ({
	type: GET_INTERNAL_EXCHANGE_FAILURE,
	payload: {
		error,
	},
});

export const getInternalExchange = params => {
	return async dispatch => {
		dispatch(getInternalExchangeStarted());
		api.exchange.cryptoExchange
			.getInternalExchange(params)
			.then(res => dispatch(getInternalExchangeSuccess(res.data)))
			.catch(error => dispatch(getInternalExchangeFailure(error)));
	};
};

export const getInternalExchangeIdStarted = () => ({
	type: GET_INTERNAL_EXCHANGE_ID_STARTED,
});

export const getInternalExchangeIdSuccess = payload => ({
	type: GET_INTERNAL_EXCHANGE_ID_SUCCESS,
	payload,
});

export const getInternalExchangeIdFailure = error => ({
	type: GET_INTERNAL_EXCHANGE_ID_FAILURE,
	payload: {
		error,
	},
});

export const getInternalExchangeId = (id, params) => {
	return async dispatch => {
		dispatch(getInternalExchangeIdStarted());
		api.exchange.cryptoExchange
			.getInternalExchangeId(id, params)
			.then(res => dispatch(getInternalExchangeIdSuccess(res.data)))
			.catch(error => dispatch(getInternalExchangeIdFailure(error)));
	};
};

export const clearExchange = () => ({
	type: CLEAR_EXCHANGE,
});

export const setExchangeParams = payload => ({
	type: SET_EXCHANGE_PARAMS,
	payload,
});
