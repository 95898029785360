import {
	CLEAR_INTERNAL_BALANCES,
	GET_INTERNAL_BALANCES_STARTED,
	GET_INTERNAL_BALANCES_SUCCESS,
	GET_INTERNAL_BALANCES_FAILURE,
	SET_INTERNAL_BALANCES_PARAMS,
} from "../types";
import api from "../api";

export const getInternalBalancesStarted = () => ({
	type: GET_INTERNAL_BALANCES_STARTED,
});

export const getInternalBalancesSuccess = (data, total) => ({
	type: GET_INTERNAL_BALANCES_SUCCESS,
	payload: { data, total },
});

export const getInternalBalancesFailure = error => ({
	type: GET_INTERNAL_BALANCES_FAILURE,
	payload: {
		error,
	},
});

export const getInternalBalances = params => {
	return async dispatch => {
		dispatch(getInternalBalancesStarted());
		api.finances.internalBalances
			.getList(params)
			.then(res =>
				dispatch(getInternalBalancesSuccess(res.data, Number(res.data.length)))
			)
			.catch(error => dispatch(getInternalBalancesFailure(error)));
	};
};

export const setInternalBalancesParams = payload => ({
	type: SET_INTERNAL_BALANCES_PARAMS,
	payload,
});

export const clearInternalBalances = () => ({
	type: CLEAR_INTERNAL_BALANCES,
});
