import React, { useState } from "react";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { Button } from "../../theme/components/Button";
import { Input } from "../../theme/components/Input";
import { changePassword } from "../../app/actions/accountActions";
import {
	changePasswordValidate,
	validatePassword,
} from "../../utils/customValidators/validators";

const initialForm = {
	currentPassword: "",
	newPassword: "",
	confirmPassword: "",
};

const ChangePassword = ({ passError }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [passForm, setPassForm] = useState(initialForm);
	const [hideCurrentPassword, setHideCurrentPassword] = useState(true);
	const [hideNewPassword, setHideNewPassword] = useState(true);
	const [hideConfirmPassword, setHideConfirmPassword] = useState(true);
	const [errors, setErrors] = useState({});

	const handleOnChangePassword = (event) => {
		event.preventDefault();
		const { name, value } = event?.target;
		handleInputValidation(event);
		setPassForm({ ...passForm, [name]: value });
	};

	const handleChangePassword = () => {
		if (!validateForm()) {
			return;
		}
		let data = { ...passForm };
		delete data.confirmPassword;
		dispatch(changePassword(data));
	};

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(passForm).forEach((key) => {
			errForm = {
				...errForm,
				[key]: changePasswordValidate(key, passForm[key], passForm),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleBlurPassword = (event) => {
		const { name, value } = event.target;
		const { invalid, errorMessage } = validatePassword(name, value, passForm);
		setErrors({
			...errors,
			[name]: {
				invalid: !errors[name]?.invalid ? invalid : errors[name]?.invalid,
				errorMessage: !errors[name]?.invalid
					? errorMessage
					: errors[name]?.errorMessage,
			},
		});
	};

	const handleInputValidation = (event) => {
		const { name, value } = event.target;
		const { invalid, errorMessage } = changePasswordValidate(
			name,
			value,
			passForm
		);
		setErrors({
			...errors,
			[name]: { invalid: invalid, errorMessage: errorMessage },
		});
	};

	return (
		<>
			<Paper className={classes.paper} elevation={4}>
				<form>
					<Typography variant='h5'>Change password</Typography>
					<Grid>
						<Input
							error={errors?.currentPassword?.invalid || Boolean(passError)}
							helperText={
								errors?.currentPassword?.errorMessage ||
								passError?.response?.data?.title
							}
							className={classes.input}
							label='Current password'
							variant='outlined'
							autoComplete='true'
							name={"currentPassword"}
							value={passForm?.currentPassword || ""}
							onChange={handleOnChangePassword}
							type={hideCurrentPassword ? "password" : "text"}
							InputProps={{
								endAdornment: hideCurrentPassword ? (
									<VisibilityOffIcon
										style={{ cursor: "pointer", fontSize: 20 }}
										onClick={() => setHideCurrentPassword(!hideCurrentPassword)}
									/>
								) : (
									<VisibilityIcon
										style={{ cursor: "pointer", fontSize: 20 }}
										onClick={() => setHideCurrentPassword(!hideCurrentPassword)}
									/>
								),
							}}
						/>
					</Grid>
					<Grid>
						<Input
							error={errors?.newPassword?.invalid}
							helperText={errors?.newPassword?.errorMessage}
							className={classes.input}
							label='New password'
							variant='outlined'
							name={"newPassword"}
							autoComplete='false'
							value={passForm?.newPassword || ""}
							type={hideNewPassword ? "password" : "text"}
							onChange={handleOnChangePassword}
							onBlur={handleBlurPassword}
							InputProps={{
								endAdornment: hideNewPassword ? (
									<VisibilityOffIcon
										style={{ cursor: "pointer", fontSize: 20 }}
										onClick={() => setHideNewPassword(!hideNewPassword)}
									/>
								) : (
									<VisibilityIcon
										style={{ cursor: "pointer", fontSize: 20 }}
										onClick={() => setHideNewPassword(!hideNewPassword)}
									/>
								),
							}}
						/>
					</Grid>
					<Grid>
						<Input
							error={errors?.confirmPassword?.invalid}
							helperText={errors?.confirmPassword?.errorMessage}
							className={classes.input}
							label='Repeat new password'
							variant='outlined'
							name={"confirmPassword"}
							autoComplete='false'
							value={passForm?.confirmPassword || ""}
							type={hideConfirmPassword ? "password" : "text"}
							onChange={handleOnChangePassword}
							onBlur={handleBlurPassword}
							InputProps={{
								endAdornment: hideConfirmPassword ? (
									<VisibilityOffIcon
										style={{ cursor: "pointer", fontSize: 20 }}
										onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
									/>
								) : (
									<VisibilityIcon
										style={{ cursor: "pointer", fontSize: 20 }}
										onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
									/>
								),
							}}
						/>
					</Grid>
					<Button
						className={classes.changeBtn}
						variant='contained'
						color='primary'
						onClick={handleChangePassword}>
						Change
					</Button>
				</form>
			</Paper>
		</>
	);
};

export default ChangePassword;

const useStyles = makeStyles((theme) => ({
	paper: {
		width: "100%",
		height: "100%",
		padding: "20px",
	},
	changeBtn: {
		display: "block",
		marginTop: "24px",
		width: "100%",
	},
	input: {
		marginTop: "24px",
	},
}));
