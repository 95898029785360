import React, { useEffect, useState } from "react";
import Tab from "@material-ui/core/Tab";
import { StyledTabs } from "../../../theme/components/StyledTabs";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../theme/components/PageTitle";
import {
	clearActiveFilters,
	setActiveFiltersPage,
	setActiveFiltersSize,
} from "../../../app/actions/filterActions";
import BinanceOrderHistoryTable from "./BinanceOrderHistoryTable";
import KunaOrderHistoryTable from "./KunaOrderHistoryTable";
import {
	getBinanceOrderHistory,
	getKunaOrderHistory,
} from "../../../app/actions/syncActions";

const tabs = {
	binance: "binance",
	kuna: "kuna",
};

const binanceOrderHistoryFilterFields = [
	{
		field: "id",
		type: "number",
		title: "ID",
		placeholder: "ID",
	},
	{
		field: "programId",
		type: "text",
		title: "Program Id",
		placeholder: "Program Id",
	},
	{
		field: "orderId",
		type: "text",
		title: "Order Id",
		placeholder: "Order Id",
	},
	{
		field: "clientOrderId",
		type: "text",
		title: "Order Id",
		placeholder: "Client Order Id",
	},
	{ field: "symbol", type: "text", title: "Symbol", placeholder: "Symbol" },
	{
		field: "timeFrom",
		type: "date",
		title: "Created from",
		placeholder: "Created from",
	},
	{
		field: "timeTo",
		type: "date",
		title: "Created to",
		placeholder: "Created to",
	},
	{
		field: "side",
		type: "text",
		title: "Side",
		placeholder: "Side",
	},
];

const kunaOrderHistoryFilterFields = [
	{
		field: "id",
		type: "number",
		title: "ID",
		placeholder: "ID",
	},
	{
		field: "programId",
		type: "text",
		title: "Program Id",
		placeholder: "Program Id",
	},
	{
		field: "orderId",
		type: "text",
		title: "Order Id",
		placeholder: "Order Id",
	},
	{ field: "market", type: "text", title: "Market", placeholder: "Market" },
	{
		field: "side",
		type: "text",
		title: "Side",
		placeholder: "Side",
	},
	{
		field: "createdAtFrom",
		type: "date",
		title: "Created At From",
		placeholder: "Created At From",
	},
	{
		field: "createdAtTo",
		type: "date",
		title: "Created At To",
		placeholder: "Created At To",
	},
	{
		field: "updatedAtFrom",
		type: "date",
		title: "Updated At From",
		placeholder: "Updated At From",
	},
	{
		field: "updatedAtTo",
		type: "date",
		title: "Updated At To",
		placeholder: "Updated At To",
	},
];

const OrderHistory = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(tabs.binance);
	const [filterFields, setFilterFields] = useState(
		binanceOrderHistoryFilterFields
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);
	const { content, totalElements } = useSelector(
		({ synchronization }) => synchronization.sync
	);
	useEffect(() => {
		let filteringParams = {};
		activeFilters.forEach(item => {
			filteringParams = { ...filteringParams, [item.field]: item.value };
		});
		/*eslint no-useless-computed-key: "off"*/
		if (activeTab === tabs.binance) {
			dispatch(getBinanceOrderHistory(filteringParams));
			setFilterFields(binanceOrderHistoryFilterFields);
		} else {
			dispatch(getKunaOrderHistory(filteringParams));
			setFilterFields(kunaOrderHistoryFilterFields);
		}
	}, [activeFilters, activeTab, dispatch]);

	const handleChangeTab = newTab => {
		setActiveTab(newTab);
		dispatch(clearActiveFilters());
	};

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<PageTitle title={"Order history"} filterFields={filterFields} />
			<StyledTabs
				style={{ marginBottom: 12 }}
				indicatorColor='primary'
				value={activeTab}
				onChange={(_, newTab) => handleChangeTab(newTab)}>
				<Tab label={"Binance orders"} value={tabs.binance} />
				<Tab label={"Kuna orders"} value={tabs.kuna} />
			</StyledTabs>
			{activeTab === tabs.binance ? (
				<BinanceOrderHistoryTable
					onPageChange={(event, newPage) => handleChangePage(event, newPage)}
					onRowsPerPageChange={event => handleChangeSize(event)}
					data={content}
					params={activeFilters}
					total={totalElements || 0}
				/>
			) : (
				<KunaOrderHistoryTable
					onPageChange={(event, newPage) => handleChangePage(event, newPage)}
					onRowsPerPageChange={event => handleChangeSize(event)}
					data={content}
					params={activeFilters}
					total={totalElements || 0}
				/>
			)}
		</>
	);
};

export default OrderHistory;
