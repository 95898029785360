import DialogWrapper from "../../../../../theme/components/dialogs/DialogWrapper";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import { Button } from "../../../../../theme/components/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requiredValidator } from "../../../../../utils/customValidators/validators";
import { SelectInput } from "../../../../../theme/components/Select";
import { createFundPayoutBalance } from "../../../../../app/actions/fundsCapitalActions";
import { getCurrencies } from "../../../../../app/actions/walletsActions";

const initialForm = {
	asset: "",
};

const CreateFundBalanceDialog = ({ open, handleClose }) => {
	const dispatch = useDispatch();
	const { currencies } = useSelector(({ wallets }) => wallets);
	const [form, setForm] = useState(initialForm);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		dispatch(getCurrencies());
	}, [dispatch]);

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach(key => {
			errForm = { ...errForm, [key]: requiredValidator(undefined, form[key]) };
			errForm[key].invalid && (valid = false);
		});

		setErrors(errForm);
		return valid;
	};

	const handleChange = event => {
		event.preventDefault();
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
		handleInputValidation(event);
	};

	const handleCreate = () => {
		if (!validateForm()) {
			return;
		}
		dispatch(createFundPayoutBalance(form));
		handleCloseDialog();
	};

	const handleInputValidation = event => {
		const { name, value } = event.target;
		const errorField = requiredValidator(name, value);
		setErrors({
			...errors,
			[name]: errorField,
		});
	};

	const handleCloseDialog = () => {
		setErrors({});
		setForm(initialForm);
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={handleCloseDialog}
			maxWidth={720}
			minWidth={400}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				Create Fund Balance
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					{currencies && (
						<SelectInput
							error={errors?.asset?.invalid}
							label='Asset'
							onChange={handleChange}
							value={form?.asset || ""}
							name={"asset"}
							onBlur={handleInputValidation}
							helperText={errors?.asset?.errorMessage}>
							{!!currencies &&
								currencies?.map((i, index) => (
									<MenuItem key={index} value={i?.ticker}>
										<div style={{ display: "flex", alignItems: "center" }}>
											<img
												src={`https://cryptoneed.com/icons/${i?.ticker}.svg`}
												alt={i?.ticker}
												width={16}
												height={16}
												style={{ marginRight: 12 }}
												loading={"lazy"}
											/>
											<p style={{ margin: 0 }}>
												{i?.ticker?.toUpperCase()}
											</p>
										</div>
									</MenuItem>
								))}
						</SelectInput>
					)}
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)", marginTop: 12 }}
							onClick={handleCreate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default CreateFundBalanceDialog;
