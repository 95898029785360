import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../theme/components/Table/Table";
import { Button } from "../../../../theme/components/Button";
import TransferDialog from "../../../../theme/components/dialogs/TransferDialog.jsx";
import {
	clearFundStatus,
	sendFromSystemBalanceToWalletByAddress,
	getSystemBalancesFunds,
	clearFundsSystemBalances,
} from "../../../../app/actions/fundsCapitalActions";
import {
	clearIndexesStatus,
	getSystemBalancesIndexes,
	clearIndexSystemBalances,
	transferFromSystemBalanceToWallet,
} from "../../../../app/actions/indexesCapitalActions";

const columns = [
	{ key: "id", label: "ID" },
	{ key: "asset", label: "Asset" },
	{ key: "balance", label: "Balance" },
	{ key: "send", label: "Withdrawals", type: "manage" },
];

const SystemBalances = ({ isIndexes }) => {
	const dispatch = useDispatch();
	const [openTransfer, setOpenTransfer] = useState(null);
	const { systemBalancesFunds } = useSelector(({ funds }) => funds);
	const { systemBalancesIndexes } = useSelector(({ indexes }) => indexes);
	const statusFunds = useSelector(({ funds }) => funds.status);
	const statusIndexes = useSelector(({ indexes }) => indexes.status);

	const systemBalances = useMemo(
		() => (isIndexes ? systemBalancesIndexes : systemBalancesFunds),
		[isIndexes, systemBalancesFunds, systemBalancesIndexes]
	);
	const status = useMemo(
		() => (isIndexes ? statusIndexes : statusFunds),
		[isIndexes, statusIndexes, statusFunds]
	);

	const closeDialogTransfer = useCallback(() => {
		if (status) {
			isIndexes ? dispatch(clearIndexesStatus()) : dispatch(clearFundStatus());
		}
		setOpenTransfer(null);
	}, [dispatch, status, isIndexes]);

	useEffect(() => {
		if (status) {
			closeDialogTransfer();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	useEffect(() => {
		isIndexes
			? dispatch(getSystemBalancesIndexes())
			: dispatch(getSystemBalancesFunds());
		return () => {
			isIndexes
				? dispatch(clearIndexSystemBalances())
				: dispatch(clearFundsSystemBalances());
		};
	}, [dispatch, isIndexes]);

	const handleTransfer = (data) => {
		isIndexes
			? dispatch(transferFromSystemBalanceToWallet(data))
			: dispatch(sendFromSystemBalanceToWalletByAddress(data));
	};

	return (
		<>
			<TransferDialog
				open={Boolean(openTransfer)}
				handleClose={closeDialogTransfer}
				data={openTransfer}
				handleTransfer={handleTransfer}
				title={"Transfer from system balance to wallet"}
			/>

			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{systemBalances?.length > 0 ? (
						systemBalances?.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
								openTransfer={() => setOpenTransfer(rowData)}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</>
	);
};

export default SystemBalances;

function TableRow({ rowData, openTransfer }) {
	return (
		<>
			<tr>
				<td style={{ width: "25%" }}>{rowData?.id}</td>
				<td style={{ width: "25%" }}>{rowData?.asset}</td>
				<td style={{ width: "25%" }}>{+rowData?.balance}</td>
				<td style={{ width: "25%", padding: "4px 14px" }}>
					<Button
						variant='outlined'
						color='primary'
						style={{ padding: "0px 8px" }}
						onClick={openTransfer}>
						Super withdraw
					</Button>
				</td>
			</tr>
		</>
	);
}
