export const Message = Object.freeze({
	LanguageChanged: "Your language has been successfully changed.",
	PasswordChanged: "Your password has been successfully changed.",
	DataSaved: "Data saved successfully.",
	DataChanged: "Data changed successfully",
	DataDeleted: "Data deleted",
	SuccessfullyLoggedOut: "You have successfully logged out.",
	SuccessfulDistribution: "Current shares distributed successfully.",
	SuccessfullyReSent: "Message sent successfully",
	DeleteData: "Data deleted successfully",
});

export const MessageError = Object.freeze({
	InvalidTFCode: "Invalid Google Authenticator code.M",
	InvalidShares: "No current shares to distribute.",
});
