

import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { IconButton, Grid, Typography } from "@material-ui/core";
import Table from "../../../theme/components/Table/Table";
import { Button } from "../../../theme/components/Button";
import {
	createVipUserReferralFundSettings,
	deleteVipUserReferralFundSettings,
	updateVipUserReferralFundSettings,
} from "../../../app/actions/referralsActions";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditSettingsDialog from "./EditSettingsDialog";
import DeleteSettingsDialog from "./DeleteSettingsDialog";
import { DEFAULT_VIP_USER_REFERRAL_SETTINGS_COLUMNS } from "../../../utils/constants/referrals";

const FundsVipReferalSettings = ({ settings }) => {
	const [create, setCreate] = useState(false);
	const [editSetting, setEditSetting] = useState(false);
	const [deleteSetting, setDeleteSetting] = useState(null);
	const userId = useSelector(({ users }) => users?.profile?.id);

	const handleDialogClose = useCallback(() => {
		setDeleteSetting(null);
		setEditSetting(null);
		setCreate(false);
	}, []);

	return (
		<div style={{ marginBottom: "30px" }}>
			<DeleteSettingsDialog
				open={Boolean(deleteSetting)}
				handleClose={handleDialogClose}
				label='Delete funds referral setting?'
				onDeleteAction={deleteVipUserReferralFundSettings}
				{...deleteSetting}
			/>
			<EditSettingsDialog
				open={Boolean(editSetting) || create}
				handleClose={handleDialogClose}
				label='funds referral settings'
				onEditAction={updateVipUserReferralFundSettings}
				onCreateAction={createVipUserReferralFundSettings}
				data={editSetting}
				userId={userId}
			/>
			<Grid container justifyContent='space-between' alignItems='center'>
				<Grid>
					<Typography
						variant={"body1"}
						color={"textPrimary"}
						style={{ marginBottom: 0 }}>
						Funds VIP referral settings
					</Typography>
				</Grid>
				{!settings && (
					<Button
						variant='outlined'
						color='primary'
						style={{ marginBottom: 8, padding: "4px 28px" }}
						onClick={() => setCreate(true)}>
						<AddIcon />
						Create
					</Button>
				)}
			</Grid>

			{settings ? (
				<Table>
					<thead>
						<tr>
							{DEFAULT_VIP_USER_REFERRAL_SETTINGS_COLUMNS?.map(
								({ key, headerName, colSpan, align }) => (
									<th key={key} colSpan={colSpan} align={align || "left"}>
										{headerName}
									</th>
								)
							)}
						</tr>
					</thead>
					<tbody>
						{settings ? (
							<TableRow
								rowData={settings}
								handleEdit={() => setEditSetting(settings)}
								handleDelete={() => setDeleteSetting(settings)}
							/>
						) : (
							<tr>
								<td colSpan={8} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			) : null}
		</div>
	);
};

export default FundsVipReferalSettings;

function TableRow({ rowData, handleDelete, handleEdit }) {
	return (
		<>
			<tr>
				<td>{rowData?.id}</td>
				<td>{+rowData?.parentRewardPercent}</td>
				<td>{+rowData?.grandParentRewardPercent}</td>
				<td>
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={handleDelete}>
						<DeleteIcon style={{ color: "#ff0000" }} />
					</IconButton>
				</td>
			</tr>
		</>
	);
}
