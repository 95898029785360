import React, { useEffect, useState } from "react";
import { Tab } from "@material-ui/core";
import { StyledTabs } from "../../../theme/components/StyledTabs";
import FiatDepositsTable from "./FiatDepositsTable";
import FiatWithdrawalsTable from "./FiatWithdrawalsTable";
import PageTitle from "../../../theme/components/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
	clearKunaDeposits,
	clearKunaWithdrawal,
	getKunaDeposits,
	getKunaWithdrawal,
} from "../../../app/actions/financesActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../app/actions/filterActions";

const tabs = {
	deposits: "Deposits",
	withdrawals: "Withdrawals",
};
const depositsFilterFields = [
	{
		field: "id",
		type: "number",
		title: "Operation ID",
		placeholder: "Operation ID",
	},
	{ field: "userId", type: "number", title: "User ID", placeholder: "User ID" },
	{
		field: "invoiceId",
		type: "text",
		title: "Invoice Id",
		placeholder: "Invoice Id",
	},
	{
		field: "paymentUrl",
		type: "text",
		title: "Payment Url",
		placeholder: "Payment Url",
	},
	{
		field: "dateFrom",
		type: "date",
		title: "Create date from",
		placeholder: "Create date from",
	},
	{
		field: "dateTo",
		type: "date",
		title: "Create date to",
		placeholder: "Create Date to",
	},
	{
		field: "currency",
		type: "text",
		title: "Currency",
		placeholder: "Currency",
	},
	{
		field: "status",
		type: "text",
		title: "Status",
		placeholder: "Status",
	},
];

const withdrawalsFilterFields = [
	{
		field: "withdrawalId",
		type: "text",
		title: "Withdrawal ID",
		placeholder: "Withdrawal ID",
	},
	{
		field: "id",
		type: "number",
		title: "Operation ID",
		placeholder: "Operation ID",
	},

	{ field: "userId", type: "number", title: "User ID", placeholder: "User ID" },
	{
		field: "cardNumber",
		type: "number",
		title: "Card number",
		placeholder: "Card number",
	},
	{
		field: "dateFrom",
		type: "date",
		title: "Create date from",
		placeholder: "Create date from",
	},
	{
		field: "dateTo",
		type: "date",
		title: "Create date to",
		placeholder: "Create Date to",
	},
	{
		field: "currency",
		type: "text",
		title: "Currency",
		placeholder: "Currency",
	},
	{
		field: "status",
		type: "text",
		title: "Status",
		placeholder: "Status",
	},
];

function FiatOperations() {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(tabs.deposits);
	const [filterFields, setFilterFields] = useState(depositsFilterFields);
	const [sorting, setSorting] = useState({ sort: "createdAt,desc" });

	const { deposits, withdrawals, total } = useSelector(
		({ finances }) => finances.kuna
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = { ...sorting };
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		if (activeTab === tabs.deposits) {
			dispatch(getKunaDeposits(filteringParams));
			setFilterFields(depositsFilterFields);
		} else {
			dispatch(getKunaWithdrawal(filteringParams));
			setFilterFields(withdrawalsFilterFields);
		}
	}, [activeFilters, activeTab, sorting, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
			dispatch(clearKunaWithdrawal());
			dispatch(clearKunaDeposits());
		};
	}, [dispatch]);

	const handleChangeTab = newTab => {
		setActiveTab(newTab);
		dispatch(clearActiveFilters());
	};

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<PageTitle title={"Kuna operations"} filterFields={filterFields} />
			<StyledTabs
				style={{ marginBottom: 12 }}
				indicatorColor='primary'
				value={activeTab}
				onChange={(_, newTab) => handleChangeTab(newTab)}>
				<Tab label={"Deposits operations"} value={tabs.deposits} />
				<Tab label={"Withdrawals operations"} value={tabs.withdrawals} />
			</StyledTabs>
			{activeTab === tabs.deposits ? (
				<FiatDepositsTable
					onPageChange={(event, newPage) => handleChangePage(event, newPage)}
					onRowsPerPageChange={event => handleChangeSize(event)}
					onSorting={event => setSorting(event)}
					deposits={deposits}
					params={activeFilters}
					total={total}
				/>
			) : (
				<FiatWithdrawalsTable
					onPageChange={(event, newPage) => handleChangePage(event, newPage)}
					onRowsPerPageChange={event => handleChangeSize(event)}
					onSorting={event => setSorting(event)}
					withdrawals={withdrawals}
					params={activeFilters}
					total={total}
				/>
			)}
		</div>
	);
}

export default FiatOperations;
