import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFirebaseAuth } from "./hooks/useFirebaseAuth";
import { useEffect } from "react";

export const PrivateRoute = ({ children, ...routeProps }) => {
	const { authorized } = useSelector(({ account }) => account);
	const { loginToFirebase, checkIsLoggedInFirebase } = useFirebaseAuth();

	useEffect(() => {
		if (authorized) {
			const isFirebaseAuthenticated = checkIsLoggedInFirebase();
			if (!isFirebaseAuthenticated) {
				loginToFirebase();
			}
		}
	}, [authorized, checkIsLoggedInFirebase, loginToFirebase]);

	if (!authorized) {
		return (
			<Redirect
				to={{
					pathname: "/login",
				}}
			/>
		);
	}
	return (
		<Route
			{...routeProps}
			render={({ location }) => {
				return authorized ? (
					children
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: location },
						}}
					/>
				);
			}}
		/>
	);
};
