import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { Grid, makeStyles, Popover, Button, MenuItem } from "@material-ui/core";
import FilterList from "@material-ui/icons/FilterList";
import { Input } from "../Input";
import { SelectInput } from "../Select";
import { validateInput } from "../../../utils/customValidators/validators";
import { setActiveFilter } from "../../../app/actions/filterActions";
import KeyboardDate from "../KeyboardDate";

const Filter = ({ filterFields }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);
	const [anchorEl, setAnchorEl] = useState(null);

	const [form, setForm] = useState({});
	const [errors, setErrors] = useState({});
	const isFilter = true;
	useEffect(() => {
		setForm(() => {
			let initialForm = {};
			filterFields.forEach((filter) => {
				const activeFilter = activeFilters.find(
					(item) => item?.field === filter?.field
				);
				initialForm[filter.field] = activeFilter ? activeFilter?.value : "";
			});
			return initialForm;
		});
	}, [activeFilters, filterFields]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "filter-popover" : undefined;

	const handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		handleInputValidation(name, value);
		setForm({ ...form, [name]: value });
	};

	const handleDateChange = (name, value) => {
		setForm({ ...form, [name]: value });
		handleInputValidation(name, value);
	};

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach((key) => {
			errForm = {
				...errForm,
				[key]: validateInput(key, form[key], form, isFilter),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const setFilters = () => {
		if (!validateForm()) {
			return;
		}

		let filters = [
			{ field: "size", value: 10 },
			{ field: "page", value: 0 },
		];
		Object.keys(form).forEach((item) => {
			if (!!form[item]) {
				filters = [...filters, { field: item, value: form[item] }];
			}
		});
		dispatch(setActiveFilter(filters));
		handleClose();
	};

	const handleInputValidation = (name, value) => {
		const { invalid, errorMessage } = validateInput(
			name,
			value,
			form,
			isFilter
		);
		setErrors({ ...errors, [name]: { invalid, errorMessage } });
	};

	return (
		<div className={classes.root}>
			<Button
				variant='outlined'
				aria-describedby={id}
				color='primary'
				style={{
					marginLeft: 16,
					padding: "4px 28px",
					color: "#18A0FB",
					minHeight: "48px",
				}}
				className={clsx(classes.btn, {
					[classes.popoverOpen]: open,
				})}
				startIcon={<FilterList style={{ marginRight: 8 }} />}
				onClick={handleClick}>
				Filter
			</Button>
			<Popover
				className={clsx(classes.popover)}
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}>
				<Grid container spacing={3} className={classes.form}>
					{filterFields?.map((filter, index) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							className={classes.formCroup}
							key={filter?.field || index}>
							{filter?.type === "date" ? (
								<KeyboardDate
									key={filter?.field + index}
									filter={filter}
									form={form}
									onChange={handleDateChange}
									error={errors[filter?.field]?.invalid}
									helperText={errors[filter?.field]?.errorMessage}
								/>
							) : filter.type === "select" ? (
								<SelectInput
									key={filter?.field + index}
									error={errors[filter?.field]?.invalid}
									label={filter?.title}
									onChange={handleChange}
									name={filter?.field}
									value={form[filter?.field] || ""}>
									{filter?.data.map((item, index) => (
										<MenuItem key={item.label || index} value={item.value}>
											{item.label}
										</MenuItem>
									))}
								</SelectInput>
							) : (
								<Input
									error={errors[filter?.field]?.invalid}
									helperText={errors[filter?.field]?.errorMessage}
									key={filter?.field + index}
									type={filter?.type}
									label={filter?.title}
									variant={"outlined"}
									name={filter?.field}
									value={form[filter?.field] || ""}
									onChange={handleChange}
								/>
							)}
						</Grid>
					))}
				</Grid>
				<Grid container className={classes.btnWrap}>
					<Button variant='outlined' color='default' onClick={handleClose}>
						Cancel
					</Button>
					<Button variant='contained' color='primary' onClick={setFilters}>
						Apply
					</Button>
				</Grid>
			</Popover>
		</div>
	);
};

export default Filter;

const useStyles = makeStyles((theme) => ({
	btn: {
		color: "#18A0FB",
		border: "1px solid #18A0FB",
	},
	popover: {
		"&>.MuiPopover-paper": {
			maxWidth: "calc(100% - 100px)",
			padding: 24,
			marginTop: -1,
			border: "1px solid #18A0FB",
			borderTopRightRadius: 0,
			width: "calc(100% - 100px)",
			maxHeight: "calc(100vh - 200px)",
			overflow: "auto",
		},
	},
	popoverOpen: {
		borderRadius: "8px 8px 0 0",
		borderBottom: "none",
		zIndex: 1301,
		backgroundColor: "#262830",
		"&:hover": {
			border: "1px solid #18A0FB",
			borderBottom: "none",
			backgroundColor: "#262830",
			cursor: "default",
		},
	},
	btnWrap: {
		display: "flex",
		justifyContent: "space-around",
	},

	form: {
		marginBottom: 24,
	},
	formCroup: {
		display: "flex",
		flexDirection: "column",
	},
	btnClear: {
		fontSize: 14,
		textTransform: "uppercase",
	},
	errorField: {
		color: theme.palette.text.error,
		fontSize: 12,
		position: "absolute",
		marginTop: 56,
	},
	success: {
		color: theme.palette.text.success,
	},
	error: {
		color: theme.palette.text.error,
	},
}));
