import DialogWrapper from "../../../../theme/components/dialogs/DialogWrapper";
import { Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Input } from "../../../../theme/components/Input";
import { Button } from "../../../../theme/components/Button";
import { useEffect, useState, useCallback } from "react";
import {
	createLiquidityProviderSettings,
	updateLiquidityProviderSettings,
} from "../../../../app/actions/bankingActions";
import { requiredValidator } from "../../../../utils/customValidators/validators";

const formFields = [
	{
		field: "userId",
		type: "text",
		label: "User ID",
	},
	{ field: "telegramChatId", type: "text", label: "Telegram Chat ID" },
	{ field: "telegramBotToken", type: "text", label: "Telegram Bot Token" },
];

const EditProviderDialog = ({ open, handleClose, data, params }) => {
	const dispatch = useDispatch();
	const [errors, setErrors] = useState({});
	const [state, setState] = useState(null);
	const createState = useCallback(() => {
		let form = {};
		formFields.forEach(item => {
			form[item.field] =
				item.type === "text" ? "" : item.type === "number" ? 0 : false;
		});
		return form;
	}, []);
	const closeDialog = useCallback(() => {
		setState(createState());
		setErrors({});
		handleClose();
	}, [createState, handleClose]);

	useEffect(() => {
		data ? setState(data) : setState(createState());
	}, [dispatch, data, createState]);

	const handleChange = ({ target: { name, value, type } }) => {
		handleInputValidation(name, value);
		setState({ ...state, [name]: value });
	};

	const handleUpdate = () => {
		if (!validateForm()) {
			return;
		}
		data
			? dispatch(updateLiquidityProviderSettings(state, params)).then(_ => {
				setState(createState());
				handleClose();
			})
			: dispatch(createLiquidityProviderSettings(state, params)).then(_ => {
				setState(createState());
				handleClose();
			});
	};

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(state).forEach(key => {
			errForm = {
				...errForm,
				[key]: requiredValidator(undefined, state[key]),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]: requiredValidator(undefined, value),
		});
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={600}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				{data
					? `Edit Provider Settings (ID: ${data?.userId})`
					: "Create Liquidity Provider"}
			</Typography>
			<Grid container spacing={3} justifyContent="center">
				<Grid item xs={12} sm={8}>
					<Input
						label={"User ID"}
						variant={"outlined"}
						type={"text"}
						name={"userId"}
						disabled={!!data}
						value={state?.userId || ""}
						error={errors.userId?.invalid}
						helperText={errors.userId?.errorMessage}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Input
						label={"Telegram Chat ID"}
						variant={"outlined"}
						type={"text"}
						name={"telegramChatId"}
						value={state?.telegramChatId || ""}
						error={errors.telegramChatId?.invalid}
						helperText={errors.telegramChatId?.errorMessage}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Input
						label={"Telegram Bot Token"}
						variant={"outlined"}
						type={"text"}
						name={"telegramBotToken"}
						value={state?.telegramBotToken || ""}
						error={errors.telegramBotToken?.invalid}
						helperText={errors.telegramBotToken?.errorMessage}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)" }}
							onClick={handleUpdate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default EditProviderDialog;
