import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Grid,
	Typography,
	TextareaAutosize,
	makeStyles,
} from "@material-ui/core";
import { Input } from "../../theme/components/Input";
import { Button } from "../../theme/components/Button";
import DialogWrapper from "../../theme/components/dialogs/DialogWrapper";
import { clearKycStatus } from "../../app/actions/kycActions";
import { kycValidateInput } from "../../utils/customValidators/kycValidator";
import { KYC_STATUS } from "../../utils/constants/kyc";
import KeyboardDate from "../../theme/components/KeyboardDate";
import { useIsMobile } from "../../utils/hooks/useIsMobile";

const approveForm = [
	{ field: "expirationDate", type: "date", title: "Document expiration date" },
];

const declineForm = [
	{ field: "decision", type: "text", label: "Reason for the decision" },
];

const KycDecisionDialog = ({
	open,
	handleClose,
	data = null,
	decision,
	handleDecision,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { status } = useSelector(({ kyc }) => kyc);
	const [errors, setErrors] = useState({});
	const [form, setForm] = useState(null);
	const [formFields, setFormFields] = useState(null);
	const isMobile = useIsMobile();

	useEffect(() => {
		if (decision !== KYC_STATUS.APPROVED) {
			setFormFields(declineForm);
			createForm(declineForm);
		} else {
			setFormFields(approveForm);
			createForm(approveForm);
		}
		// eslint-disable-next-line
	}, [open, data, decision]);

	const createForm = (fields) => {
		let form = {};
		fields?.forEach((item) => {
			form[item.field] =
				item.type === "text" || item.type === "select" || item.type === "date"
					? data
						? data[item.field]
						: ""
					: item.type === "number"
					? data
						? data[item.field]
						: 0
					: false;
		});
		setForm(form);
	};

	useEffect(() => {
		if (status) {
			dispatch(clearKycStatus());
			closeDialog();
		}
		// eslint-disable-next-line
	}, [dispatch, status]);

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach((key) => {
			errForm = {
				...errForm,
				[key]: kycValidateInput(key, form[key]),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]: kycValidateInput(name, value),
		});
	};

	const handleChange = ({ target: { name, value } }) => {
		handleInputValidation(name, value);
		setForm({ ...form, [name]: value });
	};

	const handleDateChange = (name, value) => {
		setForm({ ...form, [name]: value });
		handleInputValidation(name, value);
	};

	const onDecision = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!validateForm()) {
			return;
		}
		handleDecision(form);
	};

	const closeDialog = () => {
		setForm(null);
		setErrors({});
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={720}
			minWidth={isMobile ? 320 : 540}
			maxW={"lg"}>
			<Grid container justifyContent='space-between'>
				<Typography
					variant={"h6"}
					color={"textPrimary"}
					style={{ marginBottom: 32 }}>
					{decision === KYC_STATUS.DECLINED ? "DECLINE" : "APPROVE"}
				</Typography>
			</Grid>
			<Grid container spacing={3}>
				{decision !== KYC_STATUS.APPROVED ? (
					<>
						{form &&
							formFields &&
							formFields?.map(({ field, label }) => (
								<Grid item xs={12} key={field}>
									<Input
										className={classes.rootInput}
										fullWidth
										multiline
										label={label}
										variant={"outlined"}
										InputProps={{
											component: TextareaAutosize,
											minRows: 3,
											maxRows: 3,
										}}
										name={field}
										value={form[field] || ""}
										error={errors[field]?.invalid}
										helperText={errors[field]?.errorMessage}
										onChange={handleChange}
									/>
								</Grid>
							))}
					</>
				) : (
					<>
						{form &&
							formFields &&
							formFields?.map(({ field, title }) => (
								<Grid item xs={12} key={field}>
									<KeyboardDate
										key={field}
										filter={{ field, title }}
										form={form}
										onChange={handleDateChange}
										error={errors[field]?.invalid}
										helperText={errors[field]?.errorMessage}
									/>
								</Grid>
							))}
					</>
				)}
			</Grid>

			<Grid
				container
				justifyContent='center'
				style={{ marginTop: 24, marginBottom: 24 }}>
				<Button
					color={"primary"}
					style={{ width: "max(50%, 50px)" }}
					onClick={onDecision}>
					Save
				</Button>
			</Grid>
		</DialogWrapper>
	);
};

export default KycDecisionDialog;

const useStyles = makeStyles((theme) => ({
	rootInput: {
		"& .MuiFormHelperText-root": {
			marginTop: 96,
		},
	},
}));
