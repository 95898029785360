import React, { useEffect, useState } from "react";
import { IconButton, TablePagination } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Visibility } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import {
	getAdmins,
	setAdminsParams,
	clearAdmins,
} from "../../app/actions/adminsActions";
import PageTitle from "../../theme/components/PageTitle";
import { useHistory } from "react-router-dom";
import CreateAdminDialog from "./CreateAdminDialog";
import CopyWithAlert from "../../utils/CopyWithAlert";
import Table from "../../theme/components/Table/Table";

const columns = [
	{ key: "id", headerName: "ID" },
	{ key: "firstName", headerName: "First name" },
	{ key: "lastName", headerName: "Last name" },
	{ key: "email", headerName: "Email" },
	{ key: "authorities", headerName: "Role" },
	{ key: "vip", headerName: "VIP" },
	{ key: "status", headerName: "Status" },
	{ key: "manage", headerName: "Manage" },
];

const Admins = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { data, params, total } = useSelector(({ admins }) => admins);
	const [create, setCreate] = useState(false);

	useEffect(() => {
		!!params && dispatch(getAdmins(params));
	}, [dispatch, params]);

	useEffect(() => {
		if (
			history?.location?.state?.from === "admin detail" &&
			history?.location?.state?.params
		) {
			dispatch(setAdminsParams(JSON.parse(history?.location?.state?.params)));
		} else {
			dispatch(setAdminsParams({ page: 0, size: 10, search: "" }));
		}
	}, [dispatch, history.location]);

	useEffect(() => {
		return () => {
			dispatch(setAdminsParams(null));
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setAdminsParams({ ...params, page: newPage }));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setAdminsParams({ ...params, size: target.value, page: 0 }));
	};

	const handleSearch = search => {
		dispatch(setAdminsParams({ page: 0, size: 10, search }));
	};

	const handleManage = data => {
		dispatch(clearAdmins());
		history.push(
			`/admins/list/${data?.login}/${data?.id}/?params=${JSON.stringify(
				params
			)}`,
			{ from: "admins", params: JSON.stringify(params) }
		);
	};

	const handleCreate = () => {
		setCreate(true);
	};

	return (
		<div>
			<CreateAdminDialog
				open={Boolean(create)}
				handleClose={() => {
					setCreate(null);
				}}
			/>
			<PageTitle
				title={"Admins"}
				onCreate={handleCreate}
				onSearch={handleSearch}
				valueSearch={params?.search}
			/>
			<div>
				<Table>
					<thead>
						<tr>
							{columns.map(({ key, headerName, align }) => (
								<th key={key} align={align || "left"}>
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{data?.length > 0 ? (
							data?.map((row, index) => (
								<TableRow
									key={row?.id || index}
									rowData={row}
									onManage={handleManage}
								/>
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[10, 30, 50]}
					component='div'
					count={total}
					rowsPerPage={params?.size || 10}
					page={params?.page || 0}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeSize}
				/>
			</div>
		</div>
	);
};

export default Admins;

function TableRow({ rowData, onManage }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<td>{rowData?.firstName}</td>
				<td>{rowData?.lastName}</td>
				<CopyWithAlert text={rowData?.email + "" || ""} label={"email"}>
					<td data='hover'>{rowData?.email}</td>
				</CopyWithAlert>
				<td>{rowData?.authorities}</td>
				<td>
					{rowData?.vip ? (
						<CheckIcon style={{ color: "#00ff00" }} />
					) : (
						<CloseIcon style={{ color: "#ff0000" }} />
					)}
				</td>
				<td>
					{rowData?.activated ? (
						<CheckIcon style={{ color: "#00ff00" }} />
					) : (
						<CloseIcon style={{ color: "#ff0000" }} />
					)}
				</td>
				<td>
					<IconButton onClick={() => onManage(rowData)}>
						<Visibility />
					</IconButton>
				</td>
			</tr>
		</>
	);
}
