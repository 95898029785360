import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { clearStatusSettings } from "../../../../app/actions/settingsActions";
import { Button } from "../../../../theme/components/Button";
import DeleteDialog from "./DeleteProviderDialog";
import EditProviderDialog from "./EditProviderDialog";
import { useSettingsStyles } from "../../../Settings/SettingsStyles";
import { getProviders } from "../../../../app/actions/bankingActions.js";
import Table from "../../../../theme/components/Table/Table.jsx";

const columns = [
	{ field: "provider", headerName: "Provider" },
	{ field: "exchangeEnabled", headerName: "Exchange" },
	{ field: "withdrawEnabled", headerName: "Withdraw" },
	{ field: "edit", headerName: "Manage" },
];

const ProvidersList = () => {
	const classes = useSettingsStyles();
	const dispatch = useDispatch();
	const { providers } = useSelector(({ banking }) => banking);
	const [deleteProvider, setDeleteProvider] = useState(null);
	const [editProvider, setEditProvider] = useState(null);
	const [create, setCreate] = useState(false);

	useEffect(() => {
		dispatch(getProviders());
	}, [dispatch]);

	return (
		<div style={{ maxWidth: "100%" }}>
			<DeleteDialog
				open={Boolean(deleteProvider)}
				handleClose={() => setDeleteProvider(null)}
				{...deleteProvider}
			/>
			<EditProviderDialog
				open={Boolean(editProvider) || create}
				handleClose={() => {
					setEditProvider(null);
					setCreate(false);
					dispatch(clearStatusSettings());
				}}
				data={editProvider}
			/>
			<div className={classes.head} style={{ marginTop: -60 }}>
				<div></div>
				<Button
					variant='outlined'
					color='primary'
					style={{ marginBottom: 16, padding: "2px 28px" }}
					onClick={() => setCreate(true)}>
					<AddIcon />
					Create
				</Button>
			</div>
			<Table>
				<thead>
					<tr>
						{columns.map(({ field, headerName }) => (
							<th
								key={field}
								className={clsx(classes.th, classes.paper)}
								align={"left"}>
								{headerName}
							</th>
						))}
					</tr>
				</thead>
				<tbody style={{ position: "relative" }}>
					{providers.length > 0 ? (
						providers.map((row, index) => (
							<TableRow
								key={row?.id || index}
								rowData={row}
								handleDelete={() => setDeleteProvider(row)}
								handleEdit={() => setEditProvider(row)}
							/>
						))
					) : (
						<tr>
							<td colSpan={13} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</div>
	);
};

export default ProvidersList;

function TableRow({ rowData, handleDelete, handleEdit }) {
	const classes = useSettingsStyles();

	return (
		<>
			<tr className={classes.hover}>
				<td className={clsx(classes.td, classes.paper)}>{rowData?.provider}</td>
				<td
					className={clsx(classes.td, classes.paper)}
					style={{ color: rowData?.exchangeEnabled ? "#00ff00" : "#ff0000" }}>
					{rowData?.exchangeEnabled ? "Enable" : "Disable"}
				</td>
				<td
					className={clsx(classes.td, classes.paper)}
					style={{ color: rowData?.withdrawEnabled ? "#00ff00" : "#ff0000" }}>
					{rowData?.withdrawEnabled ? "Enable" : "Disable"}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={handleDelete}>
						<DeleteIcon style={{ color: "#ff0000" }} />
					</IconButton>
				</td>
			</tr>
		</>
	);
}
