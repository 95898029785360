import {
	GET_PROVIDERS,
	CREATE_NEW_PROVIDER,
	UPDATE_PROVIDER,
	DELETE_PROVIDER,
	TRANSFER_FROM_BANKING_BALANCE_TO_WALLET,
	GET_ALL_BANKING_BALANCES,
	GET_ALL_BANKING_SETTINGS,
	UPDATE_BANKING_SETTINGS,
	MAKE_DECISION_BY_PENDING_TRANSACTION,
	CLEAR_BANKING_STATUS,
	CLEAR_BANKING_ERROR,
	UPDATE_ALL_BANKING_BALANCES,
	GET_BANKING_HISTORY,
	GET_LIQUIDITY_PROVIDERS_SETTINGS,
	GET_LIQUIDITY_PROVIDERS_OFFERS,
} from "../types";

export default function banking(
	state = {
		providers: [],
		balances: [{}],
		history: [],
		liquidityProviders: {
			content: [],
			totalElements: 0,
		},
		liquidityProvidersOffers: {
			content: [],
			totalElements: 0,
		},
		settings: null,
		status: false,
		error: null,
	},
	action
) {
	switch (action.type) {
		case GET_PROVIDERS.STARTED:
		case CREATE_NEW_PROVIDER.STARTED:
		case UPDATE_PROVIDER.STARTED:
		case DELETE_PROVIDER.STARTED:
		case TRANSFER_FROM_BANKING_BALANCE_TO_WALLET.STARTED:
		case GET_ALL_BANKING_BALANCES.STARTED:
		case GET_ALL_BANKING_SETTINGS.STARTED:
		case UPDATE_BANKING_SETTINGS.STARTED:
		case MAKE_DECISION_BY_PENDING_TRANSACTION.STARTED:
		case GET_LIQUIDITY_PROVIDERS_SETTINGS.STARTED:
		case GET_LIQUIDITY_PROVIDERS_OFFERS.STARTED:
		case GET_BANKING_HISTORY.STARTED:
			return { ...state, status: false, error: null };
		case GET_PROVIDERS.FAILURE:
		case CREATE_NEW_PROVIDER.FAILURE:
		case UPDATE_PROVIDER.FAILURE:
		case DELETE_PROVIDER.FAILURE:
		case TRANSFER_FROM_BANKING_BALANCE_TO_WALLET.FAILURE:
		case GET_ALL_BANKING_BALANCES.FAILURE:
		case GET_ALL_BANKING_SETTINGS.FAILURE:
		case UPDATE_BANKING_SETTINGS.FAILURE:
		case MAKE_DECISION_BY_PENDING_TRANSACTION.FAILURE:
		case UPDATE_ALL_BANKING_BALANCES.FAILURE:
		case GET_BANKING_HISTORY.FAILURE:
		case GET_LIQUIDITY_PROVIDERS_SETTINGS.FAILURE:
		case GET_LIQUIDITY_PROVIDERS_OFFERS.FAILURE:
			return { ...state, status: false, error: action.payload.error };
		case CREATE_NEW_PROVIDER.SUCCESS:
		case UPDATE_PROVIDER.SUCCESS:
		case DELETE_PROVIDER.SUCCESS:
		case UPDATE_BANKING_SETTINGS.SUCCESS:
		case MAKE_DECISION_BY_PENDING_TRANSACTION.SUCCESS:
		case UPDATE_ALL_BANKING_BALANCES.SUCCESS:
			return { ...state, status: true };
		case GET_BANKING_HISTORY.SUCCESS:
			return {
				...state,
				history: action.payload,
			};
		case GET_PROVIDERS.SUCCESS:
			return {
				...state,
				providers: action.payload?.content,
			};
		case GET_ALL_BANKING_BALANCES.SUCCESS:
			return {
				...state,
				balances: action.payload,
			};
		case GET_ALL_BANKING_SETTINGS.SUCCESS:
			return {
				...state,
				settings: action.payload,
			};
		case GET_LIQUIDITY_PROVIDERS_SETTINGS.SUCCESS:
			return {
				...state,
				liquidityProviders: action.payload,
			}
		case GET_LIQUIDITY_PROVIDERS_OFFERS.SUCCESS:
			return {
				...state,
				liquidityProvidersOffers: action.payload,
			}
		case CLEAR_BANKING_STATUS:
			return {
				...state,
				status: false,
				error: null,
			};
		case CLEAR_BANKING_ERROR:
			return {
				...state,
				error: null,
				status: false,
			};
		default:
			return state;
	}
}
