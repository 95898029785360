import React from "react";
import { makeStyles } from "@material-ui/core";

const FormError = props => {
	const classes = useStyles();
	if (props?.isHidden) {
		return null;
	}
	return (
		<div className={classes.errorField} style={props?.style}>
			{props?.errorMessage}
		</div>
	);
};
export default FormError;

const useStyles = makeStyles(theme => ({
	errorField: {
		color: theme.palette.text.error,
		fontSize: 12,
		position: "absolute",
	},
}));
