import React, { useCallback, useEffect, useState } from "react";
import { IconButton, TablePagination } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Visibility } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import PowerIcon from "@material-ui/icons/PowerSettingsNew";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import EditCompanyDialog from "./CreateCompanyDialog";
import CopyWithAlert from "../../utils/CopyWithAlert";
import Table from "../../theme/components/Table/Table";
import {
	clearCompaniesList,
	deactivateCompany,
	activateCompany,
	getCompaniesList,
	setCompaniesListParams,
} from "../../app/actions/companiesActions";
import DeleteConfirmDialog from "../../theme/components/dialogs/DeleteConfirmDialog";
import getStatusColor from "../../utils/functions/getStatusColor";

const columns = [
	{ key: "id", headerName: "ID" },
	{ key: "companyName", headerName: "Company name" },
	{ key: "shortName", headerName: "Short name" },
	{ key: "status", headerName: "Status" },
	{ key: "manage", headerName: "Manage" },
];

const DEFAULT_PARAMS = { page: 0, size: 10 };

const CompaniesTab = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { content, params, totalElements } = useSelector(
		({ companies }) => companies?.companies
	);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showActivateModal, setShowActivateModal] = useState(false);
	const [selectedCompanyData, setSelectedCompanyData] = useState(null);

	useEffect(() => {
		(params?.size || params?.page) &&
			dispatch(getCompaniesList({ ...DEFAULT_PARAMS, ...params }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, params?.size, params?.page]);

	useEffect(() => {
		if (
			history?.location?.state?.from === "companyDetails" &&
			history?.location?.state?.params
		) {
			dispatch(
				setCompaniesListParams(JSON.parse(history?.location?.state?.params))
			);
		} else {
			dispatch(setCompaniesListParams({ page: 0, size: 10, search: "" }));
		}
	}, [dispatch, history.location]);

	useEffect(() => {
		return () => {
			dispatch(setCompaniesListParams(null));
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(
			setCompaniesListParams({
				size: DEFAULT_PARAMS.size,
				...params,
				page: newPage,
			})
		);
	};

	const handleChangeSize = ({ target }) => {
		dispatch(
			setCompaniesListParams({
				...params,
				size: target.value,
				page: DEFAULT_PARAMS.page,
			})
		);
	};

	const handleManage = (data) => {
		dispatch(clearCompaniesList());
		history.push(`/companies/${data?.id}/?params=${JSON.stringify(params)}`, {
			from: "/companies",
			params: JSON.stringify(params),
		});
	};

	const handleEdit = (data) => () => {
		setSelectedCompanyData(data);
		setShowCreateModal(true);
	};

	const handleCloseCreateModal = () => {
		setShowCreateModal(false);
		setSelectedCompanyData(null);
	};

	const handleDelete = (data) => () => {
		setShowDeleteModal(true);
		setSelectedCompanyData(data);
	};
	const handleActivate = (data) => () => {
		setShowActivateModal(true);
		setSelectedCompanyData(data);
	};

	const handleCloseDeleteModal = useCallback(
		({ accept }) => {
			if (accept) {
				dispatch(deactivateCompany(selectedCompanyData?.id, params));
			}
			setShowDeleteModal(false);
			setSelectedCompanyData(null);
		},
		[dispatch, selectedCompanyData?.id, params]
	);
	const handleCloseActivateModal = useCallback(
		({ accept }) => {
			if (accept) {
				dispatch(activateCompany(selectedCompanyData?.id, params));
			}
			setShowActivateModal(false);
			setSelectedCompanyData(null);
		},
		[dispatch, selectedCompanyData?.id, params]
	);

	return (
		<div>
			<EditCompanyDialog
				open={Boolean(showCreateModal)}
				handleClose={handleCloseCreateModal}
				data={selectedCompanyData}
				params={params}
			/>
			<DeleteConfirmDialog
				open={showDeleteModal}
				title='Deactivate Company'
				message='Are you sure you want to deactivate selected company?'
				handleClose={handleCloseDeleteModal}
			/>
			<DeleteConfirmDialog
				open={showActivateModal}
				title='Activate Company'
				message='Are you sure you want to activate selected company?'
				handleClose={handleCloseActivateModal}
			/>
			<div>
				<Table>
					<thead>
						<tr>
							{columns.map(({ key, headerName, align }) => (
								<th key={key} align={align || "left"}>
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{content?.length > 0 ? (
							content?.map((row, index) => (
								<TableRow
									key={row?.id || index}
									rowData={row}
									onManage={handleManage}
									onDelete={handleDelete}
									onActivate={handleActivate}
									onEdit={handleEdit}
								/>
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				<TablePagination
					rowsPerPageOptions={[10, 30, 50]}
					component='div'
					count={totalElements}
					rowsPerPage={params?.size || 10}
					page={params?.page || 0}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeSize}
				/>
			</div>
		</div>
	);
};

export default CompaniesTab;

function TableRow({ rowData, onManage, onDelete, onEdit, onActivate }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<td>{rowData?.companyName}</td>
				<td>{rowData?.shortName}</td>
				<td width={'15%'} style={{ color: getStatusColor(rowData?.status) }}>{rowData?.status}</td>
				<td width={"15%"}>
					<IconButton onClick={() => onManage(rowData)}>
						<Visibility />
					</IconButton>
					<IconButton onClick={onEdit(rowData)}>
						<EditIcon />
					</IconButton>
					{
						rowData?.status === 'ACTIVE' ?
							<IconButton onClick={onDelete(rowData)}>
								<DeleteIcon style={{ color: "#ff0000" }} />
							</IconButton> :
							<IconButton onClick={onActivate(rowData)}>
								<PowerIcon style={{ color: "#60CF61" }} />
							</IconButton>
					}
				</td>
			</tr >
		</>
	);
}
