import moment from "moment";
import { IconButton, TablePagination } from "@material-ui/core";
import Table from "../../theme/components/Table/Table";
import { Visibility } from "@material-ui/icons";
import CopyWithAlert from "../../utils/CopyWithAlert";
import getStatusColor from "../../utils/functions/getStatusColor";
import { COUNTRIES } from "../../utils/constants/kyc";

const KycHistoryTable = ({
	data,
	params,
	total,
	columns,
	isTabsAll,
	onPageChange,
	onRowsPerPageChange,
	onManage,
}) => {
	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, headerName, colSpan, align }) => (
							<th key={key} colSpan={colSpan} align={align || "left"}>
								{headerName}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data?.length > 0 ? (
						data?.map((item, index) => (
							<tr key={index}>
								<CopyWithAlert text={item?.userId + "" || ""} label={"userId"}>
									<td data='hover'>{item?.userId}</td>
								</CopyWithAlert>
								<CopyWithAlert
									text={item?.firstName + "" || ""}
									label={"First Name"}>
									<td data='hover'>{item?.firstName}</td>
								</CopyWithAlert>
								<CopyWithAlert
									text={item?.lastName + "" || ""}
									label={"Last Name"}>
									<td data='hover'>{item?.lastName}</td>
								</CopyWithAlert>
								<CopyWithAlert
									text={item?.middleName + "" || ""}
									label={"Middle Name"}>
									<td data='hover'>{item?.middleName}</td>
								</CopyWithAlert>
								<td>{item?.dob}</td>
								<CopyWithAlert text={item?.gender + "" || ""} label={"Gender"}>
									<td data='hover'>{item?.gender}</td>
								</CopyWithAlert>
								<CopyWithAlert
									text={item?.countryCode + "" || ""}
									label={"Country"}>
									<td data='hover'>{COUNTRIES[item?.countryCode]}</td>
								</CopyWithAlert>
								<CopyWithAlert text={item?.status || ""} label={"Status"}>
									<td
										data='hover'
										style={{
											color: getStatusColor(item?.status),
										}}>
										{item?.status || "-"}
									</td>
								</CopyWithAlert>
								<td>
									{item?.creationDate
										? moment(item?.creationDate).format("DD MMM YYYY HH:mm")
										: "-"}
								</td>
								{isTabsAll && (
									<>
										<td>
											{item?.completeDate
												? moment(item?.completeDate).format("DD MMM YYYY HH:mm")
												: "-"}
										</td>
										<td>
											{item?.expirationDate
												? moment(item?.expirationDate).format(
														"DD MMM YYYY HH:mm"
												  )
												: "-"}
										</td>
									</>
								)}
								<td>
									<IconButton onClick={() => onManage(item?.id)}>
										<Visibility />
									</IconButton>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 30, 50]}
				component='div'
				count={total || 0}
				rowsPerPage={params.find(x => x.field === "size").value}
				page={params.find(x => x.field === "page").value}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
			/>
		</>
	);
};

export default KycHistoryTable;
