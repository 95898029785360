import {
	Grid,
	IconButton,
	makeStyles,
	Paper,
	Typography,
} from "@material-ui/core";
import SlicedAddress from "../../theme/components/SlicedAddress";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import CopyWithAlert from "../../utils/CopyWithAlert";
import PropTypes from "prop-types";
import clsx from "clsx";

const Address = ({ variant, address, short }) => {
	const classes = useStyles();

	return (
		<Paper
			variant={variant || "elevation"}
			elevation={0}
			className={clsx(classes.root, short && "short")}>
			<Grid
				container
				spacing={2}
				justifyContent={"space-between"}
				alignItems={"center"}>
				<Grid item xs={7} sm={8}>
					<Typography
						variant={"body1"}
						color={"textSecondary"}
						style={{ fontWeight: 700, marginBottom: 8 }}>
						Address
					</Typography>
					<Typography
						variant={"body1"}
						color={"textPrimary"}
						style={{ fontWeight: 700 }}>
						<SlicedAddress address={address} short={short} />
					</Typography>
				</Grid>
				<Grid item>
					<CopyWithAlert text={address || ""} label={"Address"}>
						<IconButton size={"small"}>
							<CopyIcon fontSize={"small"} />
						</IconButton>
					</CopyWithAlert>
				</Grid>
			</Grid>
		</Paper>
	);
};

Address.prpTypes = {
	address: PropTypes.string.isRequired,
	variant: PropTypes.oneOf(["contained", "outlined"]),
	short: PropTypes.bool,
};

export default Address;

const useStyles = makeStyles(theme => ({
	root: {
		padding: 24,
		"&.short": {
			[theme.breakpoints.down("xs")]: {
				padding: 16,
				"& svg": {
					fontSize: 18,
				},
			},
		},
	},
}));
