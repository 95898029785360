import { useState } from "react";
import { Typography } from "@material-ui/core";
import { Button } from "../../theme/components/Button";
import { Input } from "../../theme/components/Input";
import { login } from "../../app/actions/accountActions";
import { useDispatch } from "react-redux";

export const OneTimePassword = ({ form }) => {
	const dispatch = useDispatch();
	const [secondAuthCode, setSecondAuthCode] = useState("");

	const handleSubmit = (event) => {
		event.preventDefault();
		dispatch(login({ ...form, secondAuthCode }));
		setSecondAuthCode("");
	};

	return (
		<div>
			<Typography variant='h2'>Google Authenticator (2FA)</Typography>
			<form onSubmit={handleSubmit}>
				<Input
					placeholder='Enter Google Authenticator code'
					variant='outlined'
					name='secondAuthCode'
					type='number'
					autoComplete='off'
					onChange={(e) => setSecondAuthCode(e.target.value)}
					value={secondAuthCode}
				/>
			</form>
			<Button
				type='submit'
				variant='contained'
				color='primary'
				onClick={handleSubmit}>
				Verify code
			</Button>
		</div>
	);
};
