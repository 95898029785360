import {
	GET_ACCOUNT_DATA_FAILURE,
	GET_ACCOUNT_DATA_STARTED,
	GET_ACCOUNT_DATA_SUCCESS,
	LOG_OUT,
	LOGIN_FAILURE,
	LOGIN_STARTED,
	LOGIN_SUCCESS,
	LOGIN_TWO_FA_SUCCESS,
	ENABLE_TWO_FA_STARTED,
	ENABLE_TWO_FA_SUCCESS,
	ENABLE_TWO_FA_FAILURE,
	DISABLE_TWO_FA_STARTED,
	DISABLE_TWO_FA_SUCCESS,
	DISABLE_TWO_FA_FAILURE,
	CHECK_IS_TWO_FA_STARTED,
	CHECK_IS_TWO_FA_SUCCESS,
	CHECK_IS_TWO_FA_FAILURE,
	CHECK_CODE_TWO_FA_STARTED,
	CHECK_CODE_TWO_FA_SUCCESS,
	CHECK_CODE_TWO_FA_FAILURE,
	CHECK_INSTALL_TWO_FA_STARTED,
	CHECK_INSTALL_TWO_FA_SUCCESS,
	CHECK_INSTALL_TWO_FA_FAILURE,
	CLEAR_TWO_FA_QR_CODE,
	CHANGE_PASSWORD_FAILURE,
	CHANGE_PASSWORD_STARTED,
	SEND_REGISTRATION_MESSAGE_FAILURE,
	SEND_REGISTRATION_MESSAGE_STARTED,
} from "../types";

export default function account(
	state = {
		authorized: false,
		isTwoFA: false,
		qrCode: "",
		data: null,
		error: null,
		passError: null,
	},
	action
) {
	switch (action.type) {
		case LOGIN_STARTED:
		case GET_ACCOUNT_DATA_STARTED:
		case ENABLE_TWO_FA_STARTED:
		case DISABLE_TWO_FA_STARTED:
		case CHECK_IS_TWO_FA_STARTED:
		case CHECK_CODE_TWO_FA_STARTED:
		case CHECK_INSTALL_TWO_FA_STARTED:
		case SEND_REGISTRATION_MESSAGE_STARTED:
			return { ...state, error: null };
		case LOGIN_SUCCESS:
			return { ...state, authorized: true };
		case LOGIN_TWO_FA_SUCCESS:
			return { ...state, isTwoFA: true, authorized: false };
		case GET_ACCOUNT_DATA_SUCCESS:
			return {
				...state,
				data: action.payload,
				authorized: true,
			};

		case CHECK_IS_TWO_FA_SUCCESS:
			return { ...state, isTwoFA: action.payload, authorized: !action.payload };
		case CHECK_INSTALL_TWO_FA_SUCCESS:
			return {
				...state,
				isTwoFA: action.payload,
			};
		case CHECK_CODE_TWO_FA_SUCCESS:
			return { ...state, authorized: action.payload };
		case ENABLE_TWO_FA_SUCCESS:
			return { ...state, qrCode: action.payload };
		case DISABLE_TWO_FA_SUCCESS:
		case CLEAR_TWO_FA_QR_CODE:
			return { ...state, qrCode: "" };
		case LOG_OUT:
			return {
				...state,
				authorized: false,
				isTwoFA: false,
				qrCode: "",
				data: null,
				error: null,
				passError: null,
			};
		case LOGIN_FAILURE:
		case GET_ACCOUNT_DATA_FAILURE:
		case ENABLE_TWO_FA_FAILURE:
		case DISABLE_TWO_FA_FAILURE:
		case CHECK_IS_TWO_FA_FAILURE:
		case CHECK_CODE_TWO_FA_FAILURE:
		case CHECK_INSTALL_TWO_FA_FAILURE:
		case SEND_REGISTRATION_MESSAGE_FAILURE:
			return { ...state, error: action.payload.error };
		case CHANGE_PASSWORD_STARTED:
			return { ...state, passError: null };
		case CHANGE_PASSWORD_FAILURE:
			return { ...state, passError: action.payload.error };
		default:
			return state;
	}
}
