import React, { useMemo } from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {
	clearActiveFilters,
	deleteActiveFilter,
} from "../../../app/actions/filterActions";

const ActiveFiltersPanel = ({ filterFields }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	const data = useMemo(() => {
		if (!activeFilters.length) {
			return [];
		}
		return activeFilters?.filter(x => !["size", "page"].includes(x.field));
	}, [activeFilters]);

	const deleteFilter = item => {
		const filters = data?.filter(filter => filter.field !== item.field);
		dispatch(deleteActiveFilter(filters));
	};

	const deleteAllFilters = () => {
		dispatch(clearActiveFilters());
	};
	const getTitle = field => {
		return filterFields?.find(i => i?.field === field).title;
	};

	return (
		<>
			{data.length > 0 && (
				<div className={classes.root}>
					<div className={classes.titleWrap}>
						<Typography color={"textPrimary"} className={classes.title}>
							Active filters
						</Typography>
						<Button
							color='primary'
							className={classes.deleteBtn}
							onClick={deleteAllFilters}>
							Remove all filters
						</Button>
					</div>
					<div className={classes.tagWrap}>
						{data.map((item, index) => (
							<div key={item?.field || index} className={classes.tag}>
								<div className={classes.tagTitle}>
									{`${getTitle(item?.field)}:  `}
									<span className={classes.tagValue}>{item?.value}</span>
								</div>
								<div
									className={classes.closeBtn}
									onClick={() => deleteFilter(item)}>
									<CloseIcon />
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};

export default ActiveFiltersPanel;

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		padding: 8,
		border: "1px solid #18A0FB",
		borderRadius: 8,
		marginBottom: 16,
		"&>.MuiTypography-root": {
			fontSize: 16,
			fontWeight: 600,
			marginBottom: 8,
		},
	},
	deleteBtn: {
		color: theme.palette.primary.light,
	},
	tagWrap: {
		display: "flex",
		flexWrap: "wrap",
	},
	tag: {
		display: "flex",
		height: 36,
		backgroundColor: theme.palette.background.paper,
		border: "1px solid theme.palette.text.secondary",
		borderRadius: 4,
		alignItems: "center",
		marginRight: 12,
		marginBottom: 8,
	},
	tagTitle: {
		fontSize: 14,
		color: theme.palette.text.primary,
		padding: "8px 10px 8px 12px",
	},
	tagValue: {
		fontWeight: 600,
		fontStyle: "italic",
	},
	closeBtn: {
		display: "flex",
		alignItems: "center",
		padding: 0,
		margin: 0,
		marginRight: 12,
		cursor: "pointer",
		"&>.MuiSvgIcon-root": {
			width: 19,
			height: 19,
		},
	},
	titleWrap: {
		display: "flex",
		justifyContent: "space-between",
		"&>.MuiButton-root": {
			fontSize: 14,
		},
	},
}));
