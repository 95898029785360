import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	TablePagination,
	IconButton,
	Grid,
	Typography,
} from "@material-ui/core";
import Table from "../../../../../theme/components/Table/Table";
import { Button } from "../../../../../theme/components/Button";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../../app/actions/filterActions";
import { getVipReferralSettings } from "../../../../../app/actions/referralsActions";
import { VIP_REFERRAL_SETTINGS_COLUMNS } from "../../../../../utils/constants/referrals";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditVipSettingsDialog from "./EditVipSettingsDialog";
import DeleteVipSettingsDialog from "./DeleteVipSettingsDialog";

const VipReferralSettings = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [create, setCreate] = useState(false);
	const [editSetting, setEditSetting] = useState(false);
	const [deleteSetting, setDeleteSetting] = useState(null);
	const [params, setParams] = useState(null);
	const { vipReferralSettings } = useSelector(({ referrals }) => referrals);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		setParams(filteringParams);
		dispatch(getVipReferralSettings(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	const goToUserProfile = (userId) => {
		history.push(`/users/list/${null}/${userId}`);
	};

	return (
		<>
			<DeleteVipSettingsDialog
				open={Boolean(deleteSetting)}
				handleClose={() => setDeleteSetting(null)}
				params={params}
				{...deleteSetting}
			/>
			<EditVipSettingsDialog
				open={Boolean(editSetting) || create}
				handleClose={() => {
					setEditSetting(null);
					setCreate(false);
				}}
				data={editSetting}
				params={params}
			/>
			<Grid container justifyContent='space-between'>
				<Grid>
					<Typography
						variant={"body1"}
						color={"textPrimary"}
						style={{ marginBottom: 0 }}>
						VIP settings
					</Typography>
				</Grid>
				<Button
					variant='outlined'
					color='primary'
					style={{ marginBottom: 8, padding: "4px 28px" }}
					onClick={() => setCreate(true)}>
					<AddIcon />
					Create
				</Button>
			</Grid>

			<Table>
				<thead>
					<tr>
						{VIP_REFERRAL_SETTINGS_COLUMNS?.map(
							({ key, headerName, colSpan, align }) => (
								<th key={key} colSpan={colSpan} align={align || "left"}>
									{headerName}
								</th>
							)
						)}
					</tr>
				</thead>
				<tbody>
					{vipReferralSettings?.content?.length > 0 ? (
						vipReferralSettings?.content?.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
								handleEdit={() => setEditSetting(rowData)}
								handleDelete={() => setDeleteSetting(rowData)}
								goToUserProfile={goToUserProfile}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={vipReferralSettings?.totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default VipReferralSettings;

function TableRow({ rowData, goToUserProfile, handleDelete, handleEdit }) {
	return (
		<>
			<tr>
				<td>{rowData?.id}</td>
				<td data='hover' onClick={() => goToUserProfile(rowData?.userId)}>
					{rowData?.userId}
				</td>
				<td>{+rowData?.parentRewardPercent}</td>
				<td>{+rowData?.grandParentRewardPercent}</td>
				<td>
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={handleDelete}>
						<DeleteIcon style={{ color: "#ff0000" }} />
					</IconButton>
				</td>
			</tr>
		</>
	);
}
