export const generateActionTypes = actionType => ({
	STARTED: `${actionType}_STARTED`,
	SUCCESS: `${actionType}_SUCCESS`,
	FAILURE: `${actionType}_FAILURE`,
});

export const actionCreator = actionType => {
	return {
		started: () => ({
			type: actionType.STARTED,
		}),
		done: data => ({
			type: actionType.SUCCESS,
			payload: data,
		}),
		failed: error => ({
			type: actionType.FAILURE,
			payload: {
				error,
			},
		}),
	};
};
