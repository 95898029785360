import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Table from "../../../theme/components/Table/Table";
import moment from "moment";
import { TablePagination } from "@material-ui/core";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import { clearActiveFilters } from "../../../app/actions/filterActions";
import { clearSyncHistory } from "../../../app/actions/syncActions";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "programId", label: "Program Id" },
	{ key: "symbol", label: "Symbol" },
	{ key: "type", label: "Type" },
	{ key: "orderId", label: "Order Id" },
	{ key: "clientOrderId", label: "Client Order Id" },
	{ key: "executedQty", label: "ExecutedQty" },
	{ key: "commission", label: "Commission" },
	{ key: "origQty", label: "OrigQty" },
	// { key: "price", label: "Price" },
	{ key: "side", label: "Side" },
	{ key: "executionTime", label: "Created" },
];

const BinanceOrderHistoryTable = ({
	data,
	params,
	total,
	onPageChange,
	onRowsPerPageChange,
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearSyncHistory());
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody style={{ position: "relative" }}>
					{data?.length > 0 ? (
						data.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 30, 50]}
				component='div'
				count={total}
				rowsPerPage={params.find(x => x.field === "size").value}
				page={params.find(x => x.field === "page").value}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
			/>
		</>
	);
};

export default BinanceOrderHistoryTable;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.programId + "" || ""}
					label={"Program Id"}>
					<td data='hover'>{rowData?.programId}</td>
				</CopyWithAlert>
				<td>{rowData?.symbol}</td>
				<td>{rowData?.type}</td>
				<CopyWithAlert text={rowData?.orderId + "" || ""} label={"Order Id"}>
					<td data='hover'>{rowData?.orderId}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.clientOrderId + "" || ""}
					label={"Client Order Id"}>
					<td data='hover'>{rowData?.clientOrderId}</td>
				</CopyWithAlert>
				<td>{rowData?.executedQty}</td>
				<td>{rowData?.commission}</td>
				<td>{rowData?.origQty}</td>
				{/* <td>{rowData?.price}</td> */}
				<td>{rowData?.side}</td>
				<td>
					{rowData?.executionTime &&
						moment(rowData?.executionTime).format("DD MMM YYYY HH:mm")}
				</td>
			</tr>
		</>
	);
}
