import moment from "moment";

export function setErrorMessage(field) {
	switch (field) {
		case "firstName":
			return "Please enter first name!";
		case "lastName":
			return "Please enter last name!";
		case "email":
			return "Please enter valid email address!";
		case "authority":
			return "Please enter role!";
		case "login":
			return "Please enter login!";
		case "invalidDate":
			return "Invalid Date Format.";
		case "invalidMaxDate":
			return "Date should not be after maximal date.";
		case "invalidRangeDate":
			return 'Date "from" must precede date "to".';
		case "invalidRangeNumber":
			return 'Number "more" must precede date "less".';
		case "passwordRuleShort":
			return "At least 8 characters, uppercase and lowercase letters, at least one number.";
		case "currentPassword":
			return "Please enter current password!";
		case "newPassword":
			return "Please enter new password!";
		case "confirmPassword":
			return "Please repeat new password!";
		case "invalidMatchingPassword":
			return "Passwords do not match.!";
		case "shortName":
			return "The field should contain exactly 2 characters";
		case "minAmount": 
			return "The amount should be more then 0."
		default:
			return "This is a required field!";
	}
}

export function changePasswordValidate(name, value, form) {
	// required
	const fieldArray = ["currentPassword", "newPassword", "confirmPassword"];
	const regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");

	if (!value && fieldArray.includes(name)) {
		return {
			invalid: true,
			errorMessage: setErrorMessage("invalidPassword"),
		};
	}

	if (!regex.test(value)) {
		return {
			invalid: true,
			errorMessage: setErrorMessage("passwordRuleShort"),
		};
	}

	// matchPasswordOnSecondField
	if (
		(name === "confirmPassword" &&
			!!value &&
			!!form?.newPassword &&
			form?.newPassword !== value) ||
		(name === "newPassword" &&
			!!value &&
			!!form?.confirmPassword &&
			form?.confirmPassword !== value)
	) {
		return {
			invalid: true,
			errorMessage: setErrorMessage("invalidMatchingPassword"),
		};
	}

	// default
	return {
		invalid: false,
		errorMessage: "",
	};
}

export function validatePassword(name, value, form) {
	// passwordRule
	const regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");

	if (name.toLowerCase().includes("password") && !regex.test(value)) {
		return {
			invalid: true,
			errorMessage: setErrorMessage("passwordRuleShort"),
		};
	}

	// default
	return {
		invalid: false,
		errorMessage: "",
	};
}

export function validateInput(name, value, form, isFilter = false) {
	const validEmailRegex = new RegExp(
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);

	// required
	const fieldArray = [
		"firstName",
		"lastName",
		"authorities",
		"email",
		"username",
		"password",
		"companyName",
		"shortName",
	];

	if (!value && !isFilter && fieldArray.includes(name)) {
		return {
			invalid: true,
			errorMessage: setErrorMessage(name),
		};
	}

	// email
	if (name === "email" && !validEmailRegex.test(value)) {
		return {
			invalid: true,
			errorMessage: setErrorMessage(name),
		};
	}

	// shortName (2 symbols only)
	if (name === "shortName" && value?.length !== 2) {
		return {
			invalid: true,
			errorMessage: setErrorMessage(name),
		};
	}

	// date
	if (
		(name === "dateFrom" || name === "dateTo") &&
		value &&
		!moment(value).isValid()
	) {
		return {
			invalid: true,
			errorMessage: setErrorMessage("invalidDate"),
		};
	}

	// maxDate
	if ((name === "dateFrom" || name === "dateTo") && moment().isBefore(value)) {
		return {
			invalid: true,
			errorMessage: setErrorMessage("invalidMaxDate"),
		};
	}

	// numberRangeValidator
	if (
		(name === "amountMore" &&
			!!value &&
			!!form?.amountLess &&
			+form?.amountLess < value) ||
		(name === "amountLess" &&
			!!value &&
			!!form?.amountMore &&
			+form?.amountMore > value) ||
		(name === "totalMore" &&
			!!value &&
			!!form?.totalLess &&
			+form?.totalLess < value) ||
		(name === "totalLess" &&
			!!value &&
			!!form?.totalMore &&
			+form?.totalMore > value)
	) {
		return {
			invalid: false,
			errorMessage: setErrorMessage("invalidRangeNumber"),
		};
	}

	// dateRangeValidator
	if (
		(name === "dateTo" &&
			!!value &&
			!!form?.dateFrom &&
			!moment(form?.dateFrom).isBefore(value)) ||
		(name === "dateFrom" &&
			!!value &&
			!!form?.dateTo &&
			!moment(value).isBefore(form?.dateTo))
	) {
		return {
			invalid: true,
			errorMessage: setErrorMessage("invalidRangeDate"),
		};
	}

	// default
	return {
		invalid: false,
		errorMessage: "",
	};
}

export function requiredValidator(_, value) {
	// required
	if (typeof value !== "boolean" && !value) {
		return {
			invalid: true,
			errorMessage: "This is a required field!",
		};
	}

	// default
	return {
		invalid: false,
		errorMessage: "",
	};
}

export function numberDigitsAfterDotValidator(value, numberDigits) {
	if (typeof +value !== "number") {
		return {
			invalid: false,
			errorMessage: "",
		};
	}

	const str = value.toString();
	var after;
	if (str.indexOf("e") === -1) {
		const result = str.split(".").map((i) => i.length);
		after = result[1] || 0;
		if (after > numberDigits) {
			return {
				invalid: true,
				errorMessage: `Scale should be less or equal to ${numberDigits}`,
			};
		}
	}

	const exponent = parseInt(str.split("-")[1], 10);
	const result = Number(value)
		.toFixed(exponent)
		.split(".")
		.map((i) => i.length);
	after = result[1] || 0;
	if (after > numberDigits) {
		return {
			invalid: true,
			errorMessage: `Scale should be less or equal to ${numberDigits}`,
		};
	}

	// default
	return {
		invalid: false,
		errorMessage: "",
	};
}

export const checkValueIsNumber = (value) => {
	return (
		/^[0-9]*\.?[0-9]*$/.test(value) &&
		!(value.length > 1 && value[0] === "0" && value[1] !== ".")
	);
};
