import {
	CLEAR_INTERNAL_BALANCES,
	GET_INTERNAL_BALANCES_FAILURE,
	GET_INTERNAL_BALANCES_STARTED,
	GET_INTERNAL_BALANCES_SUCCESS,
	SET_INTERNAL_BALANCES_PARAMS,
	SORT_INTERNAL_BALANCES_LIST,
	FILTER_INTERNAL_BALANCES_LIST,
} from "../types";
import { sorting, filtering } from "../../utils/functions/filteringTable";

export default function internalBalances(
	state = {
		data: [],
		filteredData: [],
		error: null,
		params: { page: 0, size: 10 },
		total: 0,
	},
	action
) {
	switch (action.type) {
		case GET_INTERNAL_BALANCES_STARTED:
			return { ...state, error: null };
		case GET_INTERNAL_BALANCES_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				filteredData: action.payload.data,
				total: action.payload.total,
			};
		case SORT_INTERNAL_BALANCES_LIST:
			return {
				...state,
				filteredData: sorting(
					[...state.filteredData],
					action.payload.direction,
					action.payload.key,
					action.payload.type
				),
			};
		case FILTER_INTERNAL_BALANCES_LIST:
			return {
				...state,
				filteredData: filtering([...state.data], action.payload),
			};
		case SET_INTERNAL_BALANCES_PARAMS:
			return { ...state, params: action.payload };
		case GET_INTERNAL_BALANCES_FAILURE:
			return { ...state, error: action.payload.error };
		case CLEAR_INTERNAL_BALANCES:
			return {
				...state,
				data: [],
				filteredData: [],
				error: null,
				params: { page: 0, size: 10 },
				total: 0,
			};

		default:
			return state;
	}
}
