import { collection, doc, getDocs, updateDoc, query, orderBy } from "firebase/firestore";
import {
	GET_COMPANIES_LIST,
	SET_COMPANIES_LIST_PARAMS,
	CLEAR_COMPANIES_LIST,
	CREATE_COMPANY,
	UPDATE_COMPANY,
	GET_COMPANIES_BY_NAME,
	GET_COMPANY_BALANCES,
	CREATE_COMPANY_BALANCE,
	CLEAR_COMPANY_BALANCES,
	GET_COMPANY_BALANCE_USERS,
	CREATE_COMPANY_BALANCE_USER,
	CLEAR_COMPANY_BALANCE_USERS,
	GET_COMPANY_BALANCE_TRANSFER_HISTORY,
	GET_NEW_COMPANIES_REQUEST_LIST,
	CREATE_COMPANY_BALANCE_SECURITY_SETTINGS,
	CREATE_COMPANY_BALANCE_USER_FOR_SECURITY_SETTINGS,
	GET_COMPANY_BALANCE_USERS_OF_SECURITY_SETTINGS,
	GET_COMPANY_BALANCE_SECURITY_SETTINGS_LIST,
	CLEAR_COMPANY_BALANCE_SECURITY_SETTINS,
	GET_COMPANY_BALANCE_TRANSFER_INVOICES,
	CHANGE_NEW_COMPANY_REQUEST_STATUS,
} from "../types";
import api from "../api";
import { enqueueSnackbar } from "./notistackActions";
import { actionCreator } from "../utils";
import { firebaseCollections, firebaseDB } from "../firebase";
import { COMPANY_REQUEST_STATUS } from "../../utils/constants/companies";

export const getCompaniesListAction = actionCreator(GET_COMPANIES_LIST);

export const getCompaniesList = (params) => {
	return async (dispatch) => {
		dispatch(getCompaniesListAction.started());
		api.companies
			.getAllCompanies(params)
			.then((res) => dispatch(getCompaniesListAction.done(res.data)))
			.catch((error) => dispatch(getCompaniesListAction.failed(error)));
	};
};

export const setCompaniesListParams = (payload) => ({
	type: SET_COMPANIES_LIST_PARAMS,
	payload,
});

export const clearCompaniesList = () => ({
	type: CLEAR_COMPANIES_LIST,
});

export const deleteCompany = (companyId, params) => {
	return async (dispatch) => {
		api.companies.deleteCompany(companyId).finally(() => {
			dispatch(setCompaniesListParams({ ...params, page: 0, search: '' }));
			dispatch(getCompaniesList({ ...params, page: 0 }));
		});
	};
};

export const deactivateCompany = (companyId, params) => {
	return async (dispatch) => {
		api.companies
			.deactivateCompany(companyId).finally(() => {
				dispatch(setCompaniesListParams({ ...params, page: 0, search: '' }));
				dispatch(getCompaniesList({ ...params, page: 0 }));
			})
	};
};

export const activateCompany = (companyId, params) => {
	return async (dispatch) => {
		api.companies
			.activateCompany(companyId).finally(() => {
				dispatch(setCompaniesListParams({ ...params, page: 0, search: '' }));
				dispatch(getCompaniesList({ ...params, page: 0 }));
			})
	};
};

export const createCompanyAction = actionCreator(CREATE_COMPANY);

export const createCompany = (data) => {
	return async (dispatch) => {
		dispatch(createCompanyAction.started());
		api.companies
			.createCompany(data)
			.then((res) => {
				dispatch(createCompanyAction.done(res.data));
				dispatch(getCompaniesList({ page: 0, size: 10 }));
				dispatch(
					enqueueSnackbar({
						message: `${data.companyName} successfully created`,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => {
				dispatch(createCompanyAction.failed(error));
				dispatch(
					enqueueSnackbar({
						message: error.response.data.title,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "error",
							autoHideDuration: 3000,
						},
					})
				);
			});
	};
};

export const updateCompanyAction = actionCreator(UPDATE_COMPANY);

export const updateCompany = ({ id, companyName, shortName, params }) => {
	return async (dispatch) => {
		dispatch(updateCompanyAction.started());
		api.companies
			.updateCompany({ id, companyName, shortName })
			.then((res) => {
				dispatch(updateCompanyAction.done(res.data));
				dispatch(getCompaniesList(params || { page: 0, size: 10 }));
				dispatch(
					enqueueSnackbar({
						message: "Activity saved",
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => dispatch(updateCompanyAction.failed(error.response)));
	};
};

export const getCompaniesByNameAction = actionCreator(GET_COMPANIES_BY_NAME);

export const getCompaniesByName = (searchValue) => {
	return async (dispatch) => {
		dispatch(getCompaniesByNameAction.started());
		api.companies
			.getCompaniesByName(searchValue)
			.then((res) => dispatch(getCompaniesByNameAction.done(res.data)))
			.catch((error) => dispatch(getCompaniesByNameAction.failed(error)));
	};
};

export const getCompanyBalancesAction = actionCreator(GET_COMPANY_BALANCES);

export const getCompanyBalances = (companyId) => {
	return async (dispatch) => {
		dispatch(getCompanyBalancesAction.started());
		api.companies
			.getCompanyBalances(companyId)
			.then((res) => dispatch(getCompanyBalancesAction.done(res.data)))
			.catch((error) => dispatch(getCompanyBalancesAction.failed(error)));
	};
};

export const createCompanyBalanceAction = actionCreator(CREATE_COMPANY_BALANCE);

export const createCompanyBalance = ({ companyId, currency }) => {
	return async (dispatch) => {
		dispatch(createCompanyBalanceAction.started());
		api.companies
			.createCompanyBalance({ companyId, currency })
			.then((res) => {
				dispatch(createCompanyBalanceAction.done(res.data));
				dispatch(getCompanyBalances(companyId));
				dispatch(
					enqueueSnackbar({
						message: `Balance successfully created`,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => {
				dispatch(createCompanyBalanceAction.failed(error?.message));
				dispatch(
					enqueueSnackbar({
						message: error.response.data.title,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "error",
							autoHideDuration: 3000,
						},
					})
				);
			});
	};
};

export const clearCompanyBalances = () => ({
	type: CLEAR_COMPANY_BALANCES,
});

export const deleteCompanyBalance = ({ balanceId, companyId }) => {
	return async (dispatch) => {
		api.companies.deleteCompanyBalance(balanceId).finally(() => {
			dispatch(getCompanyBalances(companyId));
		});
	};
};

export const getCompanyBalanceUsersListAction = actionCreator(
	GET_COMPANY_BALANCE_USERS
);

export const getCompanyBalanceUsersList = (balanceId) => {
	return async (dispatch) => {
		dispatch(getCompanyBalanceUsersListAction.started());
		api.companies
			.getCompanyBalanceUsers(balanceId)
			.then((res) => dispatch(getCompanyBalanceUsersListAction.done(res.data)))
			.catch((error) =>
				dispatch(getCompanyBalanceUsersListAction.failed(error))
			);
	};
};

export const createCompanyBalanceUserAction = actionCreator(
	CREATE_COMPANY_BALANCE_USER
);

export const createCompanyBalanceUser = ({ balanceId, userId }) => {
	return async (dispatch) => {
		dispatch(createCompanyBalanceUserAction.started());
		api.companies
			.createCompanyBalanceUser({ balanceId, userId })
			.then((res) => {
				dispatch(createCompanyBalanceUserAction.done(res.data));
				dispatch(getCompanyBalanceUsersList(balanceId));
				dispatch(
					enqueueSnackbar({
						message: `User successfully added`,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => {
				dispatch(createCompanyBalanceUserAction.failed(error?.message));
				dispatch(
					enqueueSnackbar({
						message: error.response.data.title,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "error",
							autoHideDuration: 3000,
						},
					})
				);
			});
	};
};

export const deleteCompanyBalanceUser = ({ balanceId, userId }) => {
	return async (dispatch) => {
		api.companies.deleteCompanyBalanceUser(userId).finally(() => {
			dispatch(getCompanyBalanceUsersList(balanceId));
		});
	};
};

export const getCompanyBalanceTransferHistoryAction = actionCreator(
	GET_COMPANY_BALANCE_TRANSFER_HISTORY
);

export const getCompanyBalanceTransferHistory = (params) => {
	return async (dispatch) => {
		dispatch(getCompanyBalanceTransferHistoryAction.started());
		api.companies
			.getCompanyBalanceTransferHistory(params)
			.then((res) => dispatch(getCompanyBalanceTransferHistoryAction.done(res.data)))
			.catch((error) =>
				dispatch(getCompanyBalanceTransferHistoryAction.failed(error))
			);
	};
};

export const getCompanyBalanceTransferInvoicesAction = actionCreator(
	GET_COMPANY_BALANCE_TRANSFER_INVOICES
);

export const getCompanyBalanceTransferInvoices = (params) => {
	return async (dispatch) => {
		dispatch(getCompanyBalanceTransferInvoicesAction.started());
		api.companies
			.getAllCompanyBalanceTransferInvoicesList(params)
			.then((res) => dispatch(getCompanyBalanceTransferInvoicesAction.done(res.data)))
			.catch((error) =>
				dispatch(getCompanyBalanceTransferInvoicesAction.failed(error))
			);
	};
};

export const clearCompanyBalanceUsers = () => ({
	type: CLEAR_COMPANY_BALANCE_USERS,
});

export const getNewCompaniesRequestListAction = actionCreator(
	GET_NEW_COMPANIES_REQUEST_LIST
);

export const getNewCompaniesRequestList = () => {
	return async (dispatch) => {
		dispatch(getNewCompaniesRequestListAction.started());
		try {
			getDocs(
				query(collection(firebaseDB, `${firebaseCollections.newCompanyRequests}`), orderBy('time', 'desc'))
			)
				.then((querySnapshot) => {
					const responseData = querySnapshot.docs.map((doc) => ({
						...doc.data(),
						id: doc.id,
					})).sort((docA, docB) => docA.time - docB.time);
					dispatch(getNewCompaniesRequestListAction.done(responseData));
				})
				.catch((error) => {
					dispatch(getNewCompaniesRequestListAction.failed(error));
				});
		} catch (e) {
			dispatch(getNewCompaniesRequestListAction.failed(e));
		}
	};
};

export const changeNewCompanyRequestStatusAction = actionCreator(
	CHANGE_NEW_COMPANY_REQUEST_STATUS
);

export const changeNewCompanyRequestStatus = ({ companyDocumentId, status, company }) => {
	const showSnackbar = (title, variant = 'error') => enqueueSnackbar({
		message: title || 'Sorry, something went wrong',
		options: {
			key: new Date().getTime() + Math.random(),
			variant: variant,
			autoHideDuration: 3000,
		},
	})
	return async (dispatch) => {

		dispatch(changeNewCompanyRequestStatusAction.started());
		try {
			if (status === COMPANY_REQUEST_STATUS.APPROVED) {

				let newCompanyData = {};
				// create new company
				try {
					dispatch(createCompanyAction.started());
					const newCompanyResponse = await api.companies
						.createCompany({
							companyName: company?.companyName || '',
							shortName: company?.shortName || '',
						});
					newCompanyData = newCompanyResponse.data;
					dispatch(createCompanyAction.done(newCompanyData));
					dispatch(showSnackbar(`The '${company?.companyName}' company successfully created`, 'success'))
				} catch (error) {
					dispatch(createCompanyAction.failed(error?.message));
					dispatch(showSnackbar(error?.response?.data?.title || 'Failed to create the company'))
				}

				if (!newCompanyData?.id) {
					throw new Error('Failed to create the company')
				}

				// add company balances
				try {
					dispatch(createCompanyBalanceAction.started());
					const companyBalancesTickers = company?.selectedCoins || [];
					const newBalanceRequests = companyBalancesTickers.map((ticker) => {
						return api.companies
							.createCompanyBalance({ companyId: newCompanyData?.id, currency: ticker })
					})
					const newBalanceResponses = await Promise.allSettled(newBalanceRequests);
					let balanceIndex = 0
					for (let newBalanceResponsePromise of newBalanceResponses) {
						const balanceTicker = companyBalancesTickers?.[balanceIndex] || '';
						if (newBalanceResponsePromise.status === 'rejected') {
							dispatch(showSnackbar(`Failed to add '${balanceTicker}' balance to '${company?.companyName}' company`))
						} else {
							dispatch(showSnackbar(`'${balanceTicker}' balance has been successfully added to '${company?.companyName}' company.`, 'success'))
						}
						balanceIndex += 1;
					}
					dispatch(createCompanyBalanceAction.done());
				} catch (error) {
					dispatch(createCompanyBalanceAction.failed(error?.message));
					dispatch(showSnackbar('Failed to add new company balances'));
				}
			}



			// update company request status
			try {
				const companyItemDocRef = doc(firebaseDB, firebaseCollections.newCompanyRequests, companyDocumentId)
				await updateDoc(companyItemDocRef, { status })
				dispatch(changeNewCompanyRequestStatusAction.done());
				dispatch(getNewCompaniesRequestList());
			} catch (error) {
				dispatch(changeNewCompanyRequestStatusAction.failed(error));
				dispatch(showSnackbar(error?.response?.data?.title || `Failed to change new company request status.`))
			}
		} catch (e) {
			dispatch(changeNewCompanyRequestStatusAction.failed(e?.message));
			dispatch(showSnackbar(e?.response?.data?.title || e?.message))
		}
	};
};

export const getCompanyBalanceSecuritySettingsListAction = actionCreator(
	GET_COMPANY_BALANCE_SECURITY_SETTINGS_LIST
);

export const getCompanyBalanceSecuritySettingsList = (balanceId) => {
	return async (dispatch) => {
		dispatch(getCompanyBalanceSecuritySettingsListAction.started());
		api.companies
			.getCompanyBalanceSecuritySettingsList(balanceId)
			.then((res) => dispatch(getCompanyBalanceSecuritySettingsListAction.done(res.data)))
			.catch((error) => {
				if (
					error?.response?.status === 400 &&
					error?.response?.data?.title === "Company balance security settings not found"
				) {
					dispatch(getCompanyBalanceSecuritySettingsListAction.done({}))
				} else {
					dispatch(getCompanyBalanceSecuritySettingsListAction.failed(error))
				}
			});
	};
};

export const createCompanyBalanceSecuritySettingsAction = actionCreator(
	CREATE_COMPANY_BALANCE_SECURITY_SETTINGS
);

export const createCompanyBalanceSecuritySettings = ({ balanceId, ttlInMinutes, approveCount }) => {
	return async (dispatch) => {
		dispatch(createCompanyBalanceSecuritySettingsAction.started());
		api.companies
			.createCompanyBalanceSecuritySettings({ balanceId, ttlInMinutes, approveCount })
			.then((res) => {
				dispatch(createCompanyBalanceSecuritySettingsAction.done(res.data));
				dispatch(getCompanyBalanceSecuritySettingsList(balanceId));
				dispatch(
					enqueueSnackbar({
						message: `Security settings successfully added`,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => {
				dispatch(createCompanyBalanceSecuritySettingsAction.failed(error?.message));
				dispatch(
					enqueueSnackbar({
						message: error.response.data.title,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "error",
							autoHideDuration: 3000,
						},
					})
				);
			});
	};
};

export const updateCompanyBalanceSecuritySettingsAction = actionCreator(
	CREATE_COMPANY_BALANCE_SECURITY_SETTINGS
);

export const updateCompanyBalanceSecuritySettings = ({ balanceId, settingsId, ttlInMinutes, approveCount }) => {
	return async (dispatch) => {
		dispatch(updateCompanyBalanceSecuritySettingsAction.started());
		api.companies
			.updateCompanyBalanceSecuritySettings({ id: settingsId, ttlInMinutes, approveCount })
			.then((res) => {
				dispatch(updateCompanyBalanceSecuritySettingsAction.done(res.data));
				dispatch(getCompanyBalanceSecuritySettingsList(balanceId))
				dispatch(
					enqueueSnackbar({
						message: `Security settings successfully updated`,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => {
				dispatch(updateCompanyBalanceSecuritySettingsAction.failed(error?.message));
				dispatch(
					enqueueSnackbar({
						message: error.response.data.title,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "error",
							autoHideDuration: 3000,
						},
					})
				);
			});
	};
};

export const deleteCompanyBalanceSecuritySettings = ({ balanceId, settingsId, params }) => {
	return async (dispatch) => {
		api.companies.deleteCompanyBalanceSecuritySettings(settingsId).finally(() => {
			// todo: reset company balance security params if needed
			// dispatch(setCompaniesListParams({ ...params, page: 0, search: '' }));
			dispatch(getCompanyBalanceSecuritySettingsList(balanceId))
		});
	};
};

export const getCompanyBalanceUsersOfSecuritySettingsAction = actionCreator(
	GET_COMPANY_BALANCE_USERS_OF_SECURITY_SETTINGS
);

export const getCompanyBalanceUsersOfSecuritySettings = (settingsId) => {
	return async (dispatch) => {
		dispatch(getCompanyBalanceUsersOfSecuritySettingsAction.started());
		api.companies
			.getCompanyBalanceUsersOfSecuritySettings(settingsId)
			.then((res) => dispatch(getCompanyBalanceUsersOfSecuritySettingsAction.done(res.data)))
			.catch((error) => dispatch(getCompanyBalanceUsersOfSecuritySettingsAction.failed(error)));
	};
};

export const createCompanyBalanceUserForSecuritySettingsAction = actionCreator(
	CREATE_COMPANY_BALANCE_USER_FOR_SECURITY_SETTINGS
);

export const createCompanyBalanceUserForSecuritySettings = ({ settingsId, userId }) => {
	return async (dispatch) => {
		dispatch(createCompanyBalanceUserForSecuritySettingsAction.started());
		api.companies
			.createCompanyBalanceUserForSecuritySettings({ settingsId, userId })
			.then((res) => {
				dispatch(createCompanyBalanceUserForSecuritySettingsAction.done(res.data));
				dispatch(getCompanyBalanceUsersOfSecuritySettings(settingsId));
				dispatch(
					enqueueSnackbar({
						message: `Security settings user successfully added`,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch((error) => {
				dispatch(createCompanyBalanceUserForSecuritySettingsAction.failed(error?.message));
				dispatch(
					enqueueSnackbar({
						message: error.response.data.title,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "error",
							autoHideDuration: 3000,
						},
					})
				);
			});
	};
};

export const deleteCompanyBalanceUserOfSecuritySettings = ({ settingsUserId, settingsId }) => {
	return async (dispatch) => {
		api.companies.deleteCompanyBalanceUserOfSecuritySettings(settingsUserId).finally(() => {
			dispatch(getCompanyBalanceUsersOfSecuritySettings(settingsId));
		});
	};
};

export const clearCompanyBalanceSecuritySettings = () => ({
	type: CLEAR_COMPANY_BALANCE_SECURITY_SETTINS,
});
