import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import clsx from "clsx";
import EditIcon from "@material-ui/icons/Edit";
import Table from "../../../../theme/components/Table/Table.jsx";
import EditPairSettingsDialog from "./EditPairSettingsDialog.jsx";
import { useSettingsStyles } from "../../../Settings/SettingsStyles.jsx";
import { getAllBankingSettings } from "../../../../app/actions/bankingActions.js";
import CopyWithAlert from "../../../../utils/CopyWithAlert.jsx";

const columns = [
	{ field: "pair", headerName: "Pair" },
	{ field: "price", headerName: "Price" },
	{ field: "priceForLiquidityProviders", headerName: "Price for Liquidity Providers" },
	{ field: "edit", headerName: "Manage" },
];

const PairSettingsList = () => {
	const classes = useSettingsStyles();
	const dispatch = useDispatch();
	const bankingSettingsList = useSelector(({ banking }) => banking.settings);
	const [editProvider, setEditProvider] = useState(null);
	const [create, setCreate] = useState(false);

	useEffect(() => {
		dispatch(getAllBankingSettings());
	}, [dispatch]);

	return (
		<div style={{ maxWidth: "100%" }}>
			<EditPairSettingsDialog
				open={Boolean(editProvider) || create}
				handleClose={() => {
					setEditProvider(null);
					setCreate(false);
				}}
				data={editProvider}
			/>
			<Table>
				<thead>
					<tr>
						{columns.map(({ field, headerName }) => (
							<th
								key={field}
								className={clsx(classes.th, classes.paper)}
								align={"left"}>
								{headerName}
							</th>
						))}
					</tr>
				</thead>
				<tbody style={{ position: "relative" }}>
					{bankingSettingsList?.length > 0 ? (
						bankingSettingsList.map((row, index) => (
							<TableRow
								key={row?.id || index}
								rowData={row}
								handleEdit={() => setEditProvider(row)}
							/>
						))
					) : (
						<tr>
							<td colSpan={13} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</div>
	);
};

export default PairSettingsList;

function TableRow({ rowData, handleDelete, handleEdit }) {
	const classes = useSettingsStyles();

	return (
		<>
			<tr className={classes.hover}>
				<CopyWithAlert
					text={`${rowData?.pair}`}
					label={"Pair"}>
					<td data='hover'>{rowData?.pair}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.price + "" || ""}
					label={"Price"}>
					<td data='hover'>{rowData?.price}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.priceForLiquidityProviders + "" || ""}
					label={"Price for Liquidity Providers"}>
					<td data='hover'>{rowData?.priceForLiquidityProviders}</td>
				</CopyWithAlert>
				<td className={clsx(classes.td, classes.paper)}>
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
				</td>
			</tr>
		</>
	);
}
