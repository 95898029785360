export function exchangeSettingsValidator(name, value, field, form) {
	const arrayIgnoredFields = ["fiat", "deposit", "allowed"];

	// required
	if (!value && !form[name] && !arrayIgnoredFields.includes(name)) {
		return {
			invalid: true,
			errorMessage: "This is a required field!",
		};
	}

	if (field?.type === "number" && name === "systemFee" && +value > 100) {
		return {
			invalid: true,
			errorMessage:
				"The field is defined as a percentage and cannot be greater than 100.",
		};
	}

	if (field?.type === "number" && String(value) !== String(+value)) {
		return {
			invalid: true,
			errorMessage: "Please enter a valid number.",
		};
	}

	if (
		(name === "assetFrom" && form?.assetTo === value) ||
		(name === "assetTo" && form?.assetFrom === value)
	) {
		return {
			invalid: true,
			errorMessage: "Asset From and Asset To values cannot be the same.",
		};
	}

	// default
	return {
		invalid: false,
		errorMessage: "",
	};
}
