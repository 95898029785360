import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TablePagination } from "@material-ui/core";
import Table from "../../../../theme/components/Table/Table";
import CopyWithAlert from "../../../../utils/CopyWithAlert";
import { searchUserFundBalances } from "../../../../app/actions/fundsCapitalActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";
import { searchUserIndexBalances } from "../../../../app/actions/indexesCapitalActions";

const INDEXES_USER_BALANCES_COLUMNS = [
	{ key: "id", label: "ID" },
	{ key: "token", label: "Token" },
	{ key: "userId", label: "User Id" },
	{ key: "address", label: "Address" },
	{ key: "balance", label: "Balance" },
];

const FUNDS_USER_BALANCES_COLUMNS = [
	{ key: "id", label: "ID" },
	{ key: "token", label: "Token" },
	{ key: "userId", label: "User Id" },
	{ key: "balance", label: "Balance" },
];

const UserBalance = ({ isIndexes }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [columns, setColumns] = useState(INDEXES_USER_BALANCES_COLUMNS);
	const { userFundBalance } = useSelector(({ funds }) => funds);
	const { userIndexBalance } = useSelector(({ indexes }) => indexes);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	const { content, totalElements } = useMemo(
		() => (isIndexes ? userIndexBalance : userFundBalance),
		[isIndexes, userIndexBalance, userFundBalance]
	);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		isIndexes
			? dispatch(searchUserIndexBalances(filteringParams))
			: dispatch(searchUserFundBalances(filteringParams));
	}, [dispatch, activeFilters, isIndexes]);

	useEffect(() => {
		isIndexes
			? setColumns(INDEXES_USER_BALANCES_COLUMNS)
			: setColumns(FUNDS_USER_BALANCES_COLUMNS);
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch, isIndexes]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	const goToUserProfile = (userId) => {
		history.push(`/users/list/${null}/${userId}`);
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								isIndexes={isIndexes}
								rowData={rowData}
								goToUserProfile={goToUserProfile}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default UserBalance;

function TableRow({ rowData, goToUserProfile, isIndexes }) {
	return (
		<>
			<tr>
				<td>{rowData?.id}</td>
				<td>{rowData?.token}</td>
				<td data='hover' onClick={() => goToUserProfile(rowData?.userId)}>
					{rowData?.userId}
				</td>
				{isIndexes && (
					<CopyWithAlert text={rowData?.address + "" || ""} label={"Address"}>
						<td data='hover'>{rowData?.address}</td>
					</CopyWithAlert>
				)}
				<td>{+rowData?.balance}</td>
			</tr>
		</>
	);
}
