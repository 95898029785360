import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import Table from "../../theme/components/Table/Table";
import CopyWithAlert from "../../utils/CopyWithAlert";
import {
	clearUserEnabledPrivateFunds,
	deletePrivateFundForUser,
	getUserEnabledPrivateFunds,
} from "../../app/actions/fundsCapitalActions";
import { IconButton } from "@material-ui/core";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "fundId", label: "Fund Id" },
	{ key: "token", label: "Token" },
	{ key: "manage", label: "Manage" },
];

const UserVipFunds = () => {
	const classes = useStyles();
	const user = useSelector(({ users }) => users.profile);
	const dispatch = useDispatch();
	const data = useSelector(({ funds }) => funds?.userEnabledPrivateFunds);

	useEffect(() => {
		dispatch(getUserEnabledPrivateFunds(user?.id));
		return () => {
			dispatch(clearUserEnabledPrivateFunds());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDeleteFundForUser = (id) => () => {
		dispatch(deletePrivateFundForUser(id));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }, index) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((rowData, index) => (
							<tr key={index}>
								<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
									<td className={classes.hoverCopyTd}>{rowData?.id}</td>
								</CopyWithAlert>
								<td>{rowData?.fundId}</td>
								<td>{rowData?.token}</td>
								<td width={'10%'}>
									<IconButton onClick={handleDeleteFundForUser(rowData?.id)}>
										<DeleteIcon style={{ color: "#ff0000" }} />
									</IconButton>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</>
	);
};

export default UserVipFunds;

const useStyles = makeStyles((theme) => ({
	head: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
		"&>.MuiTextField-root": {
			maxWidth: 256,
			[theme.breakpoints.down("sm")]: {
				marginTop: 24,
			},
		},
	},
	root: {
		marginTop: 24,
		width: "100%",
		overflowX: "auto",
		paddingBottom: 24,
	},
	table: {
		borderSpacing: 0,
		width: "100%",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		"&:first-child": {
			borderRadius: "10px 0 0 10px",
		},
		"&:last-child": {
			borderRadius: "0 10px 10px 0",
		},
	},
	hoverCopyTd: {
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.light,
		},
	},
}));
