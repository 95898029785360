import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import {
	IconButton,
	lighten,
	makeStyles,
	TablePagination,
} from "@material-ui/core";
import AnyIcon from "@material-ui/icons/UnfoldMoreRounded";
import EditIcon from "@material-ui/icons/Edit";
import DownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import TopIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import CreateSyncBalanceDialog from "./CreateSyncBalanceDialog";
import { getSettingsSyncList } from "../../app/actions/syncActions";
import { SORT_INTERNAL_BALANCES_LIST } from "../../app/types";
import PageTitle from "../../theme/components/PageTitle";
import { SORT_TYPES, next } from "../../utils/constants/sortTypes.js";

const AutoSyncBalances = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [create, setCreate] = useState(false);
	const [editedSyncItem, setEditSyncItem] = useState(null);
	const syncList = useSelector(
		({ synchronization }) => synchronization.autoSyncBalance
	);
	const [params, setParams] = useState({
		page: 0,
		size: 10,
		sort: "updateTime,desc",
	});
	const [columns, setColumns] = useState([
		{
			key: "currency",
			headerName: "Currency",
			type: "string",
		},
		{
			key: "maxBalance",
			headerName: "Max Balance",
			sort: SORT_TYPES.none,
			type: "number",
		},
		{ key: "balanceLeftoverPercent", headerName: "Left percent" },
		{
			key: "maxBalanceGrowthPercent",
			headerName: "Max Balance Growth Percent",
			type: "number",
		},
		{
			key: "updateTime",
			headerName: "Update Time",
			type: "date",
			sort: SORT_TYPES.none,
		},
		{ key: "manage", headerName: "Manage" },
	]);

	const sorting = key => {
		const nextState = next(columns.find(s => s.key === key)?.sort);
		setParams({ ...params, sort: `${key},${nextState}` });
		dispatch({
			type: SORT_INTERNAL_BALANCES_LIST,
			payload: {
				direction: nextState,
				key,
				type: columns.find(s => s.key === key)?.type,
			},
		});
		setColumns(
			columns.map(s =>
				s.key === key
					? { ...s, sort: nextState }
					: {
							...s,
							sort: s.sort ? SORT_TYPES.none : undefined,
					  }
			)
		);
	};

	useEffect(() => {
		dispatch(getSettingsSyncList(params));
	}, [dispatch, params]);

	const data = useMemo(() => {
		return syncList.data;
	}, [syncList]);

	const handleCreate = () => {
		setCreate(true);
	};

	const handleEdit = row => () => {
		setEditSyncItem(row);
	};

	const handleCloseDialog = () => {
		setCreate(false);
		setEditSyncItem(null);
	};

	const handleChangePage = (event, newPage) => {
		setParams({ ...params, page: newPage });
	};

	const handleChangeSize = ({ target }) => {
		setParams({ ...params, size: target.value, page: 0 });
	};

	return (
		<div>
			<CreateSyncBalanceDialog
				open={create || Boolean(editedSyncItem)}
				handleClose={handleCloseDialog}
				defaultData={editedSyncItem}
			/>
			<PageTitle title={"Auto sync balances"} onCreate={handleCreate} />
			<div className={classes.root}>
				<table className={classes.table}>
					<thead>
						<tr>
							{columns.map(({ key, headerName, sort, colSpan, align }) => (
								<th
									key={key}
									className={clsx(classes.th, classes.paper, sort && "hover")}
									colSpan={colSpan}
									align={align || "left"}
									onClick={() => sort && sorting(key)}>
									{sort === SORT_TYPES.up && <TopIcon />}
									{sort === SORT_TYPES.none && <AnyIcon />}
									{sort === SORT_TYPES.down && <DownIcon />}
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{data?.length > 0 ? (
							data.map((row, index) => (
								<TableRow
									key={row?.id || index}
									rowData={row}
									handleEdit={handleEdit(row)}
								/>
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<TablePagination
					rowsPerPageOptions={[10, 30, 50]}
					component='div'
					count={data?.length || 0}
					rowsPerPage={params?.size}
					page={params?.page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeSize}
				/>
			</div>
		</div>
	);
};

export default AutoSyncBalances;

function TableRow({ rowData, handleEdit }) {
	const classes = useStyles();
	return (
		<>
			<tr className={classes.spacing}></tr>
			<tr className={classes.hover}>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.currency?.toUpperCase()}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.maxBalance || 0}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.balanceLeftoverPercent || 0}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.maxBalanceGrowthPercent || 0}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{moment(rowData?.updateTime).format("DD MMM YYYY HH:mm")}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
				</td>
			</tr>
		</>
	);
}

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: 24,
		width: "100%",
		overflowX: "auto",
		paddingBottom: 24,
	},
	table: {
		borderSpacing: 0,
		width: "100%",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		"&:first-child": {
			borderRadius: "10px 0 0 10px",
		},
		"&:last-child": {
			borderRadius: "0 10px 10px 0",
		},
	},
	th: {
		color: theme.palette.text.secondary,
		padding: "14px 8px ",
		fontSize: 14,
		lineHeight: 2,
		cursor: "pointer",
		"&.hover:hover": {
			backgroundColor: lighten(theme.palette.background.paper, 0.04),
		},
		"&>svg": {
			fontSize: 19,
			verticalAlign: "middle",
			marginRight: 4,
		},
	},
	td: {
		color: theme.palette.text.primary,
		padding: "8px 14px",
		fontSize: 13,
		"& svg": {
			fontSize: 16,
		},
	},
	spacing: {
		height: 12,
	},
	hover: {
		"&:hover>td": {
			backgroundColor: lighten(theme.palette.background.paper, 0.04),
		},
	},
}));
