import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Table from "../../../theme/components/Table/Table";
import moment from "moment";
import { TablePagination } from "@material-ui/core";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import { clearActiveFilters } from "../../../app/actions/filterActions";
import getStatusColor from "../../../utils/functions/getStatusColor";
import { clearSyncHistory } from "../../../app/actions/syncActions";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "currencyFrom", label: "Currency From" },
	{ key: "currencyTo", label: "Currency To" },
	{ key: "targetMarket", label: "Target Market" },
	{ key: "amount", label: "Amount" },
	{ key: "userId", label: "User Id" },
	{ key: "orderId", label: "Order Id" },
	{ key: "customOrderId", label: "Custom Order Id" },
	{ key: "orderSide", label: "Order Side" },
	{ key: "timeWhenProgramWasCreated", label: "Created" },
	{ key: "timeWhenProgramWasCompleted", label: "Completed" },
	{ key: "status", label: "Status" },
];

const UserSyncHistoryTable = ({
	data,
	params,
	total,
	onPageChange,
	onRowsPerPageChange,
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearSyncHistory());
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody style={{ position: "relative" }}>
					{data?.length > 0 ? (
						data.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
								onStatusColor={getStatusColor}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 30, 50]}
				component='div'
				count={total}
				rowsPerPage={params.find(x => x.field === "size").value}
				page={params.find(x => x.field === "page").value}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
			/>
		</>
	);
};

export default UserSyncHistoryTable;

function TableRow({ rowData, onStatusColor }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<td>{rowData?.currencyFrom}</td>
				<td>{rowData?.currencyTo}</td>
				<td>{rowData?.targetMarket}</td>
				<td>{rowData?.amount}</td>
				<CopyWithAlert text={rowData?.userId + "" || ""} label={"User Id"}>
					<td data='hover'>{rowData?.userId}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.orderId + "" || ""} label={"Order Id"}>
					<td data='hover'>{rowData?.orderId}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.customOrderId + "" || ""}
					label={"Custom Order Id"}>
					<td data='hover'>{rowData?.customOrderId}</td>
				</CopyWithAlert>

				<td>{rowData?.orderSide}</td>
				<td>
					{!!rowData?.timeWhenProgramWasCreated
						? moment(rowData?.timeWhenProgramWasCreated).format(
								"DD MMM YYYY HH:mm"
						  )
						: "-"}
				</td>
				<td>
					{!!rowData?.timeWhenProgramWasCompleted
						? moment(rowData?.timeWhenProgramWasCompleted).format(
								"DD MMM YYYY HH:mm"
						  )
						: "-"}
				</td>
				<CopyWithAlert text={rowData?.status + "" || ""} label={"Status"}>
					<td
						data='hover'
						style={{
							color: rowData?.status && onStatusColor(rowData?.status),
						}}>
						{rowData?.status || "-"}
					</td>
				</CopyWithAlert>
			</tr>
		</>
	);
}
