import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Grid,
	Typography,
	TextareaAutosize,
	makeStyles,
	MenuItem
} from "@material-ui/core";
import { Input } from "../../theme/components/Input";
import { Button } from "../../theme/components/Button";
import DialogWrapper from "../../theme/components/dialogs/DialogWrapper";
import { useIsMobile } from "../../utils/hooks/useIsMobile";
import { BANK_CARDS_STATUS } from "../../utils/constants/bankCards";
import { clearBankCardsStatus } from "../../app/actions/bankCardsActions";
import { SelectInput } from "../../theme/components/Select";

const declineForm = [
	{ field: "decision", type: "text", label: "Reason for the decision" },
];

const acceptForm = [
	{ field: "cardIssuer", type: "text", label: "Select card issuer" },
];

const BANK_CARDS_ISSUERS = [
	{
		id: 'Mono Bank',
		label: 'Mono Bank'
	},
	{
		id: 'Privat Bank',
		label: 'Privat Bank',
	},
	{
		id: 'Universal Bank',
		label: 'Universal Bank'
	},
	{
		id: 'Ukrsibbank',
		label: 'Ukrsibbank'
	},
	{
		id: 'Oschadbank',
		label: 'Oschadbank'
	},
	{
		id: 'Raiffeisen',
		label: 'Raiffeisen'
	},
	{
		id: 'Sens',
		label: 'Sens'
	},
	{
		id: 'Pumb',
		label: 'Pumb'
	},
	{
		id: 'Izibank',
		label: 'Izibank'
	},
	{
		id: 'Sportbank',
		label: 'Sportbank'
	},
	{
		id: 'OTP Bank',
		label: 'OTP Bank'
	},
	{
		id: 'Ukrgazbank',
		label: 'Ukrgazbank'
	},
	{
		id: 'Credit Agricol',
		label: 'Credit Agricol'
	},
	{
		id: 'Bank Pivdenny',
		label: 'Bank Pivdenny'
	},
	{
		id: 'Tascombank',
		label: 'Tascombank'
	},

]

export function bankCardDecisionValidateInput(name, value, form) {
	// required
	const fieldArray = ["decision", "cardIssuer"];

	if (!value && fieldArray.includes(name)) {
		return {
			invalid: true,
			errorMessage: "This is a required field!",
		};
	}

	// default
	return {
		invalid: false,
		errorMessage: "",
	};
}

const BankCardDecisionDialog = ({
	open,
	handleClose,
	data = null,
	decision,
	handleDecision,
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { status } = useSelector(({ bankCards }) => bankCards);
	const [errors, setErrors] = useState({});
	const [form, setForm] = useState(null);
	const [formFields, setFormFields] = useState(null);
	const isMobile = useIsMobile();

	useEffect(() => {
		if (decision !== BANK_CARDS_STATUS.APPROVED) {
			setFormFields(declineForm);
			createForm(declineForm);
		}else if(decision === BANK_CARDS_STATUS.APPROVED) {
			setFormFields(acceptForm);
			createForm(acceptForm);
		}
		// eslint-disable-next-line
	}, [open, data, decision]);

	const createForm = (fields) => {
		let form = {};
		fields?.forEach((item) => {
			form[item.field] =
				item.type === "text" || item.type === "select" || item.type === "date"
					? data
						? data[item.field]
						: ""
					: item.type === "number"
						? data
							? data[item.field]
							: 0
						: false;
		});
		setForm(form);
	};

	useEffect(() => {
		if (status) {
			dispatch(clearBankCardsStatus());
			closeDialog();
		}
		// eslint-disable-next-line
	}, [dispatch, status]);

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach((key) => {
			errForm = {
				...errForm,
				[key]: bankCardDecisionValidateInput(key, form[key]),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]: bankCardDecisionValidateInput(name, value),
		});
	};

	const handleChange = ({ target: { name, value } }) => {
		handleInputValidation(name, value);
		setForm({ ...form, [name]: value });
	};

	const onDecision = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (!validateForm()) {
			return;
		}
		handleDecision(form);
	};

	const closeDialog = () => {
		setForm(null);
		setErrors({});
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={720}
			minWidth={isMobile ? 320 : 540}
			maxW={"lg"}>
			<Grid container justifyContent='space-between'>
				<Typography
					variant={"h6"}
					color={"textPrimary"}
					style={{ marginBottom: 32 }}>
					{decision === BANK_CARDS_STATUS.DECLINED ? "DECLINE CREDIT CARD" : "APPROVE CREDIT CARD"}
				</Typography>
			</Grid>
			<Grid container spacing={3}>
				{decision !== BANK_CARDS_STATUS.APPROVED ? (
					<>
						{form &&
							formFields &&
							formFields?.map(({ field, label }) => (
								<Grid item xs={12} key={field}>
									<Input
										className={classes.rootInput}
										fullWidth
										multiline
										label={label}
										variant={"outlined"}
										InputProps={{
											component: TextareaAutosize,
											minRows: 3,
											maxRows: 3,
										}}
										name={field}
										value={form[field] || ""}
										error={errors[field]?.invalid}
										helperText={errors[field]?.errorMessage}
										onChange={handleChange}
									/>
								</Grid>
							))}
					</>
				) : (
					<>
						<Grid item xs={12}>
							<Typography
								variant={"body1"}
								color={"textPrimary"}
								style={{ marginBottom: 20 }}>
								Are you sure you want to approve selected bank card?
							</Typography>
						</Grid>
						<Grid item xs={12} sm={12}>
							<SelectInput
								style={{ marginBottom: 20 }}
								label='Card Issuer'
								onChange={handleChange}
								value={form?.cardIssuer || false}
								name={"cardIssuer"}
								error={errors['cardIssuer']?.invalid}
								helperText={errors['cardIssuer']?.errorMessage}
								status={!form?.cardIssuer}>
								{BANK_CARDS_ISSUERS.map((item) => (
									<MenuItem
										key={item.id}
										value={item.id}>
										{item.label}
									</MenuItem>
								))}
							</SelectInput>
						</Grid>
					</>
				)}
			</Grid>

			<Grid
				container
				justifyContent='center'
				style={{ marginTop: 24, marginBottom: 24 }}>
				<Button
					color={"primary"}
					style={{ width: "max(50%, 50px)" }}
					onClick={onDecision}>
					Save
				</Button>
			</Grid>
		</DialogWrapper>
	);
};

export default BankCardDecisionDialog;

const useStyles = makeStyles((theme) => ({
	rootInput: {
		"& .MuiFormHelperText-root": {
			marginTop: 96,
		},
	},
}));
