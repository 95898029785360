import moment from "moment"

export const DateFormats = {
    FULL_DATE_TIME: "DD MMM YYYY HH:mm"
}

export const formatTime = (time, formatPattern = '') => {
    if (time) {
        return moment(time).format(formatPattern || DateFormats.FULL_DATE_TIME)
    }
    return ''
} 