import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PageTitle from "../../../theme/components/PageTitle";
import Table from "../../../theme/components/Table/Table";
import { getNetProfit } from "../../../app/actions/statisticActions";
import CopyWithAlert from "../../../utils/CopyWithAlert";

const columns = [
	{
		key: "ticker",
		headerName: "Ticker",
		align: "center",
	},
	{
		key: "profit",
		headerName: "Profit",
		align: "center",
	},
	{
		key: "time",
		headerName: "Time",
		align: "center",
	},
];

const Profit = () => {
	const dispatch = useDispatch();
	const data = useSelector(({ statistic }) => statistic.netProfit);

	useEffect(() => {
		dispatch(getNetProfit());
	}, [dispatch]);

	return (
		<>
			<PageTitle title={"Profit"} />
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, headerName, colSpan, align }) => (
							<th
								key={key}
								colSpan={colSpan}
								style={{ textAlign: align || "left" }}>
								{headerName}
							</th>
						))}
					</tr>
				</thead>
				<tbody style={{ position: "relative" }}>
					{data?.length > 0 ? (
						data?.map((row, index) => (
							<TableRow key={row?.ticker || index} rowData={row} />
						))
					) : (
						<tr>
							<td colSpan={13} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</>
	);
};

export default Profit;

function TableRow({ rowData }) {
	return (
		<>
			<tr></tr>
			<tr align={"center"}>
				<td>{rowData?.ticker}</td>
				<CopyWithAlert text={rowData?.profit + "" || ""} label={"Profit"}>
					<td
						data='hover'
						style={{
							color: +rowData?.profit > 0 ? "#60CF61" : "#FF0000",
						}}>
						{+rowData?.profit}
					</td>
				</CopyWithAlert>
				<td>
					{rowData?.time && moment(rowData?.time).format("DD MMM YYYY HH:mm")}
				</td>
			</tr>
		</>
	);
}
