import ReactDOM from 'react-dom';
import App from './App';
import {ThemeProvider} from '@material-ui/core/styles';
import {MuiTheme} from "./theme/MuiTheme";
import './theme/main.css';
import {Provider} from "react-redux";
import {store} from "./app/store";

ReactDOM.render(
	<ThemeProvider theme={MuiTheme}>
		<Provider store={store}>
			<App/>
		</Provider>
	</ThemeProvider>,
	document.getElementById('root')
);
