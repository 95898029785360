import {
	CLEAR_USERS,
	GET_USERS_FAILURE,
	GET_USERS_STARTED,
	GET_USERS_SUCCESS,
	GET_PENDING_ACTIVATION_USERS_FAILURE,
	GET_PENDING_ACTIVATION_USERS_STARTED,
	GET_PENDING_ACTIVATION_USERS_SUCCESS,
	SET_USERS_PARAMS,
	GET_ROLES_FAILURE,
	GET_ROLES_STARTED,
	GET_ROLES_SUCCESS,
	GET_PROFILE_BY_ID_FAILURE,
	GET_PROFILE_BY_ID_STARTED,
	GET_PROFILE_BY_ID_SUCCESS,
	ADD_USER_VIP_STATUS,
	DELETE_USER_VIP_STATUS,
	ROLLBACK_USER_BLOCK_HIMSELF,
} from "../types";
import api from "../api";
import { actionCreator } from "../utils";
import { enqueueSnackbar } from "./notistackActions";

const getUsersStarted = () => ({
	type: GET_USERS_STARTED,
});

export const getUsersSuccess = (data) => ({
	type: GET_USERS_SUCCESS,
	payload: data,
});

export const getUsersFailure = (error) => ({
	type: GET_USERS_FAILURE,
	payload: {
		error,
	},
});

export const getUsers = (params) => {
	return async (dispatch) => {
		dispatch(getUsersStarted());
		api.users
			.getUsers(params)
			.then((res) => dispatch(getUsersSuccess(res.data)))
			.catch((error) => dispatch(getUsersFailure(error)));
	};
};

const getPendingActivationUsersStarted = () => ({
	type: GET_PENDING_ACTIVATION_USERS_STARTED,
});

export const getPendingActivationUsersSuccess = (data) => ({
	type: GET_PENDING_ACTIVATION_USERS_SUCCESS,
	payload: data,
});

export const getPendingActivationUsersFailure = (error) => ({
	type: GET_PENDING_ACTIVATION_USERS_FAILURE,
	payload: {
		error,
	},
});

export const getPendingActivationUsers = () => {
	return async (dispatch) => {
		dispatch(getPendingActivationUsersStarted());
		api.users
			.getPendingActivationUsers()
			.then((res) => dispatch(getPendingActivationUsersSuccess(res.data)))
			.catch((error) => dispatch(getPendingActivationUsersFailure(error)));
	};
};

export const getUserByLogin = (login) => {
	return async (dispatch) => {
		dispatch(getUsersStarted());
		api.users
			.getUserByLogin(login)
			.then((res) => dispatch(getUsersSuccess([res.data])))
			.catch((error) => dispatch(getUsersFailure(error)));
	};
};

export const getProfileByIdStarted = () => ({
	type: GET_PROFILE_BY_ID_STARTED,
});

export const getProfileByIdSuccess = (data) => ({
	type: GET_PROFILE_BY_ID_SUCCESS,
	payload: {
		data,
	},
});

export const getProfileByIdFailure = (error) => ({
	type: GET_PROFILE_BY_ID_FAILURE,
	payload: {
		error,
	},
});

export const getProfileById = (id) => {
	return async (dispatch) => {
		dispatch(getUsersStarted());
		api.users
			.getProfileById(id)
			.then((res) => dispatch(getProfileByIdSuccess([res.data])))
			.catch((error) => dispatch(getProfileByIdFailure(error)));
	};
};

export const setUsersParams = (payload) => ({
	type: SET_USERS_PARAMS,
	payload,
});

export const clearUsers = () => ({
	type: CLEAR_USERS,
});

export const deleteUser = (login, params) => {
	return async (dispatch) => {
		api.users.deleteUser(login).finally(() => {
			dispatch(getUsers(params));
		});
	};
};

export const getRolesStarted = () => ({
	type: GET_ROLES_STARTED,
});

export const getRolesSuccess = (data) => ({
	type: GET_ROLES_SUCCESS,
	payload: {
		data,
	},
});
export const getRolesFailure = (error) => ({
	type: GET_ROLES_FAILURE,
	payload: {
		error,
	},
});

export const getRoles = () => {
	return async (dispatch) => {
		dispatch(getRolesStarted());
		api.users
			.getRoles()
			.then((res) => dispatch(getRolesSuccess([res.data])))
			.catch((error) => dispatch(getRolesFailure(error)));
	};
};

export const addUserVipStatusAction = actionCreator(ADD_USER_VIP_STATUS);

export const addUserVipStatus = userId => {
	return async dispatch => {
		dispatch(addUserVipStatusAction.started());
		api.users
			.addUserVipStatus(userId)
			.then(res => {
				dispatch(addUserVipStatusAction.done(res.data));
				dispatch(getProfileById(userId));
				dispatch(
					enqueueSnackbar({
						message: `User VIP status successfully added`,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => {
				dispatch(addUserVipStatusAction.failed(error));
				dispatch(
					enqueueSnackbar({
						message: error.response.data.title,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "error",
							autoHideDuration: 3000,
						},
					})
				);
			});
	};
};

export const deleteUserVipStatusAction = actionCreator(DELETE_USER_VIP_STATUS);

export const deleteUserVipStatus = userId => {
	return async dispatch => {
		dispatch(deleteUserVipStatusAction.started());
		api.users
			.deleteUserVipStatus(userId)
			.then(res => {
				dispatch(getProfileById(userId));
				dispatch(deleteUserVipStatusAction.done(res.data));
				dispatch(
					enqueueSnackbar({
						message: `User VIP status successfully removed`,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(deleteUserVipStatusAction.failed(error)));
	};
};

export const rollbackUserBlockHimselfAction = actionCreator(ROLLBACK_USER_BLOCK_HIMSELF);

export const rollbackUserBlockHimself = userId => {
	return async dispatch => {
		dispatch(rollbackUserBlockHimselfAction.started());
		api.users
			.rollbackUserBlockHimself(userId)
			.then(res => {
				dispatch(rollbackUserBlockHimselfAction.done(res.data));
				dispatch(getProfileById(userId));
				dispatch(
					enqueueSnackbar({
						message: `User successfully unblocked`,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => {
				dispatch(rollbackUserBlockHimselfAction.failed(error));
				dispatch(
					enqueueSnackbar({
						message: error.response.data.title,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "error",
							autoHideDuration: 3000,
						},
					})
				);
			});
	};
};
