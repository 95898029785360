import React, { useEffect, useState } from "react";
import { Tab } from "@material-ui/core";
import { StyledTabs } from "../../theme/components/StyledTabs";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import KycHistoryTable from "./KycHistoryTable.jsx";
import PageTitle from "../../theme/components/PageTitle";
import {
	getKycHistory,
	clearKycHistory,
	clearKycUserDetails,
} from "../../app/actions/kycActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
	setActiveFilter,
} from "../../app/actions/filterActions";
import {
	KYC_ALL_FILTER_FIELDS,
	KYC_FILTER_FIELDS,
	KYC_ALL_COLUMNS,
	KYC_COLUMNS,
} from "../../utils/constants/kyc";

const tabs = {
	pending: "pending",
	approved: "approved",
	declined: "declined",
	all: "all",
};
const KycHistory = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [activeTab, setActiveTab] = useState(
		history?.location?.state?.activeTab || tabs.pending
	);
	const [filterFields, setFilterFields] = useState(KYC_FILTER_FIELDS);
	const [columns, setColumns] = useState(KYC_COLUMNS);
	const isInitial = true;
	const { data, total } = useSelector(({ kyc }) => kyc);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		if (
			history?.location?.state?.from === "kyc_detail" &&
			history?.location?.state?.params
		) {
			let params = JSON.parse(history?.location?.state?.params);
			dispatch(setActiveFilter(params));
		}
	}, [dispatch, history?.location]);

	useEffect(() => {
		if (!activeTab || !activeFilters) {
			return;
		}
		let filteringParams = {};
		if (activeTab === tabs.pending || activeTab === tabs.declined) {
			filteringParams = {
				...filteringParams,
				status: activeTab?.toUpperCase(),
			};
			setFilterFields(KYC_FILTER_FIELDS);
			setColumns(KYC_COLUMNS);
		} else {
			filteringParams = {
				...filteringParams,
				status: activeTab === tabs.approved ? activeTab?.toUpperCase() : null,
			};
			setFilterFields(KYC_ALL_FILTER_FIELDS);
			setColumns(KYC_ALL_COLUMNS);
		}
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getKycHistory(filteringParams));
	}, [activeFilters, activeTab, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
			dispatch(clearKycHistory());
		};
	}, [isInitial, dispatch]);

	const handleChangeTab = (newTab) => {
		setActiveTab(newTab);
		dispatch(clearActiveFilters());
		dispatch(clearKycUserDetails());
	};

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	const handleManage = (id) => {
		history.push(
			`/users/kyc_history/${id}/?params=${JSON.stringify(activeFilters)}`,
			{
				from: "/users/kyc-history",
				params: JSON.stringify(activeFilters),
				title: "KYC history",
				activeTab: activeTab,
			}
		);
		dispatch(clearKycHistory());
		dispatch(clearActiveFilters());
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<PageTitle title={"KYC"} filterFields={filterFields} />
			<StyledTabs
				style={{ marginBottom: 12 }}
				indicatorColor='primary'
				value={activeTab}
				onChange={(_, newTab) => handleChangeTab(newTab)}>
				<Tab label={tabs.pending} value={tabs.pending} />
				<Tab label={tabs.approved} value={tabs.approved} />
				<Tab label={tabs.declined} value={tabs.declined} />
				<Tab label={tabs.all} value={tabs.all} />
			</StyledTabs>
			<KycHistoryTable
				onPageChange={(event, newPage) => handleChangePage(event, newPage)}
				onRowsPerPageChange={(event) => handleChangeSize(event)}
				data={data}
				params={activeFilters}
				total={total}
				columns={columns}
				isTabsAll={activeTab === tabs.all || activeTab === tabs.approved}
				onManage={handleManage}
			/>
		</div>
	);
};

export default KycHistory;
