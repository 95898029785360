import {
	CLEAR_ADMINS,
	GET_ADMINS_FAILURE,
	GET_ADMINS_STARTED,
	GET_ADMINS_SUCCESS,
	EDIT_ADMIN_FAILURE,
	EDIT_ADMIN_STARTED,
	EDIT_ADMIN_SUCCESS,
	SET_ADMINS_PARAMS,
} from "../types";

export default function admins(
	state = {
		data: [],
		error: null,
		profile: null,
		params: null,
		total: 0,
	},
	action
) {
	switch (action.type) {
		case GET_ADMINS_STARTED:
			return { ...state, error: null };
		case GET_ADMINS_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				total: action.payload.totalItems,
			};
		case GET_ADMINS_FAILURE:
			return { ...state, error: action.payload.error };
		case SET_ADMINS_PARAMS:
			return { ...state, params: action.payload };
		case CLEAR_ADMINS:
			return { data: [], error: null, params: null, total: 0 };
		case EDIT_ADMIN_STARTED:
			return { ...state, error: null };
		case EDIT_ADMIN_SUCCESS:
			return { ...state, profile: action.payload.data };
		case EDIT_ADMIN_FAILURE:
			return { ...state, error: action.payload.error };
		default:
			return state;
	}
}
