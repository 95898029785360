export const DIVIDENDS_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "Id",
		placeholder: "Id",
	},
	{
		field: "token",
		type: "text",
		title: "Token",
		placeholder: "Token",
	},
	{
		field: "asset",
		type: "text",
		title: "Asset",
		placeholder: "Asset",
	},
	{
		field: "userId",
		type: "text",
		title: "User Id",
		placeholder: "User Id",
	},
	{
		field: "programId",
		type: "text",
		title: "Program Id",
		placeholder: "Program Id",
	},
	{
		field: "distributionTimeFrom",
		type: "date",
		title: "Distribution Date From",
		placeholder: "Distribution Date From",
	},
	{
		field: "distributionTimeTo",
		type: "date",
		title: "Distribution Date To",
		placeholder: "Distribution Date To",
	},
];

export const DISTRIBUTION_PROGRAM_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "Id",
		placeholder: "Id",
	},
	{
		field: "token",
		type: "text",
		title: "Token",
		placeholder: "Token",
	},
	{
		field: "asset",
		type: "text",
		title: "Asset",
		placeholder: "Asset",
	},
	{
		field: "timeWhenProgramWasCompletedFrom",
		type: "date",
		title: "Completed from",
		placeholder: "Completed from",
	},
	{
		field: "timeWhenProgramWasCompletedTo",
		type: "date",
		title: "Completed to",
		placeholder: "Completed to",
	},
];

export const TRANSFERS_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "Id",
		placeholder: "Id",
	},
	{
		field: "asset",
		type: "text",
		title: "Asset",
		placeholder: "Asset",
	},
	{
		field: "addressTo",
		type: "text",
		title: "Address To",
		placeholder: "Address To",
	},
	{
		field: "timeFrom",
		type: "date",
		title: "Date from",
		placeholder: "Date from",
	},
	{
		field: "timeTo",
		type: "date",
		title: "Date to",
		placeholder: "Date to",
	},
];

export const INVOICES_FILTER_FIELDS = [
	{
		field: "id",
		type: "text",
		title: "Id",
		placeholder: "Id",
	},
	{
		field: "token",
		type: "text",
		title: "Token",
		placeholder: "Token",
	},
	{
		field: "userId",
		type: "text",
		title: "User Id",
		placeholder: "User Id",
	},
	{
		field: "targetCurrency",
		type: "text",
		title: "Target Currency",
		placeholder: "Target Currency",
	},
	{ field: "status", type: "text", title: "Status", placeholder: "Status" },

	{
		field: "creationTimeFrom",
		type: "date",
		title: "Create date from",
		placeholder: "Create date from",
	},
	{
		field: "creationTimeTo",
		type: "date",
		title: "Create date to",
		placeholder: "Create date to",
	},
	{
		field: "decisionTimeFrom",
		type: "date",
		title: "Decision date from",
		placeholder: "Decision date from",
	},
	{
		field: "decisionTimeTo",
		type: "date",
		title: "Decision date to",
		placeholder: "Decision date to",
	},
];

export const TYPES_OPERATIONS_FILTER = [
	{ label: "BUY_TOKEN", value: "BUY_TOKEN" },
	{ label: "SELL_TOKEN", value: "SELL_TOKEN" },
];

export const RISK_LEVEL_CAPITAL = [
	{ label: "LOW", value: "LOW" },
	{ label: "MEDIUM", value: "MEDIUM" },
	{ label: "HIGH", value: "HIGH" },
];
export const TYPES_FUNDS = { STABLE: "STABLE", FLOATING: "FLOATING" };

export const TYPES_FUNDS_SELECT = [
	{ label: "STABLE", value: "STABLE" },
	{ label: "FLOATING", value: "FLOATING" },
];

export const OPERATION_HISTORY_FILTER_FIELDS = [
	{ field: "id", type: "text", title: "Id", placeholder: "Id" },
	{ field: "userId", type: "text", title: "User Id", placeholder: "User Id" },
	{ field: "token", type: "text", title: "Token", placeholder: "Token" },
	{ field: "asset", type: "text", title: "Asset", placeholder: "Asset" },
	{
		field: "type",
		type: "select",
		title: "Type",
		placeholder: "Type",
		data: TYPES_OPERATIONS_FILTER,
	},
	{ field: "type", type: "text", title: "type", placeholder: "type" },
	{
		field: "timeFrom",
		type: "date",
		title: "Date from",
		placeholder: "Date from",
	},
	{
		field: "timeTo",
		type: "date",
		title: "Date to",
		placeholder: "Date to",
	},
];
export const DECISION_BY_INVOICE = {
	ACCEPT: "ACCEPT",
	DECLINE: "DECLINE",
};
export const STATUS_INVOICE = {
	DECLINED: "DECLINED",
	APPROVED: "APPROVED",
	WAITING: "WAITING",
};
