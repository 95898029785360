import { useEffect, useState } from "react";
import { useSettingsStyles } from "./../../Settings/SettingsStyles";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, TablePagination } from "@material-ui/core";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../app/actions/filterActions";
import { getDevices } from "../../../app/actions/dhsActions";
import clsx from "clsx";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDeviceDialog from "./DeleteDeviceDialog";
import EditDeviceDialog from "./EditDeviceDialog";
import PageTitle from "../../../theme/components/PageTitle";
import CopyWithAlert from "../../../utils/CopyWithAlert";

const columns = [
	{ field: "id", headerName: "ID", type: "copy" },
	{ field: "deviceId", headerName: "Device Id", type: "text" },
	{ field: "ownerId", headerName: "Owner Id", type: "text" },
	{ field: "todayShares", headerName: "Today Shares", type: "text" },
	{ field: "totalShares", headerName: "Total Shares", type: "text" },
	{ field: "edit", headerName: "Manage", type: "manage" },
];
const filterFields = [
	{ field: "userId", type: "number", title: "User ID", placeholder: "User ID" },
	{
		field: "deviceId",
		type: "text",
		title: "Device Id",
		placeholder: "Device Id",
	},
];

const Devices = () => {
	const classes = useSettingsStyles();
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(({ dhs }) => dhs.devices);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);
	const [create, setCreate] = useState(false);
	const [deleteDevice, setDeleteDevice] = useState(null);
	const [editDevice, setEditDevice] = useState(null);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getDevices(filteringParams));
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	const handleCreate = () => {
		setCreate(true);
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<DeleteDeviceDialog
				open={Boolean(deleteDevice)}
				handleClose={() => setDeleteDevice(null)}
				params={activeFilters}
				{...deleteDevice}
			/>
			<EditDeviceDialog
				open={Boolean(editDevice) || create}
				handleClose={() => {
					setEditDevice(null);
					setCreate(false);
				}}
				data={editDevice}
				params={activeFilters}
			/>
			<PageTitle
				title={"Devices DHS"}
				filterFields={filterFields}
				onCreate={handleCreate}
			/>
			<div className={classes.root}>
				<table className={classes.table}>
					<thead>
						<tr>
							{columns.map(({ field, headerName }) => (
								<th
									key={field}
									className={clsx(classes.th, classes.paper)}
									align={"left"}>
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{content?.length > 0 ? (
							content?.map((row, index) => (
								<TableRow
									key={row?.id || index}
									rowData={row}
									columns={columns}
									handleDelete={() => setDeleteDevice(row)}
									handleEdit={() => setEditDevice(row)}
								/>
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<TablePagination
					rowsPerPageOptions={[10, 30, 50]}
					component='div'
					count={totalElements}
					rowsPerPage={activeFilters.find(x => x.field === "size").value}
					page={activeFilters.find(x => x.field === "page").value}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeSize}
				/>
			</div>
		</div>
	);
};

export default Devices;

function TableRow({ rowData, columns, handleDelete, handleEdit }) {
	const classes = useSettingsStyles();
	return (
		<>
			<tr className={classes.spacing}></tr>
			<tr className={classes.hover}>
				{columns.map(({ field, type }, index) =>
					type === "copy" ? (
						<CopyWithAlert
							text={rowData[field] + "" || ""}
							label={"id"}
							key={field + index}>
							<td className={clsx(classes.td, classes.paper, classes.hoverTd)}>
								{rowData[field]}
							</td>
						</CopyWithAlert>
					) : type === "date" ? (
						<td className={clsx(classes.td, classes.paper)} key={field + index}>
							{rowData[field] &&
								moment(rowData[field]).format("DD.MM.yyyy HH:mm")}
						</td>
					) : type === "manage" ? (
						<td className={clsx(classes.td, classes.paper)} key={field + index}>
							<IconButton onClick={handleEdit}>
								<EditIcon />
							</IconButton>
							<IconButton onClick={handleDelete}>
								<DeleteIcon style={{ color: "#ff0000" }} />
							</IconButton>
						</td>
					) : type === "number" ? (
						<td className={clsx(classes.td, classes.paper)} key={field + index}>
							{+rowData[field]}
						</td>
					) : (
						<td className={clsx(classes.td, classes.paper)} key={field + index}>
							{rowData[field]}
						</td>
					)
				)}
			</tr>
		</>
	);
}
