import { useEffect, useState } from "react";
import DialogWrapper from "../../../../theme/components/dialogs/DialogWrapper";
import {
	Grid,
	Typography,
	ButtonBase,
	InputAdornment,
} from "@material-ui/core";
import { Button } from "../../../../theme/components/Button";
import { Input } from "../../../../theme/components/Input";

const DecisionInvoiceDialog = ({
	open,
	title,
	data,
	handleClose,
	btnOkText = "Ok",
	btnCancelText = "Cancel",
}) => {
	const [form, setForm] = useState({ commissionPercent: 0 });
	const [errors, setErrors] = useState({});
	useEffect(() => {
		if (!data) {
			return;
		}
		setForm(data);
	}, [data]);

	const handleChangeCommissionPercent = value => {
		if (+value > 100) {
			setErrors({
				commissionPercent: {
					invalid: true,
					errorMessage: "The commission cannot be more than 100%.",
				},
			});
		} else {
			setErrors({});
		}
		setForm({ ...form, commissionPercent: value });
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={() => handleClose({ accept: false })}>
			<Typography
				variant={"h6"}
				align={"center"}
				color={"textSecondary"}
				style={{ marginBottom: 24 }}>
				{title}
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Input
						style={{ marginBottom: 24 }}
						label={"Commission percentage"}
						variant='outlined'
						name={"commissionPercent"}
						value={form?.commissionPercent}
						onChange={({ target: { value, name } }) =>
							/^[0-9]*$/.test(value) &&
							!(value.length > 1 && value[0] === "0" && value[1] !== ".") &&
							handleChangeCommissionPercent(+value)
						}
						onFocus={() =>
							!form.commissionPercent
								? setForm({
										...form,
										commissionPercent: "",
								  })
								: setForm({ ...form })
						}
						onBlur={() =>
							!form.commissionPercent &&
							setForm({ ...form, commissionPercent: 0 })
						}
						error={errors?.commissionPercent?.invalid}
						helperText={errors?.commissionPercent?.errorMessage}
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							autoComplete: "off",
							endAdornment: (
								<InputAdornment position='end'>
									<ButtonBase disabled>&#xFF05;</ButtonBase>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>
			<Grid container justifyContent='space-between'>
				<Button
					color={"primary"}
					style={{ width: "max(25%, 50px)", marginTop: 12 }}
					onClick={() => handleClose({ accept: false })}>
					{btnCancelText}
				</Button>
				<Button
					color={"primary"}
					style={{
						width: "max(25%, 50px)",
						marginTop: 12,
						color: btnOkText === "Ok" ? "#60CF61" : "#E44A4A",
					}}
					onClick={() =>
						!errors?.commissionPercent?.invalid &&
						handleClose({ accept: true, form })
					}>
					{btnOkText}
				</Button>
			</Grid>
		</DialogWrapper>
	);
};

export default DecisionInvoiceDialog;
