import {
	GET_PROGRAM_PENDING_CONFIRMATION,
	GET_TOTAL_SHARES,
	GET_TOTAL_SHARES_USER,
	GET_DEVICES,
	CREATE_DEVICE,
	EDIT_DEVICE,
	DELETE_DEVICE,
	GET_SERVICE_ACCOUNT_BALANCES,
	GET_DISTRIBUTION_HISTORY,
	GET_CURRENT_SHARES,
	CALCULATE_CURRENT_SHARES,
	SET_SHARES_DISTRIBUTION_FORCEFULLY,
	GET_HISTORY_USED_PROGRAMS,
	PROGRAM_START,
	CLEAR_DHS_STATUS,
	CLEAR_DHS_ERROR,
	GET_OWNERS_HISTORY,
} from "../types";
import api from "../api";
import { enqueueSnackbar } from "./notistackActions";
import { Message } from "../../utils/enum";
import { actionCreator } from "../utils";

// GET_PROGRAM_PENDING_CONFIRMATION
export const getProgramPendingConfirmationAction = actionCreator(
	GET_PROGRAM_PENDING_CONFIRMATION
);

export const getProgramPendingConfirmation = () => {
	return async dispatch => {
		dispatch(getProgramPendingConfirmationAction.started());
		api.dhs
			.getProgramPendingConfirmation()
			.then(res => dispatch(getProgramPendingConfirmationAction.done(res.data)))
			.catch(error =>
				dispatch(getProgramPendingConfirmationAction.failed(error))
			);
	};
};

// GET_TOTAL_SHARES
export const getTotalSharesAction = actionCreator(GET_TOTAL_SHARES);

export const getTotalShares = params => {
	return async dispatch => {
		dispatch(getTotalSharesAction.started());
		api.dhs
			.getTotalShares(params)
			.then(res => dispatch(getTotalSharesAction.done(res.data)))
			.catch(error => dispatch(getTotalSharesAction.failed(error)));
	};
};

// GET_TOTAL_SHARES_USER
export const getTotalSharesUserAction = actionCreator(GET_TOTAL_SHARES_USER);

export const getTotalSharesUser = params => {
	return async dispatch => {
		dispatch(getTotalSharesUserAction.started());
		api.dhs
			.getTotalSharesUser(params)
			.then(res => dispatch(getTotalSharesUserAction.done(res.data)))
			.catch(error => dispatch(getTotalSharesUserAction.failed(error)));
	};
};

// GET_DEVICES
export const getDevicesAction = actionCreator(GET_DEVICES);

export const getDevices = params => {
	return async dispatch => {
		dispatch(getDevicesAction.started());
		api.dhs.device
			.getDevices(params)
			.then(res => dispatch(getDevicesAction.done(res.data)))
			.catch(error => dispatch(getDevicesAction.failed(error)));
	};
};

// CREATE_DEVICE
export const createDeviceAction = actionCreator(CREATE_DEVICE);

export const createDevice = (data, params) => {
	return async dispatch => {
		dispatch(createDeviceAction.started());
		api.dhs.device
			.create(data)
			.then(res => {
				dispatch(createDeviceAction.done(res.data));
				dispatch(getDevices(params));
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(createDeviceAction.failed(error)));
	};
};

// EDIT_DEVICE
export const editDeviceAction = actionCreator(EDIT_DEVICE);

export const editDevice = (data, params) => {
	return async dispatch => {
		dispatch(editDeviceAction.started());
		api.dhs.device
			.edit(data)
			.then(res => {
				dispatch(editDeviceAction.done(res.data));
				dispatch(getDevices(params));
				dispatch(
					enqueueSnackbar({
						message: Message.DataChanged,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(editDeviceAction.failed(error)));
	};
};

// DELETE_DEVICE
export const deleteDeviceAction = actionCreator(DELETE_DEVICE);

export const deleteDevice = (deviceId, params) => {
	return async dispatch => {
		dispatch(deleteDeviceAction.started());
		api.dhs.device
			.delete(deviceId)
			.then(res => {
				dispatch(deleteDeviceAction.done(res.data));
				dispatch(getDevices(params));
				dispatch(
					enqueueSnackbar({
						message: Message.DataDeleted,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(deleteDeviceAction.failed(error)));
	};
};

// GET_SERVICE_ACCOUNT_BALANCES
export const getServiceAccountBalancesAction = actionCreator(
	GET_SERVICE_ACCOUNT_BALANCES
);

export const getServiceAccountBalances = () => {
	return async dispatch => {
		dispatch(getServiceAccountBalancesAction.started());
		api.dhs
			.getServiceAccountBalances()
			.then(res => dispatch(getServiceAccountBalancesAction.done(res.data)))
			.catch(error => dispatch(getServiceAccountBalancesAction.failed(error)));
	};
};

// GET_DISTRIBUTION_HISTORY
export const getDistributionHistoryAction = actionCreator(
	GET_DISTRIBUTION_HISTORY
);

export const getDistributionHistory = params => {
	return async dispatch => {
		dispatch(getDistributionHistoryAction.started());
		api.dhs
			.getDistributionHistory(params)
			.then(res => dispatch(getDistributionHistoryAction.done(res.data)))
			.catch(error => dispatch(getDistributionHistoryAction.failed(error)));
	};
};

// GET_CURRENT_SHARES
export const getCurrentSharesAction = actionCreator(GET_CURRENT_SHARES);

export const getCurrentShares = params => {
	return async dispatch => {
		dispatch(getCurrentSharesAction.started());
		api.dhs
			.getCurrentShares(params)
			.then(res => dispatch(getCurrentSharesAction.done(res.data)))
			.catch(error => dispatch(getCurrentSharesAction.failed(error)));
	};
};

//  CALCULATE_CURRENT_SHARES
export const calculateCurrentSharesAction = actionCreator(
	CALCULATE_CURRENT_SHARES
);

export const calculateCurrentShares = params => {
	return async dispatch => {
		dispatch(calculateCurrentSharesAction.started());
		api.dhs
			.calculateCurrentShares(params)
			.then(res => {
				dispatch(calculateCurrentSharesAction.done(res.data));
				dispatch(getProgramPendingConfirmation());
				dispatch(getTotalShares());
				dispatch(getServiceAccountBalances());
				dispatch(
					enqueueSnackbar({
						message: Message.SuccessfulDistribution,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => {
				dispatch(calculateCurrentSharesAction.failed(error));
			});
	};
};

// SET_SHARES_DISTRIBUTION_FORCEFULLY
export const setSharesDistributionForcefullyAction = actionCreator(
	SET_SHARES_DISTRIBUTION_FORCEFULLY
);

export const setSharesDistributionForcefully = data => {
	return async dispatch => {
		dispatch(setSharesDistributionForcefullyAction.started());
		api.dhs
			.setSharesDistributionForcefully(data)
			.then(res => {
				dispatch(setSharesDistributionForcefullyAction.done(res.data));
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error =>
				dispatch(setSharesDistributionForcefullyAction.failed(error))
			);
	};
};

// GET_HISTORY_USED_PROGRAMS
export const getHistoryUsedProgramsAction = actionCreator(
	GET_HISTORY_USED_PROGRAMS
);

export const getHistoryUsedPrograms = params => {
	return async dispatch => {
		dispatch(getHistoryUsedProgramsAction.started());
		api.dhs
			.getHistoryUsedPrograms(params)
			.then(res => dispatch(getHistoryUsedProgramsAction.done(res.data)))
			.catch(error => dispatch(getHistoryUsedProgramsAction.failed(error)));
	};
};

// PROGRAM_START
export const programStartAction = actionCreator(PROGRAM_START);

export const programStart = data => {
	return async dispatch => {
		dispatch(programStartAction.started());
		api.dhs
			.programStart(data)
			.then(res => {
				dispatch(programStartAction.done(res.data));
				dispatch(
					enqueueSnackbar({
						message: Message.DataSaved,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(programStartAction.failed(error)));
	};
};

export const clearDhsStatus = () => ({
	type: CLEAR_DHS_STATUS,
});

export const clearDhsError = () => ({
	type: CLEAR_DHS_ERROR,
});

// GET_OWNERS_HISTORY
export const getOwnersHistoryAction = actionCreator(GET_OWNERS_HISTORY);

export const getOwnersHistory = params => {
	return async dispatch => {
		dispatch(getHistoryUsedProgramsAction.started());
		api.dhs
			.getOwnersHistory(params)
			.then(res => dispatch(getOwnersHistoryAction.done(res.data)))
			.catch(error => dispatch(getOwnersHistoryAction.failed(error)));
	};
};
