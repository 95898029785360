import React, { useEffect, useState } from "react";
import {
	Tabs,
	Tab,
	Box,
	Paper,
	IconButton,
	Typography,
	makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import BackIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import PropTypes from "prop-types";
import CompanyBalanceUsers from "./CompanyBalanceUsers";
import CompanyBalanceSecuritySettings from "./CompanyBalanceSecuritySettings";
import { useDispatch, useSelector } from "react-redux";
import CompanyBalanceSecuritySettingsUsers from "./CompanyBalanceSecuritySettingsUsers";
import { clearCompanyBalanceSecuritySettings, clearCompanyBalanceUsers, getCompanyBalanceSecuritySettingsList } from "../../app/actions/companiesActions";

const tabs = {
	users: {
		label: "Users",
		index: 0,
	},
	securitySettings: {
		label: "Security Settings",
		index: 1,
	},
	securitySettingsUsers: {
		label: "Security Settings Users",
		index: 2,
	},
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}>
			{value === index && <Box style={{ paddingTop: 24 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const CompanyBalanceDetails = () => {
	const classes = useStyles();
	const history = useHistory();
	const routeParams = useParams();
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(tabs.users.index);
	const balanceId = routeParams?.balanceId;
	const companyBalanceSecuritySettings = useSelector(({ companies }) => companies.companyBalanceSecuritySettings);
	const showSecuritySettingsTab = Boolean(companyBalanceSecuritySettings?.id)

	const handleChangeTabs = (_, newTab) => {
		setActiveTab(newTab);
	};

	const goBack = () => {
		history.goBack?.()
	};

	useEffect(() => {
		dispatch(getCompanyBalanceSecuritySettingsList(balanceId));
		return () => {
			dispatch(clearCompanyBalanceSecuritySettings());
			dispatch(clearCompanyBalanceUsers());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={classes.root} style={{ maxWidth: "100%" }}>
			<div className={classes.btnWrap}>
				<Paper elevation={0} className={classes.paper} onClick={goBack}>
					<IconButton className={classes.icon} size={"small"}>
						<BackIcon />
					</IconButton>
					<Typography variant={"subtitle2"} color={"textSecondary"}>
						Back to{" "}
						<span className={classes.link}>
							{history?.location?.state?.title || "Companies"}
						</span>
					</Typography>
				</Paper>
			</div>
			<Tabs
				value={activeTab}
				onChange={handleChangeTabs}
				aria-label='simple tabs example'
				indicatorColor='primary'>
				<Tab label={tabs.users.label} {...a11yProps(0)} />
				<Tab label={tabs.securitySettings.label} {...a11yProps(1)} />
				{
					showSecuritySettingsTab &&
					<Tab label={tabs.securitySettingsUsers.label} {...a11yProps(2)} />
				}
			</Tabs>
			<TabPanel value={activeTab} index={0}>
				<CompanyBalanceUsers />
			</TabPanel>
			<TabPanel value={activeTab} index={1}>
				<CompanyBalanceSecuritySettings />
			</TabPanel>
			<TabPanel value={activeTab} index={2}>
				<CompanyBalanceSecuritySettingsUsers />
			</TabPanel>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		background: theme.palette.background.default,
		boxShadow: "none",
	},
	btnWrap: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	},
	paper: {
		display: "flex",
		alignItems: "center",
		padding: 8,
		paddingRight: 12,
		borderRadius: 8,
		width: "max-content",
		marginBottom: 16,
		"&:hover": {
			cursor: "pointer",
		},
	},
	icon: {
		color: theme.palette.primary.light,
		padding: 0,
		marginRight: 4,
		"& svg": {
			fontSize: 19,
		},
	},
	link: {
		color: theme.palette.primary.light,
		fontWeight: 700,
		textDecoration: "none",
	},
}));

export default CompanyBalanceDetails;
