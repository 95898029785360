import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Typography, Paper, IconButton } from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import AdminProfile from "./AdminProfile";
import ActionLog from "../ActionLog";
import Sessions from "../Sessions";
import { getProfileById, getRoles } from "../../app/actions/usersActions";
import { getBlockersById } from "../../app/actions/blockingActions";
import UserVipFunds from "../Users/UserVipFunds";
import UserVipReferrals from "../Users/UserVipReferrals";
import { Button } from "../../theme/components/Button";
import AddFundDialog from "../Users/AddFundDialog";

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	const classes = useStyles();

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}>
			{value === index && (
				<Box className={value !== 1 && value !== 3 ? classes.tab : ""}>
					{children}
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const AdminCard = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { id } = useParams();
	const history = useHistory();
	const [value, setValue] = useState(0);
	const [params, setParams] = useState();
	const [showAddFundDialog, setShowAddFundDialog] = useState(false);
	const profile = useSelector(({ users }) => users.profile);

	useEffect(() => {
		setParams(history?.location?.state?.params);
		if (!!id) dispatch(getBlockersById(id));
		dispatch(getProfileById(id));
	}, [dispatch, id, history?.location?.state]);

	const handleChangeTabs = (event, newValue) => {
		setValue(newValue);
	};

	const handleAddFund = () => {
		setShowAddFundDialog(true);
	};

	const handleCloseDialog = () => {
		setShowAddFundDialog(false);
	};

	useEffect(() => {
		dispatch(getRoles());
	}, [dispatch]);

	return (
		<div>
			<AddFundDialog
				open={showAddFundDialog}
				handleClose={handleCloseDialog}
				userId={id}
			/>
			<div className={classes.head}>
				<Paper
					elevation={0}
					className={classes.paper}
					onClick={() =>
						history.push("/admins/list", { from: "admin detail", params })
					}>
					<IconButton className={classes.icon} size={"small"}>
						<BackIcon />
					</IconButton>
					<Typography variant={"subtitle2"} color={"textSecondary"}>
						Back to <span className={classes.link}>Admins</span>
					</Typography>
				</Paper>
				<Typography variant={"h4"} color={"textPrimary"}>
					Admin
				</Typography>
			</div>
			<div className={classes.root}>
				<Tabs
					value={value}
					onChange={handleChangeTabs}
					aria-label='simple tabs example'
					indicatorColor='primary'>
					<Tab label='Profile' {...a11yProps(0)} />
					<Tab label='Sessions' {...a11yProps(1)} />
					<Tab label='Action log' {...a11yProps(2)} />
					{profile?.vip && <Tab label='Funds' {...a11yProps(3)} />}
					{profile?.vip && <Tab label='Referrals' {...a11yProps(4)} />}
				</Tabs>
				<TabPanel value={value} index={0}>
					<AdminProfile />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Sessions id={id} />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<ActionLog id={id} />
				</TabPanel>
				<TabPanel value={value} index={3}>
					<div className={classes.vipFundsContainer}>
						<Button
							variant='outlined'
							color='primary'
							onClick={handleAddFund}
							className={classes.addFundButton}>
							<AddIcon />
							Add Fund
						</Button>
						<UserVipFunds />
					</div>
				</TabPanel>
				<TabPanel value={value} index={4}>
					<UserVipReferrals />
				</TabPanel>
			</div>
		</div>
	);
};

export default AdminCard;

const useStyles = makeStyles((theme) => ({
	head: {
		marginBottom: 16,
		"&>.MuiTextField-root": {
			maxWidth: 256,
			marginTop: 24,
			[theme.breakpoints.down("sm")]: {
				marginTop: 24,
			},
			"&>.MuiOutlinedInput-root>input": {
				padding: 10,
			},
		},
	},
	paper: {
		display: "flex",
		alignItems: "center",
		padding: 8,
		paddingRight: 12,
		borderRadius: 8,
		width: "max-content",
		marginBottom: 16,
		"&:hover": {
			cursor: "pointer",
		},
	},
	icon: {
		color: theme.palette.primary.light,
		padding: 0,
		marginRight: 4,
		"& svg": {
			fontSize: 19,
		},
	},
	link: {
		color: theme.palette.primary.light,
		fontWeight: 700,
		textDecoration: "none",
	},
	root: {
		"&>.MuiAccordion-rounded:last-child": {
			borderRadius: 0,
		},
	},
	tab: {
		paddingTop: theme.spacing(4),
	},
	vipFundsContainer: {},
	addFundButton: {
		marginBottom: "15px",
		float: "right",
	},
}));
