import { useEffect, useRef, useState } from "react";
import { useSettingsStyles } from "../SettingsStyles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button } from "../../../theme/components/Button";
import AddIcon from "@material-ui/icons/Add";
import { IconButton, Typography, TablePagination } from "@material-ui/core";
import {
	getSettingsWithdraw,
	getSettingsDeposits,
	clearTransactionsSettings,
} from "../../../app/actions/settingsActions";
import clsx from "clsx";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Search from "../../../theme/components/Search";
import DeleteTransactionsSettingsDialog from "./DeleteTransactionsSettingsDialog";
import EditTransactionsSettingsDialog from "./EditTransactionsSettingsDialog";
import CopyWithAlert from "../../../utils/CopyWithAlert";

const withdrawColumns = [
	{ field: "id", headerName: "ID", type: "copy" },
	{ field: "ticker", headerName: "Ticker", type: "text" },
	{ field: "cnSlow", headerName: "Commission (Slow)", type: "text" },
	{ field: "cnMedium", headerName: "Commission (Medium)", type: "text" },
	{ field: "cnFast", headerName: "Commission (Fast)", type: "text" },
	{ field: "system", headerName: "System Fee", type: "text" },
	{ field: "minWithdraw", headerName: "Min amount", type: "text" },
	{ field: "maxWithdraw", headerName: "Max amount", type: "text" },
	{ field: "update", headerName: "Updated", type: "date" },
	{ field: "edit", headerName: "Manage", type: "manage" },
];
const depositColumns = [
	{ field: "id", headerName: "ID", type: "copy" },
	{ field: "ticker", headerName: "Ticker", type: "text" },
	{ field: "minDeposit", headerName: "Min amount", type: "text" },
	{ field: "update", headerName: "Updated", type: "date" },
	{ field: "edit", headerName: "Manage", type: "manage" },
];

const TransactionsSettings = () => {
	const classes = useSettingsStyles();
	const dispatch = useDispatch();
	const { type } = useParams();
	const { data, totalItems } = useSelector(
		({ settings }) => settings.transactions
	);
	const [params, setParams] = useState({
		page: 0,
		size: 10,
		sort: "update,desc",
	});
	const [search, setSearch] = useState("");
	const timeout = useRef(null);
	const [create, setCreate] = useState(false);
	const [deleteTransaction, setDeleteTransaction] = useState(null);
	const [editTransaction, setEditTransaction] = useState(null);
	const [columns, setColumns] = useState(withdrawColumns);

	useEffect(() => {
		dispatch(clearTransactionsSettings());
		if (type === "withdrawal") {
			dispatch(getSettingsWithdraw(params));
			setColumns(withdrawColumns);
		} else {
			dispatch(getSettingsDeposits(params));
			setColumns(depositColumns);
		}
	}, [dispatch, params, type]);

	const handleChangePage = (event, newPage) => {
		event.stopPropagation();
		setParams({ ...params, page: newPage });
	};

	const handleChangeSize = ({ target }) => {
		setParams({ ...params, size: target.value, page: 0 });
	};
	const handleSearch = ({ target }) => {
		setSearch(target.value);
		clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			setParams({ ...params, ticker: target.value?.toLowerCase() });
		}, 500);
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<DeleteTransactionsSettingsDialog
				open={Boolean(deleteTransaction)}
				handleClose={() => setDeleteTransaction(null)}
				type={type}
				params={params}
				{...deleteTransaction}
			/>
			<EditTransactionsSettingsDialog
				open={Boolean(editTransaction) || create}
				handleClose={() => {
					setEditTransaction(null);
					setCreate(false);
				}}
				transactionsType={type}
				data={editTransaction}
				params={params}
			/>
			<div className={classes.head}>
				<Typography variant={"h4"} color={"textPrimary"}>
					{`${type === "withdrawal" ? "Withdrawal" : "Deposit"} settings`}
				</Typography>
				<div style={{ display: "flex" }}>
					<Search value={search} onChange={handleSearch} />
					<Button
						variant='outlined'
						color='primary'
						style={{ marginLeft: 16, padding: "4px 28px" }}
						onClick={() => setCreate(true)}>
						<AddIcon />
						Create
					</Button>
				</div>
			</div>
			<div className={classes.root}>
				<table className={classes.table}>
					<thead>
						<tr>
							{columns.map(({ field, headerName }) => (
								<th
									key={field}
									className={clsx(classes.th, classes.paper)}
									align={"left"}>
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{data.length > 0 ? (
							data.map((row, index) => (
								<TableRow
									key={row?.id || index}
									rowData={row}
									columns={columns}
									handleDelete={() => setDeleteTransaction(row)}
									handleEdit={() => setEditTransaction(row)}
									type={type}
								/>
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<TablePagination
					rowsPerPageOptions={[10, 30, 50]}
					component='div'
					count={totalItems}
					rowsPerPage={params?.size}
					page={params?.page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeSize}
				/>
			</div>
		</div>
	);
};

export default TransactionsSettings;

function TableRow({ rowData, columns, handleDelete, handleEdit, type }) {
	const classes = useSettingsStyles();
	return (
		<>
			<tr className={classes.spacing}></tr>
			<tr className={classes.hover}>
				{columns.map(({ field, type }, index) =>
					type === "copy" ? (
						<CopyWithAlert
							text={rowData[field] + "" || ""}
							label={"id"}
							key={field + index}>
							<td className={clsx(classes.td, classes.paper, classes.hoverTd)}>
								{rowData[field]}
							</td>
						</CopyWithAlert>
					) : type === "date" ? (
						<td className={clsx(classes.td, classes.paper)} key={field + index}>
							{rowData[field] &&
								moment(rowData[field]).format("DD.MM.yyyy HH:mm")}
						</td>
					) : type === "manage" ? (
						<td className={clsx(classes.td, classes.paper)} key={field + index}>
							<IconButton onClick={handleEdit}>
								<EditIcon />
							</IconButton>
							<IconButton onClick={handleDelete}>
								<DeleteIcon style={{ color: "#ff0000" }} />
							</IconButton>
						</td>
					) : (
						<td className={clsx(classes.td, classes.paper)} key={field + index}>
							{rowData[field]}
						</td>
					)
				)}
			</tr>
		</>
	);
}
