export const SORT_TYPES = {
	none: "none",
	up: "asc",
	down: "desc",
};

export function next(type) {
	switch (type) {
		case SORT_TYPES.up:
			return SORT_TYPES.down;
		case SORT_TYPES.down:
			return SORT_TYPES.up;
		default:
			return SORT_TYPES.up;
	}
}
