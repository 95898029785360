import moment from "moment";

export const KYC_DETAILS = [
	{ key: "id", title: "KYC ID", type: "string" },
	{ key: "status", title: "Status", type: "string" },
	{
		key: "idOfPersonResponsibleForDecisionOnThisKyc",
		title: "Decision is made",
		type: "string",
	},
	{ key: "decision", title: "Decision", type: "string" },
	{ key: "userId", title: "User ID", type: "string" },
	{ key: "firstName", title: "First Name", type: "string" },
	{ key: "lastName", title: "Last Name", type: "string" },
	{ key: "middleName", title: "Middle Name", type: "string" },
	{ key: "dob", title: "Date of birth", type: "string" },
	{ key: "gender", title: "Gender", type: "string" },
	{ key: "countryCode", title: "Country" },
	{ key: "postIndex", title: "Post code", type: "string" },
	{ key: "city", title: "City", type: "string" },
	{ key: "address", title: "Address", type: "string" },
	{ key: "createdDate", title: "Created", type: "date" },
	{ key: "completeDate", title: "Completed", type: "date" },
	{ key: "expirationDate", title: "Expiration", type: "date" },
];

export const KYC_ALL_COLUMNS = [
	{ key: "userId", headerName: "User ID" },
	{ key: "firstName", headerName: "First Name" },
	{ key: "lastName", headerName: "Last Name" },
	{ key: "middleName", headerName: "Middle Name" },
	{ key: "dob", headerName: "Date of birth" },
	{ key: "gender", headerName: "Gender" },
	{ key: "countryCode", headerName: "Country" },
	{ key: "status", headerName: "Status" },
	{ key: "createdDate", headerName: "Created" },
	{ key: "completeDate", headerName: "Completed", tab: "all" },
	{ key: "expirationDate", headerName: "Expiration", tab: "all" },
	{ key: "manage", headerName: "Manage" },
];

export const KYC_COLUMNS = [
	{ key: "userId", headerName: "User ID" },
	{ key: "firstName", headerName: "First Name" },
	{ key: "lastName", headerName: "Last Name" },
	{ key: "middleName", headerName: "Middle Name" },
	{ key: "dob", headerName: "Date of birth" },
	{ key: "gender", headerName: "Gender" },
	{ key: "countryCode", headerName: "Country" },
	{ key: "status", headerName: "Status" },
	{ key: "createdDate", headerName: "Created" },
	{ key: "manage", headerName: "Manage" },
];

export const GENDERS_FILTER = [
	{ label: "Male", value: "MALE" },
	{ label: "Female", value: "FEMALE" },
	{ label: "Other", value: "OTHER" },
];

export const DOCUMENTS_FILTER = [
	{ label: "Id card", value: "ID_CARD" },
	{ label: "Driver license", value: "DRIVER_LICENSE" },
	{ label: "Passport", value: "PASSPORT" },
];

export const STATUS_FILTER = [
	{ label: "APPROVED", value: "APPROVED" },
	{ label: "PENDING", value: "PENDING" },
	{ label: "DECLINED", value: "DECLINED" },
	{ label: "EXPIRED", value: "EXPIRED" },
];

export const FILE_TYPES = ["image/jpeg", "image/png"];

export const KYC_SOLUTION = {
	DECLINED: "DECLINED",
	APPROVED: "APPROVED",
};

export const KYC_STATUS = {
	DECLINED: "DECLINED",
	PENDING: "PENDING",
	APPROVED: "APPROVED",
};

export const PHOTO_TYPES = {
	SELFIE: "SELFIE",
	DOCUMENT_AVERS: "DOCUMENT_AVERS",
	DOCUMENT_REVERS: "DOCUMENT_REVERS",
};

export const DOCUMENT_TYPES = {
	ID_CARD: "ID_CARD",
	DRIVER_LICENSE: "DRIVER_LICENSE",
	PASSPORT: "PASSPORT",
};

export const VERIFICATION_METHODS = {
	quick: "Quick verification",
	normal: "Normal verification",
};

export const GENDERS = ["Male", "Female", "Other"];

export const YEARS = () => {
	const maxYear = moment().format("YYYY");
	const arr = [];
	for (let index = 1900; index < maxYear; index++) {
		arr.push(index + "");
	}
	return arr.reverse();
};

export const MONTHS = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export const DAYS = [
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"10",
	"11",
	"12",
	"13",
	"14",
	"15",
	"16",
	"17",
	"18",
	"19",
	"20",
	"21",
	"22",
	"23",
	"24",
	"25",
	"26",
	"27",
	"28",
	"29",
	"30",
	"31",
];

export const COUNTRIES = {
	ABW: "Aruba",
	AFG: "Afghanistan",
	AGO: "Angola",
	AIA: "Anguilla",
	ALA: "Åland Islands",
	ALB: "Albania",
	AND: "Andorra",
	ARE: "United Arab Emirates",
	ARG: "Argentina",
	ARM: "Armenia",
	ASM: "American Samoa",
	ATA: "Antarctica",
	ATF: "French Southern Territories",
	ATG: "Antigua and Barbuda",
	AUS: "Australia",
	AUT: "Austria",
	AZE: "Azerbaijan",
	BDI: "Burundi",
	BEL: "Belgium",
	BEN: "Benin",
	BES: "Bonaire, Sint Eustatius and Saba",
	BFA: "Burkina Faso",
	BGD: "Bangladesh",
	BGR: "Bulgaria",
	BHR: "Bahrain",
	BHS: "Bahamas",
	BIH: "Bosnia and Herzegovina",
	BLM: "Saint Barthélemy",
	BLR: "Belarus",
	BLZ: "Belize",
	BMU: "Bermuda",
	BOL: "Bolivia (Plurinational State of)",
	BRA: "Brazil",
	BRB: "Barbados",
	BRN: "Brunei Darussalam",
	BTN: "Bhutan",
	BVT: "Bouvet Island",
	BWA: "Botswana",
	CAF: "Central African Republic",
	CAN: "Canada",
	CCK: "Cocos (Keeling) Islands",
	CHE: "Switzerland",
	CHL: "Chile",
	CHN: "China",
	CIV: "Côte d'Ivoire",
	CMR: "Cameroon",
	COD: "Congo, Democratic Republic of the",
	COG: "Congo",
	COK: "Cook Islands",
	COL: "Colombia",
	COM: "Comoros",
	CPV: "Cabo Verde",
	CRI: "Costa Rica",
	CUB: "Cuba",
	CUW: "Curaçao",
	CXR: "Christmas Island",
	CYM: "Cayman Islands",
	CYP: "Cyprus",
	CZE: "Czechia",
	DEU: "Germany",
	DJI: "Djibouti",
	DMA: "Dominica",
	DNK: "Denmark",
	DOM: "Dominican Republic",
	DZA: "Algeria",
	ECU: "Ecuador",
	EGY: "Egypt",
	ERI: "Eritrea",
	ESH: "Western Sahara",
	ESP: "Spain",
	EST: "Estonia",
	ETH: "Ethiopia",
	FIN: "Finland",
	FJI: "Fiji",
	FLK: "Falkland Islands (Malvinas)",
	FRA: "France",
	FRO: "Faroe Islands",
	FSM: "Micronesia (Federated States of)",
	GAB: "Gabon",
	GBR: "United Kingdom of Great Britain and Northern Ireland",
	GEO: "Georgia",
	GGY: "Guernsey",
	GHA: "Ghana",
	GIB: "Gibraltar",
	GIN: "Guinea",
	GLP: "Guadeloupe",
	GMB: "Gambia",
	GNB: "Guinea-Bissau",
	GNQ: "Equatorial Guinea",
	GRC: "Greece",
	GRD: "Grenada",
	GRL: "Greenland",
	GTM: "Guatemala",
	GUF: "French Guiana",
	GUM: "Guam",
	GUY: "Guyana",
	HKG: "Hong Kong",
	HMD: "Heard Island and McDonald Islands",
	HND: "Honduras",
	HRV: "Croatia",
	HTI: "Haiti",
	HUN: "Hungary",
	IDN: "Indonesia",
	IMN: "Isle of Man",
	IND: "India",
	IOT: "British Indian Ocean Territory",
	IRL: "Ireland",
	IRN: "Iran (Islamic Republic of)",
	IRQ: "Iraq",
	ISL: "Iceland",
	ISR: "Israel",
	ITA: "Italy",
	JAM: "Jamaica",
	JEY: "Jersey",
	JOR: "Jordan",
	JPN: "Japan",
	KAZ: "Kazakhstan",
	KEN: "Kenya",
	KGZ: "Kyrgyzstan",
	KHM: "Cambodia",
	KIR: "Kiribati",
	KNA: "Saint Kitts and Nevis",
	KOR: "Korea, Republic of",
	KWT: "Kuwait",
	LAO: "Lao People's Democratic Republic",
	LBN: "Lebanon",
	LBR: "Liberia",
	LBY: "Libya",
	LCA: "Saint Lucia",
	LIE: "Liechtenstein",
	LKA: "Sri Lanka",
	LSO: "Lesotho",
	LTU: "Lithuania",
	LUX: "Luxembourg",
	LVA: "Latvia",
	MAC: "Macao",
	MAF: "Saint Martin (French part)",
	MAR: "Morocco",
	MCO: "Monaco",
	MDA: "Moldova, Republic of",
	MDG: "Madagascar",
	MDV: "Maldives",
	MEX: "Mexico",
	MHL: "Marshall Islands",
	MKD: "North Macedonia",
	MLI: "Mali",
	MLT: "Malta",
	MMR: "Myanmar",
	MNE: "Montenegro",
	MNG: "Mongolia",
	MNP: "Northern Mariana Islands",
	MOZ: "Mozambique",
	MRT: "Mauritania",
	MSR: "Montserrat",
	MTQ: "Martinique",
	MUS: "Mauritius",
	MWI: "Malawi",
	MYS: "Malaysia",
	MYT: "Mayotte",
	NAM: "Namibia",
	NCL: "New Caledonia",
	NER: "Niger",
	NFK: "Norfolk Island",
	NGA: "Nigeria",
	NIC: "Nicaragua",
	NIU: "Niue",
	NLD: "Netherlands",
	NOR: "Norway",
	NPL: "Nepal",
	NRU: "Nauru",
	NZL: "New Zealand",
	OMN: "Oman",
	PAK: "Pakistan",
	PAN: "Panama",
	PCN: "Pitcairn",
	PER: "Peru",
	PHL: "Philippines",
	PLW: "Palau",
	PNG: "Papua New Guinea",
	POL: "Poland",
	PRI: "Puerto Rico",
	PRK: "Korea (Democratic People's Republic of)",
	PRT: "Portugal",
	PRY: "Paraguay",
	PSE: "Palestine, State of",
	PYF: "French Polynesia",
	QAT: "Qatar",
	REU: "Réunion",
	ROU: "Romania",
	RUS: "Russian Federation",
	RWA: "Rwanda",
	SAU: "Saudi Arabia",
	SDN: "Sudan",
	SEN: "Senegal",
	SGP: "Singapore",
	SGS: "South Georgia and the South Sandwich Islands",
	SHN: "Saint Helena, Ascension and Tristan da Cunha",
	SJM: "Svalbard and Jan Mayen",
	SLB: "Solomon Islands",
	SLE: "Sierra Leone",
	SLV: "El Salvador",
	SMR: "San Marino",
	SOM: "Somalia",
	SPM: "Saint Pierre and Miquelon",
	SRB: "Serbia",
	SSD: "South Sudan",
	STP: "Sao Tome and Principe",
	SUR: "Suriname",
	SVK: "Slovakia",
	SVN: "Slovenia",
	SWE: "Sweden",
	SWZ: "Eswatini",
	SXM: "Sint Maarten (Dutch part)",
	SYC: "Seychelles",
	SYR: "Syrian Arab Republic",
	TCA: "Turks and Caicos Islands",
	TCD: "Chad",
	TGO: "Togo",
	THA: "Thailand",
	TJK: "Tajikistan",
	TKL: "Tokelau",
	TKM: "Turkmenistan",
	TLS: "Timor-Leste",
	TON: "Tonga",
	TTO: "Trinidad and Tobago",
	TUN: "Tunisia",
	TUR: "Türkiye",
	TUV: "Tuvalu",
	TWN: "Taiwan, Province of China",
	TZA: "Tanzania, United Republic of",
	UGA: "Uganda",
	UKR: "Ukraine",
	UMI: "United States Minor Outlying Islands",
	URY: "Uruguay",
	USA: "United States of America",
	UZB: "Uzbekistan",
	VAT: "Holy See",
	VCT: "Saint Vincent and the Grenadines",
	VEN: "Venezuela (Bolivarian Republic of)",
	VGB: "Virgin Islands (British)",
	VIR: "Virgin Islands (U.S.)",
	VNM: "Viet Nam",
	VUT: "Vanuatu",
	WLF: "Wallis and Futuna",
	WSM: "Samoa",
	YEM: "Yemen",
	ZAF: "South Africa",
	ZMB: "Zambia",
	ZWE: "Zimbabwe",
};
export const KYC_ALL_FILTER_FIELDS = [
	{ field: "userId", type: "number", title: "User ID", placeholder: "User ID" },
	{
		field: "firstName",
		type: "text",
		title: "First Name",
		placeholder: "First Name",
	},
	{
		field: "lastName",
		type: "text",
		title: "Last Name",
		placeholder: "Last Name",
	},
	{
		field: "	middleName",
		type: "text",
		title: "Middle Name",
		placeholder: "Middle Name",
	},
	// {
	// 	field: "status",
	// 	type: "select",
	// 	title: "Status",
	// 	placeholder: "Status",
	// 	data: STATUS_FILTER,
	// },
	{
		field: "documentType",
		type: "select",
		title: "Document type",
		placeholder: "Document type",
		data: DOCUMENTS_FILTER,
	},
	{
		field: "gender",
		type: "select",
		title: "Gender",
		placeholder: "Gender",
		data: GENDERS_FILTER,
	},
	{
		field: "countryCode",
		type: "select",
		title: "Country",
		placeholder: "Country",
		data: Object.keys(COUNTRIES).map(item => ({
			label: COUNTRIES[item],
			value: item,
		})),
	},
	{ field: "city", type: "text", title: "City", placeholder: "City" },
	{ field: "address", type: "text", title: "Address", placeholder: "Address" },
	{
		field: "postIndex",
		type: "text",
		title: "Post index",
		placeholder: "Post index",
	},
	{
		field: "creationDateFrom",
		type: "date",
		title: "Creation date from",
		placeholder: "Creation date from",
	},
	{
		field: "creationDateTo",
		type: "date",
		title: "Creation date to",
		placeholder: "Creation date to",
	},

	{
		field: "completeDateFrom",
		type: "date",
		title: "Complete date from",
		placeholder: "Complete date from",
	},
	{
		field: "completeDateTo",
		type: "date",
		title: "Complete date to",
		placeholder: "Complete date to",
	},
	{
		field: "expirationDateFrom",
		type: "date",
		title: "Expiration date from",
		placeholder: "Expiration date from",
	},
	{
		field: "expirationDateTo",
		type: "date",
		title: "Expiration date to",
		placeholder: "Expiration date to",
	},
];

export const KYC_FILTER_FIELDS = [
	{ field: "userId", type: "number", title: "User ID", placeholder: "User ID" },

	{
		field: "firstName",
		type: "text",
		title: "First Name",
		placeholder: "First Name",
	},
	{
		field: "lastName",
		type: "text",
		title: "Last Name",
		placeholder: "Last Name",
	},
	{
		field: "	middleName",
		type: "text",
		title: "Middle Name",
		placeholder: "Middle Name",
	},
	{
		field: "documentType",
		type: "select",
		title: "Document type",
		placeholder: "Document type",
		data: DOCUMENTS_FILTER,
	},
	{
		field: "gender",
		type: "select",
		title: "Gender",
		placeholder: "Gender",
		data: GENDERS_FILTER,
	},

	{
		field: "countryCode",
		type: "select",
		title: "Country",
		placeholder: "Country",
		data: Object.keys(COUNTRIES).map(item => ({
			label: COUNTRIES[item],
			value: item,
		})),
	},
	{ field: "city", type: "text", title: "City", placeholder: "City" },
	{ field: "address", type: "text", title: "Address", placeholder: "Address" },
	{
		field: "postIndex",
		type: "text",
		title: "Post index",
		placeholder: "Post index",
	},
	{
		field: "creationDateFrom",
		type: "date",
		title: "Creation date from",
		placeholder: "Creation date from",
	},
];
