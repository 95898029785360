import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../theme/components/PageTitle";
import {
	clearSyncHistory,
	getWithdrawalHistory,
} from "../../../app/actions/syncActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../app/actions/filterActions";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../../theme/components/Table/Table";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import getStatusColor from "../../../utils/functions/getStatusColor";

const withdrawalHistoryFilterFields = [
	{
		field: "id",
		type: "number",
		title: "ID",
		placeholder: "ID",
	},
	{
		field: "addressTo",
		type: "text",
		title: "Address To",
		placeholder: "Address To",
	},

	{ field: "txId", type: "text", title: "TxID", placeholder: "TxID" },
	{
		field: "withdrawalId",
		type: "text",
		title: " Withdrawal Id",
		placeholder: " Withdrawal Id",
	},
	{
		field: "currency",
		type: "text",
		title: "Currency",
		placeholder: "Currency",
	},
	{
		field: "exchange",
		type: "text",
		title: "Exchange",
		placeholder: "Exchange",
	},
	{ field: "status", type: "text", title: "Status", placeholder: "Status" },
	{
		field: "createdAtFrom",
		type: "date",
		title: "Create date from",
		placeholder: "Create date from",
	},
	{
		field: "createdAtTo",
		type: "date",
		title: "Create date to",
		placeholder: "Create date to",
	},
];

const columns = [
	{ key: "id", label: "Id" },
	{ key: "currency", label: "Currency" },
	{ key: "amount", label: "Amount" },
	{ key: "addressTo", label: "Address To" },
	{ key: "txId", label: "TxId" },
	{ key: "withdrawalId", label: "Withdrawal Id" },
	{ key: "exchange", label: "Exchange" },
	{ key: "createdAt", label: "Created" },
	{ key: "status", label: "Status" },
];

const WithdrawalHistory = () => {
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(
		({ synchronization }) => synchronization.sync
	);
	const [filterFields, setFilterFields] = useState(
		withdrawalHistoryFilterFields
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getWithdrawalHistory(filteringParams));
		setFilterFields(withdrawalHistoryFilterFields);
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
			dispatch(clearSyncHistory());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<PageTitle title={"Withdrawal history"} filterFields={filterFields} />
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find(x => x.field === "size").value}
				page={activeFilters?.find(x => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default WithdrawalHistory;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.currency + "" || ""} label={"Currency"}>
					<td data='hover'>{rowData?.currency}</td>
				</CopyWithAlert>
				<td>{rowData?.amount}</td>
				<CopyWithAlert
					text={rowData?.addressTo + "" || ""}
					label={"Address To"}>
					<td data='hover'>{rowData?.addressTo}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.txId + "" || ""} label={"TxId"}>
					<td data='hover'>{rowData?.txId}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.withdrawalId + "" || ""}
					label={"Withdrawal Id"}>
					<td data='hover'>{rowData?.withdrawalId}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.exchange + "" || ""} label={"Exchange"}>
					<td data='hover'>{rowData?.exchange}</td>
				</CopyWithAlert>
				<td>
					{rowData?.createdAt &&
						moment(rowData?.createdAt).format("DD MMM YYYY HH:mm")}
				</td>
				<td
					style={{
						color: rowData?.status && getStatusColor(rowData?.status),
					}}>
					{rowData?.status || "-"}
				</td>
			</tr>
		</>
	);
}
