import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../../../theme/components/Table/Table";
import CopyWithAlert from "../../../../utils/CopyWithAlert";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";
import getStatusColor from "../../../../utils/functions/getStatusColor";
import { searchFundOperationsHistory } from "../../../../app/actions/fundsCapitalActions";
import {
	clearIndexesOperationsHistory,
	searchIndexOperationsHistory,
} from "../../../../app/actions/indexesCapitalActions";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "userId", label: "User Id" },
	{ key: "token", label: "Token" },
	{ key: "tokenAmount", label: "Token Amount" },
	{ key: "asset", label: "Asset" },
	{ key: "Token price", label: "Token Price" },
	{ key: "assetAmount", label: "Asset Amount" },
	{ key: "assetCommission", label: "Asset Commission" },
	{ key: "type", label: "Type" },
	{ key: "time", label: "Created" },
];

const OperationsHistory = ({ isIndexes }) => {
	const dispatch = useDispatch();
	const { fundsOperationsHistory } = useSelector(({ funds }) => funds);
	const { indexesOperationsHistory } = useSelector(({ indexes }) => indexes);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);
	const { content, totalElements } = useMemo(
		() => (isIndexes ? indexesOperationsHistory : fundsOperationsHistory),
		[isIndexes, indexesOperationsHistory, fundsOperationsHistory]
	);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		isIndexes
			? dispatch(searchIndexOperationsHistory(filteringParams))
			: dispatch(searchFundOperationsHistory(filteringParams));
	}, [activeFilters, dispatch, isIndexes]);

	useEffect(() => {
		return () => {
			isIndexes
				? dispatch(clearIndexesOperationsHistory())
				: dispatch(clearIndexesOperationsHistory());
			dispatch(clearActiveFilters());
		};
	}, [dispatch, isIndexes]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default OperationsHistory;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.userId + "" || ""} label={"User Id"}>
					<td data='hover'>{rowData?.userId}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.token + "" || ""} label={"Token"}>
					<td data='hover'>{rowData?.token}</td>
				</CopyWithAlert>
				<td>{+rowData?.tokenAmount}</td>
				<CopyWithAlert text={rowData?.asset + "" || ""} label={"Asset"}>
					<td data='hover'>{rowData?.asset}</td>
				</CopyWithAlert>
				<td>{+rowData?.tokenPrice}</td>
				<td>{+rowData?.assetAmount}</td>
				<td>{!rowData?.assetCommission ? "-" : +rowData?.assetCommission}</td>
				<CopyWithAlert text={rowData?.type + "" || ""} label={"Type"}>
					<td
						style={{
							color: rowData?.type && getStatusColor(rowData?.type),
						}}>
						{rowData?.type || "-"}
					</td>
				</CopyWithAlert>
				<td>
					{rowData?.time && moment(rowData?.time).format("DD MMM YYYY HH:mm")}
				</td>
			</tr>
		</>
	);
}
