import DialogWrapper from "../../../theme/components/dialogs/DialogWrapper";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import {
	deleteSettingDeposit,
	deleteSettingWithdraw,
} from "../../../app/actions/settingsActions";

const DeleteTransactionsSettingsDialog = ({
	open,
	handleClose,
	ticker,
	id,
	type,
	params,
}) => {
	const dispatch = useDispatch();

	const handleDelete = () => {
		type === "withdrawal"
			? dispatch(deleteSettingWithdraw(id, params))
			: dispatch(deleteSettingDeposit(id, params));
		handleClose();
	};

	return (
		<DialogWrapper open={open} handleClose={handleClose}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				{`Delete ${type} settings for ${ticker?.toUpperCase()}?`}
			</Typography>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<IconButton onClick={handleDelete}>
					<DeleteIcon style={{ color: "red" }} />
				</IconButton>
			</div>
		</DialogWrapper>
	);
};

export default DeleteTransactionsSettingsDialog;
