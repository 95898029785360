import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Grid,
	lighten,
	makeStyles,
	Typography,
	Paper,
} from "@material-ui/core";
import NotInterestedRoundedIcon from "@material-ui/icons/NotInterestedRounded";
import clsx from "clsx";
import CoinDetailsDialog from "../../theme/components/dialogs/CoinDetailsDialog";
import { getInternalBalances } from "../../app/actions/internalBalancesActions";
import {
	getInfoNodeBlocksTron,
	getWalletsTotal,
	getInfoNodeBlocksEthereum,
} from "../../app/actions/coinsActions";

const blockControlNodes = ["trx", "eth"];

const Coins = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const data = useSelector(
		({ internalBalances }) => internalBalances.filteredData
	);
	const walletsTotal = useSelector(({ coins }) => coins.walletsTotal);
	const [details, setDetails] = useState(null);

	useEffect(() => {
		dispatch(getInternalBalances());
		dispatch(getWalletsTotal());
	}, [dispatch]);

	const openDialogCoinDetails = item => {
		if (walletsTotal?.length < 0) {
			return;
		}
		if (blockControlNodes.includes(item?.ticker)) {
			if (item?.ticker === "eth") {
				dispatch(getInfoNodeBlocksEthereum());
			} else {
				dispatch(getInfoNodeBlocksTron());
			}
		}

		const totalBalanceUsers = Number(
			walletsTotal.find(wallet => wallet.ticker === item.ticker).total
		);

		setDetails({
			...item,
			totalBalanceUsers,
			balanceCompany:
				+(Number(item?.balance) - Number(totalBalanceUsers)).toFixed(8) || null,
		});
	};

	return (
		<div>
			<CoinDetailsDialog
				open={Boolean(details)}
				handleClose={() => {
					setDetails(null);
				}}
				data={details}
			/>
			<div className={classes.head}>
				<Typography variant={"h4"} color={"textPrimary"}>
					Coins
				</Typography>
			</div>
			<div className={classes.root}>
				<Grid container spacing={3}>
					{data.length > 0 ? (
						data.map((item, index) => (
							<Grid
								key={item.ticker | index}
								item
								xs={3}
								onClick={() => openDialogCoinDetails(item)}>
								<Paper
									variant={"elevation"}
									className={clsx(classes.paper, {
										[classes.error]: !item.status,
									})}>
									<div className={classes.currency}>
										{item?.ticker && (
											<img
												src={`https://cryptoneed.com/icons/${item?.ticker?.toLowerCase()}.svg`}
												alt={item?.ticker}
												width={32}
												height={32}
												loading={"lazy"}
											/>
										)}

										<div>
											<p>
												{item?.name} ({item?.ticker?.toUpperCase()})
											</p>
										</div>
									</div>
								</Paper>
							</Grid>
						))
					) : (
						<div>
							<NotInterestedRoundedIcon />
							<br />
							The list is empty...
						</div>
					)}
				</Grid>
			</div>
		</div>
	);
};

export default Coins;

const useStyles = makeStyles(theme => ({
	head: {
		display: "flex",
		alignItems: "start",
	},
	root: {
		marginTop: 24,
		width: "100%",
		overflowX: "auto",
		paddingBottom: 24,
	},
	paper: {
		padding: 24,
		border: "3px solid #60CF61",
		backgroundColor: theme.palette.background.paper,
		cursor: "pointer",
		"&:hover": {
			backgroundColor: lighten(theme.palette.background.paper, 0.1),
		},
	},
	currency: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		"&>img": {
			marginRight: 16,
		},
	},
	error: {
		border: "3px solid #E44A4A",
		"&:hover": {
			backgroundColor: lighten(theme.palette.background.paper, 0.1),
		},
	},
}));
