import {
	FILTER_WALLETS_LIST,
	GET_CURRENCIES_SUCCESS,
	GET_WALLETS_LIST_FAILURE,
	GET_WALLETS_LIST_STARTED,
	GET_WALLETS_LIST_SUCCESS,
	SET_WALLETS_PARAMS,
	SORT_WALLETS_LIST,
	CLEAR_WALLETS,
} from "../types";

export default function wallets(
	state = {
		params: { number: 0, size: 30 },
		data: [],
		filteredData: [],
		currencies: [],
		error: null,
	},
	action
) {
	switch (action.type) {
		case SET_WALLETS_PARAMS:
			return { ...state, params: action.payload.params };
		case GET_WALLETS_LIST_STARTED:
			return { ...state, error: null };
		case GET_WALLETS_LIST_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				filteredData: action.payload.data,
			};
		case SORT_WALLETS_LIST:
			return {
				...state,
				filteredData: [...state.data].sort((a, b) =>
					action.payload.direction === "up"
						? a[action.payload.key] > b[action.payload.key]
							? 1
							: -1
						: a[action.payload.key] < b[action.payload.key]
						? 1
						: -1
				),
			};
		case FILTER_WALLETS_LIST:
			return {
				...state,
				filteredData: [...state.data].filter(i =>
					JSON.stringify(i).includes(action.payload)
				),
			};
		case GET_CURRENCIES_SUCCESS:
			return { ...state, currencies: action.payload.data };
		case GET_WALLETS_LIST_FAILURE:
			return { ...state, error: action.payload.error };
		case CLEAR_WALLETS:
			return { data: [], error: null, params: { page: 0, size: 30 }, total: 0 };
		default:
			return state;
	}
}
