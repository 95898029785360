import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../theme/components/PageTitle";
import { getCurrentShares } from "../../../app/actions/dhsActions";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../../theme/components/Table/Table";
import CopyWithAlert from "../../../utils/CopyWithAlert";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "deviceName", label: "Device Name" },
	{ key: "shares", label: "Shares" },
	{ key: "time", label: "Time" },
];

const Shares = () => {
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(
		({ dhs }) => dhs?.currentShares
	);
	const [params, setParams] = useState({ page: 0, size: 10 });

	useEffect(() => {
		dispatch(getCurrentShares(params));
	}, [params, dispatch]);

	const handleChangePage = (_, newPage) => {
		setParams({ ...params, page: newPage });
	};

	const handleChangeSize = ({ target }) => {
		setParams({ ...params, size: target.value });
	};

	return (
		<>
			<PageTitle title={"Shares"} />
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={params?.size || 0}
				page={params?.page || 0}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};
export default Shares;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.deviceName + "" || ""}
					label={"Device Name"}>
					<td data='hover'>{rowData?.deviceName}</td>
				</CopyWithAlert>
				<td>{+rowData?.shares}</td>
				<td>
					{rowData?.time && moment(rowData?.time).format("DD MMM YYYY HH:mm")}
				</td>
			</tr>
		</>
	);
}
