import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Grid } from "@material-ui/core";
import clsx from "clsx";
import EditIcon from "@material-ui/icons/Edit";
import { Button } from "../../../../theme/components/Button";
import EditBalancesDialog from "./EditBalancesDialog";
import { useSettingsStyles } from "../../../Settings/SettingsStyles";
import TransferDialog from "../../../../theme/components/dialogs/TransferDialog.jsx";
import { BALANCES_COLUMNS } from "../../../../utils/constants/banking";
import getStatusColor from "../../../../utils/functions/getStatusColor";
import {
	getAllBankingBalances,
	getAllBankingSettings,
	clearBankingStatus,
	transferFromBankingBalanceToWallet,
} from "../../../../app/actions/bankingActions.js";
import Table from "../../../../theme/components/Table/Table.jsx";

const Monitoring = () => {
	const classes = useSettingsStyles();
	const dispatch = useDispatch();
	const { balances, settings, status } = useSelector(({ banking }) => banking);
	const [openTransfer, setOpenTransfer] = useState(null);
	const [editBalance, setEditBalance] = useState(null);

	const closeDialogTransfer = useCallback(() => {
		if (status) {
			dispatch(clearBankingStatus());
		}
		setOpenTransfer(null);
	}, [dispatch, status]);

	useEffect(() => {
		dispatch(getAllBankingBalances());
		dispatch(getAllBankingSettings());
	}, [dispatch]);

	const handleTransfer = (data) => {
		dispatch(transferFromBankingBalanceToWallet(data));
	};

	return (
		<>
			<TransferDialog
				open={Boolean(openTransfer)}
				handleClose={closeDialogTransfer}
				data={openTransfer}
				handleTransfer={handleTransfer}
				title={"Transfer from banking balance to wallet"}
			/>
			<Grid container justifyContent='flex-end' style={{ marginTop: -44 }}>
				<Grid item>
					<Button
						variant='outlined'
						color='primary'
						style={{ marginLeft: 16, padding: "4px 28px" }}
						onClick={() =>
							setOpenTransfer({
								balance: balances[0]?.assetCurrentBalance,
								asset: balances[0]?.asset,
							})
						}>
						Super withdraw
					</Button>
				</Grid>
			</Grid>
			<div style={{ maxWidth: "100%" }}>
				<EditBalancesDialog
					open={Boolean(editBalance)}
					handleClose={() => setEditBalance(null)}
					data={editBalance}
				/>
				<Table>
					<thead>
						<tr>
							{BALANCES_COLUMNS.map(({ field, headerName }) => (
								<th
									key={field}
									className={clsx(classes.th, classes.paper)}
									align={"left"}>
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{balances?.length > 0 ? (
							balances?.map((row, index) => (
								<TableRow
									key={row?.id || index}
									rowData={row}
									settings={settings}
									handleEdit={() => setEditBalance(row)}
								/>
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			</div>
		</>
	);
};

export default Monitoring;

function TableRow({ rowData, settings, handleEdit }) {
	const classes = useSettingsStyles();
	return (
		<>
			<tr className={classes.hover}>
				<td className={clsx(classes.td, classes.paper)}>{rowData?.id}</td>
				<td className={clsx(classes.td, classes.paper)}>{rowData?.asset}</td>
				<td className={clsx(classes.td, classes.paper)}>{rowData?.quotable}</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.assetCurrentBalance}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.assetTotalBalance}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.quotableFrozenBalance}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.quotableCurrentBalance}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.quotableTotalBalance}
				</td>
				<td
					style={{
						color: getStatusColor("COMPLETED"),
					}}
					className={clsx(classes.td, classes.paper)}>
					{settings?.USDTUAH}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
				</td>
			</tr>
		</>
	);
}
