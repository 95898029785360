import { combineReducers, configureStore } from "@reduxjs/toolkit";
import apiMiddleware from "./apiMiddleware";
import loadingMiddleware from "./loadingMiddleware";
import notistack from "./reducers/notistackReducer";
import loading from "./reducers/loadingReducer";
import account from "./reducers/accountReducer";
import users from "./reducers/usersReducer";
import admins from "./reducers/adminsReducer";
import transactions from "./reducers/transactionsReducer";
import exchange from "./reducers/exchangeReducer";
import settings from "./reducers/settingsReducer";
import blocking from "./reducers/blockingReducer";
import internalBalances from "./reducers/internalBalancesReducer";
import coins from "./reducers/coinsReducer";
import addresses from "./reducers/addressesReducer";
import actionLogs from "./reducers/actionLogReducer";
import finances from "./reducers/financesReducer";
import statistic from "./reducers/statistcReducer";
import filter from "./reducers/filterReducer";
import wallets from "./reducers/walletsReducer";
import market from "./reducers/marketReducer";
import sessionLogs from "./reducers/sessionLogReducer";
import synchronization from "./reducers/syncReducer";
import dhs from "./reducers/dhsReducer";
import kyc from "./reducers/kycReducer";
import banking from "./reducers/bankingReducer";
import funds from "./reducers/fundsCapitalReducer";
import indexes from "./reducers/indexesCapitalReducer";
import referrals from "./reducers/referralsReducer";
import companies from "./reducers/companiesReducer";
import bankCards from "./reducers/bankCardsReducer";

const combinedReducer = combineReducers({
	notistack,
	loading,
	account,
	users,
	admins,
	transactions,
	exchange,
	settings,
	blocking,
	addresses,
	internalBalances,
	coins,
	actionLogs,
	sessionLogs,
	finances,
	statistic,
	filter,
	wallets,
	market,
	synchronization,
	dhs,
	kyc,
	banking,
	funds,
	indexes,
	referrals,
	companies,
	bankCards,
});

const rootReducer = (state, action) => {
	if (action.type === "LOG_OUT") {
		state = undefined;
	}
	return combinedReducer(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(apiMiddleware, loadingMiddleware),
});
