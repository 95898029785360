import DialogWrapper from "../../theme/components/dialogs/DialogWrapper";
import { Grid, Typography, MenuItem } from "@material-ui/core";
import { Button } from "../../theme/components/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateInput } from "../../utils/customValidators/validators";
import { SelectInput } from "../../theme/components/Select";
import {
	enablePrivateFundForUser,
	getAvailableVipFunds,
} from "../../app/actions/fundsCapitalActions";

const initialForm = {
	availablePrivateFunds: "",
};

const AddFundDialog = ({ open, handleClose, userId }) => {
	const dispatch = useDispatch();
	const [form, setForm] = useState(initialForm);
	const [errors, setErrors] = useState({});
	const { availableVipFunds } = useSelector(({ funds }) => funds);

	useEffect(() => {
		dispatch(getAvailableVipFunds());
	}, [dispatch]);

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach((key) => {
			errForm = { ...errForm, [key]: validateInput(key, form[key]) };
			errForm[key].invalid && (valid = false);
		});

		setErrors(errForm);
		return valid;
	};

	const handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
		handleInputValidation(event);
	};

	const handleSave = () => {
		if (!validateForm()) {
			return;
		}
		if (userId && form.availablePrivateFunds) {
			dispatch(
				enablePrivateFundForUser({ userId, fundId: form.availablePrivateFunds })
			);
		}
		handleCloseDialog();
	};

	const handleInputValidation = (event) => {
		const { name, value } = event.target;
		const errorField = validateInput(name, value);
		setErrors({
			...errors,
			[name]: errorField,
		});
	};

	const handleCloseDialog = () => {
		setErrors({});
		setForm(initialForm);
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={handleCloseDialog}
			maxWidth={720}
			minWidth={375}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				Add Fund
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<SelectInput
						error={errors?.availablePrivateFunds?.invalid}
						label='Available Private Funds'
						onChange={handleChange}
						value={form?.availablePrivateFunds || ""}
						name={"availablePrivateFunds"}
						onBlur={handleInputValidation}
						helperText={errors?.availablePrivateFunds?.errorMessage}>
						{availableVipFunds.map((fund, index) => (
							<MenuItem key={index} value={fund?.fundId}>
								{`ID: ${fund?.fundId} - TOKEN: ${fund?.token}`}
							</MenuItem>
						))}
					</SelectInput>
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)", marginTop: 12 }}
							onClick={handleSave}>
							Add
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default AddFundDialog;
