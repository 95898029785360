import { lighten, makeStyles } from "@material-ui/core";

const Table = ({ children }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<table className={classes.table}>{children}</table>
		</div>
	);
};

export default Table;

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		overflowX: "auto",
		paddingBottom: 24,
	},
	"root::-webkit-scrollbar": {
		backgroundColor: "#fff",
	},
	table: {
		borderCollapse: "separate",
		borderSpacing: "0 8px",
		width: "100%",
		"&>thead th": {
			textAlign: "left",
			color: theme.palette.text.secondary,
			padding: "14px",
			fontSize: 13,
			lineHeight: 2,
			backgroundColor: theme.palette.background.paper,
			"&.hover:hover": {
				backgroundColor: lighten(theme.palette.background.paper, 0.04),
				cursor: "pointer",
			},
			"&>svg": {
				fontSize: 19,
				verticalAlign: "middle",
				marginRight: 4,
			},
			"&:first-child": {
				borderRadius: "10px 0 0 10px",
			},
			"&:last-child": {
				borderRadius: "0 10px 10px 0",
			},
		},
		"&>tbody": {
			position: "relative",
			"&>tr": {
				"&>td": {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.background.paper,
					whiteSpace: "nowrap",
					padding: "14px",
					fontSize: 13,
					maxWidth: 220,
					overflow: "hidden",
					textOverflow: "ellipsis",
					"& svg": {
						fontSize: 19,
					},
					"&:first-child": {
						borderRadius: "10px 0 0 10px",
					},
					"&:last-child": {
						borderRadius: "0 10px 10px 0",
						paddingTop: 0,
						paddingBottom: 0,
					},
					"&[colspan]": {
						backgroundColor: "transparent",
					},
					"&[data='hover']:hover": {
						cursor: "pointer",
						color: theme.palette.primary.light,
					},
				},
			},
		},
	},
}));
