import { IconButton, Typography, TablePagination } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
	clearStatusSettings,
	getSettingsExchange,
} from "../../../app/actions/settingsActions";
import { getCurrencies } from "../../../app/actions/walletsActions";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Search from "../../../theme/components/Search";
import { Button } from "../../../theme/components/Button";
import AddIcon from "@material-ui/icons/Add";
import DeleteDialog from "./DeleteDialog";
import EditExchangeDialog from "./EditExchangeDialog";
import { useSettingsStyles } from "../SettingsStyles";
import CopyWithAlert from "../../../utils/CopyWithAlert";

const columns = [
	{ field: "id", headerName: "ID" },
	{ field: "pair", headerName: "Pair" },
	{ field: "min", headerName: "Min amount" },
	{ field: "max", headerName: "Max amount" },
	{ field: "system_fee", headerName: "System fee" },
	{ field: "allowed", headerName: "Allowed" },
	{ field: "update_date", headerName: "Updated" },
	{ field: "edit", headerName: "Manage" },
];

const ExchangeSettings = () => {
	const classes = useSettingsStyles();
	const dispatch = useDispatch();
	const { data, totalItems } = useSelector(({ settings }) => settings.exchange);
	const [params, setParams] = useState({
		page: 0,
		size: 10,
		symbol: "",
		sort: "updateDate,desc",
	});
	const [search, setSearch] = useState("");
	const [deleteExchange, setDeleteExchange] = useState(null);
	const [editExchange, setEditExchange] = useState(null);
	const [create, setCreate] = useState(false);
	const timeout = useRef(null);
	const currencies = useSelector(({ wallets }) => wallets.currencies);

	useEffect(() => {
		dispatch(getSettingsExchange(params));
		dispatch(getCurrencies({ page: 0, size: 100 }));
	}, [dispatch, params]);

	const handleChangePage = (event, newPage) => {
		event.stopPropagation();
		setParams({ ...params, page: newPage });
	};

	const handleChangeSize = ({ target }) => {
		setParams({ ...params, size: target.value, page: 0 });
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<DeleteDialog
				open={Boolean(deleteExchange)}
				handleClose={() => setDeleteExchange(null)}
				params={params}
				{...deleteExchange}
			/>
			<EditExchangeDialog
				open={Boolean(editExchange) || create}
				handleClose={() => {
					setEditExchange(null);
					setCreate(false);
					dispatch(clearStatusSettings());
				}}
				data={editExchange}
				params={params}
				currencies={currencies}
			/>
			<div className={classes.head}>
				<Typography variant={"h4"} color={"textPrimary"}>
					Exchange settings
				</Typography>
				<div style={{ display: "flex" }}>
					<Search
						value={search}
						onChange={({ target }) => {
							setSearch(target.value);
							clearTimeout(timeout.current);
							timeout.current = setTimeout(() => {
								setParams({
									...params,
									page: 0,
									size: params.size,
									symbol: target.value?.toLowerCase(),
								});
							}, 500);
						}}
					/>
					<Button
						variant='outlined'
						color='primary'
						style={{ marginLeft: 16, padding: "4px 28px" }}
						onClick={() => setCreate(true)}>
						<AddIcon />
						Create
					</Button>
				</div>
			</div>
			<div className={classes.root}>
				<table className={classes.table}>
					<thead>
						<tr>
							{columns.map(({ field, headerName }) => (
								<th
									key={field}
									className={clsx(classes.th, classes.paper)}
									align={"left"}>
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{data.length > 0 ? (
							data.map((row, index) => (
								<TableRow
									key={row?.id || index}
									rowData={row}
									handleDelete={() => setDeleteExchange(row)}
									handleEdit={() => setEditExchange(row)}
								/>
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</table>
				<TablePagination
					rowsPerPageOptions={[10, 30, 50]}
					component='div'
					count={totalItems}
					rowsPerPage={params?.size}
					page={params?.page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeSize}
				/>
			</div>
		</div>
	);
};

export default ExchangeSettings;

function TableRow({ rowData, handleDelete, handleEdit }) {
	const classes = useSettingsStyles();

	return (
		<>
			<tr className={classes.spacing}></tr>
			<tr className={classes.hover}>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"id"}>
					<td className={clsx(classes.td, classes.paper, classes.hoverTd)}>
						{rowData?.id}
					</td>
				</CopyWithAlert>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.assetFrom?.toUpperCase()} /{" "}
					{rowData?.assetTo?.toUpperCase()}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.minAmount}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.maxAmount}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.systemFee}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.allowed ? (
						<CheckIcon style={{ color: "#00ff00" }} />
					) : (
						<CloseIcon style={{ color: "#ff0000" }} />
					)}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.updateDate &&
						moment(rowData?.updateDate).format("DD.MM.yyyy HH:mm")}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					<IconButton onClick={handleEdit}>
						<EditIcon />
					</IconButton>
					<IconButton onClick={handleDelete}>
						<DeleteIcon style={{ color: "#ff0000" }} />
					</IconButton>
				</td>
			</tr>
		</>
	);
}
