import {
	GET_BALANCES_SUCCESS,
	SORT_BINANCE_BALANCES,
	FILTER_BINANCE_BALANCES,
	GET_KUNA_DEPOSITS_STARTED,
	GET_KUNA_DEPOSITS_SUCCESS,
	GET_KUNA_DEPOSITS_FAILURE,
	GET_KUNA_WITHDRAWAL_STARTED,
	GET_KUNA_WITHDRAWAL_SUCCESS,
	GET_KUNA_WITHDRAWAL_FAILURE,
	SET_KUNA_PARAMS,
	CLEAR_KUNA_DEPOSITS,
	CLEAR_KUNA_WITHDRAWAL,
	GET_KUNA_BALANCE,
	SORT_KUNA_BALANCE,
	FILTER_KUNA_BALANCE,
	GET_USERS_BALANCES_BY_COIN_FAILURE,
	GET_USERS_BALANCES_BY_COIN_STARTED,
	GET_USERS_BALANCES_BY_COIN_SUCCESS,
} from "../types";
import { sorting } from "../../utils/functions/filteringTable";

export default function finances(
	state = {
		balances: {
			data: [],
			filteredData: [],
			totalInUsdt: 0,
		},
		kunaBalances: {
			data: [],
			filteredData: [],
			totalInUsdt: 0,
		},
		usersBalancesByCoin: [],
		kuna: {
			deposits: [],
			withdrawals: [],
			params: { page: 0, size: 10 },
			total: 0,
		},
		error: null,
	},
	action
) {
	switch (action.type) {
		case GET_KUNA_BALANCE.REQUEST:
		case GET_KUNA_DEPOSITS_STARTED:
		case GET_KUNA_WITHDRAWAL_STARTED:
		case GET_USERS_BALANCES_BY_COIN_STARTED:
			return { ...state, error: null };
		case GET_KUNA_BALANCE.FAILURE:
		case GET_KUNA_DEPOSITS_FAILURE:
		case GET_KUNA_WITHDRAWAL_FAILURE:
		case GET_USERS_BALANCES_BY_COIN_FAILURE:
			return { ...state, error: action.payload.error };

		case GET_USERS_BALANCES_BY_COIN_SUCCESS:
			return { ...state, usersBalancesByCoin: action.payload };

		case GET_BALANCES_SUCCESS:
			return {
				...state,
				balances: {
					...state?.balances,
					data: action.payload?.balances,
					filteredData: sorting(
						action.payload?.balances,
						"desc",
						"balanceInUsdt",
						"number"
					),
					totalInUsdt: action.payload?.totalInUsdt,
				},
			};

		case GET_KUNA_BALANCE.SUCCESS:
			return {
				...state,
				kunaBalances: {
					...state.kunaBalances,
					data: action.payload?.balances,
					filteredData: sorting(
						action.payload?.balances,
						"desc",
						"balanceInUsdt",
						"number"
					),
					totalInUsdt: action.payload?.totalInUsdt,
				},
			};

		case GET_KUNA_DEPOSITS_SUCCESS:
			return {
				...state,
				kuna: {
					...state.kuna,
					deposits: action.payload.data,
					total: action.payload.totalItems,
				},
			};

		case GET_KUNA_WITHDRAWAL_SUCCESS:
			return {
				...state,
				kuna: {
					...state.kuna,
					withdrawals: action.payload.data,
					total: action.payload.totalItems,
				},
			};

		case SET_KUNA_PARAMS:
			return { ...state, kuna: { ...state.kuna, params: action.payload } };

		case CLEAR_KUNA_DEPOSITS:
			return {
				...state,
				deposits: [],
				params: { page: 0, size: 10 },
				total: 0,
			};
		case CLEAR_KUNA_WITHDRAWAL:
			return {
				...state,
				withdrawals: [],
				params: { page: 0, size: 10 },
				total: 0,
			};
		case SORT_BINANCE_BALANCES:
			return {
				...state,
				balances: {
					...state.balances,
					filteredData: sorting(
						[...state.balances.filteredData],
						action.payload.direction,
						action.payload.key,
						action.payload.type
					),
				},
			};
		case FILTER_BINANCE_BALANCES:
			return {
				...state,
				balances: {
					...state.balances,
					filteredData:
						state.balances?.data.filter(i =>
							i.currency?.includes(action.payload.toUpperCase())
						) || [],
				},
			};

		case SORT_KUNA_BALANCE:
			return {
				...state,
				kunaBalances: {
					...state.kunaBalances,
					filteredData: sorting(
						[...state.kunaBalances.filteredData],
						action.payload.direction,
						action.payload.key,
						action.payload.type
					),
				},
			};

		case FILTER_KUNA_BALANCE:
			return {
				...state,
				kunaBalances: {
					...state.kunaBalances,
					filteredData: state.kunaBalances?.data?.filter(i => {
						return i.currency?.includes(action.payload.toUpperCase());
					}),
				},
			};

		default:
			return state;
	}
}
