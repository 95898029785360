import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TablePagination, makeStyles, lighten } from "@material-ui/core";
import moment from "moment";
import Table from "../../../../theme/components/Table/Table";
import DeleteConfirmDialog from "../../../../theme/components/dialogs/DeleteConfirmDialog";
import { Button } from "../../../../theme/components/Button";
import CopyWithAlert from "../../../../utils/CopyWithAlert";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";
import getStatusColor from "../../../../utils/functions/getStatusColor";
import {
	STATUS_INVOICE,
	DECISION_BY_INVOICE,
} from "../../../../utils/constants/funds";
import {
	makeDecisionByInvoiceIndex,
	searchUsersIndexInvoices,
} from "../../../../app/actions/indexesCapitalActions";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "token", label: "Token" },
	{ key: "userId", label: "User id" },
	{ key: "tokenAmount", label: "Token amount" },
	{ key: "estimatedAmount", label: "Estimated amount" },
	{ key: "estimatedPrice", label: "Estimated price" },
	{ key: "estimatedCommission", label: "Estimated commission" },
	{ key: "targetCurrency", label: "Target currency" },
	{ key: "creationTime", label: "Created" },
	{ key: "status", label: "Status" },
	{ key: "decision", label: "Decision", align: "center" },
];

const Invoices = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [showConfirmDialog, setShowConfirmDialog] = useState(null);
	const [params, setParams] = useState({ page: 0, size: 10 });
	const timeOut = useRef();

	const { content, totalElements } = useSelector(
		({ indexes }) => indexes.invoices
	);

	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach((item) => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		setParams(filteringParams);
		dispatch(searchUsersIndexInvoices(filteringParams));
	}, [activeFilters, dispatch]);

	const getInvoiceData = useCallback(() => {
		dispatch(searchUsersIndexInvoices(params));
		timeOut.current = setTimeout(() => {
			getInvoiceData();
		}, 20000);
		// eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		getInvoiceData();
		return () => {
			clearTimeout(timeOut.current);
		};
	}, [getInvoiceData]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	const handleMakeDecision = ({ accept }) => {
		if (accept) {
			dispatch(makeDecisionByInvoiceIndex({ data: showConfirmDialog, params }));
		}
		setShowConfirmDialog(null);
	};

	const goToUserProfile = (userId) => {
		history.push(`/users/list/${null}/${userId}`);
	};

	return (
		<>
			<DeleteConfirmDialog
				open={Boolean(showConfirmDialog)}
				title='Confirm decision'
				message={`Are you sure you decide on an invoice with ID -  ${showConfirmDialog?.invoiceId}?`}
				handleClose={handleMakeDecision}
			/>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label, align }) => (
							<th key={key} style={{ textAlign: `${align || "left"}` }}>
								{label}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
								onMakeDecision={(data) => setShowConfirmDialog(data)}
								goToUserProfile={goToUserProfile}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find((x) => x.field === "size").value}
				page={activeFilters?.find((x) => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default Invoices;

function TableRow({ rowData, goToUserProfile, onMakeDecision }) {
	const classes = useStyles();
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<td data='hover' onClick={() => goToUserProfile(rowData?.userId)}>
					{rowData?.userId}
				</td>
				<CopyWithAlert text={rowData?.token + "" || ""} label={"Token"}>
					<td data='hover'>{rowData?.token}</td>
				</CopyWithAlert>
				<td>{+rowData?.tokenAmount}</td>
				<td>{+rowData?.estimatedAmount}</td>
				<td>{+rowData?.estimatedPrice}</td>
				<td>{+rowData?.estimatedCommission}</td>
				<CopyWithAlert
					text={rowData?.targetCurrency + "" || ""}
					label={"Target Currency"}>
					<td data='hover'>{rowData?.targetCurrency}</td>
				</CopyWithAlert>
				<td>
					{rowData?.creationTime &&
						moment(rowData?.creationTime).format("DD MMM YYYY HH:mm")}
				</td>
				<CopyWithAlert text={rowData?.status + "" || ""} label={"Status"}>
					<td
						style={{
							color: rowData?.status && getStatusColor(rowData?.status),
						}}>
						{rowData?.status || "-"}
					</td>
				</CopyWithAlert>
				<td>
					<div className={classes.btnWrap}>
						<Button
							variant='contained'
							color='primary'
							className={classes.declineBtn}
							disabled={rowData?.status !== STATUS_INVOICE.WAITING}
							onClick={() =>
								onMakeDecision({
									invoiceId: rowData.id,
									decision: DECISION_BY_INVOICE.DECLINE,
								})
							}>
							Decline
						</Button>
						<Button
							className={classes.approveBtn}
							variant='contained'
							disabled={rowData?.status !== STATUS_INVOICE.WAITING}
							onClick={() =>
								onMakeDecision({
									invoiceId: rowData.id,
									decision: DECISION_BY_INVOICE.ACCEPT,
								})
							}>
							Approve
						</Button>
					</div>
				</td>
			</tr>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	btnWrap: {
		display: "flex",
		justifyContent: "space-around",
	},
	declineBtn: {
		background: theme.palette.error.main,
		minWidth: 100,
		minHeight: 30,
		padding: "4px 12px",
		"&:hover": {
			background: lighten(theme.palette.error.main, 0.4),
		},
	},
	approveBtn: {
		background: theme.palette.success.main,
		minWidth: 100,
		minHeight: 30,
		padding: "4px 12px",
		"&:hover": {
			background: lighten(theme.palette.success.main, 0.4),
		},
	},
	tab: {
		paddingTop: theme.spacing(3),
	},
}));
