import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lighten, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { getBalancesKuna } from "../../app/actions/financesActions";
import PageTitle from "../../theme/components/PageTitle";
import AnyIcon from "@material-ui/icons/UnfoldMoreRounded";
import DownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import TopIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import { SORT_TYPES, next } from "../../utils/constants/sortTypes.js";
import { SORT_KUNA_BALANCE, FILTER_KUNA_BALANCE } from "../../app/types";

const KunaBalances = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [columns, setColumns] = useState([
		{
			key: "currency",
			headerName: "Currency",
			type: "string",
		},
		{
			key: "availableBalance",
			headerName: "Available Balance",
			sort: SORT_TYPES.none,
			type: "number",
		},
		{
			key: "totalBalance",
			headerName: "Total Balance",
			sort: SORT_TYPES.none,
			type: "number",
		},
		{
			key: "balanceInUsdt",
			headerName: "Equivalent USD",
			sort: SORT_TYPES.none,
			type: "number",
		},
	]);

	const { filteredData, totalInUsdt } = useSelector(
		({ finances }) => finances?.kunaBalances
	);

	useEffect(() => {
		dispatch(getBalancesKuna());
	}, [dispatch]);

	const handleSearch = (search) => {
		dispatch({
			type: FILTER_KUNA_BALANCE,
			payload: search,
		});
	};

	const handleSorting = (key) => {
		const nextState = next(columns.find((s) => s.key === key)?.sort);
		dispatch({
			type: SORT_KUNA_BALANCE,
			payload: {
				direction: nextState,
				key,
				type: "number",
			},
		});

		setColumns(
			columns.map((s) =>
				s.key === key
					? { ...s, sort: nextState }
					: {
							...s,
							sort: s.sort ? SORT_TYPES.none : undefined,
					  }
			)
		);
	};

	return (
		<div>
			<PageTitle title={"Kuna balances"} onSearch={handleSearch} />
			<div className={classes.root}>
				<Typography
					variant={"body1"}
					style={{ fontWeight: 700, marginBottom: 12 }}>
					{`Total balance: ${(+totalInUsdt)?.toFixed(2) || 0} USD`}
				</Typography>
				<table className={classes.table}>
					<thead>
						<tr>
							{columns.map(({ key, headerName, sort, colSpan, align }) => (
								<th
									key={key}
									className={clsx(classes.th, classes.paper, sort && "hover")}
									colSpan={colSpan}
									align={align || "left"}
									onClick={() => sort && handleSorting(key)}>
									{sort === SORT_TYPES.up && <TopIcon />}
									{sort === SORT_TYPES.none && <AnyIcon />}
									{sort === SORT_TYPES.down && <DownIcon />}
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{filteredData?.length > 0 ? (
							filteredData?.map((row, index) => (
								<TableRow key={row?.id || index} rowData={row} />
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default KunaBalances;

function TableRow({ rowData }) {
	const classes = useStyles();
	return (
		<>
			<tr className={classes.spacing}></tr>
			<tr className={classes.hover}>
				<td className={clsx(classes.td, classes.paper)}>
					{rowData?.currency?.toUpperCase()}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.availableBalance || 0}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.totalBalance || 0}
				</td>
				<td className={clsx(classes.td, classes.paper)}>
					{+rowData?.balanceInUsdt || 0}
				</td>
			</tr>
		</>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: 24,
		width: "100%",
		overflowX: "auto",
		paddingBottom: 24,
	},
	table: {
		borderSpacing: 0,
		width: "100%",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		"&:first-child": {
			borderRadius: "10px 0 0 10px",
		},
		"&:last-child": {
			borderRadius: "0 10px 10px 0",
		},
	},
	th: {
		color: theme.palette.text.secondary,
		padding: "14px 8px ",
		fontSize: 14,
		lineHeight: 2,
		cursor: "pointer",
		"&.hover:hover": {
			backgroundColor: lighten(theme.palette.background.paper, 0.04),
		},
		"&>svg": {
			fontSize: 19,
			verticalAlign: "middle",
			marginRight: 4,
		},
	},
	td: {
		color: theme.palette.text.primary,
		padding: "8px 14px",
		fontSize: 13,
		"& svg": {
			fontSize: 16,
		},
	},
	spacing: {
		height: 12,
	},
	hover: {
		"&:hover>td": {
			backgroundColor: lighten(theme.palette.background.paper, 0.04),
		},
	},
}));
