export function sorting(data, direction, key, type = "string") {
	return data.sort((a, b) =>
		type === "string"
			? direction === "asc"
				? a[key] > b[key]
					? 1
					: -1
				: a[key] < b[key]
				? 1
				: -1
			: direction === "asc"
			? (+a[key] || 0) > (+b[key] || 0)
				? 1
				: -1
			: (+a[key] || 0) < (+b[key] || 0)
			? 1
			: -1
	);
}

export function filtering(data, search) {
	if (!search) {
		return data;
	}
	return data.filter(i =>
		JSON.stringify(i).toUpperCase().includes(search.toUpperCase())
	);
}
