import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lighten, makeStyles, TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../theme/components/Table/Table";
import CopyWithAlert from "../../utils/CopyWithAlert";
import { changeNewCompanyRequestStatus, getNewCompaniesRequestList } from "../../app/actions/companiesActions";
import getStatusColor from "../../utils/functions/getStatusColor";
import { Button } from "../../theme/components/Button";
import { COMPANY_REQUEST_STATUS } from "../../utils/constants/companies";

const columns = [
  { key: "accountId", label: "Account Id" },
  { key: "accountLogin", label: "Account Login" },
  { key: "companyName", label: "Company Name" },
  { key: "shortName", label: "Short Name" },
  { key: "selectedCoins", label: "Selected Coins" },
  { key: "description", label: "Description" },
  { key: "status", 'label': "Status" },
  { key: "time", label: "Time" },
  { key: "decision", label: "Decision", align: "center" },
];

const NewCompaniesRequests = () => {
  const dispatch = useDispatch();
  const newComapaniesReqeustsList = useSelector(
    ({ companies }) => companies.newComapaniesReqeustsList
  );
  const [params, setParams] = useState({ page: 0, size: 10 });


  useEffect(() => {
    dispatch(getNewCompaniesRequestList());
  }, [dispatch]);

  const handleChangePage = (_, newPage) => {
    setParams({ ...params, page: newPage });
  };

  const handleChangeSize = ({ target }) => {
    setParams({ ...params, size: target.value, page: 0 });
  };

  const handleChangeCompanyRequestStatus = useCallback(({ status, company }) => () => {
    dispatch(changeNewCompanyRequestStatus({ status, companyDocumentId: company.id, company: company }))
  }, [dispatch])

  return (
    <>
      <Table>
        <thead>
          <tr>
            {columns.map(({ key, label }) => (
              <th key={key}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {newComapaniesReqeustsList?.length > 0 ? (
            newComapaniesReqeustsList
              ?.slice(
                Number(params.page) * params.size,
                Number(params.page) * params.size + params.size
              )
              ?.map((rowData, index) => (
                <TableRow key={rowData?.id || index} rowData={rowData} onAccept={handleChangeCompanyRequestStatus({ status: COMPANY_REQUEST_STATUS.APPROVED, company: rowData })} onDecline={handleChangeCompanyRequestStatus({ status: COMPANY_REQUEST_STATUS.DECLINED, company: rowData })} />
              ))
          ) : (
            <tr>
              <td colSpan={8} align={"center"} height={100}>
                The list is empty...
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30, 50]}
        component='div'
        count={newComapaniesReqeustsList?.length || 0}
        rowsPerPage={params?.size}
        page={params?.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeSize}
      />
    </>
  );
};

export default NewCompaniesRequests;

function TableRow({ rowData, onDecline, onAccept }) {
  const classes = useStyles();

  return (
    <>
      <tr>
        <CopyWithAlert
          text={rowData?.accountId + "" || ""}
          label={"Account Id"}>
          <td data='hover'>{rowData?.accountId}</td>
        </CopyWithAlert>
        <CopyWithAlert
          text={rowData?.accountLogin + "" || ""}
          label={"Account Login"}>
          <td data='hover'>{rowData?.accountLogin}</td>
        </CopyWithAlert>
        <CopyWithAlert text={rowData?.companyName || ""} label={"Company Name"}>
          <td data='hover'>{rowData?.companyName}</td>
        </CopyWithAlert>
        <CopyWithAlert text={rowData?.shortName || ""} label={"Short Name"}>
          <td data='hover'>{rowData?.shortName}</td>
        </CopyWithAlert>
        <CopyWithAlert
          text={rowData?.selectedCoins?.join?.(",") || ""}
          label={"Selected Coins"}>
          <td data='hover'>{rowData?.selectedCoins?.join?.(",")}</td>
        </CopyWithAlert>
        <td>{rowData?.description}</td>
        <td style={{ color: getStatusColor(rowData?.status || 'PENDING') }}>{rowData?.status || 'PENDING'}</td>
        <td>
          {rowData?.time && moment(rowData?.time).format("DD MMM YYYY HH:mm")}
        </td>
        <td>
          <div className={classes.btnWrap}>
            <Button
              variant='contained'
              color='primary'
              className={classes.declineBtn}
              disabled={rowData?.status}
              onClick={() =>
                onDecline({
                  decision: COMPANY_REQUEST_STATUS.DECLINED,
                })
              }>
              Decline
            </Button>
            <Button
              className={classes.approveBtn}
              variant='contained'
              disabled={rowData?.status}
              onClick={() =>
                onAccept({
                  decision: COMPANY_REQUEST_STATUS.APPROVED,
                })
              }>
              Approve
            </Button>
          </div>
        </td>
      </tr>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  btnWrap: {
    display: "flex",
    justifyContent: "space-around",
  },
  declineBtn: {
    background: theme.palette.error.main,
    minWidth: 100,
    minHeight: 30,
    padding: "2px 10px",
    "&:hover": {
      background: lighten(theme.palette.error.main, 0.4),
    },
  },
  approveBtn: {
    background: theme.palette.success.main,
    minWidth: 100,
    minHeight: 30,
    padding: "2px 10px",
    "&:hover": {
      background: lighten(theme.palette.success.main, 0.4),
    },
  },
}));
