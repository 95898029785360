import { Buffer } from "buffer";
import {
	LOGIN_STARTED,
	LOGIN_SUCCESS,
	LOGIN_TWO_FA_SUCCESS,
	LOGIN_FAILURE,
	ENABLE_TWO_FA_STARTED,
	ENABLE_TWO_FA_SUCCESS,
	ENABLE_TWO_FA_FAILURE,
	DISABLE_TWO_FA_STARTED,
	DISABLE_TWO_FA_SUCCESS,
	DISABLE_TWO_FA_FAILURE,
	CHECK_IS_TWO_FA_STARTED,
	CHECK_IS_TWO_FA_SUCCESS,
	CHECK_IS_TWO_FA_FAILURE,
	CHECK_CODE_TWO_FA_STARTED,
	CHECK_CODE_TWO_FA_SUCCESS,
	CHECK_CODE_TWO_FA_FAILURE,
	CHECK_INSTALL_TWO_FA_STARTED,
	CHECK_INSTALL_TWO_FA_SUCCESS,
	CHECK_INSTALL_TWO_FA_FAILURE,
	CLEAR_TWO_FA_QR_CODE,
	LOG_OUT,
	GET_ACCOUNT_DATA_STARTED,
	GET_ACCOUNT_DATA_SUCCESS,
	GET_ACCOUNT_DATA_FAILURE,
	CHANGE_PASSWORD_FAILURE,
	CHANGE_PASSWORD_STARTED,
	CHANGE_PASSWORD_SUCCESS,
	GET_WHOAMI_FAILURE,
	GET_WHOAMI_STARTED,
	GET_WHOAMI_SUCCESS,
	SEND_REGISTRATION_MESSAGE_FAILURE,
	SEND_REGISTRATION_MESSAGE_STARTED,
	SEND_REGISTRATION_MESSAGE_SUCCESS,
} from "../types";
import api from "../api";
import { enqueueSnackbar } from "./notistackActions";
import { Message, MessageError } from "../../utils/enum";
import { AUTH_TOKEN } from "../../utils/constants/api";

export const loginStarted = () => ({
	type: LOGIN_STARTED,
});

export const loginSuccess = (payload) => ({
	type: LOGIN_SUCCESS,
	payload,
});

export const loginTwoFaSuccess = (payload) => ({
	type: LOGIN_TWO_FA_SUCCESS,
});

export const loginFailure = (error) => ({
	type: LOGIN_FAILURE,
	payload: {
		error,
	},
});

export const login = (data) => {
	return async (dispatch) => {
		dispatch(loginStarted());
		let formData = new FormData();
		formData.append("password", data.password);
		formData.append("username", data.username);
		formData.append("grant_type", "password");
		if (data.secondAuthCode) {
			formData.append("secondAuthCode", data.secondAuthCode);
		}
		const authToken = Buffer.from(String(AUTH_TOKEN), "utf8").toString(
			"base64"
		);
		const headers = { Authorization: "Basic " + authToken };

		api.account
			.login(formData, headers)
			.then((res) => {
				localStorage.setItem("access_token", res.data?.access_token);
				localStorage.setItem("refresh_token", res.data?.refresh_token);
				dispatch(loginSuccess(res.data));
			})
			.catch((error) => {
				if (
					error?.response?.status === 403 &&
					error?.response?.data?.error_description === "2FA"
				) {
					dispatch(loginTwoFaSuccess());
					return;
				} else if (
					error?.response?.status === 403 &&
					error?.response?.data === "secret and code not equals"
				) {
					dispatch(
						enqueueSnackbar({
							message: "Invalid Google Authenticator code.",
							options: {
								key: new Date().getTime() + Math.random(),
								variant: "error",
								autoHideDuration: 3000,
							},
						})
					);
					return;
				}
				dispatch(loginFailure(error));
			});
	};
};

export const enableTwoFAStarted = () => ({
	type: ENABLE_TWO_FA_STARTED,
});

export const enableTwoFASuccess = (payload) => ({
	type: ENABLE_TWO_FA_SUCCESS,
	payload,
});

export const enableTwoFAFailure = (error) => ({
	type: ENABLE_TWO_FA_FAILURE,
	payload: {
		error,
	},
});

export const enableTwoFA = () => {
	return async (dispatch) => {
		dispatch(enableTwoFAStarted());
		api.account
			.enableTwoFA()
			.then((res) => {
				dispatch(enableTwoFASuccess(res.data));
			})
			.catch((error) => dispatch(enableTwoFAFailure(error)));
	};
};

export const disableTwoFAStarted = () => ({
	type: DISABLE_TWO_FA_STARTED,
});

export const disableTwoFASuccess = (payload) => ({
	type: DISABLE_TWO_FA_SUCCESS,
	payload,
});

export const disableTwoFAFailure = (error) => ({
	type: DISABLE_TWO_FA_FAILURE,
	payload: {
		error,
	},
});

export const disableTwoFA = () => {
	return async (dispatch) => {
		dispatch(disableTwoFAStarted());
		api.account
			.disableTwoFA()
			.then(() => {
				dispatch(disableTwoFASuccess());
				dispatch(getAccountData());
			})
			.catch((error) => dispatch(disableTwoFAFailure(error)));
	};
};

export const checkIsTwoFAStarted = () => ({
	type: CHECK_IS_TWO_FA_STARTED,
});

export const checkIsTwoFASuccess = (payload) => ({
	type: CHECK_IS_TWO_FA_SUCCESS,
	payload,
});

export const checkIsTwoFAFailure = (error) => ({
	type: CHECK_IS_TWO_FA_FAILURE,
	payload: {
		error,
	},
});

export const checkIsTwoFA = () => {
	return async (dispatch) => {
		dispatch(checkIsTwoFAStarted());
		api.account
			.checkIsTwoFA()
			.then((res) => {
				dispatch(checkIsTwoFASuccess(res.data));
			})
			.catch((error) => dispatch(checkIsTwoFAFailure(error)));
	};
};

export const checkCodeTwoFAStarted = () => ({
	type: CHECK_CODE_TWO_FA_STARTED,
});

export const checkCodeTwoFASuccess = (payload) => ({
	type: CHECK_CODE_TWO_FA_SUCCESS,
	payload,
});

export const checkCodeTwoFAFailure = (error) => ({
	type: CHECK_CODE_TWO_FA_FAILURE,
	payload: {
		error,
	},
});

export const checkCodeTwoFA = (data) => {
	return async (dispatch) => {
		dispatch(checkCodeTwoFAStarted());
		api.account
			.checkCodeTwoFA(data)
			.then((res) => {
				dispatch(checkCodeTwoFASuccess(res.data));
				if (!res.data) {
					dispatch(
						enqueueSnackbar({
							message: MessageError.InvalidTFCode,
							options: {
								key: new Date().getTime() + Math.random(),
								variant: "error",
								autoHideDuration: 3000,
							},
						})
					);
				}
			})
			.catch((error) => dispatch(checkCodeTwoFAFailure(error)));
	};
};

export const checkInstallTwoFAStarted = () => ({
	type: CHECK_INSTALL_TWO_FA_STARTED,
});

export const checkInstallTwoFASuccess = (payload) => ({
	type: CHECK_INSTALL_TWO_FA_SUCCESS,
	payload,
});

export const checkInstallTwoFAFailure = (error) => ({
	type: CHECK_INSTALL_TWO_FA_FAILURE,
	payload: {
		error,
	},
});

export const checkInstallTwoFA = (data) => {
	return async (dispatch) => {
		dispatch(checkInstallTwoFAStarted());
		return api.account
			.checkInstallTwoFA(data)
			.then((res) => {
				dispatch(checkInstallTwoFASuccess(res.data));
				dispatch(
					enqueueSnackbar({
						message: res.data
							? "Google Authenticator (2FA) enabled"
							: ` Invalid Google Authenticator code.`,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: res.data ? "success" : "error",
							autoHideDuration: 3000,
						},
					})
				);
				return res;
			})
			.catch((error) => {
				dispatch(checkInstallTwoFAFailure(error));
				dispatch(
					enqueueSnackbar({
						message: error.response.data.title,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "error",
							autoHideDuration: 3000,
						},
					})
				);
				return { data: false };
			});
	};
};

export const clearTwoFaQrCode = () => ({
	type: CLEAR_TWO_FA_QR_CODE,
});

// LOG_OUT

export const logOutStarted = () => ({
	type: LOG_OUT,
});

export const logOut = () => {
	return async (dispatch) => {
		api.account
			.logOut()
			.then((res) => {
				dispatch(logOutStarted());
				localStorage.clear();
				document.cookie = "";
			})
			.catch((error) => {
				dispatch(logOutStarted());
				localStorage.clear();
				document.cookie = "";
			});
		return {
			type: LOG_OUT,
		};
	};
};

export const getAccountDataStarted = () => ({
	type: GET_ACCOUNT_DATA_STARTED,
});

export const getAccountDataSuccess = (payload) => ({
	type: GET_ACCOUNT_DATA_SUCCESS,
	payload,
});

export const getAccountDataFailure = (error) => ({
	type: GET_ACCOUNT_DATA_FAILURE,
	payload: {
		error,
	},
});

export const getAccountData = () => {
	return async (dispatch) => {
		dispatch(getAccountDataStarted());
		api.account
			.getData()
			.then((res) => dispatch(getAccountDataSuccess(res.data)))
			.catch((error) => {
				dispatch(getAccountDataFailure(error));
				dispatch(logOut());
				if (error.response?.data?.error === "access_denied") {
					dispatch(
						enqueueSnackbar({
							message: `Access is denied`,
							options: {
								key: new Date().getTime() + Math.random(),
								variant: "error",
								autoHideDuration: 3000,
							},
						})
					);
				}
			});
	};
};

export const changePasswordStarted = () => ({
	type: CHANGE_PASSWORD_STARTED,
});

export const changePasswordSuccess = (data) => ({
	type: CHANGE_PASSWORD_SUCCESS,
	payload: {
		data,
	},
});

export const changePasswordFailure = (error) => ({
	type: CHANGE_PASSWORD_FAILURE,
	payload: {
		error,
	},
});

export const changePassword = (data) => {
	return async (dispatch) => {
		dispatch(changePasswordStarted());
		api.account
			.changePassword(data)
			.then((res) => {
				dispatch(
					enqueueSnackbar({
						message: Message.PasswordChanged,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
				dispatch(changePasswordSuccess(res.data));
				dispatch(logOut());
			})
			.catch((error) => {
				dispatch(
					enqueueSnackbar({
						message: error.response.data.title,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "error",
							autoHideDuration: 3000,
						},
					})
				);
				dispatch(changePasswordFailure(error));
			});
	};
};

// GET_WHOAMI

export const getWhoamiStarted = () => ({
	type: GET_WHOAMI_STARTED,
});

export const getWhoamiSuccess = (data) => ({
	type: GET_WHOAMI_SUCCESS,
	payload: {
		data,
	},
});

export const getWhoamiFailure = (error) => ({
	type: GET_WHOAMI_FAILURE,
	payload: {
		error,
	},
});

export const getWhoami = (data) => {
	return async (dispatch) => {
		dispatch(getWhoamiStarted());
		api.account
			.getWhoami(data)
			.then((res) => {
				dispatch(getWhoamiSuccess(res.data));
			})
			.catch((error) => {
				dispatch(getWhoamiFailure(error));
			});
	};
};

// SEND_REGISTRATION_MESSAGE

export const sendRegistrationMessageStarted = () => ({
	type: SEND_REGISTRATION_MESSAGE_STARTED,
});

export const sendRegistrationMessageSuccess = (data) => ({
	type: SEND_REGISTRATION_MESSAGE_SUCCESS,
	payload: {
		data,
	},
});

export const sendRegistrationMessageFailure = (error) => ({
	type: SEND_REGISTRATION_MESSAGE_FAILURE,
	payload: {
		error,
	},
});

export const sendRegistrationMessage = (data) => {
	return async (dispatch) => {
		dispatch(sendRegistrationMessageStarted());
		api.account
			.sendRegistrationMessage(data)
			.then((res) => {
				dispatch(
					enqueueSnackbar({
						message: Message.SuccessfullyReSent,
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
				dispatch(sendRegistrationMessageSuccess(res.data));
			})
			.catch((error) => {
				dispatch(sendRegistrationMessageFailure(error));
			});
	};
};
