import React, { useEffect, useState } from "react";
import { useSettingsStyles } from "../Settings/SettingsStyles";
import { TablePagination } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PageTitle from "../../theme/components/PageTitle";
import CopyWithAlert from "../../utils/CopyWithAlert";
import Table from "../../theme/components/Table/Table";
import {
	getActionLogList,
	getActionLogById,
} from "../../app/actions/actionLogAction";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
} from "../../app/actions/filterActions";

const columns = [
	{ field: "id", headerName: "ID" },
	{ field: "firstname", headerName: "First name" },
	{ field: "lastname", headerName: "Last name" },
	{ field: "uid", headerName: "User id" },
	{ field: "date", headerName: "Date&Time" },
	{ field: "section", headerName: "Section" },
	{ field: "action", headerName: "Action" },
	{ field: "comment", headerName: "Comment" },
];
const filterFields = [
	{ field: "uid", type: "number", title: "User ID", placeholder: "User ID" },
	{
		field: "dateFrom",
		type: "date",
		title: "Create date from",
		placeholder: "Create date from",
	},
	{
		field: "dateTo",
		type: "date",
		title: "Create date to",
		placeholder: "Create Date to",
	},
];

const ActionLog = ({ id }) => {
	const classes = useSettingsStyles();
	const dispatch = useDispatch();
	const { data, total } = useSelector(({ actionLogs }) => actionLogs);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		activeFilters.forEach(item => {
			filteringParams = { ...filteringParams, [item.field]: item.value };
		});
		!id
			? dispatch(getActionLogList(filteringParams))
			: dispatch(getActionLogById({ ...filteringParams, uid: id }));
	}, [dispatch, activeFilters, id]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			{!id && <PageTitle title={"Action Log"} filterFields={filterFields} />}
			<div className={classes.root}>
				<Table>
					<thead>
						<tr>
							{columns.map(({ field, headerName }) => (
								<th key={field} align={"left"}>
									{headerName}
								</th>
							))}
						</tr>
					</thead>
					<tbody style={{ position: "relative" }}>
						{data.length > 0 ? (
							data.map((row, index) => (
								<TableRow key={row?.id || index} rowData={row} />
							))
						) : (
							<tr>
								<td colSpan={13} align={"center"} height={100}>
									The list is empty...
								</td>
							</tr>
						)}
					</tbody>
				</Table>
				{!id && (
					<TablePagination
						rowsPerPageOptions={[10, 30, 50]}
						component='div'
						count={total}
						rowsPerPage={activeFilters.find(x => x.field === "size").value}
						page={activeFilters.find(x => x.field === "page").value}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeSize}
					/>
				)}
			</div>
		</div>
	);
};

export default ActionLog;

function TableRow({ rowData }) {
	const [isShowContent, setShowContent] = useState(false);
	return (
		<>
			<tr>
				<td>{rowData?.id}</td>
				<td>{rowData?.firstname}</td>
				<td>{rowData?.lastname}</td>
				<CopyWithAlert text={rowData?.uid + "" || ""} label={"user id"}>
					<td data='hover'>{rowData?.uid}</td>
				</CopyWithAlert>
				<td>
					{rowData?.date && moment(rowData?.date).format("DD MM yyyy HH:mm")}
				</td>
				<td>{rowData?.section}</td>
				<td>{rowData?.action}</td>
				<td data='hover' onClick={() => setShowContent(!isShowContent)}>
					{isShowContent
						? rowData?.comment
								.split(",")
								.map((str, index) => <p key={index}>{str} </p>)
						: rowData?.comment}
				</td>
			</tr>
		</>
	);
}
