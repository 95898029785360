import React, { useState } from "react";
import { Tabs, Tab, Box } from "@material-ui/core";
import PropTypes from "prop-types";
import PageTitle from "../../../theme/components/PageTitle";
import LiquidityProvidersList from "./LiquidityProviders/LiquidityProvidersList.jsx";
import PairSettingsList from "./PairSettings/PairSettingsList.jsx";

const tabs = {
	liquidityProviders: "liquidity providers",
    pairSettings: "pair settings"
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}>
			{value === index && <Box style={{ paddingTop: 24 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const BankingSettings = () => {
	const [activeTab, setActiveTab] = useState(0);

	const handleChangeTabs = (_, newTab) => {
		setActiveTab(newTab);
	};

	return (
		<div style={{ maxWidth: "100%" }}>
			<PageTitle
				title={"Banking Settings"}
				style={{ height: 100 }}
				filterFields={null}
			/>
			<Tabs
				value={activeTab}
				onChange={handleChangeTabs}
				aria-label='simple tabs example'
				indicatorColor='primary'>
				<Tab label={tabs.liquidityProviders} {...a11yProps(0)} />
				<Tab label={tabs.pairSettings} {...a11yProps(1)} />
			</Tabs>
			<TabPanel value={activeTab} index={0}>
				<LiquidityProvidersList />
			</TabPanel>
			<TabPanel value={activeTab} index={1}>
				<PairSettingsList />
			</TabPanel>
		</div>
	);
};

export default BankingSettings;
