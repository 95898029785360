import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { getWalletsList, clearWallets } from "../../app/actions/walletsActions";
import { getMarketList } from "../../app/actions/marketActions";
import Table from "../../theme/components/Table/Table";
import CopyWithAlert from "../../utils/CopyWithAlert";
import NotInterestedRoundedIcon from "@material-ui/icons/NotInterestedRounded";
import clsx from "clsx";

const columns = [
	{ key: "currency", label: "Currency" },
	{ key: "currencyName", label: "Currency name" },
	{ key: "available ", label: "Available " },
	{ key: "amount", label: "USDT amount" },
	{ key: "address", label: "Address" },
];

const Wallets = () => {
	const classes = useStyles();
	const user = useSelector(({ users }) => users.profile);
	const dispatch = useDispatch();
	const { data, params } = useSelector(({ wallets }) => wallets);
	const marketPriceList = useSelector(({ market }) => market.data);

	useEffect(() => {
		/*eslint no-useless-computed-key: "off"*/
		let filteringParams = { ...params, ["uid.equals"]: user?.id };
		dispatch(getWalletsList(filteringParams));
		dispatch(getMarketList());
	}, [dispatch, params, user]);

	useEffect(() => {
		return () => {
			dispatch(clearWallets());
		};
	}, [dispatch]);

	const getMarketPrice = rowData => {
		return (
			marketPriceList?.find(i => i?.ticker === rowData?.ticker)?.price || 0
		);
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.length > 0 && marketPriceList.length > 0 ? (
						data.map((rowData, index) => (
							<tr key={index}>
								<td>
									<div className={classes.currency}>
										<img
											src={`https://cryptoneed.com/icons/${rowData?.ticker}.svg`}
											alt={rowData?.ticker}
											width={32}
											height={32}
											loading={"lazy"}
										/>
										<div>
											<p>({rowData?.ticker?.toUpperCase()})</p>
										</div>
									</div>
								</td>
								<td>{rowData?.cname || rowData?.ticker?.toUpperCase()}</td>
								<td className={clsx(classes.td, classes.paper)}>
									<p>
										{rowData.balance} {rowData?.ticker?.toUpperCase()}
									</p>
								</td>
								<td>
									$
									{Number(rowData.balance) *
										Number(getMarketPrice(rowData)).toFixed(2)}
								</td>
								{rowData?.address ? (
									<CopyWithAlert
										text={rowData?.address + "" || ""}
										label={"Address"}>
										<td className={classes.hoverCopyTd}>{rowData?.address}</td>
									</CopyWithAlert>
								) : (
									<td></td>
								)}
							</tr>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								<NotInterestedRoundedIcon />
								<br />
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</>
	);
};

export default Wallets;

const useStyles = makeStyles(theme => ({
	head: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
		"&>.MuiTextField-root": {
			maxWidth: 256,
			[theme.breakpoints.down("sm")]: {
				marginTop: 24,
			},
		},
	},
	root: {
		marginTop: 24,
		width: "100%",
		overflowX: "auto",
		paddingBottom: 24,
	},
	title: {
		color: theme.palette.text.secondary,
	},
	subTitle: {
		fontStyle: "italic",
		textDecoration: "underline",
		marginRight: 24,
	},
	table: {
		borderSpacing: 0,
		width: "100%",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		"&:first-child": {
			borderRadius: "10px 0 0 10px",
		},
		"&:last-child": {
			borderRadius: "0 10px 10px 0",
		},
	},
	hoverCopyTd: {
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.light,
		},
	},
	currency: {
		display: "flex",
		alignItems: "center",
		height: "100%",
		"&>img": {
			marginRight: 16,
		},
	},
}));
