import { forwardRef } from "react";
import { Dialog, IconButton, makeStyles, Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const DialogWrapper = ({
	open,
	handleClose,
	children,
	maxWidth,
	minWidth,
	maxW,
}) => {
	const { root, icon } = useStyles();

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			maxWidth={maxW || "sm"}
			keepMounted
			onClose={handleClose}>
			<div className={root} style={{ maxWidth, minWidth }}>
				<IconButton className={icon} size={"small"} onClick={handleClose}>
					<CloseIcon color={"disabled"} fontSize={"small"} />
				</IconButton>
				{children}
			</div>
		</Dialog>
	);
};

DialogWrapper.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	maxWidth: PropTypes.number,
};

export default DialogWrapper;

const useStyles = makeStyles(theme => ({
	root: {
		padding: "24px 48px",
		minWidth: 320,
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			paddingTop: theme.spacing(5),
			paddingBottom: theme.spacing(4),
			paddingLeft: theme.spacing(3),
			paddingRight: theme.spacing(3),
		},
		[theme.breakpoints.down("xs")]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
	},
	icon: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
	},
}));
