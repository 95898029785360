import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	Grid,
	makeStyles,
	Typography,
	Paper,
	IconButton,
} from "@material-ui/core";
import { getNodeDetails } from "../../app/actions/coinsActions";
import PaymentListNode from "./PaymentListNode";
import BackIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	const classes = useStyles();

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}>
			{value === index && <Box className={classes.tab}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const NodeDetails = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { ticker } = useParams();
	const history = useHistory();
	const nodeDetails = useSelector(({ nodes }) => nodes.nodeDetails);
	const [generalInfo, setGeneralInfo] = useState([]);
	const [blockChainInfo, setBlockChainInfo] = useState([]);
	const [blockWalletInfo, setBlockWalletInfo] = useState([]);

	useEffect(() => {
		if (ticker) dispatch(getNodeDetails(ticker?.toLowerCase()));
	}, [dispatch, ticker]);

	useEffect(() => {
		if (!nodeDetails) {
			setGeneralInfo([]);
			setBlockChainInfo([]);
			setBlockWalletInfo([]);
		} else {
			setGeneralInfo(
				Object.keys(nodeDetails).filter(
					key =>
						typeof nodeDetails[key] === "string" ||
						typeof nodeDetails[key] === "number"
				)
			);
			setBlockChainInfo(Object.keys(nodeDetails?.blockChainInfo));
			setBlockWalletInfo(Object.keys(nodeDetails?.walletInfo));
		}
	}, [nodeDetails]);

	const [value, setValue] = useState(0);

	const handleChangeTabs = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div>
			<div className={classes.head}>
				<Paper
					elevation={0}
					className={classes.paper}
					onClick={() => history.push("/monitoring/nodes")}>
					<IconButton className={classes.icon} size={"small"}>
						<BackIcon />
					</IconButton>
					<Typography variant={"subtitle2"} color={"textSecondary"}>
						Back to <span className={classes.link}>Nodes</span>
					</Typography>
				</Paper>
				<Typography variant={"h4"} color={"textPrimary"}>
					Node information
				</Typography>
			</div>
			<div className={classes.root}>
				<Grid container spacing={2} columns={5} className={classes.general}>
					{generalInfo.map(key => (
						<Grid key={key} item xs={6}>
							<Typography
								variant={"h6"}
								color={"textPrimary"}
								align={"left"}
								className={classes.field}>
								{key}: <span className={classes.span}>{nodeDetails[key]}</span>
							</Typography>
						</Grid>
					))}
				</Grid>
				<Tabs
					value={value}
					onChange={handleChangeTabs}
					aria-label='simple tabs example'
					indicatorColor='primary'>
					<Tab label='blockChainInfo' {...a11yProps(0)} />
					<Tab label='WalletInfo' {...a11yProps(1)} />
					<Tab label='PaymentList' {...a11yProps(2)} />
				</Tabs>
				<TabPanel value={value} index={0}>
					<table style={{ width: "100%" }}>
						<tbody>
							{blockChainInfo.map(key => (
								<tr key={key}>
									<td style={{ width: "33%" }}>
										<Typography variant={"h6"}>{key}</Typography>
									</td>
									<td style={{ width: "67%" }}>
										<Typography variant={"h6"} className={classes.span}>
											{nodeDetails?.blockChainInfo[key]}
										</Typography>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<table style={{ width: "100%" }}>
						<tbody>
							{blockWalletInfo.map(key => (
								<tr key={key}>
									<td style={{ width: "33%" }}>
										<Typography variant={"h6"}>{key}</Typography>
									</td>
									<td style={{ width: "67%" }}>
										<Typography variant={"h6"} className={classes.span}>
											{nodeDetails?.walletInfo[key]}
										</Typography>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</TabPanel>
				<TabPanel value={value} index={2}>
					<PaymentListNode />
				</TabPanel>
			</div>
		</div>
	);
};

export default NodeDetails;

const useStyles = makeStyles(theme => ({
	head: {
		marginBottom: 32,
		"&>.MuiTextField-root": {
			maxWidth: 256,
			marginTop: 24,
			[theme.breakpoints.down("sm")]: {
				marginTop: 24,
			},
			"&>.MuiOutlinedInput-root>input": {
				padding: 10,
			},
		},
	},
	paper: {
		display: "flex",
		alignItems: "center",
		padding: 8,
		paddingRight: 12,
		borderRadius: 8,
		width: "max-content",
		marginBottom: 16,
		"&:hover": {
			cursor: "pointer",
		},
	},
	icon: {
		color: theme.palette.primary.light,
		padding: 0,
		marginRight: 4,
		"& svg": {
			fontSize: 19,
		},
	},
	link: {
		color: theme.palette.primary.light,
		fontWeight: 700,
		textDecoration: "none",
	},
	root: {
		"&>.MuiAccordion-rounded:last-child": {
			borderRadius: 0,
		},
	},
	subtitle: {
		width: "100%",
		textTransform: "uppercase",
	},
	field: {
		whiteSpace: "nowrap",
		padding: 0,
	},
	span: {
		paddingLeft: 24,
		textDecoration: "underline",
		fontWeight: 400,
	},
	general: {
		marginBottom: 24,
	},
	input: {
		width: "100%",
		minWidth: 200,
		marginTop: 32,
	},
	tab: {
		paddingTop: theme.spacing(3),
	},
}));
