import {
	GET_SESSION_LOG_LIST_SUCCESS,
	GET_SESSION_LOG_LIST_STARTED,
	GET_SESSION_LOG_LIST_FAILURE,
	CLEAR_SESSION_LOG,
} from "../types";

export default function sessionLog(
	state = {
		data: [],
		total: 0,
		error: null,
	},
	action
) {
	switch (action.type) {
		case GET_SESSION_LOG_LIST_STARTED:
			return { ...state, error: null };
		case GET_SESSION_LOG_LIST_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				total: action.payload.totalItems,
			};
		case GET_SESSION_LOG_LIST_FAILURE:
			return { ...state, error: action.payload.error };
		case CLEAR_SESSION_LOG:
			return { ...state, data: [], total: 0, error: null };
		default:
			return state;
	}
}
