import {Input} from "./Input";
import {InputAdornment} from "@material-ui/core";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import PropTypes from 'prop-types';

const Search = ({value, onChange, className}) => {
	return (
		<Input
			className={className}
			placeholder="Search"
			variant="outlined"
			autoComplete="on"
			value={value}
			onChange={onChange}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<SearchRoundedIcon/>
					</InputAdornment>
				)
			}}
		/>
	);
};

Search.propType = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.object
}

export default Search;
