import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Tabs, Tab, Box, Typography, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../theme/components/PageTitle";
import {
	INVOICES_FILTER_FIELDS,
	OPERATION_HISTORY_FILTER_FIELDS,
	TRANSFERS_FILTER_FIELDS,
	DISTRIBUTION_PROGRAM_FILTER_FIELDS,
	DIVIDENDS_FILTER_FIELDS,
} from "../../../utils/constants/funds";
import Invoices from "./Invoices";
import OperationsHistory from "./Info/OperationsHistory";
import FundSettings from "./Settings/FundSettings";
import SystemBalances from "./SystemBalances";
import Transfers from "./Transfers";
import UserBalance from "./UserBalances";
import DistributionPrograms from "./DistributionPrograms";
import Dividends from "./Dividends";
import DistributionHistory from "./DistributionHistory";
import PayoutBalances from "./PayoutBalances/PayoutBalances";
import { Button } from "../../../theme/components/Button";
import TransferDialog from "../../../theme/components/dialogs/TransferDialog";
import { getSystemBalancesFunds, sendFromSystemBalanceToWalletByAddress } from "../../../app/actions/fundsCapitalActions";

const tabs = {
	invoice: "Invoice",
	info: "Info",
	settings: "Settings",
	payoutBalances: "Payout Balances",
	transfers: "Transfers",
	dividends: "Dividends",
	userBalance: "User balance",
	distributionHistory: "Distribution history",
	systemBalances: "System balances",
	distributionPrograms: "Distribution programs",
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}>
			{value === index && <Box style={{ paddingTop: 24 }}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const Funds = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(0);
	const [filterFields, setFilterFields] = useState(0);
	const [createFund, setCreateFund] = useState(false);
	const [showSystemBalanceTransferDialog, setShowSystemBalanceTransferDialog] = useState(null)

	const { systemBalancesFunds } = useSelector(({ funds }) => funds);
	const usdtSystemBalance = useMemo(() => {
		return systemBalancesFunds?.find((balance) => balance?.asset === 'usdt-trc20');
	}, [systemBalancesFunds])

	useEffect(() => {
		switch (activeTab) {
			case 0:
				setFilterFields(INVOICES_FILTER_FIELDS);
				return;
			case 1:
				setFilterFields(OPERATION_HISTORY_FILTER_FIELDS);
				return;
			case 4:
				setFilterFields(TRANSFERS_FILTER_FIELDS);
				return;
			case 5:
				setFilterFields(DIVIDENDS_FILTER_FIELDS);
				return;
			case 7:
				setFilterFields(DISTRIBUTION_PROGRAM_FILTER_FIELDS);
				return;
			default:
				setFilterFields(null);
				return;
		}
	}, [dispatch, activeTab]);

	const handleChangeTabs = (_, newTab) => {
		setActiveTab(newTab);
	};

	const handleCreate = () => {
		setCreateFund(true);
	};

	const handleSystemBalanceTransfer = (data) => {
		dispatch(sendFromSystemBalanceToWalletByAddress(data));
	};

	useEffect(() => {
		dispatch(getSystemBalancesFunds())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const renderTitle = useCallback(() => {
		return (<Grid container direction="row" style={{ gap: 10 }}>
			<Typography variant={"h4"} color={"textPrimary"}>
				Funds
			</Typography>
			<Button
				variant='outlined'
				color='primary'
				style={{ padding: "0px 8px" }}
				onClick={() => setShowSystemBalanceTransferDialog(true)}>
				Super withdraw
			</Button>
		</Grid>)
	}, [])

	return (
		<div style={{ maxWidth: "100%" }}>
			<TransferDialog
				open={Boolean(showSystemBalanceTransferDialog)}
				handleClose={() => setShowSystemBalanceTransferDialog(false)}
				data={usdtSystemBalance}
				handleTransfer={handleSystemBalanceTransfer}
				title={"Transfer from system balance to wallet"}
			/>
			<PageTitle
				renderTitle={renderTitle}
				title={"Funds"}
				style={{ height: 100 }}
				filterFields={filterFields}
				onCreate={activeTab === 2 || activeTab === 3 ? handleCreate : null}
			/>
			<Tabs
				value={activeTab}
				onChange={handleChangeTabs}
				aria-label='simple tabs example'
				indicatorColor='primary'>
				<Tab label={tabs.invoice} {...a11yProps(0)} />
				<Tab label={tabs.info} {...a11yProps(1)} />
				<Tab label={tabs.settings} {...a11yProps(2)} />
				<Tab label={tabs.payoutBalances} {...a11yProps(3)} />
				<Tab label={tabs.transfers} {...a11yProps(4)} />
				<Tab label={tabs.dividends} {...a11yProps(5)} />
				<Tab label={tabs.userBalance} {...a11yProps(6)} />
				<Tab label={tabs.distributionHistory} {...a11yProps(7)} />
				<Tab label={tabs.systemBalances} {...a11yProps(8)} />
				<Tab label={tabs.distributionPrograms} {...a11yProps(9)} />
			</Tabs>
			<TabPanel value={activeTab} index={0}>
				<Invoices />
			</TabPanel>
			<TabPanel value={activeTab} index={1}>
				<OperationsHistory isIndexes={false} />
			</TabPanel>
			<TabPanel value={activeTab} index={2}>
				<FundSettings
					createFund={createFund}
					onCloseCreateFund={() => setCreateFund(false)}
				/>
			</TabPanel>
			<TabPanel value={activeTab} index={3}>
				<PayoutBalances
					createPayoutBalance={createFund}
					onCloseCreatePayoutBalance={() => setCreateFund(false)}
				/>
			</TabPanel>
			<TabPanel value={activeTab} index={4}>
				<Transfers isIndexes={false} />
			</TabPanel>
			<TabPanel value={activeTab} index={5}>
				<Dividends />
			</TabPanel>
			<TabPanel value={activeTab} index={6}>
				<UserBalance isIndexes={false} />
			</TabPanel>
			<TabPanel value={activeTab} index={7}>
				<DistributionHistory />
			</TabPanel>
			<TabPanel value={activeTab} index={8}>
				<SystemBalances isIndexes={false} />
			</TabPanel>
			<TabPanel value={activeTab} index={9}>
				<DistributionPrograms />
			</TabPanel>
		</div>
	);
};

export default Funds;
