import { useEffect } from "react";
import DialogWrapper from "../../../../theme/components/dialogs/DialogWrapper";
import {
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Button } from "../../../../theme/components/Button";
import { useDispatch, useSelector } from "react-redux";
import { getEstimateDevidendsInfo } from "../../../../app/actions/fundsCapitalActions";

const dividentsFields = [
  {
    field: 'userId',
    label: 'User Id',
    type: 'string',
  },
  {
    field: 'reward',
    label: 'Reward',
    type: 'stringWithTicker',
  },

]
// MOCK
// const estimatedDividendsInfo = {
//   "program": {
//     "id": null,
//     "token": 'bbc',
//     "asset": 'btc',
//     "totalUserTokens": 10,
//     "amountToDistribute": 5,
//     "amountToSave": 5,
//     "rate": 8,
//     "rewardPercent": 12,
//     "timeWhenProgramWasCompleted": '2023-07-28T17:40:35.787818Z',
//   },
//   "dividends": [{
//     "id": 1,
//     "token": 'tcc',
//     "asset": 'btc',
//     "userId": 12,
//     "programId": null,
//     "userTokens": 12,
//     "reward": 14,
//     "distributionTime": '2023-07-28T17:40:35.787818Z'
//   },
//   {
//     "id": 2,
//     "token": 'tcc',
//     "asset": 'btc',
//     "userId": 12,
//     "programId": null,
//     "userTokens": 12,
//     "reward": 14,
//     "distributionTime": '2023-07-28T17:40:35.787818Z'
//   }]

// }

const EstimateDividendsInfoDialog = ({
  open,
  title,
  handleClose,
  btnOkText = "Ok",
  btnCancelText = "Cancel",
  fundId,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const estimatedDividendsInfo = useSelector(
    ({ funds }) => funds?.estimatedDividendsInfo
  );

  useEffect(() => {
    if (open && fundId) {
      dispatch(getEstimateDevidendsInfo({ fundId }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, fundId])

  return (
    <DialogWrapper
      open={open}
      handleClose={() => handleClose({ accept: false })}>
      <Typography
        variant={"h6"}
        align={"center"}
        color={"textPrimary"}
        style={{ marginBottom: 24, textTransform: 'uppercase' }}>
        {title}
      </Typography>
      <Grid container >
        {estimatedDividendsInfo?.dividends?.map((divident, index) => {
          return (
            <Grid key={divident.id} container>
              <Grid container wrap="nowrap" direction="column">
                {dividentsFields?.map(({ field, label, type }) => {
                  return (
                    <Grid item xs={12} key={field} align={"left"}>
                      <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                        style={{ fontWeight: 500, marginBottom: 16 }}>
                        <Grid
                          item
                          xs={6}>
                          <Typography variant={"body1"} color={"textPrimary"} noWrap>
                            {label}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={6}>
                          <Typography
                            variant={"body1"}
                            style={{ textTransform: 'uppercase' }}
                            noWrap>

                            {divident?.[field]} {type === 'stringWithTicker' ? divident?.asset.toUpperCase() : null}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
              {estimatedDividendsInfo?.dividends?.length !== index + 1 ?
                <span className={classes.divider} />
                : null
              }
            </Grid>
          )
        })}
      </Grid>
      <Grid container justifyContent='space-between' style={{ marginTop: 4 }}>
        <Button
          color={"primary"}
          style={{ width: "max(25%, 50px)", marginTop: 12 }}
          onClick={() => handleClose({ accept: false })}>
          {btnCancelText}
        </Button>
        <Button
          color={"primary"}
          style={{
            width: "max(25%, 50px)",
            marginTop: 12,
            color: btnOkText === "Ok" ? "#60CF61" : "#E44A4A",
          }}
          onClick={() =>
            handleClose({ accept: true })
          }>
          {btnOkText}
        </Button>
      </Grid>
    </DialogWrapper>
  );
};

export default EstimateDividendsInfoDialog;

const useStyles = makeStyles(theme => ({
  divider: {
    width: '100%',
    height: 1,
    background: theme.palette.text.secondary,
    marginBottom: 24,
  },
}));

