import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import moment from "moment";
import Table from "../../../../theme/components/Table/Table";
import { searchDividends } from "../../../../app/actions/fundsCapitalActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../../app/actions/filterActions";

const columns = [
	{ key: "id", label: "ID" },
	{ key: "token", label: "Token" },
	{ key: "asset", label: "Asset" },
	{ key: "totalUserTokens", label: "User Id" },
	{ key: "programId", label: "Program Id" },
	{ key: "userTokens", label: "User Tokens" },
	{ key: "reward", label: "Reward" },
	{ key: "distributionTime", label: "Distribution Time" },
];

// MOCK RESPONSE EXAMPLE
// const content = [{
// 	id: 0,
// 	token: 'TSF',
// 	asset: 'usdt-trc20',
// 	userId: '123',
// 	programId: 2,
// 	userTokens: 123456,
// 	reward: '1235.034123',
// 	distributionTime: "2023-05-04T08:59:00.024237Z"
// }]

const Dividends = () => {
	const dispatch = useDispatch();
	const { content, totalElements } = useSelector(
		({ funds }) => funds?.dividends
	);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(searchDividends(filteringParams));
	}, [dispatch, activeFilters]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{content?.length > 0 ? (
						content?.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={totalElements || 0}
				rowsPerPage={activeFilters?.find(x => x.field === "size").value}
				page={activeFilters?.find(x => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default Dividends;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<td>{rowData?.id}</td>
				<td>{rowData?.token}</td>
				<td>{rowData?.asset}</td>
				<td>{rowData?.userId}</td>
				<td>{+rowData?.programId}</td>
				<td>{+rowData?.userTokens}</td>
				<td>{+rowData?.reward}</td>
				<td>
					{rowData?.distributionTime &&
						moment(rowData?.distributionTime).format("DD MMM YYYY HH:mm")}
				</td>
			</tr>
		</>
	);
}
