import { Grid, Typography, makeStyles } from "@material-ui/core";
import DialogWrapper from "../../../../../theme/components/dialogs/DialogWrapper";
import { Input } from "../../../../../theme/components/Input";
import { Button } from "../../../../../theme/components/Button";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useValidateForm } from '../../../../../utils/hooks/useValidateForm'
import { requiredValidator } from "../../../../../utils/customValidators/validators";
import { createNewIndexAsset, updateIndexAssetInfo } from "../../../../../app/actions/indexesCapitalActions";

const initialForm = {
	tokenName: "",
};

const editInitialForm = {
	tokenName: "",
	balance: ""
}

const CreateAssetDialog = ({ open, handleClose, data, params, isFromManagePage }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [form, setForm] = useState({ ...(data ? editInitialForm : initialForm) });

	const { validateForm, validateInput, setErrors, errors } = useValidateForm({ form, customValidator: requiredValidator });

	const handleChange = event => {
		event.preventDefault();
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
		validateInput(event)
	};

	useEffect(() => {
		if (data) {
			setForm({ tokenName: data?.token, balance: data?.balance })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data])

	const handleCloseDialog = useCallback(() => {
		setErrors({});
		setForm(initialForm);
		handleClose();
	}, [handleClose, setErrors])

	const handleCreate = useCallback(() => {
		if (!validateForm()) {
			return;
		}
		if (data) {
			dispatch(updateIndexAssetInfo({ id: data?.id, balance: form.balance, indexId: isFromManagePage ? params?.indexId : null }))
		} else {
			dispatch(createNewIndexAsset({ indexId: params?.id, tokenName: form.tokenName }));
		}
		handleCloseDialog();
	}, [data, dispatch, form, handleCloseDialog, isFromManagePage, params, validateForm])

	return (
		<DialogWrapper
			open={open}
			handleClose={handleCloseDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				{`${data ? 'Edit' : 'Create'} additional asset`}
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={12} className={classes.formCroup}>
					<Input
						type='text'
						error={errors?.tokenName?.invalid}
						label='Token Name'
						variant={"outlined"}
						name={"tokenName"}
						value={form?.tokenName || ""}
						onChange={handleChange}
						onBlur={validateInput}
						helperText={errors?.tokenName?.errorMessage}
						disabled={Boolean(data)}
					/>
				</Grid>
				{
					data ?
						<Grid item xs={12} className={classes.formCroup}>
							<Input
								type='number'
								error={errors?.balance?.invalid}
								label='Balance'
								variant={"outlined"}
								name={"balance"}
								value={form?.balance || ""}
								onChange={handleChange}
								onBlur={validateInput}
								helperText={errors?.balance?.errorMessage}
							/>
						</Grid> : null
				}
				<Grid item xs={12} className={classes.formCroup}>
					<Input
						type='text'
						error={errors?.indexId?.invalid}
						label='Index Id'
						variant={"outlined"}
						name={"indexId"}
						disabled
						value={data ? data?.indexId : params?.id || ""}
						helperText={errors?.indexId?.errorMessage}
					/>
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)", marginTop: 12 }}
							onClick={handleCreate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default CreateAssetDialog;

const useStyles = makeStyles(theme => ({
	formCroup: {
		display: "flex",
		flexDirection: "column",
	},
	errorField: {
		color: theme.palette.text.error,
		fontSize: 12,
		position: "absolute",
		marginTop: 56,
	},
	success: {
		color: theme.palette.text.success,
	},
	error: {
		color: theme.palette.text.error,
	},
}));
