import DialogWrapper from "../../../theme/components/dialogs/DialogWrapper";
import { Typography } from "@material-ui/core";

const ProgramDetailsDialog = ({ open, handleClose, data }) => {
	return (
		<DialogWrapper open={open} handleClose={handleClose}>
			<Typography
				variant={"body1"}
				align={"center"}
				color={"textSecondary"}
				style={{ marginBottom: 32, textDecoration: "underline" }}>
				{`Message about the results of program execution (ID: ${data?.id})`}
			</Typography>
			{data?.resultMessage.split("\n").map((str, index) => {
				return (
					<Typography
						key={index}
						variant={"body2"}
						align={"justify"}
						color={"textPrimary"}>
						{str}
					</Typography>
				);
			})}
		</DialogWrapper>
	);
};

export default ProgramDetailsDialog;
