import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../../theme/components/PageTitle";
import { getTransfersToMasterWallet } from "../../../app/actions/transactionsActions";
import {
	setActiveFiltersPage,
	setActiveFiltersSize,
	clearActiveFilters,
} from "../../../app/actions/filterActions";

import { TablePagination } from "@material-ui/core";

import moment from "moment";
import Table from "../../../theme/components/Table/Table";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import getStatusColor from "../../../utils/functions/getStatusColor";
import { clearTransactions } from "../../../app/actions/transactionsActions";

const styajkaFilterFields = [
	{
		field: "id",
		type: "number",
		title: "ID",
		placeholder: "ID",
	},
	{
		field: "userId",
		type: "number",
		title: "User ID",
		placeholder: "User ID",
	},
	{
		field: "txHash",
		type: "text",
		title: "Transaction Hash",
		placeholder: "Transaction Hash",
	},
	{
		field: "creationTimeFrom",
		type: "date",
		title: "Create date from",
		placeholder: "Create date from",
	},
	{
		field: "creationTimeTo",
		type: "date",
		title: "Create date to",
		placeholder: "Create date to",
	},
	{
		field: "currencyProtocol",
		type: "text",
		title: "Currency Protocol",
		placeholder: "Currency Protocol",
	},
	{ field: "status", type: "text", title: "Status", placeholder: "Status" },
];

const columns = [
	{ key: "id", label: "Id" },
	{ key: "currencyProtocol", label: "Currency protocol" },
	{ key: "amount", label: "Amount" },
	{ key: "ticker", label: "Ticker" },
	{ key: "userId", label: "User ID" },
	{ key: "address", label: "Address" },
	{ key: "txHash", label: "Transaction Hash" },
	{ key: "creationTime", label: "Created" },
	{ key: "status", label: "Status" },
];

const Styajka = () => {
	const dispatch = useDispatch();
	const { data, total } = useSelector(({ transactions }) => transactions);
	const [filterFields, setFilterFields] = useState(styajkaFilterFields);
	const activeFilters = useSelector(({ filter }) => filter.activeFilters);

	useEffect(() => {
		let filteringParams = {};
		if (activeFilters.length !== 0) {
			activeFilters.forEach(item => {
				filteringParams = { ...filteringParams, [item.field]: item.value };
			});
		}
		dispatch(getTransfersToMasterWallet(filteringParams));
		setFilterFields(styajkaFilterFields);
	}, [activeFilters, dispatch]);

	useEffect(() => {
		return () => {
			dispatch(clearActiveFilters());
			dispatch(clearTransactions());
		};
	}, [dispatch]);

	const handleChangePage = (_, newPage) => {
		dispatch(setActiveFiltersPage(newPage));
	};

	const handleChangeSize = ({ target }) => {
		dispatch(setActiveFiltersSize(target.value));
	};

	return (
		<>
			<PageTitle title={"Styajka"} filterFields={filterFields} />
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label }) => (
							<th key={key}>{label}</th>
						))}
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((rowData, index) => (
							<TableRow key={rowData?.id || index} rowData={rowData} />
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30, 50]}
				component='div'
				count={total}
				rowsPerPage={activeFilters?.find(x => x.field === "size").value}
				page={activeFilters?.find(x => x.field === "page").value}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeSize}
			/>
		</>
	);
};

export default Styajka;

function TableRow({ rowData }) {
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<CopyWithAlert
					text={rowData?.currencyProtocol + "" || ""}
					label={"Currency Protocol"}>
					<td data='hover'>{rowData?.currencyProtocol}</td>
				</CopyWithAlert>
				<td>{+rowData?.amount}</td>
				<CopyWithAlert text={rowData?.ticker + "" || ""} label={"Ticker"}>
					<td data='hover'>{rowData?.ticker}</td>
				</CopyWithAlert>
				<CopyWithAlert text={rowData?.userId + "" || ""} label={"User ID"}>
					<td data='hover'>{rowData?.userId}</td>
				</CopyWithAlert>
				<td>{rowData?.address}</td>
				<CopyWithAlert
					text={rowData?.txHash + "" || ""}
					label={"Transaction Hash"}>
					<td data='hover'>{rowData?.txHash || "-"}</td>
				</CopyWithAlert>
				<td>
					{rowData?.creationTime &&
						moment(rowData?.creationTime).format("DD MMM YYYY HH:mm")}
				</td>
				<CopyWithAlert text={rowData?.status + "" || ""} label={"Status"}>
					<td
						data='hover'
						style={{
							color: rowData?.status && getStatusColor(rowData?.status),
						}}>
						{rowData?.status || "-"}
					</td>
				</CopyWithAlert>
			</tr>
		</>
	);
}
