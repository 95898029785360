export default function getStatusColor(status) {
	if (!status) {
		return;
	}
	switch (status.toUpperCase()) {
		case "DONE":
		case "COMPLETED":
		case "EXECUTED":
		case "APPROVED":
		case "CHANGED":
		case "ACTIVE":
		case "LOW":
		case "BUY_TOKEN":
			return "#60CF61";
		case "CANCELED":
		case "REJECTED":
		case "DECLINED":
		case "FAILED":
		case "REJECTED_CODE_NOT_ACTIVATED":
		case "REJECTED_NEW_DEVICE":
		case "SELL_TOKEN":
		case "HIGH":
		case "DEACTIVATED":
		case "EXPIRED":
			return "#FF0000";
		case "NEW":
		case "PENDING":
		case "SENT_TO_ MAIN":
		case "SENT_FROM_MAIN":
		case "CREATED":
		case "WAITING_FOR_CODE_CONFIRMATION":
		case "WAITING_FOR_ALLOCATION":
		case "PARTIALLY_FILLED":
		case "WAITING_FOR_MANUALLY_CONFIRMATION":
		case "WAITING":
		case "WAITING_FOR_REVIEW":
		case "WAITING_FOR_LIQUIDITY_PROVIDER":
		case "MEDIUM":
			return "#FFFF00";
		default:
			return "#FFFFFF";
	}
}
