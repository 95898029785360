import DialogWrapper from "../../../../theme/components/dialogs/DialogWrapper";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import { deleteProvider } from "../../../../app/actions/bankingActions";

const DeleteDialog = ({ open, handleClose, provider }) => {
	const dispatch = useDispatch();

	const handleDelete = () => {
		dispatch(deleteProvider(provider));
		handleClose();
	};

	return (
		<DialogWrapper open={open} handleClose={handleClose}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				{`Delete ${provider} ?`}
			</Typography>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<IconButton onClick={handleDelete}>
					<DeleteIcon style={{ color: "red" }} />
				</IconButton>
			</div>
		</DialogWrapper>
	);
};

export default DeleteDialog;
