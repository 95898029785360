import { lighten, makeStyles } from "@material-ui/core";

export const useSettingsStyles = makeStyles(theme => ({
	head: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
		"&>.MuiTextField-root": {
			maxWidth: 256,
			[theme.breakpoints.down("sm")]: {
				marginTop: 24,
			},
		},
	},
	root: {
		marginTop: 24,
		width: "100%",
		overflowX: "auto",
		paddingBottom: 24,
	},
	table: {
		borderSpacing: 0,
		width: "100%",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		"&:first-child": {
			borderRadius: "10px 0 0 10px",
		},
		"&:last-child": {
			borderRadius: "0 10px 10px 0",
		},
	},
	th: {
		color: theme.palette.text.secondary,
		padding: "14px 8px ",
		fontSize: 12,
		lineHeight: 2,
		cursor: "pointer",
		"&>svg": {
			fontSize: 19,
			verticalAlign: "middle",
			marginRight: 4,
		},
	},
	td: {
		color: theme.palette.text.primary,
		whiteSpace: "nowrap",
		padding: "14px 8px",
		maxWidth: 300,
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontSize: 13,
		"& svg": {
			fontSize: 16,
		},
	},
	hoverTd: {
		cursor: "pointer",
		"&:hover": {
			color: theme.palette.primary.light,
		},
	},
	spacing: {
		height: 12,
	},
	hover: {
		"&:hover>td": {
			backgroundColor: lighten(theme.palette.background.paper, 0.04),
		},
	},
	currency: {
		display: "flex",
		alignItems: "center",
		height: "100%",
		"&>img": {
			marginRight: 16,
		},
	},
}));
