import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import DialogWrapper from "./DialogWrapper";
import { clearNodeDetails } from "../../../app/actions/coinsActions";

const fields = [
	{
		field: "balance",
		label: "System wallet balance",
	},
	{
		field: "balanceInUsd",
		label: "System wallet balance (USD)",
	},
	{
		field: "totalBalanceUsers",
		label: "Total balance of users",
	},
	{
		field: "balanceCompany",
		label: "Balance of the company",
	},
	{
		field: "nodeUrl",
		label: "Node url",
	},
	{
		field: "currentNodeBlock",
		label: "Current node block",
	},
	{
		field: "currentApplicationBlock",
		label: "Current application block",
	},
	{
		field: "currentNetworkBlock",
		label: "Current network block",
	},
	{
		field: "blockDifference",
		label: "Block difference",
	},
	{
		field: "syncing",
		label: "Syncing",
	},
	{
		field: "fullySynchronized",
		label: "Fully synchronized",
	},
	{
		field: "applicationConnected",
		label: "Application connected",
	},
];

const CoinDetailsDialog = ({ open, handleClose, data }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { infoNodeBlocksTron, infoNodeBlocksEthereum } = useSelector(
		({ coins }) => coins
	);

	useEffect(() => {
		return () => {
			dispatch(clearNodeDetails());
		};
	}, [dispatch]);

	const coinDetails = useMemo(() => {
		if (data?.ticker === "trx" && infoNodeBlocksTron) {
			return infoNodeBlocksTron?.map(e => Object.assign(e, data));
		}
		if (data?.ticker === "eth" && infoNodeBlocksEthereum) {
			return [...[], Object.assign(infoNodeBlocksEthereum, data)];
		}
		return [...[], data];
	}, [data, infoNodeBlocksTron, infoNodeBlocksEthereum]);

	return (
		<DialogWrapper
			open={open}
			handleClose={handleClose}
			maxW={
				coinDetails?.length === 3
					? "lg"
					: coinDetails?.length === 2
					? "md"
					: "sm"
			}>
			<Typography variant={"h4"} align={"center"} color={"textSecondary"}>
				<div className={classes.currency}>
					{data?.ticker && (
						<img
							src={`https://cryptoneed.com/icons/${data?.ticker?.toLowerCase()}.svg`}
							alt={data?.ticker}
							width={32}
							height={32}
							loading={"lazy"}
						/>
					)}
					<div>
						<p>
							{data?.name} ({data?.ticker?.toUpperCase()})
						</p>
					</div>
				</div>
			</Typography>
			{!!coinDetails && (
				<Grid container>
					{fields?.map(({ field, label }) => {
						return coinDetails[0]?.hasOwnProperty(field) ||
							coinDetails[1]?.hasOwnProperty(field) ? (
							<Grid item xs={12} key={field} align={"left"}>
								<Grid
									container
									spacing={2}
									alignItems={"center"}
									style={{ fontWeight: 500, marginBottom: 16 }}>
									<Grid
										item
										xs={
											coinDetails?.length === 3
												? 3
												: coinDetails?.length === 2
												? 4
												: 6
										}>
										<Typography variant={"body1"} color={"textPrimary"} noWrap>
											{label}
										</Typography>
									</Grid>
									{coinDetails.map((item, index) => (
										<Grid
											item
											xs={
												coinDetails?.length === 3
													? 3
													: coinDetails?.length === 2
													? 4
													: 6
											}
											key={index}>
											<Typography
												variant={"body1"}
												className={clsx(
													item?.hasOwnProperty("applicationConnected") &&
														(item.applicationConnected
															? classes.positive
															: classes.negative),
													field === "blockDifference" && classes.difference
												)}
												noWrap>
												{typeof item[field] !== "boolean" &&
												!item[field] &&
												field !== "applicationConnected"
													? "-"
													: typeof item[field] === "boolean" &&
													  field !== "applicationConnected"
													? item[field] + ""
													: field !== "applicationConnected"
													? item[field]
													: item[field]
													? "Connected"
													: "Disconnected"}
											</Typography>
										</Grid>
									))}
								</Grid>
							</Grid>
						) : null;
					})}
				</Grid>
			)}
		</DialogWrapper>
	);
};

export default CoinDetailsDialog;

const useStyles = makeStyles(theme => ({
	currency: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		"&>img": {
			marginRight: 16,
		},
	},
	positive: {
		textAlign: "right",
		color: theme.palette.text.success,
	},
	negative: {
		textAlign: "right",
		color: theme.palette.text.warning,
	},
	difference: {
		color: theme.palette.text.error,
	},
}));
