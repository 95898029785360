import moment from "moment";
import { useHistory } from "react-router-dom";
import { makeStyles, lighten } from "@material-ui/core";
import Table from "../../../theme/components/Table/Table";
import { Button } from "../../../theme/components/Button";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import getStatusColor from "../../../utils/functions/getStatusColor";
import { BANKING_STATUS } from "../../../utils/constants/banking.js";

const columns = [
	{ key: "id", headerName: "Transaction ID" },
	{ key: "uid", headerName: "User ID" },
	{ key: "cardNumber", headerName: "Card number" },
	{ key: "amount", headerName: "Amount (UAH)" },
	{ key: "transactionStatus", headerName: "Status" },
	{ key: "created", headerName: "Created" },
	{ key: "manage", headerName: "Manage", align: "center" },
];

const ApplicationsWithdrawalTable = ({ data, onMakeDecision }) => {
	const classes = useStyles();
	const history = useHistory();

	const goToUserProfile = userId => {
		history.push(`/users/list/${null}/${userId}`);
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns &&
							columns.map(({ key, headerName, colSpan, align }) => (
								<th
									key={key}
									colSpan={colSpan}
									style={{ textAlign: `${align || "left"}` }}>
									{headerName}
								</th>
							))}
					</tr>
				</thead>
				<tbody>
					{data?.length > 0 ? (
						data?.map((item, index) => (
							<tr key={index}>
								<CopyWithAlert
									text={item?.id + "" || ""}
									label={"Transaction ID"}>
									<td data='hover'>{item?.id}</td>
								</CopyWithAlert>
								<td data='hover' onClick={() => goToUserProfile(item?.uid)}>
									{item?.uid}
								</td>
								<CopyWithAlert
									text={item?.address + "" || ""}
									label={"Card number"}>
									<td data='hover'>{item?.address}</td>
								</CopyWithAlert>
								<CopyWithAlert
									text={item?.amount + "" || ""}
									label={"Amount (UAH)"}>
									<td data='hover'>{item?.amount}</td>
								</CopyWithAlert>
								<td
									style={{
										color: getStatusColor(item?.transactionStatus),
									}}>
									{item?.transactionStatus || "-"}
								</td>
								<td>
									{item?.create
										? moment(item?.create).format("DD MMM YYYY HH:mm")
										: "-"}
								</td>

								<td>
									<div className={classes.btnWrap}>
										<Button
											variant='contained'
											color='primary'
											className={classes.declineBtn}
											onClick={() =>
												onMakeDecision(item.id, BANKING_STATUS.REJECT)
											}>
											Decline
										</Button>
										<Button
											className={classes.approveBtn}
											variant='contained'
											onClick={() =>
												onMakeDecision(item.id, BANKING_STATUS.COMPLETE)
											}>
											Approve
										</Button>
									</div>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</>
	);
};

export default ApplicationsWithdrawalTable;

const useStyles = makeStyles(theme => ({
	btnWrap: {
		display: "flex",
		justifyContent: "space-around",
	},
	declineBtn: {
		background: theme.palette.error.main,
		minWidth: 100,
		minHeight: 30,
		padding: "4px 12px",
		"&:hover": {
			background: lighten(theme.palette.error.main, 0.4),
		},
	},
	approveBtn: {
		background: theme.palette.success.main,
		minWidth: 100,
		minHeight: 30,
		padding: "4px 12px",
		"&:hover": {
			background: lighten(theme.palette.success.main, 0.4),
		},
	},
	tab: {
		paddingTop: theme.spacing(3),
	},
}));
