import { FormControl, makeStyles, TextField } from "@material-ui/core";
import propTypes from "prop-types";
import clsx from "clsx";

export const SelectInput = ({ children, label, status, ...props }) => {
	const classes = useStyles();

	return (
		<FormControl className={classes.root}>
			<TextField
				select
				variant='outlined'
				{...props}
				label={label}
				className={clsx({
					[classes.active]: typeof status === "boolean" && !status,
					[classes.notActive]: typeof status === "boolean" && status,
				})}>
				{children}
			</TextField>
		</FormControl>
	);
};

SelectInput.propTypes = {
	value: propTypes.any,
	onChange: propTypes.func.isRequired,
	label: propTypes.string,
	style: propTypes.object,
};

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		"& .MuiMenu-paper": {
			maxHeight: "350px !important",
		},

		"& label, & label.Mui-focused:not(.Mui-error)": {
			color: "#fff",
		},
		"& .MuiInputLabel-root:not(.Mui-error)": {
			color: "#fff",
		},

		"& .MuiOutlinedInput-root": {
			fontSize: 14,
			"&>input": {
				color: "#fff",
				borderRadius: 8,
				fontSize: 14,
				lineHeight: 16,
				[theme.breakpoints.down("xs")]: {
					lineHeight: 14,
					paddingTop: 12,
					paddingBottom: 12,
				},
				"&::placeholder": {
					opacity: 1,
					color: theme.palette.text.secondary,
				},
			},
			"& fieldset, &:hover fieldset, &.Mui-focused fieldset": {
				borderRadius: 8,
				borderWidth: 1,
			},
			"&:not(.Mui-error) fieldset, &:not(.Mui-error) :hover fieldset, &:not(.Mui-error).Mui-focused fieldset":
				{
					borderColor: "#454B54",
				},
		},
		"& .MuiFormHelperText-root": {
			fontSize: 10,
			position: "absolute",
			marginTop: 54,
			fontWeight: 300,
			lineHeight: "100%",
		},
	},
	active: {
		"& .MuiOutlinedInput-root": {
			color: "#00ff00",
		},
	},
	notActive: {
		"& .MuiOutlinedInput-root": {
			color: "#ff0000",
		},
	},
}));
