import Tabs from '@material-ui/core/Tabs';
import {withStyles} from "@material-ui/core";

export const StyledTabs = withStyles((theme) => ({
	root: {
		'& .MuiTab-root': {
			textTransform: 'uppercase',
			color: theme.palette.common.white,
			padding: '6px 12px',
			minWidth: 10,
			marginRight: 32,
			fontSize: 16,
			lineHeight: 1,
			minHeight: 32,
			fontWeight: 400,
			[theme.breakpoints.down('sm')]: {
				marginRight: 16,
				marginLeft: 16,
			},
			[theme.breakpoints.down('xs')]: {
				marginRight: 12,
				marginLeft: 12,
				fontSize: 14,
			},

			'&.Mui-disabled': {
				color: theme.palette.text.secondary,
			}
		}
	},
	indicator: {
		backgroundColor: '#18A0FB',
		bottom: 'inherit',
	}
}))(Tabs);
