import {
	CLEAR_NODE_DETAILS,
	GET_NODE_DETAILS_STARTED,
	GET_NODE_DETAILS_SUCCESS,
	GET_NODE_DETAILS_FAILURE,
	GET_WALLETS_TOTAL_STARTED,
	GET_WALLETS_TOTAL_SUCCESS,
	GET_WALLETS_TOTAL_FAILURE,
	GET_INFO_NODE_BLOCKS_TRON_STARTED,
	GET_INFO_NODE_BLOCKS_TRON_SUCCESS,
	GET_INFO_NODE_BLOCKS_TRON_FAILURE,
	GET_INFO_NODE_BLOCKS_ETHEREUM_STARTED,
	GET_INFO_NODE_BLOCKS_ETHEREUM_SUCCESS,
	GET_INFO_NODE_BLOCKS_ETHEREUM_FAILURE,
} from "../types";
import api from "../api";

export const getNodeDetailsStarted = () => ({
	type: GET_NODE_DETAILS_STARTED,
});

export const getNodeDetailsSuccess = (data, total) => ({
	type: GET_NODE_DETAILS_SUCCESS,
	payload: { data, total },
});

export const getNodeDetailsFailure = error => ({
	type: GET_NODE_DETAILS_FAILURE,
	payload: {
		error,
	},
});

export const clearNodeDetails = () => ({
	type: CLEAR_NODE_DETAILS,
});

export const getNodeDetails = params => {
	return async dispatch => {
		dispatch(getNodeDetailsStarted());
		api.finances.coins
			.getNodeDetails(params)
			.then(res => dispatch(getNodeDetailsSuccess(res.data)))
			.catch(error => dispatch(getNodeDetailsFailure(error)));
	};
};

export const getWalletsTotalStarted = () => ({
	type: GET_WALLETS_TOTAL_STARTED,
});

export const getWalletsTotalSuccess = data => ({
	type: GET_WALLETS_TOTAL_SUCCESS,
	payload: { data },
});

export const getWalletsTotalFailure = error => ({
	type: GET_WALLETS_TOTAL_FAILURE,
	payload: {
		error,
	},
});

export const getWalletsTotal = () => {
	return async dispatch => {
		dispatch(getWalletsTotalStarted());
		api.finances.coins
			.getWalletsTotal()
			.then(res => dispatch(getWalletsTotalSuccess(res.data)))
			.catch(error => dispatch(getWalletsTotalFailure(error)));
	};
};

export const getInfoNodeBlocksTronStarted = () => ({
	type: GET_INFO_NODE_BLOCKS_TRON_STARTED,
});

export const getInfoNodeBlocksTronSuccess = data => ({
	type: GET_INFO_NODE_BLOCKS_TRON_SUCCESS,
	payload: { data },
});

export const getInfoNodeBlocksTronFailure = error => ({
	type: GET_INFO_NODE_BLOCKS_TRON_FAILURE,
	payload: {
		error,
	},
});

export const getInfoNodeBlocksTron = () => {
	return async dispatch => {
		dispatch(getInfoNodeBlocksTronStarted());
		api.finances.coins
			.getInfoNodeBlocksTron()
			.then(res => dispatch(getInfoNodeBlocksTronSuccess(res.data)))
			.catch(error => dispatch(getInfoNodeBlocksTronFailure(error)));
	};
};

// getInfoNodeBlocksEthereum
export const getInfoNodeBlocksEthereumStarted = () => ({
	type: GET_INFO_NODE_BLOCKS_ETHEREUM_STARTED,
});

export const getInfoNodeBlocksEthereumSuccess = data => ({
	type: GET_INFO_NODE_BLOCKS_ETHEREUM_SUCCESS,
	payload: { data },
});

export const getInfoNodeBlocksEthereumFailure = error => ({
	type: GET_INFO_NODE_BLOCKS_ETHEREUM_FAILURE,
	payload: {
		error,
	},
});

export const getInfoNodeBlocksEthereum = () => {
	return async dispatch => {
		dispatch(getInfoNodeBlocksEthereumStarted());
		api.finances.coins
			.getInfoNodeBlocksEthereum()
			.then(res => dispatch(getInfoNodeBlocksEthereumSuccess(res.data)))
			.catch(error => dispatch(getInfoNodeBlocksEthereumFailure(error)));
	};
};
