import DialogWrapper from "../../../../theme/components/dialogs/DialogWrapper";
import { Grid, Typography, InputAdornment, ButtonBase } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Input } from "../../../../theme/components/Input";
import { Button } from "../../../../theme/components/Button";
import { useEffect, useState, useCallback } from "react";
import {
	updateBankingSettings,
} from "../../../../app/actions/bankingActions";
import { requiredValidator } from "../../../../utils/customValidators/validators";

const formFields = [
	{ field: "price", type: "text", label: "Price" },
	{ field: "priceForLiquidityProviders", type: "text", label: "Price for Liquidity Providers" },
];

const EditPairSettingsDialog = ({ open, handleClose, data, params }) => {
	const dispatch = useDispatch();
	const [errors, setErrors] = useState({});
	const [state, setState] = useState(null);
	const createState = useCallback(() => {
		let form = {};
		formFields.forEach(item => {
			form[item.field] =
				item.type === "text" ? "" : item.type === "number" ? 0 : false;
		});
		return form;
	}, []);
	const closeDialog = useCallback(() => {
		setState(createState());
		setErrors({});
		handleClose();
	}, [createState, handleClose]);

	useEffect(() => {
		data ? setState(data) : setState(createState());
	}, [dispatch, data, createState]);

	const handleChange = ({ target: { name, value, type } }) => {
		handleInputValidation(name, value);
		if (/^[0-9]*\.?[0-9]*$/.test(value) &&
			!(value.length > 1 && value[0] === "0" && value[1] !== ".")) {
			setState({ ...state, [name]: value });
		}
	};

	const handleUpdate = () => {
		if (!validateForm()) {
			return;
		}
		// console.log('submit ', { pair: data?.pair, price: state.price, priceForLiquidityProviders: state.priceForLiquidityProviders})
		dispatch(updateBankingSettings({ pair: data?.pair, price: state.price, priceForLiquidityProviders: state.priceForLiquidityProviders })).then(_ => {
			setState(createState());
			handleClose();
		})
	};

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(state).forEach(key => {
			errForm = {
				...errForm,
				[key]: requiredValidator(undefined, state[key]),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]: requiredValidator(undefined, value),
		});
	};

	const handleOnBlur = ({ target: { value, name } }) => {
		!state?.[name] && handleInputValidation(name, value)
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={600}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				{`Edit Settings for: ${data?.pair}`}
			</Typography>
			<Grid container spacing={3} justifyContent="center">
				<Grid item xs={12} sm={8}>
					<Input
						label={"Price"}
						variant={"outlined"}
						type={"text"}
						name={"price"}
						value={state?.price || ""}
						error={errors.price?.invalid}
						helperText={errors.price?.errorMessage}
						onChange={handleChange}
						onBlur={handleOnBlur}
						InputProps={{
							autoComplete: "off",
							endAdornment: (
								<InputAdornment position='end'>
									<ButtonBase disabled>
										{data?.pair?.toUpperCase()}
									</ButtonBase>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Input
						label={"Price for Liquidity Providers"}
						variant={"outlined"}
						type={"text"}
						name={"priceForLiquidityProviders"}
						value={state?.priceForLiquidityProviders || ""}
						error={errors.priceForLiquidityProviders?.invalid}
						helperText={errors.priceForLiquidityProviders?.errorMessage}
						onChange={handleChange}
						onBlur={handleOnBlur}
						InputProps={{
							autoComplete: "off",
							endAdornment: (
								<InputAdornment position='end'>
									<ButtonBase disabled>
										{data?.pair?.toUpperCase()}
									</ButtonBase>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)" }}
							onClick={handleUpdate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default EditPairSettingsDialog;
