import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, IconButton, Typography, Paper } from "@material-ui/core";
import Table from "../../../../theme/components/Table/Table";
import DeleteConfirmDialog from "../../../../theme/components/dialogs/DeleteConfirmDialog";
import CopyWithAlert from "../../../../utils/CopyWithAlert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import BackIcon from "@material-ui/icons/KeyboardArrowLeftRounded";
import AddIcon from "@material-ui/icons/Add";
import {
	deleteIndexAsset,
	getIndexAssetsListByIndexId
} from "../../../../app/actions/indexesCapitalActions";
import CreateAssetDialog from "../Assets/components/CreateAssetDialog";
import { Button } from "../../../../theme/components/Button";

const columns = [
	{ key: "id", label: "Id" },
	{ key: "indexId", label: "Index Id" },
	{ key: "token", label: "token" },
	{ key: "balance", label: "Balance" },
	{ key: "usdRate", label: "USD Rate" },
	{ key: "usdBalance", label: "USD Balance" },
	{ key: "actions", label: "Actions", align: 'center' }
];

const ManageIndexAssets = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const routeParams = useParams();
	const [showConfirmDialog, setShowConfirmDialog] = useState(null);
	const [showEditDialog, setShowEditDialog] = useState(null);
	const [showCreateDialog, setShowCreateDialog] = useState(false);

	const indexAssetsList = useSelector(
		({ indexes }) => indexes.indexAssetsList
	);

	console.log(indexAssetsList)

	const classes = useStyles();

	const getAssetsData = useCallback(() => {
		dispatch(getIndexAssetsListByIndexId(routeParams?.indexId));
		// eslint-disable-next-line
	}, [dispatch]);

	useEffect(() => {
		getAssetsData();
	}, [getAssetsData]);

	const handleDeleteAsset = ({ accept }) => {
		if (accept) {
			dispatch(deleteIndexAsset({ assetId: showConfirmDialog?.id, indexId: routeParams?.indexId }));
		}
		setShowConfirmDialog(null);
	};

	const goToUserProfile = (userId) => {
		history.push(`/users/list/${null}/${userId}`);
	};

	const goBack = () => {
		history.push(history?.location?.state?.from || `/capital/indexes`, {
			from: "manageIndexAssets"
		});
	};

	const handleCreateAsset = () => {
		setShowCreateDialog({ id: routeParams.indexId })
	}

	const handleCloseCreateDialog = useCallback(() => {
		setShowEditDialog(null)
		setShowCreateDialog(null)
	}, [])

	return (
		<div className={classes.root}>
			<DeleteConfirmDialog
				open={Boolean(showConfirmDialog)}
				title='Confirm decision'
				message={`Are you sure you want to delete selected additional asset - ${showConfirmDialog?.token}?`}
				handleClose={handleDeleteAsset}
			/>
			<CreateAssetDialog
				open={Boolean(showEditDialog) || Boolean(showCreateDialog)}
				handleClose={handleCloseCreateDialog}
				params={showEditDialog || showCreateDialog}
				data={showEditDialog}
				isFromManagePage={true}
			/>
			<div className={classes.header}>
				<Paper elevation={0} className={classes.paper} onClick={goBack}>
					<IconButton className={classes.icon} size={"small"}>
						<BackIcon />
					</IconButton>
					<Typography variant={"subtitle2"} color={"textSecondary"}>
						Back to{" "}
						<span className={classes.link}>
							{history?.location?.state?.title || "Indexes"}
						</span>
					</Typography>
				</Paper>
				<Button className={classes.btn} variant='outlined' color='primary' onClick={handleCreateAsset}>
					<AddIcon />
					Add Asset
				</Button>
			</div>
			<Typography variant={"h4"} color={"textPrimary"}>
				Manage additional assets
			</Typography>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, label, align }) => (
							<th key={key} style={{ textAlign: `${align || "left"}` }}>
								{label}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{indexAssetsList?.length > 0 ? (
						indexAssetsList?.map((rowData, index) => (
							<TableRow
								key={rowData?.id || index}
								rowData={rowData}
								onDelete={() => setShowConfirmDialog(rowData)}
								onEdit={() => setShowEditDialog(rowData)}
								goToUserProfile={goToUserProfile}
							/>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
		</div>
	);
};

export default ManageIndexAssets;

function TableRow({ rowData, goToUserProfile, onDelete, onEdit }) {
	const classes = useStyles();
	return (
		<>
			<tr>
				<CopyWithAlert text={rowData?.id + "" || ""} label={"Id"}>
					<td data='hover'>{rowData?.id}</td>
				</CopyWithAlert>
				<td data='hover' onClick={() => goToUserProfile(rowData?.userId)}>
					{rowData?.indexId}
				</td>
				<CopyWithAlert text={rowData?.token + "" || ""} label={"Token"}>
					<td data='hover'>{rowData?.token}</td>
				</CopyWithAlert>
				<td>{+rowData?.balance}</td>
				<td>{+rowData?.usdRate}</td>
				<td>{+rowData?.usdBalance}</td>
				<td>
					<div className={classes.btnWrap}>
						<IconButton onClick={onEdit}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={onDelete}>
							<DeleteIcon style={{ color: "#ff0000" }} />
						</IconButton>
					</div>
				</td>
			</tr>
		</>
	);
}

export const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		background: theme.palette.background.default,
		boxShadow: "none",
	},
	paper: {
		display: "flex",
		alignItems: "center",
		paddingRight: 12,
		borderRadius: 8,
		width: "max-content",
		marginBottom: 16,
		"&:hover": {
			cursor: "pointer",
		},
	},
	header: {
		display: "flex",
		gap: 20,
		justifyContent: 'space-between',
		alignItems: 'baseline',
	},
	btn: {
		minWidth: 100,
		minHeight: 30,
		padding: "4px 12px",
	},
}));
