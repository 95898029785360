import DialogWrapper from "../../theme/components/dialogs/DialogWrapper";
import { Grid, Typography, makeStyles, MenuItem } from "@material-ui/core";
import { Input } from "../../theme/components/Input";
import { Button } from "../../theme/components/Button";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "../../app/actions/usersActions";
import { createAdmin } from "../../app/actions/adminsActions";
import { validateInput } from "../../utils/customValidators/validators";
import { SelectInput } from "../../theme/components/Select";

const initialForm = {
	firstName: "",
	lastName: "",
	email: "",
	authorities: "",
	imageUrl: "",
	activated: true,
};

const CreateAdminDialog = ({ open, handleClose }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const roles = useSelector(({ users }) => users.roles);
	const [form, setForm] = useState(initialForm);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		dispatch(getRoles());
	}, [dispatch]);

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach(key => {
			errForm = { ...errForm, [key]: validateInput(key, form[key]) };
			errForm[key].invalid && (valid = false);
		});

		setErrors(errForm);
		return valid;
	};

	const handleChange = event => {
		event.preventDefault();
		const { name, value } = event.target;
		setForm({ ...form, [name]: value });
		handleInputValidation(event);
	};

	const handleCreate = () => {
		if (!validateForm()) {
			return;
		}
		let newAdmin = form;
		newAdmin.authorities = [...[], newAdmin.authorities];
		newAdmin.login = form.email;
		dispatch(createAdmin(newAdmin));
		handleCloseDialog();
	};

	const handleInputValidation = event => {
		const { name, value } = event.target;
		const errorField = validateInput(name, value);
		setErrors({
			...errors,
			[name]: errorField,
		});
	};

	const handleCloseDialog = () => {
		setErrors({});
		setForm(initialForm);
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={handleCloseDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Typography
				variant={"h6"}
				color={"textPrimary"}
				style={{ marginBottom: 32 }}>
				Create admin
			</Typography>
			<Grid container spacing={3}>
				<Grid item xs={6} className={classes.formCroup}>
					<Input
						type='text'
						error={errors?.firstName?.invalid}
						label='First name'
						variant={"outlined"}
						name={"firstName"}
						value={form?.firstName || ""}
						onChange={handleChange}
						onBlur={handleInputValidation}
						helperText={errors?.firstName?.errorMessage}
					/>
				</Grid>
				<Grid item xs={6} className={classes.formCroup}>
					<Input
						type='text'
						error={errors?.lastName?.invalid}
						label='Last name'
						variant={"outlined"}
						name={"lastName"}
						value={form?.lastName || ""}
						onChange={handleChange}
						onBlur={handleInputValidation}
						helperText={errors?.lastName?.errorMessage}
					/>
				</Grid>
				<Grid item xs={6} className={classes.formCroup}>
					<Input
						error={errors?.email?.invalid}
						label='Email'
						variant={"outlined"}
						type={"email"}
						name={"email"}
						value={form?.email || ""}
						onChange={handleChange}
						onBlur={handleInputValidation}
						helperText={errors?.email?.errorMessage}
					/>
				</Grid>
				<Grid item xs={6}>
					{roles && (
						<SelectInput
							error={errors?.authorities?.invalid}
							label='Role'
							onChange={handleChange}
							value={form?.authorities || ""}
							name={"authorities"}
							onBlur={handleInputValidation}
							helperText={errors?.authorities?.errorMessage}>
							{roles.map((role, index) =>
								role !== "ROLE_USER" ? (
									<MenuItem key={index} value={role}>
										{role}
									</MenuItem>
								) : null
							)}
						</SelectInput>
					)}
				</Grid>
				<Grid item xs={12}>
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button
							color={"primary"}
							style={{ width: "max(50%, 50px)", marginTop: 12 }}
							onClick={handleCreate}>
							Save
						</Button>
					</div>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default CreateAdminDialog;

const useStyles = makeStyles(theme => ({
	formCroup: {
		display: "flex",
		flexDirection: "column",
	},
	errorField: {
		color: theme.palette.text.error,
		fontSize: 12,
		position: "absolute",
		marginTop: 56,
	},
	success: {
		color: theme.palette.text.success,
	},
	error: {
		color: theme.palette.text.error,
	},
}));
