import React, { useEffect, useMemo, useState } from "react";
import { makeStyles, Grid, MenuItem, Divider, FormControlLabel, Checkbox } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { editAdmin } from "../../app/actions/adminsActions";
import { Input } from "../../theme/components/Input";
import { Button } from "../../theme/components/Button";
import { SelectInput } from "../../theme/components/Select";
import { validateInput } from "../../utils/customValidators/validators";
import { addUserVipStatus, deleteUserVipStatus } from "../../app/actions/usersActions";

const status = {
	active: false,
	block: true,
};

const initialForm = {
	id: "",
	login: "",
	firstName: "",
	lastName: "",
	email: "",
	authority: "",
	createdBy: "",
	createdDate: "",
	lastModifiedBy: "",
	lastModifiedDate: "",
};

const initialBlockForm = {
	id: null,
	exchangeblk: false,
	accountblk: false,
	withdrawalblk: false,
	depositblk: false,
};

const AdminProfile = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const profile = useSelector(({ users }) => users.profile);
	const blockData = useSelector(({ blocking }) => blocking.userBlockers);
	const roles = useSelector(({ users }) => users.roles);
	const [isChangeForm, setIsChangeForm] = useState(false);
	const [form, setForm] = useState(initialForm);
	const [blockForm, setBlockForm] = useState(initialBlockForm);
	const [errors, setErrors] = useState({});

	const rolesOptions = useMemo(() => {
		return roles.map((role, index) =>
			role !== "ROLE_USER" ? (
				<MenuItem key={index} value={role}>
					{role}
				</MenuItem>
			) : null
		)
	}, [roles])

	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach(key => {
			errForm = { ...errForm, [key]: validateInput(key, form[key]) };
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	useEffect(() => {
		setForm(profile);
	}, [profile]);

	useEffect(() => {
		setBlockForm(blockData);
	}, [blockData]);

	const handleChange = event => {
		event.preventDefault();
		const { name, value } = event.target;
		setIsChangeForm(true);
		setForm({ ...form, [name]: value });
		handleInputValidation(event);
	};

	const handleSelect = event => {
		event.preventDefault();
		const { name, value } = event.target;
		setIsChangeForm(true);
		setBlockForm({ ...blockForm, [name]: value });
	};

	const handleUpdate = () => {
		if (!validateForm()) {
			return;
		}
		dispatch(editAdmin({ ...form, ...blockForm }));
		setIsChangeForm(false);
	};

	const handleInputValidation = event => {
		const { name, value } = event.target;
		const { invalid, errorMessage } = validateInput(name, value);
		setErrors({
			...errors,
			[name]: { invalid: invalid, errorMessage: errorMessage },
		});
	};

	const handleChangeVIPStatus = (e, v) => {
		if (!profile?.id) {
			return;
		}
		if (!profile?.vip) {
			dispatch(addUserVipStatus(profile?.id))
		} else {
			dispatch(deleteUserVipStatus(profile?.id))
		}
	}

	if (!profile) {
		return <div>ADMIN NOT FOUND!!!</div>;
	}

	return (
		<div style={{ width: "100%" }}>
			<Grid container spacing={3} style={{ paddingBottom: 36 }}>
				<Grid item xs={4}>
					<Input
						label='Id'
						variant={"outlined"}
						name={"id"}
						value={form?.id || ""}
						disabled
					/>
				</Grid>
				{/* <Grid item xs={4}>
					<Input
						label='Role'
						variant={"outlined"}
						name={"authority"}
						value={form?.authority || ""}
						disabled
					/>
				</Grid> */}
				<Grid item xs={4}>
					<Input
						label='Created by'
						variant={"outlined"}
						name={"withdrawFee"}
						value={form?.createdBy || ""}
						disabled
					/>
				</Grid>
				<Grid item xs={4}>
					<Input
						label='Created date'
						variant={"outlined"}
						name={"createDate"}
						value={
							form?.createdDate
								? moment(form?.createdDate).format("DD MMM YYYY HH:mm")
								: ""
						}
						disabled
					/>
				</Grid>
			</Grid>
			<Divider className={classes.divider} />
			<Grid container spacing={3} style={{ paddingTop: 36, paddingBottom: 36 }}>
				<Grid item xs={4} className={classes.formCroup}>
					<Input
						type='text'
						error={errors?.firstName?.invalid}
						helperText={errors?.firstName?.errorMessage || ""}
						label='First name'
						variant={"outlined"}
						name={"firstName"}
						value={form?.firstName || ""}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={4} className={classes.formCroup}>
					<Input
						type='text'
						error={errors?.lastName?.invalid}
						helperText={errors?.lastName?.errorMessage}
						label='Last name'
						variant={"outlined"}
						name={"lastName"}
						value={form?.lastName || ""}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={4} className={classes.formCroup}>
					<Input
						type='text'
						error={errors?.login?.invalid}
						helperText={errors?.login?.errorMessage}
						label='Login'
						variant={"outlined"}
						name={"login"}
						value={form?.login || ""}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={4} className={classes.formCroup}>
					<Input
						error={errors?.email?.invalid}
						helperText={errors?.email?.errorMessage}
						label='Email'
						variant={"outlined"}
						type={"email"}
						name={"email"}
						value={form?.email || ""}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={4} className={classes.formCroup}>
					<SelectInput
						label='Role'
						onChange={handleChange}
						value={form?.authority || false}
						name={"authority"}>
						{rolesOptions}
					</SelectInput>
				</Grid>
				<Grid item xs={4}>
					<FormControlLabel
						control={
							<Checkbox
								checked={Boolean(profile?.vip)}
								color={"primary"}
								onChange={handleChangeVIPStatus}
							/>
						}
						label='VIP'
					/>
				</Grid>
			</Grid>
			<Divider className={classes.divider} />
			<Grid container spacing={3} style={{ paddingTop: 36, paddingBottom: 36 }}>
				<Grid item xs={4}>
					<SelectInput
						label='Account activity'
						onChange={handleSelect}
						value={blockForm?.accountblk || false}
						name={"accountblk"}
						status={blockForm?.accountblk}>
						<MenuItem value={status.block} className={classes.error}>
							Block
						</MenuItem>
						<MenuItem value={status.active} className={classes.success}>
							Unblock
						</MenuItem>
					</SelectInput>
				</Grid>
				<Grid item xs={4}>
					<SelectInput
						label='Exchange activity'
						onChange={handleSelect}
						value={blockForm?.exchangeblk || false}
						name={"exchangeblk"}
						status={blockForm?.exchangeblk}>
						<MenuItem value={status.block} className={classes.error}>
							Block
						</MenuItem>
						<MenuItem value={status.active} className={classes.success}>
							Unblock
						</MenuItem>
					</SelectInput>
				</Grid>
				<Grid item xs={4}>
					<SelectInput
						label='Withdrawal activity'
						onChange={handleSelect}
						value={blockForm?.withdrawalblk || false}
						name={"withdrawalblk"}
						status={blockForm?.withdrawalblk}>
						<MenuItem value={status.block} className={classes.error}>
							Block
						</MenuItem>
						<MenuItem value={status.active} className={classes.success}>
							Unblock
						</MenuItem>
					</SelectInput>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					{isChangeForm ? (
						<div style={{ display: "flex", justifyContent: "center" }}>
							<Button
								color={"primary"}
								style={{ width: "max(33%, 50px)", marginTop: 12 }}
								onClick={handleUpdate}>
								Save
							</Button>
						</div>
					) : null}
				</Grid>
			</Grid>
		</div>
	);
};

export default AdminProfile;

const useStyles = makeStyles(theme => ({
	formCroup: {
		display: "flex",
		flexDirection: "column",
	},
	errorField: {
		color: theme.palette.text.error,
		fontSize: 12,
		position: "absolute",
		marginTop: 56,
	},
	divider: {
		height: 3,
	},
	success: {
		color: "#00ff00",
	},
	error: {
		color: "#ff0000",
	},
}));
