import { useState } from "react";
import moment from "moment";
import clsx from "clsx";
import Table from "../../../theme/components/Table/Table";
import { TablePagination } from "@material-ui/core";
import CopyWithAlert from "../../../utils/CopyWithAlert";
import getStatusColor from "../../../utils/functions/getStatusColor";
import AnyIcon from "@material-ui/icons/UnfoldMoreRounded";
import DownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import TopIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import { SORT_TYPES, next } from "../../../utils/constants/sortTypes.js";

const FiatWithdrawalsTable = ({
	withdrawals,
	params,
	total,
	onPageChange,
	onRowsPerPageChange,
	onSorting,
}) => {
	const [columns, setColumns] = useState([
		{ key: "id", headerName: "Id" },
		{ key: "withdrawalId", headerName: "Withdrawal Id" },
		{ key: "cardNumber", headerName: "Card Number" },
		{ key: "userId", headerName: "User ID" },
		{ key: "currency", headerName: "Currency" },
		{ key: "amount", headerName: "Amount", sort: SORT_TYPES.none },
		{ key: "fee", headerName: "Fee" },
		{ key: "totalAmount", headerName: "Total Amount" },
		{ key: "createdAt", headerName: "Created", sort: SORT_TYPES.down },
		{ key: "transactionId", headerName: "Transaction Id" },
		{ key: "status", headerName: "Status" },
		{ key: "sn", headerName: "Unique transaction number" },
	]);

	const sorting = key => {
		const nextState = next(columns.find(s => s.key === key)?.sort);
		onSorting({ sort: `${key},${nextState}` });
		setColumns(
			columns.map(s =>
				s.key === key
					? { ...s, sort: nextState }
					: {
							...s,
							sort: s.sort ? SORT_TYPES.none : undefined,
					  }
			)
		);
	};

	return (
		<>
			<Table>
				<thead>
					<tr>
						{columns.map(({ key, headerName, sort, colSpan, align }) => (
							<th
								key={key}
								className={clsx(sort && "hover")}
								colSpan={colSpan}
								align={align || "left"}
								onClick={() => sort && sorting(key)}>
								{sort === SORT_TYPES.up && <TopIcon />}
								{sort === SORT_TYPES.none && <AnyIcon />}
								{sort === SORT_TYPES.down && <DownIcon />}
								{headerName}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{withdrawals.length > 0 ? (
						withdrawals.map((data, index) => (
							<tr key={index}>
								<CopyWithAlert text={data?.id + "" || ""} label={"Id"}>
									<td data='hover'>{data?.id}</td>
								</CopyWithAlert>
								<CopyWithAlert
									text={data?.withdrawalId + "" || ""}
									label={"Withdrawal Id"}>
									<td data='hover'>{data?.withdrawalId}</td>
								</CopyWithAlert>
								<CopyWithAlert
									text={data?.cardNumber + "" || ""}
									label={"Card number"}>
									<td data='hover'>
										{data?.cardNumber &&
											`${data?.cardNumber.slice(0, 4)} ${data?.cardNumber.slice(
												4,
												8
											)} ${data?.cardNumber.slice(
												8,
												12
											)} ${data?.cardNumber.slice(12, Infinity)}`}
									</td>
								</CopyWithAlert>
								<CopyWithAlert text={data?.userId + "" || ""} label={"User ID"}>
									<td data='hover'>{data?.userId}</td>
								</CopyWithAlert>
								<td>{data?.currency?.toUpperCase()}</td>
								<td>{+data?.amount || "-"} </td>
								<td>{+data?.fee || "-"} </td>
								<td>{+data?.totalAmount || "-"} </td>
								<td>
									{data?.createdAt &&
										moment(data?.createdAt).format("DD MMM YYYY HH:mm")}
								</td>
								<CopyWithAlert
									text={data?.transactionId + "" || ""}
									label={"Transaction Id"}>
									<td data='hover'>{data?.transactionId || "-"}</td>
								</CopyWithAlert>
								<td
									style={{
										color: getStatusColor(data?.status),
									}}>
									{data?.status || "-"}
								</td>
								<CopyWithAlert
									text={data?.sn + "" || ""}
									label={"Unique transaction number"}>
									<td data='hover'>{data?.sn || "-"}</td>
								</CopyWithAlert>
							</tr>
						))
					) : (
						<tr>
							<td colSpan={8} align={"center"} height={100}>
								The list is empty...
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			<TablePagination
				rowsPerPageOptions={[10, 30, 50]}
				component='div'
				count={total}
				rowsPerPage={params?.find(x => x.field === "size").value}
				page={params?.find(x => x.field === "page").value}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
			/>
		</>
	);
};

export default FiatWithdrawalsTable;
