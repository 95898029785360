import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Grid,
	Typography,
	makeStyles,
	ButtonBase,
	InputAdornment,
} from "@material-ui/core";
import { Input } from "../../../../theme/components/Input";
import { Button } from "../../../../theme/components/Button";
import DialogWrapper from "../../../../theme/components/dialogs/DialogWrapper";
import {
	updateAllBankingBalances,
	clearBankingStatus,
} from "../../../../app/actions/bankingActions";
import { requiredValidator } from "../../../../utils/customValidators/validators";

const initialForm = {
	assetTotalBalance: 0,
	quotableTotalBalance: 0,
};

const EditBalanceDialog = ({ open, handleClose, data = null }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [errors, setErrors] = useState({});
	const [form, setForm] = useState(initialForm);
	const { settings, status } = useSelector(({ banking }) => banking);

	useEffect(() => {
		if (!settings && !data) {
			return;
		}
		setForm({
			assetTotalBalance: data?.assetTotalBalance || 0,
			quotableTotalBalance: data?.quotableTotalBalance || 0,
		});
	}, [dispatch, settings, data]);

	useEffect(() => {
		if (status) {
			closeDialog();
			dispatch(clearBankingStatus());
		}
		// eslint-disable-next-line
	}, [status]);

	const handleUpdate = () => {
		if (!validateForm()) {
			return;
		}
		if ((form?.assetTotalBalance && (data?.assetTotalBalance !== form?.assetTotalBalance)) ||
			(form?.quotableTotalBalance && (data?.quotableTotalBalance !== form?.quotableTotalBalance))) {
			dispatch(
				updateAllBankingBalances({
					id: data?.id,
					assetTotalBalance: form?.assetTotalBalance,
					quotableTotalBalance: form?.quotableTotalBalance,
				})
			);
		}
	};
	const validateForm = () => {
		let valid = true;
		let errForm = {};
		Object.keys(form).forEach(key => {
			errForm = {
				...errForm,
				[key]: requiredValidator(undefined, form[key]),
			};
			errForm[key].invalid && (valid = false);
		});
		setErrors(errForm);
		return valid;
	};

	const handleInputValidation = (name, value) => {
		setErrors({
			...errors,
			[name]: requiredValidator(undefined, value),
		});
	};

	const handleChange = (value, name) => {
		handleInputValidation(name, value);
		setForm({ ...form, [name]: value });
	};

	const handleInputChange = ({ target: { value, name } }) => {
		return (
			/^[0-9]*\.?[0-9]*$/.test(value) &&
			!(value.length > 1 && value[0] === "0" && value[1] !== ".") &&
			handleChange(value, name)
		)
	}

	const closeDialog = () => {
		setForm(initialForm);
		setErrors({});
		handleClose();
	};

	return (
		<DialogWrapper
			open={open}
			handleClose={closeDialog}
			maxWidth={720}
			maxW={"lg"}>
			<Grid container justifyContent='space-between'>
				<Typography
					variant={"h6"}
					color={"textPrimary"}
					style={{ marginBottom: 32 }}>
					Edit balances
				</Typography>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6} className={classes.formCroup}>
					<Input
						className={classes.input}
						label={`Asset Total Balance ${data?.asset}`}
						variant='outlined'
						name={"assetTotalBalance"}
						value={form?.assetTotalBalance}
						onChange={handleInputChange}
						onFocus={() =>
							!form?.assetTotalBalance &&
							setForm({
								...form,
								assetTotalBalance: "",
							})
						}
						onBlur={({ target: { value, name } }) =>
							!form?.assetTotalBalance && handleInputValidation(name, value)
						}
						error={errors?.assetTotalBalance?.invalid}
						helperText={errors?.assetTotalBalance?.errorMessage}
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							autoComplete: "off",
							endAdornment: (
								<InputAdornment position='end'>
									<ButtonBase disabled>
										<img
											src={`https://cryptoneed.com/icons/${data?.asset}.svg`}
											alt={data?.asset}
											width={24}
											height={24}
											loading={"lazy"}
										/>
										{data?.asset?.toUpperCase()}
									</ButtonBase>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.formCroup}>
					<Input
						className={classes.input}
						label={`Quotable Total Balance ${data?.quotable}`}
						variant='outlined'
						name={"quotableTotalBalance"}
						value={form?.quotableTotalBalance}
						onChange={handleInputChange}
						onFocus={() =>
							!form?.quotableTotalBalance &&
							setForm({
								...form,
								quotableTotalBalance: "",
							})
						}
						onBlur={({ target: { value, name } }) =>
							!form?.quotableTotalBalance && handleInputValidation(name, value)
						}
						error={errors?.quotableTotalBalance?.invalid}
						helperText={errors?.quotableTotalBalance?.errorMessage}
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							autoComplete: "off",
							endAdornment: (
								<InputAdornment position='end'>
									<ButtonBase className={classes.select} disabled>
										<img
											src={`https://cryptoneed.com/icons/${data?.quotable}.svg`}
											alt={data?.quotable}
											width={24}
											height={24}
											loading={"lazy"}
										/>
										{data?.quotable?.toUpperCase()}
									</ButtonBase>
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item container justifyContent='center'>
					<Button
						color={"primary"}
						style={{ width: "max(50%, 50px)" }}
						onClick={handleUpdate}>
						Save
					</Button>
				</Grid>
			</Grid>
		</DialogWrapper>
	);
};

export default EditBalanceDialog;

const useStyles = makeStyles(theme => ({
	formCroup: {
		display: "flex",
		flexDirection: "column",
	},
	input: {
		maxWidth: 300,
		width: "100%",
		margin: "0px auto",
		// marginTop: 24,
	},
	button: { marginTop: 24, margin: "0px auto" },
	errorField: {
		color: theme.palette.text.error,
		fontSize: 12,
		position: "absolute",
		marginTop: 56,
	},
	success: {
		color: "#00ff00",
	},
	error: {
		color: "#ff0000",
	},
}));
