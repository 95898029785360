import {
	GET_USERS_BLOCKERS_STARTED,
	GET_USERS_BLOCKERS_SUCCESS,
	GET_USERS_BLOCKERS_FAILURE,
	SET_USER_BLOCKERS_STARTED,
	SET_USER_BLOCKERS_SUCCESS,
	SET_USER_BLOCKERS_FAILURE,
	GET_BLOCKERS_BY_ID_STARTED,
	GET_BLOCKERS_BY_ID_SUCCESS,
	GET_BLOCKERS_BY_ID_FAILURE,
} from "../types";
import api from "../api";
import { getProfileById } from "./usersActions";
import { enqueueSnackbar } from "./notistackActions";

// getUsersBlockers

export const getUsersBlockersStarted = () => ({
	type: GET_USERS_BLOCKERS_STARTED,
});

export const getUsersBlockersSuccess = data => ({
	type: GET_USERS_BLOCKERS_SUCCESS,
	payload: {
		data,
	},
});

export const getUsersBlockersFailure = error => ({
	type: GET_USERS_BLOCKERS_FAILURE,
	payload: {
		error,
	},
});

export const getUsersBlockers = () => {
	getUsersBlockersStarted();
	return async dispatch => {
		api.blockUser
			.getList()
			.then(res => {
				dispatch(getUsersBlockersSuccess(res.data));
			})
			.catch(error => dispatch(getUsersBlockersFailure(error)));
	};
};

// setUserBlockers

export const setUserBlockersStarted = () => ({
	type: SET_USER_BLOCKERS_STARTED,
});

export const setUserBlockersSuccess = data => ({
	type: SET_USER_BLOCKERS_SUCCESS,
	payload: {
		data,
	},
});

export const setUserBlockersFailure = error => ({
	type: SET_USER_BLOCKERS_FAILURE,
	payload: {
		error,
	},
});

export const setUserBlockers = data => {
	setUserBlockersStarted();
	const { id } = data;
	return async dispatch => {
		api.blockUser
			.createBlocking(data)
			.then(() => {
				dispatch(getProfileById(id))
				dispatch(
					enqueueSnackbar({
						message: "Activity saved",
						options: {
							key: new Date().getTime() + Math.random(),
							variant: "success",
							autoHideDuration: 3000,
						},
					})
				);
			})
			.catch(error => dispatch(setUserBlockersFailure(error)));
	};
};

// getBlockersBy

export const getBlockersByIdStarted = () => ({
	type: GET_BLOCKERS_BY_ID_STARTED,
});

export const getBlockersByIdSuccess = data => ({
	type: GET_BLOCKERS_BY_ID_SUCCESS,
	payload: {
		data,
	},
});

export const getBlockersByIdFailure = error => ({
	type: GET_BLOCKERS_BY_ID_FAILURE,
	payload: {
		error,
	},
});

export const getBlockersById = id => {
	getBlockersByIdStarted();
	return async dispatch => {
		api.blockUser
			.getBlocksById(id)
			.then(res => {
				dispatch(getBlockersByIdSuccess(res.data));
			})
			.catch(error => dispatch(getBlockersByIdFailure(error)));
	};
};
